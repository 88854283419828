import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useAppDispatch } from '../../../../redux/store';
import { setNotificationError } from '../../../../redux/slices/NotificationSlices';
import TopHeader from '../../../../shared-components/top-header/top-header';
import CardBox from '../../../../shared-components/card/card';
import RadioButtonField from '../../../../shared-components/radio-button-field/radio-button-field';
import SelectBoxField from '../../../../shared-components/selectbox-field/selectbox-field';
import CheckBoxInputField from '../../../../shared-components/checkbox-input-field/checkbox-input-field';
import Button from '../../../../shared-components/button/button';
import InputField from '../../../../shared-components/input-field/input-field';
import securityPrameters from '../../../../utils/api/SystemManagement/securityPrameters';

interface FormValues {
  verificationType: string;
  loginVerificationSetting: string;
  defaultPasswordStrength: string;
  maxLoginAttempts: string;
  accountValidDays: string;
  passwordValidDays: string;
  passwordModification: string;
  securityPasswordVerification: boolean;
  securePasswordAuthenticationInterval: string;
}

interface OptionData {
  value: string;
  label: string;
  id: string;
}

function SecurityParameters() {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const [formValues, setFormValues] = useState<FormValues>({
    verificationType: '0',
    loginVerificationSetting: '2',
    defaultPasswordStrength: '2',
    maxLoginAttempts: '5',
    accountValidDays: '0',
    passwordValidDays: '2',
    passwordModification: '0',
    securityPasswordVerification: true,
    securePasswordAuthenticationInterval: '0',
  });
  const [show2FAError, setShow2FAError] = useState(false);
  const [userDefinedDays, setUserDefinedDays] = useState<string>('');

  const handleChange = (name: string, data: any) => {
    if (name === 'securityPasswordVerification') {
      setFormValues((prevValues) => ({
        ...prevValues,
        [name]: !prevValues.securityPasswordVerification,
      }));
    } else {
      setFormValues((prevValues) => ({
        ...prevValues,
        [name]: data,
      }));

      if (name === 'verificationType' && data === '1') {
        setShow2FAError(true);
      } else if (name === 'verificationType') {
        setShow2FAError(false);
      }

      if (name === 'passwordValidDays' && data !== '4') {
        setUserDefinedDays('');
      }
    }
  };

  const handleUserDefinedDaysChange = (name: string, value: string) => {
    const numValue = parseInt(value, 10);
    if (value === '' || (!Number.isNaN(numValue) && numValue >= 1 && numValue <= 365)) {
      setUserDefinedDays(value);
    }
  };

  const handleSecondPasswordStrength = (name: string, value: string) => {
    setFormValues((prevValues) => ({
      ...prevValues,
      securePasswordAuthenticationInterval: value,
    }));
  };

  const passwordStrengthOptions: OptionData[] = [
    { value: '0', id: '0', label: t('none') },
    { value: '15', id: '15', label: '15' },
    { value: '30', id: '30', label: '30' },
    { value: '60', id: '60', label: '60' },
  ];

  const loginVerificationOptions: OptionData[] = [
    { value: '0', id: '0', label: t('none') },
    { value: '1', id: '1', label: t('alwaysPromptVerificationCode') },
    { value: '2', id: '2', label: t('promptAfterEnteringAnError') },
  ];

  const loginAttemptOptions: OptionData[] = [
    { value: 'Unlimited', id: 'Unlimited', label: t('unlimited') },
    { value: '1', id: '1', label: '1' },
    { value: '2', id: '2', label: '2' },
    { value: '3', id: '3', label: '3' },
    { value: '4', id: '4', label: '4' },
    { value: '5', id: '5', label: '5' },
  ];

  const accountLockOptions: OptionData[] = [
    { value: '0', id: '0', label: '10' },
    { value: '1', id: '1', label: '20' },
    { value: '2', id: '2', label: '30' },
    { value: '3', id: '3', label: '40' },
    { value: '4', id: '4', label: '50' },
    { value: '5', id: '5', label: '60' },
  ];

  const validDaysOptions: OptionData[] = [
    { value: '0', id: '0', label: '30' },
    { value: '1', id: '1', label: '60' },
    { value: '2', id: '2', label: '90' },
    { value: '3', id: '3', label: '180' },
    { value: '4', id: '4', label: t('userDefined') },
    { value: '5', id: '5', label: t('permanent') },
  ];

  const passwordModificationOptions: OptionData[] = [
    { value: '0', id: '0', label: t('none') },
    { value: '2', id: '2', label: t('forcedToModifyPasswordInLogin') },
  ];

  const verificationTypeOptions: OptionData[] = [
    { value: '0', id: '0', label: t('verificationCode') },
    { value: '1', id: '1', label: t('twoFAVerification') },
  ];

  const passwordStrengthRadioOptions: OptionData[] = [
    { value: '3', id: '3', label: t('none') },
    { value: '0', id: '0', label: t('weak') },
    { value: '1', id: '1', label: t('medium') },
    { value: '2', id: '2', label: t('strong') },
  ];

  const fetchSecurityParams = async () => {
    try {
      const response = await securityPrameters.getParams();
      if (response && response.data) {
        const { data } = response;

        setFormValues({
          verificationType: data.verificationOption === '1' ? '1' : '0',
          loginVerificationSetting: data.verificationCodeOpen || '2',
          defaultPasswordStrength: data.passwordStrengthLevel || '2',
          maxLoginAttempts: data.loginErrorCont || '5',
          accountValidDays: data.lockTime || '0',
          passwordValidDays: data.effectiveTime || '2',
          passwordModification: data.initPwd === '2' ? '2' : '0',
          securityPasswordVerification: data.pwdRequiredForOperation === '1',
          securePasswordAuthenticationInterval: data.pwdAuthenticationInterval || '0',
        });

        if (data.pwdAuthenticationInterval && data.effectiveTime === '4') {
          setUserDefinedDays(data.pwdAuthenticationInterval);
        }

        if (data.verificationOption === '1') {
          setShow2FAError(true);
        }
      }
    } catch (error: any) {
      dispatch(
        setNotificationError({
          error: error.message,
          status: error.status,
        }),
      );
    }
  };

  const getPasswordMessage = (strength: string) => {
    switch (strength) {
      case '3':
        return '';
      case '0':
        return t('weakPasswordMessage');
      case '1':
        return t('mediumPasswordMessage');
      case '2':
        return t('strongPasswordMessage');
      default:
        return '';
    }
  };

  const submit = async () => {
    const mapOtherEffectiveTime = (days: string) => {
      const numDays = parseInt(days, 10);
      if (
        formValues.passwordValidDays === '4' &&
        !Number.isNaN(numDays) &&
        numDays >= 1 &&
        numDays <= 365
      ) {
        return numDays.toString();
      }
      return '2';
    };

    const apiPayload = {
      verificationOption: formValues.verificationType,
      verificationCodeOpen: formValues.loginVerificationSetting,
      passwordStrengthLevel: formValues.defaultPasswordStrength,
      loginErrorCont: formValues.maxLoginAttempts,
      lockTime: formValues.accountValidDays,
      effectiveTime: formValues.passwordValidDays,
      initPwd: formValues.passwordModification,
      pwdRequiredForOperation: formValues.securityPasswordVerification ? '1' : '0',
      pwdAuthenticationInterval:
        formValues.passwordValidDays === '4'
          ? userDefinedDays
          : formValues.securePasswordAuthenticationInterval,
      otherEffectiveTime: mapOtherEffectiveTime(userDefinedDays),
    };

    try {
      const response = await securityPrameters.saveParams(apiPayload);
      if (response.data.success) {
        const message = t('Security parameters successfully saved');
        dispatch(
          setNotificationError({
            error: message,
            status: 200,
          }),
        );
        await fetchSecurityParams();
      } else {
        dispatch(
          setNotificationError({
            error: response.data.message || 'Failed to save security parameters',
            status: response.data.code || 400,
          }),
        );
      }
    } catch (error: any) {
      dispatch(
        setNotificationError({
          error: error.message || 'An error occurred while saving security parameters',
          status: error.status || 500,
        }),
      );
    }
  };

  useEffect(() => {
    fetchSecurityParams();
  }, []);

  return (
    <div className="security-parameters">
      <TopHeader
        title={t('system')}
        broadCram={[{ title: t('authorityManagement') }, { title: t('securityParameters') }]}
      />
      <CardBox title={t('securityParameters')}>
        <div className="security-parameters-content">
          <div className="section login-verification-section">
            <h4>{t('loginVerificationSetting')}</h4>
            <div className="verification-options">
              <RadioButtonField
                label=""
                name="verificationType"
                alignment="row-wrap"
                data={verificationTypeOptions}
                value={formValues.verificationType}
                onChangeFunction={(name, data) => handleChange(name as keyof FormValues, data)}
              />
            </div>

            {show2FAError && (
              <div className="text-danger mt-2">
                <div className="note">
                  <div className="content">{t('pleaseEnsureThatTheUserSetsUpAMailbox')}</div>
                </div>
              </div>
            )}

            {formValues.verificationType !== '1' && (
              <div className="select-box-container">
                <SelectBoxField
                  id="loginVerificationSetting"
                  name="loginVerificationSetting"
                  label=""
                  data={loginVerificationOptions}
                  value={formValues.loginVerificationSetting}
                  onChangeFunction={(name, changeValue) =>
                    handleChange(name as keyof FormValues, changeValue.id)
                  }
                />
              </div>
            )}
          </div>
          <div className="section password-strength-section">
            <h4>{t('defaultPasswordStrength')}</h4>
            <div className="radio-options">
              <RadioButtonField
                label=""
                name="defaultPasswordStrength"
                alignment="row-wrap"
                data={passwordStrengthRadioOptions}
                value={formValues.defaultPasswordStrength}
                onChangeFunction={(name, data) => handleChange(name as keyof FormValues, data)}
              />
            </div>
          </div>
          {formValues.defaultPasswordStrength !== '3' && (
            <div className="text-danger mt-2">
              <div className="note">
                <div className="content">
                  <div className="password-msg">
                    {getPasswordMessage(formValues.defaultPasswordStrength)}
                  </div>
                </div>
              </div>
            </div>
          )}
          <div className="section login-settings-grid">
            <div className="login-format">
              <div className="grid-cell">
                <h4>{t('failedLogin')}</h4>
                <SelectBoxField
                  id="maxLoginAttempts"
                  name="maxLoginAttempts"
                  label=""
                  data={loginAttemptOptions}
                  value={formValues.maxLoginAttempts}
                  onChangeFunction={(name, changeValue) =>
                    handleChange(name as keyof FormValues, changeValue.id)
                  }
                  validation
                  message={t('times')}
                />
              </div>
              <div className="grid-cell">
                <h4>{t('accountWillLock')}</h4>
                <SelectBoxField
                  id="accountValidDays"
                  name="accountValidDays"
                  label=""
                  data={accountLockOptions}
                  value={formValues.accountValidDays}
                  onChangeFunction={(name, changeValue) =>
                    handleChange(name as keyof FormValues, changeValue.id)
                  }
                  disabled={formValues.maxLoginAttempts === 'Unlimited'}
                  validation
                  message={t('minutes')}
                />
              </div>
              <div className="grid-cell">
                <h4>{t('passwordValidDays')}</h4>
                <SelectBoxField
                  id="passwordValidDays"
                  name="passwordValidDays"
                  label=""
                  data={validDaysOptions}
                  value={formValues.passwordValidDays}
                  onChangeFunction={(name, changeValue) =>
                    handleChange(name as keyof FormValues, changeValue.id)
                  }
                />
              </div>
              {formValues.passwordValidDays === '4' && (
                <div className="grid-cell">
                  <h4>{t('userDefined')}</h4>
                  <InputField
                    id="userDefinedPasswordValidDays"
                    name="userDefinedPasswordValidDays"
                    label=""
                    type="number"
                    value={userDefinedDays}
                    onChangeFunction={handleUserDefinedDaysChange}
                    min={1}
                    max={365}
                  />
                </div>
              )}
              <div className="password-motification">
                <h4>{t('passwordModification')}</h4>
                <SelectBoxField
                  id="passwordModification"
                  name="passwordModification"
                  label=""
                  data={passwordModificationOptions}
                  value={formValues.passwordModification}
                  onChangeFunction={(name, changeValue) =>
                    handleChange(name as keyof FormValues, changeValue.id)
                  }
                />
              </div>
            </div>
          </div>
          <div className="checkbox-section">
            <CheckBoxInputField
              name="securityPasswordVerification"
              data={[
                {
                  label: t('securityPasswordVerification'),
                  id: 'yes',
                  value: 'yes',
                },
              ]}
              value={formValues.securityPasswordVerification ? ['yes'] : []}
              onChangeFunction={() => handleChange('securityPasswordVerification', null)}
            />
          </div>
          <div className="defaultPasswordStrength">
            <h4>{t('Secure Password Authentication Interval')}</h4>
            <SelectBoxField
              id="securePasswordAuthenticationInterval"
              name="securePasswordAuthenticationInterval"
              label=""
              data={passwordStrengthOptions}
              value={formValues.securePasswordAuthenticationInterval}
              onChangeFunction={(name, changeValue) =>
                handleSecondPasswordStrength(name as keyof FormValues, String(changeValue.id))
              }
              disabled={!formValues.securityPasswordVerification}
            />
          </div>
          <div className="parameters-buttons">
            <div className="form-buttons-right">
              <Button onClickFunction={submit} title={t('done')} className="btn-primary btn-bold" />
            </div>
          </div>
        </div>
      </CardBox>
    </div>
  );
}

SecurityParameters.defaultProps = {
  closeModal: '',
};

export default SecurityParameters;
