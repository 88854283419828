import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from '../../../../redux/store';
import QrCodeSetting from './components/qrCodeSetting';
import DateTimeFormatSettings from './components/dateTimeFormatSettings';
import PersonalSensitiveInformationProtection from './components/personalSensitiveInformation';
import parametersApi from '../../../../utils/api/SystemManagement/parameters';
import { setNotificationError } from '../../../../redux/slices/NotificationSlices';
import {
  QrCodeMapItem,
  SystemEntryMapItem,
  SystemPluginItem,
  ParametersProps,
} from '../../../../interface/System/systemManagementParameters.interface';
import TopHeader from '../../../../shared-components/top-header/top-header';
import CardBox from '../../../../shared-components/card/card';
import TabPages from '../../../../shared-components/tab-pages/tab-pages';
import Button from '../../../../shared-components/button/button';
import { fetchSystemManagementParameters } from '../../../../redux/slices/system-management/SystemManagementParametersSlices';

const defaultQrCodeMap: QrCodeMapItem = {
  'qrcode.algorithm': '2',
  'qrcode.type': '2',
  'qrcode.validTime': '30',
  'qrcode.enable': '1',
};

const defaultSystemEntryMap: SystemEntryMapItem = {
  'systemEntry.cmd.encryptProp': 'false',
  'systemEntry.email.encryptMode': '',
  'systemEntry.email.encryptProp': 'false',
  'systemEntry.lastName.encryptMode': '',
  'systemEntry.lastName.encryptProp': 'false',
  'systemEntry.name.encryptMode': '',
  'systemEntry.name.encryptProp': 'false',
};

const defaultSystemPlugin: SystemPluginItem = {
  'system.plugin.isEnableWaterMark': 'false',
};

function SystemManagementParameters() {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  let sortTime = true;

  const { systemManagementParameters, status } = useAppSelector(
    (state: any) => state.systemManagementParameters,
  );

  const [parametersValue, setParameters] = useState<ParametersProps>({
    qrCodeMap: { ...defaultQrCodeMap },
    systemEntryMap: { ...defaultSystemEntryMap },
    systemPlugin: { ...defaultSystemPlugin },
    systemPrivacy: '',
    permissions: [],
  });
  const onChangeValue = (page: any, name: any, changeValue: any) => {
    setParameters((prev: any) => ({
      ...prev,
      [page]: {
        ...prev?.[page],
        [name]: changeValue,
      },
    }));
  };
  const onChangeMultiValue = (name: any, changeValue: any) => {
    setParameters((prev: any) => ({
      ...prev,
      [name]: changeValue,
    }));
  };
  const [tabs, setTabs] = useState([
    {
      key: 'qrCodeSetting',
      title: t('qrCodeSetting'),
      content: <QrCodeSetting onChangeFunction={onChangeValue} values={parametersValue} />,
      enable: true,
    },
  ]);
  const fetchParameterData = () => {
    if (status === 'success') {
      dispatch(fetchSystemManagementParameters({}));
    }
  };
  const validateFields = () => {
    const emptyFields = Object.entries(parametersValue).filter(([value]) => value === '');
    return emptyFields.length > 0;
  };

  useEffect(() => {
    if (
      systemManagementParameters &&
      Object.keys(systemManagementParameters).length === 0 &&
      sortTime
    ) {
      fetchParameterData();
      sortTime = false;
    } else {
      setParameters(systemManagementParameters);
    }
  }, [systemManagementParameters]);

  const submit = async () => {
    if (validateFields()) {
      dispatch(
        setNotificationError({
          error: t('Please fill in all required fields'),
          status: 400,
        }),
      );
      return;
    }

    // Create a new FormData object
    const formData = new FormData();

    // Declare qrCodeEnable and qrCodeType at the top of the function
    const qrCodeEnable = String(parametersValue.qrCodeMap['qrcode.enable'] || '0');
    let qrCodeType = String(parametersValue.qrCodeMap['qrcode.type'] || '2');
    // dispatch(setQrcodeEnable(qrCodeEnable));

    // Check qrcode.enable and adjust qrcode.type accordingly
    if (qrCodeEnable === '0') {
      qrCodeType = '0'; // Force qrcode.type to be '0' if qrcode.enable is '0'
    }

    // Append parameters to the FormData
    formData.append('qrcode.enable', qrCodeEnable);
    formData.append('qrcode.type', qrCodeType);
    formData.append(
      'qrcode.algorithm',
      String(parametersValue.qrCodeMap['qrcode.algorithm'] || '2'),
    );
    formData.append(
      'qrcode.validTime',
      String(parametersValue.qrCodeMap['qrcode.validTime'] || '30'),
    );
    formData.append('system.fmt.date', parametersValue['system.fmt.date'] || 'YYYY_MM_DD');
    formData.append(
      'system.fmt.date_new_value',
      String(parametersValue['system.fmt.date_new_value'] || ''),
    );
    formData.append('system.fmt.time', parametersValue['system.fmt.time'] || 'HH_MM_SS');
    formData.append(
      'system.fmt.time_new_value',
      String(parametersValue['system.fmt.time_new_value'] || ''),
    );
    formData.append(
      'systemEntry.name.encryptProp',
      String(parametersValue.systemEntryMap['systemEntry.name.encryptProp'] || 'false'),
    );
    formData.append(
      'systemEntry.lastName.encryptProp',
      String(parametersValue.systemEntryMap['systemEntry.lastName.encryptProp'] || 'false'),
    );
    formData.append(
      'systemEntry.email.encryptProp',
      String(parametersValue.systemEntryMap['systemEntry.email.encryptProp'] || 'false'),
    );
    formData.append(
      'systemEntry.cmd.encryptProp',
      String(parametersValue.systemEntryMap['systemEntry.cmd.encryptProp'] || 'false'),
    );
    formData.append('system.privacy', String(parametersValue['system.privacy'] || '0'));
    formData.append(
      'system.privacy_new_value',
      String(parametersValue['system.privacy_new_value'] || ''),
    );
    if (parametersValue.importPrivacy !== null) {
      formData.append('importPrivacy', parametersValue.importPrivacy);
    }

    try {
      const response = await parametersApi.saveList(formData);

      if (response.data.success) {
        dispatch(
          setNotificationError({
            error: t('systemParametersAddedSuccessfully'),
            status: 200,
          }),
        );
        fetchParameterData();
      } else {
        dispatch(
          setNotificationError({
            error: response.data.msg,
            status: response.data.code,
          }),
        );
      }
    } catch (error: any) {
      dispatch(
        setNotificationError({
          error: error.message || 'An unexpected error occurred',
          status: error.status || 500,
        }),
      );
    }
  };

  useEffect(() => {
    if (parametersValue) {
      setTabs([
        {
          key: 'qrCodeSetting',
          title: t('qrCodeSetting'),
          content: (
            <QrCodeSetting onChangeFunction={onChangeValue} values={parametersValue.qrCodeMap} />
          ),
          enable: true,
        },
        {
          key: 'dateTimeFormatSettings',
          title: t('dateTimeFormatSettings'),
          content: (
            <DateTimeFormatSettings
              onChangeFunction={(updatedValue: any) => {
                setParameters((prev) => ({ ...prev, ...updatedValue }));
              }}
              values={parametersValue}
            />
          ),
          enable: true,
        },
        {
          key: 'personalSensitiveInformationProtection',
          title: t('personalSensitiveInformationProtection'),
          content: (
            <PersonalSensitiveInformationProtection
              onChangeFunction={onChangeMultiValue}
              values={parametersValue.systemEntryMap}
            />
          ),
          enable: true,
        },
      ]);
    }
  }, [parametersValue, t]);

  return (
    <div className="parameters">
      <TopHeader
        title={t('system')}
        broadCram={[{ title: t('systemManagement') }, { title: t('parameters') }]}
      />
      <CardBox title={t('parameters')}>
        <TabPages tabs={tabs} defaultNumber={0} />

        <div className="parameters-buttons">
          <div className="form-buttons-right">
            <Button onClickFunction={submit} title={t('done')} className="btn-primary btn-bold" />
          </div>
        </div>
      </CardBox>
    </div>
  );
}

export default SystemManagementParameters;
