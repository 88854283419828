import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import SelectBoxField from '../../../../../shared-components/selectbox-field/selectbox-field';
import Button from '../../../../../shared-components/button/button';
import {
  ADFieldSettingProps,
  FieldOption,
  FieldState,
  SelectItemInterFace,
} from '../../../../../interface/System/adFieldSetting.interface';
import Label from '../../../../../shared-components/label/Label';

function ADFieldSetting({ onChangeFunction }: ADFieldSettingProps) {
  const { t } = useTranslation();

  // Database field options
  const dbFieldOptions: FieldOption[] = [
    { label: 'Personnel ID', id: 'personnelId' },
    { label: 'First Name', id: 'firstName' },
    { label: 'Last Name', id: 'lastName' },
    { label: 'Department Name', id: 'departmentName' },
    { label: 'Mobile Phone', id: 'mobilePhone' },
    { label: 'Email', id: 'email' },
    { label: 'Kelas siswa', id: 'kelasSiswa' },
  ];

  // AD field options
  const adFieldOptions: FieldOption[] = [
    { label: 'Description', id: 'description' },
    { label: 'Name', id: 'name' },
    { label: 'Last Name', id: 'lastName' },
    { label: 'Department', id: 'department' },
    { label: 'Mobile', id: 'mobile' },
    { label: 'Email', id: 'email' },
    { label: 'Employee Id', id: 'employeeId' },
  ];

  // Initial database fields with default values matching the image
  const initialDbFields: FieldState[] = [
    { id: 'field1', value: 'personnelId' },
    { id: 'field2', value: 'firstName' },
    { id: 'field3', value: 'lastName' },
    { id: 'field4', value: 'departmentName' },
    { id: 'field5', value: 'mobilePhone' },
    { id: 'field6', value: 'email' },
    { id: 'field7', value: '' },
    { id: 'field8', value: '' },
  ];

  // Initial AD fields with default values matching the image
  const initialAdFields: FieldState[] = [
    { id: 'field1', value: 'description' },
    { id: 'field2', value: 'name' },
    { id: 'field3', value: 'lastName' },
    { id: 'field4', value: 'department' },
    { id: 'field5', value: 'mobile' },
    { id: 'field6', value: 'email' },
    { id: 'field7', value: '' },
    { id: 'field8', value: '' },
  ];

  const [dbFields, setDbFields] = useState<FieldState[]>(initialDbFields);
  const [adFields, setAdFields] = useState<FieldState[]>(initialAdFields);

  // Function to handle db field changes
  const handleDbFieldChange = (fieldId: string, selectedOption: SelectItemInterFace) => {
    setDbFields((prevFields) =>
      prevFields.map((field) =>
        field.id === fieldId ? { ...field, value: String(selectedOption.id) } : field,
      ),
    );
  };

  // Function to handle ad field changes
  const handleAdFieldChange = (fieldId: string, selectedOption: SelectItemInterFace) => {
    setAdFields((prevFields) =>
      prevFields.map((field) =>
        field.id === fieldId ? { ...field, value: String(selectedOption.id) } : field,
      ),
    );
  };

  // Function to reset configuration
  const resetConfiguration = () => {
    setDbFields(initialDbFields);
    setAdFields(initialAdFields);
  };

  // Function to add a new field
  const addField = () => {
    const newId = `field${dbFields.length + 1}`;
    setDbFields([...dbFields, { id: newId, value: '' }]);
    setAdFields([...adFields, { id: newId, value: '' }]);
  };

  // Function to remove a field
  const removeField = (index: number) => {
    if (dbFields.length > 1) {
      setDbFields(dbFields.filter((_, i) => i !== index));
      setAdFields(adFields.filter((_, i) => i !== index));
    }
  };

  // Handle keyboard navigation for add and remove buttons
  const handleKeyDown = (event: React.KeyboardEvent, action: () => void) => {
    if (event.key === 'Enter' || event.key === ' ') {
      event.preventDefault();
      action();
    }
  };
  console.log(handleKeyDown);

  useEffect(() => {
    // Notify parent component about field changes if needed
    if (onChangeFunction) {
      const mappings = dbFields.map((field, index) => ({
        dbField: field.value,
        adField: adFields[index]?.value || '',
      }));
      onChangeFunction(mappings);
    }
  }, [dbFields, adFields, onChangeFunction]);

  // Helper function to get the selected option or a default label
  const getDefaultLabel = (options: FieldOption[], value: string, index: number): string => {
    if (value) {
      const option = options.find((opt) => opt.id === value);
      return option ? option.label : options[index]?.label || '';
    }
    return '';
  };

  return (
    <div className="ad-field-setting">
      <div className="field-mapping-container">
        {/* Database Fields Section */}
        <div className="database-fields">
          <Label className="section-title" title={t('databaseFields')} />
          <div className="field-list">
            {dbFields.map((field, index) => (
              <div key={field.id} className="field-item">
                <SelectBoxField
                  id={field.id}
                  name={field.id}
                  value={field.value}
                  onChangeFunction={(name, value) => handleDbFieldChange(field.id, value)}
                  data={dbFieldOptions}
                  defaultLabel={getDefaultLabel(dbFieldOptions, field.value, index)}
                />
              </div>
            ))}
          </div>
        </div>

        {/* AD Fields Section */}
        <div className="ad-fields">
          <Label className="section-title" title={t('importADFields')} />
          <div className="field-list">
            {adFields.map((field, index) => (
              <div key={field.id} className="field-item-with-controls">
                <div className="field-select">
                  <SelectBoxField
                    id={field.id}
                    name={field.id}
                    value={field.value}
                    onChangeFunction={(name, value) => handleAdFieldChange(field.id, value)}
                    data={adFieldOptions}
                    defaultLabel={getDefaultLabel(adFieldOptions, field.value, index)}
                  />
                </div>

                {/* Add button for the 7th field (index 6) */}
                {index === 6 && (
                  <Button
                    title="+"
                    className="icon-button add-button"
                    onClickFunction={addField}
                    aria-label="Add field"
                  />
                )}

                {/* Remove and Add buttons for the 8th field (index 7) */}
                {index === 7 && (
                  <div className="action-buttons">
                    <Button
                      title=""
                      className="icon-button remove-button"
                      onClickFunction={() => removeField(index)}
                      aria-label="Remove field"
                      icon={<span className="delete-icon">🗑</span>}
                    />
                    <Button
                      title="+"
                      className="icon-button add-button"
                      onClickFunction={addField}
                      aria-label="Add field"
                    />
                  </div>
                )}
              </div>
            ))}
          </div>
        </div>
      </div>
      <div className="cleanup-button">
        <Button
          title={t('resetConfiguration')}
          className="btn-primary"
          onClickFunction={resetConfiguration}
        />
      </div>
    </div>
  );
}

export default ADFieldSetting;
