/* eslint-disable no-param-reassign */
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { setNotificationError } from '../NotificationSlices';
import clientRegister from '../../../utils/api/SystemManagement/clientRegister';

export const fetchClientRegister = createAsyncThunk(
  'acc/fetchResourceFile',
  async (data: any, { dispatch }) => {
    const response = await clientRegister
      .getListPage(data)
      .then((response1) => {
        return {
          ...response1.data,
          data: {
            list: response1.data.data,
            size: response1.data.data.length,
            ...data,
          },
        };
      })
      .catch((error) => {
        dispatch(
          setNotificationError({
            error: error.message,
            status: error.response?.status || 500,
          }),
        );
        return error;
      });
    return response;
  },
);

interface ReaderReduxInterface {
  clientRegisterList: any;
  status: 'loading' | 'success' | 'failed';
  totalDataCount: number;
  currentPageNo: number;
  searchFilter: { [key: string]: any };
}

const initialState: ReaderReduxInterface = {
  clientRegisterList: [],
  status: 'success',
  totalDataCount: 0,
  currentPageNo: 0,
  searchFilter: {},
};

export const ClientRegisterSlice: any = createSlice({
  name: 'clientRegister',
  initialState,
  reducers: {
    deleteClientRegister: (state, action: any) => {
      state.clientRegisterList = state.clientRegisterList.filter(
        (val: any) => val.id !== action.payload,
      );
      state.totalDataCount -= 1;
    },
    setSearchFilter: (state, action: any) => {
      state.searchFilter = action.payload;
    },
  },
  extraReducers: (builder) => {
    // Add reducers for additional action types here, and handle loading state as needed
    builder
      .addCase(fetchClientRegister.pending, (state = initialState) => {
        state.status = 'loading';
      })
      .addCase(fetchClientRegister.fulfilled, (state: any, action: any) => {
        // Add user to the state array
        if (action.payload.success) {
          state.clientRegisterList = action.payload.data.list;
          state.totalDataCount = action.payload.data.size;
          state.currentPageNo += 1;
        }
        state.status = 'success';
      })
      .addCase(fetchClientRegister.rejected, (state: any) => {
        state.status = 'failed';
      });
  },
});

// Action creators are generated for each case reducer function
export const { setSearchFilter, deleteClientRegister } = ClientRegisterSlice.actions;

export default ClientRegisterSlice.reducer;
