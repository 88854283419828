import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from '../../../redux/store';

// Use shared components
import TopHeader from '../../../shared-components/top-header/top-header';
import CardBox from '../../../shared-components/card/card';
import DataTable from '../../../shared-components/data-table/data-table';
import CardRightButtonGroup from '../../../shared-components/card-right-button-group/card-right-button-group';
import ModalBox from '../../../shared-components/modal-box/modal-box';
// Import slice actions
import { fetchFirstInLastOut, setSearchFilter } from '../../../redux/slices/FirstInLastOutSlices';
import { DepartmentParamsProps } from '../../../interface/accessByDepartment.interface';
import { changeDateTimeFormat } from '../../../utils/helpers';
import ConformationBox from '../../../shared-components/conformation-box/conformation-box';
import firstInLastOut from '../../../utils/api/AccControlReports/firstInLastOut';
import { setNotificationError } from '../../../redux/slices/NotificationSlices';
import ExportData from './export';
import { checkPermission } from '../../../utils/role-permission';

function FirstInLastOut() {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { firstInLastOutList, status, totalDataCount, searchFilter } = useAppSelector(
    (state: any) => state.firstInLastOut,
  );

  /**
   * Selects the `loadMenus` permission from the authentication slice of the Redux store.
   *
   * @returns {Object} loadMenus - The user's menu permissions.
   */
  const { loadMenus } = useAppSelector((state) => state.auth);

  const [modalOpen, setModalOpen] = useState(false);
  const [modalView, setModalView] = useState({
    title: 'hi',
    content: <>hi</>,
  });
  const [selectedValues, setSelectedValues] = useState<string[]>([]);
  const columns = [
    {
      accessorKey: 'pin',
      header: t('personnelId'),
      size: 180,
    },
    {
      accessorKey: 'name',
      header: t('firstName'),
      size: 140,
    },
    {
      accessorKey: 'lastName',
      header: t('lastName'),
      size: 140,
    },
    {
      accessorKey: 'readerNameIn',
      header: t('firstInReaderName'),
      size: 220,
    },
    {
      accessorKey: 'firstInTime',
      header: t('firstInTime'),
      size: 180,
      Cell: ({ row }: any) => <div>{changeDateTimeFormat(row.original.firstInTime)}</div>,
    },
    {
      accessorKey: 'readerNameOut',
      header: t('lastOutReaderName'),
      size: 180,
    },
    {
      accessorKey: 'lastOutTime',
      header: t('lastOutTime'),
      size: 190,
      Cell: ({ row }: any) => <div>{changeDateTimeFormat(row.original.lastOutTime)}</div>,
    },
    {
      accessorKey: 'deptName',
      header: t('departmentName'),
      size: 140,
    },
  ];

  const fetchData = async (params: DepartmentParamsProps) => {
    if (status === 'success') {
      dispatch(fetchFirstInLastOut({ ...params }));
    }
  };

  const getFilterData = (filter: any): any => {
    if (filter.pageIndex > 0) {
      dispatch(setSearchFilter(filter));
      fetchData({ pageNo: filter.pageIndex, pageSize: filter.pageSize, ...filter.filter });
    }
  };

  const exportData = () => {
    setModalOpen(false);
  };

  const onConfirmClear = () => {
    firstInLastOut
      .clearData()
      .then((response) => {
        if (response.data.success) {
          dispatch(
            setNotificationError({
              error: response.data.msg,
              status: 200,
            }),
          );
          setModalOpen(false);
          fetchData({ pageNo: 1 }); // Refresh the list after successful deletion
        } else {
          dispatch(
            setNotificationError({
              error: response.data.msg,
              status: response.data.ret,
            }),
          );
        }
      })
      .catch((error) => {
        dispatch(
          setNotificationError({
            error: error.message,
            status: error.response?.status || 500,
          }),
        );
      });
  };
  const moreActionButton = [
    {
      title: t('export'),
      enable: checkPermission('acc:firstInLastOut:export', loadMenus),
      clickFunction: (): void => {
        setModalView({
          title: t('export'),
          content: (
            <ExportData closeModal={() => setModalOpen(false)} submitFunction={exportData} />
          ),
        });
        setModalOpen(true);
      },
    },
    {
      title: t('clearAllData'),
      enable: checkPermission('acc:firstInLastOut:clearData', loadMenus),
      clickFunction: (): void => {
        setModalView({
          title: t('clearAllData'),
          content: (
            <ConformationBox
              closeModal={() => setModalOpen(false)}
              okFunction={onConfirmClear}
              title={t('areYouWantToClearAllRecords')}
              buttonLabel={t('ok')}
              cancelTrue
            />
          ),
        });
        setModalOpen(true);
      },
    },
  ];

  return (
    <div className="card-management">
      <ModalBox
        status={modalOpen}
        closeModal={() => setModalOpen(false)}
        title={modalView.title}
        content={modalView.content}
      />
      <TopHeader
        title={t('access')}
        broadCram={[
          {
            title: t('accControlReports'),
          },
          {
            title: t('firstInLastOut'),
          },
        ]}
      />
      <CardBox
        header=""
        title={t('listOfPersonnel')}
        rightSide={
          <CardRightButtonGroup
            actions={moreActionButton}
            buttonIcon={false}
            buttonLabel=""
            buttonClass=""
            buttonStatus={false}
          />
        }>
        <DataTable
          actionButtonStatus={false}
          columns={columns}
          getFilterData={getFilterData}
          assignFilterValue={searchFilter}
          assignSelectedData={selectedValues}
          getSelectedData={(val: any): any => setSelectedValues(val)}
          checkBoxActive={false}
          enablePagination
          isFetching={status === 'loading'}
          values={firstInLastOutList}
          fixedColumn={['pin']}
          totalRecords={totalDataCount}
          height={411}
          enableColumnResizing={false}
        />
      </CardBox>
    </div>
  );
}

export default FirstInLastOut;
