import React from 'react';
import { useTranslation } from 'react-i18next';
import permissionDenied from '../../assets/images/permission-denied.png';

function PermissionDenied() {
  const { t } = useTranslation();
  return (
    <div className="permission-denied">
      <div className="permission-wrapper">
        <div className="img">
          <img src={permissionDenied} alt="" />
        </div>
        <div className="bottom-wrap">
          <h2>{t('permissionRequired')}</h2>
          <span>{t('pleaseGetTheNecessaryPermissions')}</span>
        </div>
      </div>
    </div>
  );
}

export default PermissionDenied;
