import React, { useEffect, useState } from 'react';

// Use common modules
import { useTranslation } from 'react-i18next';

// Use shared components
import CheckBoxInputField from '../../../shared-components/checkbox-input-field/checkbox-input-field';

// Use interface
import { ParameterInterface } from '../../../interface/parameter.interface';

function PersonnelSensitiveInformationProtection({ onChangeFunction, values }: ParameterInterface) {
  const { t } = useTranslation();
  const [value, setValue] = useState(values);

  const onChange = (name: string, changeValue: any) => {
    setValue((prev: any) => ({
      ...prev,
      [name]: changeValue,
    }));
  };

  useEffect(() => {
    onChangeFunction(value);
  }, [value]);
  return (
    <div className="personnel-setting">
      <div className="personnel-setting-form w100">
        <div className="row row-2 row1--3">
          <div className="item">
            <CheckBoxInputField
              label=""
              name="permissions"
              alignment="warp grid-2"
              required
              data={[
                {
                  label: t('personnelId'),
                  id: 'pin',
                  value: 'pin',
                },
                {
                  label: t('photo'),
                  id: 'bioPhoto',
                  value: 'bioPhoto',
                },
                {
                  label: t('firstName'),
                  id: 'name',
                  value: 'name',
                },
                {
                  label: t('certificateNumber'),
                  id: 'certNumber',
                  value: 'certNumber',
                },
                {
                  label: t('lastName'),
                  id: 'lastName',
                  value: 'lastName',
                },
                {
                  label: t('email'),
                  id: 'email',
                  value: 'email',
                },
                {
                  label: t('gender'),
                  id: 'gender',
                  value: 'gender',
                },
                {
                  label: t('facePicture'),
                  id: 'headPortrait',
                  value: 'headPortrait',
                },
                {
                  label: t('mobilePhone'),
                  id: 'mobilePhone',
                  value: 'mobilePhone',
                },
                {
                  label: t('licensePlate'),
                  id: 'carPlate',
                  value: 'carPlate',
                },
                {
                  label: t('birthday'),
                  id: 'birthday',
                  value: 'birthday',
                },
                {
                  label: t('cardNumber'),
                  id: 'cardNo',
                  value: 'cardNo',
                },
              ]}
              value={value?.permissions}
              onChangeFunction={onChange}
            />
          </div>
          <div className="item text-danger">
            <div>Note:</div>
            <div>
              After enabling the personal sensitive information security protection option, the
              sensitive personal data involved in this module will be desensitized or obscured,
              including but not limited to names, card numbers, ID numbers, photos, etc.
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default PersonnelSensitiveInformationProtection;
