import React, { useEffect, useRef } from 'react';
import Cookies from 'universal-cookie';

// Use common modules
import { useNavigate } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../redux/store';

// Use redux modules
import { closeNotification } from '../../redux/slices/NotificationSlices';

// Use assets svg icons
import { CloseIcon } from '../../assets/svgicons/svgicon';
import { removeLocalStorageItem } from '../../utils/helpers';

function Notification() {
  const dispatch = useAppDispatch();
  const ref = useRef<HTMLDivElement>(null);
  const navigate = useNavigate();
  const cookies = new Cookies();
  const { message, status, type, code, position } = useAppSelector(
    (state: any) => state.notification,
  );

  const handleClickOutside = () => {
    // Check if the click is outside the notification element
    dispatch(closeNotification()); // Close the notification when clicking outside
  };

  useEffect(() => {
    // Attach the event listener when the component mounts
    document.addEventListener('mousedown', handleClickOutside);

    // Cleanup the event listener when the component unmounts
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  useEffect(() => {
    if (status) {
      setTimeout(() => {
        dispatch(closeNotification());
      }, 3500);
    }
    if (code === 403) {
      navigate('/login');
      cookies.remove('token', { path: '/' });
      cookies.remove('username', { path: '/' });
      cookies.remove('userId', { path: '/' });
      removeLocalStorageItem('isSuperuser');
      removeLocalStorageItem('systemCode');
    }
  }, [message]);
  return (
    <div
      className={`notification ${status ? 'active' : ''} ${position === 'bottom' ? 'bottom' : ''}`}
      ref={ref}>
      <div className={`notification-content ${type}`}>
        <div className="notification-message">{message}</div>
        <div
          className="notification-close"
          onClick={() => {
            dispatch(closeNotification());
          }}
          role="presentation">
          <CloseIcon color="#f00" />
        </div>
      </div>
    </div>
  );
}

export default Notification;
