import {
  BulletListIcon,
  ClearAdministrationPermissionIcon,
  DisableIcon,
  EnableIcon,
  ExtendedParametersIcon,
  GetPersonalInformationIcon,
  ModifyFingerprintThresholdIcon,
  RebootDeviceIcon,
  registrationDeviceIcon,
  ReplaceDeviceIcon,
  setDeviceTimeZoneIcon,
  SynchronizeAllDevicesIcon,
  SynchronizeTimeIcon,
  TransactionIcon,
  UpgradeFirmwareIcon,
  VerificationOptionsIcon,
  VideoIntercomServerIcon,
  ViewDeviceCapacityIcon,
  ViewRulesIcon,
  ModifyIPAddressIcon,
  ModifyCommunicationIcon,
  SwitchNetworkConnectionIcon,
  ClearCommandIcon,
} from '../../assets/svgicons/svgicon';
import { LoadMenusProps } from '../../interface/auth.interface';
import { OptionType } from '../../interface/common.interface';

import { checkPermission } from '../../utils/role-permission';

const controlOptions = (permissions: LoadMenusProps[]) => {
  const controlOptionsBase: OptionType[] = [
    {
      value: 'clearAdminPermission',
      label: 'clearAdminPermission',
      icon: ClearAdministrationPermissionIcon,
      enable: checkPermission('acc:device:clearAdministrator', permissions),
    },
    {
      value: 'clearCmdCache',
      label: 'clearCommand',
      icon: ClearCommandIcon,
      enable: checkPermission('acc:device:clearCmdCache', permissions),
    },
    {
      value: 'upgradeFirmware',
      label: 'upgradeFirmware',
      icon: UpgradeFirmwareIcon,
      enable: checkPermission('acc:device:upgradeFirmware', permissions),
    },
    {
      value: 'rebootDevice',
      label: 'rebootDevice',
      icon: RebootDeviceIcon,
      enable: checkPermission('acc:device:rebootDevice', permissions),
    },
    {
      value: 'synchronizeTime',
      label: 'synchronizeTime',
      icon: SynchronizeTimeIcon,
      enable: checkPermission('acc:device:syncTime', permissions),
    },
    {
      value: 'enable',
      label: 'enable',
      icon: EnableIcon,
      enable: checkPermission('acc:device:enable', permissions),
    },
    {
      value: 'disable',
      label: 'disable',
      icon: DisableIcon,
      enable: checkPermission('acc:device:disable', permissions),
    },
    {
      value: 'synchronizeAllDevices',
      label: 'synchronizeAllDevices',
      icon: SynchronizeAllDevicesIcon,
      enable: checkPermission('acc:device:syncAllData', permissions),
    },
  ];
  return controlOptionsBase?.filter((control) => control.enable);
};

const setupOptions = (permissions: LoadMenusProps[]) => {
  const setupOptionBase: OptionType[] = [
    {
      value: 'verificationOptions',
      label: 'Set Bg - Verification Options',
      icon: VerificationOptionsIcon,
      enable: checkPermission('acc:device:issueBGVerifyParam', permissions),
    },
    {
      value: 'setDeviceTimeZone',
      label: 'Set Device, Time Zone',
      icon: setDeviceTimeZoneIcon,
      enable: checkPermission('acc:device:setTimeZone', permissions),
    },
    {
      value: 'registrationDevice',
      label: 'Set as Registration Device',
      icon: registrationDeviceIcon,
      enable: checkPermission('acc:device:setRegistrationDevice', permissions),
    },
    {
      value: 'modifyFingerprintThreshold',
      label: 'Modify the Fingerprint Identification Threshold',
      icon: ModifyFingerprintThresholdIcon,
      enable: checkPermission('acc:device:updateMThreshold', permissions),
    },
    {
      value: 'extendedParameters',
      label: 'Set Extended Parameters',
      icon: ExtendedParametersIcon,
      enable: checkPermission('acc:device:updateDevExtendParam', permissions),
    },
    {
      value: 'videoIntercomServer',
      label: 'Set Video Intercom Server',
      icon: VideoIntercomServerIcon,
      enable: checkPermission('acc:device:setIntercomServer', permissions),
    },
    {
      value: 'replaceDevice',
      label: 'Replace Device',
      icon: ReplaceDeviceIcon,
      enable: checkPermission('acc:device:replace', permissions),
    },
  ];
  return setupOptionBase?.filter((control) => control.enable);
};

const viewGetOptions = (permissions: LoadMenusProps[]) => {
  const viewGetOptionBase: OptionType[] = [
    {
      value: 'getDeviceOption',
      label: 'getDeviceOption',
      icon: BulletListIcon,
      enable: checkPermission('acc:device:getOptFromDev', permissions),
    },
    {
      value: 'getPersonnelInformation',
      label: 'getPersonnelInformation',
      icon: GetPersonalInformationIcon,
      enable: checkPermission('acc:device:uploadPersonInfo', permissions),
    },
    {
      value: 'getTransactions',
      label: 'getTransactions',
      icon: TransactionIcon,
      enable: checkPermission('acc:device:uploadTransaction', permissions),
    },
    {
      value: 'viewRulesOfDevices',
      label: 'viewRulesOfDevices',
      icon: ViewRulesIcon,
      enable: checkPermission('acc:device:queryDevRule', permissions),
    },
    {
      value: 'viewDeviceCapacity',
      label: 'viewDeviceCapacity',
      icon: ViewDeviceCapacityIcon,
      enable: checkPermission('acc:device:queryDevUsage', permissions),
    },
  ];
  return viewGetOptionBase?.filter((control) => control.enable);
};

const CommunicationOptions = (permissions: LoadMenusProps[]) => {
  const CommunicationOptionBase: OptionType[] = [
    {
      value: 'modifyIpAddress',
      label: 'modifyIpAddress',
      icon: ModifyIPAddressIcon,
      enable: checkPermission('acc:device:updateIpAddr', permissions),
    },
    {
      value: 'modifyCommunicationPassword',
      label: 'modifyCommunicationPassword',
      icon: ModifyCommunicationIcon,
      enable: checkPermission('acc:device:updateCommPwd', permissions),
    },
    {
      value: 'switchNetworkConnection',
      label: 'switchNetworkConnection',
      icon: SwitchNetworkConnectionIcon,
      enable: checkPermission('acc:device:updateNetConnectMode', permissions),
    },
  ];
  return CommunicationOptionBase?.filter((control) => control.enable);
};

export { controlOptions, setupOptions, viewGetOptions, CommunicationOptions };
