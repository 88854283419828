import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from '../../redux/store';
import auxiliaryInputApi from '../../utils/api/Devices/auxiliaryInput';

// Import shared components
import TopHeader from '../../shared-components/top-header/top-header';
import CardBox from '../../shared-components/card/card';
import DataTable from '../../shared-components/data-table/data-table';
import ModalBox from '../../shared-components/modal-box/modal-box';
import EditReader from './components/edit-auxiliary';
import { setNotificationError } from '../../redux/slices/NotificationSlices';
import BindingUnbindingCameraModal from './components/binding-unbinding';
import { fetchAuxiliaryInput, setSearchFilter } from '../../redux/slices/AuxiliaryInputSlice';
import { checkPermission } from '../../utils/role-permission';

function AuxiliaryInput() {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { auxiliaryInput, status, totalDataCount, searchFilter } = useAppSelector(
    (state: any) => state.auxiliaryInput,
  );
  const { loadMenus } = useAppSelector((state) => state.auth);

  let handleEditOpen: any;
  const canEdit = checkPermission('acc:auxIn:edit', loadMenus);
  const columnsField = [
    {
      accessorKey: 'name',
      header: t('name'),
      Cell: ({ row }: any) => (
        <div
          className={canEdit ? 'list-hyperlink' : ''}
          role="presentation"
          onClick={(event) => {
            if (!canEdit) return;
            event.stopPropagation();
            handleEditOpen(row.original);
          }}>
          {row?.original?.name}
        </div>
      ),
    },
    {
      accessorKey: 'devAlias',
      header: t('deviceName'),
    },
    {
      accessorKey: 'auxNo',
      header: t('number'),
    },
    {
      accessorKey: 'printerNumber',
      header: t('printedName'),
    },
    {
      accessorKey: 'boundCamera',
      header: t('boundCamera'),
    },
    {
      accessorKey: 'owningBoard',
      header: t('owningBoard'),
    },
    {
      accessorKey: 'remarks',
      header: t('remarks'),
    },
  ];

  const [modalOpen, setModalOpen] = useState(false);
  const [modalView, setModalView] = useState({
    title: '',
    content: <>hi</>,
  });

  const fetchData = async (params: any) => {
    if (status === 'success') {
      dispatch(fetchAuxiliaryInput({ ...params }));
    }
  };
  handleEditOpen = (editData: any) => {
    if (!modalOpen) {
      setModalOpen(true);
      setModalView({
        title: t('edit'),
        content: (
          <EditReader
            initialData={{
              deviceName: editData.devAlias,
              number: editData.auxNo,
              name: editData.name,
              printedName: editData.printerNumber,
            }}
            closeModal={() => setModalOpen(false)}
            saveChanges={async () => {
              const req = {
                name: editData.name,
                devAlias: editData.devAlias,
                auxNo: editData.auxNo,
                printerNumber: editData.printerNumber,
                boundCamera: editData.boundCamera,
                owningBoard: editData.owningBoard,
                remarks: editData.remarks,
                id: editData.id,
              };
              try {
                const response = await auxiliaryInputApi.saveAuxIn(req);
                if (response.data.success) {
                  dispatch(
                    setNotificationError({
                      error: t('auxiliaryInputUpdatedSuccessfully'),
                      status: 200,
                    }),
                  );
                  setModalOpen(false);
                } else {
                  dispatch(
                    setNotificationError({
                      error: response.data.msg,
                      status: response.data.ret,
                    }),
                  );
                }
              } catch (error) {
                dispatch(
                  setNotificationError({
                    error: t('failedToSaveChanges'),
                    status: 500,
                  }),
                );
              }
            }}
          />
        ),
      });
    }
  };

  const actionButtons = [
    {
      label: t('edit'),
      enable: checkPermission('acc:auxIn:edit', loadMenus),
      function: (val: any) => handleEditOpen(val),
    },
    {
      label: t('binding/unbindingcamera'),
      enable: checkPermission('acc:auxIn:bindChannel', loadMenus),
      function: () => {
        if (!modalOpen) {
          setModalOpen(true);
          setModalView({
            title: t('binding/unbindingcamera'),
            content: (
              <BindingUnbindingCameraModal
                closeModal={() => setModalOpen(false)}
                onConfirm={() => console.log('OK clicked')}
              />
            ),
          });
        }
      },
    },
  ];

  const getFilterData = (filter: any): any => {
    if (filter.pageIndex > 0) {
      dispatch(setSearchFilter(filter));
      fetchData({ pageNo: filter.pageIndex, pageSize: filter.pageSize, ...filter.filter });
    }
  };
  return (
    <div className="department">
      <ModalBox
        status={modalOpen}
        closeModal={() => setModalOpen(false)}
        title={modalView.title}
        content={modalView.content}
      />
      <TopHeader
        title={t('access')}
        broadCram={[
          {
            title: t('accessDevice'),
          },
          {
            title: t('auxiliaryInput'),
          },
        ]}
      />
      <CardBox title={t('listOfauxiliaryInput')}>
        <DataTable
          actionButtonStatus={!modalOpen} // Disable action buttons when modal is open
          action={actionButtons}
          columns={columnsField}
          getFilterData={getFilterData}
          assignFilterValue={searchFilter}
          checkBoxActive={false}
          enablePagination
          isFetching={false} // Placeholder status
          values={auxiliaryInput} // Use the actual data here
          fixedColumn={['readerName']}
          totalRecords={totalDataCount}
          height={411}
        />
      </CardBox>
    </div>
  );
}

export default AuxiliaryInput;
