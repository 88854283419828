import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from '../../../../redux/store';

// Use shared components
import TopHeader from '../../../../shared-components/top-header/top-header';
import CardBox from '../../../../shared-components/card/card';
import DataTable from '../../../../shared-components/data-table/data-table';
import CardRightButtonGroup from '../../../../shared-components/card-right-button-group/card-right-button-group';

// Import slice actions
import {
  fetchSystemParameter,
  setSearchFilter,
} from '../../../../redux/slices/system-management/SystemParameterSlices';
import { FilterProps } from '../../../../interface/common.interface';

function SystemManagementParameter() {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { systemList, status, totalDataCount, searchFilter, currentPageNo } = useAppSelector(
    (state: any) => state.systemParameter,
  );
  const columns = [
    {
      accessorKey: 'createTime',
      header: t('createTime'),
    },
    {
      accessorKey: 'paramName',
      header: t('parameterName'),
    },
    {
      accessorKey: 'paramValue',
      header: t('parameterValue'),
    },
    {
      accessorKey: 'description',
      header: t('description'),
    },
  ];

  const fetchData = async (params: FilterProps) => {
    if (status === 'success') {
      dispatch(fetchSystemParameter({ ...params }));
    }
  };

  const getFilterData = (filter: any): any => {
    if (currentPageNo > 0) {
      dispatch(setSearchFilter(filter));
      fetchData({ pageNo: 1, ...filter.filter });
    }
  };

  const fetchNextData = (): any => {
    fetchData({ pageNo: currentPageNo + 1, ...searchFilter.filter });
  };

  useEffect(() => {
    if (systemList.length === 0) {
      fetchData({ pageNo: 1 });
    }
  }, []);

  return (
    <div className="card-management">
      <TopHeader
        title={t('system')}
        broadCram={[
          {
            title: t('systemManagement'),
          },
          {
            title: t('systemParameter'),
          },
        ]}
      />
      <CardBox
        header=""
        title={t('listOfParameter')}
        rightSide={
          <CardRightButtonGroup
            buttonIcon={false}
            buttonLabel=""
            buttonClass=""
            buttonStatus={false}
          />
        }>
        <DataTable
          actionButtonStatus={false}
          columns={columns}
          getFilterData={getFilterData}
          assignFilterValue={searchFilter}
          checkBoxActive={false}
          fetchNextPage={fetchNextData}
          isFetching={status === 'loading'}
          values={systemList}
          fixedColumn={['eventTime']}
          totalRecords={totalDataCount}
          height={411}
        />
      </CardBox>
    </div>
  );
}

export default SystemManagementParameter;
