import React, { useEffect, useState } from 'react';

// Use common modules
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from '../../../../redux/store';

// Use own component
import AdmsServiceSettings from './components/adms-service-settings';
import ServerSideNetworkCondition from './components/server-side-network';
import communicationMonitorApi from '../../../../utils/api/SystemManagement/communicationMonitor';
// Use shared components
import TopHeader from '../../../../shared-components/top-header/top-header';
import CardBox from '../../../../shared-components/card/card';
import TabPages from '../../../../shared-components/tab-pages/tab-pages';
import Button from '../../../../shared-components/button/button';
import {
  fetchCommunicationMonitor,
  updateCommunicationMonitor,
} from '../../../../redux/slices/system-management/CommunicationMonitorSlices';

// Use interface
import { setNotificationError } from '../../../../redux/slices/NotificationSlices';

interface CommunicationMonitorProps {
  'adms.serverPort': number;
  'adms.encrypted.transmission': number;
  'adms.linkIntenet': string;
}

interface CommunicationReduxInterface {
  communicationMonitor: {
    admsParams?: CommunicationMonitorProps;
  };
  status: string;
}
function CommunicationMonitor() {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  let sortTime = true;
  const { communicationMonitor, status }: CommunicationReduxInterface = useAppSelector(
    (state: any) => state.communicationMonitor,
  );

  const [monitorSettings, setMonitorSettings] = useState<CommunicationMonitorProps>({
    'adms.serverPort': 8088,
    'adms.encrypted.transmission': 0,
    'adms.linkIntenet': 'No',
  });

  const onChangeValue = (name: any, changeValue: any) => {
    setMonitorSettings((prev: any) => ({
      ...prev,
      [name]: changeValue,
    }));
  };

  const [tabs, setTabs] = useState([
    {
      key: 'qrCodeSetting',
      title: t('qrCodeSetting'),
      content: <AdmsServiceSettings onChangeFunction={onChangeValue} values={monitorSettings} />,
      enable: true,
    },
  ]);
  const fetchParameterData = () => {
    if (status === 'success') {
      dispatch(fetchCommunicationMonitor({}));
    }
  };

  const submit = async () => {
    // Create a new FormData object
    const formData = new FormData();

    // Append the parameters to the FormData
    formData.append('adms.serverPort', String(monitorSettings['adms.serverPort']));
    formData.append(
      'adms.encrypted.transmission',
      String(monitorSettings['adms.encrypted.transmission'] || 1),
    );
    formData.append('adms.linkIntenet', monitorSettings['adms.linkIntenet']);

    try {
      const response: any = await communicationMonitorApi.saveList(formData);

      if (response.data.success) {
        dispatch(
          setNotificationError({
            error: t('communicationMonitorAddedSuccessfully'),
            status: 200,
          }),
        );
        dispatch(updateCommunicationMonitor(monitorSettings));
      } else {
        dispatch(
          setNotificationError({
            error: response.data.msg,
            status: response.data.code,
          }),
        );
      }
    } catch (error: any) {
      dispatch(
        setNotificationError({
          error: error.message || 'An unexpected error occurred',
          status: error.status || 500,
        }),
      );
    }
  };
  useEffect(() => {
    if (communicationMonitor && Object.keys(communicationMonitor).length === 0 && sortTime) {
      fetchParameterData();
      sortTime = false;
    } else if (communicationMonitor && communicationMonitor.admsParams) {
      setMonitorSettings(communicationMonitor.admsParams);
    }
  }, [communicationMonitor]);

  useEffect(() => {
    if (monitorSettings && Object.keys(monitorSettings).length > 0) {
      setTabs([
        {
          key: 'admsServiceSettings',
          title: t('admsServiceSettings'),
          content: (
            <AdmsServiceSettings onChangeFunction={onChangeValue} values={monitorSettings} />
          ),
          enable: true,
        },
        {
          key: 'serverSideNetworkCondition',
          title: t('serverSideNetworkCondition'),
          content: (
            <ServerSideNetworkCondition onChangeFunction={onChangeValue} values={monitorSettings} />
          ),
          enable: true,
        },
      ]);
    }
  }, [monitorSettings]);

  return (
    <div className="parameters">
      <TopHeader
        title={t('system')}
        broadCram={[{ title: t('communicationManagement') }, { title: t('communicationMonitor') }]}
      />
      <CardBox title={t('communicationMonitor')}>
        <TabPages tabs={tabs} defaultNumber={0} />

        <div className="parameters-buttons">
          <div className="form-buttons-right">
            <Button onClickFunction={submit} title={t('done')} className="btn-primary btn-bold" />
          </div>
        </div>
      </CardBox>
    </div>
  );
}

export default CommunicationMonitor;
