import axios, { defaultParams } from './axios';

export default async (url: string, params: any, responseType: 'json' | 'blob' = 'json') => {
  try {
    return await axios.get(url, { ...defaultParams(), params, responseType });
  } catch (e) {
    console.log(e);
    throw e;
  }
};
