import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from '../../../redux/store';

// Use shared components
import TopHeader from '../../../shared-components/top-header/top-header';
import CardBox from '../../../shared-components/card/card';
import DataTable from '../../../shared-components/data-table/data-table';
import CardRightButtonGroup from '../../../shared-components/card-right-button-group/card-right-button-group';
import ModalBox from '../../../shared-components/modal-box/modal-box';
// Import slice actions
import { fetchAlarmLogList, setSearchFilter } from '../../../redux/slices/AlarmLogReportSlices';
import { DepartmentParamsProps } from '../../../interface/accessByDepartment.interface';
import { changeDateTimeFormat } from '../../../utils/helpers';
import Acknowledge from './components/acknowledge';
import ExportData from './components/export-data';
import ConformationBox from '../../../shared-components/conformation-box/conformation-box';
import { checkPermission } from '../../../utils/role-permission';

function AlarmLog() {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { logList, status, totalDataCount, searchFilter } = useAppSelector(
    (state: any) => state.alarmLogs,
  );

  /**
   * Selects the `loadMenus` permission from the authentication slice of the Redux store.
   *
   * @returns {Object} loadMenus - The user's menu permissions.
   */
  const { loadMenus } = useAppSelector((state) => state.auth);

  const [modalOpen, setModalOpen] = useState(false);
  const [modalView, setModalView] = useState({
    title: 'hi',
    content: <>hi</>,
  });
  const [selectedValues, setSelectedValues] = useState<string[]>([]);
  const columns = [
    {
      accessorKey: 'eventTime',
      header: t('time'),
      size: 180,
      Cell: ({ row }: any) => <div>{changeDateTimeFormat(row.original.eventTime)}</div>,
    },
    {
      accessorKey: 'devAlias',
      header: t('deviceName'),
      size: 180,
    },
    {
      accessorKey: 'eventPointName',
      header: t('eventPoint'),
      size: 150,
    },
    {
      accessorKey: 'eventName',
      header: t('eventDescription'),
      size: 220,
    },
    {
      accessorKey: 'persPerson',
      header: t('person'),
      size: 140,
    },
    {
      accessorKey: 'belongAreas',
      header: t('belongAreas'),
      size: 140,
    },
    {
      accessorKey: 'priority',
      header: t('priority'),
      size: 140,
      Cell: ({ row }: any) => {
        let result;
        if (row.original.priority === 0) {
          result = <div className="text-danger">Weak</div>;
        } else if (row.original.priority === 1) {
          result = <div>Medium</div>;
        } else if (row.original.priority === 2) {
          result = <div className="text-success">Strong</div>;
        } else if (row.original.priority === 3) {
          result = <div className="text-warning">Danger</div>;
        }
        return result;
      },
    },
    {
      accessorKey: 'status',
      header: t('status'),
      size: 140,
      Cell: ({ row }: any) => {
        let result;
        if (row.original.status === 0) {
          result = <div className="text-danger">Unconfirmed</div>;
        } else if (row.original.status === 1) {
          result = <div className="text-processing">Processing</div>;
        } else if (row.original.status === 2) {
          result = <div className="text-success">Confirmed</div>;
        }
        return result;
      },
    },
  ];

  const fetchData = async (params: DepartmentParamsProps) => {
    if (status === 'success') {
      dispatch(fetchAlarmLogList({ ...params }));
    }
  };

  const getFilterData = (filter: any): any => {
    if (filter.pageIndex > 0) {
      dispatch(setSearchFilter(filter));
      fetchData({ pageNo: filter.pageIndex, pageSize: filter.pageSize, ...filter.filter });
    }
  };

  const exportData = () => {
    setModalOpen(false);
  };

  const moreActionButton = [
    {
      title: t('export'),
      enable: checkPermission('acc:alarmReport:export', loadMenus),
      clickFunction: (): void => {
        setModalView({
          title: t('export'),
          content: (
            <ExportData closeModal={() => setModalOpen(false)} submitFunction={exportData} />
          ),
        });
        setModalOpen(true);
      },
    },
    {
      title: t('acknowledge'),
      enable: true,
      clickFunction: (): void => {
        if (selectedValues.length === 0) {
          setModalView({
            title: t('selectObject'),
            content: (
              <ConformationBox
                closeModal={() => setModalOpen(false)}
                okFunction={() => setModalOpen(false)}
                title={t('pleaseSelectTheObjectsYouWantToOperate')}
                buttonLabel={t('ok')}
              />
            ),
          });
          setModalOpen(true);
        } else if (selectedValues.length === 1) {
          setModalView({
            title: t('acknowledge'),
            content: (
              <Acknowledge closeModal={() => setModalOpen(false)} selectedId={selectedValues} />
            ),
          });
          setModalOpen(true);
        } else {
          setModalView({
            title: t('selectObject'),
            content: (
              <ConformationBox
                closeModal={() => setModalOpen(false)}
                okFunction={() => setModalOpen(false)}
                title={t('Only one object can be selected at one time!')}
                buttonLabel={t('ok')}
              />
            ),
          });
          setModalOpen(true);
        }
      },
    },
  ];

  return (
    <div className="card-management">
      <ModalBox
        status={modalOpen}
        closeModal={() => setModalOpen(false)}
        title={modalView.title}
        content={modalView.content}
      />
      <TopHeader
        title={t('access')}
        broadCram={[
          {
            title: t('accControlReports'),
          },
          {
            title: t('alarmLog'),
          },
        ]}
      />
      <CardBox
        header=""
        title={t('listOfAlarm')}
        rightSide={
          <CardRightButtonGroup
            actions={moreActionButton}
            buttonIcon={false}
            buttonLabel=""
            buttonClass=""
            buttonStatus={false}
          />
        }>
        <DataTable
          actionButtonStatus={false}
          columns={columns}
          getFilterData={getFilterData}
          assignFilterValue={searchFilter}
          assignSelectedData={selectedValues}
          getSelectedData={(val: any): any => setSelectedValues(val)}
          checkBoxActive
          enablePagination
          isFetching={status === 'loading'}
          values={logList}
          fixedColumn={['eventTime']}
          totalRecords={totalDataCount}
          height={411}
        />
      </CardBox>
    </div>
  );
}

export default AlarmLog;
