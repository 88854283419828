/* eslint-disable import/prefer-default-export */
import { RouteInterface } from '../interface/common.interface';
import { extractPermissions } from './helpers';

/**
 * Recursively filters routes based on user permissions.
 *
 * @param {RouteInterface[]} routes - The array of routes to filter.
 * @param {Set<string> | string[]} permission - The set of user permissions.
 * @returns {RouteInterface[]} An array of filtered routes that match the user's permissions.
 */
export const filterRoutes = (
  routes: RouteInterface[],
  permission: Set<string> | string[],
): RouteInterface[] => {
  const permissionSet = new Set(permission); // Ensure it's a Set
  return routes
    .map((route) => {
      // Recursively filter children
      const filteredChildren = route.children ? filterRoutes(route.children, permissionSet) : [];

      // Check if current route is valid
      const isRouteAllowed =
        permissionSet.has(route.permissionKey || '') || filteredChildren.length > 0;

      if (isRouteAllowed) {
        return {
          ...route,
          ...(filteredChildren.length > 0 ? { children: filteredChildren } : {}), // Only add children if non-empty
        };
      }

      return null; // Remove non-matching routes
    })
    .filter(Boolean) as RouteInterface[];
};

/**
 * Checks if a given permission key exists in the extracted permissions.
 *
 * @param {string} permissionKey - The key representing the permission to check.
 * @param {any[]} permissions - The raw permissions data from which valid permissions are extracted.
 * @returns {boolean} - Returns `true` if the permission key is found, otherwise `false`.
 */
export function checkPermission(permissionKey: string, permissions: any[]): boolean {
  const validPermission: string[] = extractPermissions(permissions);
  return validPermission.includes(permissionKey);
}
