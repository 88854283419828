import { get, post } from '../base/index';

export default {
  saveList: (params: any = {}) => {
    return post(`${process.env.REACT_APP_API_URL}Sys/api/admsParams/save`, params);
  },

  admsParams: () => {
    return get(`${process.env.REACT_APP_API_URL}Sys/api/admsParams/index`, {});
  },
};
