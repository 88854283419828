import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useAppDispatch } from '../../../../redux/store';
import InputField from '../../../../shared-components/input-field/input-field';
import Button from '../../../../shared-components/button/button';
import ModalBox from '../../../../shared-components/modal-box/modal-box';
import SelectGlobalLinkage from './selectGlobalLinkage';
import LinkageGlobalTriggerConnections from './linkageGlobalTriggerConnections';
import CheckBoxInputField from '../../../../shared-components/checkbox-input-field/checkbox-input-field';
import TabPages from '../../../../shared-components/tab-pages/tab-pages';
import GlobalOutputPoint from './globalOutputPoint';
import GlobalVideoLinkage from './globalVideoLinkage';
import GlobalActiveTime from './globalActiveTime';
import GlobalEmailLinkage from './globalEmailLinkage';
import GlobalIntrusion from './globalIntrusion';
import GlobalLinkageVoicePrompts from './globalLinkageVoicePrompts';
import GlobalSendSms from './globalSendSms';
import Label from '../../../../shared-components/label/Label';
import GlobalLine from './globalLine';
import GlobalWhatsapp from './globalWhatsapp';
import GlobalMusterPoint from './globalMusterpoint';
import { setNotificationError } from '../../../../redux/slices/NotificationSlices';
import { DeviceProps } from '../../../../interface/accessByDepartment.interface';
import { fetchGlobalLinkage } from '../../../../redux/slices/GlobalLinkageSlices';
import globalLinkage from '../../../../utils/api/AdvancedFunctions/globalLinkage';

interface LinkageProps {
  closeModal: () => void;
  data?: any;
}
interface DoorEventProps {
  id: string;
  text: string;
}
function AddLinkage({ closeModal, data }: LinkageProps) {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const [value, setValue] = useState(
    data || {
      id: '',
      name: '',
      doorId: '',
      trigger: [],
      triggerCondName: '',
      selectedDevice: [],
      applyToAllPersonnel: [],
      inputPoint: [],
      outputAuxOutAddr: [],
      doorOutput: [],
      auxoutActionType: '',
      mailAddr: '',
      mobileNo: '',
      partitionActionType: '',
      partitionArmType: '',
      whatsappMobileNo: '',
      doorActionTime: '',
      auxoutActionTime: '',
      doorActionType: '',
      musterPointId: '',
      lineContactId: '',
      iasPartitionIds: '',
      baseMediaFileName: '',
      baseMediaFilePath: '',
    },
  );
  const [modalOpen, setModalOpen] = useState(false);
  const [modalView, setModalView] = useState({
    title: '',
    content: <>hi</>,
  });
  const [disable, setDisable] = useState(false);
  const [selectedDevices, setSelectedDevices] = useState<any[]>([]);
  const [triggerConditions, setTriggerConditions] = useState<any[]>([]);

  const [tabs, setTabs] = useState([
    {
      key: 'outputPoint',
      title: t('globalOutputPoint'),
      content: <GlobalOutputPoint data={value} />,
      enable: true,
    },
  ]);

  const [search, setSearch] = useState('');
  const onChangeValue = (name: string, datas: any) => {
    setValue((prev: any) => ({
      ...prev,
      [name]: datas,
    }));
  };
  const tableColumns = [
    { label: t('doorName'), id: 'doorName' },
    { label: t('ownedDevice'), id: 'deviceAlias' },
    { label: t('serialNumber'), id: 'deviceSn' },
  ];
  const changeSearch = (name: string, val: any) => {
    setSearch(val);
  };
  const getSelectedDevice = (selected: DeviceProps[]) => {
    const deviceValue = selected.map((item: any) => ({
      label: item.doorName,
      id: item.id,
      value: item.id,
    }));
    setSelectedDevices(deviceValue);
    setValue((prev: any) => ({
      ...prev,
      selectedDevice: deviceValue.map((item: any) => item.id),
    }));
  };
  const getInOut = (params: string[]) => {
    const request = {
      triggerNos: params.toString(),
    };
    globalLinkage
      .getInputInfo(request)
      .then(() => {})
      .catch((error) => {
        dispatch(
          setNotificationError({
            error: error.message,
            status: error.response?.status || 500,
          }),
        );
      });
  };
  const handleClickDevice = () => {
    if (!value.trigger || value.trigger.length === 0) {
      dispatch(
        setNotificationError({
          error: t('pleaseSelectLinkageTriggerConditionsFirst'),
          status: 400,
        }),
      );
      return;
    }
    setModalView({
      title: t('selectDoor'),
      content: (
        <SelectGlobalLinkage
          searchValue={search}
          changeSearch={changeSearch}
          tableColumns={tableColumns}
          getSelected={(selected: DeviceProps[]) => getSelectedDevice(selected)}
          closeModal={() => setModalOpen(false)}
          initialValues={value?.selectedDevice}
        />
      ),
    });
    setModalOpen(true);
  };

  const addTriggerCondition = (val: DoorEventProps[]) => {
    const triggerValue = val.map((item) => ({
      label: item.text,
      id: item.id,
      value: item.id,
    }));
    setTriggerConditions(triggerValue);
    const getId = val.map((ids) => ids.id);
    getInOut(getId);
    setValue((prev: any) => ({
      ...prev,
      trigger: triggerValue.map((item) => item.id),
    }));
  };
  useEffect(() => {
    setTabs([
      {
        key: 'outputPoint',
        title: t('outputPoint'),
        content: <GlobalOutputPoint data={value} />,
        enable: true,
      },
      {
        key: 'videoLinkage',
        title: t('videoLinkage'),
        content: <GlobalVideoLinkage />,
        enable: true,
      },
      {
        key: 'activeTime',
        title: t('activeTime'),
        content: <GlobalActiveTime />,
        enable: true,
      },
      {
        key: 'E-mail',
        title: t('E-mail'),
        content: <GlobalEmailLinkage />,
        enable: true,
      },
      {
        key: 'intrusion',
        title: t('intrusion'),
        content: <GlobalIntrusion />,
        enable: true,
      },
      {
        key: 'linkageVoicePrompts',
        title: t('linkageVoicePrompts'),
        content: <GlobalLinkageVoicePrompts />,
        enable: true,
      },
      {
        key: 'sendSMS',
        title: t('sendSMS'),
        content: <GlobalSendSms />,
        enable: true,
      },
      {
        key: 'line',
        title: t('line'),
        content: <GlobalLine />,
        enable: true,
      },
      {
        key: 'whatsapp',
        title: t('whatsapp'),
        content: <GlobalWhatsapp />,
        enable: true,
      },
      {
        key: 'musterpoint',
        title: t('musterpoint'),
        content: <GlobalMusterPoint />,
        enable: true,
      },
    ]);
  }, []);
  const handleLinkageTrigger = () => {
    setModalView({
      title: t('chooseLinkageTriggerConnections'),
      content: (
        <LinkageGlobalTriggerConnections
          closeModal={() => setModalOpen(false)}
          addTriggerCondition={addTriggerCondition}
          globalLinkageId={selectedDevices?.[0]?.id || ''}
          value={value.trigger || []}
        />
      ),
    });
    setModalOpen(true);
  };

  const submit = () => {
    const { name, trigger, isApplyToAll, mailAddr, mobileNo, triggerCondName, whatsappMobileNo } =
      value;
    const request = {
      inputAddr: selectedDevices[0]?.id || '',
      inputName: selectedDevices[0]?.label || '',
      inputType: 'accDoor',
      isApplyToAll,
      name,
      opType: 'select',
      outputDoorAddr: selectedDevices[0]?.id || '',
      outputDoorName: selectedDevices[0]?.label || '',
      partitionActionType: 0,
      partitionArmType: 2,
      timeSegId: '',
      triggerCond: trigger?.join(',') || '',
      triggerCondName,
      doorActionType: 0,
      doorActionTime: 0,
      auxoutActionType: 0,
      auxoutActionTime: 0,
      recordTimes: 0,
      outputAuxOutAddr: '',
      mailAddr: mailAddr === undefined ? '' : mailAddr,
      mobileNo: mobileNo === undefined ? '' : mobileNo,
      whatsappMobileNo: whatsappMobileNo === undefined ? '' : whatsappMobileNo,
      musterPointId: '',
      lineContactId: '',
      iasPartitionIds: '',
      baseMediaFileName: '',
      baseMediaFilePath: '',
    };
    globalLinkage
      .save(request)
      .then((response) => {
        if (response?.data?.success) {
          const message = data ? t('linkageAddedSuccessfully') : t('linkageUpdatedSuccessfully');
          dispatch(
            setNotificationError({
              error: message,
              status: 200,
            }),
          );
          closeModal();
          dispatch(fetchGlobalLinkage({ pageNo: 1, pageSize: 10 }));
        } else {
          dispatch(
            setNotificationError({
              error: response.data.message,
              status: response.data.code,
            }),
          );
        }
      })
      .catch((error) => {
        dispatch(
          setNotificationError({
            error: error.message,
            status: error.status,
          }),
        );
      });
  };

  useEffect(() => {
    if (value.name) {
      setDisable(false);
    } else {
      setDisable(true);
    }
  }, [value]);

  return (
    <div className="add-linkage flex-row column">
      <ModalBox
        status={modalOpen}
        closeModal={() => setModalOpen(false)}
        title={modalView.title}
        content={modalView.content}
      />
      <div className="flex-row" style={{ alignItems: 'center' }}>
        <div className="col">
          <InputField
            name="name"
            label={t('name')}
            id="name"
            type="text"
            value={value.name}
            innerIconStatus={false}
            onChangeFunction={onChangeValue}
            required
          />
        </div>
        <CheckBoxInputField
          name="applyToAllPersonnel"
          data={[
            {
              label: t('applyToAllPersonnel'),
              id: 'yes',
              value: 'yes',
            },
          ]}
          value={value?.applyToAllPersonnel}
          onChangeFunction={onChangeValue}
        />
      </div>
      <div className="flex-row linkage-box">
        <div className="box-wrapper">
          <div className="header">
            <Label title={t('linkageTriggerConditions')} required />
            <span className="add-link" role="presentation" onClick={handleLinkageTrigger}>
              {t('add')}
            </span>
          </div>
          <div className="box">
            <CheckBoxInputField
              name="trigger"
              data={triggerConditions}
              value={value?.trigger}
              onChangeFunction={onChangeValue}
              alignment="column"
            />
          </div>
        </div>
        <div className="box-wrapper">
          <div className="header">
            <Label title={t('inputPoint')} required />
            <span className="add-link" role="presentation" onClick={handleClickDevice}>
              {t('add')}
            </span>
          </div>
          <div className="box">
            <CheckBoxInputField
              name="selectedDevice"
              data={selectedDevices}
              value={value?.selectedDevice}
              onChangeFunction={onChangeValue}
              alignment="column"
            />
          </div>
        </div>
      </div>
      <div className="linkage-types">
        <TabPages tabs={tabs} defaultNumber={0} />
      </div>
      <div className="form-buttons-right">
        <Button onClickFunction={submit} title={t('save&new')} className="btn-primary" />
        <Button
          onClickFunction={() => {
            closeModal();
          }}
          title={t('cancel')}
          className="btn-default"
        />
        <Button
          onClickFunction={submit}
          title={t('done')}
          className="btn-primary"
          disabled={disable}
        />
      </div>
    </div>
  );
}

AddLinkage.defaultProps = {
  data: [],
};

export default AddLinkage;
