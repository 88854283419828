/* eslint-disable no-param-reassign */
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { setNotificationError } from '../NotificationSlices';
import role from '../../../utils/api/SystemManagement/role';
import { changeDateTimeFormat } from '../../../utils/helpers';
import { RoleProps, RoleReduxInterface, RoleItemProps } from '../../../interface/role.interface';

const addParentReferences = (items: any, parent?: any) => {
  return items.map((item: any) => {
    const newItem = { ...item, parent: parent ? { id: parent.id, text: parent.text } : {} };

    if (item.item) {
      newItem.item = addParentReferences(item.item, newItem);
    }

    return newItem;
  });
};

/**
 * Recursively retrieves checked item IDs while ensuring correct inclusion of parents.
 *
 * **Rules:**
 * 1. All child items checked → Include child and immediate parent (but NOT grandparent).
 * 2. Some child items checked → Include child and immediate parent (but NOT grandparent).
 * 3. Only one child checked → Include only that child, no parent.
 *
 * @param {RoleItemProps[]} items - The list of nested role items.
 * @returns {string[]} - An array of checked item IDs, ensuring correct hierarchy rules.
 */
const getCheckedIds = (items: RoleItemProps[]): string[] => {
  const checkedIds: Set<string> = new Set();

  items.forEach((item) => {
    let childCheckedIds: string[] = [];

    if (item.item) {
      // Recursively retrieve checked child IDs
      childCheckedIds = getCheckedIds(item.item);
      childCheckedIds.forEach((id) => checkedIds.add(id));
    }

    // If the item itself is checked, always add it
    if (item.checked === '1') {
      checkedIds.add(item.id);
    }

    // If ALL children are checked, include only the immediate parent
    if (childCheckedIds.length > 0 && item.item && childCheckedIds.length === item.item.length) {
      checkedIds.add(item.id);
    }
    // If SOME children are checked, include only the immediate parent
    else if (childCheckedIds.length > 0) {
      checkedIds.add(item.id);
    }
  });

  return Array.from(checkedIds);
};

export const fetchRoleList = createAsyncThunk(
  'acc/fetchRoleList ',
  async (data: any, { dispatch }) => {
    const response = await role
      .getRoleList(data)
      .then((response1) => {
        return {
          ...response1.data,
          data: {
            list: response1.data.data.data.map((item: RoleProps) => ({
              ...item,
              createTime: changeDateTimeFormat(item.createTime),
            })),
          },
          size: response1.data.data.total,
          ...data,
        };
      })
      .catch((error: any) => {
        dispatch(setNotificationError(error.response.data));
        return error;
      });
    return response;
  },
);

export const fetchPersonnelRole = createAsyncThunk(
  'acc/fetchPersonnelRole',
  async (data: any, { dispatch }) => {
    const response = await role
      .getTreeList(data)
      .then((response1) => {
        return {
          ...response1.data,
          editAssignId: getCheckedIds(response1.data.data.item),
          data: addParentReferences(response1.data.data.item),
        };
      })
      .catch((error: any) => {
        dispatch(setNotificationError(error.response.data));
        return error;
      });
    return response;
  },
);

export const fetchAccessRole = createAsyncThunk(
  'acc/fetchAccessRole',
  async (data: any, { dispatch }) => {
    const response = await role
      .getTreeList(data)
      .then((response1) => {
        return {
          ...response1.data,
          editAssignId: getCheckedIds(response1.data.data.item),
          data: addParentReferences(response1.data.data.item),
        };
      })
      .catch((error: any) => {
        dispatch(setNotificationError(error.response.data));
        return error;
      });
    return response;
  },
);

export const fetchSystemRole = createAsyncThunk(
  'acc/fetchSystemRole',
  async (data: any, { dispatch }) => {
    const response = await role
      .getTreeList(data)
      .then((response1) => {
        return {
          ...response1.data,
          editAssignId: getCheckedIds(response1.data.data.item),
          data: addParentReferences(response1.data.data.item),
        };
      })
      .catch((error: any) => {
        dispatch(setNotificationError(error.response.data));
        return error;
      });
    return response;
  },
);

const initialState: RoleReduxInterface = {
  roleList: [],
  status: 'success',
  totalDataCount: 0,
  currentPageNo: 0,
  searchFilter: {},
  assignId: [],
  personnelRole: [],
  accessRole: [],
  systemRole: [],
  // editAssignId: [],
};

export const RoleSlices: any = createSlice({
  name: 'role',
  initialState,
  reducers: {
    deleteRole: (state, action: any) => {
      state.roleList = state.roleList.filter((val: any) => val.id !== action.payload);
      state.totalDataCount -= 1;
    },
    setSearchFilter: (state, action: any) => {
      state.searchFilter = action.payload;
    },
    saveAssignId: (state, action: any) => {
      state.assignId = action.payload;
    },
    /**
     *
     * @param state \- Redux state
     * this function is used to remove assignId.
     */
    removeAssignId: (state) => {
      state.assignId = [];
    },
    /**
     *
     * @param state \- Redux state
     * this function is used to remove all roles from Redux state.
     */
    removeRoles: (state) => {
      state.personnelRole = [];
      state.accessRole = [];
      state.systemRole = [];
      state.assignId = [];
      // state.editAssignId = [];
    },
  },
  extraReducers: (builder) => {
    // Add reducers for additional action types here, and handle loading state as needed
    builder
      .addCase(fetchRoleList.pending, (state = initialState) => {
        state.status = 'loading';
      })
      .addCase(fetchRoleList.fulfilled, (state: any, action: any) => {
        if (action.payload.success) {
          state.roleList = action.payload.data.list;
          state.totalDataCount = action.payload.size;
        }
        state.status = 'success';
      })
      .addCase(fetchRoleList.rejected, (state: any) => {
        state.status = 'failed';
      })
      .addCase(fetchPersonnelRole.fulfilled, (state: any, action: any) => {
        if (action.payload.success) {
          state.personnelRole = action.payload.data;
          if (action.payload.editAssignId) {
            state.assignId = [...state.assignId, ...action.payload.editAssignId];
          } else {
            state.assignId = action.payload.editAssignId;
          }
        }
      })
      .addCase(fetchAccessRole.fulfilled, (state: any, action: any) => {
        if (action.payload.success) {
          state.accessRole = action.payload.data;
          if (action.payload.editAssignId) {
            state.assignId = [...state.assignId, ...action.payload.editAssignId];
          } else {
            state.assignId = action.payload.editAssignId;
          }
        }
      })
      .addCase(fetchSystemRole.fulfilled, (state: any, action: any) => {
        if (action.payload.success) {
          state.systemRole = action.payload.data;
          if (action.payload.editAssignId) {
            state.assignId = [...state.assignId, ...action.payload.editAssignId];
          } else {
            state.assignId = action.payload.editAssignId;
          }
        }
      });
  },
});

// Action creators are generated for each case reducer function
export const { setSearchFilter, deleteRole, saveAssignId, removeRoles, removeAssignId } =
  RoleSlices.actions;

export default RoleSlices.reducer;
