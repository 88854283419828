import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from '../../redux/store';

// Use shared components
import TopHeader from '../../shared-components/top-header/top-header';
import CardBox from '../../shared-components/card/card';
import DataTable from '../../shared-components/data-table/data-table';
import ModalBox from '../../shared-components/modal-box/modal-box';
// Use assets svg icons

import DeleteModalBox from '../../shared-components/delete-modal-box/delete-modal-box';
// Import card slice actions
import CardRightButtonGroup from '../../shared-components/card-right-button-group/card-right-button-group';
import { AddIcon, DeleteButtonIcon } from '../../assets/svgicons/svgicon';
import AddVerificationMode from './components/AddVerificationMode';
import {
  deleteVerifyRule,
  fetchDoorList,
  fetchVerificationMode,
  setDoorSearchFilter,
  setSearchFilter,
  deleteDoor as verifyDeleteDoor,
} from '../../redux/slices/VerificationModeSlices';
import { transformVerifyData } from '../../utils/helpers';
import verificationMode from '../../utils/api/AccessRule/verificationMode';
import { setNotificationError } from '../../redux/slices/NotificationSlices';
import AddPersonnel from './components/AddPersonnel';
import { DeleteParamsProps } from '../../interface/VerificationMode.interface';
import { checkPermission } from '../../utils/role-permission';
// import AddPersonnel from './components/AddPersonnel';

function VerificationMode() {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const {
    verificationModeList,
    status,
    totalDataCount,
    searchFilter,
    doors,
    doorStatus,
    doorTotalDataCount,
    doorCurrentPageNo,
    doorSearchFilter,
  } = useAppSelector((state: any) => state.verificationMode);

  /**
   * Selects the `loadMenus` permission from the authentication slice of the Redux store.
   *
   * @returns {Object} loadMenus - The user's menu permissions.
   */
  const { loadMenus } = useAppSelector((state) => state.auth);

  const [modalOpen, setModalOpen] = useState(false);
  const [modalView, setModalView] = useState({
    title: 'title',
    content: <>hi</>,
  });
  const [verifyMode, setVerifyMode] = useState<string[]>([]);
  const [selectDoor, setSelectDoor] = useState<string[]>([]);
  const [selectedRulesFullData, setSelectedRulesFullData] = useState<any[]>([]);
  const [selectedDoorFullData, setSelectedDoorFullData] = useState<any[]>([]);

  const handleEditOpen = (editData: any) => {
    const updates = transformVerifyData(editData);
    const updateData = {
      equals: editData.equals,
      id: editData.id,
      name: editData.name,
      timeSegBusinessId: editData.timeSegBusinessId,
      timeSegId: editData.timeSegId,
      timeSegName: editData.timeSegName,
      timeValues: updates,
    };

    if (!modalOpen) {
      setModalOpen(true);
      setModalView({
        title: t('edit'),
        content: <AddVerificationMode closeModal={() => setModalOpen(false)} update={updateData} />,
      });
    }
  };

  const canEdit = checkPermission('acc:verifyModeRule:edit', loadMenus) || true;
  const accessLevelColumnsField = [
    {
      accessorKey: 'name',
      header: t('ruleName'),
      Cell: ({ row }: any) => (
        <div
          className={canEdit ? 'list-hyperlink' : ''}
          role="presentation"
          onClick={(event) => {
            if (!canEdit) return;
            event.stopPropagation(); // Prevent row selection
            handleEditOpen(row.original); // Call your edit function
          }}>
          {row?.original?.name}
        </div>
      ),
    },
    {
      accessorKey: 'timeSegName',
      header: t('timeZones'),
    },
  ];

  const doorColumnsField = [
    {
      accessorKey: 'doorName',
      header: t('doorName'),
    },
    {
      accessorKey: 'deviceAlias',
      header: t('ownedDevice'),
    },
  ];

  const fetchData = async (params: any) => {
    if (status === 'success') {
      dispatch(fetchVerificationMode({ ...params }));
    }
  };

  const getFilterData = (filter: any): any => {
    if (filter.pageIndex > 0) {
      dispatch(setSearchFilter(filter));
      fetchData({ pageNo: filter.pageIndex, pageSize: filter.pageSize, ...filter.filter });
    }
  };

  const fetchDoors = (params: any) => {
    if (params.accVerifyModeRuleId) {
      dispatch(fetchDoorList({ ...params }));
    }
  };

  const getDoorFilter = (filter: any): any => {
    if (doorCurrentPageNo > 0) {
      dispatch(setDoorSearchFilter(filter));
      fetchDoors({ pageNo: filter.pageIndex, pageSize: filter.pageSize, ...filter.filter });
    }
  };

  const getSelected = (id: string[]) => {
    setVerifyMode(id);
    fetchDoors({ accVerifyModeRuleId: id?.[0], pageNo: 1 });
  };

  const deleteRule = (id: any) => {
    verificationMode
      .deleteRule({ ids: id })
      .then((response) => {
        if (response.data.success) {
          dispatch(
            setNotificationError({
              error: t('verificationModeDeletedSuccessfully'),
              status: 200,
            }),
          );
          dispatch(deleteVerifyRule(id));
          setModalOpen(false);
          fetchData({
            pageNo: searchFilter.pageIndex,
            pageSize: searchFilter.pageSize,
          });
        } else {
          dispatch(
            setNotificationError({
              error: response.data.message,
              status: response.data.code,
            }),
          );
        }
      })
      .catch((error) => {
        dispatch(
          setNotificationError({
            error: error.message,
            status: error.response?.status || 500,
          }),
        );
      });
  };

  const deleteDoor = (params: DeleteParamsProps) => {
    const request = {
      doorIds: params,
      verifyModeRuleId: verifyMode?.[0],
    };
    verificationMode
      .deleteDoor(request)
      .then((response) => {
        if (response.data.success) {
          dispatch(
            setNotificationError({
              error: t('doorDeletedSuccessfully'),
              status: 200,
            }),
          );
          dispatch(verifyDeleteDoor(params.id));
          setModalOpen(false);
          fetchDoors({
            accVerifyModeRuleId: verifyMode?.[0],
            pageNo: doorSearchFilter.pageIndex,
            pageSize: doorSearchFilter.pageSize,
          });
        } else {
          dispatch(
            setNotificationError({
              error: response.data.message,
              status: response.data.code,
            }),
          );
        }
      })
      .catch((error) => {
        dispatch(
          setNotificationError({
            error: error.message,
            status: error.response?.status || 500,
          }),
        );
      });
  };
  const handleDeleteClick = () => {
    if (selectedRulesFullData.length === 0) {
      dispatch(
        setNotificationError({
          error: t('pleaseSelectAtLeastOneVerfication'), // Also fixed the error message
          status: 400,
        }),
      );
      return;
    }

    setModalOpen(true);
    setModalView({
      title: t('delete'),
      content: (
        <DeleteModalBox
          closeModal={() => setModalOpen(false)}
          id={selectedRulesFullData.join(',')}
          okFunction={deleteRule}
          isMultiSelect
        />
      ),
    });
  };
  const actionButtons = [
    {
      label: t('edit'),
      enable: checkPermission('acc:verifyModeRule:edit', loadMenus),
      function: (val: any) => handleEditOpen(val),
    },
    {
      label: t('delete'),
      enable: checkPermission('acc:verifyModeRule:del', loadMenus),
      function: (val: any) => {
        setModalOpen(true);
        setModalView({
          title: t('delete'),
          content: (
            <DeleteModalBox
              closeModal={() => setModalOpen(false)}
              id={val.id}
              okFunction={deleteRule}
            />
          ),
        });
      },
    },
    {
      label: t('addDoor'),
      enable: checkPermission('acc:verifyModeRule:addDoor', loadMenus),
      function: (val: any): void => {
        setModalView({
          title: t('addDoor'),
          content: (
            <AddPersonnel closeModal={() => setModalOpen(false)} verifyModeRuleId={val.id} />
          ),
        });
        setModalOpen(true);
      },
    },
  ];
  const handleDeleteDoorClick = () => {
    if (selectedDoorFullData.length === 0) {
      dispatch(
        setNotificationError({
          error: t('pleaseSelectAtLeastOneDoor'),
          status: 400,
        }),
      );
      return;
    }
    setModalOpen(true);
    setModalView({
      title: t('delete'),
      content: (
        <DeleteModalBox
          closeModal={() => setModalOpen(false)}
          id={selectedDoorFullData.map((val) => val.id).join(',')}
          okFunction={deleteDoor}
          isMultiSelect
        />
      ),
    });
  };
  const doorActionButtons = [
    {
      label: t('delete'),
      enable: checkPermission('acc:verifyModeRule:delDoor', loadMenus),
      function: (val: any) => {
        setModalOpen(true);
        setModalView({
          title: t('delete'),
          content: (
            <DeleteModalBox
              closeModal={() => setModalOpen(false)}
              id={val}
              okFunction={deleteDoor}
            />
          ),
        });
      },
    },
  ];

  return (
    <div className="card-management">
      <ModalBox
        status={modalOpen}
        closeModal={() => setModalOpen(false)}
        title={modalView.title}
        content={modalView.content}
      />
      <TopHeader
        title={t('access')}
        broadCram={[
          {
            title: t('accessRule'),
          },
          {
            title: t('verificationMode'),
          },
        ]}
      />
      <CardBox
        header={t('verificationMode')}
        title={t('listOfRules')}
        rightSide={
          <div className="delete-icon">
            <CardRightButtonGroup
              buttonIcon={<AddIcon color="#ffffff" />}
              buttonLabel={t('new')}
              buttonClass="btn-primary btn-sm"
              buttonStatus
              buttonFunction={(): void => {
                setModalOpen(true);
                setModalView({
                  title: t('new'),
                  content: <AddVerificationMode closeModal={() => setModalOpen(false)} />,
                });
              }}
              buttonEnable={checkPermission('acc:verifyModeRule:add', loadMenus)}
              // Add delete button configuration
              DeleteButtonIcon={<DeleteButtonIcon />}
              deleteButtonLabel={t('delete')}
              showDeleteButton
              deleteButtonFunction={handleDeleteClick}
              deleteButtonEnable={checkPermission('acc:verifyModeRule:del', loadMenus)}
              deleteButtonClass="multi-delete-button"
            />
          </div>
        }>
        <DataTable
          actionButtonStatus={!modalOpen}
          action={actionButtons}
          columns={accessLevelColumnsField}
          getFilterData={getFilterData}
          assignFilterValue={searchFilter}
          assignSelectedData={verifyMode} // Assign selected cards
          // getSelectedData={(val: any): any => getSelected(val)} // Set selected
          getSelectedData={(val: any): any => {
            getSelected(val); // Set selected cards
            setSelectedRulesFullData(val);
          }}
          checkBoxActive
          singleRowSelection
          enablePagination
          isFetching={status === 'loading'}
          values={verificationModeList}
          fixedColumn={['name']}
          totalRecords={totalDataCount}
          height={411}
        />
        <hr className="line" />
        <div className="header" style={{ padding: '20px 20px 20px 10px' }}>
          <h4>{t('door')}</h4>
        </div>
        <div className="door-header">
          <div className="doorlist">
            <span className="sub-title">{t('listOfDoor')}</span>
          </div>
          <div className="delete-icon">
            <CardRightButtonGroup
              DeleteButtonIcon={<DeleteButtonIcon />}
              deleteButtonLabel={t('delete')}
              showDeleteButton
              deleteButtonFunction={handleDeleteDoorClick}
              deleteButtonEnable={checkPermission('acc:verifyModeRule:delDoor', loadMenus)}
              deleteButtonClass="multi-delete-button"
            />
          </div>
        </div>
        <DataTable
          action={doorActionButtons}
          columns={doorColumnsField}
          values={doors}
          actionButtonStatus={!modalOpen}
          getFilterData={getDoorFilter}
          assignFilterValue={doorSearchFilter}
          assignSelectedData={selectDoor} // Assign selected cards
          getSelectedData={(val: any): any => setSelectDoor(val)} // Set selected cards
          getSelectedFullData={(val: any[]): any => {
            setSelectedDoorFullData(val);
          }}
          checkBoxActive
          isFetching={doorStatus === 'loading'}
          fixedColumn={['doorName']}
          totalRecords={doorTotalDataCount}
          height={411}
        />
      </CardBox>
    </div>
  );
}

export default VerificationMode;
