import React from 'react';
import RadioButtonField from '../../../../../shared-components/radio-button-field/radio-button-field';
import InputField from '../../../../../shared-components/input-field/input-field';
import { AttentionIcon } from '../../../../../assets/svgicons/svgicon';

interface MoniterInterface {
  onChangeFunction: (page: any, name: any, changeValue: any) => void;
  values?: any;
}

function QRCodeSettings({ onChangeFunction, values }: MoniterInterface) {
  const onChange = (name: any, changeValue: any) => {
    onChangeFunction('qrCodeMap', name, changeValue);
  };

  return (
    <div className="card-management">
      <div className="cloud-setting">
        <div className="cloud-setting-form w50">
          <div className="column">
            <div className="item">
              <RadioButtonField
                label="Enable QR Code"
                name="qrcode.enable"
                alignment="row"
                data={[
                  {
                    label: 'Yes',
                    id: '1',
                    value: '1',
                  },
                  {
                    label: 'No',
                    id: '0',
                    value: '0',
                  },
                ]}
                value={values['qrcode.enable']}
                onChangeFunction={onChange}
              />
            </div>

            {values['qrcode.enable'] === '1' ? (
              <>
                <div className="item">
                  <RadioButtonField
                    label="QR Code Type"
                    name="qrcode.type"
                    alignment="row"
                    data={[
                      {
                        label: 'Static',
                        id: '1',
                        value: '1',
                      },
                      {
                        label: 'Dynamic',
                        id: '2',
                        value: '2',
                      },
                    ]}
                    value={values['qrcode.type']}
                    onChangeFunction={onChange}
                  />
                </div>

                {values['qrcode.type'] === '2' ? (
                  <>
                    <div className="item">
                      <RadioButtonField
                        label="Encryption Mode"
                        name="qrcode.algorithm"
                        alignment="row"
                        data={[
                          {
                            label: 'Mode A',
                            id: '1',
                            value: '1',
                          },
                          {
                            label: 'Mode E',
                            id: '2',
                            value: '2',
                          },
                        ]}
                        value={values['qrcode.algorithm']}
                        onChangeFunction={onChange}
                      />
                      <div className="text-danger mt-2">
                        <div className="note">
                          <div className="alter-icon">
                            <AttentionIcon />
                          </div>
                          <div className="content">
                            The current device does not support E-mode encryption. Before selecting
                            the encryption mode, please ensure that the device has supported the
                            current mode; otherwise the QR code will not be recognized correctly.
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="valid-item">
                      <InputField
                        id="validTime"
                        label="Valid Time"
                        name="qrcode.validTime"
                        type="number"
                        value={values['qrcode.validTime']}
                        onChangeFunction={onChange}
                        min={30}
                        max={300}
                      />
                    </div>
                    <div className="seconds">
                      Second <span className="custom-color">(30-300)</span>
                    </div>
                  </>
                ) : (
                  <div className="text-danger mt-2">
                    <div className="note">
                      <div className="alter-icon">
                        <AttentionIcon />
                      </div>
                      <div className="content">
                        Switch between static QR code and dynamic QR code should be careful,
                        frequent switch may lead to device error!
                      </div>
                    </div>
                  </div>
                )}
              </>
            ) : (
              <div className="text-danger mt-2">
                <div className="note">
                  <div className="alter-icon">
                    <AttentionIcon />
                  </div>
                  <div className="content">
                    Switch between static QR code and dynamic QR code should be careful, frequent
                    switch may lead to device error!
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
QRCodeSettings.defaultProps = {
  values: '',
};
export default QRCodeSettings;
