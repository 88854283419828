import { get, post } from './base/index';

export default {
  login: (data = {}) => {
    const params = { ...data };
    return post(`${process.env.REACT_APP_API_URL}Auth/auth/authenticate`, {}, { ...params });
  },
  authHome: () => {
    return get(`${process.env.REACT_APP_API_URL}Auth/api/main/home`, {});
  },
  loadMenus: (data: any) => {
    return post(`${process.env.REACT_APP_API_URL}Auth/api/main/loadMenus`, {}, data);
  },
  getAuthArea: (data: any = {}) => {
    return post(
      `${process.env.REACT_APP_API_URL}Auth/api/authArea/getAreaList`,
      {},
      { ...data, pageSize: 10 },
    );
  },

  logout: () => {
    return post(`${process.env.REACT_APP_API_URL}Auth/auth/logout`, {});
  },

  submitPwdChange: (data: any = {}) => {
    return post(`${process.env.REACT_APP_API_URL}Auth/api/securityParam/submitPwdInit`, {}, data);
  },
  initPassword: (data: any = {}) => {
    return post(`${process.env.REACT_APP_API_URL}Auth/api/securityParam/initPassword`, {}, data);
  },
  isNeedValidUser: (data: any = {}) => {
    return post(`${process.env.REACT_APP_API_URL}Auth/api/main/isNeedValidUser`, {}, data);
  },
  sendTwoFACode: (data: any = {}) => {
    return post(`${process.env.REACT_APP_API_URL}Auth/auth/send2FACode`, {}, data);
  },
};
