import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import InputField from '../../../../../shared-components/input-field/input-field';
import Button from '../../../../../shared-components/button/button';
import {
  ServerConnectionFormProps,
  FormData,
} from '../../../../../interface/System/adFieldSetting.interface';
import Label from '../../../../../shared-components/label/Label';

function ServerConfiguration({ onChangeFunction, values }: ServerConnectionFormProps) {
  const { t } = useTranslation();

  const generateUniqueId = () => `domain-${Date.now()}-${Math.random().toString(36).substr(2, 9)}`;

  const [formData, setFormData] = useState<FormData>({
    serverAddress: values?.serverAddress || 'ldap://localhost:389',
    username: values?.username || 'Test',
    password: values?.password || '123456',
    domainNodes: values?.domainNodes || [{ id: generateUniqueId(), value: '' }],
  });

  const handleChange = (name: string, value: string, id?: string) => {
    if (name === 'domainNode' && id) {
      const updatedDomainNodes = formData.domainNodes.map((node) =>
        node.id === id ? { ...node, value } : node,
      );

      setFormData((prev) => ({
        ...prev,
        domainNodes: updatedDomainNodes,
      }));

      if (onChangeFunction) {
        onChangeFunction({
          ...formData,
          domainNodes: updatedDomainNodes,
        });
      }
    } else {
      setFormData((prev) => ({
        ...prev,
        [name]: value,
      }));

      if (onChangeFunction) {
        onChangeFunction({
          ...formData,
          [name]: value,
        });
      }
    }
  };

  const addDomainNode = () => {
    const newNode = { id: generateUniqueId(), value: '' };

    if (formData.domainNodes.length >= 5) {
      return;
    }

    setFormData((prev) => ({
      ...prev,
      domainNodes: [...prev.domainNodes, newNode],
    }));

    if (onChangeFunction) {
      const updatedDomainNodes = [...formData.domainNodes, newNode];
      if (updatedDomainNodes.length > 3)
        onChangeFunction({
          ...formData,
          domainNodes: updatedDomainNodes,
        });
    }
  };

  const removeDomainNode = (id: string) => {
    const updatedDomainNodes = formData.domainNodes.filter((node) => node.id !== id);

    setFormData((prev) => ({
      ...prev,
      domainNodes: updatedDomainNodes,
    }));

    if (onChangeFunction) {
      onChangeFunction({
        ...formData,
        domainNodes: updatedDomainNodes,
      });
    }
  };

  const handleTestConnection = () => {};

  return (
    <div className="server-configuration">
      <div className="server-row">
        <div className="form-group">
          <Label className="server-filed" title={t('serverAddress')} />
          <InputField
            id="serverAddress"
            name="serverAddress"
            label=""
            value={formData.serverAddress}
            onChangeFunction={(name: string, value: string) => handleChange(name, value)}
            type="text"
          />
        </div>

        <div className="form-group">
          <Label className="server-filed" title={t('username')} />
          <InputField
            id="username"
            name="username"
            label=""
            value={formData.username}
            onChangeFunction={(name: string, value: string) => handleChange(name, value)}
            type="text"
          />
        </div>

        <div className="form-group">
          <Label className="server-filed" title={t('password')} />
          <InputField
            id="password"
            name="password"
            label=""
            type="password"
            value={formData.password}
            onChangeFunction={(name: string, value: string) => handleChange(name, value)}
          />
        </div>
      </div>

      <Label className="Domain-filed" title={t('domainNode')} />
      <div className="domain-node-row">
        {formData.domainNodes.map((domainNode, index) => (
          <div key={domainNode.id} className="domain-node-field">
            <InputField
              id={`domainNode-${domainNode.id}`}
              name="domainNode"
              label=""
              value={domainNode.value}
              onChangeFunction={(name: string, value: string) =>
                handleChange(name, value, domainNode.id)
              }
              type="text"
            />
            {index > 0 && (
              <Button
                title=""
                className="delete-button"
                onClickFunction={() => removeDomainNode(domainNode.id)}>
                <span className="delete-icon">🗑</span>
              </Button>
            )}
            <Button title="+" className="add-button" onClickFunction={addDomainNode} />
          </div>
        ))}
      </div>

      <div className="cleanup-button">
        <Button
          title={t('testConnection')}
          className="btn-primary"
          onClickFunction={handleTestConnection}
        />
      </div>
    </div>
  );
}

ServerConfiguration.defaultProps = {
  values: '',
};

export default ServerConfiguration;
