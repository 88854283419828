/* eslint-disable no-param-reassign */
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { setNotificationError } from '../NotificationSlices';
import authorizedDevice from '../../../utils/api/SystemManagement/authorizedDevice';
import { AuthDeviceReduxInterface } from '../../../interface/System/authorizedDevice.interface';

export const fetchAuthDeviceList = createAsyncThunk(
  'acc/fetchAuthDeviceList ',
  async (data: any, { dispatch }) => {
    const response = await authorizedDevice
      .getAuthDevice(data)
      .then((response1) => {
        return {
          ...response1.data,
          data: {
            list: response1.data.data.data.map((item: any) => ({
              ...item,
              ...(item?.initFlag ? { delete: false } : {}),
            })),
          },
          size: response1.data.data.total,
          ...data,
        };
      })
      .catch((error: any) => {
        dispatch(setNotificationError(error.response.data));
        return error;
      });
    return response;
  },
);

const initialState: AuthDeviceReduxInterface = {
  authDevList: [],
  status: 'success',
  totalDataCount: 0,
  currentPageNo: 0,
  searchFilter: {},
};

export const AuthDeviceSlices: any = createSlice({
  name: 'authorizedDevice',
  initialState,
  reducers: {
    setSearchFilter: (state, action: any) => {
      state.searchFilter = action.payload;
    },
    deleteAuthDev: (state, action: any) => {
      state.authDevList = state.authDevList.filter((val: any) => val.id !== action.payload);
      state.totalDataCount -= 1;
    },
  },
  extraReducers: (builder) => {
    // Add reducers for additional action types here, and handle loading state as needed
    builder
      .addCase(fetchAuthDeviceList.pending, (state = initialState) => {
        state.status = 'loading';
      })
      .addCase(fetchAuthDeviceList.fulfilled, (state: any, action: any) => {
        if (action.payload.success) {
          state.authDevList = action.payload.data.list;
          state.totalDataCount = action.payload.size;
        }
        state.status = 'success';
      })
      .addCase(fetchAuthDeviceList.rejected, (state: any) => {
        state.status = 'failed';
      });
  },
});

// Action creators are generated for each case reducer function
export const { setSearchFilter, deleteAuthDev } = AuthDeviceSlices.actions;

export default AuthDeviceSlices.reducer;
