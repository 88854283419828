import React, { useState } from 'react';

// Use translation
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from '../../redux/store';

// Use own components
import AddAttribute from './components/add-attribute';

// Use redux functions
import {
  deleteCustomAttribute,
  fetchCustomAttributeList,
  setSearchFilter,
} from '../../redux/slices/CustomAttributesSlices';
import { setNotificationError } from '../../redux/slices/NotificationSlices';

// Use Interface
import { CustomAttributeReduxInterface } from '../../interface/attribute.interface';

// Use api's calls
import customAttributeApis from '../../utils/api/customAttribute';

// Use shared components
import TopHeader from '../../shared-components/top-header/top-header';
import CardBox from '../../shared-components/card/card';
import DataTable from '../../shared-components/data-table/data-table';
import CardRightButtonGroup from '../../shared-components/card-right-button-group/card-right-button-group';
import ModalBox from '../../shared-components/modal-box/modal-box';
import DeleteModalBox from '../../shared-components/delete-modal-box/delete-modal-box';

// Use assets svg icons
import { AddIcon, DeleteButtonIcon } from '../../assets/svgicons/svgicon';
import { checkPermission } from '../../utils/role-permission';

interface DeleteDepartProps {
  id: string;
}

function CustomAttributes() {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const [selectedDeleteFullData, setSelectedDeleteFullData] = useState<any[]>([]);

  const { customAttribute, status, totalDataCount, searchFilter }: CustomAttributeReduxInterface =
    useAppSelector((state: any) => state.customAttribute);
  const { loadMenus } = useAppSelector((state) => state.auth);
  let handleEditOpen: any;
  const columnsField = [
    {
      accessorKey: 'attrName',
      header: t('displayName'),
      Cell: ({ row }: any) =>
        checkPermission('pers:attribute:edit', loadMenus) ? (
          <div
            className="list-hyperlink"
            role="presentation"
            onClick={(event) => {
              event.stopPropagation(); // Prevent row selection
              handleEditOpen(row.original); // Call your edit function
            }}>
            {row?.original?.attrName}
          </div>
        ) : (
          <div>{row?.original?.attrName}</div>
        ),
    },
    {
      accessorKey: 'attrValue',
      header: t('attributeValue'),
    },
    {
      accessorKey: 'controlType',
      header: t('inputType'),
      Cell: ({ row }: any) => row.original.controlTypeLabel,
    },
    {
      accessorKey: 'positionX',
      header: t('row'),
    },
    {
      accessorKey: 'positionY',
      header: t('column'),
    },
    {
      accessorKey: 'personType',
      header: t('displayInPersonList'),
      Cell: ({ row }: any) => row.original.personTypeLabel,
    },
  ];

  const [modalOpen, setModalOpen] = useState(false);
  const [selectedCustom, setSelectedCustom] = useState<any[]>([]);
  const [modalView, setModalView] = useState({
    title: t('addNewDepartment'),
    content: <>hi</>,
  });

  const fetchCustomAttributeData = (params: any) => {
    if (status === 'success') {
      dispatch(fetchCustomAttributeList({ ...params }));
    }
  };

  const deleteRecord = (params: DeleteDepartProps) => {
    const req = {
      id: params,
    };
    customAttributeApis
      .deleteCustomAttribute(req)
      .then((response) => {
        if (response.data.code === 0) {
          dispatch(
            setNotificationError({
              error: t('customAttributeDeletedSuccessfully'),
              status: 200,
            }),
          );
          dispatch(deleteCustomAttribute(req));
          setModalOpen(false);
          dispatch(fetchCustomAttributeList({ pageNo: 1, pageSize: 10 }));
        } else {
          dispatch(
            setNotificationError({
              error: response.data.message,
              status: response.data.code,
            }),
          );
        }
      })
      .catch((error) => {
        dispatch(
          setNotificationError({
            error: error.message,
            status: error.status,
          }),
        );
      });
  };
  handleEditOpen = (editData: any) => {
    setModalOpen(true);
    setModalView({
      title: t('editAttribute'),
      content: <AddAttribute closeModal={() => setModalOpen(false)} data={editData} />,
    });
  };
  const handleDeleteClick = () => {
    if (selectedDeleteFullData.length === 0) {
      dispatch(
        setNotificationError({
          error: t('pleaseSelectAtLeastOneCustom'),
          status: 400,
        }),
      );
      return;
    }

    setModalView({
      title: t('delete'),
      content: (
        <DeleteModalBox
          closeModal={() => setModalOpen(false)}
          id={selectedDeleteFullData.map((val) => val.id).join(',')}
          okFunction={deleteRecord}
          isMultiSelect
        />
      ),
    });
    setModalOpen(true);
  };
  const actionButtons = [
    {
      label: t('edit'),
      enable: checkPermission('pers:attribute:edit', loadMenus),
      function: (val: any) => handleEditOpen(val),
    },
    {
      label: t('delete'),
      enable: checkPermission('pers:attribute:del', loadMenus),
      function: (val: any) => {
        setModalOpen(true);
        setModalView({
          title: t('deleteAttribute'),
          content: (
            <DeleteModalBox
              closeModal={() => setModalOpen(false)}
              id={val.id}
              okFunction={deleteRecord}
            />
          ),
        });
      },
    },
  ];

  const getFilterData = (filter: any): any => {
    if (filter.pageIndex > 0) {
      dispatch(setSearchFilter(filter));
      fetchCustomAttributeData({
        pageNo: filter.pageIndex,
        pageSize: filter.pageSize,
        ...filter.filter,
      });
    }
  };

  return (
    <div className="attribute">
      <ModalBox
        status={modalOpen}
        closeModal={() => setModalOpen(false)}
        title={modalView.title}
        content={modalView.content}
      />
      <TopHeader
        title={t('customAttribute')}
        broadCram={[
          {
            title: t('personnel'),
          },
          {
            title: t('customAttribute'),
          },
        ]}
      />
      <CardBox
        title={t('listOfCustomAttribute')}
        rightSide={
          <div className="delete-icon">
            <CardRightButtonGroup
              buttonIcon={<AddIcon color="#ffffff" />}
              buttonLabel={t('add')}
              buttonClass="btn-primary btn-sm"
              buttonStatus
              buttonFunction={(): void => {
                setModalOpen(true);
                setModalView({
                  title: t('addNewAttribute'),
                  content: <AddAttribute closeModal={() => setModalOpen(false)} />,
                });
              }}
              buttonEnable={checkPermission('pers:attribute:add', loadMenus)}
              DeleteButtonIcon={<DeleteButtonIcon />}
              deleteButtonLabel={t('delete')}
              showDeleteButton
              deleteButtonFunction={handleDeleteClick}
              deleteButtonEnable={checkPermission('pers:attribute:del', loadMenus)}
              deleteButtonClass="multi-delete-button"
            />
          </div>
        }>
        <DataTable
          actionButtonStatus
          action={actionButtons}
          columns={columnsField}
          getFilterData={getFilterData}
          enablePagination
          assignFilterValue={searchFilter}
          assignSelectedData={selectedCustom}
          getSelectedData={(val: any): any => {
            setSelectedCustom(val);
          }}
          getSelectedFullData={(val: any): any => {
            setSelectedDeleteFullData(val);
          }}
          checkBoxActive
          values={customAttribute}
          fixedColumn={['attrName']}
          isFetching={status === 'loading'}
          totalRecords={totalDataCount}
          height={411}
        />
      </CardBox>
    </div>
  );
}

export default CustomAttributes;
