/* eslint-disable no-param-reassign */
import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import systemManagementParameters from '../../../utils/api/SystemManagement/parameters';
import { setNotificationError } from '../NotificationSlices';

interface SystemParametersInterface {
  systemManagementParameters: any;
  status: string;
}

export const fetchSystemManagementParameters = createAsyncThunk(
  'parameters/fetchSystemManagementParameters',
  async (data: any, { dispatch }) => {
    try {
      const response = await systemManagementParameters.paramSetting();
      const permissions: string[] = [];

      const { qrCodeMap, systemEntryMap, systemPrivacy, systemPlugin } = response.data.data;

      Object?.values(systemEntryMap)?.forEach((val: any) => {
        if (response.data.data[`pers.${val}.encryptProp`] === 'true') {
          permissions.push(val);
        }
      });

      return {
        success: true,
        data: {
          qrCodeMap: { ...qrCodeMap },
          systemEntryMap,
          systemPlugin,
          systemPrivacy,
          permissions,
        },
      };
    } catch (error: any) {
      dispatch(setNotificationError(error.response.data));
      return { success: false, error: error.response.data };
    }
  },
);

const initialState: SystemParametersInterface = {
  systemManagementParameters: {},
  status: 'success',
};

export const SystemManagementParametersSlices = createSlice({
  name: 'systemManagementParameters',
  initialState,
  reducers: {
    updateSystemManagementParameters: (state, action: PayloadAction<any>) => {
      return { ...state, systemManagementParameters: action?.payload };
    },
    setQrcodeEnable: (state, action: PayloadAction<any>) => {
      return { ...state, qrcodeEnable: action?.payload };
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchSystemManagementParameters.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchSystemManagementParameters.fulfilled, (state, action) => {
        if (action.payload.success) {
          state.systemManagementParameters = {
            ...action.payload.data,
          };
          state.status = 'success';
        }
      })
      .addCase(fetchSystemManagementParameters.rejected, (state) => {
        state.status = 'failed';
      });
  },
});

export const { updateSystemManagementParameters, setQrcodeEnable } =
  SystemManagementParametersSlices.actions;

export default SystemManagementParametersSlices.reducer;
