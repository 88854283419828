import { post, get } from '../base/index';

export default {
  getListPage: (data: any = {}) => {
    return post(
      `${process.env.REACT_APP_API_URL}Auth/api/authApiToken/list`,
      {},
      { ...data, pageSize: 10 },
    );
  },

  saveList: (data: any = {}) => {
    return post(`${process.env.REACT_APP_API_URL}Auth/api/authApiToken/save`, data, {});
  },

  generateApiToken: (data: any = {}) => {
    return get(
      `${process.env.REACT_APP_API_URL}Auth/api/authApiToken/generateApiToken?clientName=${data}`,
      {},
    );
  },

  isExistName: (name: string) =>
    post(
      `${process.env.REACT_APP_API_URL}Auth/api/authApiToken/isExistClientName?clientName=${name}`,
      {},
    ),

  delete: (id: string = '') => {
    return get(`${process.env.REACT_APP_API_URL}Auth/api/authApiToken/delete?ids=${id}`, {});
  },

  openSwaggerUI: () => {
    const swaggerUIUrl =
      'https://acs-api-dev.aictec.com/swagger-ui/?urls.primaryName=authentication-service';
    window.open(swaggerUIUrl, '_blank');
  },
};
