import React, { useEffect, useState } from 'react';

// Use interface
import { TabPageInterFace, ModalTabItemInterFace } from '../../interface/common.interface';

function TabPages({ tabs, defaultNumber }: TabPageInterFace) {
  // Filter only enabled tabs
  const filteredTabs = tabs.filter((tab) => Boolean(tab?.enable));

  // Ensure the first selected tab is an enabled tab
  const [tabIndex, setTabIndex] = useState(defaultNumber); // Default to first enabled tab

  const [tab, changeTab] = useState(filteredTabs[tabIndex]);

  useEffect(() => {
    changeTab(filteredTabs[tabIndex]); // Update active tab when index changes
  }, [tabIndex, filteredTabs]);

  return (
    <div className="tab-page">
      <div className="tab-modal-content">
        <div className="tab-list-items">
          {tabs
            ?.filter((act) => Boolean(act.enable))
            ?.map((tabItem: ModalTabItemInterFace, index: number) => (
              <div
                key={tabItem.key}
                className={`tab-item ${tabItem?.key === tab?.key && 'active'}`}
                onClick={() => {
                  changeTab(tabItem);
                  setTabIndex(index);
                }}
                role="presentation"
                title={tabItem?.title}>
                {tabItem?.title}
              </div>
            ))}
        </div>
        <div className="tab-body">{tab?.content}</div>
      </div>
    </div>
  );
}

export default TabPages;
