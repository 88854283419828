/* eslint-disable no-param-reassign */
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { setNotificationError } from '../NotificationSlices';
import operationLog from '../../../utils/api/SystemManagement/operationLog';

export const fetchOperationLog = createAsyncThunk(
  'acc/fetchOperationLog',
  async (data: any, { dispatch }) => {
    const response = await operationLog
      .getListPage(data)
      .then((response1) => {
        return {
          ...response1.data,
          data: {
            list: response1.data.data ? response1.data.data : [],
            size: response1.data.data ? response1.data.data.length : 0,
            ...data,
          },
        };
      })
      .catch((error) => {
        dispatch(
          setNotificationError({
            error: error.message,
            status: error.response?.status || 500,
          }),
        );
        return error;
      });
    return response;
  },
);

interface ReduxInterface {
  logList: any;
  status: 'loading' | 'success' | 'failed'; // Status can be one of these three strings
  totalDataCount: number;
  currentPageNo: number;
  searchFilter: { [key: string]: any };
}

const initialState: ReduxInterface = {
  logList: [],
  status: 'success',
  totalDataCount: 0,
  currentPageNo: 0,
  searchFilter: {},
};

export const operationLogSlice: any = createSlice({
  name: 'allExceptionEvents',
  initialState,
  reducers: {
    setSearchFilter: (state, action: any) => {
      state.searchFilter = action.payload;
    },
  },
  extraReducers: (builder) => {
    // Add reducers for additional action types here, and handle loading state as needed
    builder
      .addCase(fetchOperationLog.pending, (state = initialState) => {
        state.status = 'loading';
      })
      .addCase(fetchOperationLog.fulfilled, (state: any, action: any) => {
        if (action.payload.success) {
          state.totalDataCount = action.payload.data.size;
          state.currentPageNo = action.payload.data.pageNo;

          if (state.currentPageNo === 1) {
            state.logList = action.payload.data.list;
          } else {
            state.logList = [...state.logList, ...action.payload.data.list];
          }
        }
        state.status = 'success';
      })
      .addCase(fetchOperationLog.rejected, (state: any) => {
        state.status = 'failed';
      });
  },
});

// Action creators are generated for each case reducer function
export const { setSearchFilter } = operationLogSlice.actions;

export default operationLogSlice.reducer;
