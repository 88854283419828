import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import SelectBoxField from '../../../../../shared-components/selectbox-field/selectbox-field';
import {
  AuthDevProps,
  AuthModalType,
  ProductsProps,
} from '../../../../../interface/System/authorizedDevice.interface';
import Button from '../../../../../shared-components/button/button';
import InputField from '../../../../../shared-components/input-field/input-field';
import InputTextAreaField from '../../../../../shared-components/input-text-area-field/input-text-area-field';
import authorizedDevice from '../../../../../utils/api/SystemManagement/authorizedDevice';
import { setNotificationError } from '../../../../../redux/slices/NotificationSlices';
import { useAppDispatch } from '../../../../../redux/store';
import { fetchAuthDeviceList } from '../../../../../redux/slices/system-management/AuthorizedDeviceSlices';

/**
 * AddAuthorizedDevice component for adding or updating authorized devices.
 *
 * @param {Object} props - The component props
 * @param {Function} props.closeModal - Function to close the modal
 * @param {boolean} props.update - Flag indicating if this is an update operation
 */
function AddAuthorizedDevice({ closeModal, update }: AuthModalType) {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  /**
   * State for the form values.
   * @type {AuthDevProps}
   */
  const [values, setValues] = useState<AuthDevProps>(update);
  const [disabled, setDisabled] = useState(true);
  const [isExistSn, setIsExistSn] = useState(true);
  const [products, setProducts] = useState<ProductsProps[]>([]);

  const onChangeValue = (name: string, value: any) => {
    setValues((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const onBlur = async (username: string, value: any) => {
    if (username === 'sn' && value !== '') {
      try {
        const responseData = await authorizedDevice.isNotExistSn({ sn: value });
        if (responseData.data) {
          setIsExistSn(responseData?.data);
        } else {
          setIsExistSn(false);
        }
      } catch (error: any) {
        setIsExistSn(false);
        dispatch(
          setNotificationError({
            error: error.message,
            status: error.status,
          }),
        );
      }
    }
  };

  const getProductList = () => {
    authorizedDevice
      .getProductList()
      .then((response) => {
        const list: ProductsProps[] = response.data.data.map((item: any) => ({
          label: item.text,
          id: item.value,
        }));
        setProducts(list);
      })
      .catch((error) => {
        dispatch(
          setNotificationError({
            error: error.message,
            status: error.status,
          }),
        );
      });
  };

  const submit = () => {
    setDisabled(true);
    const request = {
      id: values?.id,
      modeType: values?.modeType,
      productId: values?.productId,
      protype: values?.protype,
      remark: values?.remark,
      sn: values?.sn,
      ID: values?.id,
    };
    authorizedDevice
      ?.save(request)
      .then((response) => {
        if (response?.data?.success) {
          dispatch(
            setNotificationError({
              error: response.data.msg,
              status: 200,
            }),
          );
          setDisabled(false);
          closeModal();
          dispatch(
            fetchAuthDeviceList({
              pageNo: 1,
              pageSize: 10,
            }),
          );
        } else {
          setDisabled(false);
          dispatch(
            setNotificationError({
              error: response.data.message,
              status: response.data.code,
            }),
          );
        }
      })
      .catch((error) => {
        setDisabled(false);
        dispatch(
          setNotificationError({
            error: error.message,
            status: error.status,
          }),
        );
      });
  };

  useEffect(() => {
    if (values.productId && values.protype && values?.sn && isExistSn) {
      setDisabled(false);
    } else {
      setDisabled(true);
    }
  }, [values, isExistSn]);

  useEffect(() => {
    getProductList();
  }, []);

  return (
    <div className="add-authorize">
      <div className="grid-rows">
        <SelectBoxField
          id="productId"
          name="productId"
          label={t('productName')}
          value={values?.productId}
          onChangeFunction={(name, value) => onChangeValue(name, value.id)}
          data={products}
          required
          disabled
        />
        <InputField
          type="text"
          id="protype"
          name="protype"
          label={t('productMode')}
          value={values?.protype}
          onChangeFunction={(name, value) => onChangeValue(name, value)}
          required
          disabled
        />
      </div>
      <div className="grid-rows">
        <InputField
          id="sn"
          name="sn"
          label={t('serialNumber')}
          type="text"
          value={values.sn}
          onBlurFunction={onBlur}
          errorStatus={values.sn !== '' && !isExistSn}
          message={t('objectIsAlreadyExist')}
          onChangeFunction={onChangeValue}
          required
          disabled
        />
        <InputField
          id="deviceSecret"
          name="deviceSecret"
          label={t('deviceSecret')}
          type="text"
          value={values.deviceSecret}
          onChangeFunction={onChangeValue}
          required
          disabled
        />
      </div>
      <div className="grid-rows">
        <InputTextAreaField
          id="remark"
          value={values?.remark}
          label={t('remarks')}
          name="remark"
          onChangeFunction={onChangeValue}
        />
        <div />
      </div>
      <div className="form-buttons-right">
        <Button
          onClickFunction={() => {
            closeModal();
          }}
          title={t('cancel')}
          className="btn-outline-primary"
        />
        <Button
          onClickFunction={submit}
          title={t('done')}
          className="btn-primary"
          disabled={disabled}
        />
      </div>
    </div>
  );
}

export default AddAuthorizedDevice;
