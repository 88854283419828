/* eslint-disable no-param-reassign */
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { setNotificationError } from '../NotificationSlices';
import databaseMgt from '../../../utils/api/SystemManagement/databaseManagement';
import { changeDateTimeFormat } from '../../../utils/helpers';

export const fetchDbMgt = createAsyncThunk('acc/fetchDbMgt', async (data: any, { dispatch }) => {
  const response = await databaseMgt
    .getListPage(data)
    .then((response1) => {
      return {
        ...response1.data,
        data: {
          list: response1.data.data.data.map((item: any) => ({
            ...item,
            startTime: changeDateTimeFormat(item.startTime),
          })),
          size: response1.data.data.total,
          ...data,
        },
      };
    })
    .catch((error) => {
      dispatch(
        setNotificationError({
          error: error.message,
          status: error.response?.status || 500,
        }),
      );
      return error;
    });
  return response;
});
interface ReduxInterface {
  dbList: any;
  status: 'loading' | 'success' | 'failed'; // Status can be one of these three strings
  totalDataCount: number;
  currentPageNo: number;
  searchFilter: { [key: string]: any };
}

const initialState: ReduxInterface = {
  dbList: [],
  status: 'success',
  totalDataCount: 0,
  currentPageNo: 0,
  searchFilter: {},
};

export const DatabaseManagementSlice: any = createSlice({
  name: 'databaseManagement',
  initialState,
  reducers: {
    setSearchFilter: (state, action: any) => {
      state.searchFilter = action.payload;
    },
  },
  extraReducers: (builder) => {
    // Add reducers for additional action types here, and handle loading state as needed
    builder
      .addCase(fetchDbMgt.pending, (state = initialState) => {
        state.status = 'loading';
      })
      .addCase(fetchDbMgt.fulfilled, (state: any, action: any) => {
        if (action.payload.success) {
          state.totalDataCount = action.payload.data.size;
          state.currentPageNo = action.payload.data.pageNo;

          state.dbList = action.payload.data.list;
        }
        state.status = 'success';
      })
      .addCase(fetchDbMgt.rejected, (state: any) => {
        state.status = 'failed';
      });
  },
});

// Action creators are generated for each case reducer function
export const { setSearchFilter } = DatabaseManagementSlice.actions;

export default DatabaseManagementSlice.reducer;
