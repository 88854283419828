import { useEffect, useState } from 'react';
import TreeViewMultiSelect from '../../../../../shared-components/tree-view/TreeViewMultiSelect';
import CheckBoxInputField from '../../../../../shared-components/checkbox-input-field/checkbox-input-field';
import { useAppDispatch, useAppSelector } from '../../../../../redux/store';
import {
  fetchSystemRole,
  saveAssignId,
} from '../../../../../redux/slices/system-management/RoleSlices';
import { PersonnelTabProps } from '../../../../../interface/role.interface';

/**
 * SystemTab component for managing system roles and permissions.
 *
 * @component
 * @param {Object} props - The component props
 * @param {string} props.tabKey - The key identifying the current tab
 * @param {Function} props.handleChange - Function to handle changes in the component
 * @param {string} props.roleId - The ID of the current role being edited
 */
function SystemTab({ tabKey, handleChange, roleId }: PersonnelTabProps) {
  const dispatch = useAppDispatch();
  const { assignId, systemRole } = useAppSelector((state: any) => state?.role);

  const [values, setValues] = useState({
    selectAll: [],
    selectValue: assignId,
  });

  /**
   * Recursively get all node IDs from the tree structure
   * @param {Object[]} nodes - The tree data array
   * @returns {string[]} - An array of all node IDs
   */
  const getAllNodeIds = (nodes: any): string[] => {
    let ids: string[] = [];

    nodes.forEach((node: any) => {
      ids.push(node.id);
      if (node.item && node.item.length > 0) {
        ids = ids.concat(getAllNodeIds(node.item)); // Recursive call for child nodes
      }
    });

    return ids;
  };

  /**
   * Handles selection of multiple items in the tree view
   * @param {string[]} selectedValues - Array of selected values
   */
  const handleTreeSelect = (selectedValues: string[]) => {
    setValues((prev: any) => ({
      ...prev,
      selectValue: selectedValues,
      selectAll: selectedValues?.length === getAllNodeIds(systemRole)?.length ? ['all'] : [],
    }));

    dispatch(saveAssignId(selectedValues));
    handleChange('assignId', selectedValues);
  };

  /**
   * Handles the "Select All" checkbox
   * @param {string} name - The name of the checkbox
   * @param {string[]} val - The new value of the checkbox
   */
  const selectAll = (name: string, val: any) => {
    const allIds = getAllNodeIds(systemRole);
    setValues((prev: any) => ({
      ...prev,
      selectAll: val,
      selectValue: val.length > 0 ? allIds : [],
    }));

    dispatch(saveAssignId(val.length > 0 ? allIds : []));
    handleChange('assignId', val.length > 0 ? allIds : []);
  };

  // Fetch system roles when the tab changes
  useEffect(() => {
    if (systemRole?.length === 0) {
      dispatch(fetchSystemRole({ systemId: tabKey, roleId }));
    }
  }, [tabKey]);

  // Update values when editing an existing role
  useEffect(() => {
    if (roleId) {
      setValues((prev: any) => ({
        ...prev,
        selectValue: assignId,
      }));
      handleChange('assignId', assignId);
      dispatch(saveAssignId(assignId));
    }
  }, [roleId, assignId]);

  return (
    <div className="personnel-Tab">
      <div className="role-tree-section">
        <TreeViewMultiSelect
          listItems={systemRole}
          onSelect={handleTreeSelect}
          value={values?.selectValue}
          multiSelect
          checkboxSelection
        />
      </div>
      <div className="select-all">
        <CheckBoxInputField
          name="selectAll"
          data={[
            {
              label: 'Select All',
              id: 'all',
              value: 'all',
            },
          ]}
          value={values?.selectAll}
          onChangeFunction={selectAll}
          alignment="row"
        />
      </div>
    </div>
  );
}

export default SystemTab;
