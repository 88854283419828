import React, { useEffect, useState } from 'react';

// Use common modules
import { useTranslation } from 'react-i18next';
import { useAppDispatch } from '../../../redux/store';

// Use redux function
import { setNotificationError } from '../../../redux/slices/NotificationSlices';

// Use shared component
import SelectBoxField from '../../../shared-components/selectbox-field/selectbox-field';
import InputTextAreaField from '../../../shared-components/input-text-area-field/input-text-area-field';
import Button from '../../../shared-components/button/button';
import DatePickerField from '../../../shared-components/date-picker-field/date-picker-field';

// Use api calls
import PersonalApis from '../../../utils/api/person';

// Use assets json file
import DismissalType from '../../../assets/json/dismissal-type.json';
import { changeDateFormat } from '../../../utils/helpers';

function Dismissal({
  closeModal,
  selectedPerson,
  submitFunction,
}: {
  closeModal: () => void;
  selectedPerson: any[];
  submitFunction: () => void;
}) {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const selectData = DismissalType.map((val) => ({
    ...val,
    label: t(val.label),
  }));
  const [value, setValue] = useState({
    dismissalDate: '',
    dismissalType: '',
    dismissalReason: '',
    type: 'dismissal',
  });
  const [disableButton, setDisableButton] = useState(true);

  const onChange = (name: string, changeValue: any) =>
    setValue((prev: any) => ({
      ...prev,
      [name]: changeValue,
    }));

  const submit = () => {
    setDisableButton(true);
    const request = {
      leaveDate: changeDateFormat(value.dismissalDate),
      leaveReason: value.dismissalReason,
      leaveType: value.dismissalType,
      id: selectedPerson.join(','),
    };
    PersonalApis.editDismissedPersons(request)
      .then((response) => {
        if (response.data.code === 0) {
          setDisableButton(false);
          submitFunction();
        } else {
          setDisableButton(false);
          dispatch(
            setNotificationError({
              error: response.data.message,
              status: response.data.code,
            }),
          );
        }
      })
      .catch((error) => {
        setDisableButton(false);
        dispatch(
          setNotificationError({
            error: error.message,
            status: error.status,
          }),
        );
      });
  };

  useEffect(() => {
    if (value.dismissalDate !== '' && value.dismissalType !== '') {
      setDisableButton(false);
    } else {
      setDisableButton(true);
    }
  }, [value]);
  return (
    <div className="modal-page">
      <div className="statistics-page rows-2">
        <div className="item">
          <DatePickerField
            name="dismissalDate"
            label={t('dismissalDate')}
            value={value.dismissalDate}
            required
            onChangeFunction={onChange}
          />
        </div>
        <div className="item">
          <SelectBoxField
            name="dismissalType"
            label={t('dismissalType')}
            id="dismissalType"
            value={value.dismissalType}
            required
            onChangeFunction={(name, val) => {
              onChange(name, val.id);
            }}
            data={selectData}
          />
        </div>
        <div className="item span-2">
          <InputTextAreaField
            name="dismissalReason"
            label={t('dismissalReason')}
            id="dismissalReason"
            value={value.dismissalReason}
            onChangeFunction={onChange}
          />
        </div>
        <div className="item span-2">
          <div className="form-buttons-right">
            <Button onClickFunction={closeModal} title={t('close')} className="btn-default" />
            <Button
              onClickFunction={submit}
              title={t('ok')}
              disabled={disableButton}
              className="btn-primary btn-bold"
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default Dismissal;
