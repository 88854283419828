import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import Button from '../../../../shared-components/button/button';
import globalLinkage from '../../../../utils/api/AdvancedFunctions/globalLinkage';
import { useAppDispatch } from '../../../../redux/store';
import { setNotificationError } from '../../../../redux/slices/NotificationSlices';
import { eventCodes } from '../../../realTime-monitoring/options';
import Table from '../../../../shared-components/table/table';

interface ModalType {
  closeModal: () => void;
  addTriggerCondition: (val: any, tableType: 'doors' | 'inputEvent') => void;
  globalLinkageId: any;
  value: string[];
}

interface DoorEventProps {
  id: string;
  text: string;
  open?: number;
}

function LinkageGlobalTriggerConnections({
  closeModal,
  addTriggerCondition,
  globalLinkageId,
  value: triggerId,
}: ModalType) {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const [allEventData, setAllEventData] = useState<any[]>([]);
  const [doorEvent, setDoorEvent] = useState<string[]>([]);
  const [inputEvent, setInputEvent] = useState<string[]>([]);
  const [selectedFullData, setSelectedFullData] = useState<DoorEventProps[]>([]);
  const [currentTableType, setCurrentTableType] = useState<'doors' | 'inputEvent' | null>(null);

  const handleRowSelection = (selectedRows: string[], tableType: 'doors' | 'inputEvent') => {
    // Clear previous selections based on which table is being used
    if (tableType === 'doors') {
      setInputEvent([]);
      setDoorEvent(selectedRows);
    } else if (tableType === 'inputEvent') {
      setDoorEvent([]);
      setInputEvent(selectedRows);
    }

    // Always set the current table type, regardless of selection
    setCurrentTableType(tableType);

    // Determine which table to process
    const tableIndex = tableType === 'doors' ? 0 : 1;
    const tableItems = allEventData?.[tableIndex]?.item || [];

    // Guard against empty or undefined tableItems
    if (!tableItems || tableItems.length === 0) {
      console.warn('Table items are not loaded yet:', tableItems);
      setSelectedFullData([]);
      return;
    }

    let fullData: DoorEventProps[] = [];

    // Handle the selection
    if (selectedRows.length === 0) {
      fullData = [];
    } else {
      // If all rows are selected (header checkbox case)
      const allSelected = tableItems.every((item: DoorEventProps) =>
        selectedRows.includes(item.id),
      );

      if (allSelected && selectedRows.length === tableItems.length) {
        fullData = [...tableItems];
      } else {
        // Individual row selections
        fullData = tableItems.filter((dev: DoorEventProps) => selectedRows.includes(dev.id));
      }
    }

    setSelectedFullData(fullData);
  };

  const submit = () => {
    if (selectedFullData?.length) {
      addTriggerCondition(selectedFullData, 'doors');
      closeModal();
    }
  };
  // Log currentTableType whenever it changes
  useEffect(() => {}, [currentTableType]);

  const fetchData = (params: any) => {
    globalLinkage
      .getLinkTriggerOpt(params)
      .then((res) => {
        const updatedData = res.data.data.item.map((parent: any) => ({
          ...parent,
          item: parent.item
            .filter((child: any) => !triggerId?.includes(child.id))
            .map((child: any) => ({
              ...child,
              text: eventCodes[child.text] || child.text,
            })),
        }));
        setAllEventData(updatedData.length > 0 ? updatedData : []);
      })
      .catch((error) => {
        dispatch(setNotificationError(error.response?.data || error.msg));
      });
  };

  useEffect(() => {
    fetchData({ globalLinkageId });
  }, [globalLinkageId]);

  return (
    <div className="linkage-trigger-connections">
      <div className="table-container">
        <div className="table-wrapper">
          <h4 className="table-title">{t('doorEvent')}</h4>
          <Table
            header={[{ label: t('eventName'), id: 'text' }]}
            value={allEventData?.[0]?.item}
            checkBoxStatus
            selectedValues={doorEvent}
            onSelect={(rows) => {
              handleRowSelection(rows, 'doors');
            }}
            multiSelect
            getSelectedFullData={(value: any) => {
              setSelectedFullData(value);
            }}
          />
        </div>
        <div className="table-wrapper">
          <h4 className="table-title">{t('auxiliaryInputEvent')}</h4>
          <Table
            header={[{ label: t('eventName'), id: 'text' }]}
            value={allEventData?.[1]?.item}
            checkBoxStatus
            selectedValues={inputEvent}
            onSelect={(rows) => handleRowSelection(rows, 'inputEvent')}
            multiSelect
            getSelectedFullData={(value: any) => setSelectedFullData(value)}
            returnKey="id"
          />
        </div>
      </div>
      <div className="form-buttons-right add-margin">
        <Button onClickFunction={closeModal} title={t('cancel')} className="btn-default" />
        <Button
          onClickFunction={() => {
            submit();
          }}
          title={t('done')}
          className="btn-primary"
          disabled={selectedFullData.length === 0}
        />
      </div>
    </div>
  );
}

export default LinkageGlobalTriggerConnections;
