import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import CheckBoxInputField from '../../../../../shared-components/checkbox-input-field/checkbox-input-field';
import { AttentionIcon } from '../../../../../assets/svgicons/svgicon';

interface PersonalSensitiveInfoInterface {
  onChangeFunction: (name: any, changeValue: any) => void;
  values: any;
}

function PersonalSensitiveInformationProtection({
  onChangeFunction,
  values,
}: PersonalSensitiveInfoInterface) {
  const onChange = (name: any, changeValue: any) => {
    onChangeFunction(name, changeValue);
  };

  const { t } = useTranslation();

  // Convert string values to boolean values for checkboxes
  const [checkboxValues, setCheckboxValues] = useState({
    'systemEntry.name.encryptProp': values['systemEntry.name.encryptProp'] === 'true',
    'systemEntry.lastName.encryptProp': values['systemEntry.lastName.encryptProp'] === 'true',
    'systemEntry.email.encryptProp': values['systemEntry.email.encryptProp'] === 'true',
    'systemEntry.cmd.encryptProp': values['systemEntry.cmd.encryptProp'] === 'true',
  });

  // Update checkboxValues when values from parent component change
  useEffect(() => {
    setCheckboxValues({
      'systemEntry.name.encryptProp': values['systemEntry.name.encryptProp'] === 'true',
      'systemEntry.lastName.encryptProp': values['systemEntry.lastName.encryptProp'] === 'true',
      'systemEntry.email.encryptProp': values['systemEntry.email.encryptProp'] === 'true',
      'systemEntry.cmd.encryptProp': values['systemEntry.cmd.encryptProp'] === 'true',
    });
  }, [values]);

  const checkboxData = [
    {
      label: t('firstName'),
      id: 'systemEntry.name.encryptProp',
      value: 'systemEntry.name.encryptProp',
    },
    {
      label: t('lastName'),
      id: 'systemEntry.lastName.encryptProp',
      value: 'systemEntry.lastName.encryptProp',
    },
    {
      label: t('email'),
      id: 'systemEntry.email.encryptProp',
      value: 'systemEntry.email.encryptProp',
    },
    {
      label: t('deviceCommands'),
      id: 'systemEntry.cmd.encryptProp',
      value: 'systemEntry.cmd.encryptProp',
    },
  ];

  return (
    <div className="card-management">
      <div className="cloud-setting">
        <div className="cloud-setting-form w50">
          <div className="column">
            <div className="item checkbox-group">
              <CheckBoxInputField
                label=""
                name="personalSensitiveInfo"
                alignment="grid-2"
                data={checkboxData}
                value={checkboxData
                  .filter((item) => checkboxValues[item.value as keyof typeof checkboxValues])
                  .map((item) => item.value)}
                onChangeFunction={(name, selectedValues) => {
                  // Convert boolean values back to strings for parent component
                  const updatedSystemEntryMap: Record<string, string> = {
                    'systemEntry.name.encryptProp': 'false',
                    'systemEntry.lastName.encryptProp': 'false',
                    'systemEntry.email.encryptProp': 'false',
                    'systemEntry.cmd.encryptProp': 'false',
                    'systemEntry.name.encryptMode': values['systemEntry.name.encryptMode'] || '',
                    'systemEntry.lastName.encryptMode':
                      values['systemEntry.lastName.encryptMode'] || '',
                    'systemEntry.email.encryptMode': values['systemEntry.email.encryptMode'] || '',
                  };

                  // Set selected values to true
                  selectedValues.forEach((selectedValue) => {
                    updatedSystemEntryMap[selectedValue] = 'true';
                  });
                  // Send updated values to parent component
                  onChange('systemEntryMap', updatedSystemEntryMap);
                }}
              />
            </div>
            <div className="text-danger mt-4">
              <div className="note">
                <div className="alter-icon">
                  <AttentionIcon />
                </div>
                <div className="content">
                  {t(
                    'After enabling the personal sensitive information security protection option, the sensitive personal data involved in this module will be desensitized or obscured, including but not limited to names, card numbers, ID numbers, photos, etc.',
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default PersonalSensitiveInformationProtection;
