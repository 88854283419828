/* eslint-disable no-param-reassign */
import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';

// Use API calls
import auth from '../../utils/api/auth';

// Use other slices
import { setNotificationError } from './NotificationSlices';

// Use interface
import { AuthReduxInterface } from '../../interface/auth.interface';

// Define the async thunk for fetching the auth permission list
export const fetchAuthHome = createAsyncThunk('auth/fetchAuthHome', async (_, { dispatch }) => {
  const response = await auth
    .authHome()
    .then((response1) => {
      return {
        ...response1.data,
        data: {
          list: response1?.data?.data?.systemMenus,
        },
      };
    })
    .catch((error) => {
      dispatch(setNotificationError(error.response.data));
      return error;
    });
  return response;
});

// Define the async thunk for fetching the auth load menus list
export const fetchLoadMenus = createAsyncThunk(
  'auth/fetchLoadMenus',
  async (data: any, { dispatch }) => {
    const response = await auth
      .loadMenus(data)
      .then((response1) => {
        return {
          data: {
            list: response1?.data,
          },
        };
      })
      .catch((error) => {
        dispatch(setNotificationError(error.response.data));
        return error;
      });
    return response;
  },
);

// Define the async thunk for fetching the auth load menus list
export const checkIsNeedValidUser = createAsyncThunk(
  'auth/checkIsNeedValidUser',
  async (data: any, { dispatch }) => {
    const response = await auth
      .isNeedValidUser(data)
      .then((response1) => {
        return response1?.data?.success;
      })
      .catch((error) => {
        dispatch(
          setNotificationError({
            error: error?.response?.data?.msg || error.message,
            status: error?.status || 500,
          }),
        );
        return error;
      });
    return response;
  },
);

const initialState: AuthReduxInterface = {
  authMenus: [],
  loadMenus: [],
  // temporarily save the system code after implement dashboard need to change this
  systemCode: '',
  loading: false,
  isNeedValidUser: false,
};

// Create the slice
export const AuthSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    saveSystemCode: (state, action: PayloadAction<any>) => {
      state.systemCode = action.payload;
    },
    resetAuthState: (state) => {
      Object.assign(state, initialState);
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchAuthHome.pending, () => {})
      .addCase(fetchAuthHome.fulfilled, (state, action: any) => {
        if (action.payload.success) {
          state.authMenus = action.payload.data.list;
        }
      })
      .addCase(fetchAuthHome.rejected, () => {})
      .addCase(fetchLoadMenus.pending, (state: any) => {
        state.loadMenus = [];
        state.loading = true;
      })
      .addCase(fetchLoadMenus.fulfilled, (state, action: any) => {
        if (action.payload.data) {
          state.loadMenus = action.payload.data.list;
          state.loading = false;
        }
      })
      .addCase(fetchLoadMenus.rejected, (state: any) => {
        state.loading = false;
      })
      .addCase(checkIsNeedValidUser.fulfilled, (state, action: any) => {
        if (action.payload) {
          state.isNeedValidUser = action.payload;
        }
      });
  },
});

// Action creators are generated for each case reducer function
export const { saveSystemCode, resetAuthState } = AuthSlice.actions;

// Export the reducer
export default AuthSlice.reducer;
