import { type UIEvent, useEffect, useRef, useState } from 'react';

// Use common modules
import {
  MaterialReactTable,
  useMaterialReactTable,
  type MRT_ColumnFiltersState,
  type MRT_SortingState,
  type MRT_RowVirtualizer,
  type MRT_RowSelectionState,
  type MRT_PaginationState,
} from 'material-react-table';
import {
  MRT_ToggleDensePaddingButton as ToggleDensePaddingButton,
  MRT_ToggleFullScreenButton as ToggleFullScreenButton,
  MRT_ToggleFiltersButton as ToggleFiltersButton,
  MRT_ShowHideColumnsButton as ShowHideColumnsButton,
} from 'material-react-table';
import { createTheme, ThemeProvider, Tooltip, Typography } from '@mui/material';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';

// Use interface
import { DataTableInterFace } from '../../interface/table.interface';
import { RefreshIcon } from '../../assets/svgicons/svgicon';
import EmptyContent from '../empty-content/EmptyContent';

function Example({
  action = [],
  actionButtonStatus,
  columns,
  checkBoxActive,
  singleRowSelection = false,
  getFilterData = (val = {}) => {
    console.log(val);
  },
  getSelectedData = () => {},
  getSelectedFullData,
  fetchNextPage = () => {},
  assignFilterValue = {},
  assignSelectedData = [],
  isFetching,
  values,
  fixedColumn = [],
  totalRecords,
  height = 0,
  enableColumnFilters = false,
  enableFullScreenToggle = true,
  enableDensityToggle = true,
  enableColumnPinning = true,
  enableHiding = true,
  enableRefresh = false,
  enableGlobalFilter = true,
  showCustomActions = true,
  handleRefresh = () => {},
  enableColumnResizing = true,
  enablePagination = false,
  showGlobalFilter = true,
  showToolbar = true,
}: DataTableInterFace) {
  const { t } = useTranslation();
  const tableContainerRef = useRef<HTMLDivElement>(null);
  const rowVirtualizerInstanceRef = useRef<MRT_RowVirtualizer>(null);
  const defaultSelectedData =
    assignSelectedData.length > 0
      ? assignSelectedData.reduce((a, v) => ({ ...a, [v]: true }), {})
      : {};

  const [columnFilters, setColumnFilters] = useState<MRT_ColumnFiltersState>(
    assignFilterValue.columnFilters || [],
  );
  const [globalFilter, setGlobalFilter] = useState<string>(assignFilterValue.globalFilter || '');
  const [sorting, setSorting] = useState<MRT_SortingState>(assignFilterValue.sorting || []);
  const [rowSelection, setRowSelection] = useState<MRT_RowSelectionState>(defaultSelectedData);
  const [pagination, setPagination] = useState<MRT_PaginationState>({
    pageIndex: 0,
    pageSize: 10,
  });
  const [rowsPerPageOptions, setRowsPerPageOptions] = useState([10]);
  const isLoading = false;
  const theme = createTheme({
    typography: {
      fontFamily: 'Urbanist, sans-serif',
    },
    components: {
      MuiTableCell: {
        styleOverrides: {
          root: {
            fontFamily: 'Urbanist, sans-serif',
            color: '#696C6F',
          },
          head: {
            color: '#292D31',
          },
        },
      },
      MuiPaper: {
        styleOverrides: {
          root: {
            boxShadow: 'none',
          },
        },
      },
      MuiPopover: {
        styleOverrides: {
          paper: {
            boxShadow: '0px 1px 8px 0px #0000001A',
          },
        },
      },
      MuiMenu: {
        styleOverrides: {
          paper: {
            boxShadow: '0px 1px 8px 0px #0000001A',
          },
        },
      },
      MuiTableBody: {
        styleOverrides: {
          root: {
            minHeight: '150px !important', // Set your desired min-height
          },
        },
      },
    },
  });
  const getFilterFormat = () => {
    const filter: any = {};
    columnFilters.forEach((fil: any) => {
      filter[fil.id] = fil.value;
    });
    if (globalFilter) {
      filter.search = globalFilter;
    }
    if (sorting.length > 0) {
      filter.sortField = sorting[0].id;
      filter.sortOrder = sorting[0].desc ? 'desc' : 'asc';
    }
    return filter;
  };

  const fetchMoreOnBottomReached = (containerRefElement?: HTMLDivElement | null) => {
    if (containerRefElement) {
      const { scrollHeight, scrollTop, clientHeight } = containerRefElement;
      if (scrollTop + clientHeight >= scrollHeight && !isFetching && values.length < totalRecords) {
        fetchNextPage();
      }
    }
  };

  /**
   * Generates action buttons for each row in the data table.
   *
   * @param {Object} rowData - The data for the current row.
   * @returns {Array} An array of JSX elements representing the action buttons.
   *
   * @description
   * This function maps over the `action` array to create buttons for each action.
   * It applies the following logic:
   * - Only renders buttons where `button.enable` is true.
   * - Disables the delete button if `rowData.delete` is false and `button.disabled` is true.
   */

  const actionButtons = (rowData: any): Array<any> =>
    action?.map((button: any) => {
      // Check if the label is 'delete' and disable the button if delete is false in the row data
      const isDeleteDisabled = rowData.delete === false && button.disabled;
      return (
        button?.enable && (
          <div
            className={`table-menu ${isDeleteDisabled ? 'disabled' : ''}`}
            onClick={() => {
              if (!isDeleteDisabled) {
                button.function(rowData);
              }
            }}
            role="presentation">
            {button.label}
          </div>
        )
      );
    });
  const handleRowClick = (row: any) => {
    setRowSelection((prev) => {
      // Create a new object to avoid mutating the state directly
      let newSelection = { ...prev };
      if (singleRowSelection) {
        // For single row selection, clear previous selections and select only the clicked row
        newSelection = { [row.id]: true };
      } else if (!singleRowSelection) {
        // For multiple row selection, toggle the clicked row's selection state
        if (newSelection[row.id]) {
          delete newSelection[row.id];
        } else {
          newSelection[row.id] = true;
        }
      }
      // Get the full row data of the currently selected rows
      const selectedRows = Object.keys(newSelection).map((rowId) =>
        values.find((r) => r.id === rowId),
      );
      // Call the selection data functions with the updated selection
      getSelectedData(Object.keys(newSelection));
      if (getSelectedFullData) {
        getSelectedFullData(selectedRows);
      }
      return newSelection;
    });
  };
  const handleCheckboxClick = (e: React.MouseEvent, row: any) => {
    e.stopPropagation();
    setRowSelection((prev) => {
      const newSelection = { ...prev };
      if (newSelection[row.id]) {
        delete newSelection[row.id];
      } else {
        newSelection[row.id] = true;
      }
      const selectedRows = Object.keys(newSelection).map((rowId) =>
        values.find((r) => r.id === rowId),
      );
      // Get the full row data of the currently selected rows
      getSelectedData(Object.keys(newSelection));
      if (getSelectedFullData) {
        getSelectedFullData(selectedRows);
      }
      return newSelection;
    });
  };
  useEffect(() => {
    try {
      getFilterData({
        filter: getFilterFormat(),
        columnFilters,
        globalFilter,
        sorting,
        pageIndex: pagination.pageIndex + 1,
        pageSize: pagination.pageSize,
      });
    } catch (error) {
      console.error(error);
    }
  }, [sorting, columnFilters, globalFilter, pagination.pageIndex, pagination.pageSize]);

  // a check on mount to see if the table is already scrolled to the bottom and immediately needs to fetch more data
  useEffect(() => {
    fetchMoreOnBottomReached(tableContainerRef.current);
  }, [values]);

  useEffect(() => {
    setRowSelection(defaultSelectedData);
  }, [assignSelectedData]);

  useEffect(() => {
    if (totalRecords > 10) {
      setRowsPerPageOptions([10, 50, 100]);
    } else {
      setRowsPerPageOptions([10]);
    }
  }, [totalRecords]);

  const table = useMaterialReactTable({
    columns,
    data: values,
    enablePagination,
    enableColumnPinning,
    enableRowNumbers: false,
    enableColumnFilters,
    enableRowVirtualization: true,
    manualFiltering: true,
    manualPagination: true,
    enableRowActions: actionButtonStatus,
    enableFullScreenToggle,
    enableDensityToggle,
    enableHiding,
    enableGlobalFilter,
    displayColumnDefOptions: {
      'mrt-row-actions': {
        header: t('action'),
      },
    },
    positionActionsColumn: 'last',
    muiTableContainerProps: {
      ref: tableContainerRef,
      sx: { maxHeight: `${window.innerHeight - height}px` },
      onScroll: (event: UIEvent<HTMLDivElement>) =>
        fetchMoreOnBottomReached(event.target as HTMLDivElement), // add an event listener to the table container element
    },
    renderRowActionMenuItems: ({ row }) => actionButtons(row.original),
    onColumnFiltersChange: setColumnFilters,

    // Sorting order method
    onSortingChange: setSorting,
    manualSorting: true,

    // pagination
    rowCount: totalRecords,

    // Global filter textbox
    positionGlobalFilter: 'left',
    onGlobalFilterChange: setGlobalFilter,
    onPaginationChange: (newPagination) => {
      setPagination(newPagination); // Update pagination state
    },
    muiPaginationProps: {
      rowsPerPageOptions, // Customize rows per page options
    },
    muiSearchTextFieldProps: {
      placeholder: 'Search All Data',
      sx: { minWidth: '300px', borderRadius: '8px' },
      variant: 'outlined',
    },

    // Check box of records
    enableRowSelection: checkBoxActive,
    positionToolbarAlertBanner: showCustomActions ? 'bottom' : 'none',
    onRowSelectionChange: (
      updaterOrValue:
        | MRT_RowSelectionState
        | ((old: MRT_RowSelectionState) => MRT_RowSelectionState),
    ) => {
      setRowSelection((prevSelection) => {
        // Determine the new selection state
        let newSelection =
          typeof updaterOrValue === 'function'
            ? updaterOrValue(prevSelection) // If it's a function, pass in the previous selection
            : updaterOrValue; // Otherwise, it's the new selection object
        // Loop through the new selection data
        if (singleRowSelection) {
          // Keep only one row selected at a time for single row selection
          const selectedRowId = Object.keys(newSelection)[0];
          newSelection = selectedRowId ? { [selectedRowId]: true } : {};
        }
        Object.keys(newSelection).forEach((key) => {
          if (newSelection[key]) {
            // Add the row to the selection if it's checked
            newSelection[key] = true;
          } else {
            // Remove the row from the selection if it's unchecked
            delete newSelection[key];
          }
        });
        // Get the selected rows' data based on the new selection state
        const selectedRows = Object.keys(newSelection).map((rowId) =>
          values.find((row) => row.id === rowId),
        );
        // Pass the currently selected rows to getSelectedData
        // getSelectedData(Object.keys(newSelection));
        if (getSelectedFullData) {
          getSelectedFullData(selectedRows);
        }
        // Return the updated selection state
        return newSelection;
      });
    },
    muiSelectCheckboxProps: ({ row }) => ({
      onClick: (e) => handleCheckboxClick(e, row),
      checked: !!rowSelection[row.id],
      color: 'primary',
    }),
    getRowId: (row) => row.id,
    muiTableBodyRowProps: ({ row }) => ({
      onClick: () => handleRowClick(row),
    }),
    renderBottomToolbarCustomActions: () =>
      showToolbar ? (
        <Typography>
          {t('fetched')} {values.length} {t('of')} {totalRecords} {t('totalRows')}.
        </Typography>
      ) : null,
    renderToolbarInternalActions: () => (
      <div className="table-header-right">
        {enableRefresh && (
          <Tooltip title="Refresh">
            <div className="refresh-button" role="presentation" onClick={handleRefresh}>
              <RefreshIcon />
            </div>
          </Tooltip>
        )}
        {enableColumnFilters && <ToggleFiltersButton table={table} />}
        {enableHiding && <ShowHideColumnsButton table={table} />}
        {enableDensityToggle && <ToggleDensePaddingButton table={table} />}
        {enableFullScreenToggle && <ToggleFullScreenButton table={table} />}
      </div>
    ),
    initialState: {
      columnPinning: { left: ['mrt-row-select', ...fixedColumn], right: ['mrt-row-actions'] },
      showColumnFilters: columnFilters.length > 0,
      showGlobalFilter,
    },
    state: {
      columnFilters,
      globalFilter,
      isLoading,
      rowSelection,
      showAlertBanner: false,
      showProgressBars: isFetching,
      sorting,
      pagination,
    },
    rowVirtualizerInstanceRef, // get access to the virtualizer instance
    // rowVirtualizerOptions: { overscan: 4 },
    muiTableHeadCellProps: ({ column }) => ({
      sx: {
        backgroundColor: column.getIsPinned() ? '' : '#F6F6F7',
      },
    }),
    layoutMode: 'grid',
    enableColumnResizing,
    renderEmptyRowsFallback: () => <EmptyContent />,
  });

  return (
    <ThemeProvider theme={theme}>
      <MaterialReactTable table={table} />
    </ThemeProvider>
  );
}

const queryClient = new QueryClient();

function DataTable(data: DataTableInterFace) {
  return (
    <QueryClientProvider client={queryClient}>
      <Example {...data} />
    </QueryClientProvider>
  );
}

export default DataTable;
