import React from 'react';
import { CardStatusProps } from '../../interface/common.interface';

function CardStatus({
  data,
  header,
  click = () => {},
  onSelect = () => {},
  isSelected = false,
  // index = 0,
  footerLabel,
  enableFooter = false,
}: CardStatusProps) {
  return (
    <div
      className={`card-status ${isSelected ? 'selected' : ''}`}
      role="presentation"
      onClick={() => onSelect(data)}>
      {/* Card Title */}
      <div className="card-title">{data?.title}</div>

      {/* Card Body */}
      <div className="card-body">
        {header.map(({ label, key }) => (
          <div className="content-row" key={key}>
            <span className="left">{label}</span>
            {key === 'status' ? (
              <div className={`badge ${data[key]}`}>{data[key]}</div>
            ) : (
              <span className="right">{data[key]}</span>
            )}
          </div>
        ))}
      </div>

      {/* Optional Card Footer */}
      {click && enableFooter && footerLabel && (
        <div className="card-footer">
          <span
            onClick={(e) => {
              e.stopPropagation();
              click();
            }}
            role="presentation">
            {footerLabel}
          </span>
        </div>
      )}
    </div>
  );
}

export default CardStatus;
