import { post, get } from '../base/index';

export default {
  listByPage: (data: any = {}) => {
    return post(`${process.env.REACT_APP_API_URL}Acc/api/accFirstOpen/list`, {}, { ...data });
  },
  getPersonListInFirstOpen: (data: any = {}) => {
    return post(`${process.env.REACT_APP_API_URL}Acc/api/accFirstOpen/personList`, data);
  },

  getTimeSegList: () => {
    return get(`${process.env.REACT_APP_API_URL}Acc/api/timeSeg/getTimeSegList`, {});
  },

  selectDoorlist: (data: any = {}) => {
    return post(`${process.env.REACT_APP_API_URL}Acc/api/accFirstOpen/selectDoorlist`, data, {});
  },

  validBackgroundVerify: (data: string) =>
    post(
      `${process.env.REACT_APP_API_URL}Acc/api/accCombOpenDoor/validBackgroundVerify?doorId=${data}`,
      {},
    ),

  saveFirstPerson: (data: any = {}) => {
    return post(`${process.env.REACT_APP_API_URL}Acc/api/accFirstOpen/save`, data, {});
  },

  editFirstPerson: (id: string = '') => {
    return post(`${process.env.REACT_APP_API_URL}Acc/api/accFirstOpen/edit?ids=${id}`, {});
  },

  deleteFirstPerson: (data: any = {}) => {
    return post(`${process.env.REACT_APP_API_URL}Acc/api/accFirstOpen/delete`, {}, data);
  },

  delPerson: (data: any = {}) => {
    return post(
      `${process.env.REACT_APP_API_URL}Acc/api/accFirstOpen/delPerson?firstOpenId=${data.firstOpenId}&personIds=${data.personIds}`,
      {},
    );
  },
  addPerson: (data: any = {}) => {
    return post(`${process.env.REACT_APP_API_URL}Acc/api/accFirstOpen/addPerson`, {}, data);
  },
  getPersonCountByDept: (data: any = {}) => {
    return post(`${process.env.REACT_APP_API_URL}Acc/api/accPerson/getPersonCountByDept`, {}, data);
  },
};
