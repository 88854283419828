import { post } from '../base/index';

export default {
  saveList: (params: any = {}) => {
    return post(`${process.env.REACT_APP_API_URL}Sys/api/baseDataClean/save`, params);
  },

  immediateClean: (data: any = {}) =>
    post(`${process.env.REACT_APP_API_URL}Sys/api/baseDataClean/immediateClean`, {}, data),
};
