import { fetch, get, post } from '../base/index';

export default {
  getListPage: (data: any = {}) => {
    return post(`${process.env.REACT_APP_API_URL}Sys/api/baseDbBackup/listByPage`, {}, { ...data });
  },
  clearData: () => {
    return post(`${process.env.REACT_APP_API_URL}Sys/api/alarmMonitorReport/clearData`, {});
  },
  export: (data: any) => {
    return fetch(`${process.env.REACT_APP_API_URL}Sys/api/transaction/export`, 'POST', data);
  },
  backupTestConnection: () => {
    return get(`${process.env.REACT_APP_API_URL}Sys/api/baseDbBackup/backupTestConnection`, {});
  },
  immeBackup: (data: any = {}) => {
    return post(`${process.env.REACT_APP_API_URL}Sys/api/baseDbBackup/immeBackup`, {}, data);
  },
  getStartTime: () => {
    return get(`${process.env.REACT_APP_API_URL}Sys/api/baseDbBackup/getStartTime`, {});
  },
  getFtpSetting: () => {
    return get(`${process.env.REACT_APP_API_URL}Sys/api/baseDbBackup/getFtpSetting`, {});
  },
  resourceFileBackup: () => {
    return get(`${process.env.REACT_APP_API_URL}Sys/api/baseDbBackup/resourceFileBackup`, {});
  },
  opDownloadFile: (data: any) => {
    return fetch(
      `${process.env.REACT_APP_API_URL}Sys/api/baseDbBackup/opDownloadFile`,
      'POST',
      data,
    );
  },
  testFTPConnection: (data: any = {}) => {
    return post(`${process.env.REACT_APP_API_URL}Sys/api/baseDbBackup/opDownloadFile`, {}, data);
  },
  delete: (data: any = {}) => {
    return post(`${process.env.REACT_APP_API_URL}Sys/api/baseDbBackup/delete`, {}, data);
  },
  planBackup: (params: any = {}) => {
    return get(`${process.env.REACT_APP_API_URL}Sys/api/baseDbBackup/planBackup`, params);
  },
};
