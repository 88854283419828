import React from 'react';
// Use Translation
import { useTranslation } from 'react-i18next';
// Use interface
import FormRightButton from '../../../../../shared-components/form-right-buttons/FormRightButton';

interface ClientResetProps {
  closeModal: () => void;
  disabled?: boolean;
}

function ClientReset({ closeModal, disabled = false }: ClientResetProps) {
  const { t } = useTranslation();

  const submit = () => {
    console.log('hai');
  };

  return (
    <div className="delete-box">
      <div className="message-text">
        <p>{t('areYouSureYouWantToPerformTheResetOperation')}</p>
      </div>
      <FormRightButton
        cancelLabel={t('cancel')}
        cancelFunction={closeModal}
        submitLabel={t('done')}
        submitFunction={submit}
        disabled={disabled}
      />
    </div>
  );
}
ClientReset.defaultProps = {
  disabled: '',
};
export default ClientReset;
