import {
  RemoteOpeningIcon,
  RemoteClosingIcon,
  CancelAlarmIcon,
  RemoteNormallyOpenIcon,
  ExportAccessLevelIcon,
  ImportAccessLevelIcon,
  ActivatesLockdownIcon,
  DeactivatesLockdownIcon,
  EnablesIndradayIcon,
  DisablesIndradayIcon,
} from '../../../assets/svgicons/svgicon';
import { LoadMenusProps } from '../../../interface/auth.interface';
import { OptionType } from '../../../interface/common.interface';
import { checkPermission } from '../../../utils/role-permission';

/**
 * Generates a list of control options based on user permissions.
 *
 * @param {LoadMenusProps[]} permissions - The list of user permissions.
 * @returns {OptionType[]} The filtered list of control options.
 */

const controlOptions = (permissions: LoadMenusProps[]): OptionType[] => {
  const baseControlOption: OptionType[] = [
    {
      value: 'remoteOpening',
      label: 'remoteOpening',
      icon: RemoteOpeningIcon,
      enable: checkPermission('acc:level:openDoor', permissions),
    },
    {
      value: 'remoteClosing',
      label: 'remoteClosing',
      icon: RemoteClosingIcon,
      enable: checkPermission('acc:level:closeDoor', permissions),
    },
    {
      value: 'cancelAlarm',
      label: 'cancelAlarm',
      icon: CancelAlarmIcon,
      enable: checkPermission('acc:level:cancelAlarmDoor', permissions),
    },
    {
      value: 'remoteNormallyOpen',
      label: 'remoteNormallyOpen',
      icon: RemoteNormallyOpenIcon,
      enable: checkPermission('acc:level:normalOpenDoor', permissions),
    },
    {
      value: 'activateLockdown',
      label: 'activateLockdown',
      icon: ActivatesLockdownIcon,
      enable: checkPermission('acc:level:lockDoor', permissions),
    },
    {
      value: 'deActivateLockdown',
      label: 'deActivateLockdown',
      icon: DeactivatesLockdownIcon,
      enable: checkPermission('acc:level:unlockDoor', permissions),
    },
    {
      value: 'enableIntradayPassageMode',
      label: 'enableIntradayPassageMode',
      icon: EnablesIndradayIcon,
      enable: checkPermission('acc:level:enableNormalOpenDoor', permissions),
    },
    {
      value: 'disableIntradayPassageMode',
      label: 'disableIntradayPassageMode',
      icon: DisablesIndradayIcon,
      enable: checkPermission('acc:level:disableNormalOpenDoor', permissions),
    },
  ];
  return baseControlOption?.filter((option) => option?.enable);
};

/**
 * Generates a list of export options based on user permissions.
 *
 * @param {LoadMenusProps[]} permissions - The list of user permissions.
 * @returns {OptionType[]} The filtered list of control options.
 */

const exportOptions = (permissions: LoadMenusProps[]) => {
  const exportOptionBase: OptionType[] = [
    {
      value: 'exportAccessLevel',
      label: 'exportAccessLevel',
      icon: ExportAccessLevelIcon,
      enable: checkPermission('acc:level:export', permissions),
    },
    {
      value: 'exportDoorsOfAccessLevel',
      label: 'exportDoorsOfAccessLevel',
      icon: ExportAccessLevelIcon,
      enable: checkPermission('acc:levelDoor:export', permissions),
    },
  ];
  return exportOptionBase?.filter((option) => option?.enable);
};

/**
 * Generates a list of import options based on user permissions.
 *
 * @param {LoadMenusProps[]} permissions - The list of user permissions.
 * @returns {OptionType[]} The filtered list of control options.
 */

const importOptions = (permissions: LoadMenusProps[]) => {
  const importOptionBase: OptionType[] = [
    {
      value: 'importAccessLevel',
      label: 'importAccessLevel',
      icon: ImportAccessLevelIcon,
      enable: checkPermission('acc:level:import', permissions),
    },
    {
      value: 'importDoorsOfAccessLevel',
      label: 'importDoorsOfAccessLevel',
      icon: ImportAccessLevelIcon,
      enable: checkPermission('acc:levelDoor:import', permissions),
    },
  ];
  return importOptionBase?.filter((option) => option?.enable);
};

export { controlOptions, exportOptions, importOptions };
