import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
// import { useAppDispatch } from '../../../../../redux/store';
import Button from '../../../../../shared-components/button/button';
import InputField from '../../../../../shared-components/input-field/input-field';
import { useAppDispatch } from '../../../../../redux/store';
import databaseManagement from '../../../../../utils/api/SystemManagement/databaseManagement';
import { setNotificationError } from '../../../../../redux/slices/NotificationSlices';

interface ModalType {
  closeModal: () => void;
}

function FTPServerSettings({ closeModal }: ModalType) {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const [values, setValues] = useState({
    ftpAddr: '',
    ftpPort: '',
    ftpDir: '',
    ftpUsername: '',
    ftpPwd: '',
  });
  const [disable, setDisable] = useState(false);
  const handleChange = (name: string, value: any) => {
    setValues((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const getFtp = async () => {
    try {
      const response = await databaseManagement.getFtpSetting();

      if (response?.data.success) {
        setValues((prev) => ({
          ...prev,
          ...response?.data?.data?.ftpParams,
        }));
      } else {
        dispatch(
          setNotificationError({
            error: response.data.msg,
            status: response.data.code || 500,
          }),
        );
      }
    } catch (error: any) {
      dispatch(
        setNotificationError({
          error: error.message,
          status: error.status,
        }),
      );
    }
  };

  const testConnection = async () => {
    try {
      const response = await databaseManagement.testFTPConnection(values);
      if (response?.data.success) {
        dispatch(
          setNotificationError({
            error: t('connectionSuccessful'),
            status: 200,
          }),
        );
      } else {
        dispatch(
          setNotificationError({
            error: response.data.msg,
            status: response.data.code || 500,
          }),
        );
      }
    } catch (error: any) {
      dispatch(
        setNotificationError({
          error: error.message,
          status: error.status,
        }),
      );
    }
  };

  useEffect(() => {
    getFtp();
  }, []);

  useEffect(() => {
    if (values?.ftpAddr && values?.ftpPort && values?.ftpUsername && values?.ftpPwd) {
      setDisable(false);
    } else {
      setDisable(true);
    }
  }, [values]);

  return (
    <div className="ftp-server-settings flex-row column">
      <div className="flex-row">
        <InputField
          name="ftpAddr"
          label={t('ftpServerAddress')}
          id="ftpAddr"
          type="text"
          value={values.ftpAddr}
          innerIconStatus={false}
          onChangeFunction={handleChange}
          required
        />
        <InputField
          name="ftpPort"
          label={t('port')}
          id="ftpPort"
          type="text"
          value={values.ftpPort}
          innerIconStatus={false}
          onChangeFunction={handleChange}
          required
        />
      </div>
      <div className="flex-row">
        <InputField
          name="ftpUsername"
          label={t('userName')}
          id="ftpUsername"
          type="text"
          value={values.ftpUsername}
          innerIconStatus={false}
          onChangeFunction={handleChange}
          required
        />
        <InputField
          name="ftpPwd"
          label={t('password')}
          id="ftpPwd"
          type="text"
          value={values.ftpPwd}
          innerIconStatus={false}
          onChangeFunction={handleChange}
          required
        />
      </div>
      <div className="single-row">
        <InputField
          name="ftpDir"
          label={t('folderLocation')}
          id="ftpDir"
          type="text"
          value={values.ftpDir}
          innerIconStatus={false}
          onChangeFunction={handleChange}
        />
      </div>
      <div className="test-btn">
        <Button
          onClickFunction={testConnection}
          title={t('testConnection')}
          className="btn-outline-primary"
          disabled={disable}
        />
      </div>
      <div className="form-buttons-right">
        <Button onClickFunction={() => closeModal()} title={t('cancel')} className="btn-default" />
        <Button
          onClickFunction={closeModal}
          title={t('done')}
          className="btn-primary"
          disabled={disable}
        />
      </div>
    </div>
  );
}

export default FTPServerSettings;
