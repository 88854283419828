import { configureStore } from '@reduxjs/toolkit';
import { useDispatch, useSelector } from 'react-redux';

// Use all slices
import { AuthSlice } from './slices/AuthSlices';
import { personSlice } from './slices/PersonSlices';
import { notificationSlice } from './slices/NotificationSlices';
import { departmentSlice } from './slices/DepartmentSlices';
import { positionSlice } from './slices/PositionSlices';
import { dismissalPersonnelSlice } from './slices/DismissalPersonSlices';
import { librarySlice } from './slices/LibrarySlices';
import { customAttributesSlices } from './slices/CustomAttributesSlices';
import { pendingReviewSlices } from './slices/PendingReviewSlices';
import { parameterSlices } from './slices/ParameterSlices';
import { wiegandSlice } from './slices/WiegantSlice';
import { cardSlice } from './slices/CardSlice';
import { issueCardSlice } from './slices/IssueCardSlice';
import { doorSlice } from './slices/DoorSlice';
import { deviceSlice } from './slices/DeviceSlice';
import { accessByDepartmentSlice } from './slices/AccessByDepartmentSlices';
import { accessByLevelSlice } from './slices/AccessByLevelSlices';
import { accessByPersonSlice } from './slices/AccessByPersonSlices';
import { readerSlice } from './slices/ReaderSlice';
import { IoBoardSlice } from './slices/AccIOBoardSlices';
import { auxiliaryInputSlice } from './slices/AuxiliaryInputSlice';
import { auxiliaryOutputSlice } from './slices/AuxiliaryOutputSlice';
import { daylightSavingTimeOutputSlice } from './slices/DayLightSavingTimeSlice';
import { eventTypeSlice } from './slices/EventTypeSlice';
import { holidaysSlice } from './slices/HolidaysSlices';
import { alarmMonitoringSlice } from './slices/AlarmMonitoringSlices';
import { multiPersonOpeningDoorSlice } from './slices/MultiPersonOpeningDoorSlices';
import { timeZoneSlice } from './slices/TimeZoneSlices';
import { mapSlice } from './slices/MapSlices';
import { VerifyModeGroupSlice } from './slices/VerifyModeGroupSlices';
import { accessLevelSlice } from './slices/AccessLevelSlices';
import { multiPersonGroupSlice } from './slices/MultiPersonGroupSlices';
import { linkageSlice } from './slices/LinkageSlices';
import { zoneSlice } from './slices/ZoneSlices';
import { firstPersonNormallyOpenSlice } from './slices/FirstPersonNormallyOpenSlices';
import { VerificationModeSlice } from './slices/VerificationModeSlices';
import { interlockSlice } from './slices/InterlockSlices';
import { antiPassBackSlice } from './slices/AntiPassBackSlices';
import { globalInterlockSlice } from './slices/GlobalInterlockSlices';
import { globalLinkageSlice } from './slices/GlobalLinkageSlices';
import { globalInterlockGroupSlice } from './slices/GlobalInterlockGroupSlices';
import { readerDefineSlice } from './slices/ReaderDefineSlices';
import { areaHeadCountSlice } from './slices/areaHeadCountSlices';
import { globalAntiPassBackSlice } from './slices/GlobalAntipassbackSlices';
import { occupancyControlSlice } from './slices/OccupancyControlSlices';
import { personAvailabilitySlice } from './slices/PersonAvailabilitySlices';
import { musterPointSlice } from './slices/MusterPointSlices';
import { allTransactionsSlice } from './slices/AllTransactionReportSlices';
import { eventsFromTodaySlice } from './slices/EventsFromTodaySlices';
import { allExceptionEventsSlice } from './slices/AllExceptionEventsSlices';
import { alarmLogReportSlice } from './slices/AlarmLogReportSlices';
import { alarmProcessingHistorySlice } from './slices/AlarmProcessingHistorySlices';
import { firstInLastOutReportSlice } from './slices/FirstInLastOutSlices';
import { accessRightsByPersonnelSlice } from './slices/AccessRightsByPersonnel';
import { accessRightByDoorSlice } from './slices/AccessRightsByDoorSlices';
import { musterPointReportSlice } from './slices/MusterPointReportSlices';
import { emailManagementSlice } from './slices/system-management/EmailManagementSlices';
import { systemParameterSlice } from './slices/system-management/SystemParameterSlices';
import { dictionaryManagementSlice } from './slices/system-management/DictionaryManagementSlices';
import { resourceFileSlice } from './slices/system-management/ResourceFileSlices';
import { certificateTypeSlice } from './slices/system-management/CertificateTypeSlices';
import { areaSettingsSlice } from './slices/system-management/AreaSettings';
import { operationLogSlice } from './slices/system-management/OperationLogSlices';
import { DatabaseManagementSlice } from './slices/system-management/DataBaseMgtSlices';
import { ClientRegisterSlice } from './slices/system-management/clientRegisterSlices';
import { AuthorityManagementUserSlice } from './slices/system-management/AuthorityManagementUser';
import { securitySettingsSlice } from './slices/system-management/SecuritySettingsSlice';
import { RoleSlices } from './slices/system-management/RoleSlices';
import { ApiAuthorizationSlice } from './slices/system-management/APIAuthorization';
import { DevCommandSlices } from './slices/system-management/DeviceCommandSlices';
import { CommunicationMonitorSlices } from './slices/system-management/CommunicationMonitorSlices';
import { CommunicationDeviceSlice } from './slices/system-management/CommunicationDeviceSlices';
import { AuthDeviceSlices } from './slices/system-management/AuthorizedDeviceSlices';
import { SystemManagementParametersSlices } from './slices/system-management/SystemManagementParametersSlices';

// store the all data
export const store = configureStore({
  reducer: {
    auth: AuthSlice.reducer,
    persons: personSlice.reducer,
    departments: departmentSlice.reducer,
    positions: positionSlice.reducer,
    notification: notificationSlice.reducer,
    dismissalPerson: dismissalPersonnelSlice.reducer,
    library: librarySlice.reducer,
    customAttribute: customAttributesSlices.reducer,
    pendingReviews: pendingReviewSlices.reducer,
    parameters: parameterSlices.reducer,
    wiegandFormat: wiegandSlice.reducer,
    cards: cardSlice.reducer,
    issueCard: issueCardSlice.reducer,
    doors: doorSlice.reducer,
    device: deviceSlice.reducer,
    accessByDepartment: accessByDepartmentSlice.reducer,
    accessByLevel: accessByLevelSlice.reducer,
    accessByPerson: accessByPersonSlice.reducer,
    reader: readerSlice.reducer,
    ioBoard: IoBoardSlice.reducer,
    auxiliaryInput: auxiliaryInputSlice.reducer,
    auxiliaryOutput: auxiliaryOutputSlice.reducer,
    daylightSavingTime: daylightSavingTimeOutputSlice.reducer,
    eventType: eventTypeSlice.reducer,
    holidays: holidaysSlice.reducer,
    alarmMonitoring: alarmMonitoringSlice.reducer,
    interlock: interlockSlice.reducer,
    multiPersonOpeningDoor: multiPersonOpeningDoorSlice.reducer,
    timeZone: timeZoneSlice.reducer,
    mapSlice: mapSlice.reducer,
    multiPersonGroup: multiPersonGroupSlice.reducer,
    linkage: linkageSlice.reducer,
    verificationMode: VerificationModeSlice.reducer,
    verifyModeGroup: VerifyModeGroupSlice.reducer,
    accessLevel: accessLevelSlice.reducer,
    zone: zoneSlice.reducer,
    firstPersonNormallyOpen: firstPersonNormallyOpenSlice.reducer,
    antiPassBack: antiPassBackSlice.reducer,
    globalInterlock: globalInterlockSlice.reducer,
    globalLinkage: globalLinkageSlice.reducer,
    globalInterlockGroup: globalInterlockGroupSlice.reducer,
    readerDefine: readerDefineSlice.reducer,
    areaHeadCount: areaHeadCountSlice.reducer,
    globalAntiPassBack: globalAntiPassBackSlice.reducer,
    occupancyControl: occupancyControlSlice.reducer,
    personAvailability: personAvailabilitySlice.reducer,
    musterPoint: musterPointSlice.reducer,
    allTransactions: allTransactionsSlice.reducer,
    todayTransaction: eventsFromTodaySlice.reducer,
    alarmTransactions: allExceptionEventsSlice.reducer,
    alarmLogs: alarmLogReportSlice.reducer,
    alarmProcessingHistory: alarmProcessingHistorySlice.reducer,
    firstInLastOut: firstInLastOutReportSlice.reducer,
    accessRightsByPersonnel: accessRightsByPersonnelSlice.reducer,
    accessByDoor: accessRightByDoorSlice.reducer,
    musterPointReport: musterPointReportSlice.reducer,
    emailManagement: emailManagementSlice.reducer,
    systemParameter: systemParameterSlice.reducer,
    dictionaryManagement: dictionaryManagementSlice.reducer,
    resource: resourceFileSlice.reducer,
    certificate: certificateTypeSlice.reducer,
    area: areaSettingsSlice.reducer,
    operationLog: operationLogSlice.reducer,
    databaseManagement: DatabaseManagementSlice.reducer,
    clientRegister: ClientRegisterSlice.reducer,
    securitySettings: securitySettingsSlice.reducer,
    apiAuthorization: ApiAuthorizationSlice.reducer,
    user: AuthorityManagementUserSlice.reducer,
    role: RoleSlices.reducer,
    deviceCommand: DevCommandSlices.reducer,
    communicationMonitor: CommunicationMonitorSlices.reducer,
    communication: CommunicationDeviceSlice.reducer,
    authDevice: AuthDeviceSlices.reducer,
    systemManagementParameters: SystemManagementParametersSlices.reducer,
  },
});

export const useAppDispatch: () => typeof store.dispatch = useDispatch;
export const useAppSelector = useSelector.withTypes<ReturnType<typeof store.getState>>();
