import React, { useEffect, useState } from 'react';
// Use common modules
import { useTranslation } from 'react-i18next';
// Use shared components
import InputField from '../../../shared-components/input-field/input-field';
import DatePickerField from '../../../shared-components/date-picker-field/date-picker-field';
import SelectBoxField from '../../../shared-components/selectbox-field/selectbox-field';
// Use interface
import { PermissionInterface } from '../../../interface/permissions.interface';
// Use assets svg icons
import { CameraIcon, CloseIcon, UploadIcon } from '../../../assets/svgicons/svgicon';
import ModalBox from '../../../shared-components/modal-box/modal-box';
import OpenCameraModal from './open-camera-modal';
import ConformationBox from '../../../shared-components/conformation-box/conformation-box';
import person from '../../../utils/api/person';
import { setNotificationError } from '../../../redux/slices/NotificationSlices';
import { useAppDispatch } from '../../../redux/store';
import { emailValidation } from '../../../shared-components/form/validation';

function PersonalDetails({ onChangeFunction, values }: PermissionInterface) {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const selectData = [
    { label: t('male'), id: 'M' },
    { label: t('female'), id: 'F' },
  ];
  const [value, setValue] = useState<any>(values);
  const [modalOpen, setModalOpen] = useState(false);
  const [modalView, setModalView] = useState({
    title: t('addNewPerson'),
    content: <>hi</>,
  });
  const onChange = (name: string, changeValue: any) => {
    setValue((prev: any) => ({
      ...prev,
      [name]: changeValue,
    }));
    if ((name === 'personIdPhoto' || name === 'personPhoto') && changeValue !== '') {
      setModalOpen(true);
      setModalView({
        title: t('prompt'),
        content: (
          <ConformationBox
            closeModal={() => setModalOpen(false)}
            okFunction={() => {
              onChange('enableAvatar', true);
              setModalOpen(false);
            }}
            title={t('doYouWantToUseTheAvatarToGenerateAComparisonPhoto')}
            buttonLabel={t('ok')}
            cancelTrue
          />
        ),
      });
    }
  };
  const convertToBase64 = (file: File): Promise<string> => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result as string);
      reader.onerror = (error) => reject(error);
    });
  };
  const handleFileUpload = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files ? e.target.files[0] : null;
    if (file) {
      const base64 = await convertToBase64(file);
      onChange('personPhoto', base64);
      // const request = {
      //   personIdPhoto: base64?.replace(/^data:image\/[a-z]+;base64,/, ''),
      // };
      const formData: any = new FormData();
      formData.append('personPhoto', file);
      formData.append('personIdPhoto', base64?.replace(/^data:image\/[a-z]+;base64,/, ''));
      person
        .validPersonPhoto(formData)
        .then(() => {
          setValue({ personIdPhoto: base64, personPhoto: file });
        })
        .catch((err) => {
          dispatch(
            setNotificationError({
              error: err.message,
              status: err.status,
            }),
          );
        });
    }
  };
  useEffect(() => {
    onChangeFunction(value);
  }, [value]);

  return (
    <div className="personal-details">
      <ModalBox
        status={modalOpen}
        closeModal={() => setModalOpen(false)}
        title={modalView.title}
        content={modalView.content}
      />
      <div className="personal-details-form">
        <div className="row-form-warp">
          <div className="item">
            <InputField
              name="name"
              label={t('firstName')}
              id="name"
              type="text"
              value={value.name}
              onChangeFunction={onChange}
            />
          </div>
          <div className="item">
            <InputField
              name="email"
              label={t('email')}
              id="email"
              type="email"
              value={value.email}
              onChangeFunction={onChange}
              errorStatus={value.email && !emailValidation(value.email)}
              message="Please enter a valid email address"
            />
          </div>
          <div className="item">
            <DatePickerField
              name="birthday"
              label={t('dateOfBirth')}
              value={value.birthday}
              formatDate={false}
              onChangeFunction={onChange}
              disableFuture
            />
          </div>
          <div className="item column2">
            <div className="image-upload">
              {value?.personIdPhoto ? (
                <div className="image-view">
                  <img src={value.personIdPhoto} alt="" />
                  <div
                    className="close-icon"
                    onClick={() => {
                      onChange('personIdPhoto', '');
                      onChange('personPhoto', '');
                    }}
                    role="presentation">
                    <CloseIcon color="red" />
                  </div>
                </div>
              ) : (
                <div className="image-upload-session">
                  <label className="session-item" htmlFor="image">
                    <UploadIcon />
                    <input type="file" name="image" id="image" onChange={handleFileUpload} />
                    <span>Upload a Message</span>
                  </label>
                  <span>or</span>
                  <div
                    className="session-item"
                    onClick={() => {
                      setModalOpen(true);
                      setModalView({
                        title: t('takeAiImage'),
                        content: (
                          <OpenCameraModal
                            closeModal={() => setModalOpen(false)}
                            submitFunction={(data) => {
                              onChange('personIdPhoto', data.personIdPhoto);
                              onChange('personPhoto', data.personPhoto);
                            }}
                          />
                        ),
                      });
                    }}
                    role="presentation">
                    <CameraIcon />
                    <span>take a short</span>
                  </div>
                </div>
              )}
            </div>
          </div>
          <div className="item">
            <InputField
              name="lastName"
              label={t('lastName')}
              id="lastName"
              type="text"
              value={value.lastName}
              onChangeFunction={onChange}
            />
          </div>
          <div className="item">
            <InputField
              name="whatsappMobileNo"
              label={t('whatsapp')}
              id="whatsappMobileNo"
              type="text"
              value={value.whatsappMobileNo}
              innerIconStatus={false}
              onChangeFunction={onChange}
            />
          </div>
          <div className="item">
            <SelectBoxField
              name="gender"
              label={t('gender')}
              id="gender"
              value={value.gender}
              onChangeFunction={(name, val) => {
                onChange(name, val.id);
              }}
              data={selectData}
            />
          </div>
          <div className="item">
            <InputField
              name="mobilePhone"
              label={t('mobileNumber')}
              id="mobilePhone"
              type="number"
              value={value.mobilePhone}
              innerIconStatus={false}
              onChangeFunction={onChange}
            />
          </div>
        </div>
      </div>
    </div>
  );
}
export default PersonalDetails;
