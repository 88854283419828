import { get, post } from '../base/index';

export default {
  getParams: (data: any = {}) => {
    return get(`${process.env.REACT_APP_API_URL}Auth/api/securityParam/getParams`, data);
  },

  saveParams: (data: any = {}) => {
    return post(`${process.env.REACT_APP_API_URL}Auth/api/securityParam/saveParams`, data, {});
  },

  verifyCode: (data: any = {}) => {
    return get(`${process.env.REACT_APP_API_URL}Auth/api/securityParam/VerifyCode`, data, 'blob');
  },

  getLoginVerificationParams: (data: any = {}) => {
    return get(
      `${process.env.REACT_APP_API_URL}Auth/api/securityParam/getLoginVerificationParams`,
      data,
    );
  },

  getIsNeedVerCodeParams: (data: any = {}) => {
    return get(
      `${process.env.REACT_APP_API_URL}Auth/api/securityParam/getIsNeedVerCodeParams`,
      data,
    );
  },

  pwdEffectiveCheck: (data = {}) => {
    const params = { ...data };
    return post(
      `${process.env.REACT_APP_API_URL}Auth/api/securityParam/pwdEffectiveCheck`,
      {},
      { ...params },
    );
  },
};
