/* eslint-disable no-param-reassign */
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { setNotificationError } from '../NotificationSlices';
import systemParameter from '../../../utils/api/SystemManagement/systemParameter';
import { changeDateTimeFormat } from '../../../utils/helpers';

export const fetchSystemParameter = createAsyncThunk(
  'acc/fetchSystemParameter',
  async (data: any, { dispatch }) => {
    const response = await systemParameter
      .getListPage(data)
      .then((response1) => {
        return {
          ...response1.data,
          data: {
            list: response1.data.data.map((item: any) => ({
              ...item,
              id: item.code,
              parametersId: item.id,
              createTime: changeDateTimeFormat(item.createTime),
            })),
            size: response1.data.data.length,
            ...data,
          },
        };
      })
      .catch((error) => {
        dispatch(
          setNotificationError({
            error: error.message,
            status: error.response?.status || 500,
          }),
        );
        return error;
      });
    return response;
  },
);

interface ReduxInterface {
  systemList: any;
  status: 'loading' | 'success' | 'failed'; // Status can be one of these three strings
  totalDataCount: number;
  currentPageNo: number;
  searchFilter: { [key: string]: any };
}

const initialState: ReduxInterface = {
  systemList: [],
  status: 'success',
  totalDataCount: 0,
  currentPageNo: 0,
  searchFilter: {},
};

export const systemParameterSlice: any = createSlice({
  name: 'systemParameter',
  initialState,
  reducers: {
    setSearchFilter: (state, action: any) => {
      state.searchFilter = action.payload;
    },
  },
  extraReducers: (builder) => {
    // Add reducers for additional action types here, and handle loading state as needed
    builder
      .addCase(fetchSystemParameter.pending, (state = initialState) => {
        state.status = 'loading';
      })
      .addCase(fetchSystemParameter.fulfilled, (state: any, action: any) => {
        if (action.payload.success) {
          state.totalDataCount = action.payload.data?.size;
          state.currentPageNo = action.payload.data?.pageNo;

          if (state.currentPageNo === 1) {
            state.systemList = [...action.payload.data.list];
          } else {
            state.systemList = [...state.systemList, ...action.payload.data.list];
          }
        }
        state.status = 'success';
      })
      .addCase(fetchSystemParameter.rejected, (state: any) => {
        state.status = 'failed';
      });
  },
});

// Action creators are generated for each case reducer function
export const { setSearchFilter } = systemParameterSlice.actions;

export default systemParameterSlice.reducer;
