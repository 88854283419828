import React, { useState } from 'react';

// Use Translation
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from '../../redux/store';

// Use own components
import EditDismissedPersonnel from './components/edit-dismissed-personnel';

// Use redux functions
import {
  fetchDismissalPersonsList,
  reinstatementDismissedPerson,
  setSearchFilter,
} from '../../redux/slices/DismissalPersonSlices';
import { setNotificationError } from '../../redux/slices/NotificationSlices';
// import { fetchPersonsList } from '../../redux/slices/PersonSlices';

// Use api calls
import PersonApi from '../../utils/api/person';

// Use shared components
import TopHeader from '../../shared-components/top-header/top-header';
import CardBox from '../../shared-components/card/card';
import DataTable from '../../shared-components/data-table/data-table';
import ModalBox from '../../shared-components/modal-box/modal-box';
import { checkPermission } from '../../utils/role-permission';
import { DeleteButtonIcon } from '../../assets/svgicons/svgicon';
import CardRightButtonGroup from '../../shared-components/card-right-button-group/card-right-button-group';
import DeleteModalBox from '../../shared-components/delete-modal-box/delete-modal-box';

interface DeleteDepartProps {
  id: string;
}
function DismissedPersonnel() {
  const { t } = useTranslation();
  let handleEditOpen: any;
  const dispatch = useAppDispatch();

  const { dismissalPersons, status, totalDataCount, searchFilter } = useAppSelector(
    (state: any) => state.dismissalPerson,
  );
  const { loadMenus } = useAppSelector((state) => state.auth);

  const fetchData = async (params: any) => {
    if (status === 'success') {
      dispatch(fetchDismissalPersonsList({ ...params }));
    }
  };
  const [selectedDeleteFullData, setSelectedDeleteFullData] = useState<any[]>([]);
  const [selectedDismissed, setSelectedDismissed] = useState<any[]>([]);

  const columnsField = [
    {
      accessorKey: 'pin',
      header: t('personnelId'),
      Cell: ({ row }: any) =>
        checkPermission('pers:leavePerson:edit', loadMenus) ? (
          <div
            className="list-hyperlink"
            role="presentation"
            onClick={(event) => {
              event.stopPropagation(); // Prevent row selection
              handleEditOpen(row.original); // Call your edit function
            }}>
            {row?.original?.pin}
          </div>
        ) : (
          <div>{row?.original?.pin}</div>
        ),
    },
    {
      accessorKey: 'name',
      header: t('firstName'),
    },
    {
      accessorKey: 'lastName',
      header: t('lastName'),
    },
    {
      accessorKey: 'deptName',
      header: t('departmentName'),
    },
    {
      accessorKey: 'hireDate',
      header: t('hireDate'),
    },
    {
      accessorKey: 'leaveDate',
      header: t('dismissalDate'),
    },
    {
      accessorKey: 'leaveType',
      header: t('dismissalType'),
      Cell: ({ row }: any) => t(row.original.dismissalType),
    },
    {
      accessorKey: 'leaveReason',
      header: t('dismissalReason'),
    },
  ];

  const [modalOpen, setModalOpen] = useState(false);
  const [modalView, setModalView] = useState({
    title: t('addNewDepartment'),
    content: <>hi</>,
  });

  const reinstatementRecord = (id: any) => {
    PersonApi.reinstatedDismissedPersons({ id: id.id })
      .then((response) => {
        if (response.data.code === 0) {
          dispatch(
            setNotificationError({
              error: t('dismissalPersonnelReinstatedSuccessfully'),
              status: 200,
            }),
          );
          dispatch(reinstatementDismissedPerson(response.data.data.pin));
          setModalOpen(false);
        } else {
          dispatch(
            setNotificationError({
              error: response.data.message,
              status: response.data.code,
            }),
          );
        }
      })
      .catch((error) => {
        dispatch(
          setNotificationError({
            error: error.message,
            status: error.status,
          }),
        );
      });
  };
  const deleteRecord = (params: DeleteDepartProps) => {
    const req = {
      ids: params,
    };
    PersonApi.dismissedDelete(req)
      .then((response) => {
        if (response.data.success) {
          dispatch(
            setNotificationError({
              error: t('PersonDeletedSuccessfully'),
              status: 200,
            }),
          );
          setModalOpen(false);
          dispatch(fetchDismissalPersonsList({ pageNo: 1, pageSize: 10 }));
        } else {
          dispatch(
            setNotificationError({
              error: response.data.msg,
              status: response.data.code,
            }),
          );
        }
      })
      .catch((error) => {
        dispatch(
          setNotificationError({
            error: error.message,
            status: error.status,
          }),
        );
      });
  };
  handleEditOpen = (editData: any) => {
    setModalOpen(true);
    setModalView({
      title: t('editDismissedPersonnel'),
      content: <EditDismissedPersonnel closeModal={() => setModalOpen(false)} data={editData} />,
    });
  };
  const handleDeleteClick = () => {
    if (selectedDeleteFullData.length === 0) {
      dispatch(
        setNotificationError({
          error: t('pleaseSelectAtLeastOneDismissel'),
          status: 400,
        }),
      );
      return;
    }

    setModalView({
      title: t('delete'),
      content: (
        <DeleteModalBox
          closeModal={() => setModalOpen(false)}
          id={selectedDeleteFullData.map((val) => val.id).join(',')}
          okFunction={deleteRecord}
          isMultiSelect
        />
      ),
    });
    setModalOpen(true);
  };
  const actionButtons = [
    {
      label: t('edit'),
      enable: checkPermission('pers:leavePerson:edit', loadMenus),
      function: (val: any) => handleEditOpen(val),
    },
    {
      label: t('reinstatement'),
      enable: checkPermission('pers:leavePerson:reinstated', loadMenus),
      function: reinstatementRecord,
    },
  ];

  const getFilterData = (filter: any): any => {
    if (filter.pageIndex > 0) {
      dispatch(setSearchFilter(filter));
      fetchData({
        pageNo: filter.pageIndex,
        pageSize: filter.pageSize,
        ...filter.filter,
      });
    }
  };

  return (
    <div className="dismissed-personnel">
      <ModalBox
        status={modalOpen}
        closeModal={() => setModalOpen(false)}
        title={modalView.title}
        content={modalView.content}
      />
      <TopHeader
        title={t('dismissedPersonnel')}
        broadCram={[
          {
            title: t('personnel'),
          },
          {
            title: t('dismissedPersonnel'),
          },
        ]}
      />
      <CardBox
        title={t('listOfDismissedPersonnel')}
        rightSide={
          <div className="delete-icon">
            <CardRightButtonGroup
              DeleteButtonIcon={<DeleteButtonIcon />}
              deleteButtonLabel={t('delete')}
              showDeleteButton
              deleteButtonFunction={handleDeleteClick}
              deleteButtonEnable
              deleteButtonClass="multi-delete-button"
            />
          </div>
        }>
        <DataTable
          actionButtonStatus
          action={actionButtons}
          columns={columnsField}
          getFilterData={getFilterData}
          enablePagination
          assignFilterValue={searchFilter}
          assignSelectedData={selectedDismissed}
          getSelectedData={(val: any): any => {
            setSelectedDismissed(val);
          }}
          getSelectedFullData={(val: any): any => {
            setSelectedDeleteFullData(val);
          }}
          checkBoxActive
          values={dismissalPersons}
          fixedColumn={['pin']}
          isFetching={status === 'loading'}
          totalRecords={totalDataCount}
          height={411}
        />
      </CardBox>
    </div>
  );
}

export default DismissedPersonnel;
