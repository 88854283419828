/* eslint-disable no-param-reassign */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface SecuritySettingsState {
  verificationCodeOpen: string; // '0', '1', or '2'
  // Add other security settings as needed
}

const initialState: SecuritySettingsState = {
  verificationCodeOpen: '2', // Default to 'Prompt After Entering an Error'
};

export const securitySettingsSlice = createSlice({
  name: 'securitySettings',
  initialState,
  reducers: {
    setSecuritySettings: (state, action: PayloadAction<Partial<SecuritySettingsState>>) => {
      return { ...state, ...action.payload };
    },
  },
});

export const { setSecuritySettings } = securitySettingsSlice.actions;
export default securitySettingsSlice.reducer;
