import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
// Import shared components
import { useAppDispatch } from '../../../../redux/store';
import TopHeader from '../../../../shared-components/top-header/top-header';
import CardBox from '../../../../shared-components/card/card';
import TabPages from '../../../../shared-components/tab-pages/tab-pages';
import CurrentInformation from './components/current-information';
import systemMonitoring from '../../../../utils/api/SystemManagement/systemMonitoring';
import { setNotificationError } from '../../../../redux/slices/NotificationSlices';
import {
  CurrentResponseInterface,
  RedisInfoResInterface,
} from '../../../../interface/System/currentInformation.interface';
import Caching from './components/caching';
import TrendInformation from './components/trend-information';

function SystemMonitoring() {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const [currentInfo, setCurrentInfo] = useState<any>();
  const [allRedisData, setAllRedisData] = useState<any>();

  const getCurrentInformation = async () => {
    try {
      const response: CurrentResponseInterface = await systemMonitoring.getInfo();

      if (response?.data) {
        setCurrentInfo(response?.data);
      }
    } catch (error: any) {
      dispatch(
        setNotificationError({
          error: error.message,
          status: error.status,
        }),
      );
    }
  };

  const getRedisInformation = async () => {
    try {
      const response: RedisInfoResInterface = await systemMonitoring.getRedisInfo();
      if (response?.data) {
        setAllRedisData(response?.data);
      }
    } catch (error: any) {
      dispatch(
        setNotificationError({
          error: error.message,
          status: error.status,
        }),
      );
    }
  };

  useEffect(() => {
    const interval = setInterval(() => {
      getCurrentInformation();
      getRedisInformation();
    }, 6500);

    return () => {
      clearInterval(interval);
    };
  }, []);

  useEffect(() => {
    getCurrentInformation();
    getRedisInformation();
  }, []);

  return (
    <div>
      <TopHeader
        title={t('system')}
        broadCram={[
          {
            title: t('systemManagement'),
          },
          {
            title: t('systemMonitoring'),
          },
        ]}
      />
      <CardBox title={t('systemMonitoring')}>
        <TabPages
          tabs={[
            {
              key: 'currentInformation',
              title: t('currentInformation'),
              content: <CurrentInformation currentInfo={currentInfo} />,
              enable: true,
            },
            {
              key: 'caching',
              title: t('caching'),
              content: <Caching allRedisData={allRedisData} />,
              enable: true,
            },
            {
              key: 'trendInformation',
              title: t('trendInformation'),
              content: <TrendInformation currentInfo={currentInfo} />,
              enable: true,
            },
          ]}
          defaultNumber={0}
        />
      </CardBox>
    </div>
  );
}

export default SystemMonitoring;
