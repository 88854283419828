import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
// Import shared components
import { useAppDispatch, useAppSelector } from '../../../redux/store';
import TopHeader from '../../../shared-components/top-header/top-header';
import CardBox from '../../../shared-components/card/card';
import DataTable from '../../../shared-components/data-table/data-table';
import DeleteModalBox from '../../../shared-components/delete-modal-box/delete-modal-box';
import ModalBox from '../../../shared-components/modal-box/modal-box';
import CardRightButtonGroup from '../../../shared-components/card-right-button-group/card-right-button-group';
import { DepartmentParamsProps } from '../../../interface/accessByDepartment.interface';
import { setNotificationError } from '../../../redux/slices/NotificationSlices';
import { AddIcon, DeleteButtonIcon } from '../../../assets/svgicons/svgicon';
import AddAvailability, { useOptions } from './components/NewAvailability';
import {
  setPersonSearchFilter,
  setSearchFilter,
  deleteAntiPassBack as deleteApb,
} from '../../../redux/slices/GlobalAntipassbackSlices';
import globalAntiPassBack from '../../../utils/api/AdvancedFunctions/globalAntiPassBack';
import {
  fetchPersonAvailability,
  deletePerson,
  fetchPersonList,
} from '../../../redux/slices/PersonAvailabilitySlices';
import personAvailability from '../../../utils/api/AdvancedFunctions/personAvailability';
import AddPersonnel from './components/AddPersonnel';
import ConformationBox from '../../../shared-components/conformation-box/conformation-box';
import { checkPermission } from '../../../utils/role-permission';

interface LevelProps {
  personLimitZoneId?: string;
  pageNo?: number;
  pageSize?: number;
}
interface DeleteLevelProps {
  personLimitZoneId: string;
  persPersonId: string;
}
function PersonAvailability() {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const {
    personAvailabilities,
    status,
    totalDataCount,
    searchFilter,
    persons,
    personStatus,
    personTotalDataCount,
    personSearchFilter,
  } = useAppSelector((state: any) => state.personAvailability);

  /**
   * Selects the `loadMenus` permission from the authentication slice of the Redux store.
   *
   * @returns {Object} loadMenus - The user's menu permissions.
   */
  const { loadMenus } = useAppSelector((state) => state.auth);

  const [modalOpen, setModalOpen] = useState(false);
  const [modalView, setModalView] = useState({
    title: '',
    content: <>hi</>,
  });
  const [selectedValues, setSelectedValues] = useState<string[]>([]);
  const [personnelSelect, setPersonnelSelect] = useState<string[]>([]);
  const [selectedAvailabilityFullData, setSelectedAvailabilityFullData] = useState<any[]>([]);
  const [selectedPersonFullData, setSelectedPersonFullData] = useState<any[]>([]);

  let handleEditOpen: any;
  const fetchZoneDetails = async (ids: string) => {
    try {
      const response = await personAvailability.getPersonLimitZoneDetail(ids);
      return response.data.data;
    } catch (error: any) {
      dispatch(setNotificationError(error.response.data));
      return null;
    }
  };

  const canEdit = checkPermission('acc:personLimitZone:edit', loadMenus) || true;
  const columnsField = [
    {
      accessorKey: 'name',
      header: t('availabilityName'),
      Cell: ({ row }: any) => (
        <div
          className={canEdit ? 'list-hyperlink' : ''}
          role="presentation"
          onClick={(event) => {
            if (!canEdit) return;
            event.stopPropagation(); // Prevent row selection
            handleEditOpen(row.original); // Call your edit function
          }}>
          {row?.original?.name}
        </div>
      ),
    },
    {
      accessorKey: 'zoneName',
      header: t('zone'),
    },
    {
      accessorKey: 'validType',
      header: t('use'),
      Cell: ({ row }: any) => {
        const result =
          useOptions.find((item) => item.id === row?.original?.validType.toString())?.label || '';
        return <div>{result}</div>;
      },
    },
    {
      accessorKey: 'detail',
      header: t('detail'),
      Cell: ({ row }: any) => {
        const [details, setDetails] = useState<string | null>(null);

        useEffect(() => {
          const fetchData = async () => {
            const data = await fetchZoneDetails(row.original.id);
            setDetails(data);
          };

          fetchData();
        }, [row.original.id]);

        return <span>{details}</span>;
      },
    },
  ];
  const personnelColumn = [
    {
      accessorKey: 'persPin',
      header: t('personnelId'),
    },
    {
      accessorKey: 'name',
      header: t('firstName'),
    },
    {
      accessorKey: 'lastName',
      header: t('lastName'),
    },
    {
      accessorKey: 'detail',
      header: t('detail'),
    },
  ];

  handleEditOpen = (val: any): void => {
    const editData = {
      ...val,
      validType: String(val.validType),
    };
    setModalView({
      title: t('edit'),
      content: <AddAvailability closeModal={() => setModalOpen(false)} update={editData} />,
    });
    setModalOpen(true);
  };

  const fetchData = (params: DepartmentParamsProps) => {
    if (status === 'success') {
      dispatch(fetchPersonAvailability({ ...params }));
    }
  };

  // level functions
  const fetchLevels = (params: LevelProps) => {
    if (params.personLimitZoneId) {
      dispatch(fetchPersonList({ ...params }));
    }
  };

  const getFilterData = (filter: any): any => {
    if (filter.pageIndex > 0) {
      dispatch(setPersonSearchFilter(filter));
      fetchLevels({
        pageNo: filter.pageIndex,
        pageSize: filter.pageSize,
        ...filter.filter,
      });
    }
  };

  const getFilterLevels = (filter: any): any => {
    if (filter.pageIndex > 0) {
      dispatch(setSearchFilter(filter));
      fetchData({
        pageNo: filter.pageIndex,
        pageSize: filter.pageSize,
        ...filter.filter,
      });
    }
  };

  const getSelected = (id: string[]) => {
    setSelectedValues(id);
    fetchLevels({ personLimitZoneId: id?.[0] });
  };
  const selectedPersonIds = selectedPersonFullData.map((val) => val.id).join(',');
  const deleteRecord = (params: DeleteLevelProps) => {
    const req = {
      perPersonLimitZoneIds: params,
      personLimitZoneId: selectedValues?.[0],
    };
    globalAntiPassBack
      .delPerson(req)
      .then((response) => {
        if (response.data.success) {
          dispatch(
            setNotificationError({
              error: t('personDeletedSuccessfully'),
              status: 200,
            }),
          );
          dispatch(deletePerson(params.persPersonId));
          setModalOpen(false);
          fetchLevels({
            personLimitZoneId: selectedValues[0],
            pageNo: personSearchFilter?.pageIndex || 1,
            pageSize: personSearchFilter?.pageSize || 10,
          });
        } else {
          dispatch(
            setNotificationError({
              error: response.data.msg,
              status: response.data.code || 500,
            }),
          );
        }
      })
      .catch((error) => {
        dispatch(
          setNotificationError({
            error: error.message,
            status: error.status,
          }),
        );
      });
  };
  const selectedAvailabilityIds = selectedAvailabilityFullData.join(',');
  const deleteAvailability = (ids: string) => {
    personAvailability
      .delete(ids)
      .then((response) => {
        if (response.data.success) {
          dispatch(
            setNotificationError({
              error: response.data.msg,
              status: 200,
            }),
          );
          dispatch(deleteApb(ids));
          setModalOpen(false);
          fetchData({ pageNo: 1, pageSize: 10, ...searchFilter.filter });
        } else {
          dispatch(
            setNotificationError({
              error: response.data.msg,
              status: response.data.code || 500,
            }),
          );
          setModalOpen(false);
        }
      })
      .catch((error) => {
        dispatch(
          setNotificationError({
            error: error.message,
            status: error.status,
          }),
        );
        setModalOpen(false);
      });
  };

  const onConfirmReset = (zoneId: string) => {
    const req = {
      personLimitZoneId: zoneId,
    };
    personAvailability
      .resetPersonLimitZone(req)
      .then((response) => {
        if (response.data.success) {
          dispatch(
            setNotificationError({
              error: response.data.msg,
              status: 200,
            }),
          );
          setModalOpen(false);
          fetchPersonAvailability({
            pageNo: 1,
          });
        } else {
          dispatch(
            setNotificationError({
              error: response.data.message,
              status: response.data.code,
            }),
          );
          setModalOpen(false);
        }
      })
      .catch((error) => {
        dispatch(
          setNotificationError({
            error: error.message,
            status: error.status,
          }),
        );
        setModalOpen(false);
      });
  };
  const handleDeleteClick = () => {
    if (selectedAvailabilityFullData.length === 0) {
      dispatch(
        setNotificationError({
          error: t('pleaseSelectAtLeastOnePerson'), // Also fixed the error message
          status: 400,
        }),
      );
      return;
    }

    setModalOpen(true);
    setModalView({
      title: t('delete'),
      content: (
        <DeleteModalBox
          closeModal={() => setModalOpen(false)}
          id={selectedAvailabilityIds}
          okFunction={deleteAvailability}
          isMultiSelect
        />
      ),
    });
  };
  const actionButtons = [
    {
      label: t('edit'),
      enable: checkPermission('acc:personLimitZone:edit', loadMenus),
      function: (val: any) => handleEditOpen(val),
    },
    {
      label: t('reset'),
      enable: checkPermission('acc:personLimitZone:reset', loadMenus),
      function: (val: any) => {
        setModalView({
          title: t('reset'),
          content: (
            <ConformationBox
              closeModal={() => setModalOpen(false)}
              okFunction={() => onConfirmReset(val.id)}
              title={t('confirmResetOperation')}
              buttonLabel={t('ok')}
              cancelTrue
            />
          ),
        });
        setModalOpen(true);
      },
    },
    {
      label: t('delete'),
      enable: checkPermission('acc:personLimitZone:del', loadMenus),
      function: (val: any) => {
        setModalOpen(true);
        setModalView({
          title: t('delete'),
          content: (
            <DeleteModalBox
              closeModal={() => setModalOpen(false)}
              id={val.id}
              okFunction={deleteAvailability}
            />
          ),
        });
      },
    },
    {
      label: t('addPersonnel'),
      enable: checkPermission('acc:personLimitZone:addPerson', loadMenus),
      function: (val: any): void => {
        setModalView({
          title: t('addPersonnel'),
          content: <AddPersonnel closeModal={() => setModalOpen(false)} data={val} />,
        });
        setModalOpen(true);
      },
    },
  ];
  const handleDeletePersonClick = () => {
    if (selectedPersonFullData.length === 0) {
      dispatch(
        setNotificationError({
          error: t('pleaseSelectAtLeastOneDoor'),
          status: 400,
        }),
      );
      return;
    }
    setModalOpen(true);
    setModalView({
      title: t('delete'),
      content: (
        <DeleteModalBox
          closeModal={() => setModalOpen(false)}
          id={selectedPersonIds}
          okFunction={deleteRecord}
          isMultiSelect
        />
      ),
    });
  };
  const personActions = [
    {
      label: t('delete'),
      enable: checkPermission('acc:personLimitZone:delPerson', loadMenus),
      function: (val: any) => {
        setModalOpen(true);
        setModalView({
          title: t('delete'),
          content: (
            <DeleteModalBox
              closeModal={() => setModalOpen(false)}
              id={val}
              okFunction={deleteRecord}
            />
          ),
        });
      },
    },
  ];

  return (
    <div className="access-by-level">
      <ModalBox
        status={modalOpen}
        closeModal={() => setModalOpen(false)}
        title={modalView.title}
        content={modalView.content}
      />
      <TopHeader
        title={t('access')}
        broadCram={[
          {
            title: t('advancedFunctions'),
          },
          {
            title: t('personAvailability'),
          },
        ]}
      />
      <CardBox
        header={t('personAvailability')}
        title={t('listOfAvailability')}
        rightSide={
          <div className="delete-icon">
            <CardRightButtonGroup
              buttonLabel={t('new')}
              buttonIcon={<AddIcon color="#ffffff" />}
              buttonClass="btn-primary btn-sm"
              buttonStatus
              buttonFunction={(): void => {
                setModalOpen(true);
                setModalView({
                  title: t('new'),
                  content: <AddAvailability closeModal={() => setModalOpen(false)} />,
                });
              }}
              buttonEnable={checkPermission('acc:personLimitZone:add', loadMenus)}
              // Add delete button configuration
              DeleteButtonIcon={<DeleteButtonIcon />}
              deleteButtonLabel={t('delete')}
              deleteButtonStatus
              showDeleteButton
              deleteButtonFunction={handleDeleteClick}
              deleteButtonEnable={checkPermission('acc:personLimitZone:del', loadMenus)}
              deleteButtonClass="multi-delete-button"
            />
          </div>
        }>
        <DataTable
          actionButtonStatus={!modalOpen}
          action={actionButtons}
          columns={columnsField}
          getFilterData={getFilterLevels}
          assignFilterValue={searchFilter}
          assignSelectedData={selectedValues}
          getSelectedData={(val: any): any => {
            getSelected(val); // Set selected cards
            setSelectedAvailabilityFullData(val);
          }}
          checkBoxActive
          singleRowSelection
          enablePagination
          isFetching={status === 'loading'}
          values={personAvailabilities}
          fixedColumn={['name']}
          totalRecords={totalDataCount}
          height={411}
        />
        <hr className="line" />
        <div className="browse-table">
          <h4>Person</h4>
          <div className="door-header">
            <div className="header">
              <span className="sub-title">{t('listOfPersonnel')}</span>
            </div>
            <div className="delete-icon">
              <CardRightButtonGroup
                DeleteButtonIcon={<DeleteButtonIcon />}
                deleteButtonLabel={t('delete')}
                deleteButtonStatus
                showDeleteButton
                deleteButtonFunction={handleDeletePersonClick}
                deleteButtonEnable={checkPermission('acc:personLimitZone:delPerson', loadMenus)}
                deleteButtonClass="multi-delete-button"
              />
            </div>
          </div>
          <DataTable
            actionButtonStatus={!modalOpen}
            action={personActions}
            columns={personnelColumn}
            getFilterData={getFilterData}
            assignFilterValue={personSearchFilter}
            assignSelectedData={personnelSelect}
            getSelectedData={(val: any): any => setPersonnelSelect(val)}
            getSelectedFullData={(val: any[]): any => {
              setSelectedPersonFullData(val);
            }}
            checkBoxActive
            enablePagination
            isFetching={personStatus === 'loading'}
            values={persons}
            fixedColumn={['id']}
            totalRecords={personTotalDataCount}
            height={411}
          />
        </div>
      </CardBox>
    </div>
  );
}

export default PersonAvailability;
