import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
// Import shared components
import { useAppDispatch, useAppSelector } from '../../../redux/store';
import TopHeader from '../../../shared-components/top-header/top-header';
import CardBox from '../../../shared-components/card/card';
import DataTable from '../../../shared-components/data-table/data-table';
import ModalBox from '../../../shared-components/modal-box/modal-box';
import CardRightButtonGroup from '../../../shared-components/card-right-button-group/card-right-button-group';
import { AddIcon, DeleteButtonIcon } from '../../../assets/svgicons/svgicon';
import DeleteModalBox from '../../../shared-components/delete-modal-box/delete-modal-box';
import AddReader from './components/AddReader';
import AddBatch from './components/Add-Batch';
import {
  deleteReader,
  fetchReaderDefineList,
  setSearchFilter,
} from '../../../redux/slices/ReaderDefineSlices';
import readerDefine from '../../../utils/api/AdvancedFunctions/readerDefine';
import { setNotificationError } from '../../../redux/slices/NotificationSlices';
import { checkPermission } from '../../../utils/role-permission';
// import AddHolidays from './components/add-holidays';
interface DeleteLevelProps {
  id: string | string[];
  pin: string;
}

function ReaderDefine() {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { readersList, status, totalDataCount, searchFilter } = useAppSelector(
    (state: any) => state.readerDefine,
  );

  /**
   * Selects the `loadMenus` permission from the authentication slice of the Redux store.
   *
   * @returns {Object} loadMenus - The user's menu permissions.
   */
  const { loadMenus } = useAppSelector((state) => state.auth);

  const [modalOpen, setModalOpen] = useState(false);
  const [modalView, setModalView] = useState({
    title: '',
    content: <>hi</>,
  });
  const [selectedValues, setSelectedValues] = useState<string[]>([]);
  let handleEditOpen: any;

  const canEdit = checkPermission('acc:readerZone:edit', loadMenus) || true;
  const [selectedReaderFullData, setSelectedReaderFullData] = useState<any[]>([]);

  const columnsField = [
    {
      accessorKey: 'readerName',
      header: t('readerName'),
      Cell: ({ row }: any) => (
        <div
          className={canEdit ? 'list-hyperlink' : ''}
          role="presentation"
          onClick={(event) => {
            if (!canEdit) return;
            event.stopPropagation(); // Prevent row selection
            handleEditOpen(row.original); // Call your edit function
          }}>
          {row?.original?.readerName}
        </div>
      ),
      size: 150,
    },
    {
      accessorKey: 'accZoneFromName',
      header: t('goesFrom'),
    },
    {
      accessorKey: 'accZoneToName',
      header: t('goesTo'),
    },
  ];
  handleEditOpen = (editData: any) => {
    const updateData = {
      ...editData,
      accReaderIds: editData?.readerId,
    };
    setModalOpen(true);
    setModalView({
      title: t('edit'),
      content: <AddReader closeModal={() => setModalOpen(false)} update={updateData} />,
    });
  };

  const fetchData = async (params: any) => {
    if (status === 'success') {
      dispatch(fetchReaderDefineList({ ...params }));
    }
  };
  const deleteRecord = (params: DeleteLevelProps) => {
    const req = {
      ids: params,
      pin: params.pin,
    };
    readerDefine
      .deleteReader(req)
      .then((response) => {
        if (response.data.success) {
          dispatch(
            setNotificationError({
              error: t('readerDefineDeletedSuccessfully'),
              status: 200,
            }),
          );
          dispatch(deleteReader(req));
          setModalOpen(false);
          dispatch(fetchReaderDefineList({ pageNo: 1, pageSize: 10 }));
        } else {
          dispatch(
            setNotificationError({
              error: response.data.msg,
              status: response.data.code,
            }),
          );
        }
      })
      .catch((error) => {
        dispatch(
          setNotificationError({
            error: error.message,
            status: error.response?.status || 500,
          }),
        );
      });
  };
  const handleDeleteClick = () => {
    if (selectedReaderFullData.length === 0) {
      dispatch(
        setNotificationError({
          error: t('pleaseSelectAtLeastOneReader'), // Also fixed the error message
          status: 400,
        }),
      );
      return;
    }

    setModalOpen(true);
    setModalView({
      title: t('delete'),
      content: (
        <DeleteModalBox
          closeModal={() => setModalOpen(false)}
          id={selectedReaderFullData.map((val) => val.id).join(',')}
          okFunction={deleteRecord}
          isMultiSelect
        />
      ),
    });
  };
  const actionButtons = [
    {
      label: t('edit'),
      enable: checkPermission('acc:readerZone:edit', loadMenus),
      function: (val: any) => handleEditOpen(val),
    },
    {
      label: t('delete'),
      enable: checkPermission('acc:readerZone:del', loadMenus),
      function: (val: any) => {
        setModalOpen(true);
        setModalView({
          title: t('delete'),
          content: (
            <DeleteModalBox
              closeModal={() => setModalOpen(false)}
              id={val.id}
              okFunction={deleteRecord}
            />
          ),
        });
      },
    },
  ];

  const getFilterData = (filter: any): any => {
    if (filter.pageIndex > 0) {
      dispatch(setSearchFilter(filter));
      fetchData({
        pageNo: filter.pageIndex,
        pageSize: filter.pageSize,
        ...filter.filter,
      });
    }
  };

  return (
    <div className="department">
      <ModalBox
        status={modalOpen}
        closeModal={() => setModalOpen(false)}
        title={modalView.title}
        content={modalView.content}
      />
      <TopHeader
        title={t('access')}
        broadCram={[
          {
            title: t('advancedFunctions'),
          },
          {
            title: t('readerDefine'),
          },
        ]}
      />
      <CardBox
        title={t('listOfReader')}
        rightSide={
          <div className="delete-icon">
            <CardRightButtonGroup
              buttonIcon={<AddIcon color="#ffffff" />}
              buttonLabel={t('Batch New')}
              buttonClass="btn-primarys btn-sm"
              buttonStatus
              buttonFunction={(): void => {
                setModalOpen(true);
                setModalView({
                  title: t('Batch New'),
                  content: <AddBatch closeModal={() => setModalOpen(false)} />,
                });
              }}
              buttonEnable={checkPermission('acc:readerZone:add', loadMenus)}
            />
            <CardRightButtonGroup
              buttonIcon={<AddIcon color="#ffffff" />}
              buttonLabel={t('new')}
              buttonClass="btn-primary btn-sm"
              buttonStatus
              buttonFunction={(): void => {
                setModalOpen(true);
                setModalView({
                  title: t('new'),
                  content: <AddReader closeModal={() => setModalOpen(false)} />,
                });
              }}
              buttonEnable={checkPermission('acc:readerZone:add', loadMenus)}
              // Add delete button configuration
              DeleteButtonIcon={<DeleteButtonIcon />}
              deleteButtonLabel={t('delete')}
              deleteButtonStatus
              showDeleteButton
              deleteButtonFunction={handleDeleteClick}
              deleteButtonEnable={checkPermission('acc:readerZone:del', loadMenus)}
              deleteButtonClass="multi-delete-button"
            />
          </div>
        }>
        <DataTable
          actionButtonStatus={!modalOpen}
          action={actionButtons}
          columns={columnsField}
          getFilterData={getFilterData}
          enablePagination
          assignFilterValue={searchFilter}
          assignSelectedData={selectedValues}
          getSelectedData={(val: any): any => setSelectedValues(val)}
          getSelectedFullData={(val: any): any => {
            setSelectedReaderFullData(val);
          }}
          checkBoxActive
          values={readersList}
          fixedColumn={['zone']}
          isFetching={status === 'loading'}
          totalRecords={totalDataCount}
          height={411}
        />
      </CardBox>
    </div>
  );
}

export default ReaderDefine;
