import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
// Import shared components
import { useAppDispatch, useAppSelector } from '../../../../redux/store';
import TopHeader from '../../../../shared-components/top-header/top-header';
import CardBox from '../../../../shared-components/card/card';
import DataTable from '../../../../shared-components/data-table/data-table';
import ModalBox from '../../../../shared-components/modal-box/modal-box';
import CardRightButtonGroup from '../../../../shared-components/card-right-button-group/card-right-button-group';
import {
  AddIcon,
  CheckCircleIcon,
  CloseCircleIcon,
  DeleteButtonIcon,
} from '../../../../assets/svgicons/svgicon';
import DeleteModalBox from '../../../../shared-components/delete-modal-box/delete-modal-box';
import {
  deleteUser,
  fetchAuthorityManagementUser,
  setSearchFilter,
} from '../../../../redux/slices/system-management/AuthorityManagementUser';
import { setNotificationError } from '../../../../redux/slices/NotificationSlices';
import authorityManagementUser from '../../../../utils/api/SystemManagement/authorityManagementUser';
import AuthorityAddUser from './components/add-user';
import { checkPermission } from '../../../../utils/role-permission';

function User() {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { AuthorityManagementUser, status, totalDataCount, searchFilter } = useAppSelector(
    (state: any) => state.user,
  );
  const [modalOpen, setModalOpen] = useState(false);
  const [modalView, setModalView] = useState({
    title: '',
    content: <>hi</>,
  });
  const { loadMenus } = useAppSelector((state) => state.auth);

  const [selectedValues, setSelectedValues] = useState<string[]>([]);
  const [selectedUserFullData, setSelectedUserFullData] = useState<any[]>([]);

  let handleEditOpen: any;
  const columnsField = [
    {
      accessorKey: 'username',
      header: t('userName'),
      Cell: ({ row }: any) =>
        checkPermission('auth:user:edit', loadMenus) ? (
          <span
            className="list-hyperlink"
            role="presentation"
            onClick={(event) => {
              event.stopPropagation(); // Prevent row selection
              handleEditOpen(row.original); // Call your edit function
            }}>
            {row?.original?.username}
          </span>
        ) : (
          <div>{row?.original?.name}</div>
        ),
      size: 150,
    },
    {
      accessorKey: 'name',
      header: t('firstName'),
    },
    {
      accessorKey: 'lastName',
      header: t('lastName'),
    },
    {
      accessorKey: 'email',
      header: t('email'),
    },
    {
      accessorKey: 'authUserAuthorizedDept',
      header: t('authDepartment'),
      Cell: ({ row }: any) => {
        const parser = new DOMParser();
        const doc = parser.parseFromString(row.original.authUserAuthorizedDept, 'text/html');
        const deptContent = doc.body.textContent || '';
        return <span>{deptContent}</span>;
      },
    },
    {
      accessorKey: 'authUserAuthorizedArea',
      header: t('authorizeArea'),
      Cell: ({ row }: any) => {
        const parser = new DOMParser();
        const doc = parser.parseFromString(row.original.authUserAuthorizedArea, 'text/html');
        const areaContent = doc.body.textContent || '';
        return <span>{areaContent}</span>;
      },
    },
    {
      accessorKey: 'isActive',
      header: t('state'),
      visibleInShowHideMenu: false,
      Cell: ({ row }: any) => {
        const value = row.original?.isActive ? (
          <CheckCircleIcon color="#48d726de" />
        ) : (
          <CloseCircleIcon color="#f00" />
        );
        return value;
      },
    },
    {
      accessorKey: 'isSuperuser',
      header: t('superUserState'),
      visibleInShowHideMenu: false,
      Cell: ({ row }: any) => {
        const value = row.original?.isSuperuser ? (
          <CheckCircleIcon color="#48d726de" />
        ) : (
          <CloseCircleIcon color="#f00" />
        );
        return value;
      },
    },
  ];
  const fetchData = async (params: any) => {
    if (status === 'success') {
      dispatch(fetchAuthorityManagementUser({ ...params }));
    }
  };

  const deleteRecord = (id: any) => {
    const req = {
      ids: id,
    };
    authorityManagementUser
      .delete(req)
      .then((response) => {
        if (response.data.success) {
          dispatch(
            setNotificationError({
              error: response.data.msg,
              status: 200,
            }),
          );
          dispatch(deleteUser(id));
          setModalOpen(false);
          fetchData({ pageNo: 1, pageSize: 10, ...searchFilter.filter });
        } else {
          dispatch(
            setNotificationError({
              error: response.data.msg,
              status: response.data.code || 500,
            }),
          );
        }
      })
      .catch((error) => {
        dispatch(
          setNotificationError({
            error: error.message,
            status: error.response?.status || 500,
          }),
        );
      });
  };

  handleEditOpen = (editData: any) => {
    authorityManagementUser
      .edit({ id: editData.id })
      .then((res) => {
        const response = res?.data?.data?.item;
        const updateData = {
          id: response?.id,
          username: response?.username,
          name: response?.name,
          lastName: response?.lastName,
          email: response?.email,
          isActive: response?.isActive,
          isSuperuser: response?.isSuperuser,
          areaIds: response?.areaIds || '',
          deptIds: response?.deptIds || '',
          roleIds: response?.roleIds || '',
        };

        if (!modalOpen) {
          setModalOpen(true);
          setModalView({
            title: t('edit'),
            content: <AuthorityAddUser closeModal={() => setModalOpen(false)} data={updateData} />,
          });
        }
      })
      .catch((error) => {
        dispatch(
          setNotificationError({
            error: error.message,
            status: error.status,
          }),
        );
      });
  };
  const handleDeleteClick = () => {
    if (selectedUserFullData.length === 0) {
      dispatch(
        setNotificationError({
          error: t('pleaseSelectAtLeastOneUser'), // Also fixed the error message
          status: 400,
        }),
      );
      return;
    }

    setModalOpen(true);
    setModalView({
      title: t('delete'),
      content: (
        <DeleteModalBox
          closeModal={() => setModalOpen(false)}
          id={selectedUserFullData.map((val) => val.id).join(',')}
          okFunction={deleteRecord}
          isMultiSelect
        />
      ),
    });
  };
  const actionButtons = [
    {
      label: t('edit'),
      enable: checkPermission('auth:user:edit', loadMenus),
      function: (val: any) => handleEditOpen(val),
    },
    {
      label: t('delete'),
      enable: checkPermission('auth:user:del', loadMenus),
      disabled: true,
      function: (val: any) => {
        setModalOpen(true);
        setModalView({
          title: t('delete'),
          content: (
            <DeleteModalBox
              closeModal={() => setModalOpen(false)}
              id={val.id}
              okFunction={deleteRecord}
            />
          ),
        });
      },
    },
  ];

  const getFilterData = (filter: any): any => {
    if (filter.pageIndex > 0) {
      dispatch(setSearchFilter(filter));
      fetchData({
        pageNo: filter.pageIndex,
        pageSize: filter.pageSize,
        ...filter.filter,
      });
    }
  };

  return (
    <div className="department">
      <ModalBox
        status={modalOpen}
        closeModal={() => setModalOpen(false)}
        title={modalView.title}
        content={modalView.content}
      />
      <TopHeader
        title={t('system')}
        broadCram={[
          {
            title: t('authorityManagement'),
          },
          {
            title: t('user'),
          },
        ]}
      />
      <CardBox
        title={t('listOfUser')}
        rightSide={
          <div className="delete-icon">
            <CardRightButtonGroup
              buttonIcon={<AddIcon color="#ffffff" />}
              buttonLabel={t('new')}
              buttonClass="btn-primary btn-sm"
              buttonStatus
              buttonFunction={(): void => {
                setModalOpen(true);
                setModalView({
                  title: t('new'),
                  content: <AuthorityAddUser closeModal={() => setModalOpen(false)} />,
                });
              }}
              buttonEnable={checkPermission('auth:user:add', loadMenus)}
              // Add delete button configuration
              DeleteButtonIcon={<DeleteButtonIcon />}
              deleteButtonLabel={t('delete')}
              showDeleteButton
              deleteButtonFunction={handleDeleteClick}
              deleteButtonEnable={checkPermission('auth:user:del', loadMenus)}
              deleteButtonClass="multi-delete-button"
            />
          </div>
        }>
        <DataTable
          actionButtonStatus={!modalOpen}
          action={actionButtons}
          columns={columnsField}
          getFilterData={getFilterData}
          assignFilterValue={searchFilter}
          assignSelectedData={selectedValues}
          getSelectedData={(val: any): any => setSelectedValues(val)}
          getSelectedFullData={(val: any): any => {
            setSelectedUserFullData(val);
          }}
          checkBoxActive
          enablePagination
          isFetching={status === 'loading'}
          values={AuthorityManagementUser}
          fixedColumn={['username']}
          totalRecords={totalDataCount}
          height={411}
        />
      </CardBox>
    </div>
  );
}

export default User;
