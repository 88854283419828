import { post, get, fetch } from '../base/index';

export default {
  getListPage: (data: any = {}) => {
    return post(
      `${process.env.REACT_APP_API_URL}Auth/api/authArea/getAreaList`,
      {},
      { ...data, pageSize: 10 },
    );
  },

  exportArea: (data: any) => {
    return fetch(`${process.env.REACT_APP_API_URL}Auth/api/authArea/export`, 'POST', data);
  },

  importArea: (data: any) => {
    return post(`${process.env.REACT_APP_API_URL}Auth/api/authArea/import`, data);
  },

  downloadImportTemplate: (data: any) => {
    return fetch(`${process.env.REACT_APP_API_URL}Auth/api/authArea/exportTemplate`, 'POST', data);
  },

  getList: () => {
    return get(`${process.env.REACT_APP_API_URL}Auth/api/authArea/tree`, {});
  },

  addArea: (data: any = {}) => {
    return post(`${process.env.REACT_APP_API_URL}Auth/api/authArea/save`, { ...data });
  },

  deleteArea: (data: any = {}) => {
    return post(`${process.env.REACT_APP_API_URL}Auth/api/authArea/del`, {}, data);
  },

  validCode: (data: any = {}) => {
    return post(`${process.env.REACT_APP_API_URL}Auth/api/authArea/valid`, {}, data);
  },

  validName: (data: any = {}) => {
    return post(`${process.env.REACT_APP_API_URL}Auth/api/authArea/validName`, {}, data);
  },
};
