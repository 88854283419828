// Use interface
import { ButtonInterFace } from '../../interface/common.interface';
// import DotLoader from '../dot-loader/DotLoader';
import SpinnerLoader from '../spinnerLoader/spinner-loader';

function Button({
  title,
  onClickFunction,
  disabled,
  className,
  icon,
  loading,
  children,
  buttonEnable = true,
}: ButtonInterFace) {
  return (
    <div className="button-field">
      <button
        type="button"
        className={buttonEnable ? `btn  ${className}` : `btn d-none`}
        onClick={onClickFunction}
        disabled={disabled}>
        {className === 'multi-delete-button' ? (
          <>
            {icon} {title}
          </>
        ) : (
          <>
            {title} {icon}
          </>
        )}
        {children}
        {loading && <SpinnerLoader />}
      </button>
    </div>
  );
}

export default Button;
