import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import InputField from '../../../../../shared-components/input-field/input-field';
import SelectBoxField from '../../../../../shared-components/selectbox-field/selectbox-field';
import Button from '../../../../../shared-components/button/button';
import CheckBoxInputField from '../../../../../shared-components/checkbox-input-field/checkbox-input-field';
import RadioButtonField from '../../../../../shared-components/radio-button-field/radio-button-field';
import { SystemFingerPrintIcon, viewIcon, hideIcon } from '../../../../../assets/svgicons/svgicon';
import auth from '../../../../../utils/api/auth';
import { useAppDispatch } from '../../../../../redux/store';
import { setNotificationError } from '../../../../../redux/slices/NotificationSlices';
import authorityManagementUser from '../../../../../utils/api/SystemManagement/authorityManagementUser';
import { fetchAuthorityManagementUser } from '../../../../../redux/slices/system-management/AuthorityManagementUser';

interface AddUser {
  closeModal: () => void;
  data?: any;
  searchFilter?: {
    pageIndex: 1;
    pageSize: 10;
  };
}

function AuthorityAddUser({ closeModal, data, searchFilter }: AddUser) {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const [isPasswordVisible, setIsPasswordVisible] = useState(false);
  const [isConfirmPasswordVisible, setIsConfirmPasswordVisible] = useState(false);
  const [areaList, setAreaList] = useState([]);
  const [roleList, setRoleList] = useState([]);
  const [depList, setDepList] = useState([]);
  const [disable, setDisable] = useState(false);
  const [checkExistName, setCheckExistName] = useState(true);
  const [resetPassword, setResetPassword] = useState(false);
  const [passwordError, setPasswordError] = useState('');

  const [values, setValues] = useState({
    id: data?.id || '',
    username: data?.username || '',
    loginPwd: data?.loginPwd || '111111',
    password: data?.password || '',
    isSuperuser: data?.isSuperuser || false,
    repassword: data?.repassword || '111111',
    isActive: data?.isActive || 'false',
    multipleLogin: data?.multipleLogin || false,
    userLoginLimit: data?.userLoginLimit || '',
    roleIds: data?.roleIds?.split(',')?.[0] || '',
    deptIds: data?.deptIds?.split(',')?.[0] || '',
    areaIds: data?.areaIds?.split(',')?.[0] || '',
    email: data?.email || '',
    name: data?.name || '',
    lastName: data?.lastName || '',
    fingerprint: data?.fingerprint || '',
  });

  const validatePasswords = (password: string, confirmPassword: string) => {
    if (password !== confirmPassword) {
      setPasswordError(t('The two passwords do not match.'));
      return false;
    }
    setPasswordError('');
    return true;
  };
  const togglePasswordVisibility = (): void => {
    setIsPasswordVisible(!isPasswordVisible);
  };
  const toggleConfirmPasswordVisibility = () => {
    setIsConfirmPasswordVisible(!isConfirmPasswordVisible);
  };
  const onChangeValue = (name: string, value: any) => {
    if (name === 'multipleLogin' || name === 'isSuperuser') {
      setValues((prev) => ({
        ...prev,
        [name]: !prev[name],
      }));

      if (name === 'multipleLogin') {
        setDisable(!values.multipleLogin);
      }
    } else if (name === 'resetPassword') {
      setResetPassword(!resetPassword);
      if (!resetPassword) {
        setValues((prev) => ({
          ...prev,
          loginPwd: '111111',
          repassword: '111111',
        }));
        setPasswordError('');
      }
    } else {
      setValues((prev) => ({
        ...prev,
        [name]: Array.isArray(value) ? value[0] : value,
      }));

      if (name === 'loginPwd' || name === 'repassword') {
        validatePasswords(
          name === 'loginPwd' ? value : values.loginPwd,
          name === 'repassword' ? value : values.repassword,
        );
      }
    }
  };

  const getAuthAreaList = () => {
    auth
      .getAuthArea({ pageNo: 1 })
      .then((res) => {
        const authArea = res.data.data.data.map((area: any) => ({
          label: area.name,
          id: area.id,
        }));
        setAreaList(authArea);
      })
      .catch((error) => {
        dispatch(
          setNotificationError({
            error: error.message,
            status: error.status,
          }),
        );
      });
  };

  const fetchRoleList = async () => {
    try {
      const response = await authorityManagementUser.getList();
      const formattedData = response.data.item
        .map((item: any) => ({
          label: item.text || '',
          id: item.id || '',
        }))
        .filter((item: any) => item.label && item.id);
      setRoleList(formattedData);
    } catch (error: any) {
      dispatch(
        setNotificationError({
          error: error.message,
          status: error.status,
        }),
      );
    }
  };

  const getDeptList = () => {
    authorityManagementUser
      .getDepList({ pageNo: 1 })
      .then((res) => {
        const dept = res.data.data.data.map((lev: any) => ({
          label: lev.name,
          id: lev.id,
        }));
        setDepList(dept);
      })
      .catch((error) => {
        dispatch(
          setNotificationError({
            error: error.message,
            status: error.status,
          }),
        );
      });
  };

  const onBlur = async (username: string, value: any) => {
    if (username === 'username' && value !== '' && value !== data?.username) {
      const responseData = await authorityManagementUser.isExistName({ username: value });
      setCheckExistName(responseData?.data);
    }
  };

  const submit = () => {
    setDisable(true);
    if (!validatePasswords(values.loginPwd, values.repassword)) {
      return;
    }

    const request = {
      id: values.id,
      username: values.username,
      name: values.name,
      email: values.email,
      loginPwd: values.loginPwd,
      repassword: values.repassword,
      areaIds: values.areaIds,
      deptIds: values.deptIds,
      roleIds: values.roleIds,
      userLoginLimit: values.userLoginLimit,
      lastName: values.lastName,
      isActive: values.isActive,
      multipleLogin: values.multipleLogin,
      isSuperuser: values.isSuperuser,
      fingerprint: values.fingerprint,
    };

    authorityManagementUser
      ?.saveUser(request)
      .then((response) => {
        if (response?.data?.success) {
          const message = data ? t('userUpdatedSuccessfully') : t('userAddedSuccessfully');
          dispatch(
            setNotificationError({
              error: message,
              status: 200,
            }),
          );
          closeModal();
          dispatch(
            fetchAuthorityManagementUser({
              pageNo: searchFilter?.pageIndex || 1,
              pageSize: searchFilter?.pageSize || 10,
            }),
          );
        } else {
          setDisable(false);
          dispatch(
            setNotificationError({
              error: response.data.message,
              status: response.data.code,
            }),
          );
        }
      })
      .catch((error) => {
        setDisable(false);
        dispatch(
          setNotificationError({
            error: error.message,
            status: error.status,
          }),
        );
      });
  };

  useEffect(() => {
    getAuthAreaList();
    fetchRoleList();
    getDeptList();
  }, []);

  useEffect(() => {
    if (values.username && values?.email && checkExistName && !passwordError) {
      setDisable(false);
    } else {
      setDisable(true);
    }
  }, [values, checkExistName, passwordError]);

  const renderPasswordFields = () => (
    <>
      <InputField
        id="loginPwd"
        name="loginPwd"
        label="Password"
        type={isPasswordVisible ? 'text' : 'password'}
        value={values.loginPwd}
        onChangeFunction={onChangeValue}
        innerIconStatus
        InnerIcon={isPasswordVisible ? viewIcon : hideIcon}
        innerIconFunction={togglePasswordVisibility}
        required
        errorStatus={!!passwordError}
        message={passwordError}
      />
      <InputField
        id="repassword"
        name="repassword"
        label="Confirm Password"
        type={isConfirmPasswordVisible ? 'text' : 'password'}
        value={values.repassword}
        onChangeFunction={onChangeValue}
        innerIconStatus
        InnerIcon={isConfirmPasswordVisible ? viewIcon : hideIcon}
        innerIconFunction={toggleConfirmPasswordVisibility}
        required
      />
    </>
  );

  return (
    <div className="add-user-container">
      <div className="user-form-fields">
        <div className="user-input-filelds">
          <InputField
            id="username"
            name="username"
            label={t('userName')}
            type="text"
            value={values.username}
            onBlurFunction={onBlur}
            errorStatus={values.username !== '' && !checkExistName}
            message={t('nameIsAlreadyExist')}
            onChangeFunction={onChangeValue}
            required
            readonly={!!data}
          />
          {!data && renderPasswordFields()}

          {!data && (
            <div className="state-manage">
              <p className="container-name">{t('state')}</p>
              <div className="state-checkboxes flex items-center gap-4">
                <RadioButtonField
                  name="isActive"
                  data={[
                    { id: 'enable', label: t('enable'), value: 'true' },
                    { id: 'disable', label: t('disable'), value: 'false' },
                  ]}
                  value={values.isActive}
                  onChangeFunction={onChangeValue}
                />
              </div>
            </div>
          )}
        </div>
        {!data && (
          <div className="item text-danger">
            <div className="note">
              {t('passwordIsACompositionOf4to18CharactersDefaultIs111111')}
            </div>
          </div>
        )}
        <div className="login-permissions-checkboxes">
          <CheckBoxInputField
            name="multipleLogin"
            data={[{ id: 'multipleLogin', label: t('multipleLogin'), value: 'true' }]}
            value={values.multipleLogin ? ['true'] : []}
            onChangeFunction={onChangeValue}
          />
          <CheckBoxInputField
            name="isSuperuser"
            data={[{ id: 'superUserState', label: t('superUserState'), value: 'true' }]}
            value={values.isSuperuser ? ['true'] : []}
            onChangeFunction={onChangeValue}
          />
          {data && (
            <CheckBoxInputField
              name="resetPassword"
              data={[{ id: 'resetPassword', label: t('resetPassword'), value: 'true' }]}
              value={resetPassword ? ['true'] : []}
              onChangeFunction={onChangeValue}
            />
          )}
        </div>

        <div className="resetPassword-fields">
          {data && resetPassword && renderPasswordFields()}
        </div>
        <div className="maximum-number-field">
          <InputField
            id="userLoginLimit"
            name="userLoginLimit"
            label={t('maximumNumber')}
            type="number"
            value={values.userLoginLimit}
            onChangeFunction={onChangeValue}
            disabled={!values.multipleLogin}
            min={1}
            max={100}
          />
        </div>
        <div className="item text-danger">
          <div className="note">{t('limitMultipleLoginForTheSameAccount')}</div>
        </div>

        <div className="role-department-fields">
          <SelectBoxField
            id="roleIds"
            name="roleIds"
            label={t('role')}
            value={values.roleIds}
            onChangeFunction={(name, value) => onChangeValue(name, value.id)}
            data={roleList}
            disabled={values.isSuperuser}
          />
          <SelectBoxField
            id="deptIds"
            name="deptIds"
            label={t('authDepartment')}
            value={values.deptIds}
            onChangeFunction={(name, value) => onChangeValue(name, value.id)}
            data={depList}
            disabled={values.isSuperuser}
          />
        </div>
        <div className="item text-danger">
          <div className="note">
            {t('ifYouDon&apostSelectADepartmentYouWillNotHaveFullDepartmentalPermission')}
          </div>
        </div>

        <div className="authorizeArea-fields">
          <SelectBoxField
            id="areaIds"
            name="areaIds"
            label={t('authorizeArea')}
            value={values.areaIds}
            onChangeFunction={(name, value) => onChangeValue(name, value.id)}
            data={areaList}
            disabled={values.isSuperuser}
          />
        </div>
        <div className="item text-danger">
          <div className="note">
            {t('ifYouDon&apostSelectAZoneYouWillNotHaveFullZonePermission')}
          </div>
        </div>

        <div className="personal-info-fields">
          <InputField
            id="email"
            name="email"
            label={t('email')}
            type="email"
            value={values.email}
            onChangeFunction={onChangeValue}
            required
          />
          <InputField
            id="name"
            name="name"
            label={t('firstName')}
            type="text"
            value={values.name}
            onChangeFunction={onChangeValue}
          />
          <InputField
            id="lastName"
            name="lastName"
            label={t('lastName')}
            type="text"
            value={values.lastName}
            onChangeFunction={onChangeValue}
          />
          <InputField
            id="fingerprint"
            name="fingerprint"
            label={t('fingerprint')}
            type="text"
            value={values.fingerprint}
            onChangeFunction={onChangeValue}
            rightIcon={<SystemFingerPrintIcon />}
          />
        </div>
      </div>
      <div className="form-buttons-right">
        {!data && (
          <Button
            onClickFunction={submit}
            title={t('save&new')}
            className="btn-primary"
            disabled={disable}
          />
        )}
        <Button
          onClickFunction={() => {
            closeModal();
          }}
          title={t('cancel')}
          className="btn-outline-primary"
        />
        <Button
          onClickFunction={submit}
          title={t('done')}
          className="btn-primary"
          disabled={disable}
        />
      </div>
    </div>
  );
}

AuthorityAddUser.defaultProps = {
  data: '',
  searchFilter: '',
};

export default AuthorityAddUser;
