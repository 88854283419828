import { post } from '../base/index';

export default {
  getListPage: (data: any = {}) => {
    return post(`${process.env.REACT_APP_API_URL}Acc/api/accGlobalApb/list`, {}, { ...data });
  },
  save: (data: any = {}) => {
    return post(`${process.env.REACT_APP_API_URL}Acc/api/accGlobalApb/save`, data, {});
  },
  delete: (data: any = {}) => {
    return post(`${process.env.REACT_APP_API_URL}Acc/api/accGlobalApb/delete`, {}, data);
  },
  validApb: () => {
    return post(`${process.env.REACT_APP_API_URL}Acc/api/accGlobalApb/validApb`, {});
  },
  validEntranceApb: () => {
    return post(`${process.env.REACT_APP_API_URL}Acc/api/accGlobalApb/validEntranceApb`, {});
  },
  judge: (data: any = {}) => {
    return post(`${process.env.REACT_APP_API_URL}Acc/api/accGlobalApb/judge`, {}, data);
  },
  getAccDoorByZone: (data: any = {}) => {
    return post(
      `${process.env.REACT_APP_API_URL}Acc/api/accReaderZone/getAccDoorByZone?zoneId=${data?.accZoneId}`,
      {},
    );
  },
  getPersonList: (data: any = {}) => {
    return post(`${process.env.REACT_APP_API_URL}Acc/api/accGlobalApb/personList`, {}, { ...data });
  },
  isExistName: (data: any = {}) =>
    post(`${process.env.REACT_APP_API_URL}Acc/api/accGlobalApb/valid`, {}, data),
  selectPersonlist: (data: any = {}) => {
    return post(`${process.env.REACT_APP_API_URL}Acc/api/accAdvancedPerson/selectPersonlist`, data);
  },
  addPerson: (data: any = {}) => {
    return post(`${process.env.REACT_APP_API_URL}Acc/api/accGlobalApb/addPerson`, {}, data);
  },
  delPerson: (data: any = {}) => {
    return post(`${process.env.REACT_APP_API_URL}Acc/api/personLimitZone/delPerson`, {}, data);
  },
};
