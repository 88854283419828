import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
// Import shared components
import { useAppDispatch, useAppSelector } from '../../redux/store';
import TopHeader from '../../shared-components/top-header/top-header';
import CardBox from '../../shared-components/card/card';
import DataTable from '../../shared-components/data-table/data-table';
import ModalBox from '../../shared-components/modal-box/modal-box';
import CardRightButtonGroup from '../../shared-components/card-right-button-group/card-right-button-group';
import { AddIcon, DeleteButtonIcon } from '../../assets/svgicons/svgicon';
import DeleteModalBox from '../../shared-components/delete-modal-box/delete-modal-box';
import AddLinkage from './components/add-linkage';
import { fetchLinkage, setSearchFilter, deleteLinkage } from '../../redux/slices/LinkageSlices';
import linkage from '../../utils/api/AccessRule/linkage';
import { setNotificationError } from '../../redux/slices/NotificationSlices';
import { checkPermission } from '../../utils/role-permission';

function LinkageOutputPoint() {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { linkages, status, totalDataCount, searchFilter } = useAppSelector(
    (state: any) => state.linkage,
  );

  /**
   * Selects the `loadMenus` permission from the authentication slice of the Redux store.
   *
   * @returns {Object} loadMenus - The user's menu permissions.
   */
  const { loadMenus } = useAppSelector((state) => state.auth);

  const [modalOpen, setModalOpen] = useState(false);
  const [modalView, setModalView] = useState({
    title: '',
    content: <>hi</>,
  });
  const [selectedValues, setSelectedValues] = useState<string[]>([]);
  const [selectedLinkageFullData, setSelectedLinkageFullData] = useState<any[]>([]);

  let handleEditOpen: any;

  const canEdit = checkPermission('acc:linkage:edit', loadMenus) || false;
  const columnsField = [
    {
      accessorKey: 'name',
      header: t('linkageName'),
      Cell: ({ row }: any) => (
        <div
          className={canEdit ? 'list-hyperlink' : ''}
          role="presentation"
          onClick={(event) => {
            if (!canEdit) return;
            event.stopPropagation(); // Prevent row selection
            handleEditOpen(row.original); // Call your edit function
          }}>
          {row?.original?.name}
        </div>
      ),
      size: 150,
    },
    {
      accessorKey: 'deviceAlias',
      header: t('deviceName'),
    },
    {
      accessorKey: 'triggerCond',
      header: t('linkageTriggerConditions'),
    },
  ];

  handleEditOpen = (editData: any) => {
    linkage
      .edit({ id: editData.id })
      .then((res) => {
        const response = res?.data?.data?.item;

        const updateData = {
          ...response,
          device: response?.deviceId,
          trigger: response?.triggerCond,
          inputPoint: [`${response?.inputAddr}_AccDoor`],
          doorOutput: [`${response?.outputAddr}_AccDoor`],
          selectedDevices: [
            {
              id: response?.deviceId,
              label: response?.deviceAlias,
              value: response?.deviceId,
            },
          ],
          triggerValue: response?.triggerCond.split(',').map((value: string, index: number) => ({
            value: value.trim(),
            label: response?.triggerCondName.split(',')[index]?.trim(),
          })),
        };
        if (!modalOpen) {
          setModalOpen(true);
          setModalView({
            title: t('edit'),
            content: <AddLinkage closeModal={() => setModalOpen(false)} update={updateData} />,
          });
        }
      })
      .catch((error) => {
        dispatch(
          setNotificationError({
            error: error.message,
            status: error.status,
          }),
        );
      });
  };

  const fetchData = async (params: any) => {
    if (status === 'success') {
      dispatch(fetchLinkage({ ...params }));
    }
  };

  const deleteRecord = (id: any) => {
    linkage
      .delete(id)
      .then((response) => {
        if (response.data.success) {
          dispatch(
            setNotificationError({
              error: t('linkageDeletedSuccessfully'),

              status: 200,
            }),
          );
          dispatch(deleteLinkage(id));
          fetchData({
            pageNo: searchFilter?.pageIndex,
            pageSize: searchFilter?.pageSize,
            ...searchFilter?.filter,
          });
          setModalOpen(false);
        } else {
          dispatch(
            setNotificationError({
              error: response.data.msg,
              status: response.data.code,
            }),
          );
        }
      })
      .catch((error) => {
        dispatch(
          setNotificationError({
            error: error.message,
            status: error.response?.status || 500,
          }),
        );
      });
  };
  const handleDeleteClick = () => {
    if (selectedLinkageFullData.length === 0) {
      dispatch(
        setNotificationError({
          error: t('pleaseSelectAtLeastOneLinkage'), // Also fixed the error message
          status: 400,
        }),
      );
      return;
    }

    setModalOpen(true);
    setModalView({
      title: t('delete'),
      content: (
        <DeleteModalBox
          closeModal={() => setModalOpen(false)}
          id={selectedLinkageFullData.map((val) => val.id).join(',')}
          okFunction={deleteRecord}
          isMultiSelect
        />
      ),
    });
  };
  const handleDelete = (val: any) => {
    setModalOpen(true);
    setModalView({
      title: t('delete'),
      content: (
        <DeleteModalBox
          closeModal={() => setModalOpen(false)}
          id={val.id}
          okFunction={deleteRecord}
        />
      ),
    });
  };
  const actionButtons = [
    {
      label: t('edit'),
      enable: checkPermission('acc:linkage:edit', loadMenus),
      function: (val: any) => handleEditOpen(val),
    },
    {
      label: t('delete'),
      enable: checkPermission('acc:linkage:del', loadMenus),
      function: (val: any) => handleDelete(val),
    },
  ];

  const getFilterData = (filter: any): any => {
    if (filter.pageIndex > 0) {
      dispatch(setSearchFilter(filter));
      fetchData({ pageNo: filter.pageIndex, pageSize: filter.pageSize, ...filter.filter });
    }
  };
  return (
    <div className="department">
      <ModalBox
        status={modalOpen}
        closeModal={() => setModalOpen(false)}
        title={modalView.title}
        content={modalView.content}
      />
      <TopHeader
        title={t('access')}
        broadCram={[
          {
            title: t('accessRule'),
          },
          {
            title: t('linkage'),
          },
        ]}
      />
      <CardBox
        title={t('listOfLinkage')}
        rightSide={
          <div className="delete-icon">
            <CardRightButtonGroup
              buttonIcon={<AddIcon color="#ffffff" />}
              buttonLabel={t('new')}
              buttonClass="btn-primary btn-sm"
              buttonStatus
              buttonFunction={(): void => {
                setModalOpen(true);
                setModalView({
                  title: t('new'),
                  content: <AddLinkage closeModal={() => setModalOpen(false)} />,
                });
              }}
              buttonEnable={checkPermission('acc:linkage:add', loadMenus)}
              // Add delete button configuration
              DeleteButtonIcon={<DeleteButtonIcon />}
              deleteButtonLabel={t('delete')}
              showDeleteButton
              deleteButtonFunction={handleDeleteClick}
              deleteButtonEnable={checkPermission('acc:linkage:del', loadMenus)}
              deleteButtonClass="multi-delete-button"
            />
          </div>
        }>
        <DataTable
          actionButtonStatus={!modalOpen} // Disable action buttons when modal is open
          action={actionButtons}
          columns={columnsField}
          getFilterData={getFilterData}
          assignFilterValue={searchFilter}
          assignSelectedData={selectedValues}
          getSelectedData={(val: any): any => setSelectedValues(val)}
          getSelectedFullData={(val: any): any => {
            setSelectedLinkageFullData(val);
          }}
          checkBoxActive
          enablePagination
          isFetching={status === 'loading'} // Placeholder status
          values={linkages} // Use the actual data here
          fixedColumn={['name']}
          totalRecords={totalDataCount}
          height={411}
        />
      </CardBox>
    </div>
  );
}

export default LinkageOutputPoint;
