import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
// Import shared components
import { useAppDispatch, useAppSelector } from '../../../../../redux/store';
import CardBox from '../../../../../shared-components/card/card';
import DataTable from '../../../../../shared-components/data-table/data-table';
import ModalBox from '../../../../../shared-components/modal-box/modal-box';
import CardRightButtonGroup from '../../../../../shared-components/card-right-button-group/card-right-button-group';
import { CheckCircleIcon, CloseCircleIcon } from '../../../../../assets/svgicons/svgicon';
import {
  fetchViewAuthorizedDevice,
  setSearchFilter,
} from '../../../../../redux/slices/system-management/CommunicationDeviceSlices';

function ViewAuthorizedDevice() {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { AuthorizedDevice, authorizedDeviceStatus, authorizedDeviceDataCount, searchFilter } =
    useAppSelector((state: any) => state.communication);
  const [modalOpen, setModalOpen] = useState(false);

  const columnsField = [
    {
      accessorKey: 'ip',
      header: t('ipAddress'),
      size: 150,
    },
    {
      accessorKey: 'macAddress',
      header: t('macAddress'),
    },
    {
      accessorKey: 'subnetMask',
      header: t('subnetMask'),
    },
    {
      accessorKey: 'gateway',
      header: t('gatewayAddress'),
    },
    {
      accessorKey: 'sn',
      header: t('serialNumber'),
    },
    {
      accessorKey: 'deviceType',
      header: t('moduleName'),
    },
    {
      accessorKey: 'serverUrl',
      header: t('serverAddress'),
    },
    {
      accessorKey: 'deviceName',
      header: t('deviceType'),
    },
    {
      accessorKey: 'ping',
      header: t('communicationConnection'),
      visibleInShowHideMenu: false,
      Cell: ({ row }: any) => {
        const value = row.original?.ping ? (
          <CheckCircleIcon color="#48d726de" />
        ) : (
          <CloseCircleIcon color="#f00" />
        );
        return value;
      },
    },
  ];

  const fetchData = async (params: any) => {
    if (authorizedDeviceStatus === 'success') {
      dispatch(fetchViewAuthorizedDevice({ ...params }));
    }
  };

  const getFilterData = (filter: any): any => {
    if (filter.pageIndex > 0) {
      dispatch(setSearchFilter(filter));
      fetchData({
        pageNo: filter.pageIndex,
        pageSize: filter.pageSize,
        ...filter.filter,
      });
    }
  };

  return (
    <div className="ViewAuthorizedModal">
      <ModalBox status={modalOpen} closeModal={() => setModalOpen(false)} title="" content="" />
      <CardBox rightSide={<CardRightButtonGroup buttonStatus={false} />}>
        <DataTable
          actionButtonStatus={false}
          columns={columnsField}
          getFilterData={getFilterData}
          assignFilterValue={searchFilter}
          checkBoxActive={false}
          enablePagination
          isFetching={authorizedDeviceStatus === 'loading'}
          values={AuthorizedDevice}
          fixedColumn={['ip']}
          totalRecords={authorizedDeviceDataCount}
          height={411}
        />
      </CardBox>
    </div>
  );
}

export default ViewAuthorizedDevice;
