import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Button from '../../../../../shared-components/button/button';
import CheckBoxInputField from '../../../../../shared-components/checkbox-input-field/checkbox-input-field';
import InputField from '../../../../../shared-components/input-field/input-field';
import { useAppDispatch, useAppSelector } from '../../../../../redux/store';
import {
  fetchEmailManagement,
  fetchMailParam,
} from '../../../../../redux/slices/system-management/EmailManagementSlices';
import emailManagement from '../../../../../utils/api/SystemManagement/emailManagement';
import { setNotificationError } from '../../../../../redux/slices/NotificationSlices';

interface ModalType {
  closeModal: () => void;
}

function OutgoingMailServerSettings({ closeModal }: ModalType) {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const { emailParams, searchFilter } = useAppSelector((state: any) => state.emailManagement);

  const [disable, setDisable] = useState(false);
  const [connectionResponse, setConnectionResponse] = useState(true);

  const [values, setValues] = useState({ ...emailParams });

  const handleChange = (name: string, value: any) => {
    setValues((prev: any) => ({
      ...prev,
      [name]: value,
    }));
    if (name === 'base.mailSSLSelect' && value !== '') {
      setValues((prev: any) => ({
        ...prev,
        'base.mailTLSSelect': [],
        'base.mailSTARTTLSSelect': [],
        'base.mailServerPort': '465',
      }));
    } else if (name === 'base.mailTLSSelect' && value !== '') {
      setValues((prev: any) => ({
        ...prev,
        'base.mailSSLSelect': [],
        'base.mailSTARTTLSSelect': [],
        'base.mailServerPort': '587',
      }));
    } else if (name === 'base.mailSTARTTLSSelect' && value !== '') {
      setValues((prev: any) => ({
        ...prev,
        'base.mailSSLSelect': [],
        'base.mailTLSSelect': [],
        'base.mailServerPort': '587',
      }));
    }
  };

  const handleTestConnection = async () => {
    setDisable(true);
    try {
      const request = {
        'base.mailServerHost': values?.['base.mailServerHost'],
        'base.mailServerPort': values?.['base.mailServerPort'],
        'base.mailUserName': values?.['base.mailUserName'],
        'base.mailPassword': window.btoa(values?.['base.mailPassword']),
        'base.mailShowMailName': values?.['base.mailShowMailName'],
        'base.mailSSLSelect': values?.['base.mailSSLSelect']?.length > 0 ? '1' : '0',
        'base.mailTLSSelect': values?.['base.mailTLSSelect']?.length > 0 ? '1' : '0',
        'base.mailSTARTTLSSelect': values?.['base.mailSTARTTLSSelect']?.length > 0 ? '1' : '0',
      };

      const res = await emailManagement.testSendMail(request);

      if (res?.data?.success) {
        dispatch(
          setNotificationError({
            error: 'Testing Successful!',
            status: 200,
          }),
        );
        setConnectionResponse(false);
        setDisable(false);
      } else {
        setNotificationError({
          error: res?.data?.msg,
          status: 500,
        });
        setDisable(false);
      }
    } catch (error: any) {
      setDisable(false);
      dispatch(
        setNotificationError({
          error: error.response.data.msg || error.message,
          status: error.status,
        }),
      );
    }
  };

  const handleSaveMailParam = async () => {
    setConnectionResponse(true);
    try {
      const request: any = {
        'base.mailServerHost': values?.['base.mailServerHost'],
        'base.mailServerPort': Number(values?.['base.mailServerPort']) || 0,
        'base.mailUserName': values?.['base.mailUserName'],
        'base.mailPassword': window.btoa(values?.['base.mailPassword']),
        'base.mailShowMailName': values?.['base.mailShowMailName'],
      };
      if (values?.['base.mailSSLSelect']?.length > 0) {
        request['base.mailSSLSelect'] = 1;
      }
      if (values?.['base.mailTLSSelect']?.length > 0) {
        request['base.mailTLSSelect'] = 1;
      }
      if (values?.['base.mailSTARTTLSSelect']?.length > 0) {
        request['base.mailSTARTTLSSelect'] = 1;
      }

      const res = await emailManagement.saveMailParam(request);
      if (res?.data?.success) {
        dispatch(
          setNotificationError({
            error: 'Successfully connected',
            status: 200,
          }),
        );
        closeModal();
        setConnectionResponse(false);
        dispatch(
          fetchEmailManagement({
            pageNo: searchFilter?.pageIndex,
            pageSize: searchFilter?.pageSize,
          }),
        );
      } else {
        setNotificationError({
          error: res?.data?.msg,
          status: 500,
        });
        setConnectionResponse(false);
      }
    } catch (error: any) {
      setConnectionResponse(false);
      dispatch(
        setNotificationError({
          error: error?.response?.data?.msg || error?.message,
          status: error?.status,
        }),
      );
    }
  };

  useEffect(() => {
    dispatch(fetchMailParam());
    setValues(emailParams);
  }, []);

  useEffect(() => {
    if (
      values?.['base.mailServerHost'] &&
      values?.['base.mailServerPort'] &&
      values?.['base.mailUserName'] &&
      values?.['base.mailPassword']
    ) {
      setDisable(false);
    } else {
      setDisable(true);
    }
  }, [values]);

  return (
    <div className="outgoing-mail-server-settings">
      <h3>{t('outgoingMailServerSettings')}</h3>
      <div className="flex-row">
        <InputField
          name="base.mailServerHost"
          label={t('emailSendingServer')}
          id="base.mailServerHost"
          type="text"
          value={values?.['base.mailServerHost']}
          onChangeFunction={handleChange}
          required
        />

        <div className="checkbox-group">
          <CheckBoxInputField
            name="base.mailSSLSelect"
            data={[
              {
                label: 'SSL',
                id: '1',
                value: '1',
              },
            ]}
            value={values?.['base.mailSSLSelect']}
            onChangeFunction={handleChange}
          />
          <CheckBoxInputField
            name="base.mailTLSSelect"
            data={[
              {
                label: 'TLS',
                id: '1',
                value: '1',
              },
            ]}
            value={values?.['base.mailTLSSelect']}
            onChangeFunction={handleChange}
          />
          <CheckBoxInputField
            name="base.mailSTARTTLSSelect"
            data={[
              {
                label: 'STARTTLS',
                id: '1',
                value: '1',
              },
            ]}
            value={values?.['base.mailSTARTTLSSelect']}
            onChangeFunction={handleChange}
          />
        </div>
      </div>
      <p className="field-helper-text">smtp.xxx.xxx</p>
      <div className="flex-input">
        <InputField
          name="base.mailUserName"
          label={t('emailAccount')}
          id="base.mailUserName"
          type="text"
          value={values?.['base.mailUserName']}
          onChangeFunction={handleChange}
          required
        />
        <InputField
          name="base.mailServerPort"
          label={t('port')}
          id="base.mailServerPort"
          type="text"
          value={values?.['base.mailServerPort']}
          onChangeFunction={handleChange}
          required
        />
      </div>
      <p className="field-helper-text">xxx@xxx.xxx</p>
      <div className="flex-input">
        <InputField
          name="base.mailPassword"
          label={t('password')}
          id="base.mailPassword"
          type="password"
          value={values?.['base.mailPassword']}
          onChangeFunction={handleChange}
        />
        <InputField
          name="base.mailShowMailName"
          label={t('senderName')}
          id="base.mailShowMailName"
          type="text"
          value={values?.['base.mailShowMailName']}
          onChangeFunction={handleChange}
        />
      </div>
      <div className="test-btn">
        <Button
          onClickFunction={handleTestConnection}
          title={disable ? `${t('loading')}...` : t('testConnection')}
          className="btn-primary"
          disabled={disable}
          loading={disable}
        />
      </div>
      <div className="form-buttons-right">
        <Button onClickFunction={closeModal} title={t('cancel')} className="btn-default" />
        <Button
          onClickFunction={handleSaveMailParam}
          title={t('done')}
          className="btn-primary"
          disabled={connectionResponse}
        />
      </div>
    </div>
  );
}

export default OutgoingMailServerSettings;
