/* eslint-disable no-param-reassign */
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

// Use apis calls
import communicationMonitor from '../../../utils/api/SystemManagement/communicationMonitor';

// Use other slices
import { setNotificationError } from '../NotificationSlices';

// Use interface
interface CommunicationMonitorInterface {
  communicationMonitor: any;
  status: string;
}
// Use get table custom attribute list
export const fetchCommunicationMonitor = createAsyncThunk(
  'communication/fetchCommunicationMonitor',
  async (data: any, { dispatch }) => {
    const response = await communicationMonitor
      .admsParams()
      .then((response1) => {
        const permissions: string[] = [];
        const permision = ['adms.encrypted.transmission', 'adms.linkIntenet', 'adms.serverPort'];

        permision.forEach((val) => {
          if (response1.data.data[`pers.${val}.encryptProp`] === 'true') {
            permissions.push(val);
          }
        });
        return {
          ...response1.data,
          data: {
            ...response1.data.data,
            permissions,
          },
        };
      })
      .catch((error) => {
        dispatch(setNotificationError(error.response.data));
        return error;
      });
    return response;
  },
);

const initialState: CommunicationMonitorInterface = {
  communicationMonitor: {},
  status: 'success',
};

export const CommunicationMonitorSlices: any = createSlice({
  name: 'communicationMonitor',
  initialState,
  reducers: {
    updateCommunicationMonitor: (state, action: any) => {
      state.communicationMonitor = action.payload;
    },
  },
  extraReducers: (builder) => {
    // Add reducers for additional action types here, and handle loading state as needed
    builder
      .addCase(fetchCommunicationMonitor.pending, (state = initialState) => {
        state.status = 'loading';
      })
      .addCase(fetchCommunicationMonitor.fulfilled, (state: any, action: any) => {
        // Add user to the state array
        if (action.payload.success) {
          const permissions: string[] = [];
          const permission = ['adms.encrypted.transmission', 'adms.linkIntenet', 'adms.serverPort'];

          permission.forEach((val) => {
            if (action.payload.data[`pers.${val}.encryptProp`] === 'true') {
              permissions.push(val);
            }
          });
          state.communicationMonitor = action.payload.data;
          state.communicationMonitor.permissions = permissions;
        }
        state.status = 'success';
      })
      .addCase(fetchCommunicationMonitor.rejected, (state: any) => {
        state.status = 'success';
      });
  },
});

// Action creators are generated for each case reducer function
export const { updateCommunicationMonitor } = CommunicationMonitorSlices.actions;

export default CommunicationMonitorSlices.reducer;
