import { post } from '../base/index';

export default {
  getListPage: (data: any = {}) => {
    return post(`${process.env.REACT_APP_API_URL}Acc/api/personLimitZone/list`, {}, { ...data });
  },
  getPersonLimitZoneDetail: (data: any = {}) => {
    return post(
      `${process.env.REACT_APP_API_URL}Acc/api/personLimitZone/getPersonLimitZoneDetail/${data}`,
      {},
    );
  },
  isExistName: (data: any = {}) =>
    post(`${process.env.REACT_APP_API_URL}Acc/api/personLimitZone/valid`, {}, data),
  save: (data: any = {}) => {
    return post(`${process.env.REACT_APP_API_URL}Acc/api/personLimitZone/save`, data, {});
  },
  delete: (data: string = '') => {
    return post(`${process.env.REACT_APP_API_URL}Acc/api/personLimitZone/delete/${data}`, {});
  },
  selectPersonList: (data: any = {}) => {
    return post(`${process.env.REACT_APP_API_URL}Acc/api/personLimitZone/selectPersonList`, data);
  },
  addPerson: (data: any = {}) => {
    return post(`${process.env.REACT_APP_API_URL}Acc/api/personLimitZone/addPerson`, {}, data);
  },
  delPerson: (data: any = {}) => {
    return post(`${process.env.REACT_APP_API_URL}Acc/api/personLimitZone/delPerson`, {}, data);
  },
  getPersonList: (data: any = {}) => {
    return post(`${process.env.REACT_APP_API_URL}Acc/api/personLimitZone/personList`, data, {});
  },
  resetPersonLimitZone: (data: any = {}) => {
    return post(
      `${process.env.REACT_APP_API_URL}Acc/api/personLimitZone/resetPersonLimitZone`,
      {},
      data,
    );
  },
};
