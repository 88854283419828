import { post, fetch } from './base/index';

export default {
  getPositions: (data: any = {}) => {
    return post(
      `${process.env.REACT_APP_API_URL}Pers/api/position/getPositionList`,
      {},
      { ...data },
    );
  },
  getAllPositions: () => {
    return post(`${process.env.REACT_APP_API_URL}Pers/api/position/getAllPositions`, {});
  },
  addPosition: (data: any = {}) => {
    return post(`${process.env.REACT_APP_API_URL}Pers/api/position/add`, { ...data });
  },
  deletePosition: (data: any = {}) => {
    return post(`${process.env.REACT_APP_API_URL}Pers/api/position/delete`, {}, data);
  },
  exportPosition: (data: any) => {
    return fetch(`${process.env.REACT_APP_API_URL}Pers/api/position/exportPosition`, 'POST', data);
  },
  importPosition: (data: any) => {
    return fetch(`${process.env.REACT_APP_API_URL}Pers/api/position/importPosition`, 'POST', data);
  },
  downloadImportFile: (data: any) => {
    return fetch(
      `${process.env.REACT_APP_API_URL}Pers/api/position/dlPositionImprtTemplate`,
      'POST',
      data,
    );
  },
  isExist: (data: any) =>
    post(`${process.env.REACT_APP_API_URL}Pers/api/position/isExist`, {}, data),
};
