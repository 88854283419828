import { post } from './base/index';

export default {
  getCustomAttribute: (data: any = {}) => {
    return post(
      `${process.env.REACT_APP_API_URL}Pers/api/attribute/getCustmAttList`,
      {},
      { ...data },
    );
  },
  addCustomAttribute: (data: any = {}) => {
    return post(`${process.env.REACT_APP_API_URL}Pers/api/attribute/add`, { ...data });
  },
  deleteCustomAttribute: (data: any = {}) => {
    return post(`${process.env.REACT_APP_API_URL}Pers/api/attribute/delete`, {}, data);
  },
};
