import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import CheckBoxInputField from '../../../../../shared-components/checkbox-input-field/checkbox-input-field';
import { AttentionIcon } from '../../../../../assets/svgicons/svgicon';
import Button from '../../../../../shared-components/button/button';
import databaseManagement from '../../../../../utils/api/SystemManagement/databaseManagement';
import { useAppDispatch, useAppSelector } from '../../../../../redux/store';
import { setNotificationError } from '../../../../../redux/slices/NotificationSlices';
import { fetchDbMgt } from '../../../../../redux/slices/system-management/DataBaseMgtSlices';

// interface StateProps {
//   backupFtp: string[];
// }

interface ModalType {
  closeModal: () => void;
}

/**
 * Props for the BackupImmediate component//+
 * @typedef {Object} ModalType//+
 * @property {() => void} closeModal - Function to close the modal//+
 */

function BackupImmediate({ closeModal }: ModalType) {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { searchFilter } = useAppSelector((state: any) => state.databaseManagement);

  const [values, setValues] = useState({
    backupFtp: [],
  });
  const [disable, setDisable] = useState(false);

  const handleChange = async (name: string, value: any) => {
    setValues((prev) => ({
      ...prev,
      [name]: value,
    }));
    if (name === 'backupFtp' && value.length > 0) {
      try {
        const response = await databaseManagement.backupTestConnection();

        if (response?.data.success) {
          closeModal();
        } else {
          dispatch(
            setNotificationError({
              error: response.data.i18nArgs?.[0],
              status: response.data.code || 500,
            }),
          );
        }
      } catch (error: any) {
        dispatch(
          setNotificationError({
            error: error.message,
            status: error.status,
          }),
        );
      }
    }
  };

  const submit = async () => {
    setDisable(true);
    try {
      // after getting the response from back test connection will integrate the
      // back ftp integration logic here.
      const response = await databaseManagement.immeBackup();

      if (response?.data.success) {
        setDisable(false);
        closeModal();
        dispatch(
          setNotificationError({
            error: response.data.msg,
            status: response.data.code || 200,
          }),
        );
        dispatch(
          fetchDbMgt({
            pageNo: searchFilter?.pageIndex,
            pageSize: searchFilter?.pageSize,
          }),
        );
      } else {
        setDisable(false);
        dispatch(
          setNotificationError({
            error: response.data.msg,
            status: response.data.code || 500,
          }),
        );
      }
    } catch (error: any) {
      setDisable(false);
      dispatch(
        setNotificationError({
          error: error.message,
          status: error.status,
        }),
      );
    }
  };

  return (
    <div className="backup-Immediate flex-row column">
      <h2 className="title">{t('areYouSureYouWantToBackUpDataNow')}</h2>
      <CheckBoxInputField
        name="backupFtp"
        data={[
          {
            label: t('backUpToFTPServerAtTheSameTime'),
            id: '1',
            value: '1',
          },
        ]}
        value={values?.backupFtp}
        onChangeFunction={handleChange}
      />
      <div className="text-danger flex-row center">
        <AttentionIcon />
        <span className="error-msg">{t('warningClickCheckBoxFtpServer')}</span>
      </div>
      <div className="form-buttons-right">
        <Button onClickFunction={() => closeModal()} title={t('cancel')} className="btn-default" />
        <Button
          onClickFunction={submit}
          title={t('done')}
          className="btn-primary"
          disabled={disable}
        />
      </div>
    </div>
  );
}

export default BackupImmediate;
