import React, { useEffect, useState } from 'react';

// Use common modules
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from '../../redux/store';

// Use own component
import PersonnelIdSetting from './components/personnel-id-setting';
import CardSetting from './components/card-setting';
import PendingPersonnelSetting from './components/pending-personnel-setting';
import SelfServiceRegistration from './components/self-service-registration';
import PersonnelSensitiveInformationProtection from './components/personnel-sensitive-information-protection';
import RegistrationClient from './components/registration-client';

// Use redux function
import { fetchParametersList, updateParameters } from '../../redux/slices/ParameterSlices';
import { setNotificationError } from '../../redux/slices/NotificationSlices';

// Use api call
import parametersApi from '../../utils/api/parameters';

// Use shared components
import TopHeader from '../../shared-components/top-header/top-header';
import CardBox from '../../shared-components/card/card';
import TabPages from '../../shared-components/tab-pages/tab-pages';
import Button from '../../shared-components/button/button';

// Use interface
import { ParameterReduxInterface } from '../../interface/parameter.interface';
import { checkPermission } from '../../utils/role-permission';

function Parameters() {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  let sortTime = true;
  const { parameters, status }: ParameterReduxInterface = useAppSelector(
    (state: any) => state.parameters,
  );
  const { loadMenus } = useAppSelector((state) => state.auth);
  const [tabs, setTabs] = useState([
    {
      key: 'personnelIdSetting',
      enable: true,
      title: t('personnelIdSetting'),
      content: <PersonnelIdSetting onChangeFunction={() => {}} values={parameters} />,
    },
  ]);
  const [disable, setDisable] = useState(false);
  const [parametersValue, setParameters] = useState(
    parameters || {
      'pers.pinLen': '0',
      'pers.pinSupportLetter': '',
      'pers.pinSupportIncrement': '',
      'pers.cardHex': '',
      'pers.cardsSupport': '',
      'pers.cardsReadMode': '0',
      'pers.tempPerson.audit': '0',
      'pers.selfRegistration': '0',
      qrCodeUrl: '',
      permissions: [],
      certificateType: '',
      registrationCode: '',
      'pers.ocrDriveVerson': '',
      'pers.IDReadMode': '',
      'pers.ocrCertNoType': '',
    },
  );

  const fetchParameterData = () => {
    if (status === 'success') {
      dispatch(fetchParametersList({}));
    }
  };

  const onChangeValue = (value: any) => {
    setParameters((prev: any) => ({
      ...prev,
      ...value,
    }));
  };

  const submit = () => {
    setDisable(true);
    let request = {
      'pers.pinLen': parametersValue['pers.pinLen'],
      'pers.pinSupportLetter': parametersValue['pers.pinSupportLetter'],
      'pers.pinSupportIncrement': parametersValue['pers.pinSupportIncrement'],
      'pers.cardHex': parametersValue['pers.cardHex'],
      'pers.cardsSupport': parametersValue['pers.cardsSupport'],
      'pers.cardsReadMode': parametersValue['pers.cardsReadMode'],
      'pers.tempPerson.audit': parametersValue['pers.tempPerson.audit'],
      'pers.selfRegistration': parametersValue['pers.selfRegistration'],
      // qrCodeUrl: parametersValue,
      // permissions: parametersValue,
      // certificateType: parametersValue,
      // registrationCode: parametersValue,
      'pers.ocrDriveVerson': parametersValue['pers.ocrDriveVerson'],
      'pers.IDReadMode': parametersValue['pers.IDReadMode'],
      'pers.ocrCertNoType': parametersValue['pers.ocrCertNoType'],
      qrCodeUrl: parametersValue.qrCodeUrl,
    };
    const permission = [
      'cardNo',
      'birthday',
      'carPlate',
      'certNumber',
      'email',
      'gender',
      'lastName',
      'pin',
      'name',
      'mobilePhone',
      'bioPhoto',
      'headPortrait',
    ];
    permission.forEach((element: string) => {
      request = {
        ...request,
        [`pers.${element}.encryptProp`]: parametersValue?.permissions?.includes(element)
          ? 'true'
          : 'false',
      };
    });

    parametersApi
      .addParameters(request)
      .then((response) => {
        if (response.data.code === 0) {
          setDisable(false);
          dispatch(
            setNotificationError({
              error: t('parametersUpdatedSuccessfully'),
              status: 200,
            }),
          );
          dispatch(updateParameters(request));
        } else {
          setDisable(false);
          dispatch(
            setNotificationError({
              error: response.data.message,
              status: response.data.code,
            }),
          );
        }
      })
      .catch((error) => {
        setDisable(false);
        dispatch(
          setNotificationError({
            error: error.message,
            status: error.status,
          }),
        );
      });
  };

  useEffect(() => {
    if (Object.keys(parameters).length !== 0) {
      setParameters({ ...parameters });
    }
  }, [parameters]);

  useEffect(() => {
    if (Object.keys(parametersValue).length > 0) {
      setTabs([
        {
          key: 'personnelIdSetting',
          enable: checkPermission('pers:params:edit', loadMenus),
          title: t('personnelIdSetting'),
          content: <PersonnelIdSetting onChangeFunction={onChangeValue} values={parametersValue} />,
        },
        {
          key: 'cardSetting',
          enable: checkPermission('pers:params:edit', loadMenus),
          title: t('cardSetting'),
          content: <CardSetting onChangeFunction={onChangeValue} values={parametersValue} />,
        },
        {
          key: 'pendingPersonnelSetting',
          enable: checkPermission('pers:params:edit', loadMenus),
          title: t('pendingPersonnelSetting'),
          content: (
            <PendingPersonnelSetting onChangeFunction={onChangeValue} values={parametersValue} />
          ),
        },
        {
          key: 'selfServiceRegistration',
          enable: checkPermission('pers:params:edit', loadMenus),
          title: t('selfServiceRegistration'),
          content: (
            <SelfServiceRegistration onChangeFunction={onChangeValue} values={parametersValue} />
          ),
        },
        {
          key: 'personnelSensitiveInformationProtection',
          enable: checkPermission('pers:params:edit', loadMenus),
          title: t('personnelSensitiveInformationProtection'),
          content: (
            <PersonnelSensitiveInformationProtection
              onChangeFunction={onChangeValue}
              values={parametersValue}
            />
          ),
        },
        {
          key: 'registrationClient',
          enable: checkPermission('pers:params:edit', loadMenus),
          title: t('registrationClient'),
          content: <RegistrationClient onChangeFunction={onChangeValue} values={parametersValue} />,
        },
      ]);
    }
  }, [parametersValue]);

  useEffect(() => {
    if (Object.keys(parameters).length === 0 && sortTime) {
      fetchParameterData();
      sortTime = false;
    }
  }, []);

  return (
    <div className="parameters">
      <TopHeader
        title={t('parameters')}
        broadCram={[
          {
            title: t('personnel'),
          },
          {
            title: t('parameters'),
          },
        ]}
      />
      <CardBox title={t('parameters')}>
        <TabPages tabs={tabs} defaultNumber={0} />

        <div className="parameters-buttons">
          <div className="form-buttons-right">
            <Button
              onClickFunction={submit}
              title={t('done')}
              disabled={disable}
              className="btn-primary btn-bold"
            />
          </div>
        </div>
      </CardBox>
    </div>
  );
}

export default Parameters;
