/* eslint-disable no-param-reassign */
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { setNotificationError } from '../NotificationSlices';
import emailManagement from '../../../utils/api/SystemManagement/emailManagement';
import { changeDateTimeFormat } from '../../../utils/helpers';

export const fetchEmailManagement = createAsyncThunk(
  'acc/fetchEmailManagement',
  async (data: any, { dispatch }) => {
    const response = await emailManagement
      .getListPage(data)
      .then((response1) => {
        return {
          ...response1.data,
          data: {
            list: response1.data.data.data.map((item: any) => ({
              ...item,
              commitTime: changeDateTimeFormat(item.commitTime),
            })),
            size: response1.data.data.total,
            ...data,
          },
        };
      })
      .catch((error) => {
        dispatch(
          setNotificationError({
            error: error.message,
            status: error.response?.status || 500,
          }),
        );
        return error;
      });
    return response;
  },
);

export const fetchMailParam = createAsyncThunk('acc/fetchMailParam', async (_, { dispatch }) => {
  const response = await emailManagement
    .getMailParam()
    .then((response1) => {
      const params = response1?.data?.data?.baseMailParams;
      const mailParams = {
        'base.mailFromAddress': params?.['base.mailFromAddress'] || '',
        'base.mailTLSSelect':
          params?.['base.mailTLSSelect'] !== '0' ? [params?.['base.mailTLSSelect']] : [],
        'base.mailSSLSelect':
          params?.['base.mailSSLSelect'] !== '0' ? [params?.['base.mailSSLSelect']] : [],
        'base.mailSTARTTLSSelect':
          params?.['base.mailSTARTTLSSelect'] !== '0' ? [params?.['base.mailSTARTTLSSelect']] : [],
        'base.mailServerHost': params?.['base.mailServerHost'] || '',
        'base.mailServerPort': params?.['base.mailServerPort'] || '',
        'base.mailPassword': response1?.data?.data?.['base.mailPassword'] || '',
        'base.mailUserName': params?.['base.mailUserName'] || '',
        'base.mailShowMailName': params?.['base.mailShowMailName'] || '',
      };
      return mailParams;
    })
    .catch((error) => {
      dispatch(
        setNotificationError({
          error: error.message,
          status: error.response?.status || 500,
        }),
      );
      return error;
    });
  return response;
});

interface ReduxInterface {
  emailList: any;
  status: 'loading' | 'success' | 'failed'; // Status can be one of these three strings
  totalDataCount: number;
  currentPageNo: number;
  searchFilter: { [key: string]: any };
  emailParams: any;
}

const initialState: ReduxInterface = {
  emailList: [],
  status: 'success',
  totalDataCount: 0,
  currentPageNo: 0,
  searchFilter: {},
  emailParams: {},
};

export const emailManagementSlice: any = createSlice({
  name: 'emailManagement',
  initialState,
  reducers: {
    setSearchFilter: (state, action: any) => {
      state.searchFilter = action.payload;
    },
    deleteLinkage: (state, action: any) => {
      state.emailList = state.emailList.filter((val: any) => val.id !== action.payload);
      state.totalDataCount -= 1;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchEmailManagement.pending, (state = initialState) => {
        state.status = 'loading';
      })
      .addCase(fetchEmailManagement.fulfilled, (state: any, action: any) => {
        if (action.payload.code === 0) {
          state.totalDataCount = action.payload.data.size;
          state.emailList = action.payload.data.list;
        }
        state.status = 'success';
      })

      .addCase(fetchEmailManagement.rejected, (state: any) => {
        state.status = 'failed';
      });
    builder.addCase(fetchMailParam.fulfilled, (state: any, action: any) => {
      state.emailParams = action?.payload;
    });
  },
});

// Action creators are generated for each case reducer function
export const { setSearchFilter, deleteLinkage } = emailManagementSlice.actions;

export default emailManagementSlice.reducer;
