import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
// Import shared components
import { useAppDispatch, useAppSelector } from '../../../../redux/store';
import TopHeader from '../../../../shared-components/top-header/top-header';
import CardBox from '../../../../shared-components/card/card';
import DataTable from '../../../../shared-components/data-table/data-table';
import ModalBox from '../../../../shared-components/modal-box/modal-box';
import CardRightButtonGroup from '../../../../shared-components/card-right-button-group/card-right-button-group';
import {
  CommunicationDeviceIcon,
  CheckCircleIcon,
  CloseCircleIcon,
} from '../../../../assets/svgicons/svgicon';
import {
  fetchCommunicationDevice,
  setSearchFilter,
} from '../../../../redux/slices/system-management/CommunicationDeviceSlices';
import ViewAuthorizedDevice from './components/view-authorized-device';
import { checkPermission } from '../../../../utils/role-permission';

function CommunicationDevice() {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { CommunicationsDevice, communicationStatus, communicationDataCount, searchFilter } =
    useAppSelector((state: any) => state.communication);
  const [modalOpen, setModalOpen] = useState(false);
  const [modalView, setModalView] = useState({
    title: '',
    content: <>hi</>,
  });
  const { loadMenus } = useAppSelector((state) => state.auth);
  const hasViewAuthorizedDevicePermission =
    checkPermission('adms:device:authSearch', loadMenus) || false;

  const [selectedValues, setSelectedValues] = useState<string[]>([]);
  const columnsField = [
    {
      accessorKey: 'module',
      header: t('module'),
    },
    {
      accessorKey: 'sn',
      header: t('deviceSerialNumber'),
    },
    {
      accessorKey: 'fwVersion',
      header: t('deviceFirmware'),
    },
    {
      accessorKey: 'deviceName',
      header: t('deviceName'),
    },
    {
      accessorKey: 'ipAddress',
      header: t('deviceIPAddress'),
    },
    {
      accessorKey: 'subnetMask',
      header: t('subnetMask'),
    },
    {
      accessorKey: 'gateway',
      header: t('gateway'),
    },
    {
      accessorKey: 'enabled',
      header: t('enable'),
      visibleInShowHideMenu: false,
      Cell: ({ row }: any) => {
        const value = row.original?.enabled ? (
          <CheckCircleIcon color="#48d726de" />
        ) : (
          <CloseCircleIcon color="#f00" />
        );
        return value;
      },
    },
    {
      accessorKey: 'status',
      header: t('status'),
      size: 150,
      Cell: ({ row }: any) => {
        let result;
        if (row?.original?.status === '1') {
          result = <span style={{ color: '#00A76F' }}>{t('online')}</span>;
        } else if (row?.original?.status === '0') {
          result = <span style={{ color: '#E8212E' }}>{t('offline')}</span>;
        } else {
          result = <span style={{ color: '#E8212E' }}>{t('disabled')}</span>;
        }
        return result;
      },
    },
    {
      accessorKey: 'cmdCount',
      header: t('executoryCommandCount'),
    },
  ];
  const fetchData = async (params: any) => {
    if (communicationStatus === 'success') {
      dispatch(fetchCommunicationDevice({ ...params }));
    }
  };

  const getFilterData = (filter: any): any => {
    if (filter.pageIndex > 0) {
      dispatch(setSearchFilter(filter));
      fetchData({
        pageNo: filter.pageIndex,
        pageSize: filter.pageSize,
        ...filter.filter,
      });
    }
  };
  const handleExport = () => {
    setModalOpen(true);
    setModalView({
      title: t('View Authorized Device'),
      content: <ViewAuthorizedDevice />,
    });
  };
  return (
    <div className="department">
      <ModalBox
        status={modalOpen}
        closeModal={() => setModalOpen(false)}
        title={modalView.title}
        content={modalView.content}
      />
      <TopHeader
        title={t('system')}
        broadCram={[
          {
            title: t('communicationManagement'),
          },
          {
            title: t('communicationDevice'),
          },
        ]}
      />
      <CardBox title={t('listOfDevice')} rightSide={<CardRightButtonGroup buttonStatus={false} />}>
        <div className="device-wrapper">
          {hasViewAuthorizedDevicePermission && (
            <div className="communication-device" role="presentation" onClick={handleExport}>
              <CommunicationDeviceIcon />
              <span className="label">{t('viewAuthorizedDevice')}</span>
            </div>
          )}
        </div>
        <DataTable
          actionButtonStatus={false}
          columns={columnsField}
          getFilterData={getFilterData}
          assignFilterValue={searchFilter}
          assignSelectedData={selectedValues}
          getSelectedData={(val: any): any => setSelectedValues(val)}
          checkBoxActive
          enablePagination
          isFetching={communicationStatus === 'loading'}
          values={CommunicationsDevice}
          fixedColumn={['module']}
          totalRecords={communicationDataCount}
          height={411}
        />
      </CardBox>
    </div>
  );
}

export default CommunicationDevice;
