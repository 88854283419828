import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from '../../../redux/store';

// Use shared components
import TopHeader from '../../../shared-components/top-header/top-header';
import CardBox from '../../../shared-components/card/card';
import DataTable from '../../../shared-components/data-table/data-table';
import CardRightButtonGroup from '../../../shared-components/card-right-button-group/card-right-button-group';
import ModalBox from '../../../shared-components/modal-box/modal-box';
// Import slice actions
import {
  fetchAlarmMonitoryHistoryList,
  setSearchFilter,
} from '../../../redux/slices/AlarmProcessingHistorySlices';
import { DepartmentParamsProps } from '../../../interface/accessByDepartment.interface';
import { changeDateTimeFormat } from '../../../utils/helpers';
import ExportData from './export-data';
import { checkPermission } from '../../../utils/role-permission';

function AlarmProcessingHistory() {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { historyList, status, totalDataCount, searchFilter } = useAppSelector(
    (state: any) => state.alarmProcessingHistory,
  );

  /**
   * Selects the `loadMenus` permission from the authentication slice of the Redux store.
   *
   * @returns {Object} loadMenus - The user's menu permissions.
   */
  const { loadMenus } = useAppSelector((state) => state.auth);

  const [modalOpen, setModalOpen] = useState(false);
  const [modalView, setModalView] = useState({
    title: 'hi',
    content: <>hi</>,
  });
  const columns = [
    {
      accessorKey: 'createTime',
      header: t('creationTime'),
      size: 180,
      Cell: ({ row }: any) => <div>{changeDateTimeFormat(row.original.createTime)}</div>,
    },
    {
      accessorKey: 'deviceName',
      header: t('deviceName'),
      size: 200,
    },
    {
      accessorKey: 'eventPoint',
      header: t('eventPoint'),
      size: 150,
    },
    {
      accessorKey: 'eventName',
      header: t('eventDescription'),
      size: 220,
    },
    {
      accessorKey: 'statusStr',
      header: t('status'),
      size: 140,
    },
    {
      accessorKey: 'operator',
      header: t('operator'),
      size: 140,
    },
    {
      accessorKey: 'records',
      header: t('processingRecords'),
      size: 140,
    },
    {
      accessorKey: 'eventNum',
      header: t('eventNumber'),
      size: 140,
    },
  ];

  const fetchData = async (params: DepartmentParamsProps) => {
    if (status === 'success') {
      dispatch(fetchAlarmMonitoryHistoryList({ ...params }));
    }
  };

  const getFilterData = (filter: any): any => {
    if (filter.pageIndex > 0) {
      dispatch(setSearchFilter(filter));
      fetchData({ pageNo: filter.pageIndex, pageSize: filter.pageSize, ...filter.filter });
    }
  };

  const exportData = () => {
    setModalOpen(false);
  };

  const moreActionButton = [
    {
      title: t('export'),
      enable: checkPermission('acc:alarmHistory:export', loadMenus),
      clickFunction: (): void => {
        setModalView({
          title: t('export'),
          content: (
            <ExportData closeModal={() => setModalOpen(false)} submitFunction={exportData} />
          ),
        });
        setModalOpen(true);
      },
    },
  ];

  return (
    <div className="card-management">
      <ModalBox
        status={modalOpen}
        closeModal={() => setModalOpen(false)}
        title={modalView.title}
        content={modalView.content}
      />
      <TopHeader
        title={t('access')}
        broadCram={[
          {
            title: t('accControlReports'),
          },
          {
            title: t('alarmProcessingHistory'),
          },
        ]}
      />
      <CardBox
        header=""
        title={t('listOfAlarm')}
        rightSide={
          <CardRightButtonGroup
            actions={moreActionButton}
            buttonIcon={false}
            buttonLabel=""
            buttonClass=""
            buttonStatus={false}
          />
        }>
        <DataTable
          actionButtonStatus={false}
          columns={columns}
          enablePagination
          getFilterData={getFilterData}
          assignFilterValue={searchFilter}
          checkBoxActive={false}
          isFetching={status === 'loading'}
          values={historyList}
          fixedColumn={['eventTime']}
          totalRecords={totalDataCount}
          height={411}
        />
      </CardBox>
    </div>
  );
}

export default AlarmProcessingHistory;
