import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useAppDispatch } from '../../../../../redux/store';
import { setNotificationError } from '../../../../../redux/slices/NotificationSlices';
import InputField from '../../../../../shared-components/input-field/input-field';
import FormRightButton from '../../../../../shared-components/form-right-buttons/FormRightButton';
import { fetchApiAuthorization } from '../../../../../redux/slices/system-management/APIAuthorization';
import apiAuthorization from '../../../../../utils/api/SystemManagement/apiAuthorization';

interface AddClientProp {
  id: string;
  clientName: string;
  clientToken: string;
  name: string;
}

interface InterlockGroupType {
  closeModal: () => void;
  data?: AddClientProp;
}

function AddClient({ closeModal, data }: InterlockGroupType) {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const [value, setValue] = useState<AddClientProp>({
    id: data?.id || '',
    name: data?.name || '',
    clientName: data?.clientName || '',
    clientToken: data?.clientToken || '',
  });
  const [checkExistName, setCheckExistName] = useState(true);
  const [disabled, setDisabled] = useState(false);

  const onChangeValue = async (name: string, values: any) => {
    setValue((prev: AddClientProp) => ({
      ...prev,
      [name]: values,
    }));
  };

  const onBlur = async (clientName: string, values: any) => {
    if (clientName === 'clientName' && values !== '' && values !== data?.clientName) {
      try {
        const responseData = await apiAuthorization.isExistName(values);
        setCheckExistName(responseData?.data);

        if (responseData?.data) {
          const apiTokenResponse = await apiAuthorization.generateApiToken(values);
          const apiToken = apiTokenResponse.data.data || apiTokenResponse.data;
          setValue((prev: AddClientProp) => ({
            ...prev,
            clientToken: apiToken,
          }));
        }
      } catch (error: any) {
        dispatch(
          setNotificationError({
            error: error.message || t('anErrorOccurred'),
            status: error.status || 500,
          }),
        );
      }
    }
  };

  const submit = () => {
    const request = {
      id: value.id,
      clientName: value.clientName,
      clientToken: value.clientToken,
    };
    apiAuthorization
      .saveList(request)
      .then((response) => {
        if (response?.data) {
          const message = data
            ? t('apiAuthorizationUpdatedSuccessfully')
            : t('apiAuthorizationAddedSuccessfully');
          dispatch(
            setNotificationError({
              error: message,
              status: 200,
            }),
          );
          setDisabled(false);
          dispatch(fetchApiAuthorization({ pageNo: 1 }));
          closeModal();
        } else {
          dispatch(
            setNotificationError({
              error: response.data.msg,
              status: response.data.code,
            }),
          );
        }
      })
      .catch((error) => {
        dispatch(
          setNotificationError({
            error: error.message,
            status: error.status,
          }),
        );
      });
  };

  useEffect(() => {
    if (value?.clientName && checkExistName) {
      setDisabled(false);
    } else {
      setDisabled(true);
    }
  }, [value, checkExistName]);

  return (
    <div className="add-client">
      <div className="client-filed">
        <InputField
          id="clientName"
          name="clientName"
          label="Client Name"
          type="text"
          onBlurFunction={onBlur}
          value={value.clientName}
          onChangeFunction={onChangeValue}
        />
      </div>
      <div className="client-filed">
        <InputField
          id="clientToken"
          name="clientToken"
          label="Client Token"
          type="text"
          value={value.clientToken}
          onChangeFunction={onChangeValue}
          disabled
        />
      </div>
      <FormRightButton
        cancelLabel={t('cancel')}
        cancelFunction={() => closeModal()}
        submitLabel={t('done')}
        submitFunction={submit}
        disabled={disabled}
      />
    </div>
  );
}

AddClient.defaultProps = {
  data: {
    CertificatesName: '',
  },
};

export default AddClient;
