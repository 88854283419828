import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from '../../../../redux/store';

// Shared components
import TopHeader from '../../../../shared-components/top-header/top-header';
import CardBox from '../../../../shared-components/card/card';
import DataTable from '../../../../shared-components/data-table/data-table';
import CardRightButtonGroup from '../../../../shared-components/card-right-button-group/card-right-button-group';
import ModalBox from '../../../../shared-components/modal-box/modal-box';
import DeleteModalBox from '../../../../shared-components/delete-modal-box/delete-modal-box';
import { setNotificationError } from '../../../../redux/slices/NotificationSlices';
import { AddIcon } from '../../../../assets/svgicons/svgicon';

// Components
import AddVideoChannel from './components/AddVideoChannel';

function VideoChannel() {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { apiAuthorizationList, status, totalDataCount, searchFilter } = useAppSelector(
    (state: any) => state.apiAuthorization,
  );

  const [modalOpen, setModalOpen] = useState(false);
  const [modalView, setModalView] = useState({
    title: '',
    content: <>hi</>,
  });
  const [selectedValues, setSelectedValues] = useState<any>([]);
  let handleEditOpen: any;

  const columns = [
    {
      accessorKey: 'name',
      header: t('name'),
      Cell: ({ row }: any) => (
        <span
          className="list-hyperlink"
          role="presentation"
          onClick={(event) => {
            event.stopPropagation();
            handleEditOpen(row.original);
          }}>
          {row?.original?.name}
        </span>
      ),
      size: 150,
    },
    { accessorKey: 'iD', header: t('iD') },
    { accessorKey: 'icon', header: t('icon') },
  ];

  const deleteRecord = () => {
    if (selectedValues.length === 0) {
      dispatch(
        setNotificationError({
          error: t('pleaseSelectAtLeastOneVideo'),
          status: 400,
        }),
      );
    }

    // apiAuthorization
    //   .delete(id)
    //   .then((response) => {
    //     if (response.data.success) {
    //       dispatch(
    //         setNotificationError({
    //           error: t('apiAuthorizationDeletedSuccessfully'),
    //           status: 200,
    //         }),
    //       );
    //       setModalOpen(false);
    //     } else {
    //       dispatch(
    //         setNotificationError({
    //           error: t('deleteFailed'),
    //           status: 400,
    //         }),
    //       );
    //     }
    //   })
    //   .catch((error) => {
    //     console.error('Delete API error:', error);
    //     dispatch(
    //       setNotificationError({
    //         error: error.message,
    //         status: error.response?.status || 500,
    //       }),
    //     );
    //   });
  };

  const actionButtons = [
    {
      label: t('edit'),
      function: (val: any) => handleEditOpen(val),
    },
    {
      label: t('delete'),
      disabled: true,
      function: (val: any) => {
        setModalOpen(true);
        setModalView({
          title: t('deleteResource'),
          content: (
            <DeleteModalBox
              closeModal={() => setModalOpen(false)}
              id={val.id}
              okFunction={deleteRecord}
            />
          ),
        });
      },
    },
  ];

  handleEditOpen = (editData: any) => {
    if (!modalOpen) {
      setModalOpen(true);
      setModalView({
        title: t('edit'),
        content: <AddVideoChannel closeModal={() => setModalOpen(false)} data={editData} />,
      });
    }
  };
  const handleDeleteClick = () => {
    if (selectedValues.length === 0) {
      dispatch(
        setNotificationError({
          error: t('pleaseSelectAtLeastOneVideo'),
          status: 400,
        }),
      );
      return;
    }

    setModalView({
      title: t('delete'),
      content: (
        <DeleteModalBox
          closeModal={() => setModalOpen(false)}
          id={selectedValues}
          okFunction={deleteRecord}
        />
      ),
    });
    setModalOpen(true);
  };

  return (
    <div className="video-channel">
      <ModalBox
        status={modalOpen}
        closeModal={() => setModalOpen(false)}
        title={modalView.title}
        content={modalView.content}
      />
      <TopHeader
        title={t('system')}
        broadCram={[{ title: t('thirdPartyIntegration') }, { title: t('videoChannel') }]}
      />
      <CardBox
        header=""
        title={t('listOfVideoChannel')}
        rightSide={
          <div className="delete-icon">
            {/* Standalone Delete button with fixed click handler */}
            <button type="button" className="delete-button" onClick={handleDeleteClick}>
              {t('delete')}
            </button>

            {/* New button */}
            <CardRightButtonGroup
              buttonIcon={<AddIcon color="#ffffff" />}
              buttonLabel={t('new')}
              buttonClass="btn-primary btn-sm"
              buttonStatus
              buttonFunction={(): void => {
                setModalOpen(true);
                setModalView({
                  title: t('new'),
                  content: <AddVideoChannel closeModal={() => setModalOpen(false)} />,
                });
              }}
              buttonEnable
            />
          </div>
        }>
        <DataTable
          actionButtonStatus={!modalOpen}
          action={actionButtons}
          columns={columns}
          assignFilterValue={searchFilter}
          assignSelectedData={selectedValues}
          getSelectedData={(val: any): any => {
            setSelectedValues(val);
          }}
          checkBoxActive
          enablePagination
          isFetching={status === 'loading'}
          values={apiAuthorizationList}
          fixedColumn={['name']}
          totalRecords={totalDataCount}
          height={411}
        />
      </CardBox>
    </div>
  );
}

export default VideoChannel;
