import React from 'react';

// // Use common modules
import { useNavigate } from 'react-router-dom';
import Cookies from 'universal-cookie';

// // Use Translation
import i18next from 'i18next';
import { useTranslation } from 'react-i18next';

// // Use shared components
import DropDown from '../drop-down/drop-down';

// // Use interface
import { DropDownMenuInterFace } from '../../interface/common.interface';
import LoginApis from '../../utils/api/auth';

// // Use assets svg icons
import { ArabicFlagIcon, EnglishFlagIcon } from '../../assets/svgicons/svgicon';
import { getFirstCapsLetter, removeLocalStorageItem } from '../../utils/helpers';
import Menus from './header-menus';
import { resetAuthState } from '../../redux/slices/AuthSlices';
import { useAppDispatch } from '../../redux/store';

function Header() {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const cookies = new Cookies();
  const currentLanguageCode = cookies.get('i18next') || 'en';
  const actions = [
    {
      icon: <EnglishFlagIcon />,
      label: 'English',
      key: 'en',
      disabled: currentLanguageCode === 'en',
    },
    {
      icon: <ArabicFlagIcon />,
      label: 'عربي',
      key: 'ar',
      disabled: currentLanguageCode === 'ar',
    },
  ];
  const profileAction = [
    {
      label: t('profile'),
      key: 'profile',
    },
    {
      label: t('logout'),
      key: 'logout',
    },
  ];

  const getSelectLanguage = (val: DropDownMenuInterFace) => {
    i18next.changeLanguage(val.key);
    if (val.key === 'ar') {
      document.documentElement.setAttribute('dir', 'rtl');
    } else {
      document.documentElement.setAttribute('dir', 'ltr');
    }
    window.location.reload();
  };

  const getPageKey = (val: DropDownMenuInterFace) => {
    switch (val.key) {
      case 'logout':
        // Call the logout API first
        LoginApis.logout()
          .then(() => {
            // After successful logout, clear cookies and redirect
            cookies.remove('token', { path: '/' });
            cookies.remove('username', { path: '/' });
            cookies.remove('userId', { path: '/' });
            removeLocalStorageItem('isSuperuser');
            removeLocalStorageItem('systemCode');
            dispatch(resetAuthState());
            navigate('/login');
          })
          .catch(() => {
            cookies.remove('token', { path: '/' });
            cookies.remove('username', { path: '/' });
            navigate('/login');
          });

        break;

      default:
        break;
    }
  };

  return (
    <div className="header-container">
      <div className="header-left">
        <div className="header-menus">
          <Menus />
        </div>
      </div>
      <div className="header-right">
        <div className="header-buttons-group">
          <div className="language-button">
            <DropDown
              name={cookies.get('i18next') === 'en' ? <EnglishFlagIcon /> : <ArabicFlagIcon />}
              action={actions}
              clickFunction={(val: DropDownMenuInterFace) => getSelectLanguage(val)}
            />
          </div>
          <div className="profile-button">
            <DropDown
              name={
                <div className="profile-icon">{getFirstCapsLetter(cookies.get('username'))}</div>
              }
              action={profileAction}
              clickFunction={(val: DropDownMenuInterFace) => getPageKey(val)}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default Header;
