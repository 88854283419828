import { get, post } from '../base/index';

export default {
  getListPage: (data: any = {}) => {
    return post(`${process.env.REACT_APP_API_URL}Auth/api/authUser/listByPage`, {}, { ...data });
  },

  getList: () => {
    return get(`${process.env.REACT_APP_API_URL}Auth/api/authRole/tree`, {});
  },

  getDepList: (data: any = {}) => {
    return post(
      `${process.env.REACT_APP_API_URL}Auth/api/department/getDeptList`,
      {},
      { ...data, pageSize: 10 },
    );
  },

  isExistName: (data: any) =>
    post(`${process.env.REACT_APP_API_URL}Auth/api/authUser/valid`, {}, data),

  saveUser: (data: any = {}) => {
    return post(`${process.env.REACT_APP_API_URL}Auth/api/authUser/save`, data, {});
  },

  delete: (data: any = {}) => {
    return post(`${process.env.REACT_APP_API_URL}Auth/api/authUser/delete`, {}, data);
  },

  edit: (data: any = {}) => {
    return post(`${process.env.REACT_APP_API_URL}Auth/api/authUser/edit`, {}, data);
  },
};
