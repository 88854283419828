import React, { Suspense, useEffect } from 'react';

// Use common modules
import { Navigate, Route, Routes } from 'react-router-dom';
import Cookies from 'universal-cookie';

// Use Redux modules
import { useAppDispatch, useAppSelector } from '../../redux/store';
import { fetchLoadMenus } from '../../redux/slices/AuthSlices';

// helper functions
import {
  capitalizeFirstLetter,
  extractPermissions,
  getLocalStorageItem,
} from '../../utils/helpers';
import { filterRoutes } from '../../utils/role-permission';

// interfaces
import { RouteInterface } from '../../interface/common.interface';

// Use Pages
import Layout from '../layout';
import Login from '../login';
import ForgotPassword from '../forgotpassword';
import Department from '../department';
import Dashboard from '../dashboard';
import Position from '../position';
import DismissedPersonnel from '../dismissed-personnel';
import PendingReview from '../pending-review';
import CustomAttributes from '../custom-attributes';
import ListLibrary from '../list-library';
import Parameters from '../parameters';
import CardManagement from '../card-management';
import WiegandFormat from '../wiegand-format';
import IssueCardRecord from '../issue-card-record';
import Person from '../person';
import AddPerson from '../person/components/add-person';
import AuthGuard from './auth-guard';
import EditPerson from '../person/components/edit-person';
import Device from '../devices';
import IOBoard from '../ioboard';
import Reader from '../reader';
import Door from '../door';
import AuxiliaryInput from '../auxiliary-input';
import AuxiliaryOutput from '../auxiliary-output';
import RealTimeMonitoring from '../realTime-monitoring';
import DayLightSavingTime from '../daylight-saving-time';
import EvenType from '../event-type';
import AlarmMonitoring from '../alarm-monitoring';
import AccessLevels from '../access-levels';
import Map from '../map';
// import Holidays from '../holidays';
import SetAccessByPerson from '../set-access-by-person';
import SetAccessByLevels from '../access-by-levels';
import TimeZones from '../timeZones';
import LinkageOutputPoint from '../linkage';
import Interlock from '../interlock';
import SetAccessByDepartment from '../access-by-department';
import AntiPassBack from '../anti-passback';
import FirstPersonNormallyOpen from '../first-person-normally-open';
import MultiPersonGroup from '../multi-person-group';
import AccRuleParameters from '../acc-rule-parameters';
import MultiPersonOpeningDoor from '../multi-person-openning-door';
import VerificationModeGroup from '../verification-mode-group';
import VerificationMode from '../verification-mode';
import ReaderDefine from '../advanced-functions/reader-define';
import AreaHeadCount from '../advanced-functions/areaHeadCount';
import GlobalAnitPassback from '../advanced-functions/globalAntiPassback';
import ZoneList from '../advanced-functions/zone';
import GlobalInterlock from '../advanced-functions/globalInterlock';
import OccupancyControl from '../advanced-functions/occupancyControl';
import GlobalLinkageOutputPoint from '../advanced-functions/globalLinkage';
import GlobalInterlockGroup from '../advanced-functions/globalInterlockGroup';
import PersonAvailability from '../advanced-functions/personAvailability';
import MusterPointReport from '../advanced-functions/musterPointReport';
import MusterPoint from '../advanced-functions/musterPoint';
import AllTransactions from '../accessControlReports/allTransactions';
import EventsFromToday from '../accessControlReports/eventsFromToday';
import AllExceptionEvents from '../accessControlReports/allExceptionEvents';
import AlarmLog from '../accessControlReports/alarmLog';
import AlarmProcessingHistory from '../accessControlReports/alarmProcessingHistory';
import FirstInLastOut from '../accessControlReports/firstInLastOut';
import AccessRightsByPersonnel from '../accessControlReports/accessRightsByPersonnel';
import AccessRightsByDoor from '../accessControlReports/accessRightsByDoor';
import OperationLog from '../system/system-management/operation-log';
import DatabaseManagement from '../system/system-management/database-management';
import SystemParameter from '../system/system-management/system-Parameter';
import EmailManagement from '../system/system-management/email-management';
// import ResourceFile from '../system/system-management/resource-file';
// import CloudSettings from '../system/system-management/cloud-settings';
import DictionaryManagement from '../system/system-management/directory-management';
import CertificateType from '../system/system-management/certificate-type';
import AreaSettings from '../system/system-management/area-settings';
import DataCleaning from '../system/system-management/data-cleaning';
import APIAuthorization from '../system/authority-management/api-authorization';
import Role from '../system/authority-management/role';
import User from '../system/authority-management/user';
import SystemManagementParameters from '../system/system-management/system-management-parameters';
import DeviceCommand from '../system/communication-management/device-command';
import CommunicationMonitor from '../system/communication-management/communication-monitor';
import CommunicationDevice from '../system/communication-management/communication-device';
import AuthorizedDevice from '../system/communication-management/authorized-device';
import VideoChannel from '../system/third-party-integration/video-channel';
import SystemMonitoring from '../system/system-management/system-monitoring';
import ClientRegister from '../system/authority-management/client-register';
import SecurityParameters from '../system/authority-management/security-parameters';
import ADManagement from '../system/third-party-integration/ad-management';
import PageLoader from '../../shared-components/page-loader/page-loader';
import PermissionDenied from '../../shared-components/permission-denied/permission-denied';

function Router() {
  const dispatch = useAppDispatch();
  const routes = [
    {
      path: '',
      children: [
        {
          id: 1,
          path: '',
          element: <Navigate to="dashboard" />,
        },
      ],
    },
    {
      path: 'dashboard',
      children: [
        {
          id: 1,
          path: '',
          element: <Dashboard />,
        },
      ],
    },
    {
      path: 'pers',
      permissionKey: 'Personnel',
      children: [
        {
          id: 1,
          path: '',
          permissionKey: 'Person',
          element: <Navigate to="pers/person" />,
        },
        {
          id: 2,
          permissionKey: 'Person',
          path: 'personnel/person',
          element: <Person />,
        },
        {
          id: 3,
          permissionKey: 'Person',
          path: 'personnel/person/add',
          element: <AddPerson />,
        },
        {
          id: 4,
          permissionKey: 'Person',
          path: 'personnel/person/edit/:id',
          element: <EditPerson />,
        },
        {
          id: 5,
          permissionKey: 'Department',
          path: 'personnel/department',
          element: <Department />,
        },
        {
          id: 6,
          permissionKey: 'Position',
          path: 'personnel/position',
          element: <Position />,
        },
        {
          id: 7,
          permissionKey: 'Dismissed Personnel',
          path: 'personnel/dismissed-personnel',
          element: <DismissedPersonnel />,
        },
        {
          id: 8,
          permissionKey: 'Pending Review',
          path: 'personnel/pending-review',
          element: <PendingReview />,
        },
        {
          id: 9,
          permissionKey: 'Custom Attributes',
          path: 'personnel/custom-attributes',
          element: <CustomAttributes />,
        },
        {
          id: 10,
          permissionKey: 'List Library',
          path: 'personnel/list-library',
          element: <ListLibrary />,
        },
        {
          id: 11,
          permissionKey: 'Parameters',
          path: 'personnel/parameters',
          element: <Parameters />,
        },
        {
          id: 12,
          permissionKey: 'Card',
          path: 'card-management/card',
          element: <CardManagement />,
        },
        {
          id: 13,
          permissionKey: 'Wiegand Format',
          path: 'card-management/wiegand-format',
          element: <WiegandFormat />,
        },
        {
          id: 14,
          permissionKey: 'Issued Card Record',
          path: 'card-management/issued-card-record',
          element: <IssueCardRecord />,
        },
      ],
    },
    {
      path: 'acc',
      permissionKey: 'Access Device',
      children: [
        {
          id: 1,
          path: '',
          permissionKey: 'Access Device',
          element: <Navigate to="acc/device" />,
        },
        {
          id: 2,
          permissionKey: 'Device',
          path: 'accessDevice/device',
          element: <Device />,
        },
        {
          id: 3,
          permissionKey: 'I/O Board',
          path: 'accessDevice/iOBoard',
          element: <IOBoard />,
        },
        {
          id: 4,
          permissionKey: 'Door',
          path: 'accessDevice/door',
          element: <Door />,
        },
        {
          id: 5,
          permissionKey: 'Reader',
          path: 'accessDevice/reader',
          element: <Reader />,
        },
        {
          id: 6,
          permissionKey: 'Auxiliary Input',
          path: 'accessDevice/auxiliaryinput',
          element: <AuxiliaryInput />,
        },
        {
          id: 7,
          permissionKey: 'Auxiliary Output',
          path: 'accessDevice/auxiliaryoutput',
          element: <AuxiliaryOutput />,
        },
        {
          id: 8,
          permissionKey: 'Event Type',
          path: 'accessDevice/eventType',
          element: <EvenType />,
        },
        {
          id: 9,
          permissionKey: 'Daylight Saving Time',
          path: 'accessDevice/daylightsavingtime',
          element: <DayLightSavingTime />,
        },
        {
          id: 10,
          permissionKey: 'Real-Time Monitoring',
          path: 'accessDevice/realtime-monitoring',
          element: <RealTimeMonitoring />,
        },
        {
          id: 11,
          permissionKey: 'Alarm Monitoring',
          path: 'accessDevice/alarm-monitoring',
          element: <AlarmMonitoring />,
        },
        {
          id: 12,
          permissionKey: 'Map',
          path: 'accessDevice/map',
          element: <Map />,
        },
        {
          id: 13,
          permissionKey: 'Time Zones',
          path: 'accessRule/timezones',
          element: <TimeZones />,
        },
        // {
        //   id: 14,
        //   permissionKey: 'Holidays',
        //   path: 'accessRule/holidays',
        //   element: <Holidays />,
        // },
        {
          id: 15,
          permissionKey: 'Access Levels',
          path: 'accessRule/accessLevels',
          element: <AccessLevels />,
        },
        {
          id: 16,
          permissionKey: 'Set Access By Levels',
          path: 'accessRule/setaccessByLevels',
          element: <SetAccessByLevels />,
        },
        {
          id: 17,
          permissionKey: 'Set Access By Person',
          path: 'accessRule/setAccessByPerson',
          element: <SetAccessByPerson />,
        },
        {
          id: 18,
          permissionKey: 'Set Access By Department',
          path: 'accessRule/setAccessByDepartment',
          element: <SetAccessByDepartment />,
        },
        {
          id: 19,
          permissionKey: 'Interlock',
          path: 'accessRule/interlock',
          element: <Interlock />,
        },
        {
          id: 20,
          permissionKey: 'Linkage',
          path: 'accessRule/linkage',
          element: <LinkageOutputPoint />,
        },
        {
          id: 21,
          permissionKey: 'Anti-Passback',
          path: 'accessRule/antiPassback',
          element: <AntiPassBack />,
        },
        {
          id: 22,
          permissionKey: 'First-Person Normally Open',
          path: 'accessRule/firstPersonNormallyOpen',
          element: <FirstPersonNormallyOpen />,
        },
        {
          id: 23,
          permissionKey: 'Multi-Person Group',
          path: 'accessRule/multi-person-group',
          element: <MultiPersonGroup />,
        },
        {
          id: 24,
          permissionKey: 'Multi-Person Opening Door',
          path: 'accessRule/multi-person-opening-door',
          element: <MultiPersonOpeningDoor />,
        },
        {
          id: 25,
          permissionKey: 'Verification Mode',
          path: 'accessRule/verification-mode',
          element: <VerificationMode />,
        },
        {
          id: 26,
          permissionKey: 'Verification Mode Group',
          path: 'accessRule/verification-mode-group',
          element: <VerificationModeGroup />,
        },
        {
          id: 27,
          permissionKey: 'Parameters',
          path: 'accessRule/parameters',
          element: <AccRuleParameters />,
        },
        {
          id: 28,
          permissionKey: 'Zone',
          path: 'advancedFunctions/zone',
          element: <ZoneList />,
        },
        {
          id: 29,
          permissionKey: 'Reader Define',
          path: 'advancedFunctions/reader-define',
          element: <ReaderDefine />,
        },
        {
          id: 30,
          permissionKey: 'Who Is Inside',
          path: 'advancedFunctions/areaHeadCount',
          element: <AreaHeadCount />,
        },
        {
          id: 31,
          permissionKey: 'Global Anti-Passback',
          path: 'advancedFunctions/globalAntiPassback',
          element: <GlobalAnitPassback />,
        },
        {
          id: 32,
          permissionKey: 'Global Linkage',
          path: 'advancedFunctions/globalLinkage',
          element: <GlobalLinkageOutputPoint />,
        },
        {
          id: 33,
          permissionKey: 'Global Interlock Group',
          path: 'advancedFunctions/globalInterlockGroup',
          element: <GlobalInterlockGroup />,
        },
        {
          id: 34,
          permissionKey: 'Global Interlock',
          path: 'advancedFunctions/globalInterlock',
          element: <GlobalInterlock />,
        },
        {
          id: 35,
          permissionKey: 'Person Availability',
          path: 'advancedFunctions/person-Availability',
          element: <PersonAvailability />,
        },
        {
          id: 36,
          permissionKey: 'Occupancy Control',
          path: 'advancedFunctions/occupancyControl',
          element: <OccupancyControl />,
        },
        {
          id: 37,
          permissionKey: 'Muster Point',
          path: 'advancedFunctions/musterPoint',
          element: <MusterPoint />,
        },
        {
          id: 38,
          permissionKey: 'Muster Point Report',
          path: 'advancedFunctions/musterPoint-report',
          element: <MusterPointReport />,
        },
        {
          id: 39,
          permissionKey: 'All Transactions',
          path: 'accControlReports/allTransactions',
          element: <AllTransactions />,
        },
        {
          id: 40,
          permissionKey: 'Events From Today',
          path: 'accControlReports/events-from-today',
          element: <EventsFromToday />,
        },
        {
          id: 41,
          permissionKey: 'All Exception Events',
          path: 'accControlReports/all-exception-events',
          element: <AllExceptionEvents />,
        },
        {
          id: 42,
          permissionKey: 'Alarm Log',
          path: 'accControlReports/alarm-log',
          element: <AlarmLog />,
        },
        {
          id: 43,
          permissionKey: 'Alarm Processing History',
          path: 'accControlReports/alarm-processing-history',
          element: <AlarmProcessingHistory />,
        },
        {
          id: 44,
          permissionKey: 'Access Rights By Door',
          path: 'accControlReports/access-rights-by-door',
          element: <AccessRightsByDoor />,
        },
        {
          id: 45,
          permissionKey: 'Access Rights By Personnel',
          path: 'accControlReports/access-rights-by-personnel',
          element: <AccessRightsByPersonnel />,
        },
        {
          id: 46,
          permissionKey: 'First In And Last Out',
          path: 'accControlReports/firstIn-and-lastOut',
          element: <FirstInLastOut />,
        },
      ],
    },
    {
      path: 'system',
      permissionKey: 'System Management',
      children: [
        {
          id: 1,
          path: '',
          permissionKey: 'System Management',
          element: <Navigate to="system/system-management" />,
        },
        {
          id: 2,
          permissionKey: 'Operation Log',
          path: 'system-management/operation-log',
          element: <OperationLog />,
        },
        {
          id: 3,
          permissionKey: 'Data Management',
          path: 'system-management/database-management',
          element: <DatabaseManagement />,
        },
        {
          id: 4,
          permissionKey: 'Area Settings',
          path: 'system-management/area-settings',
          element: <AreaSettings />,
        },
        {
          id: 5,
          permissionKey: 'Parameters',
          path: 'system-management/system-Parameter',
          element: <SystemParameter />,
        },
        {
          id: 6,
          permissionKey: 'E-mail Management',
          path: 'system-management/email-management',
          element: <EmailManagement />,
        },
        {
          id: 7,
          permissionKey: 'Dictionary Management',
          path: 'system-management/dictionary-management',
          element: <DictionaryManagement />,
        },
        {
          id: 8,
          permissionKey: 'Data Cleaning',
          path: 'system-management/data-cleaning',
          element: <DataCleaning />,
        },
        {
          id: 11,
          permissionKey: 'Certificate Type',
          path: 'system-management/certificate-type',
          element: <CertificateType />,
        },
        {
          id: 14,
          permissionKey: 'System Monitoring',
          path: 'system-management/system-monitoring',
          element: <SystemMonitoring />,
        },
        {
          id: 15,
          permissionKey: 'Parameters',
          path: 'system-management/system-management-parameters',
          element: <SystemManagementParameters />,
        },
        {
          id: 16,
          permissionKey: 'User',
          path: 'authority-management/user',
          element: <User />,
        },
        {
          id: 17,
          permissionKey: 'Role',
          path: 'authority-management/role',
          element: <Role />,
        },
        {
          id: 18,
          permissionKey: 'API Authorization',
          path: 'authority-management/api-authorization',
          element: <APIAuthorization />,
        },
        {
          id: 19,
          permissionKey: 'Client Register',
          path: 'authority-management/client-register',
          element: <ClientRegister />,
        },
        {
          id: 20,
          permissionKey: 'Client Register',
          path: 'authority-management/security-parameters',
          element: <SecurityParameters />,
        },
        {
          id: 21,
          permissionKey: 'Device Command',
          path: 'communication-management/device-command/:sn?',
          element: <DeviceCommand />,
        },
        {
          id: 22,
          permissionKey: 'Communication Device',
          path: 'communication-management/communication-device',
          element: <CommunicationDevice />,
        },
        {
          id: 23,
          permissionKey: 'Authorized device',
          path: 'communication-management/authorized-device',
          element: <AuthorizedDevice />,
        },
        {
          id: 24,
          permissionKey: 'Communication Monitor',
          path: 'communication-management/communication-monitor',
          element: <CommunicationMonitor />,
        },
        {
          id: 26,
          path: 'third-party-integration/video-channel',
          element: <VideoChannel />,
        },
        {
          id: 27,
          path: 'third-party-integration/ad-management',
          element: <ADManagement />,
        },
      ],
    },
  ];

  const { loadMenus, loading } = useAppSelector((state) => state.auth);

  /**
   * Extract and create a Set of valid permissions from the loadMenus data.
   * This Set will be used to filter routes based on user permissions.
   * @type {Set<string>}
   */
  const validPermission: Set<string> = new Set(extractPermissions(loadMenus));

  const currentPath = window.location.pathname;
  const parts = currentPath.split('/');
  const slicedString = parts?.[2];

  /**
   * Filter the routes based on the user's permissions.
   * Only routes that match the user's permissions will be included in the filtered result.
   * @type {Route[]}
   */
  const filteredRoutes: RouteInterface[] = filterRoutes(routes, validPermission);

  /**
   * @systemCode get from local storage
   */
  const getSystemCode = getLocalStorageItem('systemCode');
  const sysCode = capitalizeFirstLetter(slicedString) || getSystemCode;
  const cookies = new Cookies();
  const userId = cookies.get('userId') && cookies.get('userId');

  /**
   * check super user or not
   */
  const isSuperUser = getLocalStorageItem('isSuperuser');

  // Fetch and update the menus when the systemCode changes or user logs in or out.
  useEffect(() => {
    if (sysCode || loadMenus?.length > 0) {
      dispatch(fetchLoadMenus({ systemCode: sysCode, userId, ...isSuperUser }));
    }
  }, [getSystemCode, sysCode, userId]);

  return (
    <Suspense fallback={<PageLoader />}>
      <Routes>
        <Route path="/" element={<Navigate to="/login" />} />
        <Route path="/login" element={<Login />} />
        <Route path="/forgot-password" element={<ForgotPassword />} />
        <Route path="/admin" element={<Layout />}>
          {loading && <Route path="*" element={<PageLoader />} />}
          {filteredRoutes.length > 0 ? (
            filteredRoutes?.map((route: any) => (
              <Route key={route.path} path={route.path}>
                {route?.children.map((subRoute: any) => {
                  return (
                    <Route
                      key={subRoute.id}
                      path={subRoute.path}
                      element={<AuthGuard Component={subRoute.element} />}
                    />
                  );
                })}
              </Route>
            ))
          ) : (
            <Route path="*" element={<PermissionDenied />} />
          )}
        </Route>
        <Route path="/permission-denied" element={<PermissionDenied />} />
      </Routes>
    </Suspense>
  );
}

export default Router;
