import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from '../../redux/store';

// Import shared components
import TopHeader from '../../shared-components/top-header/top-header';
import CardBox from '../../shared-components/card/card';
import DataTable from '../../shared-components/data-table/data-table';
import ModalBox from '../../shared-components/modal-box/modal-box';
import EditReader from './components/edit-reader';
import BindingUnbindingCameraModal from './components/binding-unbinding';
import { DepartmentParamsProps } from '../../interface/accessByDepartment.interface';
import { fetchReaderList, setSearchFilter } from '../../redux/slices/ReaderSlice';
import reader from '../../utils/api/Devices/reader';
import { setNotificationError } from '../../redux/slices/NotificationSlices';
import { checkPermission } from '../../utils/role-permission';

function Reader() {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { readers, status, totalDataCount, searchFilter } = useAppSelector(
    (state: any) => state.reader,
  );
  const { loadMenus } = useAppSelector((state) => state.auth);

  let handleEditOpen: any;
  const canEdit = checkPermission('acc:reader:edit', loadMenus);
  const columnsField = [
    {
      accessorKey: 'name',
      header: t('name'),
      Cell: ({ row }: any) => (
        <div
          className={canEdit ? 'list-hyperlink' : ''}
          role="presentation"
          onClick={(event) => {
            if (!canEdit) return;
            event.stopPropagation();
            handleEditOpen(row.original);
          }}>
          {row?.original?.name}
        </div>
      ),
    },
    {
      accessorKey: 'doorName',
      header: t('doorName'),
    },
    {
      accessorKey: 'commType',
      header: t('communicationType'),
      Cell: ({ row }: any) => <div>{row.original.commType === 3 ? 'Wiegand/RS485' : ''}</div>,
    },
    {
      accessorKey: 'readerState',
      header: t('in/Out'),
      Cell: ({ row }: any) => <div>{row.original.readerState === 0 ? 'In' : 'Out'}</div>,
    },
    {
      accessorKey: 'boundCamera',
      header: t('boundCamera'),
    },
    {
      accessorKey: 'owningBoard',
      header: t('owningBoard'),
    },
  ];

  const [modalOpen, setModalOpen] = useState(false);
  const [modalView, setModalView] = useState({
    title: '',
    content: <>hi</>,
  });
  handleEditOpen = (editData: any) => {
    if (!modalOpen) {
      setModalOpen(true);
      setModalView({
        title: t('edit'),
        content: (
          <EditReader
            initialData={{
              ...editData,
              doorName: editData.doorName,
              number: editData.doorNo,
              inOut: editData.readerState === 0 ? 'out' : 'in',
              name: editData.name,
              id: editData.id,
            }}
            closeModal={() => setModalOpen(false)}
          />
        ),
      });
    }
  };
  const onClickBindCamera = () => {
    if (!modalOpen) {
      reader
        .isExistVidChannel()
        .then((response) => {
          if (!response.data) {
            setModalView({
              title: t('binding/unbindingcamera'),
              content: (
                <BindingUnbindingCameraModal
                  closeModal={() => setModalOpen(false)}
                  onConfirm={() => setModalOpen(false)}
                />
              ),
            });
            setModalOpen(true);
          } else {
            dispatch(
              setNotificationError({
                error: response.data.message,
                status: response.data.code,
              }),
            );
          }
        })
        .catch((error) => {
          dispatch(setNotificationError(error.response.data));
        });
    }
  };
  const actionButtons = [
    {
      label: t('edit'),
      enable: checkPermission('acc:reader:edit', loadMenus),
      function: (val: any) => handleEditOpen(val),
    },
    {
      label: t('binding/unbindingcamera'),
      enable: checkPermission('acc:reader:bindChannel', loadMenus),
      function: onClickBindCamera,
    },
  ];

  const fetchReaders = (params: DepartmentParamsProps) => {
    if (status === 'success') {
      dispatch(fetchReaderList({ ...params }));
    }
  };

  const getFilterData = (filter: any): any => {
    if (filter.pageIndex > 0) {
      dispatch(setSearchFilter(filter));
      fetchReaders({ pageNo: filter.pageIndex, pageSize: filter.pageSize, ...filter.filter });
    }
  };

  return (
    <div className="department">
      <ModalBox
        status={modalOpen}
        closeModal={() => setModalOpen(false)}
        title={modalView.title}
        content={modalView.content}
      />
      <TopHeader
        title={t('access')}
        broadCram={[
          {
            title: t('accessDevice'),
          },
          {
            title: t('reader'),
          },
        ]}
      />
      <CardBox title={t('listOfReader')}>
        <DataTable
          actionButtonStatus={!modalOpen} // Disable action buttons when modal is open
          action={actionButtons}
          columns={columnsField}
          getFilterData={getFilterData}
          assignFilterValue={searchFilter}
          checkBoxActive={false}
          enablePagination
          isFetching={status === 'loading'}
          values={readers} // Use the actual data here
          fixedColumn={['readerName']}
          totalRecords={totalDataCount}
          height={411}
        />
      </CardBox>
    </div>
  );
}

export default Reader;
