import React, { useEffect, useState } from 'react';
import Cookies from 'universal-cookie';
import { useTranslation } from 'react-i18next';
import InputField from '../../../shared-components/input-field/input-field';
import Button from '../../../shared-components/button/button';
import accessLevel from '../../../utils/api/AccessRule/accessLevel';
import { useAppDispatch, useAppSelector } from '../../../redux/store';
import { setNotificationError } from '../../../redux/slices/NotificationSlices';
import { fetchAccessLevels } from '../../../redux/slices/AccessLevelSlices';
import { checkIsNeedValidUser } from '../../../redux/slices/AuthSlices';

type ModalType = {
  closeModal: () => void;
  selectedId: string[];
};
function RemoteOpeningModal({ closeModal, selectedId }: ModalType) {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const cookies = new Cookies();
  const { isNeedValidUser } = useAppSelector((state) => state?.auth);
  const [levelDetails, setLevelDetails] = useState({
    openValue: '5',
    userPassword: '',
  });
  const [disable, setDisable] = useState(false);

  const onChangeValue = (name: string, value: any) => {
    setLevelDetails((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const submit = async () => {
    setDisable(true);
    try {
      const request: any = {
        ids: selectedId.join('&ids='),
        openInterval: levelDetails.openValue,
      };
      if (!isNeedValidUser) {
        request.userName = cookies.get('username') && cookies.get('username');
        request.loginPassword = levelDetails.userPassword;
      }
      const response = await accessLevel.openingDoor(request);
      if (response?.data?.success) {
        dispatch(
          setNotificationError({
            error: response.data.msg,
            status: 200,
          }),
        );
        setDisable(false);
        dispatch(fetchAccessLevels({ pageNo: 1, pageSize: 10 }));
        closeModal();
      }
    } catch (error: any) {
      setDisable(false);
      dispatch(
        setNotificationError({
          error: error.message,
          status: error.status,
        }),
      );
    }
  };

  useEffect(() => {
    dispatch(checkIsNeedValidUser({ mode: 'pwd' }));
  }, []);

  useEffect(() => {
    if (levelDetails?.openValue && (isNeedValidUser ? true : levelDetails?.userPassword)) {
      setDisable(false);
    } else {
      setDisable(true);
    }
  }, [levelDetails, isNeedValidUser]);

  return (
    <div className="remote-opening-modal">
      <div className="input-section">
        {!isNeedValidUser && (
          <InputField
            id="userPassword"
            name="userPassword"
            label="User Password"
            value={levelDetails.userPassword}
            onChangeFunction={onChangeValue}
            type="password"
            required
          />
        )}
        <InputField
          id="openValue"
          name="openValue"
          label="Open"
          value={levelDetails.openValue}
          onChangeFunction={onChangeValue}
          type="number"
          min={1}
          max={254}
          className="input-number"
          errorStatus={Number(levelDetails.openValue) <= 0 || Number(levelDetails.openValue) > 255}
          message={t('second(1-254)')}
          required
        />
      </div>

      <div className="form-buttons-right">
        <Button onClickFunction={() => closeModal()} title={t('cancel')} className="btn-default" />
        <Button
          onClickFunction={submit}
          title={t('ok')}
          className="btn-primary"
          disabled={disable}
        />
      </div>
    </div>
  );
}

export default RemoteOpeningModal;
