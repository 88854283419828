import React, { useState, useEffect } from 'react';
import i18next from 'i18next';
import { useTranslation } from 'react-i18next';
import Cookies from 'universal-cookie';
import { useNavigate, Link } from 'react-router-dom';
import LoginApis from '../../utils/api/auth';
import securityPrameters from '../../utils/api/SystemManagement/securityPrameters';
import InputField from '../../shared-components/input-field/input-field';
import Button from '../../shared-components/button/button';
import CheckBoxInputField from '../../shared-components/checkbox-input-field/checkbox-input-field';
import DropDown from '../../shared-components/drop-down/drop-down';
import loginImage from '../../assets/images/login-image.png';
import fingerprintIcon from '../../assets/images/fingerprint-icon.png';
import { viewIcon, hideIcon, ArabicFlagIcon, EnglishFlagIcon } from '../../assets/svgicons/svgicon';
import { DropDownMenuInterFace } from '../../interface/common.interface';
import { FormData, CheckBoxInterFace } from '../../interface/login.interface';
import { useAppDispatch } from '../../redux/store';
import {
  setNotificationError,
  setNotificationSuccess,
} from '../../redux/slices/NotificationSlices';
import ResetPassword from './components/reset-password';
import ModalBox from '../../shared-components/modal-box/modal-box';
import { resetAuthState, saveSystemCode } from '../../redux/slices/AuthSlices';
import { removeLocalStorageItem, setLocalStorageItem } from '../../utils/helpers';

function Login() {
  const { t } = useTranslation();
  const cookies = new Cookies();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const currentLanguageCode = cookies.get('i18next');

  const [formData, setFormData] = useState<FormData>({
    username: '',
    password: '',
    agreement: [],
    captcha: '',
  });
  const [errors, setErrors] = useState(false);
  const [isPasswordVisible, setIsPasswordVisible] = useState(false);
  const [activeLogin, setActiveLogin] = useState<'user' | 'person'>('user');
  const [disableButton, setDisableButton] = useState(true);
  const [needVerCode, setNeedVerCode] = useState('');
  const [verificationOption, setVerificationOption] = useState('0');
  const [captchaData, setCaptchaData] = useState<any>(null);
  const [isNeedVerCode, setIsNeedVerCode] = useState<boolean>(false);
  const [modalOpen, setModalOpen] = useState(false);
  const [modalView, setModalView] = useState({
    title: '',
    content: <>hi</>,
  });
  const checkBoxOptions: CheckBoxInterFace[] = [
    {
      id: 'agreement',
      value: 'agreement',
      label: t('iHaveReadAndAgreeToTheProductUseAgreement'),
    },
  ];

  const validateFormData = (): void => {
    if (
      !formData.username || // Username must be filled
      !formData.password || // Password must be filled
      formData.agreement.length === 0 || // Agreement must be checked
      ((needVerCode === '1' || isNeedVerCode) && !formData.captcha) // CAPTCHA must be filled if required
    ) {
      setDisableButton(true);
    } else {
      setDisableButton(false);
    }
  };

  useEffect(() => {
    validateFormData();
  }, [formData, errors, needVerCode, isNeedVerCode]);

  const handleInputChange = (name: string, value: any): void => {
    const newFormData = {
      ...formData,
      [name]: value,
    };
    setFormData(newFormData);
  };

  const fetchCaptcha = async () => {
    try {
      const response = await securityPrameters.verifyCode({ userName: formData?.username });
      const blobToUrl = (binaryData: Blob) => {
        const url = URL.createObjectURL(binaryData);
        return url;
      };
      const imageUrl: any = blobToUrl(response.data);
      setCaptchaData(imageUrl);
    } catch (error: any) {
      dispatch(
        setNotificationError({
          error: error.message,
          status: error.status,
        }),
      );
    }
  };

  const fetchVerificationParams = async () => {
    try {
      const params = await securityPrameters.getLoginVerificationParams();
      const newNeedVerCode = params.data.data.needVerCode === 'true' ? '1' : '0';
      setVerificationOption(newNeedVerCode);
      setNeedVerCode(newNeedVerCode);

      await fetchCaptcha();
    } catch (error: any) {
      dispatch(
        setNotificationError({
          error: error.message,
          status: error.status,
        }),
      );
    }
  };

  const handleIncorrectCredentials = async () => {
    try {
      const verCodeResponse = await securityPrameters.getIsNeedVerCodeParams({
        userName: formData?.username,
      });
      const requiresVerCode = verCodeResponse.data.data.needVerCode;
      setIsNeedVerCode(requiresVerCode);

      if (verificationOption === '2' || requiresVerCode) {
        setNeedVerCode('1');
        await fetchCaptcha();
      }
    } catch (error: any) {
      dispatch(
        setNotificationError({
          error: error.message,
          status: 500,
        }),
      );
    }
  };

  const handleSubmit = async () => {
    setDisableButton(true);
    try {
      const pwdCheckResponse = await securityPrameters.pwdEffectiveCheck({
        userName: formData.username,
        password: formData.password,
      });

      const isCheckPwdFalse = !pwdCheckResponse.data?.data?.checkPwd;

      if (!isCheckPwdFalse) {
        setErrors(false);
      }

      try {
        const login: any = await LoginApis.login({
          userName: formData.username,
          password: formData.password,
          checkCode: formData.captcha,
        });
        if (login.data.ret === '200' || login.data.success === true) {
          setErrors(false);
          if (login?.data?.data?.pwdInitFlag && login?.data?.data?.pwdInitFlag !== '1') {
            setModalView({
              title: t('resetPassword'),
              content: (
                <ResetPassword
                  userName={formData?.username}
                  closeModal={() => setModalOpen(false)}
                />
              ),
            });
            setModalOpen(true);
            setDisableButton(false);
            return;
          }
          dispatch(saveSystemCode('Pers'));
          setLocalStorageItem('systemCode', 'Pers');
          setErrors(false);
          dispatch(
            setNotificationSuccess({
              message: login.data.message || login.data.msg,
              status: 200,
            }),
          );
          cookies.set('token', login?.data?.token?.accessToken, { path: '/' });
          cookies.set('username', login?.data?.data?.username, { path: '/' });
          cookies.set('userId', login?.data?.data?.id, { path: '/' });
          setLocalStorageItem('isSuperuser', { isSuperUser: login?.data?.data?.isSuperuser });
          setTimeout(() => {
            setDisableButton(false);
            navigate('/admin/pers/personnel/person');
          }, 800);
        } else {
          setErrors(true);
          dispatch(
            setNotificationError({
              error: login.data.msg || login.data.message || t('loginFailed'),
              status: 500,
              position: 'bottom',
            }),
          );
          await handleIncorrectCredentials();
        }
      } catch (error: any) {
        setErrors(true);
        dispatch(
          setNotificationError({
            error: error.response?.data?.message || t('somethingWentWrong'),
            status: error.response?.status || 500,
            position: 'bottom',
          }),
        );

        const isCredentialsError =
          error.response?.status === 401 ||
          (error.response?.data?.message &&
            error.response.data.message.toLowerCase().includes('incorrect')) ||
          (error.response?.data?.message &&
            error.response.data.message.toLowerCase().includes('invalid credentials'));

        if (isCredentialsError) {
          await handleIncorrectCredentials();
        }
      }
    } catch (error: any) {
      setDisableButton(false);
      setErrors(true);
      dispatch(
        setNotificationError({
          error: error.response?.data?.message || t('somethingWentWrong'),
          status: error.response?.status || 500,
        }),
      );
    }

    setDisableButton(false);
  };

  const togglePasswordVisibility = (): void => {
    setIsPasswordVisible(!isPasswordVisible);
  };

  const handleLoginClick = (loginType: 'user' | 'person') => {
    setActiveLogin(loginType);
  };

  const actions = [
    {
      icon: <EnglishFlagIcon />,
      label: 'English',
      key: 'en',
    },
    {
      icon: <ArabicFlagIcon />,
      label: 'عربي',
      key: 'ar',
    },
  ];

  const getSelectLanguage = (val: DropDownMenuInterFace) => {
    i18next.changeLanguage(val.key);
    if (val.key === 'ar') {
      document.documentElement.setAttribute('dir', 'rtl');
    } else {
      document.documentElement.setAttribute('dir', 'ltr');
    }
    window.location.reload();
  };

  useEffect(() => {
    fetchVerificationParams();
    cookies.remove('token', { path: '/' });
    cookies.remove('username', { path: '/' });
    cookies.remove('userId', { path: '/' });
    removeLocalStorageItem('isSuperuser');
    removeLocalStorageItem('systemCode');
    dispatch(resetAuthState());
  }, []);

  return (
    <div className="login-container">
      <ModalBox
        status={modalOpen}
        closeModal={() => setModalOpen(false)}
        title={modalView.title}
        content={modalView.content}
      />
      <div className="login-header">
        <div className="logo">
          <span className="logo-aic">AIC</span>
          <span className="logo-tec">TEC</span>
          <div className="subtitle">SECURITY SYSTEM</div>
        </div>
        <div className="links">
          <Link to="/download">{t('downloadCertificate')}</Link>
          <Link to="/about">{t('about')}</Link>
          <DropDown
            name={cookies.get('i18next') === 'en' ? <EnglishFlagIcon /> : <ArabicFlagIcon />}
            action={actions}
            clickFunction={(val: DropDownMenuInterFace) => getSelectLanguage(val)}
          />
        </div>
      </div>
      <div className="login-body">
        <div className="login-image">
          <img src={loginImage} alt={t('Login')} />
        </div>
        <div className={`login-form ${currentLanguageCode === 'ar' ? 'ar' : ''}`}>
          <h2>{t('welcomeBack!PleaseEnterYourDetails')}</h2>
          <div className="login-links">
            <div
              className={`login-link ${activeLogin === 'user' ? 'active' : ''}`}
              onClick={() => handleLoginClick('user')}
              role="button"
              tabIndex={0}
              onKeyPress={(e) => {
                if (e.key === 'Enter' || e.key === ' ') {
                  handleLoginClick('user');
                }
              }}>
              {t('userLogin')}
            </div>
            <div
              className={`login-link ${activeLogin === 'person' ? 'active' : ''}`}
              onClick={() => handleLoginClick('person')}
              role="button"
              tabIndex={0}
              onKeyPress={(e) => {
                if (e.key === 'Enter' || e.key === ' ') {
                  handleLoginClick('person');
                }
              }}>
              {t('person-SelfLogin')}
            </div>
          </div>
          <form>
            <InputField
              name="username"
              label={t('username')}
              id="username"
              type="text"
              value={formData.username}
              onChangeFunction={handleInputChange}
              innerIconStatus={false}
              InnerIcon={null}
              innerIconFunction={() => {}}
              className="username-input"
              required
              message={t('invalidMailID')}
              disabled={false}
            />
            <InputField
              name="password"
              label={t('password')}
              id="password"
              type={isPasswordVisible ? 'text' : 'password'}
              value={formData.password}
              onChangeFunction={handleInputChange}
              innerIconStatus
              InnerIcon={isPasswordVisible ? viewIcon : hideIcon}
              innerIconFunction={togglePasswordVisibility}
              className="password-input"
              required
              message={errors ? t('Invalid Password') : ''}
              disabled={false}
            />
            {needVerCode === '1' || isNeedVerCode ? (
              <div className="captcha-container">
                <InputField
                  name="captcha"
                  label={t('captcha')}
                  id="captcha"
                  type="text"
                  value={formData.captcha}
                  onChangeFunction={handleInputChange}
                  innerIconStatus={false}
                  InnerIcon={null}
                  innerIconFunction={() => {}}
                  className="password-input"
                  required
                  message={t('enterCaptcha')}
                  disabled={false}
                />
                <img src={captchaData} alt="captchaImage" className="captcha-image" />
              </div>
            ) : null}
            <Link to="/forgot-password" className="forgot-password">
              {t('forgotPassword?')}
            </Link>
            <CheckBoxInputField
              name="agreement"
              data={checkBoxOptions}
              value={formData.agreement}
              label=""
              alignment="horizontal"
              position="left"
              required={false}
              onChangeFunction={handleInputChange}
            />
            <Button
              title={t('login')}
              className="btn-primary full-size"
              onClickFunction={handleSubmit}
              disabled={disableButton}
            />
            {activeLogin === 'user' && <div className="or-divider">{t('or')}</div>}
            {activeLogin === 'user' && (
              <div className="fingerprint-login">
                <img src={fingerprintIcon} alt={t('Fingerprint')} />
              </div>
            )}
          </form>
        </div>
      </div>
    </div>
  );
}

export default Login;
