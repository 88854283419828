import { post } from '../base/index';

export default {
  getListPage: (data: any = {}) => {
    return post(`${process.env.REACT_APP_API_URL}Sys/api/admsDevice/listByPage`, {}, { ...data });
  },

  authDeviceList: (data: any = {}) => {
    return post(
      `${process.env.REACT_APP_API_URL}Sys/api/admsDevice/authDeviceList`,
      {},
      { ...data },
    );
  },
};
