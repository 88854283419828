import React from 'react';
import { useTranslation } from 'react-i18next';
import { AttentionIcon } from '../../../../../assets/svgicons/svgicon';
import RadioButtonField from '../../../../../shared-components/radio-button-field/radio-button-field';
import InputField from '../../../../../shared-components/input-field/input-field';

interface MoniterInterface {
  onChangeFunction: (name: any, changeValue: any) => void;
  values?: any;
}

function AdmsServiceSettings({ onChangeFunction, values }: MoniterInterface) {
  const { t } = useTranslation();
  const onChange = (name: any, changeValue: any) => {
    onChangeFunction(name, changeValue);
  };

  return (
    <div className="card-management">
      <div className="cloud-setting">
        <div className="cloud-setting-form w50">
          <div className="column">
            <div className="tab active">{t('admsServicePort')}</div>
            <div className="adms-item">
              <InputField
                id="adms.serverPort"
                label=""
                name="adms.serverPort"
                type="text"
                value={values['adms.serverPort']}
                onChangeFunction={onChange}
                disabled
              />
            </div>
            <div className="tab active">{t('projectControlFileVersion')}</div>
            <div className="adms-item">
              <InputField
                id="projectControlFile"
                label=""
                name="project.control.file"
                type="text"
                value="None"
                onChangeFunction={onChange}
                disabled
              />
            </div>

            <div className="item">
              <RadioButtonField
                label="Turn on encrypted transmission"
                name="adms.encrypted.transmission"
                alignment="row"
                data={[
                  {
                    label: t('yes'),
                    id: '1',
                    value: '1',
                  },
                  {
                    label: t('no'),
                    id: '0',
                    value: '0',
                  },
                ]}
                value={values['adms.encrypted.transmission']}
                onChangeFunction={onChange}
              />
            </div>

            <div className="text-danger mt-2">
              <div className="note">
                <div className="alter-icon">
                  <AttentionIcon />
                </div>
                <div className="content">
                  {t(
                    'theCurrentPortIsForDeviceCommunicationServiceIfThereIsANetworkMappingForTheServicePortPleaseReferToTheActualMappedPort',
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
AdmsServiceSettings.defaultProps = {
  values: '',
};
export default AdmsServiceSettings;
