import React, { useEffect, useRef, useState } from 'react';

import { useTranslation } from 'react-i18next';

// day js
import dayjs from 'dayjs';

// Use shared components
import InputField from '../../../shared-components/input-field/input-field';
import DatePickerField from '../../../shared-components/date-picker-field/date-picker-field';
import SelectBoxField from '../../../shared-components/selectbox-field/selectbox-field';

// Use interface
import { ProfessionalDetailsProps } from '../../../interface/personnel.interface';

// Use json file
import { personVerifyModes } from '../../../utils/helpers';
import person from '../../../utils/api/person';
import {
  // BioFingerPrint,
  // InfoIcon,
  IssueCardIcon,
  StopIssueIcon,
} from '../../../assets/svgicons/svgicon';
import TabModalBox from '../../../shared-components/tab-modal-box/tab-modal-box';
// import FingerPrintModal from './FingerPrintModal';
import ModalBox from '../../../shared-components/modal-box/modal-box';
import AddDoor from './CardNumber';
import allTransaction from '../../../utils/api/AccControlReports/allTransaction';
import { setNotificationError } from '../../../redux/slices/NotificationSlices';
import { useAppDispatch } from '../../../redux/store';
import SpinnerLoader from '../../../shared-components/spinnerLoader/spinner-loader';
import card from '../../../utils/api/card';

function ProfessionalDetails({
  onChangeFunction,
  values,
  allDepartments,
  allPositions,
}: ProfessionalDetailsProps) {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const intervalRef = useRef<NodeJS.Timeout | null>(null);

  const [value, setValue] = useState(values);

  const [errorMessage, setErrorMessage] = useState('');
  const [checkExist, setCheckExistName] = useState(true);
  const [tabModalOpen, setTabModalOpen] = useState(false);
  const [tabModalView, setTabModalView] = useState({
    title: '',
    content: [
      {
        title: '',
        key: '',
        content: <>hi</>,
      },
    ],
    index: 0,
  });
  const [modalOpen, setModalOpen] = useState(false);
  const [modalView, setModalView] = useState({
    title: '',
    content: <>hi</>,
  });
  const [loading, setLoading] = useState(false);
  // const [isExistCard, setIsExistCard] = useState(false);
  let isExistCardNumber: (val: string) => void;

  const onChange = (name: string, changeValue: any) => {
    if (name === 'pin' && changeValue.length > 9) {
      setErrorMessage(t('MaxLengthError'));
      return;
    }
    if (name === 'cardNo' && changeValue !== '') {
      isExistCardNumber(changeValue);
    }
    setErrorMessage('');
    setValue((prev: any) => ({
      ...prev,
      [name]: changeValue,
    }));
  };

  const onBlurName = async (name: string, changeValue: any) => {
    if (name === 'pin' && changeValue !== '' && changeValue !== values?.pin) {
      const responseData = await person.isExistName({ pin: changeValue });
      setCheckExistName(responseData?.data);
    }
  };

  // const handleBiometrics = () => {
  //   setTabModalView({
  //     title: t('biometricType'),
  //     content: [
  //       {
  //         title: t('fingerPrint'),
  //         content: <FingerPrintModal closeModal={() => setTabModalOpen(false)} />,
  //         key: 'fingerPrint',
  //       },
  //       {
  //         title: t('fingerVein'),
  //         content: <>hi</>,
  //         key: 'fingerVein',
  //       },
  //       {
  //         title: t('palmRegistration'),
  //         content: <>hi</>,
  //         key: 'palmRegistration',
  //       },
  //       {
  //         title: t('faceRegistration'),
  //         content: <>hi</>,
  //         key: 'faceRegistration',
  //       },
  //     ],
  //     index: 0,
  //   });
  //   setTabModalOpen(true);
  // };

  // const handleBioInfo = () => {
  //   console.log('hi');
  // };

  isExistCardNumber = async (val: string) => {
    const res = await card.isExistCardNo({ cardNo: val });
    setValue((prev: any) => ({
      ...prev,
      isExistCard: res?.data?.data !== 'true',
    }));
  };

  const submitCard = async (selectedDoor: string[]) => {
    setModalOpen(false);
    setLoading(true);
    const time = dayjs().format('YYYY-MM-DD HH:mm:ss');
    const req = {
      readerIds: selectedDoor?.join(','),
      type: 'cardNo',
      time,
    };

    const pollingInterval = 1000; // Poll every 1.5 second

    const pollApi = async () => {
      try {
        const response = await allTransaction.readerCardNo(req);
        if (response.data.success) {
          if (response.data.data.cardNo) {
            setLoading(false);
            setValue((prev: any) => ({
              ...prev,
              cardNo: response?.data?.data?.cardNo,
            }));
            isExistCardNumber(response?.data?.data?.cardNo);
            if (intervalRef.current) clearInterval(intervalRef.current); // Clear the interval
          }
        }
      } catch (error: any) {
        dispatch(
          setNotificationError({
            error: error.message,
            status: error.status,
          }),
        );
        setLoading(false);
        if (intervalRef.current) clearInterval(intervalRef.current);
      }
    };

    intervalRef.current = setInterval(pollApi, pollingInterval);
  };

  const handleIssueCard = () => {
    setModalView({
      title: t('chooseTheDoor'),
      content: <AddDoor closeModal={() => setModalOpen(false)} submitFunction={submitCard} />,
    });
    setModalOpen(true);
  };

  const handleStopIssue = () => {
    if (intervalRef.current) {
      clearInterval(intervalRef.current);
      setLoading(false);
    }
  };

  useEffect(() => {
    onChangeFunction(value);
  }, [value]);

  useEffect(() => {
    return () => {
      if (intervalRef.current) {
        clearInterval(intervalRef.current);
      }
    };
  }, []);

  return (
    <div className="personal-details">
      <TabModalBox
        status={tabModalOpen}
        closeModal={() => {
          setTabModalOpen(false);
          setTabModalView({
            title: '',
            content: [
              {
                title: '',
                key: '',
                content: <>hi</>,
              },
            ],
            index: 0,
          });
        }}
        title={tabModalView.title}
        tabs={tabModalView.content}
        currentIndex={tabModalView.index}
      />
      <ModalBox
        status={modalOpen}
        closeModal={() => setModalOpen(false)}
        title={modalView.title}
        content={modalView.content}
      />
      <div className="personal-details-form">
        <div className="row-form-warp">
          <div className="item">
            <InputField
              name="pin"
              label={t('personalId')}
              id="pin"
              type="number"
              value={value.pin}
              required
              disabled={!!value.id}
              onBlurFunction={onBlurName}
              onChangeFunction={onChange}
              errorStatus={errorMessage !== '' || (value.pin !== '' && !checkExist)}
              message={errorMessage || t('objectIsAlreadyExist')}
            />
          </div>
          <div className="item">
            <DatePickerField
              name="hireDate"
              label={t('hireDate')}
              value={value.hireDate}
              onChangeFunction={onChange}
              formatDate={false}
              disableFuture
            />
          </div>
          <div className="item">
            <SelectBoxField
              name="certType"
              label={t('certificateType')}
              id="certType"
              value={value.certType}
              onChangeFunction={(name, val) => {
                onChange(name, val.id);
              }}
              data={[
                {
                  label: t('id'),
                  id: '1',
                },
                {
                  label: t('passport'),
                  id: '3',
                },
                {
                  label: t('drivingLicense'),
                  id: '4',
                },
                {
                  label: t('others'),
                  id: '8',
                },
              ]}
            />
          </div>
          <div className="item">
            <InputField
              name="certNumber"
              label={t('certificateNumber')}
              id="certNumber"
              type="text"
              value={value.certNumber}
              onChangeFunction={onChange}
            />
          </div>
          <div className="item">
            <SelectBoxField
              name="deptId"
              label={t('department')}
              id="deptId"
              value={value.deptId}
              required
              onChangeFunction={(name, val) => {
                onChange(name, val.id);
              }}
              data={allDepartments} // Use the department data
            />
          </div>
          <div className="item">
            <SelectBoxField
              name="positionId"
              label={t('positionName')}
              id="positionId"
              value={value.positionId}
              onChangeFunction={(name, val) => {
                onChange(name, val.id);
              }}
              data={allPositions}
            />
          </div>
          <div className="item">
            <InputField
              name="deviceVerification"
              label={t('deviceVerificationPassword')}
              id="deviceVerification"
              type="password"
              value={value.deviceVerification}
              onChangeFunction={onChange}
            />
          </div>
          <div className="item">
            <InputField
              name="biometrics"
              label={t('biometrics')}
              id="biometrics"
              type="text"
              value={value.verifyMode ? personVerifyModes(value.verifyMode) : ''}
              onChangeFunction={onChange}
              // innerIconStatus
              // InnerIcon={BioFingerPrint}
              // innerIconFunction={handleBiometrics}
              // SecondInnerIcon={InfoIcon}
              // secondInnerIconStatus
              // secondInnerIconFunction={handleBioInfo}
              disabled
            />
          </div>
          <div className="item">
            <div className="flex-row">
              <InputField
                name="cardNo"
                label={t('issueCardNumbers')}
                id="cardNo"
                type="number"
                value={value?.cardNo}
                innerIconStatus
                InnerIcon={loading ? StopIssueIcon : IssueCardIcon}
                innerIconFunction={!loading ? handleIssueCard : handleStopIssue}
                onChangeFunction={onChange}
                errorStatus={value?.isExistCard}
                message="object is already exist"
              />
              {loading && <SpinnerLoader />}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ProfessionalDetails;
