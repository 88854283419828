import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import CheckBoxInputField from '../../../../../shared-components/checkbox-input-field/checkbox-input-field';
import InputField from '../../../../../shared-components/input-field/input-field';
import Button from '../../../../../shared-components/button/button';
import { AttentionIcon } from '../../../../../assets/svgicons/svgicon';
import Label from '../../../../../shared-components/label/Label';

function SynchronizePermission({ onChangeFunction }: { onChangeFunction: (values: any) => void }) {
  const { t } = useTranslation();
  const [localValues, setLocalValues] = useState({
    syncPermissionGroup: [], // Changed to string[] to match expected type
    syncMembersInGroup: [], // Changed to string[] to match expected type
    domainNode: '',
  });

  const onChange = (name: string, value: any) => {
    if (Array.isArray(value)) {
      setLocalValues((prev) => ({
        ...prev,
        [name]: value,
      }));
      onChangeFunction({
        ...localValues,
        [name]: value.length > 0, // Convert to boolean: true if array has items, false if empty
      });
    } else {
      setLocalValues((prev) => ({
        ...prev,
        [name]: value,
      }));
      onChangeFunction({
        ...localValues,
        [name]: value,
      });
    }
  };

  return (
    <div className="synchronizePermission-setting">
      <div className="sync-settings">
        <div className="checkbox-group">
          <div className="box">
            <CheckBoxInputField
              name="syncPermissionGroup"
              data={[
                {
                  id: 'syncPermissionGroup',
                  label: t('synchronizationPermissionGroup'),
                  value: 'syncPermissionGroup',
                },
              ]}
              value={localValues.syncPermissionGroup}
              onChangeFunction={(name, value) => onChange(name, value)}
              alignment="row"
            />
          </div>
          <div className="box">
            <CheckBoxInputField
              name="syncMembersInGroup"
              data={[
                {
                  id: 'syncMembersInGroup',
                  label: t('synchronizeMembersInPermissionGroup'),
                  value: 'syncMembersInGroup',
                },
              ]}
              value={localValues.syncMembersInGroup}
              onChangeFunction={(name, value) => onChange(name, value)}
              alignment="row"
            />
          </div>
        </div>
        <div className="domain-node">
          <Label className="sub-title" title={t('domainNode')} />
          <div className="domainNode">
            <InputField
              id="domainNode"
              name="domainNode"
              label=""
              value={localValues.domainNode}
              onChangeFunction={(name, value) => onChange(name, value)}
              type="text"
            />
          </div>
        </div>
        <div className="warning-message text-danger flex-row center">
          <AttentionIcon />
          {t(
            'pleasePerformTheSynchronizationPermissionGroupFirstOtherwiseTheSynchronizationMayFail',
          )}
        </div>
      </div>
      <div className="cleanup-button">
        <Button
          title={t('testConnection')}
          className="btn-primary"
          onClickFunction={() => console.log('Testing connection...')}
        />
      </div>
    </div>
  );
}

export default SynchronizePermission;
