import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
// Import shared components
import { useAppDispatch, useAppSelector } from '../../../redux/store';
import TopHeader from '../../../shared-components/top-header/top-header';
import CardBox from '../../../shared-components/card/card';
import DataTable from '../../../shared-components/data-table/data-table';
import DeleteModalBox from '../../../shared-components/delete-modal-box/delete-modal-box';
import ModalBox from '../../../shared-components/modal-box/modal-box';
import CardRightButtonGroup from '../../../shared-components/card-right-button-group/card-right-button-group';
import { DepartmentParamsProps } from '../../../interface/accessByDepartment.interface';
import { setNotificationError } from '../../../redux/slices/NotificationSlices';
import { AddIcon, DeleteButtonIcon } from '../../../assets/svgicons/svgicon';
import {
  fetchDeptList,
  fetchMusterPoint,
  setDeptSearchFilter,
  setSearchFilter,
  deleteDept as deleteDepartment,
} from '../../../redux/slices/MusterPointSlices';
import AddMusterPoint from './components/addMusterPoint';
import AddDepartment from './components/AddDepartment';
import musterPoint from '../../../utils/api/AdvancedFunctions/musterPoint';
import ConformationBox from '../../../shared-components/conformation-box/conformation-box';
import { checkPermission } from '../../../utils/role-permission';

interface LevelProps {
  pageNo: number;
  pageSize?: number;
  accMusterPointId?: string;
}

interface DeleteLevelProps {
  id: string | string[];
  pin: string;
}
function MusterPoint() {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const {
    pointList,
    status,
    totalDataCount,
    searchFilter,
    departments,
    deptStatus,
    deptTotalDataCount,
    deptSearchFilter,
  } = useAppSelector((state: any) => state.musterPoint);

  /**
   * Selects the `loadMenus` permission from the authentication slice of the Redux store.
   *
   * @returns {Object} loadMenus - The user's menu permissions.
   */
  const { loadMenus } = useAppSelector((state) => state.auth);

  const [modalOpen, setModalOpen] = useState(false);
  const [modalView, setModalView] = useState({
    title: '',
    content: <>hi</>,
  });
  const [selectedValues, setSelectedValues] = useState<string[]>([]);
  const [selectedPointFullData, setSelectedPointFullData] = useState<any[]>([]);
  const [selectedDeptFullData, setSelectedDeptFullData] = useState<any[]>([]);

  const [personnelSelect, setPersonnelSelect] = useState<string[]>([]);
  let handleEditOpen: any;
  // const canEdit = checkPermission('acc:interlock:edit', loadMenus) || true;

  const fetchEditData = async (ids: string) => {
    try {
      const response = await musterPoint.edit({ id: ids });
      return response.data.data;
    } catch (error: any) {
      dispatch(setNotificationError(error.response.data));
      return null;
    }
  };

  const columnsField = [
    {
      accessorKey: 'name',
      header: t('name'),
      Cell: ({ row }: any) => (
        <div
          className="list-hyperlink"
          role="presentation"
          onClick={(event) => {
            event.stopPropagation(); // Prevent row selection
            handleEditOpen(row.original); // Call your edit function
          }}>
          {row?.original?.name}
        </div>
      ),
    },
    {
      accessorKey: 'signPoint',
      header: t('signPoint'),
      Cell: ({ row }: any) => {
        const [details, setDetails] = useState([]);

        useEffect(() => {
          const fetchData = async () => {
            const response = await fetchEditData(row.original.id);
            const result = response?.signPointList
              ?.map((item: any) => item.signPointName)
              .join(', ');
            setDetails(result);
          };

          fetchData();
        }, [row.original.id]);

        return <span>{details}</span>;
      },
    },
    {
      accessorKey: 'activated',
      header: t('activate'),
      Cell: ({ row }: any) => {
        let result;
        if (row?.original?.activated) {
          result = <span className="text-success">Activated</span>;
        } else {
          result = <span className="text-danger">Deactivated</span>;
        }
        return result;
      },
    },
  ];
  const deptColumn = [
    {
      accessorKey: 'code',
      header: t('departmentNumber'),
    },
    {
      accessorKey: 'deptName',
      header: t('departmentName'),
    },
    {
      accessorKey: 'parentDeptName',
      header: t('parentDepartment'),
    },
  ];

  handleEditOpen = (val: any): void => {
    const update = {
      ...val,
      statisticsVisitors: val?.statisticsVisitors ? ['0'] : [],
      messageNotification: val?.messageNotification ? ['0'] : [],
      sendReport: val?.sendReport ? ['0'] : [],
    };
    setModalView({
      title: t('edit'),
      content: <AddMusterPoint closeModal={() => setModalOpen(false)} update={update} />,
    });
    setModalOpen(true);
  };

  const fetchData = (params: DepartmentParamsProps) => {
    if (status === 'success') {
      dispatch(fetchMusterPoint({ ...params }));
    }
  };

  const getFilterData = (filter: any): any => {
    if (filter.pageIndex > 0) {
      dispatch(setDeptSearchFilter(filter));
      fetchData({
        pageNo: filter.pageIndex,
        pageSize: filter.pageSize,
        ...filter.filter,
      });
    }
  };

  // level functions
  const fetchLevels = (params: LevelProps) => {
    if (params.accMusterPointId) {
      dispatch(fetchDeptList({ ...params }));
    }
  };

  const getSelected = (id: string[]) => {
    setSelectedValues(id);
    fetchLevels({ accMusterPointId: id?.[0], pageNo: 1 });
  };

  const getFilterLevels = (filter: any): any => {
    if (filter.pageIndex > 0) {
      dispatch(setSearchFilter(filter));
      fetchLevels({
        pageNo: filter.pageIndex,
        pageSize: filter.pageSize,
        ...filter.filter,
      });
    }
  };

  const deleteDept = (params: DeleteLevelProps) => {
    const req = {
      musterPointId: selectedValues?.[0],
      musterPointDeptIds: params,
    };
    musterPoint
      .deleteDept(req)
      .then((response) => {
        if (response.data.success) {
          dispatch(
            setNotificationError({
              error: t('departmentDeletedSuccessfully'),
              status: 200,
            }),
          );
          dispatch(deleteDepartment(params.id));
          setModalOpen(false);
          fetchLevels({
            accMusterPointId: selectedValues[0],
            pageNo: deptSearchFilter?.pageIndex || 1,
            pageSize: deptSearchFilter?.pageSize || 10,
          });
        } else {
          dispatch(
            setNotificationError({
              error: response.data.msg,
              status: response.data.code || 500,
            }),
          );
          setModalOpen(false);
        }
      })
      .catch((error) => {
        dispatch(
          setNotificationError({
            error: error.message,
            status: error.status,
          }),
        );
        setModalOpen(false);
      });
  };

  const handleActivate = async (type: string) => {
    const req = {
      ids: selectedValues.toString(),
    };
    try {
      const response =
        type === 'activate' ? await musterPoint.activate(req) : await musterPoint.cancel(req);
      if (response.data.success) {
        dispatch(
          setNotificationError({
            error: response.data.msg,
            status: 200,
          }),
        );
        setModalOpen(false);
        fetchData({ pageNo: 1, pageSize: 10, ...searchFilter.filter });
      } else {
        dispatch(
          setNotificationError({
            error: response.data.message,
            status: response.data.code,
          }),
        );
        setModalOpen(false);
      }
    } catch (error: any) {
      dispatch(
        setNotificationError({
          error: error.message,
          status: error.status,
        }),
      );
      setModalOpen(false);
    }
  };

  const moreActionButton = [
    {
      title: t('activate'),
      enable: checkPermission('acc:musterPoint:activate', loadMenus),
      clickFunction: (): void => {
        if (selectedValues.length > 0) {
          setModalView({
            title: t('activate'),
            content: (
              <ConformationBox
                closeModal={() => setModalOpen(false)}
                okFunction={() => handleActivate('activate')}
                title={t('performTheActivateOperation')}
                buttonLabel={t('ok')}
                cancelTrue
              />
            ),
          });
          setModalOpen(true);
        } else {
          setModalView({
            title: t('selectObject'),
            content: (
              <ConformationBox
                closeModal={() => setModalOpen(false)}
                okFunction={() => setModalOpen(false)}
                title={t('pleaseSelectTheObjectsYouWantToOperate')}
                buttonLabel={t('ok')}
              />
            ),
          });
          setModalOpen(true);
        }
      },
    },
    {
      title: t('cancel'),
      enable: checkPermission('acc:musterPoint:cancel', loadMenus),
      clickFunction: (): void => {
        if (selectedValues.length > 0) {
          setModalView({
            title: t('cancel'),
            content: (
              <ConformationBox
                closeModal={() => setModalOpen(false)}
                okFunction={() => handleActivate('cancel')}
                title={t('performTheCancelOperation')}
                buttonLabel={t('ok')}
              />
            ),
          });
          setModalOpen(true);
        } else {
          setModalView({
            title: t('selectObject'),
            content: (
              <ConformationBox
                closeModal={() => setModalOpen(false)}
                okFunction={() => setModalOpen(false)}
                title={t('pleaseSelectTheObjectsYouWantToOperate')}
                buttonLabel={t('ok')}
              />
            ),
          });
          setModalOpen(true);
        }
      },
    },
  ];
  const deletePoint = (params: DeleteLevelProps) => {
    const req = {
      ids: params,
      pin: params.pin,
    };
    musterPoint
      .deletePoint(req)
      .then((response) => {
        if (response.data.success) {
          dispatch(
            setNotificationError({
              error: t('musterPointDeletedSuccessfully'),
              status: 200,
            }),
          );
          setModalOpen(false);
          fetchData({
            pageNo: searchFilter.pageIndex,
            pageSize: searchFilter.pageSize,
          });
        } else {
          dispatch(
            setNotificationError({
              error: response.data.message,
              status: response.data.code,
            }),
          );
          setModalOpen(false);
        }
      })
      .catch((error) => {
        dispatch(
          setNotificationError({
            error: error.message,
            status: error.status,
          }),
        );
        setModalOpen(false);
      });
  };

  // const onConfirmReset = (zoneId: string) => {
  //   const req = {
  //     personLimitZoneId: zoneId,
  //   };
  //   personAvailability
  //     .resetPersonLimitZone(req)
  //     .then((response) => {
  //       if (response.data.success) {
  //         dispatch(
  //           setNotificationError({
  //             error: response.data.msg,
  //             status: 200,
  //           }),
  //         );
  //         setModalOpen(false);
  //         fetchPersonAvailability({
  //           pageNo: 1,
  //         });
  //       } else {
  //         dispatch(
  //           setNotificationError({
  //             error: response.data.message,
  //             status: response.data.code,
  //           }),
  //         );
  //         setModalOpen(false);
  //       }
  //     })
  //     .catch((error) => {
  //       dispatch(
  //         setNotificationError({
  //           error: error.message,
  //           status: error.status,
  //         }),
  //       );
  //       setModalOpen(false);
  //     });
  // };
  const handleDeleteClick = () => {
    if (selectedPointFullData.length === 0) {
      dispatch(
        setNotificationError({
          error: t('pleaseSelectAtLeastOneLevel'), // Also fixed the error message
          status: 400,
        }),
      );
      return;
    }

    setModalOpen(true);
    setModalView({
      title: t('delete'),
      content: (
        <DeleteModalBox
          closeModal={() => setModalOpen(false)}
          id={selectedPointFullData.join(',')}
          okFunction={deletePoint}
          isMultiSelect
        />
      ),
    });
  };
  const actionButtons = [
    {
      label: t('edit'),
      function: (val: any) => handleEditOpen(val),
      enable: checkPermission('acc:musterPoint:add', loadMenus),
    },
    {
      label: t('delete'),
      enable: true,
      disabled: true,
      function: (val: any) => {
        setModalOpen(true);
        setModalView({
          title: t('delete'),
          content: (
            <DeleteModalBox
              closeModal={() => setModalOpen(false)}
              id={val.id}
              okFunction={deletePoint}
            />
          ),
        });
      },
    },
    {
      label: t('addDepartment'),
      enable: checkPermission('acc:musterPoint:addDept', loadMenus),
      function: (val: any): void => {
        setModalView({
          title: t('addDepartment'),
          content: <AddDepartment closeModal={() => setModalOpen(false)} id={val.id} />,
        });
        setModalOpen(true);
      },
    },
  ];
  const handleDeleteDeptClick = () => {
    if (selectedDeptFullData.length === 0) {
      dispatch(
        setNotificationError({
          error: t('pleaseSelectAtLeastOneDoor'),
          status: 400,
        }),
      );
      return;
    }
    setModalOpen(true);
    setModalView({
      title: t('delete'),
      content: (
        <DeleteModalBox
          closeModal={() => setModalOpen(false)}
          id={selectedDeptFullData.map((val) => val.id).join(',')}
          okFunction={deleteDept}
          isMultiSelect
        />
      ),
    });
  };
  const deptActions = [
    {
      label: t('delete'),
      enable: checkPermission('acc:musterPoint:delDept', loadMenus),
      function: (val: any) => {
        setModalOpen(true);
        setModalView({
          title: t('delete'),
          content: (
            <DeleteModalBox
              closeModal={() => setModalOpen(false)}
              id={val.id}
              okFunction={deleteDept}
            />
          ),
        });
      },
    },
  ];

  return (
    <div className="access-by-level">
      <ModalBox
        status={modalOpen}
        closeModal={() => setModalOpen(false)}
        title={modalView.title}
        content={modalView.content}
      />
      <TopHeader
        title={t('access')}
        broadCram={[
          {
            title: t('advancedFunctions'),
          },
          {
            title: t('musterPoint'),
          },
        ]}
      />
      <CardBox
        header={t('musterPoint')}
        title={t('listOfPoint')}
        rightSide={
          <div className="delete-icon">
            <CardRightButtonGroup
              actions={moreActionButton}
              buttonLabel={t('new')}
              buttonIcon={<AddIcon color="#ffffff" />}
              buttonClass="btn-primary btn-sm"
              buttonStatus
              buttonFunction={(): void => {
                setModalOpen(true);
                setModalView({
                  title: t('new'),
                  content: <AddMusterPoint closeModal={() => setModalOpen(false)} />,
                });
              }}
              buttonEnable={checkPermission('acc:musterPoint:addDept', loadMenus)}
              // Add delete button configuration
              DeleteButtonIcon={<DeleteButtonIcon />}
              deleteButtonLabel={t('delete')}
              deleteButtonStatus
              showDeleteButton
              deleteButtonFunction={handleDeleteClick}
              deleteButtonEnable
              deleteButtonClass="multi-delete-button"
            />
          </div>
        }>
        <DataTable
          actionButtonStatus={!modalOpen}
          action={actionButtons}
          columns={columnsField}
          getFilterData={getFilterLevels}
          assignFilterValue={searchFilter}
          assignSelectedData={selectedValues}
          getSelectedData={(val: any): any => {
            getSelected(val); // Set selected cards
            setSelectedPointFullData(val);
          }}
          checkBoxActive
          singleRowSelection
          enablePagination
          isFetching={status === 'loading'}
          values={pointList}
          fixedColumn={['name']}
          totalRecords={totalDataCount}
          height={411}
        />
        <hr className="line" />
        <div className="browse-table">
          <h4>{t('department')}</h4>
          <div className="door-header">
            <div className="header">
              <span className="sub-title">{t('listOfDepartment')}</span>
            </div>
            <div className="delete-icon">
              <CardRightButtonGroup
                DeleteButtonIcon={<DeleteButtonIcon />}
                deleteButtonLabel={t('delete')}
                deleteButtonStatus
                showDeleteButton
                deleteButtonFunction={handleDeleteDeptClick}
                deleteButtonEnable={checkPermission('acc:musterPoint:delDept', loadMenus)}
                deleteButtonClass="multi-delete-button"
              />
            </div>
          </div>
          <DataTable
            actionButtonStatus={!modalOpen}
            action={deptActions}
            columns={deptColumn}
            getFilterData={getFilterData}
            assignFilterValue={deptSearchFilter}
            assignSelectedData={personnelSelect}
            getSelectedData={(val: any): any => setPersonnelSelect(val)}
            getSelectedFullData={(val: any[]): any => {
              setSelectedDeptFullData(val);
            }}
            checkBoxActive
            enablePagination
            isFetching={deptStatus === 'loading'}
            values={departments}
            fixedColumn={['id']}
            totalRecords={deptTotalDataCount}
            height={411}
          />
        </div>
      </CardBox>
    </div>
  );
}

export default MusterPoint;
