import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useAppDispatch } from '../../../../../redux/store';
import { setNotificationError } from '../../../../../redux/slices/NotificationSlices';
import InputField from '../../../../../shared-components/input-field/input-field';
import FormRightButton from '../../../../../shared-components/form-right-buttons/FormRightButton';
import { fetchApiAuthorization } from '../../../../../redux/slices/system-management/APIAuthorization';
import SelectBoxField from '../../../../../shared-components/selectbox-field/selectbox-field';
import clientRegister from '../../../../../utils/api/SystemManagement/clientRegister';

interface AddClientRegisterProp {
  id: string;
  clientType: string;
  registrationCode: string;
  name: string;
}

interface InterlockGroupType {
  closeModal: () => void;
  data?: AddClientRegisterProp;
}

function AddClientRegister({ closeModal, data }: InterlockGroupType) {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const [value, setValue] = useState<AddClientRegisterProp>({
    id: data?.id || '',
    name: data?.name || '',
    clientType: data?.clientType || '',
    registrationCode: data?.registrationCode || '',
  });
  const [clientTypeList, setClientTypeList] = useState([]);
  const [disabled, setDisabled] = useState(false);

  const onChangeValue = async (name: string, values: any) => {
    setValue((prev: AddClientRegisterProp) => ({
      ...prev,
      [name]: values,
    }));
  };

  const submit = () => {
    const request = {
      id: value.id,
      clientType: value.clientType,
      registrationCode: value.registrationCode,
    };
    clientRegister
      .saveList(request)
      .then((response) => {
        if (response?.data) {
          const message = data
            ? t('clientRegisterUpdatedSuccessfully')
            : t('clientRegisterAddedSuccessfully');
          dispatch(
            setNotificationError({
              error: message,
              status: 200,
            }),
          );
          setDisabled(false);
          dispatch(fetchApiAuthorization({ pageNo: 1 }));
          closeModal();
        } else {
          dispatch(
            setNotificationError({
              error: response.data.msg,
              status: response.data.code,
            }),
          );
        }
      })
      .catch((error) => {
        dispatch(
          setNotificationError({
            error: error.message,
            status: error.status,
          }),
        );
      });
  };

  const fetchClientList = async () => {
    try {
      const response = await clientRegister.getClientList();
      const formattedData = response.data.data.map(
        (item: { text: string; value: string | number }) => ({
          label: item.text,
          id: item.value,
        }),
      );

      setClientTypeList(formattedData);
    } catch (error: any) {
      let errorMessage = t('errorFetchingData');
      if (error.response) {
        errorMessage = error.response.data?.msg || errorMessage;
      } else if (error instanceof Error) {
        errorMessage = error.message || errorMessage;
      }

      dispatch(
        setNotificationError({ error: errorMessage, status: error.response?.status || 500 }),
      );
    }
  };
  useEffect(() => {
    fetchClientList();
  }, []);

  return (
    <div className="add-client">
      <div className="client-filed">
        <SelectBoxField
          id="clientType"
          name="clientType"
          label="Client Type"
          value={value.clientType}
          onChangeFunction={onChangeValue}
          data={clientTypeList}
        />
      </div>
      <div className="client-filed">
        <InputField
          id="registrationCode"
          name="registrationCode"
          label="Registration Code"
          type="text"
          value={value.registrationCode}
          onChangeFunction={onChangeValue}
          disabled
        />
      </div>
      <FormRightButton
        cancelLabel={t('cancel')}
        cancelFunction={() => closeModal()}
        submitLabel={t('done')}
        submitFunction={submit}
        disabled={disabled}
      />
    </div>
  );
}

AddClientRegister.defaultProps = {
  data: {
    CertificatesName: '',
  },
};

export default AddClientRegister;
