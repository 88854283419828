import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';

// Use Translation
import { useTranslation } from 'react-i18next';

// Use interface
import { SideBarSessionInterFace, SubmenuInterFace } from '../../interface/common.interface';
import {
  AccControlReportIcon,
  AccessDeviceIcon,
  AccessRuleIcon,
  AdvancedFuncIcon,
  AuthorityIcon,
  CardHandIcon,
  CommunicationIcon,
  DownArrowIcon,
  PersonIcon,
  SystemIcon,
  ThirdPartyIcon,
} from '../../assets/svgicons/svgicon';

// use assets image files
import logo from '../../assets/images/logo.png';
import logo1 from '../../assets/images/logo1.png';
import { checkPermission } from '../../utils/role-permission';
import { useAppSelector } from '../../redux/store';

function SidebarSession({
  menus,
  redirect,
  activeValue,
  openDropDown,
  enable,
}: SideBarSessionInterFace) {
  const { Icon, RightIcon, label, active, children } = menus;
  const pathUrl = window.location.pathname;
  const pathSegment = pathUrl?.split('/');
  // Extract base path without ID
  const basePath = pathSegment.slice(0, 5).join('/');

  return (
    <div className={`sidebar-menu-session ${activeValue === active ? 'active' : ''}`}>
      <div
        className={`main-menu ${activeValue === active ? 'active' : ''} ${!enable ? 'hide-menu' : ''}`}
        onClick={() => openDropDown(active)}
        role="presentation">
        <div className="menu-icon">
          <Icon color={activeValue === active ? '#078DEE' : '#3E4246'} />
        </div>
        <div className="menu-label">{label}</div>
        <div className="menu-dropdown-icon">
          <RightIcon color={activeValue === active ? '#078DEE' : '#3E4246'} />
        </div>
      </div>
      <div className={`menu-dropdown ${activeValue === active ? 'active' : ''}`}>
        {children.map((menu: SubmenuInterFace) => (
          <Link
            to={menu.url}
            key={menu.url}
            className={`sub-menu ${basePath === menu.activeURL && 'active'}`}
            onClick={() => redirect(menu.url, active)}
            style={{ display: !menu?.enable ? 'none' : 'block' }}>
            {menu.label}
          </Link>
        ))}
      </div>
      <div className={`tab-menu ${activeValue === active ? 'active' : ''}`}>
        <div className="menu-icon">
          <Icon color={activeValue === 'active' ? '#078DEE' : '#3E4246'} />
        </div>
        <div className="menus-list">
          <div className="menu-label">{label}</div>
          {children.map((menu: SubmenuInterFace) => (
            <div
              key={menu.url}
              className={`sub-menu ${basePath === menu.activeURL && 'active'}`}
              role="presentation"
              onClick={() => redirect(menu.url, active)}>
              {menu.label}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

function Sidebar() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { loadMenus } = useAppSelector((state) => state.auth);
  const currentPath = window.location.pathname;
  const parts = currentPath.split('/');
  const slicedString = parts.slice(0, 4).join('/');

  const sideBarMenu = [
    {
      icon: PersonIcon,
      rightIcon: DownArrowIcon,
      name: t('personnel'),
      activeURL: '/admin/pers/personnel',
      enable: checkPermission('Personnel', loadMenus),
      key: 'pers',
      children: [
        {
          label: t('person'),
          enable: checkPermission('Person', loadMenus),
          url: 'pers/personnel/person',
          activeURL: '/admin/pers/personnel/person',
        },
        {
          label: t('department'),
          enable: checkPermission('Department', loadMenus),
          url: 'pers/personnel/department',
          activeURL: '/admin/pers/personnel/department',
        },
        {
          label: t('position'),
          enable: checkPermission('Position', loadMenus),
          url: 'pers/personnel/position',
          activeURL: '/admin/pers/personnel/position',
        },
        {
          label: t('dismissedPersonnel'),
          enable: checkPermission('Dismissed Personnel', loadMenus),
          url: 'pers/personnel/dismissed-personnel',
          activeURL: '/admin/pers/personnel/dismissed-personnel',
        },
        {
          label: t('pendingReview'),
          enable: checkPermission('Pending Review', loadMenus),
          url: 'pers/personnel/pending-review',
          activeURL: '/admin/pers/personnel/pending-review',
        },
        {
          label: t('customAttributes'),
          enable: checkPermission('Custom Attributes', loadMenus),
          url: 'pers/personnel/custom-attributes',
          activeURL: '/admin/pers/personnel/custom-attributes',
        },
        {
          label: t('listLibrary'),
          enable: checkPermission('List Library', loadMenus),
          url: 'pers/personnel/list-library',
          activeURL: '/admin/pers/personnel/list-library',
        },
        {
          label: t('parameters'),
          enable: checkPermission('Parameters', loadMenus),
          url: 'pers/personnel/parameters',
          activeURL: '/admin/pers/personnel/parameters',
        },
      ],
    },
    {
      icon: CardHandIcon,
      rightIcon: DownArrowIcon,
      enable: checkPermission('Card Management', loadMenus),
      name: t('cardManagement'),
      activeURL: '/admin/pers/card-management',
      key: 'pers',
      children: [
        {
          label: t('card'),
          enable: checkPermission('Card', loadMenus),
          url: 'pers/card-management/card',
          activeURL: '/admin/pers/card-management/card',
        },
        {
          label: t('wiegandFormat'),
          enable: checkPermission('Wiegand Format', loadMenus),
          url: 'pers/card-management/wiegand-format',
          activeURL: '/admin/pers/card-management/wiegand-format',
        },
        {
          label: t('issuedCardRecord'),
          enable: checkPermission('Issued Card Record', loadMenus),
          url: 'pers/card-management/issued-card-record',
          activeURL: '/admin/pers/card-management/issued-card-record',
        },
      ],
    },
    {
      icon: AccessDeviceIcon,
      rightIcon: DownArrowIcon,
      name: t('accessDevice'),
      activeURL: '/admin/acc/accessDevice',
      enable: checkPermission('Access Device', loadMenus),
      key: 'acc',
      children: [
        {
          label: t('device'),
          enable: checkPermission('Device', loadMenus),
          url: 'acc/accessDevice/device',
          activeURL: '/admin/acc/accessDevice/device',
        },
        {
          label: t('i/OBoard'),
          enable: checkPermission('I/O Board', loadMenus),
          url: 'acc/accessDevice/ioBoard',
          activeURL: '/admin/acc/accessDevice/ioBoard',
        },
        {
          label: t('door'),
          enable: checkPermission('Door', loadMenus),
          url: 'acc/accessDevice/door',
          activeURL: '/admin/acc/accessDevice/door',
        },
        {
          label: t('reader'),
          enable: checkPermission('Reader', loadMenus),
          url: 'acc/accessDevice/reader',
          activeURL: '/admin/acc/accessDevice/reader',
        },
        {
          label: t('auxiliaryInput'),
          enable: checkPermission('Auxiliary Input', loadMenus),
          url: 'acc/accessDevice/auxiliaryInput',
          activeURL: '/admin/acc/accessDevice/auxiliaryInput',
        },
        {
          label: t('auxiliaryOutput'),
          enable: checkPermission('Auxiliary Output', loadMenus),
          url: 'acc/accessDevice/auxiliaryOutput',
          activeURL: '/admin/acc/accessDevice/auxiliaryOutput',
        },
        {
          label: t('eventType'),
          enable: checkPermission('Event Type', loadMenus),
          url: 'acc/accessDevice/eventType',
          activeURL: '/admin/acc/accessDevice/eventType',
        },
        {
          label: t('daylightsavingtime'),
          enable: checkPermission('Daylight Saving Time', loadMenus),
          url: 'acc/accessDevice/daylightsavingtime',
          activeURL: '/admin/acc/accessDevice/daylightsavingtime',
        },
        {
          label: t('realTimeMonitoring'),
          enable: checkPermission('Real-Time Monitoring', loadMenus),
          url: 'acc/accessDevice/realtime-monitoring',
          activeURL: '/admin/acc/accessDevice/realtime-monitoring',
        },
        {
          label: t('alarmMonitoring'),
          enable: checkPermission('Alarm Monitoring', loadMenus),
          url: 'acc/accessDevice/alarm-monitoring',
          activeURL: '/admin/acc/accessDevice/alarm-monitoring',
        },
        {
          label: t('map'),
          enable: checkPermission('Map', loadMenus),
          url: 'acc/accessDevice/map',
          activeURL: '/admin/acc/accessDevice/map',
        },
      ],
    },
    {
      icon: AccessRuleIcon,
      rightIcon: DownArrowIcon,
      name: t('accessRule'),
      enable: checkPermission('Access Rule', loadMenus),
      activeURL: '/admin/acc/accessRule',
      key: 'acc',
      children: [
        {
          label: t('timeZones'),
          enable: checkPermission('Time Zones', loadMenus),
          url: 'acc/accessRule/timeZones',
          activeURL: '/admin/acc/accessRule/timeZones',
        },
        // {
        //   label: t('holidays'),
        //   enable: checkPermission('Holidays', loadMenus),
        //   url: 'acc/accessRule/holidays',
        //   activeURL: '/admin/acc/accessRule/holidays',
        // },
        {
          label: t('accessLevels'),
          enable: checkPermission('Access Levels', loadMenus),
          url: 'acc/accessRule/accessLevels',
          activeURL: '/admin/acc/accessRule/accessLevels',
        },
        {
          label: t('setaccessByLevels'),
          enable: checkPermission('Set Access By Levels', loadMenus),
          url: 'acc/accessRule/setaccessByLevels',
          activeURL: '/admin/acc/accessRule/setaccessByLevels',
        },
        {
          label: t('setAccessByPerson'),
          enable: checkPermission('Set Access By Person', loadMenus),
          url: 'acc/accessRule/setAccessByPerson',
          activeURL: '/admin/acc/accessRule/setAccessByPerson',
        },
        {
          label: t('setAccessByDepartment'),
          enable: checkPermission('Set Access By Department', loadMenus),
          url: 'acc/accessRule/setAccessByDepartment',
          activeURL: '/admin/acc/accessRule/setAccessByDepartment',
        },
        {
          label: t('interlock'),
          enable: checkPermission('Interlock', loadMenus),
          url: 'acc/accessRule/interlock',
          activeURL: '/admin/acc/accessRule/interlock',
        },
        {
          label: t('linkage'),
          enable: checkPermission('Linkage', loadMenus),
          url: 'acc/accessRule/linkage',
          activeURL: '/admin/acc/accessRule/linkage',
        },
        {
          label: t('antiPassback'),
          enable: checkPermission('Anti-Passback', loadMenus),
          url: 'acc/accessRule/antiPassback',
          activeURL: '/admin/acc/accessRule/antiPassback',
        },
        {
          label: t('firstPersonNormallyOpen'),
          enable: checkPermission('First-Person Normally Open', loadMenus),
          url: 'acc/accessRule/firstPersonNormallyOpen',
          activeURL: '/admin/acc/accessRule/firstPersonNormallyOpen',
        },
        {
          label: t('multiPersonGroup'),
          enable: checkPermission('Multi-Person Group', loadMenus),
          url: 'acc/accessRule/multi-person-group',
          activeURL: '/admin/acc/accessRule/multi-person-group',
        },
        {
          label: t('multiPersonOpeningDoor'),
          enable: checkPermission('Multi-Person Opening Door', loadMenus),
          url: 'acc/accessRule/multi-person-opening-door',
          activeURL: '/admin/acc/accessRule/multi-person-opening-door',
        },
        {
          label: t('verificationMode'),
          enable: checkPermission('Verification Mode', loadMenus),
          url: 'acc/accessRule/verification-mode',
          activeURL: '/admin/acc/accessRule/verification-mode',
        },
        {
          label: t('verificationModeGroup'),
          enable: checkPermission('Verification Mode Group', loadMenus),
          url: 'acc/accessRule/verification-mode-group',
          activeURL: '/admin/acc/accessRule/verification-mode-group',
        },
        {
          label: t('parameters'),
          enable: checkPermission('Parameters', loadMenus),
          url: 'acc/accessRule/parameters',
          activeURL: '/admin/acc/accessRule/parameters',
        },
      ],
    },
    {
      icon: AdvancedFuncIcon,
      rightIcon: DownArrowIcon,
      name: t('advancedFunctions'),
      activeURL: '/admin/acc/advancedFunctions',
      enable: checkPermission('Advanced Functions', loadMenus),
      key: 'acc',
      children: [
        {
          label: t('zone'),
          enable: checkPermission('Zone', loadMenus),
          url: 'acc/advancedFunctions/zone',
          activeURL: '/admin/acc/advancedFunctions/zone',
        },
        {
          label: t('readerDefine'),
          enable: checkPermission('Reader Define', loadMenus),
          url: 'acc/advancedFunctions/reader-define',
          activeURL: '/admin/acc/advancedFunctions/reader-define',
        },
        {
          label: t('whoIsInside'),
          enable: checkPermission('Who Is Inside', loadMenus),
          url: 'acc/advancedFunctions/areaHeadCount',
          activeURL: '/admin/acc/advancedFunctions/areaHeadCount',
        },
        {
          label: t('globalAntiPassback'),
          enable: checkPermission('Global Anti-Passback', loadMenus),
          url: 'acc/advancedFunctions/globalAntiPassback',
          activeURL: '/admin/acc/advancedFunctions/globalAntiPassback',
        },
        {
          label: t('globalInterlock'),
          enable: checkPermission('Global Interlock', loadMenus),
          url: 'acc/advancedFunctions/globalInterlock',
          activeURL: '/admin/acc/advancedFunctions/globalInterlock',
        },
        {
          label: t('globalLinkage'),
          enable: checkPermission('Global Linkage', loadMenus),
          url: 'acc/advancedFunctions/globalLinkage',
          activeURL: '/admin/acc/advancedFunctions/globalLinkage',
        },
        {
          label: t('globalInterlockGroup'),
          enable: checkPermission('Global Interlock Group', loadMenus),
          url: 'acc/advancedFunctions/globalInterlockGroup',
          activeURL: '/admin/acc/advancedFunctions/globalInterlockGroup',
        },
        {
          label: t('personAvailability'),
          enable: checkPermission('Person Availability', loadMenus),
          url: 'acc/advancedFunctions/person-Availability',
          activeURL: '/admin/acc/advancedFunctions/person-Availability',
        },
        {
          label: t('occupancyControl'),
          enable: checkPermission('Occupancy Control', loadMenus),
          url: 'acc/advancedFunctions/occupancyControl',
          activeURL: '/admin/acc/advancedFunctions/occupancyControl',
        },
        {
          label: t('musterPoint'),
          enable: checkPermission('Muster Point', loadMenus),
          url: 'acc/advancedFunctions/musterPoint',
          activeURL: '/admin/acc/advancedFunctions/musterPoint',
        },
        {
          label: t('musterPointReport'),
          enable: checkPermission('Muster Point Report', loadMenus),
          url: 'acc/advancedFunctions/musterPoint-Report',
          activeURL: '/admin/acc/advancedFunctions/musterPoint-Report',
        },
      ],
    },
    {
      icon: AccControlReportIcon,
      rightIcon: DownArrowIcon,
      name: t('accControlReports'),
      activeURL: '/admin/acc/accControlReports',
      enable: checkPermission('Access Control Reports', loadMenus),
      key: 'acc',
      children: [
        {
          label: t('allTransaction'),
          enable: checkPermission('All Transactions', loadMenus),
          url: 'acc/accControlReports/allTransactions',
          activeURL: '/admin/acc/accControlReports/allTransactions',
        },
        {
          label: t('eventsFromToday'),
          enable: checkPermission('Events From Today', loadMenus),
          url: 'acc/accControlReports/events-from-today',
          activeURL: '/admin/acc/accControlReports/events-from-today',
        },
        {
          label: t('allExceptionEvents'),
          enable: checkPermission('All Exception Events', loadMenus),
          url: 'acc/accControlReports/all-exception-events',
          activeURL: '/admin/acc/accControlReports/all-exception-events',
        },
        {
          label: t('alarmLog'),
          enable: checkPermission('Alarm Log', loadMenus),
          url: 'acc/accControlReports/alarm-log',
          activeURL: '/admin/acc/accControlReports/alarm-log',
        },
        {
          label: t('alarmProcessingHistory'),
          enable: checkPermission('Alarm Processing History', loadMenus),
          url: 'acc/accControlReports/alarm-processing-history',
          activeURL: '/admin/acc/accControlReports/alarm-processing-history',
        },
        {
          label: t('accessRightsByDoor'),
          enable: checkPermission('Access Rights By Door', loadMenus),
          url: 'acc/accControlReports/access-rights-by-door',
          activeURL: '/admin/acc/accControlReports/access-rights-by-door',
        },
        {
          label: t('accessRightsByPersonnel'),
          enable: checkPermission('Access Rights By Personnel', loadMenus),
          url: 'acc/accControlReports/access-rights-by-personnel',
          activeURL: '/admin/acc/accControlReports/access-rights-by-personnel',
        },
        {
          label: t('firstInLastOut'),
          enable: checkPermission('First In And Last Out', loadMenus),
          url: 'acc/accControlReports/firstIn-and-lastOut',
          activeURL: '/admin/acc/accControlReports/firstIn-and-lastOut',
        },
      ],
    },
    {
      icon: SystemIcon,
      rightIcon: DownArrowIcon,
      name: t('system'),
      activeURL: '/admin/system/system-management',
      enable: checkPermission('System Management', loadMenus),
      key: 'system',
      children: [
        {
          label: t('operationLog'),
          enable: checkPermission('Operation Log', loadMenus),
          url: 'system/system-management/operation-log',
          activeURL: '/admin/system/system-management/operation-log',
        },
        {
          label: t('databaseManagement'),
          enable: checkPermission('Data Management', loadMenus),
          url: 'system/system-management/database-management',
          activeURL: '/admin/system/system-management/database-management',
        },
        {
          label: t('areaSettings'),
          enable: checkPermission('Area Settings', loadMenus),
          url: 'system/system-management/area-settings',
          activeURL: '/admin/system/system-management/area-settings',
        },
        {
          label: t('systemParameter'),
          enable: checkPermission('Parameters', loadMenus),
          url: 'system/system-management/system-Parameter',
          activeURL: '/admin/system/system-management/system-Parameter',
        },
        {
          label: t('emailManagement'),
          enable: checkPermission('E-mail Management', loadMenus),
          url: 'system/system-management/email-management',
          activeURL: '/admin/system/system-management/email-management',
        },
        {
          label: t('dictionaryManagement'),
          enable: checkPermission('Dictionary Management', loadMenus),
          url: 'system/system-management/dictionary-management',
          activeURL: '/admin/system/system-management/dictionary-management',
        },
        {
          label: t('dataCleaning'),
          enable: checkPermission('Data Cleaning', loadMenus),
          url: 'system/system-management/data-cleaning',
          activeURL: '/admin/system/system-management/data-cleaning',
        },
        // {
        //   label: t('resourceFile'),
        //   url: 'system/system-management/resource-file',
        //   activeURL: '/admin/system/system-management/resource-file',
        // },
        // {
        //   label: t('cloudSettings'),
        //   url: 'system/system-management/cloud-settings',
        //   activeURL: '/admin/system/system-management/cloud-settings',
        // },
        {
          label: t('certificateType'),
          enable: checkPermission('Certificate Type', loadMenus),
          url: 'system/system-management/certificate-type',
          activeURL: '/admin/system/system-management/certificate-type',
        },
        {
          label: t('systemMonitoring'),
          enable: checkPermission('System Monitoring', loadMenus),
          url: 'system/system-management/system-monitoring',
          activeURL: '/admin/system/system-management/system-monitoring',
        },
        {
          label: t('parameters'),
          enable: checkPermission('Parameters', loadMenus),
          url: 'system/system-management/system-management-parameters',
          activeURL: '/admin/system/system-management/system-management-parameters',
        },
      ],
    },
    {
      icon: AuthorityIcon,
      rightIcon: DownArrowIcon,
      name: t('authorityManagement'),
      activeURL: '/admin/system/authority-management',
      enable: checkPermission('Authority Management', loadMenus),
      key: 'system',
      children: [
        {
          label: t('user'),
          enable: checkPermission('User', loadMenus),
          url: 'system/authority-management/user',
          activeURL: '/admin/system/authority-management/user',
        },
        {
          label: t('role'),
          enable: checkPermission('Role', loadMenus),
          url: 'system/authority-management/role',
          activeURL: '/admin/system/authority-management/role',
        },
        {
          label: t('apiAuthorization'),
          enable: checkPermission('API Authorization', loadMenus),
          url: 'system/authority-management/api-authorization',
          activeURL: '/admin/system/authority-management/api-authorization',
        },
        {
          label: t('clientRegister'),
          enable: checkPermission('Client Register', loadMenus),
          url: 'system/authority-management/client-register',
          activeURL: '/admin/system/authority-management/client-register',
        },
        {
          label: t('securityParameters'),
          enable: checkPermission('Security Parameters', loadMenus),
          url: 'system/authority-management/security-parameters',
          activeURL: '/admin/system/authority-management/security-parameters',
        },
      ],
    },
    {
      icon: CommunicationIcon,
      rightIcon: DownArrowIcon,
      name: t('communicationManagement'),
      activeURL: '/admin/system/communication-management',
      enable: checkPermission('Communication management', loadMenus),
      key: 'system',
      children: [
        {
          label: t('deviceCommand'),
          enable: checkPermission('Communication Device', loadMenus),
          url: 'system/communication-management/device-command',
          activeURL: '/admin/system/communication-management/device-command',
        },
        {
          label: t('communicationDevice'),
          enable: checkPermission('Communication Device', loadMenus),
          url: 'system/communication-management/communication-device',
          activeURL: '/admin/system/communication-management/communication-device',
        },
        {
          label: t('authorizedDevice'),
          enable: checkPermission('Authorized device', loadMenus),
          url: 'system/communication-management/authorized-device',
          activeURL: '/admin/system/communication-management/authorized-device',
        },
        {
          label: t('communicationMonitor'),
          enable: checkPermission('Communication Monitor', loadMenus),
          url: 'system/communication-management/communication-monitor',
          activeURL: '/admin/system/communication-management/communication-monitor',
        },
      ],
    },
    {
      icon: ThirdPartyIcon,
      rightIcon: DownArrowIcon,
      name: t('thirdPartyIntegration'),
      activeURL: '/admin/system/third-party-integration',
      enable: true,
      key: 'system',
      children: [
        {
          label: t('videoChannel'),
          url: 'system/third-party-integration/video-channel',
          enable: true,
          activeURL: '/admin/system/third-party-integration/video-channel',
        },
        {
          label: t('adManagement'),
          url: 'system/third-party-integration/ad-management',
          enable: true,
          activeURL: '/admin/system/third-party-integration/ad-management',
        },
      ],
    },
  ];

  const [activeValue, setActive] = useState(slicedString);

  const openDropDown = (key: string) => {
    setActive((prev: any) => (prev === key ? '' : key));
  };

  const redirect = (url: string): void => {
    navigate(url);
  };

  // Filter the sidebar items to only include those key and split words matched
  const filterMenu = sideBarMenu.filter((menu) => menu?.key === parts?.[2]);
  useEffect(() => {
    if (slicedString) {
      setActive(slicedString);
    }
  }, [slicedString]);
  return (
    <div className="sidebar-container">
      <div className="header-logo">
        <div className="image">
          <img src={logo} alt="logo_image" />
        </div>
        <div className="image">
          <img src={logo1} alt="logo_image_1" />
        </div>
      </div>
      <div className="sidebar-content">
        <div className="sidebar-menus">
          {filterMenu.map((sidebar) => {
            // console.log(sidebar);
            return (
              <SidebarSession
                key={sidebar.name}
                redirect={redirect}
                activeValue={activeValue}
                openDropDown={openDropDown}
                enable={sidebar.enable}
                menus={{
                  Icon: sidebar.icon,
                  RightIcon: sidebar.rightIcon,
                  label: sidebar.name,
                  active: sidebar.activeURL,
                  children: sidebar.children,
                }}
              />
            );
          })}
        </div>
      </div>
    </div>
  );
}

export default Sidebar;
