import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import InputField from '../../../../shared-components/input-field/input-field';
import SelectBoxField from '../../../../shared-components/selectbox-field/selectbox-field';
import Button from '../../../../shared-components/button/button';
import InputTextAreaField from '../../../../shared-components/input-text-area-field/input-text-area-field';
import Label from '../../../../shared-components/label/Label';
import CheckBoxInputField from '../../../../shared-components/checkbox-input-field/checkbox-input-field';
import { useAppDispatch, useAppSelector } from '../../../../redux/store';
import globalAntiPassBack from '../../../../utils/api/AdvancedFunctions/globalAntiPassBack';
import { setNotificationError } from '../../../../redux/slices/NotificationSlices';
import {
  fetchGlobalAntiPassBackList,
  fetchGlobalApbZone,
} from '../../../../redux/slices/GlobalAntipassbackSlices';
import readerDefine from '../../../../utils/api/AdvancedFunctions/readerDefine';
import ModalBox from '../../../../shared-components/modal-box/modal-box';
import ConformationBox from '../../../../shared-components/conformation-box/conformation-box';
import BgVerificationOptions from '../../../devices/components/bg-verification-option';

interface AddProps {
  equals: boolean;
  id: string;
  name: string;
  zoneName: string;
  apbType: number;
  lockoutDuration: number;
  resetTime: string;
  applyTo: number;
  isResetApb?: null[] | null;
  zoneId: string;
}

interface AntiPassbackProps {
  closeModal: () => void;
  update?: AddProps;
}

export const abpType = [
  {
    label: 'Logical Anti-Passback',
    id: '1',
  },
  {
    label: 'Time Anti-Passback',
    id: '2',
  },
  {
    label: 'Timed Logical Anti-Passback',
    id: '3',
  },
];

function AddAntiPassBack({ closeModal, update }: AntiPassbackProps) {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { globalApbZone } = useAppSelector((state: any) => state.globalAntiPassBack);
  const [values, setValues] = useState<any>(
    update || {
      name: '',
      zoneId: '',
      zoneDoors: [],
      apbType: '1',
      lockoutDuration: '',
      isResetApb: [],
      resetTime: '',
      applyTo: '0',
    },
  );
  const [disable, setDisable] = useState(false);
  const [zoneValidation, setZoneValidation] = useState(false);
  const [checkExistName, setCheckExistName] = useState(true);
  const hours = Array.from({ length: 24 }, (_, i) => `${i}`);
  const hourOptions = hours.map((hour: string) => ({
    label: hour,
    id: hour,
    value: hour,
  }));
  const [modalOpen, setModalOpen] = useState(false);
  const [modalView, setModalView] = useState({
    title: '',
    content: <>hi</>,
  });

  const getApbDoors = async (val: string, isUpdate: string = '') => {
    try {
      const req = {
        accZoneId: val,
      };
      if (!isUpdate) {
        const response1 = await globalAntiPassBack.validApb();
        const response = await globalAntiPassBack.validEntranceApb();
        const judgeRes = await globalAntiPassBack.judge(req);
        if (response1.data.data && response.data.data && !isUpdate) {
          setZoneValidation(!judgeRes.data);
        }
      }
      const zoneRes = await globalAntiPassBack.getAccDoorByZone(req);

      if (zoneRes.data.success) {
        setValues((prev: any) => ({
          ...prev,
          zoneDoors: zoneRes.data.data,
        }));
      }
    } catch (error: any) {
      dispatch(
        setNotificationError({
          error: error.message,
          status: error.status,
        }),
      );
    }
  };

  const onChangeValue = async (name: string, value: any) => {
    setValues((prev: any) => ({
      ...prev,
      [name]: value,
    }));
    if (name === 'zoneId') {
      getApbDoors(value);
    }
    if (name === 'apbType') {
      if (value === '2' || value === '3') {
        setValues((prev: any) => ({
          ...prev,
          lockoutDuration: '10',
        }));
      } else {
        setValues((prev: any) => ({
          ...prev,
          lockoutDuration: '',
        }));
      }
    }
  };

  const onBlurName = async (name: string, value: any) => {
    if (name === 'name' && value !== '' && value !== update?.name) {
      const responseData = await globalAntiPassBack.isExistName({ name: value });
      setCheckExistName(responseData?.data);
    }
  };

  const saveAntiPassBack = async () => {
    setDisable(true);
    try {
      const req = {
        apbType: values?.apbType,
        applyTo: values?.applyTo,
        isResetApb: values?.isResetApb?.length > 0 ? '1' : '0',
        lockoutDuration: values?.lockoutDuration,
        name: values?.name,
        resetTime: values?.resetTime,
        zoneDoors: values?.zoneDoors,
        zoneId: values?.zoneId,
      };
      const response1 = await globalAntiPassBack.save(req);
      if (response1.data.success) {
        dispatch(
          setNotificationError({
            error: response1.data.msg,
            status: 200,
          }),
        );
        setDisable(false);
        closeModal();
        dispatch(fetchGlobalAntiPassBackList({ pageNo: 1, pageSize: 10 }));
      }
    } catch (error: any) {
      dispatch(
        setNotificationError({
          error: error.message,
          status: error.status,
        }),
      );
    }
  };

  const handleBgVerification = (devId: string) => {
    setModalOpen(true);
    setModalView({
      title: t('Set Bg - Verification Options'),
      content: <BgVerificationOptions closeModal={() => setModalOpen(false)} devId={devId} />,
    });
  };

  const submit = async () => {
    try {
      const req = {
        opType: 'accGlobalApb',
        zoneId: values?.zoneId,
      };
      const response1 = await readerDefine.checkDevIssueVerify(req);
      /**
       * Confirmation box should be check if the device is
       * @checkDevIssueVerify is valid or not
       */
      if (response1) {
        setModalView({
          title: t('reset'),
          content: (
            <ConformationBox
              closeModal={() => saveAntiPassBack()}
              okFunction={() => handleBgVerification(response1.data.data)}
              title={t('checkDevIssueVerify')}
              buttonLabel={t('ok')}
              cancelTrue
            />
          ),
        });
        setModalOpen(true);
      }
    } catch (error: any) {
      dispatch(
        setNotificationError({
          error: error.message,
          status: error.status,
        }),
      );
    }
  };

  useEffect(() => {
    if (values?.name && values?.zoneId && values?.apbType && !zoneValidation) {
      setDisable(false);
    } else {
      setDisable(true);
    }
  }, [values]);

  useEffect(() => {
    dispatch(fetchGlobalApbZone({}));
  }, []);

  useEffect(() => {
    if (update && Object.keys(update).length > 0) {
      getApbDoors(update?.zoneId, 'update');
      setValues(update);
    }
  }, [update]);

  const zoneList =
    values?.zoneDoors?.length > 0
      ? values?.zoneDoors.map((item: any) => Object.values(item).join('\n')).join('\n')
      : 'None';

  return (
    <div className="add-global-antiPassBack">
      <ModalBox
        status={modalOpen}
        closeModal={() => setModalOpen(false)}
        title={modalView.title}
        content={modalView.content}
      />
      <div className="form-row">
        <div className="flex-row">
          <InputField
            id="name"
            name="name"
            label={t('name')}
            type="text"
            value={values.name}
            onChangeFunction={onChangeValue}
            onBlurFunction={onBlurName}
            errorStatus={values.name !== '' && !checkExistName}
            message={t('objectIsAlreadyExist')}
            required
          />
          <SelectBoxField
            id="zoneId"
            name="zoneId"
            label={t('zone')}
            value={values.zoneId}
            onChangeFunction={(name, value) => onChangeValue(name, value.id)}
            data={globalApbZone}
            required
            disabled={update && Object.keys(update).length > 0}
          />
        </div>
        <div className="flex-row column controlZone">
          <Label title={t('theFollowingDoorsControlAccessInAndOutOfTheZone')} />
          <InputTextAreaField
            id="zoneDoors"
            value={zoneList}
            label=""
            name="zoneDoors"
            placeHolder=""
            rows={4}
            onChangeFunction={onChangeValue}
            errorStatus={zoneValidation}
            message="A single door cannot be used to control two relatively independent perimeter boundaries."
            disabled
          />
        </div>
        <SelectBoxField
          id="apbType"
          name="apbType"
          label={t('antiPassBackType')}
          value={values?.apbType}
          onChangeFunction={(name, value) => onChangeValue(name, value.id)}
          data={abpType}
          required
        />
        <InputField
          id="lockoutDuration"
          name="lockoutDuration"
          label={t('lockoutDuration')}
          type="number"
          value={values.lockoutDuration}
          onChangeFunction={onChangeValue}
          validation
          min={0}
          max={500}
          message="minute(1-500)"
          disabled={values.apbType === '1'}
        />
        <CheckBoxInputField
          name="isResetApb"
          data={[
            {
              label: 'Reset Anti-Passback Status',
              id: '0',
              value: '0',
            },
          ]}
          value={values?.isResetApb}
          onChangeFunction={onChangeValue}
          alignment="row"
        />
        <SelectBoxField
          id="resetTime"
          name="resetTime"
          label={t('antiPassbackTimeReset')}
          value={values.resetTime}
          onChangeFunction={(name, value) => onChangeValue(name, value.id)}
          data={hourOptions}
          disabled={!values?.isResetApb?.[0]}
        />
        <SelectBoxField
          id="applyTo"
          name="applyTo"
          label={t('applyTo')}
          value={values.applyTo}
          onChangeFunction={(name, value) => onChangeValue(name, value.id)}
          data={[
            {
              label: 'All Personnel',
              id: '0',
            },
            {
              label: 'Just Selected Personnel',
              id: '1',
            },
            {
              label: 'Exclude Selected Personnel',
              id: '2',
            },
          ]}
        />
      </div>

      <div className="form-buttons-right">
        <Button
          onClickFunction={() => {
            closeModal();
          }}
          title={t('cancel')}
          className="btn-outline-primary"
        />
        <Button
          onClickFunction={submit}
          title={t('done')}
          className="btn-primary"
          disabled={disable}
        />
      </div>
    </div>
  );
}

AddAntiPassBack.defaultProps = {
  update: {},
};

export default AddAntiPassBack;
