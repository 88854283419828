import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Button from '../../../shared-components/button/button';
import SelectBoxField from '../../../shared-components/selectbox-field/selectbox-field';
import RadioButtonField from '../../../shared-components/radio-button-field/radio-button-field';
import { LoadingProgressCount, sortValuesByStatus } from '../../../utils/helpers';
import deviceApi from '../../../utils/api/device';
import { useAppDispatch } from '../../../redux/store';
import { setNotificationError } from '../../../redux/slices/NotificationSlices';
import SpinnerLoader from '../../../shared-components/spinnerLoader/spinner-loader';

interface HtmlContentProps {
  htmlString: string;
}

function HtmlContent({ htmlString }: HtmlContentProps): JSX.Element {
  return (
    <div
      // eslint-disable-next-line react/no-danger
      dangerouslySetInnerHTML={{
        __html: htmlString,
      }}
    />
  );
}

function BgVerificationOptions({ closeModal, selectedValues, devId }: any) {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const [showUpgrade, setShowUpgrade] = useState(true);
  const [isDisable, setIsDisable] = useState(false);
  const [progress, setProgress] = useState(0);
  const [loading, setLoading] = useState<string>('');
  const [responseMsg, setResponseMsg] = useState('');
  const [values, setValues] = useState({
    bgVerification: 0,
    deviceOffline: 0,
    onlineStatus: 'on',
  });
  const [skipRes, setSkipRes] = useState('');
  /**
   * @sortValuesByStatus - Helper to sort devices based on their offline/disabled status
   */
  const selectedDevices = selectedValues?.sort(sortValuesByStatus);
  const [deviceData, setDeviceData] = useState(selectedDevices);

  const onChangeValue = (name: string, value: any) => {
    setValues((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const skip = async () => {
    try {
      const response = await deviceApi.skip();
      if (response) {
        setSkipRes(response.data.progressContent);
        return response.data.progressContent;
      }
      return null;
    } catch (error: any) {
      setLoading('0');
      dispatch(
        setNotificationError({
          error: error.message,
          status: error.response?.status || 500,
        }),
      );
      return null;
    }
  };

  const submit = async () => {
    setIsDisable(true);
    setLoading('1');
    const stopProgress = LoadingProgressCount(0, 25, 100, setProgress, () => {});
    const deviceId = selectedValues?.map((item: any) => item.id).toString() || devId;
    try {
      const req = {
        devIds: deviceId,
      };
      const response = await deviceApi.checkCombOpenDoor(req);
      if (response?.data?.success) {
        const response1 = await deviceApi.checkVerifyParam({ verifyParam: values?.bgVerification });
        if (response1?.data?.success) {
          const isOnlineRes = await deviceApi.synchronizeTime(deviceId);
          setResponseMsg(isOnlineRes.data.success);
          if (isOnlineRes?.data?.success) {
            setIsDisable(false);
            skip();
            setTimeout(() => {
              stopProgress();
              setLoading('0');
            }, 3500);
          }
        } else {
          dispatch(
            setNotificationError({
              error: response?.data?.msg,
              status: response?.data?.code || 500,
            }),
          );
        }
      } else {
        dispatch(
          setNotificationError({
            error: response?.data?.msg,
            status: response?.data?.code || 500,
          }),
        );
      }
    } catch (error: any) {
      setIsDisable(false);
      setLoading('0');
      dispatch(
        setNotificationError({
          error: error.message,
          status: error.response?.status || 500,
        }),
      );
    }
  };

  // if the inputs disabled based on the status is online or offline
  const isOnline = deviceData?.map((device: any) => device?.status)?.includes('online');
  useEffect(() => {
    if (!devId) {
      if (isOnline) {
        setIsDisable(false);
      } else {
        setIsDisable(true);
      }
    }
  }, []);

  useEffect(() => {
    if (devId) {
      deviceApi
        ?.getDevIdsByIssueBgValid({ ids: devId })
        .then((response) => {
          const result = [response?.data?.data].map((item) => {
            let status: string;
            if (item?.offlineDevName) {
              status = 'offline';
            } else if (item?.disabledDevName) {
              status = 'disabled';
            } else {
              status = 'online';
            }
            return {
              status,
              name: item?.alias,
            };
          });
          setDeviceData(result);
          setIsDisable(false);
        })
        .catch((error) => {
          dispatch(
            setNotificationError({
              error: error.message,
              status: error.status,
            }),
          );
        });
    }
  }, [devId]);

  return (
    <div className="device-setup-popup">
      <div className="row">
        <div className="column-1">
          <h3 className="title">{t('selectedDevice')}</h3>
          <div className="box">
            {deviceData?.map(
              (device: any) =>
                device?.status === 'online' && (
                  <RadioButtonField
                    label=""
                    name="onlineStatus"
                    alignment="column"
                    data={[
                      {
                        label: t('The devices which have disabled background verification'),
                        id: 'on',
                        value: 'on',
                      },
                    ]}
                    value="on"
                    onChangeFunction={() => {}}
                  />
                ),
            )}
            {deviceData?.filter((device: any) => device?.status === 'offline').length > 0 && (
              <div>
                <h4 className="text-warning">{t('theOfflineDevicesCanNotBeOperated')}</h4>
                <span className="text-warning">
                  {deviceData
                    ?.filter((item: any) => item?.status === 'offline')
                    ?.map((item: any) => item?.name)
                    ?.join(', ')}
                </span>
              </div>
            )}
            {deviceData?.filter((device: any) => device?.status === 'disable').length > 0 && (
              <div>
                <h4 className="text-warning">{t('theDisabledDevicesCanNotBeOperated')}</h4>
                <span className="text-warning">
                  {deviceData
                    ?.filter((item: any) => item?.status === 'disable')
                    ?.map((item: any) => item?.name)
                    ?.join(', ')}
                </span>
              </div>
            )}
          </div>
          <div className="verification-options">
            <h4>{t('setBg-VerificationOptions')}</h4>
            <div className="input-row">
              <SelectBoxField
                name="bgVerification"
                label={t('backgroundVerification')}
                id="bgVerification"
                value={values?.bgVerification}
                onChangeFunction={(name, value) => onChangeValue(name, value.id)}
                data={[
                  { label: t('enable'), id: 1 },
                  { label: t('disable'), id: 0 },
                ]}
                className=""
                disabled={!isOnline}
              />
              <SelectBoxField
                name="deviceOffline"
                label={t('ifTheDeiceIsOffline')}
                id="deviceOffline"
                value={values?.deviceOffline}
                onChangeFunction={(name, value) => onChangeValue(name, value.id)}
                data={[
                  { label: t('standardAccessLevel'), id: 0 },
                  { label: t('accessDenied'), id: 1 },
                ]}
                className=""
                disabled={!isOnline}
              />
            </div>
          </div>
        </div>
        <div className="column-1">
          <div className="upgrading-content">
            <div className="left">
              <Button
                onClickFunction={() => setShowUpgrade(!showUpgrade)}
                title={showUpgrade ? t('hideInfo') : t('detailedInfo')}
                className="btn-primary"
              />
            </div>
            <div className="right">
              {loading && (
                <div className="search-loader">
                  {loading === '1' && <span className="count">{progress}%</span>}
                  {loading === '1' && <SpinnerLoader />}
                </div>
              )}
              <div className="form-buttons-right">
                <Button
                  onClickFunction={submit}
                  title={t('start')}
                  className={`btn-primary ${loading === '1' ? 'loading' : ''} `}
                  disabled={isDisable}
                />
                <Button
                  onClickFunction={() => {
                    closeModal();
                  }}
                  title={t('close')}
                  className="btn-default"
                />
              </div>
            </div>
          </div>
          {showUpgrade && (
            <div className="box">
              {loading === '0' && responseMsg && <HtmlContent htmlString={skipRes} />}
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default BgVerificationOptions;
