import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
// Import shared components
import { useAppSelector, useAppDispatch } from '../../redux/store';
import TopHeader from '../../shared-components/top-header/top-header';
import CardBox from '../../shared-components/card/card';
import DataTable from '../../shared-components/data-table/data-table';
import ModalBox from '../../shared-components/modal-box/modal-box';
import CardRightButtonGroup from '../../shared-components/card-right-button-group/card-right-button-group';
import DeleteModalBox from '../../shared-components/delete-modal-box/delete-modal-box';
import { AddIcon, DeleteButtonIcon } from '../../assets/svgicons/svgicon';
import AddInterlock from './components/AddInterlock';
import { setNotificationError } from '../../redux/slices/NotificationSlices';
import interlockApi from '../../utils/api/AccessRule/interlock';
import {
  fetchInterlock,
  setSearchFilter,
  deleteInterlock,
} from '../../redux/slices/InterlockSlices';
import { checkPermission } from '../../utils/role-permission';

interface DeleteDepartProps {
  id: string;
}

function Interlock() {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { interlock, status, totalDataCount, searchFilter } = useAppSelector(
    (state: any) => state.interlock,
  );

  /**
   * Selects the `loadMenus` permission from the authentication slice of the Redux store.
   *
   * @returns {Object} loadMenus - The user's menu permissions.
   */
  const { loadMenus } = useAppSelector((state) => state.auth);

  const [modalOpen, setModalOpen] = useState(false);
  const [modalView, setModalView] = useState({
    title: '',
    content: <>hi</>,
  });
  const fetchData = (params: any) => {
    if (status === 'success') {
      dispatch(fetchInterlock({ ...params }));
    }
  };
  const [selectedValues, setSelectedValues] = useState<string[]>([]);
  const [selectedInterlockFullData, setSelectedInterlockFullData] = useState<any[]>([]);

  let handleEditOpen: any;
  const canEdit = checkPermission('acc:interlock:edit', loadMenus) || true;
  const columnsField = [
    {
      accessorKey: 'deviceName',
      header: t('deviceName'),
      Cell: ({ row }: any) => (
        <div
          className={canEdit ? 'list-hyperlink' : ''}
          role="presentation"
          onClick={(event) => {
            if (!canEdit) return;
            event.stopPropagation(); // Prevent row selection
            handleEditOpen(row.original); // Call your edit function
          }}>
          {row?.original?.deviceName}
        </div>
      ),
    },
    {
      accessorKey: 'interlockRuleShow',
      header: t('interlockRule'),
    },
  ];

  const getFilterData = (filter: any): any => {
    if (filter.pageIndex > 0) {
      dispatch(setSearchFilter(filter));
      fetchData({ pageNo: filter.pageIndex, pageSize: filter.pageSize, ...filter.filter });
    }
  };

  handleEditOpen = (editData: any) => {
    const updateData = {
      ...editData,
      id: editData?.id,
      name: editData?.deviceName,
      deviceId: editData?.deviceId,
      interlockRuleShow: String(editData?.interlockRule),
    };

    setModalOpen(true);
    setModalView({
      title: t('edit'),
      content: <AddInterlock closeModal={() => setModalOpen(false)} data={{ ...updateData }} />,
    });
  };
  const deleteRecord = (params: DeleteDepartProps) => {
    const req = {
      ids: params,
    };
    interlockApi
      .deleteInterlock(req)
      .then((response) => {
        if (response.data.success) {
          dispatch(
            setNotificationError({
              error: t('interlockDeletedSuccessfully'),

              status: 200,
            }),
          );
          dispatch(deleteInterlock(params));
          fetchData({
            pageNo: searchFilter?.pageIndex,
            pageSize: searchFilter?.pageSize,
          });
          setModalOpen(false);
        } else {
          dispatch(
            setNotificationError({
              error: response.data.message,
              status: response.data.code,
            }),
          );
        }
      })
      .catch((error) => {
        dispatch(
          setNotificationError({
            error: error.message,
            status: error.response?.status || 500,
          }),
        );
      });
  };
  const handleInterlockClick = () => {
    if (selectedInterlockFullData.length === 0) {
      dispatch(
        setNotificationError({
          error: t('pleaseSelectAtLeastOneInterlock'), // Also fixed the error message
          status: 400,
        }),
      );
      return;
    }

    setModalOpen(true);
    setModalView({
      title: t('delete'),
      content: (
        <DeleteModalBox
          closeModal={() => setModalOpen(false)}
          id={selectedInterlockFullData.map((val) => val.id).join(',')}
          okFunction={deleteRecord}
          isMultiSelect
        />
      ),
    });
  };
  const actionButtons = [
    {
      label: t('edit'),
      enable: checkPermission('acc:interlock:edit', loadMenus),
      function: (val: any) => handleEditOpen(val),
    },
    {
      label: t('delete'),
      enable: checkPermission('acc:interlock:del', loadMenus),
      function: (val: any) => {
        setModalOpen(true);
        setModalView({
          title: t('delete'),
          content: (
            <DeleteModalBox
              closeModal={() => setModalOpen(false)}
              id={val.id}
              okFunction={deleteRecord}
            />
          ),
        });
      },
    },
  ];

  return (
    <div className="access-by-level">
      <ModalBox
        status={modalOpen}
        closeModal={() => setModalOpen(false)}
        title={modalView.title}
        content={modalView.content}
      />
      <TopHeader
        title={t('access')}
        broadCram={[
          {
            title: t('accessRule'),
          },
          {
            title: t('interlock'),
          },
        ]}
      />
      <CardBox
        title={t('listOfDevices')}
        rightSide={
          <div className="delete-icon">
            <CardRightButtonGroup
              buttonIcon={<AddIcon color="#ffffff" />}
              buttonLabel={t('new')}
              buttonClass="btn-primary btn-sm"
              buttonStatus
              buttonFunction={(): void => {
                setModalOpen(true);
                setModalView({
                  title: t('new'),
                  content: <AddInterlock closeModal={() => setModalOpen(false)} />,
                });
              }}
              buttonEnable={checkPermission('acc:interlock:add', loadMenus)}
              // Add delete button configuration
              DeleteButtonIcon={<DeleteButtonIcon />}
              deleteButtonLabel={t('delete')}
              showDeleteButton
              deleteButtonFunction={handleInterlockClick}
              deleteButtonEnable={checkPermission('acc:interlock:del', loadMenus)}
              deleteButtonClass="multi-delete-button"
            />
          </div>
        }>
        {' '}
        <DataTable
          actionButtonStatus={!modalOpen} // Disable action buttons when modal is open
          action={actionButtons}
          columns={columnsField}
          getFilterData={getFilterData}
          assignFilterValue={searchFilter}
          assignSelectedData={selectedValues}
          getSelectedData={(val: any): any => setSelectedValues(val)} // Set selected cards
          getSelectedFullData={(val: any): any => {
            setSelectedInterlockFullData(val);
          }}
          checkBoxActive
          enablePagination
          // isFetching={status === 'loading'} // Placeholder status
          values={interlock} // Use the actual data here
          fixedColumn={['name']}
          totalRecords={totalDataCount}
          height={411}
        />
      </CardBox>
    </div>
  );
}

export default Interlock;
