import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';
// import { useAppDispatch, useAppSelector } from '../../../../../redux/store';
import CheckBoxInputField from '../../../../../shared-components/checkbox-input-field/checkbox-input-field';
import { AttentionIcon } from '../../../../../assets/svgicons/svgicon';
import Button from '../../../../../shared-components/button/button';
import InputDateTime from '../../../../../shared-components/input-time-date/input-time-date';
import SelectBoxField from '../../../../../shared-components/selectbox-field/selectbox-field';
import { useAppDispatch } from '../../../../../redux/store';
import { setNotificationError } from '../../../../../redux/slices/NotificationSlices';
import databaseManagement from '../../../../../utils/api/SystemManagement/databaseManagement';

interface DbBackupInfo {
  lastdbBackupTime: string;
  backupday: number;
  backupmin: number;
  backupsed: number;
  nextdbBackupTime: string;
  backuphour: number;
}

interface StateProps {
  dbBackupRemote: string[];
  dbBackupIntervalTime: string;
  editPage?: boolean;
  dbBackupStartTime?: string;
  dbBackupIsLinux?: string;
  dbBackup?: DbBackupInfo;
}

interface ModalType {
  closeModal: () => void;
}

function BackupSchedule({ closeModal }: ModalType) {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const [values, setValues] = useState<StateProps>({
    dbBackupStartTime: '',
    dbBackupRemote: [],
    dbBackupIntervalTime: '',
  });
  const handleChange = (name: string, value: any) => {
    setValues((prev) => ({
      ...prev,
      [name]: value,
    }));
  };
  const changeDateTime = (dateValue: any, types: string) => {
    setValues((prev: any) => ({
      ...prev,
      [types]: dayjs(dateValue),
    }));
  };

  const handleAcceptDate = (dateValue: any, types: string) => {
    setValues((prev: any) => ({
      ...prev,
      [types]: dayjs(dateValue),
    }));
  };

  const getTimes = async () => {
    try {
      const response = await databaseManagement.getStartTime();

      if (response?.data.success) {
        setValues((prev) => ({
          ...prev,
          ...response?.data?.data,
        }));
      } else {
        dispatch(
          setNotificationError({
            error: response.data.msg,
            status: response.data.code || 500,
          }),
        );
      }
    } catch (error: any) {
      dispatch(
        setNotificationError({
          error: error.message,
          status: error.status,
        }),
      );
    }
  };
  const submit = async () => {
    if (!values.dbBackupIntervalTime || !values.dbBackupStartTime) {
      dispatch(
        setNotificationError({
          error: 'Interval time and start time are required.',
          status: 400,
        }),
      );
      return;
    }

    const formattedStartTime = dayjs(values.dbBackupStartTime).format('YYYY-MM-DD HH:mm:ss');
    const params = {
      intervaltime: String(values.dbBackupIntervalTime),
      startTime: formattedStartTime,
    };

    try {
      const response = await databaseManagement.planBackup(params);
      if (response?.data?.success) {
        closeModal();
      } else {
        dispatch(
          setNotificationError({
            error: response?.data?.msg || 'Failed to schedule backup',
            status: response?.data?.code || 500,
          }),
        );
      }
    } catch (error: any) {
      dispatch(
        setNotificationError({
          error: error.message || 'An error occurred while scheduling the backup',
          status: error.status || 500,
        }),
      );
    }
  };

  useEffect(() => {
    getTimes();
  }, []);

  return (
    <div className="backup-schedule flex-row column">
      <div className="input-row">
        <InputDateTime
          label={t('from')}
          value={values.dbBackupStartTime}
          onChangeFunction={(val) => changeDateTime(val, 'dbBackupStartTime')}
          okFunction={(val) => handleAcceptDate(val, 'dbBackupStartTime')}
          monthFormat={false}
          ampm={false}
        />
        <SelectBoxField
          name="dbBackupIntervalTime"
          label={t('byDay')}
          id="dbBackupIntervalTime"
          value={values.dbBackupIntervalTime}
          onChangeFunction={(name, value) => handleChange(name, value.id)}
          data={[
            { label: '-----', id: '' },
            { label: t('1'), id: '1' },
            { label: t('3'), id: '3' },
            { label: t('7'), id: '7' },
            { label: t('30'), id: '30' },
          ]}
          className=""
        />
      </div>
      <div className="messages">
        <p className="">The last backup time: {values?.dbBackup?.lastdbBackupTime}</p>
        <p>
          The next backup time: {values?.dbBackup?.nextdbBackupTime}, left{' '}
          {values?.dbBackup?.backupday} day {values?.dbBackup?.backuphour} hour{' '}
          {values?.dbBackup?.backupmin} minute {values?.dbBackup?.backupsed} second.
        </p>
      </div>
      <CheckBoxInputField
        name="dbBackupRemote"
        data={[
          {
            label: t('backUpToFTPServerAtTheSameTime'),
            id: '1',
            value: '1',
          },
        ]}
        value={values?.dbBackupRemote}
        onChangeFunction={handleChange}
      />
      <div className="text-danger flex-row center">
        <AttentionIcon />
        <span className="error-msg">{t('warningClickCheckBoxManualFAQ')}</span>
      </div>
      <div className="form-buttons-right">
        <Button onClickFunction={() => closeModal()} title={t('cancel')} className="btn-default" />
        <Button
          onClickFunction={submit}
          title={t('done')}
          className="btn-primary"
          // disabled={disabled}
        />
      </div>
    </div>
  );
}

export default BackupSchedule;
