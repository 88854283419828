import React, { useEffect, useState } from 'react';

// Use common modules
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../redux/store';

// Use own components
import StatisticsModal from './components/statistics';
import ChangeDepartment from './components/change-department';
import ChangePosition from './components/change-position';
import Dismissal from './components/dismissal';
import ExportBiometricTemplate from './components/export-biometric-template';
import ExportDismissal from './components/export-dismissal';
import ImportPersonnel from './components/import-personnel';
import ImportBiometricTemplate from './components/import-biometric-template';
import ImportPersonnelPhoto from './components/import-personnel-photo';
import ImportDismissions from './components/import-dismission';
import DownloadPersonnelImportTemplate from './components/download-personnel-import-template';
import PrintCard from './components/print-card';
// Use api's calls
import personApis from '../../utils/api/person';

// Use redux functions
import {
  fetchPersonsList,
  fetchVerifyCode,
  setSearchFilter,
  removeSinglePerson,
  deletePerson,
} from '../../redux/slices/PersonSlices';
import { setNotificationError } from '../../redux/slices/NotificationSlices';
import { fetchDismissalPersonsList } from '../../redux/slices/DismissalPersonSlices';

// Use shared components
import TopHeader from '../../shared-components/top-header/top-header';
import CardBox from '../../shared-components/card/card';
import DataTable from '../../shared-components/data-table/data-table';
import CardRightButtonGroup from '../../shared-components/card-right-button-group/card-right-button-group';
import ModalBox from '../../shared-components/modal-box/modal-box';
import ExportData from '../../shared-components/export-data/export-data';
import DeleteModalBox from '../../shared-components/delete-modal-box/delete-modal-box';
import TabModalBox from '../../shared-components/tab-modal-box/tab-modal-box';
import ConformationBox from '../../shared-components/conformation-box/conformation-box';
import ImportData from '../../shared-components/import-data/import-data';

// Use assets svg icons
import {
  AddIcon,
  CheckCircleIcon,
  CloseCircleIcon,
  DeleteButtonIcon,
} from '../../assets/svgicons/svgicon';

// Use interface
import { PersonValue, DeleteLevelProps } from '../../interface/personnel.interface';
import { checkPermission } from '../../utils/role-permission';
import MultiSelectBoxField from '../../shared-components/multi-selectbox-field/multi-selectbox-field';

function Person() {
  const { t } = useTranslation();
  const navigator = useNavigate();
  const dispatch = useAppDispatch();

  const { persons, status, totalDataCount, searchFilter } = useAppSelector(
    (state: any) => state.persons,
  );
  const { loadMenus } = useAppSelector((state) => state.auth);
  let handleEditOpen: any;

  // Verification mode options
  const verificationModes: any[] = [
    { id: 'card', value: '1000', label: t('card') },
    { id: 'fingerprint', value: '1', label: t('fingerprint') },
    { id: 'palmVein', value: '8', label: t('palmVein') },
    { id: 'fingerVein', value: '7', label: t('fingerVein') },
    { id: 'nirFace', value: '2', label: t('nearInfraredFaceTemplate') },
    { id: 'visFace', value: '9', label: t('visibleFaceTemplate') },
    { id: 'visFacePhoto', value: '1001', label: t('visibleFacePhoto') },
    { id: 'visLightPalm', value: '10', label: t('visibleLightPalm') },
    { id: 'noCard', value: 'N1000', label: t('doesNotIncludeCard') },
    { id: 'noFingerprint', value: 'N1', label: t('doesNotIncludeFingerprint') },
    { id: 'noPalmVein', value: 'N8', label: t('doesNotIncludePalmVein') },
    { id: 'noFingerVein', value: 'N7', label: t('doesNotIncludeFingerVein') },
    { id: 'nonIrFace', value: 'N2', label: t('doesNotIncludeInfraredFaceTemplate') },
    { id: 'noVisFace', value: 'N9', label: t('doesNotIncludeVisibleFaceTemplate') },
    { id: 'noVisFacePhoto', value: 'N1001', label: t('doesNotIncludeVisibleFacePhoto') },
    { id: 'noVisLightPalm', value: 'N10', label: t('doesNotIncludeVisibleLightPalm') },
  ];
  // Selected verification modes state
  const [selectedVerificationModes, setSelectedVerificationModes] = useState([]);

  const columnsField = [
    {
      accessorKey: 'pin',
      header: t('personnelId'),
      enableHiding: false,
      Cell: ({ row }: any) =>
        checkPermission('pers:person:edit', loadMenus) ? (
          <div
            className="list-hyperlink"
            role="presentation"
            onClick={(event) => {
              event.stopPropagation(); // Prevent row selection
              handleEditOpen(row.original); // Call your edit function
            }}>
            {row?.original?.pin}
          </div>
        ) : (
          <div>{row?.original?.pin}</div>
        ),
    },
    {
      accessorKey: 'name',
      header: t('firstName'),
      enableHiding: false,
    },
    {
      accessorKey: 'lastName',
      header: t('lastName'),
      enableHiding: false,
    },
    {
      accessorKey: 'enabledCredential',
      header: t('enable'),
      visibleInShowHideMenu: false,
      Cell: ({ row }: any) => {
        const value = row.original?.enabledCredential ? (
          <CheckCircleIcon color="#48d726de" />
        ) : (
          <CloseCircleIcon color="#f00" />
        );
        return value;
      },
    },
    {
      accessorKey: 'appAuthorization',
      header: t('appLogin'),
      visibleInShowHideMenu: false,
      Cell: ({ cell }: any) => {
        const value = cell.getValue() ? (
          <CheckCircleIcon color="#48d726de" />
        ) : (
          <CloseCircleIcon color="#f00" />
        );
        return value;
      },
    },
    {
      accessorKey: 'deptName',
      header: t('departmentName'),
      enableHiding: false,
    },
    {
      accessorKey: 'deptCode',
      header: t('departmentNumber'),
      enableHiding: false,
    },
    {
      accessorKey: 'verifyMode',
      header: t('verificationMode'),
    },
    {
      accessorKey: 'createTime',
      header: t('createTime'),
    },
    {
      accessorKey: 'gender',
      header: t('gender'),
    },
    {
      accessorKey: 'birthday',
      header: t('birthday'),
    },
    {
      accessorKey: 'mobilePhone',
      header: t('mobilePhone'),
    },
    {
      accessorKey: 'cardNo',
      header: t('cardNumber'),
    },
    {
      accessorKey: 'licensePlate',
      header: t('licensePlate'),
    },
    {
      accessorKey: 'email',
      header: t('email'),
    },
    {
      accessorKey: 'certType',
      header: t('certificateType'),
    },
    {
      accessorKey: 'certNumber',
      header: t('certificateNumber'),
    },
    {
      accessorKey: 'positionName',
      header: t('positionName'),
    },
    {
      accessorKey: 'position',
      header: t('positionNumber'),
    },
    {
      accessorKey: 'hireDate',
      header: t('hireDate'),
    },
    {
      accessorKey: 'buildingName',
      header: t('buildingName'),
    },
    {
      accessorKey: 'unitName',
      header: t('unitName'),
    },
    {
      accessorKey: 'roomNumber',
      header: t('roomNumber'),
    },
    {
      accessorKey: 'verification',
      header: t('verification'),
    },
  ];
  const [modalOpen, setModalOpen] = useState(false);
  const [tabModalOpen, setTabModalOpen] = useState(false);
  const [selectedPerson, setSelectedPerson] = useState<any[]>([]);
  const [selectedPersonFullData, setSelectedPersonFullData] = useState<any[]>([]);
  const [modalView, setModalView] = useState({
    title: t('addNewPerson'),
    content: <>hi</>,
  });
  const [tabModalView, setTabModalView] = useState({
    title: '',
    content: [
      {
        title: '',
        key: '',
        content: <>hi</>,
        enable: true,
      },
    ],
    index: 0,
  });

  const fetchData = async (params: any) => {
    if (status === 'success') {
      dispatch(fetchPersonsList({ ...params }));
    }
  };
  const fetchVerifyData = async (params: any) => {
    if (status === 'success') {
      dispatch(fetchVerifyCode({ ...params }));
    }
  };

  const handleVerificationModeChange = (name: any, selectedItems: any) => {
    // Get previous state to check if we're clearing selections
    const hadPreviousSelections = selectedVerificationModes && selectedVerificationModes.length > 0;

    // Extract values and filter out any undefined/null values
    const selectedValues = selectedItems
      .map((item: any) => (typeof item === 'object' ? item.value : item))
      .filter((value: any) => value !== null && value !== undefined && value !== '');

    // Check if we're clearing all selections
    const isClearing = hadPreviousSelections && selectedValues.length === 0;

    // Only join if we have values
    const verifyModePayload = selectedValues.length > 0 ? selectedValues.join(',') : null;

    // Update local state
    setSelectedVerificationModes(selectedItems);

    // Create a clean updated filter object
    const updatedFilter: any = { ...searchFilter.filter };

    // Only add verifyMode if we have a valid payload
    if (verifyModePayload) {
      updatedFilter.verifyMode = verificationModes
        .filter((item: any) => selectedValues.includes(item.id))
        .map((item: any) => item.value)
        .join(',');

      // Call fetchVerifyData when values are selected
      fetchVerifyData({
        pageNo: 1,
        pageSize: 10,
        ...updatedFilter,
      });
    } else {
      // Remove the property when no values
      delete updatedFilter.verifyMode;

      if (isClearing) {
        fetchData({
          pageNo: 1,
          pageSize: 10,
          ...updatedFilter,
        });
      }
    }

    // Dispatch updated filters
    dispatch(
      setSearchFilter({
        ...searchFilter,
        filter: updatedFilter,
      }),
    );
  };

  const deleteRecord = (params: DeleteLevelProps) => {
    const req = {
      ids: params,
    };
    personApis
      .deletePerson(req)
      .then((response) => {
        if (response.data.code === 0) {
          dispatch(
            setNotificationError({
              error: t('personDeletedSuccessfully'),
              status: 200,
            }),
          );
          dispatch(deletePerson(params));
          setSelectedPerson([]);
          setModalOpen(false);
          fetchData({ pageNo: 1, pageSize: 10, ...searchFilter.filter });
        } else {
          dispatch(
            setNotificationError({
              error: response.data.msg,
              status: response.data.code || 500,
            }),
          );
        }
      })
      .catch((error) => {
        dispatch(
          setNotificationError({
            error: error?.response?.data?.msg,
            status: error.response?.status || 500,
          }),
        );
      });
  };

  const actionButtons = [
    {
      label: t('edit'),
      function: (val: any) => handleEditOpen(val),
      enable: checkPermission('pers:person:edit', loadMenus),
    },
    {
      label: t('delete'),
      enable: checkPermission('pers:person:del', loadMenus),
      function: (val: any) => {
        setModalOpen(true);
        setModalView({
          title: t('deletePerson'),
          content: (
            <DeleteModalBox
              closeModal={() => setModalOpen(false)}
              id={val.person_id}
              okFunction={deleteRecord}
            />
          ),
        });
      },
    },
  ];

  const exportData = (data: any) => {
    console.log(data);
    setModalOpen(false);
  };

  const selfLoginPassword = () => {
    const personIds = persons
      .filter((val: PersonValue) => selectedPerson.includes(val.id))
      .map((val: PersonValue) => val.person_id);
    const idsArray = personIds;
    const request = idsArray.join('&ids=');
    personApis
      .resetSelfPwd(request)
      .then((response) => {
        if (response.data.success) {
          // Handle successful response
          dispatch(
            setNotificationError({
              error: t('resetSelfloginPasswordUpdatedSuccessfully'),
              status: 200, // Success status code
            }),
          );
          setModalOpen(false);
          setSelectedPerson([]);
        } else {
          // Handle specific error response
          dispatch(
            setNotificationError({
              error: response.data.message,
              status: response.data.code, // Error status code
            }),
          );
        }
      })
      .catch((error) => {
        dispatch(
          setNotificationError({
            error: error.message,
            status: error.response?.status || 500, // Fallback to 500 if status is not available
          }),
        );
        setSelectedPerson([]);
      });
  };
  const submitDismissalFunction = () => {
    dispatch(
      setNotificationError({
        error: t('dismissalPersonnelAddedSuccessfully'),
        status: 200,
      }),
    );
    fetchData({ pageNo: 1, pageSize: 10, ...searchFilter.filter });
    setSelectedPerson([]);
    dispatch(fetchDismissalPersonsList({ pageNo: 1, pageSize: 10 }));
    setTabModalOpen(false);
  };
  const submitChangeDepartmentFunction = () => {
    dispatch(
      setNotificationError({
        error: t('departmentChangedSuccessfully'),
        status: 200,
      }),
    );
    fetchData({ pageNo: 1, pageSize: 10, ...searchFilter.filter });
    setSelectedPerson([]);
    setTabModalOpen(false);
  };
  const submitChangePositionFunction = () => {
    dispatch(
      setNotificationError({
        error: t('positionChangedSuccessfully'),
        status: 200,
      }),
    );
    fetchData({ pageNo: 1, pageSize: 10, ...searchFilter.filter });
    setSelectedPerson([]);
    setTabModalOpen(false);
  };

  const submitPrintCard = (data: any) => {
    console.log(data);
  };

  const enableDisablePersons = (data: string) => {
    const personIds = persons
      .filter((val: PersonValue) => selectedPerson.includes(val.id))
      .map((val: PersonValue) => val.person_id);
    const request = personIds.join('&ids=');
    let apiCall;
    let message: string;
    if (data === 'disable') {
      apiCall = personApis.disablePersons(request);
      message = t('personDisabledSuccessfully');
    } else if (data === 'enable') {
      apiCall = personApis.enablePersons(request);
      message = t('personEnabledSuccessfully');
    } else if (data === 'app-login-enable') {
      apiCall = personApis.enableAppLogin(request);
      message = t('personAppLoginEnabledSuccessfully');
    } else {
      apiCall = personApis.disableAppLogin(request);
      message = t('personAppLoginDisableSuccessfully');
    }
    apiCall
      .then((response) => {
        if (response.data.success) {
          // Handle successful response
          dispatch(
            setNotificationError({
              error: message,
              status: 200, // Success status code
            }),
          );
          fetchData({ pageNo: 1, pageSize: 10, ...searchFilter.filter });
          setModalOpen(false);
          setSelectedPerson([]);
        } else {
          // Handle specific error response
          dispatch(
            setNotificationError({
              error: response.data.message,
              status: response.data.code, // Error status code
            }),
          );
        }
      })
      .catch((error) => {
        dispatch(
          setNotificationError({
            error: error.message,
            status: error.response?.status || 500, // Fallback to 500 if status is not available
          }),
        );
      });
  };

  handleEditOpen = (editData: any) => {
    navigator(`edit/${editData?.person_id}`);
  };

  // import content items form
  const importContentItems = [
    {
      title: t('importPersonnel'),
      enable: checkPermission('pers:person:import', loadMenus),
      content: (
        <ImportPersonnel closeModal={() => setTabModalOpen(false)} submitFunction={exportData} />
      ),
      key: 'importPersonnel',
    },
    {
      title: t('importBiometricTemplate'),
      enable: checkPermission('pers:bioTemplate:import', loadMenus),
      content: (
        <ImportBiometricTemplate
          closeModal={() => setTabModalOpen(false)}
          submitFunction={exportData}
        />
      ),
      key: 'importBiometricTemplate',
    },
    {
      title: t('importPersonnelPhoto'),
      enable: checkPermission('pers:person:importPhoto', loadMenus),
      content: (
        <ImportPersonnelPhoto
          closeModal={() => setTabModalOpen(false)}
          submitFunction={exportData}
        />
      ),
      key: 'importPersonnelPhoto',
    },
    {
      title: t('importDismissions'),
      enable: checkPermission('pers:person:importLeavePerson', loadMenus),
      content: (
        <ImportDismissions closeModal={() => setTabModalOpen(false)} submitFunction={exportData} />
      ),
      key: 'importDismissions',
    },
    {
      title: t('downloadPersonnelImportTemplate'),
      enable: checkPermission('pers:person:exportTemplate', loadMenus),
      content: (
        <DownloadPersonnelImportTemplate
          closeModal={() => setTabModalOpen(false)}
          submitFunction={exportData}
        />
      ),
      key: 'downloadPersonnelImportTemplate',
    },
    {
      title: t('downloadDismissionImportTemplate'),
      enable: checkPermission('pers:person:exportLeavePersonTemplate', loadMenus),
      content: <ImportData closeModal={() => setTabModalOpen(false)} submitFunction={exportData} />,
      key: 'downloadDismissionImportTemplate',
    },
  ];

  // Check if any child component has permission
  const isImportParentEnabled = importContentItems.some((item) => item.enable);

  // export content items form
  const exportContentItems = [
    {
      title: t('exportPersonnel'),
      enable: checkPermission('pers:person:export', loadMenus),
      content: <ExportData closeModal={() => setTabModalOpen(false)} submitFunction={exportData} />,
      key: 'exportPersonnel',
    },
    {
      title: t('exportBiometricTemplate'),
      enable: checkPermission('pers:bioTemplate:export', loadMenus),
      content: (
        <ExportBiometricTemplate
          closeModal={() => setTabModalOpen(false)}
          submitFunction={exportData}
        />
      ),
      key: 'exportBiometricTemplate',
    },
    {
      title: t('exportPersonnelPhoto'),
      enable: checkPermission('pers:person:exportPhoto', loadMenus),
      content: (
        <ExportDismissal closeModal={() => setTabModalOpen(false)} submitFunction={exportData} />
      ),
      key: 'dismissal',
    },
  ];
  const handleDeleteClick = () => {
    if (selectedPersonFullData.length === 0) {
      dispatch(
        setNotificationError({
          error: t('pleaseSelectAtLeastOnePerson'), // Also fixed the error message
          status: 400,
        }),
      );
      return;
    }

    setModalOpen(true);
    setModalView({
      title: t('delete'),
      content: (
        <DeleteModalBox
          closeModal={() => setModalOpen(false)}
          id={selectedPersonFullData.map((person) => person.person_id).join(',')}
          okFunction={deleteRecord}
          isMultiSelect
        />
      ),
    });
  };
  // Check if any child component has permission
  const isExportParentEnabled = exportContentItems.some((item) => item.enable);

  const moreActionButton = [
    {
      title: t('personalAdjustment'),
      enable: checkPermission('pers:person:deptChange', loadMenus),
      clickFunction: (): void => {
        const personId = selectedPersonFullData?.map((id) => id?.person_id);

        if (selectedPerson.length > 0) {
          setTabModalView({
            title: t('personalAdjustment'),
            content: [
              {
                title: t('changeDepartment'),
                enable: checkPermission('pers:person:deptChange', loadMenus),
                content: (
                  <ChangeDepartment
                    closeModal={() => setTabModalOpen(false)}
                    selectedPerson={selectedPersonFullData?.map((val: any) => val.person_id)}
                    selectedPersonFullData={selectedPersonFullData}
                    submitFunction={submitChangeDepartmentFunction}
                  />
                ),
                key: 'changeDepartment',
              },
              {
                title: t('changePosition'),
                enable: checkPermission('pers:person:batchPositionChange', loadMenus),
                content: (
                  <ChangePosition
                    closeModal={() => setTabModalOpen(false)}
                    selectedPerson={selectedPersonFullData?.map((val: any) => val.person_id)}
                    selectedPersonFullData={selectedPersonFullData}
                    submitFunction={submitChangePositionFunction}
                  />
                ),
                key: 'changePosition',
              },
              {
                title: t('dismissal'),
                enable: checkPermission('pers:person:leave', loadMenus),
                content: (
                  <Dismissal
                    closeModal={() => setTabModalOpen(false)}
                    selectedPerson={personId}
                    submitFunction={submitDismissalFunction}
                  />
                ),
                key: 'dismissal',
              },
            ],
            index: 0,
          });
          setTabModalOpen(true);
        } else {
          // Show an error notification when no person is selected
          dispatch(
            setNotificationError({
              error: t('pleaseSelectAtLeastOnePerson'),
              status: 400, // Error status code
            }),
          );
        }
      },
    },
    {
      title: t('export'),
      enable: isExportParentEnabled,
      clickFunction: (): void => {
        setTabModalView({
          title: t('export'),
          content: exportContentItems,
          index: 0,
        });
        setTabModalOpen(true);
      },
    },
    {
      title: t('import'),
      enable: isImportParentEnabled,
      clickFunction: (): void => {
        setTabModalView({
          title: t('import'),
          content: importContentItems,
          index: 0,
        });
        setTabModalOpen(true);
      },
    },
    {
      title: t('statistics'),
      enable: checkPermission('pers:person:dataCount', loadMenus),
      clickFunction: (): void => {
        setModalOpen(true);
        setModalView({
          title: t('statistics'),
          content: <StatisticsModal closeModal={() => setModalOpen(false)} />,
        });
      },
    },
    {
      title: t('printCard'),
      enable: checkPermission('pers:person:cardPrint', loadMenus),
      clickFunction: (): void => {
        if (selectedPerson.length > 0) {
          setModalOpen(true);
          setModalView({
            title: t('printCard'),
            content: (
              <PrintCard
                closeModal={() => setModalOpen(false)}
                personIds={selectedPerson}
                submitFunction={submitPrintCard}
              />
            ),
          });
        } else {
          // Show an error notification when no person is selected
          dispatch(
            setNotificationError({
              error: t('pleaseSelectAtLeastOnePerson'),
              status: 400, // Error status code
            }),
          );
        }
      },
    },
    {
      title: t('enablePersons'),
      enable: checkPermission('pers:person:enabled', loadMenus),
      clickFunction: (): void => {
        if (selectedPerson.length > 0) {
          setModalOpen(true);
          setModalView({
            title: t('enablePersons'),
            content: (
              <ConformationBox
                closeModal={() => setModalOpen(false)}
                title={t('areYouWantToEnableThisPersons')}
                buttonLabel={t('ok')}
                okFunction={() => enableDisablePersons('enable')}
              />
            ),
          });
        } else {
          // Show an error notification when no person is selected
          dispatch(
            setNotificationError({
              error: t('pleaseSelectAtLeastOnePerson'),
              status: 400, // Error status code
            }),
          );
        }
      },
    },
    {
      title: t('disablePersons'),
      enable: checkPermission('pers:person:disable', loadMenus),
      clickFunction: (): void => {
        if (selectedPerson.length > 0) {
          setModalOpen(true);
          setModalView({
            title: t('disablePersons'),
            content: (
              <ConformationBox
                closeModal={() => setModalOpen(false)}
                title={t('areYouWantToDisableThisPersons')}
                buttonLabel={t('ok')}
                okFunction={() => enableDisablePersons('disable')}
              />
            ),
          });
        } else {
          // Show an error notification when no person is selected
          dispatch(
            setNotificationError({
              error: t('pleaseSelectAtLeastOnePerson'),
              status: 400, // Error status code
            }),
          );
        }
      },
    },
    {
      title: t('enableAppLogin'),
      enable: checkPermission('pers:person:enabledApplogin', loadMenus),
      clickFunction: (): void => {
        if (selectedPerson.length > 0) {
          setModalOpen(true);
          setModalView({
            title: t('enableAppLogin'),
            content: (
              <ConformationBox
                closeModal={() => setModalOpen(false)}
                title={t('areYouWantToEnableThisPersonAppLogin')}
                buttonLabel={t('ok')}
                okFunction={() => enableDisablePersons('app-login-enable')}
              />
            ),
          });
        } else {
          // Show an error notification when no person is selected
          dispatch(
            setNotificationError({
              error: t('pleaseSelectAtLeastOnePerson'),
              status: 400, // Error status code
            }),
          );
        }
      },
    },
    {
      title: t('disableAppLogin'),
      enable: checkPermission('pers:person:disableApplogin', loadMenus),
      clickFunction: (): void => {
        if (selectedPerson.length > 0) {
          setModalOpen(true);
          setModalView({
            title: t('disableAppLogin'),
            content: (
              <ConformationBox
                closeModal={() => setModalOpen(false)}
                title={t('areYouWantToDisableThisPersonsAppLogin')}
                buttonLabel={t('ok')}
                okFunction={() => enableDisablePersons('app-login-disable')}
              />
            ),
          });
        } else {
          // Show an error notification when no person is selected
          dispatch(
            setNotificationError({
              error: t('pleaseSelectAtLeastOnePerson'),
              status: 400, // Error status code
            }),
          );
        }
      },
    },
    {
      title: t('resetSelfLoginPassword'),
      enable: checkPermission('pers:person:resetSelfPwd', loadMenus),
      clickFunction: (): void => {
        if (selectedPerson.length > 0) {
          setModalOpen(true);
          setModalView({
            title: t('resetSelfLoginPassword'),
            content: (
              <ConformationBox
                closeModal={() => setModalOpen(false)}
                title={t('areYouWantToResetSelfLoginPassword')}
                buttonLabel={t('ok')}
                okFunction={selfLoginPassword}
              />
            ),
          });
        } else {
          // Show an error notification when no person is selected
          dispatch(
            setNotificationError({
              error: t('pleaseSelectAtLeastOnePerson'),
              status: 400, // Error status code
            }),
          );
        }
      },
    },
  ];

  const getFilterData = (filter: any): any => {
    if (filter.pageIndex > 0) {
      dispatch(setSearchFilter(filter));
      fetchData({
        pageNo: filter.pageIndex,
        pageSize: filter.pageSize,
        ...filter.filter,
      });
    }
  };

  useEffect(() => {
    dispatch(removeSinglePerson());
  }, []);
  return (
    <div className="person">
      <ModalBox
        status={modalOpen}
        closeModal={() => setModalOpen(false)}
        title={modalView.title}
        content={modalView.content}
      />
      <TabModalBox
        status={tabModalOpen}
        closeModal={() => {
          setTabModalOpen(false);
          setTabModalView({
            title: '',
            content: [
              {
                title: '',
                key: '',
                content: <>hi</>,
                enable: true,
              },
            ],
            index: 0,
          });
        }}
        title={tabModalView.title}
        tabs={tabModalView.content}
        currentIndex={tabModalView.index}
      />
      <TopHeader
        title={t('person')}
        broadCram={[
          {
            title: t('personnel'),
          },
          {
            title: t('person'),
          },
        ]}
      />
      <CardBox
        title={t('listOfPerson')}
        rightSide={
          <div className="delete-icon">
            <CardRightButtonGroup
              actions={moreActionButton}
              buttonIcon={<AddIcon color="#ffffff" />}
              buttonLabel={t('add')}
              buttonClass="btn-primary btn-sm"
              buttonStatus
              buttonFunction={(): void => {
                navigator('add');
              }}
              buttonEnable={checkPermission('pers:person:add', loadMenus)}
              // Add delete button configuration
              DeleteButtonIcon={<DeleteButtonIcon />}
              deleteButtonLabel={t('delete')}
              showDeleteButton
              deleteButtonFunction={handleDeleteClick}
              deleteButtonEnable={checkPermission('pers:person:del', loadMenus)}
              deleteButtonClass="multi-delete-button"
            />
            <div className="filter-section">
              <div className="item span-2">
                <MultiSelectBoxField
                  name="verificationMode"
                  label={t('verificationMode')}
                  id="verificationMode"
                  value={selectedVerificationModes}
                  required={false}
                  onChangeFunction={handleVerificationModeChange}
                  data={verificationModes}
                />
              </div>
            </div>
          </div>
        }>
        <DataTable
          actionButtonStatus={!modalOpen}
          action={actionButtons}
          columns={columnsField}
          getFilterData={getFilterData}
          enablePagination
          assignFilterValue={searchFilter}
          assignSelectedData={selectedPerson}
          getSelectedData={(val: any): any => {
            setSelectedPerson(val);
          }}
          getSelectedFullData={(val: any): any => {
            setSelectedPersonFullData(val);
          }}
          checkBoxActive
          values={persons}
          fixedColumn={['pin']}
          isFetching={status === 'loading'}
          totalRecords={totalDataCount}
          height={411}
        />
      </CardBox>
    </div>
  );
}

export default Person;
