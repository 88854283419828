/* eslint-disable no-param-reassign */
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { setNotificationError } from '../NotificationSlices';
import communicationDevice from '../../../utils/api/SystemManagement/communicationDevice';

export const fetchCommunicationDevice = createAsyncThunk(
  'acc/fetchCommunicationDevice',
  async (data: any, { dispatch }) => {
    const response = await communicationDevice
      .getListPage(data)
      .then((response1) => {
        return {
          ...response1.data,
          data: {
            list: response1.data.data.data,
          },
          size: response1.data.data.total,
          ...data,
        };
      })
      .catch((error: any) => {
        dispatch(setNotificationError(error.response.data));
        return error;
      });
    return response;
  },
);

export const fetchViewAuthorizedDevice = createAsyncThunk(
  'acc/fetchViewAuthorizedDevice',
  async (data: any, { dispatch }) => {
    const response = await communicationDevice
      .authDeviceList(data)
      .then((response1) => {
        return {
          ...response1.data,
          data: {
            list: response1.data.data.data,
          },
          size: response1.data.data.total,
          ...data,
        };
      })
      .catch((error: any) => {
        dispatch(setNotificationError(error.response.data));
        return error;
      });
    return response;
  },
);
interface CommunicationDevice {
  module: string;
  sn: string;
  fwVersion: string;
  deviceName: string;
  ipAddress: string;
  subnetMask: string;
  gateway: string;
  enabled: boolean;
  status: string;
  cmdCount: number;
}
interface ReaderReduxInterface {
  CommunicationsDevice: CommunicationDevice[];
  AuthorizedDevice: CommunicationDevice[];
  communicationStatus: 'loading' | 'success' | 'failed';
  authorizedDeviceStatus: 'loading' | 'success' | 'failed';
  communicationDataCount: number;
  authorizedDeviceDataCount: number;
  currentPageNo: number;
  searchFilter: { [key: string]: any };
}

const initialState: ReaderReduxInterface = {
  CommunicationsDevice: [],
  AuthorizedDevice: [],
  communicationStatus: 'success',
  authorizedDeviceStatus: 'success',
  communicationDataCount: 0,
  authorizedDeviceDataCount: 0,
  currentPageNo: 0,
  searchFilter: {},
};

export const CommunicationDeviceSlice: any = createSlice({
  name: 'communication',
  initialState,
  reducers: {
    setSearchFilter: (state, action: any) => {
      state.searchFilter = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchCommunicationDevice.pending, (state = initialState) => {
        state.communicationStatus = 'loading';
      })
      .addCase(fetchCommunicationDevice.fulfilled, (state: any, action: any) => {
        if (action.payload.success) {
          state.CommunicationsDevice = action.payload.data.list;
          state.communicationDataCount = action.payload.size;
        }
        state.communicationStatus = 'success';
      })
      .addCase(fetchCommunicationDevice.rejected, (state: any) => {
        state.communicationStatus = 'failed';
      })
      .addCase(fetchViewAuthorizedDevice.pending, (state = initialState) => {
        state.authorizedDeviceStatus = 'loading';
      })
      .addCase(fetchViewAuthorizedDevice.fulfilled, (state: any, action: any) => {
        // Add user to the state array
        if (action.payload.success) {
          state.AuthorizedDevice = action.payload.data.list;
          state.authorizedDeviceDataCount = action.payload.size;
        }
        state.authorizedDeviceStatus = 'success';
      })
      .addCase(fetchViewAuthorizedDevice.rejected, (state: any) => {
        state.authorizedDeviceStatus = 'failed';
      });
  },
});

// Action creators are generated for each case reducer function
export const { setSearchFilter } = CommunicationDeviceSlice.actions;

export default CommunicationDeviceSlice.reducer;
