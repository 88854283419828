import { post, get } from '../base/index';

export default {
  getListPage: (data: any = {}) => {
    return post(`${process.env.REACT_APP_API_URL}Acc/api/interlock/listByPage`, {}, { ...data });
  },
  addInterlock: (data: any = {}) => {
    return post(`${process.env.REACT_APP_API_URL}Acc/api/interlock/save`, data, {});
  },

  selectDeviceList: (data: any = {}) => {
    return post(`${process.env.REACT_APP_API_URL}Acc/api/interlock/selectDevicelist`, data, {});
  },

  getRule: (data: string) =>
    get(`${process.env.REACT_APP_API_URL}Acc/api/interlock/getRule/${data}`, {}),

  saveInterlock: (data: any = {}) => {
    return post(`${process.env.REACT_APP_API_URL}Acc/api/interlock/save`, data, {});
  },

  deleteInterlock: (data: any = {}) => {
    return post(`${process.env.REACT_APP_API_URL}Acc/api/interlock/delete`, {}, data);
  },
};
