import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
// Import shared components
import { useAppDispatch, useAppSelector } from '../../../redux/store';
import TopHeader from '../../../shared-components/top-header/top-header';
import CardBox from '../../../shared-components/card/card';
import DataTable from '../../../shared-components/data-table/data-table';
import ModalBox from '../../../shared-components/modal-box/modal-box';
import CardRightButtonGroup from '../../../shared-components/card-right-button-group/card-right-button-group';
import DeleteModalBox from '../../../shared-components/delete-modal-box/delete-modal-box';
import {
  deleteAreaHeadCount,
  fetchAreaHeadCountList,
  setSearchFilter,
} from '../../../redux/slices/areaHeadCountSlices';
import areaHeadCount from '../../../utils/api/AdvancedFunctions/areaHeadCount';
import { setNotificationError } from '../../../redux/slices/NotificationSlices';
import ExportData from './components/export-data';
import { checkPermission } from '../../../utils/role-permission';
import { DeleteButtonIcon } from '../../../assets/svgicons/svgicon';

function AreaHeadCount() {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { areaHeadCounts, status, totalDataCount, searchFilter } = useAppSelector(
    (state: any) => state.areaHeadCount,
  );

  /**
   * Selects the `loadMenus` permission from the authentication slice of the Redux store.
   *
   * @returns {Object} loadMenus - The user's menu permissions.
   */
  const { loadMenus } = useAppSelector((state) => state.auth);

  const [modalOpen, setModalOpen] = useState(false);
  const [modalView, setModalView] = useState({
    title: '',
    content: <>hi</>,
  });
  const [selectedValues, setSelectedValues] = useState<string[]>([]);
  const [selectedAreaFullData, setSelectedAreaFullData] = useState<any[]>([]);

  const columnsField = [
    {
      accessorKey: 'pin',
      header: t('personnelId'),
    },
    {
      accessorKey: 'name',
      header: t('firstName'),
    },
    {
      accessorKey: 'lastName',
      header: t('lastName'),
    },
    {
      accessorKey: 'department',
      header: t('department'),
    },
    {
      accessorKey: 'lastAccessTime',
      header: t('lastAccessTime'),
    },
    {
      accessorKey: 'lastAccessReader',
      header: t('lastAccessReader'),
    },
  ];

  const fetchData = async (params: any) => {
    if (status === 'success') {
      dispatch(fetchAreaHeadCountList({ ...params }));
    }
  };

  const exportData = () => {
    setModalOpen(false);
  };

  const deleteRecord = (id: any) => {
    const request = { personPins: id };
    areaHeadCount
      .deleteAreaHead(request)
      .then((response) => {
        if (response.data.success) {
          dispatch(
            setNotificationError({
              error: response.data.msg,
              status: 200,
            }),
          );
          dispatch(deleteAreaHeadCount(id));
          setModalOpen(false);
          fetchData({ pageNo: 1, pageSize: 10 }); // Refresh the list after successful deletion
        } else {
          dispatch(
            setNotificationError({
              error: response.data.message,
              status: response.data.code,
            }),
          );
        }
      })
      .catch((error) => {
        dispatch(
          setNotificationError({
            error: error.message,
            status: error.response?.status || 500,
          }),
        );
      });
  };
  const handleDeleteClick = () => {
    if (selectedAreaFullData.length === 0) {
      dispatch(
        setNotificationError({
          error: t('pleaseSelectAtLeastOneArea'), // Also fixed the error message
          status: 400,
        }),
      );
      return;
    }

    setModalOpen(true);
    setModalView({
      title: t('delete'),
      content: (
        <DeleteModalBox
          closeModal={() => setModalOpen(false)}
          id={selectedAreaFullData.map((val) => val.id).join(',')}
          okFunction={deleteRecord}
          isMultiSelect
        />
      ),
    });
  };
  const actionButtons = [
    {
      label: t('delete'),
      enable: true,
      function: (val: any) => {
        setModalOpen(true);
        setModalView({
          title: t('delete'),
          content: (
            <DeleteModalBox
              closeModal={() => setModalOpen(false)}
              id={val.pin}
              okFunction={deleteRecord}
            />
          ),
        });
      },
    },
    {
      label: t('export'),
      enable: checkPermission('acc:zonePerson:export', loadMenus),
      function: () => {
        setModalOpen(true);
        setModalView({
          title: t('export'),
          content: (
            <ExportData closeModal={() => setModalOpen(false)} submitFunction={exportData} />
          ),
        });
      },
    },
  ];
  const getFilterData = (filter: any): any => {
    if (filter.pageIndex > 0) {
      dispatch(setSearchFilter(filter));
      fetchData({
        pageNo: filter.pageIndex,
        pageSize: filter.pageSize,
        ...filter.filter,
      });
    }
  };

  return (
    <div className="department">
      <ModalBox
        status={modalOpen}
        closeModal={() => setModalOpen(false)}
        title={modalView.title}
        content={modalView.content}
      />
      <TopHeader
        title={t('access')}
        broadCram={[
          {
            title: t('advancedFunctions'),
          },
          {
            title: t('whoIsInside'),
          },
        ]}
      />
      <CardBox
        title={t('listOfPersonnel')}
        rightSide={
          <div className="delete-icon">
            <CardRightButtonGroup
              buttonStatus={false}
              // Add delete button configuration
              DeleteButtonIcon={<DeleteButtonIcon />}
              deleteButtonLabel={t('delete')}
              deleteButtonStatus
              showDeleteButton
              deleteButtonFunction={handleDeleteClick}
              deleteButtonEnable
              deleteButtonClass="multi-delete-button"
            />
          </div>
        }>
        <DataTable
          actionButtonStatus={!modalOpen}
          action={actionButtons}
          columns={columnsField}
          getFilterData={getFilterData}
          enablePagination
          assignFilterValue={searchFilter}
          assignSelectedData={selectedValues}
          getSelectedData={(val: any): any => setSelectedValues(val)}
          getSelectedFullData={(val: any): any => {
            setSelectedAreaFullData(val);
          }}
          checkBoxActive
          values={areaHeadCounts}
          fixedColumn={['zone']}
          isFetching={status === 'loading'}
          totalRecords={totalDataCount}
          height={411}
        />
      </CardBox>
    </div>
  );
}

export default AreaHeadCount;
