import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import NoPersonImage from '../../../assets/images/no-person-img.png';

interface MessageTipModalType {
  userData: any;
}

function MessageTip({ userData }: MessageTipModalType) {
  const { t } = useTranslation();

  const [userDatas, setUserDatas] = useState(userData);

  useEffect(() => {
    if (Object.keys(userData)?.length > 0) {
      setUserDatas(userData);
      setTimeout(() => {
        setUserDatas({});
      }, 5000);
    }
  }, [userData]);

  return (
    <div
      className="message-tip"
      style={{
        visibility: Object.keys(userDatas)?.length > 0 ? 'visible' : 'hidden',
        opacity: Object.keys(userDatas)?.length > 0 ? '1' : '0',
      }}>
      <div className="card-status">
        {/* Card Title */}
        <div className="card-title">{t('messageTip')}</div>

        {/* Card Body */}
        <div className="card-body">
          <img src={userDatas?.photoBase64 || NoPersonImage} alt="message" className="card-image" />
          {[userDatas]?.map((item: any) => (
            <div className="content-body" key={item}>
              <span className="text">{item.person}</span>
              <span className="text">{item.name}</span>
              <span className="text">{item.eventDescription}</span>
              <span className="text" style={item.textColor}>
                {item.status}
              </span>
              <span className="text">{item.time}</span>
              <span className="text">{item.device}</span>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

export default MessageTip;
