import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Cookies from 'universal-cookie';
import Button from '../../../shared-components/button/button';
import InputField from '../../../shared-components/input-field/input-field';
import { DoorInterface, DoorOpenRequestInterface } from '../../../interface/door.interface';
import door from '../../../utils/api/door';
import { useAppDispatch } from '../../../redux/store';
import { setNotificationError } from '../../../redux/slices/NotificationSlices';
import { LoadingProgressCount } from '../../../utils/helpers';
import SpinnerLoader from '../../../shared-components/spinnerLoader/spinner-loader';
import auth from '../../../utils/api/auth';

function CommonPopup({
  closeModal,
  selectedValues,
  selectedIds,
  inputTrue,
  modelName,
}: DoorInterface) {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const cookies = new Cookies();
  const [showUpgrade, setShowUpgrade] = useState(true);
  const [doorValue, setDoorValue] = useState({
    openInterval: '5',
    userPassword: '',
  });
  const [disable, setDisable] = useState(false);
  const [progress, setProgress] = useState(0);
  const [loading, setLoading] = useState<string>('');
  const [successMsg, setSuccessMsg] = useState<any>({});
  const [selectedDoorList, setSelectedDoorList] = useState(selectedValues);
  const [showPwd, setShowPwd] = useState(false);
  const onChangeValue = (name: string, value: any) => {
    setDoorValue((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const getDoorStatusById = () => {
    const req = {
      ids: selectedIds?.join('&ids='),
      type: 'openDoor',
    };
    door
      .getDoorIds(req)
      .then((res) => {
        const offlineDoors = res.data.data.offlineDoorsName
          ? res.data.data.offlineDoorsName.split(',').map((name: any) => name.trim())
          : [];
        const onlineDoors = res.data.data.retIds
          ? res.data.data.retIds.split(',').map((id: any) => id.trim())
          : [];
        const disabledDoors = res.data.data.disabledDoorsName
          ? res.data.data.disabledDoorsName.split(',').map((id: any) => id.trim())
          : [];

        setSelectedDoorList((prev: any) =>
          prev.map((item: any) => {
            let updatedStatus = item.deviceStatus;

            if (onlineDoors.includes(item.id)) {
              updatedStatus = 'online'; // Online if `item.id` is in `retIds`
            } else if (offlineDoors.includes(item.name)) {
              updatedStatus = 'offline'; // Offline if `item.name` is in `offlineDoorsName`
            } else if (disabledDoors.includes(item.name)) {
              updatedStatus = 'disabled';
            }
            return {
              ...item,
              deviceStatus: updatedStatus,
            };
          }),
        );
      })
      .catch((error) => {
        dispatch(
          setNotificationError({
            error: error.message,
            status: error.status,
          }),
        );
      });
  };

  const submit = () => {
    setDisable(true);
    setLoading('1');
    const stopProgress = LoadingProgressCount(0, 25, 100, setProgress, () => {});
    const filterEnabled = selectedDoorList.filter((val: any) => val.deviceStatus === 'online');
    const doorsId = filterEnabled.map((val: any) => val.id).join('&');

    const request: DoorOpenRequestInterface = {
      ids: doorsId,
    };
    if (modelName === 'openDoor') {
      request.openInterval = doorValue?.openInterval;
    }

    if (!showPwd) {
      request.userName = cookies.get('username') && cookies.get('username');
      request.loginPassword = doorValue?.userPassword;
    }
    door
      .doorActionsApi(modelName, request)
      .then((res: any) => {
        setSuccessMsg(res?.message);
        if (res.data.code === 0) {
          setTimeout(() => {
            setDisable(false);
            setLoading('0');
          }, 1000);
        } else {
          setDisable(false);
          setLoading('0');
          stopProgress();
          dispatch(
            setNotificationError({
              error: res.data.message,
              status: 500,
            }),
          );
        }
      })
      .catch((error) => {
        setDisable(false);
        setLoading('0');
        stopProgress();
        dispatch(
          setNotificationError({
            error: error?.response?.data?.msg || 'Something went wrong',
            status: error.status || 500,
          }),
        );
      });
  };

  const checkNeedValidUser = async () => {
    try {
      const response = await auth.isNeedValidUser({ mode: 'pwd' });
      if (response.data.success) {
        setShowPwd(response?.data?.success);
      }
    } catch (error: any) {
      dispatch(
        setNotificationError({
          error: error?.response?.data?.msg || error.message,
          status: error?.status || 500,
        }),
      );
    }
  };

  useEffect(() => {
    checkNeedValidUser();
  }, []);

  useEffect(() => {
    const getOnlineDevice = selectedDoorList
      .map((device: any) => device?.deviceStatus)
      .includes('online');
    if (getOnlineDevice && (showPwd ? true : doorValue?.userPassword)) {
      setDisable(false);
      if (modelName === 'openDoor') {
        if (
          doorValue?.openInterval &&
          Number(doorValue.openInterval) > 0 &&
          Number(doorValue.openInterval) < 255
        ) {
          setDisable(false);
        } else {
          setDisable(true);
        }
      }
    } else {
      setDisable(true);
    }
  }, [doorValue, selectedDoorList, showPwd]);

  useEffect(() => {
    getDoorStatusById();
    setSelectedDoorList(selectedValues);
  }, [selectedValues]);

  return (
    <div className="device-setup-popup">
      <div className="row">
        <div className="column-1">
          <h3 className="title">{t('selectedDevice')}</h3>
          <div className="box">
            {selectedDoorList.map(
              (item: any) =>
                item?.deviceStatus === 'online' && <span className="message">{item.name}</span>,
            )}
            {selectedDoorList.filter((item: any) => item?.deviceStatus === 'offline').length >
              0 && (
              <div>
                <h4 className="text-warning">{t('theOfflineDevicesCanNotBeOperated')}</h4>
                <span className="text-warning">
                  {selectedDoorList
                    .filter((item: any) => item?.deviceStatus === 'offline')
                    .map((item: any) => item?.name)
                    .join(', ')}
                </span>
              </div>
            )}
            {selectedDoorList.filter((item: any) => item?.deviceStatus === 'disabled').length >
              0 && (
              <div>
                <h4 className="text-warning">{t('theDisabledDevicesCanNotBeOperated')}</h4>
                <span className="text-warning">
                  {selectedDoorList
                    .filter((item: any) => item?.deviceStatus === 'disabled')
                    .map((item: any) => item?.name)
                    .join(', ')}
                </span>
              </div>
            )}
          </div>
          {inputTrue && (
            <div className="column-new">
              <div className="row row-3">
                {!showPwd && (
                  <InputField
                    name="userPassword"
                    label={t('userPassword')}
                    id="userPassword"
                    type="password"
                    value={doorValue?.userPassword}
                    innerIconStatus={false}
                    onChangeFunction={onChangeValue}
                    required
                  />
                )}
                {modelName === 'openDoor' && (
                  <div className="open-interval">
                    <InputField
                      name="openInterval"
                      label={t('openInterval')}
                      id="openInterval"
                      type="number"
                      value={doorValue?.openInterval}
                      innerIconStatus={false}
                      onChangeFunction={onChangeValue}
                      required
                      errorStatus={
                        Number(doorValue.openInterval) <= 0 || Number(doorValue.openInterval) > 255
                      }
                      message={t('second(1-254)')}
                    />
                  </div>
                )}
              </div>
            </div>
          )}
        </div>
        <div className="column-1">
          <div className="upgrading-content">
            <div className="left">
              <Button
                onClickFunction={() => setShowUpgrade(!showUpgrade)}
                title={showUpgrade ? t('hideInfo') : t('detailedInfo')}
                className="btn-primary"
              />
            </div>
            <div className="right">
              {loading && (
                <div className="search-loader">
                  {loading === '1' && <span className="count">{progress}%</span>}
                  {loading === '1' && <SpinnerLoader />}
                </div>
              )}
              <div className="form-buttons-right">
                <Button
                  onClickFunction={submit}
                  title={t('start')}
                  className="btn-primary"
                  disabled={disable}
                />
                <Button
                  onClickFunction={() => {
                    closeModal();
                  }}
                  title={t('close')}
                  className="btn-default"
                />
              </div>
            </div>
          </div>
          {showUpgrade && (
            <div className="box">
              {loading === '0' && successMsg && (
                <div className="success-wrapper">
                  <p>{successMsg}</p>
                </div>
              )}
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default CommonPopup;
