import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import CheckBoxInputField from '../../../../../shared-components/checkbox-input-field/checkbox-input-field';
import InputField from '../../../../../shared-components/input-field/input-field';
import Button from '../../../../../shared-components/button/button';
import Label from '../../../../../shared-components/label/Label';

function ADDataTimingImport({ onChangeFunction }: { onChangeFunction: (values: any) => void }) {
  const { t } = useTranslation();
  const [localValues, setLocalValues] = useState({
    incrementalSync: ['incrementalSync'],
    incrementalValue: '30',
    globalSync: ['globalSync'],
    globalValue: '3',
  });

  const onChange = (name: string, value: string | string[]) => {
    const isCheckbox = Array.isArray(value);
    const isChecked = isCheckbox ? value.length > 0 : false;

    setLocalValues((prev) => {
      const updatedValues = { ...prev, [name]: value };

      if (isCheckbox) {
        if (name === 'incrementalSync') {
          updatedValues.incrementalValue = isChecked ? prev.incrementalValue : '';
        } else if (name === 'globalSync') {
          updatedValues.globalValue = isChecked ? prev.globalValue : '';
        }
      }

      return updatedValues;
    });

    const updatedExternalValues = { ...localValues, [name]: isCheckbox ? isChecked : value };

    if (isCheckbox) {
      if (name === 'incrementalSync') {
        updatedExternalValues.incrementalValue = isChecked ? localValues.incrementalValue : '';
      } else if (name === 'globalSync') {
        updatedExternalValues.globalValue = isChecked ? localValues.globalValue : '';
      }
    }

    onChangeFunction(updatedExternalValues);
  };

  return (
    <div className="data-setting">
      <div className="sync-section">
        <div className="checkbox-group">
          <div className="box">
            <CheckBoxInputField
              name="incrementalSync"
              data={[
                {
                  id: 'incrementalSync',
                  label: t('incrementalSynchronization'),
                  value: 'incrementalSync',
                },
              ]}
              value={localValues.incrementalSync}
              onChangeFunction={onChange}
              alignment="row"
            />
          </div>
        </div>
        <div className="input-group">
          <InputField
            id="incrementalValue"
            name="incrementalValue"
            label=""
            value={localValues.incrementalValue}
            onChangeFunction={onChange}
            type="number"
            disabled={localValues.incrementalSync.length === 0}
          />
        </div>
        <div className="cleanup-button">
          <Button
            title={t('importNow')}
            className="btn-primary"
            onClickFunction={() => console.log('Global Import Now')}
          />
        </div>
      </div>
      <div className="sync-section">
        <div className="globalSync-group">
          <div className="box">
            <CheckBoxInputField
              name="globalSync"
              data={[
                {
                  id: 'globalSync',
                  label: t('globalSynchronization'),
                  value: 'globalSync',
                },
              ]}
              value={localValues.globalSync}
              onChangeFunction={onChange}
              alignment="row"
            />
          </div>
        </div>
        <div className="every-group">
          <Label className="input-label" title={t('every')} />
          <InputField
            id="globalValue"
            name="globalValue"
            label=""
            value={localValues.globalValue}
            onChangeFunction={onChange}
            type="number"
            disabled={localValues.globalSync.length === 0}
          />
          <Label className="input-label" title={t('day')} />
          <InputField
            id="globalValue"
            name="globalValue"
            label=""
            value={localValues.globalValue}
            onChangeFunction={onChange}
            type="number"
            disabled={localValues.globalSync.length === 0}
          />
          <Label className="input-label" title={t('oClock')} />
        </div>
        <div className="cleanup-button">
          <Button
            title={t('importNow')}
            className="btn-primary"
            onClickFunction={() => console.log('Global Import Now')}
          />
        </div>
      </div>
    </div>
  );
}

export default ADDataTimingImport;
