import { fetch, post } from '../base/index';

export default {
  getListPage: (data: any = {}) => {
    return post(
      `${process.env.REACT_APP_API_URL}Sys/api/baseOpLog/listByPage`,
      {},
      { ...data, pageSize: 10 },
    );
  },
  export: (data: any) => {
    return fetch(`${process.env.REACT_APP_API_URL}Sys/api/baseOpLog/export`, 'POST', data);
  },
};
