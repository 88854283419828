/* eslint-disable no-param-reassign */
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { setNotificationError } from '../NotificationSlices';
import areaSettings from '../../../utils/api/SystemManagement/areaSettings';

export const fetchArea = createAsyncThunk('acc/fetchArea', async (data: any, { dispatch }) => {
  const response = await areaSettings
    .getListPage(data)
    .then((response1: any) => {
      return {
        ...response1.data,
        data: {
          list: response1.data.data.data.map((val: any) => ({
            ...val,
            ...(val?.initFlag ? { delete: false } : {}),
            id: val?.id,
          })),
          size: response1.data.data.total,
          ...data,
        },
      };
    })
    .catch((error) => {
      dispatch(
        setNotificationError({
          error: error.message,
          status: error.response?.status || 500,
        }),
      );
      return error;
    });
  return response;
});

interface ReaderReduxInterface {
  areaSettingsList: any;
  status: 'loading' | 'success' | 'failed';
  totalDataCount: number;
  currentPageNo: number;
  searchFilter: { [key: string]: any };
}

const initialState: ReaderReduxInterface = {
  areaSettingsList: [],
  status: 'success',
  totalDataCount: 0,
  currentPageNo: 0,
  searchFilter: {},
};

export const areaSettingsSlice: any = createSlice({
  name: 'area',
  initialState,
  reducers: {
    deleteArea: (state, action: any) => {
      state.areaSettingsList = state.areaSettingsList.filter(
        (val: any) => val.id !== action.payload,
      );
      state.totalDataCount -= 1;
    },
    setSearchFilter: (state, action: any) => {
      state.searchFilter = action.payload;
    },
  },
  extraReducers: (builder) => {
    // Add reducers for additional action types here, and handle loading state as needed
    builder
      .addCase(fetchArea.pending, (state = initialState) => {
        state.status = 'loading';
      })
      .addCase(fetchArea.fulfilled, (state: any, action: any) => {
        // Add user to the state array
        if (action.payload.code === 0) {
          state.areaSettingsList = action.payload.data.list;
          state.totalDataCount = action.payload.data.size;
          state.currentPageNo += 1;
        }
        state.status = 'success';
      })
      .addCase(fetchArea.rejected, (state: any) => {
        state.status = 'failed';
      });
  },
});

// Action creators are generated for each case reducer function
export const { setSearchFilter, deleteArea } = areaSettingsSlice.actions;

export default areaSettingsSlice.reducer;
