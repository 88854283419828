import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  ParameterInterface,
  ParameterReduxInterface,
} from '../../../../../interface/parameter.interface';
import { useAppSelector } from '../../../../../redux/store';
import SelectBoxField from '../../../../../shared-components/selectbox-field/selectbox-field';
import { AttentionIcon } from '../../../../../assets/svgicons/svgicon';

function Records({ onChangeFunction, values }: ParameterInterface & { values: any }) {
  const { t } = useTranslation();
  const { parameters }: ParameterReduxInterface = useAppSelector((state: any) => state.parameters);

  // Parse the accReportDataClean JSON string safely
  const accReportDataClean = parameters?.accReportDataClean
    ? JSON.parse(parameters.accReportDataClean)
    : {
        keptMonth: values?.accReportDataCleanKeptMonth || '1',
        keptType: values?.accReportDataCleanKeptType || '0',
        runtime: values?.accReportDataCleanTime || '03:00:00',
      };

  // Initial state using values prop or parameters or default values
  const [value, setValue] = useState({
    ...parameters,
    access: {
      number: accReportDataClean.keptMonth,
      period: accReportDataClean.keptType,
      startTime: accReportDataClean.runtime,
    },
    attendance: {
      number: '1',
      period: '0',
      startTime: '00:00:00',
    },
    elevator: {
      number: '1',
      period: '0',
      startTime: '00:00:00',
    },
    visitor: {
      number: '1',
      period: '0',
      startTime: '00:00:00',
    },
    parking: {
      number: '1',
      period: '0',
      startTime: '00:00:00',
    },
  });

  const onChange = (key: string, field: string, changeValue: string | number) => {
    setValue((prev: any) => ({
      ...prev,
      [key]: {
        ...prev[key],
        [field]: changeValue,
      },
    }));
  };

  const executionTimeOptions = Array.from({ length: 24 }, (_, index) => {
    const time = `${index.toString().padStart(2, '0')}:00:00`;
    return { label: time, id: time };
  });

  const sections = [
    {
      title: 'Access Transactions',
      key: 'access',
      retentionName: 'accReportDataCleanKeptMonth',
      periodName: 'accReportDataCleanKeptType',
      timeName: 'accReportDataCleanTime',
    },
    {
      title: 'Attendance Transactions',
      key: 'attendance',
      retentionName: 'attReportDataCleanKeptMonth',
      periodName: 'attReportDataCleanKeptType',
      timeName: 'attReportDataCleanTime',
    },
    {
      title: 'Elevator Transactions',
      key: 'elevator',
      retentionName: 'eleReportDataCleanKeptMonth',
      periodName: 'eleReportDataCleanKeptType',
      timeName: 'eleReportDataCleanTime',
    },
    {
      title: 'Visitor Transactions',
      key: 'visitor',
      retentionName: 'visReportDataCleanKeptMonth',
      periodName: 'visReportDataCleanKeptType',
      timeName: 'visReportDataCleanTime',
    },
    {
      title: 'Parking Transactions',
      key: 'parking',
      retentionName: 'parkReportDataCleanKeptMonth',
      periodName: 'parkReportDataCleanKeptType',
      timeName: 'parkReportDataCleanTime',
    },
  ];

  const retentionOptions = Array.from({ length: 36 }, (_, index) => ({
    label: t((index + 1).toString()),
    id: (index + 1).toString(),
  }));

  const periodOptions = [
    { label: t('day'), id: '0' },
    { label: t('week'), id: '1' },
    { label: t('month'), id: '2' },
  ];

  useEffect(() => {
    const transformedValues = sections.reduce((acc, section) => {
      return {
        ...acc,
        [section.retentionName]: value?.[section.key]?.number,
        [section.periodName]: value?.[section.key]?.period,
        [section.timeName]: value?.[section.key]?.startTime,
      };
    }, {});

    onChangeFunction(transformedValues);
  }, [value, onChangeFunction]);

  // Add this useEffect to handle updates from parent component
  useEffect(() => {
    if (values) {
      if (values.accReportDataClean) {
        const reportData = JSON.parse(values?.accReportDataClean);
        setValue((prev: any) => ({
          ...prev,
          access: {
            number: reportData.keptMonth || prev.access.number,
            period: reportData.keptType || prev.access.period,
            startTime: reportData.runtime || prev.access.startTime,
          },
        }));
      }
    }
  }, [values]);

  return (
    <div className="data-setting">
      <div className="data-setting-form">
        <div className="column">
          {sections.slice(0, 3).map((section) => (
            <div key={section.key} className="item item-1">
              <div className="record-title">{t(section.title)}</div>
              <div className="sub-title">{t('Retains the Recent')}</div>
              <div className="main-container">
                <SelectBoxField
                  id={section.retentionName}
                  name={section.retentionName}
                  value={value?.[section.key]?.number}
                  onChangeFunction={(name, selectBoxValue) =>
                    onChange(section.key, 'number', selectBoxValue.id)
                  }
                  data={retentionOptions}
                />
                <SelectBoxField
                  id={section.periodName}
                  name={section.periodName}
                  value={value?.[section.key]?.period}
                  onChangeFunction={(name, selectBoxValue) =>
                    onChange(section.key, 'period', selectBoxValue.id)
                  }
                  data={periodOptions}
                />
              </div>
              <div className="form-start">
                <div className="sub-title">{t('Execution Time')}</div>
                <SelectBoxField
                  id={section.timeName}
                  name={section.timeName}
                  value={value?.[section.key]?.startTime}
                  onChangeFunction={(name, selectBoxValue) =>
                    onChange(section.key, 'startTime', selectBoxValue.id)
                  }
                  data={executionTimeOptions}
                />
                <div className="item text-danger">
                  <div className="note">(Carefully Cleanup)</div>
                </div>
              </div>
            </div>
          ))}
        </div>
        <div className="column">
          {sections.slice(3).map((section) => (
            <div key={section.key} className="item item-1">
              <div className="record-title">{t(section.title)}</div>
              <div className="sub-title">{t('Retains the Recent')}</div>
              <div className="main-container">
                <SelectBoxField
                  id={section.retentionName}
                  name={section.retentionName}
                  value={value?.[section.key]?.number}
                  onChangeFunction={(name, selectBoxValue) =>
                    onChange(section.key, 'number', selectBoxValue.id)
                  }
                  data={retentionOptions}
                />
                <SelectBoxField
                  id={section.periodName}
                  name={section.periodName}
                  value={value?.[section.key]?.period}
                  onChangeFunction={(name, selectBoxValue) =>
                    onChange(section.key, 'period', selectBoxValue.id)
                  }
                  data={periodOptions}
                />
              </div>
              <div className="form-start">
                <div className="sub-title">{t('Execution Time')}</div>
                <SelectBoxField
                  id={section.timeName}
                  name={section.timeName}
                  value={value?.[section.key]?.startTime}
                  onChangeFunction={(name, selectBoxValue) =>
                    onChange(section.key, 'startTime', selectBoxValue.id)
                  }
                  data={executionTimeOptions}
                />
                <div className="item text-danger">
                  <div className="note">(Carefully Cleanup)</div>
                </div>
              </div>
            </div>
          ))}
          <div className="text-danger flex-row center">
            <AttentionIcon />
            {t(
              'Cleaning frequency is executed once every day and clean up data before the set number of reserved months. Execution Time refers to the time when the system starts to perform a data clean-up. When you click OK, the system will automatically clean expired system data according to the users settings.',
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default Records;
