import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
// Import shared components
import { useAppDispatch, useAppSelector } from '../../../redux/store';
import TopHeader from '../../../shared-components/top-header/top-header';
import CardBox from '../../../shared-components/card/card';
import DataTable from '../../../shared-components/data-table/data-table';
import ModalBox from '../../../shared-components/modal-box/modal-box';
import CardRightButtonGroup from '../../../shared-components/card-right-button-group/card-right-button-group';
import {
  DepartmentParamsProps,
  FirstLevelApiProps,
} from '../../../interface/accessByDepartment.interface';
import {
  fetchDoorList,
  fetchPersonList,
  setDoorSearchFilter,
  setSearchFilter,
} from '../../../redux/slices/AccessRightsByPersonnel';
import ExportData from './export-data';
import { checkPermission } from '../../../utils/role-permission';

function AccessRightsByPersonnel() {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const {
    personList,
    status,
    totalDataCount,
    searchFilter,
    doorList,
    doorStatus,
    doorTotalDataCount,
    doorSearchFilter,
  } = useAppSelector((state: any) => state.accessRightsByPersonnel);

  /**
   * Selects the `loadMenus` permission from the authentication slice of the Redux store.
   *
   * @returns {Object} loadMenus - The user's menu permissions.
   */
  const { loadMenus } = useAppSelector((state) => state.auth);

  const [modalOpen, setModalOpen] = useState(false);
  const [modalView, setModalView] = useState({
    title: '',
    content: <>hi</>,
  });

  const [selectedValues, setSelectedValues] = useState<string[]>([]);
  const [selectedData, setSelectedData] = useState<any[]>([]);

  const columnsField = [
    {
      accessorKey: 'pin',
      header: t('personnelId'),
    },
    {
      accessorKey: 'name',
      header: t('firstName'),
    },
    {
      accessorKey: 'lastName',
      header: t('lastName'),
    },
    {
      accessorKey: 'deptName',
      header: t('department'),
    },
  ];
  const personnelColumn = [
    {
      accessorKey: 'floorNo',
      header: t('doorNumber'),
    },
    {
      accessorKey: 'name',
      header: t('name'),
    },
  ];

  const exportData = () => {
    setModalOpen(false);
  };

  const moreActionButton = [
    {
      title: t('export'),
      enable: checkPermission('acc:transactionByPerson:export', loadMenus),
      clickFunction: (): void => {
        setModalView({
          title: t('export'),
          content: (
            <ExportData closeModal={() => setModalOpen(false)} submitFunction={exportData} />
          ),
        });
        setModalOpen(true);
      },
    },
  ];

  const fetchData = (params: DepartmentParamsProps) => {
    if (status === 'success') {
      dispatch(fetchPersonList({ ...params }));
    }
  };

  // level functions
  const fetchLevels = (params: FirstLevelApiProps) => {
    if (params.personId) {
      dispatch(fetchDoorList({ ...params }));
    }
  };

  const getFilterData = (filter: any): any => {
    if (filter.pageIndex > 0) {
      dispatch(setDoorSearchFilter(filter));
      fetchLevels({ pageNo: filter.pageIndex, pageSize: filter.pageSize, ...filter.filter });
    }
  };

  const getSelected = (id: string[]) => {
    setSelectedValues(id);
    if (id?.[0] !== selectedValues?.[0]) {
      fetchLevels({ personId: id?.[0], pageNo: 1 });
    }
  };
  const getFilterLevels = (filter: any): any => {
    if (filter.pageIndex > 0) {
      dispatch(setSearchFilter(filter));
      fetchData({ pageNo: filter.pageIndex, pageSize: filter.pageSize, ...filter.filter });
    }
  };

  const selectedLevelName = selectedData.slice(selectedData.length - 1);

  return (
    <div className="access-by-level">
      <ModalBox
        status={modalOpen}
        closeModal={() => setModalOpen(false)}
        title={modalView.title}
        content={modalView.content}
      />
      <TopHeader
        title={t('access')}
        broadCram={[
          {
            title: t('accControlReports'),
          },
          {
            title: t('accessRightsByPersonnel'),
          },
        ]}
      />
      <CardBox
        header={t('accessRightsByPersonnel')}
        title={t('listOfPerson')}
        rightSide={
          <CardRightButtonGroup
            buttonIcon={false}
            buttonLabel=""
            buttonClass=""
            buttonStatus={false}
          />
        }>
        <DataTable
          actionButtonStatus={false} // Disable action buttons when modal is open
          columns={columnsField}
          getFilterData={getFilterLevels}
          assignFilterValue={searchFilter}
          assignSelectedData={selectedValues}
          getSelectedData={(val: any): any => getSelected(val)}
          getSelectedFullData={(val: any): any => setSelectedData(val)}
          checkBoxActive
          singleRowSelection
          enablePagination
          isFetching={status === 'loading'} // Placeholder status
          values={personList} // Use the actual data here
          fixedColumn={['pin']}
          totalRecords={totalDataCount}
          height={411}
        />
        <hr className="line" />
        <div className="browse-table">
          <div className="header">
            <div className="flex-row space-between">
              <div className="flex-row column">
                <h4>
                  Browse <span className="text-primary">{selectedLevelName?.[0]?.name}</span> Having
                  Levels to Access
                </h4>
                <span className="sub-title">{t('listOfDoor')}</span>
              </div>
              <CardRightButtonGroup
                actions={moreActionButton}
                buttonIcon={false}
                buttonLabel=""
                buttonClass=""
                buttonStatus={false}
              />
            </div>
          </div>
          <DataTable
            actionButtonStatus={false} // Disable action buttons when modal is open
            columns={personnelColumn}
            getFilterData={getFilterData}
            assignFilterValue={doorSearchFilter}
            checkBoxActive={false}
            isFetching={doorStatus === 'loading'} // Placeholder status
            values={doorList}
            fixedColumn={['id']}
            totalRecords={doorTotalDataCount}
            height={411}
          />
        </div>
      </CardBox>
    </div>
  );
}

export default AccessRightsByPersonnel;
