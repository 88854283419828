import { get } from '../base/index';

export default {
  getRedisInfo: () => {
    return get(`${process.env.REACT_APP_API_URL}Sys/api/systemServerInfo/getRedisInfo`, {});
  },
  getInfo: () => {
    return get(`${process.env.REACT_APP_API_URL}Sys/api/systemServerInfo/getInfo`, {});
  },
};
