import { fetch, get, post } from '../base/index';

export default {
  getAuthDevice: (data: any = {}) => {
    return post(`${process.env.REACT_APP_API_URL}Sys/api/authorizedDevice/listByPage`, {}, data);
  },
  isNotExistSn: (data: any = {}) => {
    return post(`${process.env.REACT_APP_API_URL}Sys/api/authorizedDevice/isNotExistSn`, {}, data);
  },
  getProductList: () => {
    return get(`${process.env.REACT_APP_API_URL}Sys/api/product/getSelectList`, {});
  },
  save: (data: any = {}) => {
    return post(`${process.env.REACT_APP_API_URL}Sys/api/authorizedDevice/save`, {}, data);
  },
  delete: (data: any = {}) => {
    return post(`${process.env.REACT_APP_API_URL}Sys/api/authorizedDevice/delete`, {}, data);
  },
  exportKey: (data: any) => {
    return fetch(`${process.env.REACT_APP_API_URL}Sys/api/authorizedDevice/export`, 'POST', data);
  },
};
