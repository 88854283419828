import React from 'react';

// Use Translation
import { useTranslation } from 'react-i18next';

// Use shard components
import Button from '../button/button';

// Use interface
import { DeleteModalBoxInterFace } from '../../interface/common.interface';

function DeleteModalBox({
  closeModal,
  id,
  okFunction,
  disabled = false,
  loading,
  isMultiSelect = false, // New prop to differentiate multi-select delete
}: DeleteModalBoxInterFace & { isMultiSelect?: boolean }) {
  const { t } = useTranslation();

  // Safely handle undefined id and pad with leading zero
  const selectedItemsCount = id ? id?.split(',').length : 0;
  const paddedItemsCount = selectedItemsCount.toString().padStart(2, '0');

  return (
    <div className={`delete-box ${isMultiSelect ? 'multi-select-delete' : ''}`}>
      <div className="message-text mutli-text">
        {isMultiSelect && selectedItemsCount > 0 && (
          <div className="selected-items-info">
            {t('itemSelected')} : <span className="selected-count">{paddedItemsCount}</span>
          </div>
        )}
        <p>{t('areYouSureYouWantToPerformTheDeleteOperation?')}</p>
      </div>
      <div className="confirm-buttons">
        <Button
          onClickFunction={closeModal}
          title={t('Cancel')}
          className="btn-default multi-delete-cancel"
        />
        <Button
          onClickFunction={() => okFunction(id)}
          title={t('ok')}
          className={`btn-primary multi-delete-confirm ${loading ? 'loading' : ''}`}
          loading={loading}
          disabled={disabled}
        />
      </div>
    </div>
  );
}
DeleteModalBox.defaultProps = {
  isMultiSelect: '',
};
export default DeleteModalBox;
