/* eslint-disable no-param-reassign */
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { setNotificationError } from './NotificationSlices';
import readerDefine from '../../utils/api/AdvancedFunctions/readerDefine';

export const fetchReaderDefineList = createAsyncThunk(
  'acc/fetchReaderDefineList',
  async (data: any, { dispatch }) => {
    const response = await readerDefine
      .getListPage(data)
      .then((response1) => {
        return {
          ...response1.data,
          data: {
            list: response1.data.data.data,
          },
          size: response1.data.data.total,
          ...data,
        };
      })
      .catch((error) => {
        dispatch(setNotificationError(error.response.data));
        return error;
      });
    return response;
  },
);

export const fetchAccReaderList = createAsyncThunk(
  'acc/fetchAccReaderList',
  async (data: any, { dispatch }) => {
    const response = await readerDefine
      .getAccReaderList(data)
      .then((response1) => {
        return {
          ...response1.data,
          data: {
            list: response1.data.data.map((val: any) => ({
              label: val.text,
              id: val.value,
            })),
            ...data,
          },
        };
      })
      .catch((error) => {
        dispatch(setNotificationError(error.response.data));
        return error;
      });
    return response;
  },
);

export const fetchArea = createAsyncThunk('acc/fetchArea', async (_, { dispatch }) => {
  const apiResponse = await readerDefine
    .zoneTree()
    .then((response) => {
      return {
        data: {
          list: response.data.item.map((val: any) => ({
            label: val.text,
            id: val.id,
          })),
        },
      };
    })
    .catch((error) => {
      dispatch(setNotificationError(error.response.data));
      return error;
    });
  return apiResponse;
});

interface ReaderReduxInterface {
  readersList: any;
  status: 'loading' | 'success' | 'failed'; // Status can be one of these three strings
  totalDataCount: number;
  currentPageNo: number;
  searchFilter: { [key: string]: any };
  accReaderList: any;
  areaList: any;
}

const initialState: ReaderReduxInterface = {
  readersList: [],
  status: 'success',
  totalDataCount: 0,
  currentPageNo: 0,
  searchFilter: {},
  accReaderList: [],
  areaList: [],
};

export const readerDefineSlice: any = createSlice({
  name: 'reader',
  initialState,
  reducers: {
    editReader: (state, action: any) => {
      if (state.readersList.find((val: any) => val.id === action.payload.id)) {
        state.readersList = state.readersList.map((val: any) => {
          let output = val;
          if (val.id === action.payload.id) {
            output = action.payload;
          }
          return output;
        });
      }
    },
    deleteReader: (state, action: any) => {
      state.readersList = state.readersList.filter((val: any) => val.id !== action.payload);
      state.totalDataCount -= 1;
    },
    setSearchFilter: (state, action: any) => {
      state.searchFilter = action.payload;
    },
  },
  extraReducers: (builder) => {
    // Add reducers for additional action types here, and handle loading state as needed
    builder
      .addCase(fetchReaderDefineList.pending, (state = initialState) => {
        state.status = 'loading';
      })
      .addCase(fetchReaderDefineList.fulfilled, (state: any, action: any) => {
        // Add user to the state array
        if (action.payload.code === 0) {
          state.readersList = action.payload.data.list;
          state.currentPageNo = action.payload.pageNo;
          state.totalDataCount = action.payload.size;
        }
        state.status = 'success';
      })
      .addCase(fetchReaderDefineList.rejected, (state: any) => {
        state.status = 'failed';
      });
    builder.addCase(fetchAccReaderList.fulfilled, (state: any, action: any) => {
      // Add user to the state array
      if (action.payload.success) {
        state.accReaderList = action.payload.data.list;
      }
    });
    builder.addCase(fetchArea.fulfilled, (state: any, action: any) => {
      // Add user to the state array
      state.areaList = action.payload.data?.list;
    });
  },
});

// Action creators are generated for each case reducer function
export const { setSearchFilter, editReader, deleteReader } = readerDefineSlice.actions;

export default readerDefineSlice.reducer;
