import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from '../../redux/store';

// Use shared components
import TopHeader from '../../shared-components/top-header/top-header';
import CardBox from '../../shared-components/card/card';
import DataTable from '../../shared-components/data-table/data-table';
import CardRightButtonGroup from '../../shared-components/card-right-button-group/card-right-button-group';
import ModalBox from '../../shared-components/modal-box/modal-box';
import ConformationBox from '../../shared-components/conformation-box/conformation-box';
import DeleteModalBox from '../../shared-components/delete-modal-box/delete-modal-box';
// Import slice actions
import SynchronizeLevel from './components/synchronizeLevel';
import {
  fetchAccessByPerson,
  fetchPersonLevel,
  resetLevels,
  setLevelSearchFilter,
  setSearchFilter,
  deleteLevels as deleteLevelList,
} from '../../redux/slices/AccessByPersonSlices';
import AccessControlSetting from './components/access-control-setting';
import AccessExport from './components/access-export';
import AddLevels from './components/AddLevel';
import accessByPerson from '../../utils/api/AccessRule/accessByPerson';
import { setNotificationError } from '../../redux/slices/NotificationSlices';
import {
  DeleteLevelProps,
  LevelApiProps,
  PersonProps,
} from '../../interface/accessByPerson.interface';
import { DepartmentParamsProps } from '../../interface/accessByDepartment.interface';
import { checkPermission } from '../../utils/role-permission';
import { DeleteButtonIcon } from '../../assets/svgicons/svgicon';

function SetAccessByPerson() {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const {
    persons,
    status,
    totalDataCount,
    searchFilter,
    levels,
    levelId,
    levelStatus,
    levelTotalDataCount,
    levelSearchFilter,
  } = useAppSelector((state: any) => state.accessByPerson);
  /**
   * Selects the `loadMenus` permission from the authentication slice of the Redux store.
   *
   * @returns {Object} loadMenus - The user's menu permissions.
   */
  const { loadMenus } = useAppSelector((state) => state.auth);

  const [selectedData, setSelectedData] = useState<PersonProps[]>([]); // Add state to hold selected cards
  const [modalOpen, setModalOpen] = useState(false);
  const [modalView, setModalView] = useState({
    title: 'hi',
    content: <>hi</>,
  });
  const [selectedPerson, setSelectPerson] = useState<string[]>([]); // Add state to hold selected cards
  const [selectedLevel, setSelectedLevel] = useState<string[]>([]); // Add state to hold selected cards
  const [selectedPersonFullData, setSelectedPersonFullData] = useState<any[]>([]);

  const accessLevelColumnsField = [
    {
      accessorKey: 'persPin',
      header: t('personnelId'),
    },
    {
      accessorKey: 'name',
      header: t('firstName'),
    },
    {
      accessorKey: 'lastName',
      header: t('lastName'),
    },
    {
      accessorKey: 'deptName',
      header: t('department'),
    },
    {
      accessorKey: 'privilege',
      header: t('deviceOperationRole'),
      Cell: ({ row }: any) => {
        let result;
        if (row.original.privilege === 0) {
          result = t('ordinaryUser');
        } else if (row.original.privilege === 14) {
          result = t('enroller');
        } else if (row.original.privilege === 2) {
          result = t('administrator');
        }
        return <div>{result}</div>;
      },
    },
  ];

  const doorColumnsField = [
    {
      accessorKey: 'name',
      header: t('levelName'),
    },
    {
      accessorKey: 'authAreaName',
      header: t('areaName'),
    },
    {
      accessorKey: 'timeSegName',
      header: t('timeZone'),
    },
  ];

  const fetchData = async (params: DepartmentParamsProps) => {
    if (status === 'success') {
      dispatch(fetchAccessByPerson({ ...params }));
    }
  };

  const getFilterData = (filter: any): any => {
    if (filter.pageIndex > 0) {
      dispatch(setSearchFilter(filter));
      fetchData({ pageNo: filter.pageIndex, pageSize: 50, ...filter.filter });
    }
  };
  const fetchLevels = async (params: LevelApiProps) => {
    if (params.personId) {
      dispatch(fetchPersonLevel({ ...params }));
    }
  };

  const handleSelectPerson = (selectedIds: string[]): void => {
    setSelectPerson(selectedIds);
    const lastSelectedId = selectedIds.at(-1);
    if (selectedIds.length === 1 && lastSelectedId) {
      fetchLevels({
        personId: lastSelectedId,
        pageNo: 1,
      });
    } else if (selectedIds.length === 0) {
      setSelectedData([]);
      dispatch(resetLevels());
    }
  };
  const getLevelFilter = (filter: any): any => {
    if (filter.pageIndex > 0) {
      dispatch(setLevelSearchFilter(filter));
      fetchLevels({ pageNo: filter.pageIndex, pageSize: filter.pageSize, ...filter.filter });
    }
  };

  const personActions = [
    {
      label: t('addToLevels'),
      enable: checkPermission('acc:personLevelByPerson:addLevel', loadMenus),
      function: (val: PersonProps): void => {
        setModalView({
          title: t('addToLevels'),
          content: <AddLevels closeModal={() => setModalOpen(false)} personId={val.id} />,
        });
        setModalOpen(true);
      },
    },
  ];
  const deleteLevels = (params: DeleteLevelProps) => {
    const req = {
      personId: selectedPerson?.[0],
      levelIds: params,
    };
    accessByPerson
      .deleteLevel(req)
      .then((response) => {
        if (response.data.success) {
          dispatch(
            setNotificationError({
              error: t('levelDeletedSuccessfully'),
              status: 200,
            }),
          );
          dispatch(deleteLevelList(levelId));
          setModalOpen(false);

          fetchLevels({ personId: selectedPerson[0], pageNo: 1 });
        } else {
          dispatch(
            setNotificationError({
              error: response.data.message,
              status: response.data.code,
            }),
          );
          setModalOpen(false);
        }
      })
      .catch((error) => {
        dispatch(
          setNotificationError({
            error: error.message,
            status: error.status,
          }),
        );
        setModalOpen(false);
      });
  };
  const handleDeletePersonClick = () => {
    if (selectedPersonFullData.length === 0) {
      dispatch(
        setNotificationError({
          error: t('pleaseSelectAtLeastOneDoor'),
          status: 400,
        }),
      );
      return;
    }

    setModalOpen(true);
    setModalView({
      title: t('delete'),
      content: (
        <DeleteModalBox
          closeModal={() => setModalOpen(false)}
          id={selectedPersonFullData.map((val) => val.id).join(',')}
          okFunction={deleteLevels}
          isMultiSelect
        />
      ),
    });
  };
  const actionButtons = [
    {
      label: t('deleteFromLevels'),
      enable: checkPermission('acc:personLevelByPerson:delLevel', loadMenus),
      function: (val: any) => {
        setModalOpen(true);
        setModalView({
          title: t('deleteFromLevels'),
          content: (
            <DeleteModalBox
              closeModal={() => setModalOpen(false)}
              id={val.id}
              okFunction={deleteLevels}
            />
          ),
        });
      },
    },
  ];

  const exportData = (data: any) => {
    console.log(data);
    setModalOpen(false);
  };
  const moreActionButton = [
    {
      title: t('accessControlSetting'),
      enable: checkPermission('acc:personLevelByPerson:setAccParams', loadMenus),
      clickFunction: (): void => {
        if (selectedPerson.length > 0) {
          setModalView({
            title: t('accessControlSetting'),
            content: (
              <AccessControlSetting
                closeModal={() => setModalOpen(false)}
                submit={() => {
                  /* Your submit handler */
                }}
                disable={false}
              />
            ),
          });
          setModalOpen(true);
        } else {
          setModalView({
            title: t('accessControlSetting'),
            content: (
              <ConformationBox
                closeModal={() => setModalOpen(false)}
                okFunction={() => setModalOpen(false)}
                title={t('pleaseSelectTheObjectsYouWantToOperate')}
                buttonLabel={t('ok')}
              />
            ),
          });
          setModalOpen(true);
        }
      },
    },
    {
      title: t('export'),
      enable: checkPermission('acc:personLevelByPerson:export', loadMenus),
      clickFunction: (): void => {
        if (selectedPerson.length > 0) {
          setModalView({
            title: t('export'),
            content: (
              <AccessExport closeModal={() => setModalOpen(false)} submitFunction={exportData} />
            ),
          });
          setModalOpen(true);
        } else {
          setModalView({
            title: t('selectObject'),
            content: (
              <ConformationBox
                closeModal={() => setModalOpen(false)}
                okFunction={() => setModalOpen(false)}
                title={t('pleaseSelectTheObjectsYouWantToOperate')}
                buttonLabel={t('ok')}
              />
            ),
          });
          setModalOpen(true);
        }
      },
    },
    {
      title: t('synchronizeLevel'),
      enable: checkPermission('acc:personLevelByPerson:sync', loadMenus),
      clickFunction: (): void => {
        if (selectedPerson.length > 0) {
          setModalView({
            title: t('synchronizeLevel'),
            content: (
              <SynchronizeLevel closeModal={() => setModalOpen(false)} ids={selectedPerson} />
            ),
          });
          setModalOpen(true);
        } else {
          setModalView({
            title: t('selectObject'),
            content: (
              <ConformationBox
                closeModal={() => setModalOpen(false)}
                okFunction={() => setModalOpen(false)}
                title={t('pleaseSelectTheObjectsYouWantToOperate')}
                buttonLabel={t('ok')}
              />
            ),
          });
          setModalOpen(true);
        }
      },
    },
  ];

  const selectedLevelName = selectedData.slice(selectedData.length - 1);
  return (
    <div className="card-management">
      <ModalBox
        status={modalOpen}
        closeModal={() => setModalOpen(false)}
        title={modalView.title}
        content={modalView.content}
      />
      <TopHeader
        title={t('access')}
        broadCram={[
          {
            title: t('accessRule'),
          },
          {
            title: t('setAccessByPerson'),
          },
        ]}
      />
      <CardBox
        header={t('editPersonnelForLevels')}
        title={t('listOfPerson')}
        rightSide={
          <CardRightButtonGroup
            actions={moreActionButton}
            buttonIcon={false}
            buttonLabel=""
            buttonClass=""
            buttonStatus={false}
          />
        }>
        <DataTable
          actionButtonStatus={!modalOpen}
          action={personActions}
          columns={accessLevelColumnsField}
          getFilterData={getFilterData}
          assignFilterValue={searchFilter}
          assignSelectedData={selectedPerson} // Assign selected cards
          getSelectedData={(val: any): any => handleSelectPerson(val)} // Set selected cards
          getSelectedFullData={(val: PersonProps[]): any => setSelectedData(val)}
          checkBoxActive
          singleRowSelection
          enablePagination
          isFetching={status === 'loading'}
          values={persons}
          fixedColumn={['persPin']}
          totalRecords={totalDataCount}
          height={411}
        />
        <h4>Browse Personnel ({selectedLevelName?.[0]?.name}) From Levels</h4>
        <div className="door-header">
          <div className="doorlist">
            <p>{t('listOfLevels')}</p>
          </div>
          <div className="delete-icon">
            <CardRightButtonGroup
              DeleteButtonIcon={<DeleteButtonIcon />}
              deleteButtonLabel={t('delete')}
              showDeleteButton
              deleteButtonFunction={handleDeletePersonClick}
              deleteButtonEnable={checkPermission('acc:personLevelByPerson:delLevel', loadMenus)}
              deleteButtonClass="multi-delete-button"
            />
          </div>
        </div>
        <DataTable
          action={actionButtons}
          columns={doorColumnsField}
          values={levels}
          actionButtonStatus={!modalOpen}
          getFilterData={getLevelFilter}
          assignFilterValue={levelSearchFilter}
          assignSelectedData={selectedLevel} // Assign selected cards
          getSelectedData={(val: any): any => setSelectedLevel(val)} // Set selected cards
          getSelectedFullData={(val: any): any => {
            setSelectedPersonFullData(val);
          }}
          checkBoxActive
          isFetching={levelStatus === 'loading'}
          fixedColumn={['cardNo']}
          totalRecords={levelTotalDataCount}
          height={411}
        />
      </CardBox>
    </div>
  );
}

export default SetAccessByPerson;
