import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
// Import shared components
import { useAppDispatch, useAppSelector } from '../../../../redux/store';
import TopHeader from '../../../../shared-components/top-header/top-header';
import CardBox from '../../../../shared-components/card/card';
import DataTable from '../../../../shared-components/data-table/data-table';
import ModalBox from '../../../../shared-components/modal-box/modal-box';
import CardRightButtonGroup from '../../../../shared-components/card-right-button-group/card-right-button-group';
import { AddIcon, DeleteButtonIcon } from '../../../../assets/svgicons/svgicon';
import DeleteModalBox from '../../../../shared-components/delete-modal-box/delete-modal-box';
import { setNotificationError } from '../../../../redux/slices/NotificationSlices';
import AuthorityAddRole from './components/add-role';
import {
  fetchRoleList,
  deleteRole,
  setSearchFilter,
} from '../../../../redux/slices/system-management/RoleSlices';
import role from '../../../../utils/api/SystemManagement/role';
import { checkPermission } from '../../../../utils/role-permission';

function Role() {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { roleList, status, totalDataCount, searchFilter } = useAppSelector(
    (state: any) => state.role,
  );
  const [modalOpen, setModalOpen] = useState(false);
  const [modalView, setModalView] = useState({
    title: '',
    content: <>hi</>,
  });
  const { loadMenus } = useAppSelector((state) => state.auth);

  const [selectedValues, setSelectedValues] = useState<string[]>([]);
  const [selectedRoleFullData, setSelectedRoleFullData] = useState<any[]>([]);

  let handleEditOpen: any;
  const columnsField = [
    {
      accessorKey: 'code',
      header: t('roleCode'),
      Cell: ({ row }: any) =>
        checkPermission('auth:role:edit', loadMenus) ? (
          <span
            className="list-hyperlink"
            role="presentation"
            onClick={(event) => {
              event.stopPropagation(); // Prevent row selection
              handleEditOpen(row.original); // Call your edit function
            }}>
            {row?.original?.code}
          </span>
        ) : (
          <div>{row?.original?.name}</div>
        ),
      size: 150,
    },
    {
      accessorKey: 'name',
      header: t('roleName'),
      Cell: ({ row }: any) => (
        <span
          className="list-hyperlink"
          role="presentation"
          onClick={(event) => {
            event.stopPropagation(); // Prevent row selection
            handleEditOpen(row.original); // Call your edit function
          }}>
          {row?.original?.name}
        </span>
      ),
      size: 150,
    },
    {
      accessorKey: 'createTime',
      header: t('creationTime'),
    },
  ];

  const fetchData = async (params: any) => {
    if (status === 'success') {
      dispatch(fetchRoleList({ ...params }));
    }
  };

  const deleteRecord = (id: any) => {
    const req = {
      ids: id,
    };
    role
      .deleteRole(req)
      .then((response) => {
        if (response.data.success) {
          dispatch(
            setNotificationError({
              error: response.data.msg,
              status: 200,
            }),
          );
          dispatch(deleteRole(id));
          setModalOpen(false);
          fetchData({ pageNo: 1, pageSize: 10, ...searchFilter.filter });
        } else {
          dispatch(
            setNotificationError({
              error: response.data.msg,
              status: response.data.code || 500,
            }),
          );
        }
      })
      .catch((error) => {
        dispatch(
          setNotificationError({
            error:
              error?.response?.data?.msg === 'auth_role_containAuthUserPrompt'
                ? t('associatedUserCannotBeDeleted')
                : error?.message,
            status: error.response?.status || 500,
          }),
        );
      });
  };

  handleEditOpen = (editData: any) => {
    if (!modalOpen) {
      setModalOpen(true);
      setModalView({
        title: t('edit'),
        content: <AuthorityAddRole closeModal={() => setModalOpen(false)} data={editData} />,
      });
    }
  };
  const handleDeleteClick = () => {
    if (selectedRoleFullData.length === 0) {
      dispatch(
        setNotificationError({
          error: t('pleaseSelectAtLeastOneRole'), // Also fixed the error message
          status: 400,
        }),
      );
      return;
    }

    setModalOpen(true);
    setModalView({
      title: t('delete'),
      content: (
        <DeleteModalBox
          closeModal={() => setModalOpen(false)}
          id={selectedRoleFullData.map((val) => val.id).join(',')}
          okFunction={deleteRecord}
          isMultiSelect
        />
      ),
    });
  };
  const actionButtons = [
    {
      label: t('edit'),
      enable: checkPermission('auth:role:edit', loadMenus),
      function: (val: any) => handleEditOpen(val),
    },
    {
      label: t('delete'),
      enable: checkPermission('auth:role:del', loadMenus),
      disabled: true,
      function: (val: any) => {
        setModalOpen(true);
        setModalView({
          title: t('delete'),
          content: (
            <DeleteModalBox
              closeModal={() => setModalOpen(false)}
              id={val.id}
              okFunction={deleteRecord}
            />
          ),
        });
      },
    },
  ];

  const getFilterData = (filter: any): any => {
    if (filter.pageIndex > 0) {
      dispatch(setSearchFilter(filter));
      fetchData({
        pageNo: filter.pageIndex,
        pageSize: filter.pageSize,
        ...filter.filter,
      });
    }
  };

  return (
    <div className="department">
      <ModalBox
        status={modalOpen}
        closeModal={() => setModalOpen(false)}
        title={modalView.title}
        content={modalView.content}
      />
      <TopHeader
        title={t('system')}
        broadCram={[
          {
            title: t('authorityManagement'),
          },
          {
            title: t('role'),
          },
        ]}
      />
      <CardBox
        title={t('listOfRole')}
        rightSide={
          <div className="delete-icon">
            <CardRightButtonGroup
              buttonIcon={<AddIcon color="#ffffff" />}
              buttonLabel={t('new')}
              buttonClass="btn-primary btn-sm"
              buttonStatus
              buttonFunction={(): void => {
                setModalOpen(true);
                setModalView({
                  title: t('new'),
                  content: <AuthorityAddRole closeModal={() => setModalOpen(false)} />,
                });
              }}
              buttonEnable={checkPermission('auth:role:add', loadMenus)}
              // Add delete button configuration
              DeleteButtonIcon={<DeleteButtonIcon />}
              deleteButtonLabel={t('delete')}
              showDeleteButton
              deleteButtonFunction={handleDeleteClick}
              deleteButtonEnable={checkPermission('auth:role:del', loadMenus)}
              deleteButtonClass="multi-delete-button"
            />
          </div>
        }>
        <DataTable
          actionButtonStatus={!modalOpen}
          action={actionButtons}
          columns={columnsField}
          getFilterData={getFilterData}
          assignFilterValue={searchFilter}
          assignSelectedData={selectedValues}
          getSelectedData={(val: any): any => setSelectedValues(val)}
          getSelectedFullData={(val: any): any => {
            setSelectedRoleFullData(val);
          }}
          checkBoxActive
          enablePagination
          isFetching={status === 'loading'}
          values={roleList}
          fixedColumn={['username']}
          totalRecords={totalDataCount}
          height={411}
        />
      </CardBox>
    </div>
  );
}

export default Role;
