import React from 'react';

type LabelProps = {
  title: string;
  required?: boolean;
  className?: string;
};
function Label({ title, required, className }: LabelProps) {
  return (
    <div className={`label ${className || ''}`}>
      <span className="title">{title}</span>
      {required && <span className="required"> *</span>}
    </div>
  );
}

Label.defaultProps = {
  required: false,
  className: '',
};

export default Label;
