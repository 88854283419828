import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import SelectBoxField from '../../../../../shared-components/selectbox-field/selectbox-field';
import {
  ParameterInterface,
  ParameterReduxInterface,
} from '../../../../../interface/parameter.interface';
import { useAppSelector } from '../../../../../redux/store';
import { SelectItemInterFace } from '../../../../../interface/common.interface';

function DateTimeFormatSettings({ onChangeFunction }: ParameterInterface) {
  const { t } = useTranslation();
  const { parameters }: ParameterReduxInterface = useAppSelector((state: any) => state.parameters);

  const [value, setValue] = useState({
    'system.fmt.date': parameters['system.fmt.date'] || 'YYYY_MM_DD',
    'system.fmt.date_new_value': String(parameters['system.fmt.date_new_value'] || 'YYYY_MM_DD'),
    'system.fmt.time': parameters['system.fmt.time'] || 'HH_MM_SS',
    'system.fmt.time_new_value': String(parameters['system.fmt.time_new_value'] || 'HH_MM_SS'),
  });

  const handleChange = (name: string, selectedOption: SelectItemInterFace) => {
    setValue((prev: any) => ({
      ...prev,
      [name]: selectedOption.value,
    }));
    onChangeFunction({ ...value, [name]: selectedOption.value });
  };

  useEffect(() => {
    onChangeFunction(value);
  }, [value]);

  useEffect(() => {
    setValue({
      'system.fmt.date': parameters['system.fmt.date'] || 'YYYY_MM_DD',
      'system.fmt.date_new_value': String(parameters['system.fmt.date_new_value'] || 'YYYY_MM_DD'),
      'system.fmt.time': parameters['system.fmt.time'] || 'HH_MM_SS',
      'system.fmt.time_new_value': String(parameters['system.fmt.time_new_value'] || 'HH_MM_SS'),
    });
  }, [parameters]);

  const dateFormatOptions: SelectItemInterFace[] = [
    {
      id: 'YYYY_MM_DD',
      label: '2022-01-01',
      value: 'YYYY_MM_DD',
    },
    {
      id: 'DD_MM_YYYY',
      label: '01/01/2022',
      value: 'DD_MM_YYYY',
    },
    {
      id: 'D_M_YYYY',
      label: '1/1/2022',
      value: 'D_M_YYYY',
    },
    {
      id: 'DD_MMM_YY',
      label: '01-JAN-22',
      value: 'DD_MMM_YY',
    },
  ];

  const timeFormatOptions: SelectItemInterFace[] = [
    {
      id: 'HH_MM_SS',
      label: '00:00:00',
      value: 'HH_MM_SS',
    },
    {
      id: 'H_MM_SS',
      label: '0:00:00',
      value: 'H_MM_SS',
    },
  ];

  return (
    <div className="card-management">
      <div className="parameter-settings">
        <div className="setting-form w50">
          <div className="date-time-format-settings">
            <div className="item">
              <SelectBoxField
                label={t('Date Format')}
                id="system.fmt.date"
                name="system.fmt.date"
                value={value['system.fmt.date']}
                data={dateFormatOptions}
                onChangeFunction={(name, selectedOption: SelectItemInterFace) =>
                  handleChange(name, selectedOption)
                }
              />
            </div>

            <div className="item">
              <SelectBoxField
                label={t('Time Format')}
                id="system.fmt.time"
                name="system.fmt.time"
                value={value['system.fmt.time']}
                data={timeFormatOptions}
                onChangeFunction={(name, selectedOption: SelectItemInterFace) =>
                  handleChange(name, selectedOption)
                }
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default DateTimeFormatSettings;
