import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Label from '../../../../../shared-components/label/Label';
import Table from '../../../../../shared-components/table/table';
import { TableInterface } from '../../../../../interface/System/currentInformation.interface';

function Caching({ allRedisData }: any) {
  const { t } = useTranslation();
  const [clientInfo, setClientInfo] = useState<TableInterface[]>([]);
  const [currentDatabase, setCurrentDatabase] = useState<TableInterface[]>([]);
  const [redisServer, setRedisServer] = useState<TableInterface[]>([]);
  const [memory, setMemory] = useState<TableInterface[]>([]);
  const [statistics, setStatistics] = useState<TableInterface[]>([]);

  const getRedisInformation = () => {
    /**
     * Processes the response data and updates the client and database state.
     *
     * @param {Object} response - The API response object.
     * @param {Object} response.data - The response data containing client and database information.
     * @param {string} response.data.connected_clients - Number of connected clients (as a string).
     * @param {string} response.data.blocked_clients - Number of blocked clients (as a string).
     * @param {string} response.data.keys - Number of keys in the database (as a string).
     * @param {string} response.data.expires - Number of expired keys (as a string).
     *
     * @returns {void}
     *
     * Updates:
     * - `clientInfo` state with an array containing client-related attributes.
     * - `currentDatabase` state with an array containing database-related attributes.
     */
    const clientInfoRes = [
      {
        attribute: t('numberOfConnectedClients'),
        value: allRedisData?.connected_clients,
      },
      {
        attribute: t('numberOfBlockedClients'),
        value: allRedisData?.blocked_clients,
      },
    ];
    const currentDatabaseRes = [
      {
        attribute: t('numberOfKeys'),
        value: allRedisData?.keys,
      },
      {
        attribute: t('expiredKeys'),
        value: allRedisData?.expires,
      },
      {
        attribute: t('average'),
        value: allRedisData?.expires,
      },
    ];
    const redisServerRes = [
      {
        attribute: t('redisVersion'),
        value: allRedisData?.redis_version,
      },
      {
        attribute: t('osArchitecture'),
        value: allRedisData?.os,
      },
      {
        attribute: t('operationHours'),
        value: allRedisData?.uptime_in_format,
      },
    ];
    const memoryRes = [
      {
        attribute: t('rssMemory'),
        value: allRedisData?.used_memory_human,
      },
      {
        attribute: t('allocateSpace'),
        value: allRedisData?.used_memory_rss_human,
      },
      {
        attribute: t('memoryPeak'),
        value: allRedisData?.uptime_in_format,
      },
      {
        attribute: t('maxMemory'),
        value: allRedisData?.maxmemory,
      },
      {
        attribute: t('fragmentRatio'),
        value: allRedisData?.mem_fragmentation_ratio,
      },
    ];
    const statisticsRes = [
      {
        attribute: t('connectionReceived'),
        value: allRedisData?.total_connections_received,
      },
      {
        attribute: t('connectionProcessed'),
        value: allRedisData?.total_commands_processed,
      },
      {
        attribute: t('instantaneousOpsPerSec'),
        value: allRedisData?.instantaneous_ops_per_sec,
      },
      {
        attribute: t('expiredKeys'),
        value: allRedisData?.expired_keys,
      },
      {
        attribute: t('evictedKeys'),
        value: allRedisData?.evicted_keys,
      },
      {
        attribute: t('keyspaceHits'),
        value: allRedisData?.keyspace_hits,
      },
      {
        attribute: t('keyspaceMisses'),
        value: allRedisData?.keyspace_misses,
      },
    ];
    setClientInfo(clientInfoRes);
    setCurrentDatabase(currentDatabaseRes);
    setRedisServer(redisServerRes);
    setMemory(memoryRes);
    setStatistics(statisticsRes);
  };
  // } catch (error: any) {
  //   dispatch(
  //     setNotificationError({
  //       error: error.message,
  //       status: error.status,
  //     }),
  //   );
  // }

  useEffect(() => {
    getRedisInformation();
  }, [allRedisData]);

  return (
    <div className="caching current-information">
      <div className="table-form">
        <div className="table-wrapper">
          <div className="table-title">
            <Label title={t('clientInformation')} />
          </div>
          <Table
            header={[
              { label: t('attribute'), id: 'attribute' },
              { label: t('value'), id: 'value' },
            ]}
            hideHeader={false}
            value={clientInfo}
            textWrap
          />
        </div>
        <div className="table-wrapper">
          <div className="table-title">
            <Label title={t('currentDatabase')} />
          </div>
          <Table
            header={[
              { label: t('attribute'), id: 'attribute' },
              { label: t('value'), id: 'value' },
            ]}
            hideHeader={false}
            value={currentDatabase}
            textWrap
          />
        </div>
      </div>
      <div className="table-wrapper">
        <div className="table-title">
          <Label title={t('redisServerInformation')} />
        </div>
        <Table
          header={[
            { label: t('attribute'), id: 'attribute' },
            { label: t('value'), id: 'value' },
          ]}
          hideHeader={false}
          value={redisServer}
          textWrap
        />
      </div>
      <div className="table-form">
        <div className="table-wrapper">
          <div className="table-title">
            <Label title={t('memoryInformation')} />
          </div>
          <Table
            header={[
              { label: t('attribute'), id: 'attribute' },
              { label: t('value'), id: 'value' },
            ]}
            hideHeader={false}
            value={memory}
            textWrap
          />
        </div>
        <div className="table-wrapper">
          <div className="table-title">
            <Label title={t('statistics')} />
          </div>
          <Table
            header={[
              { label: t('attribute'), id: 'attribute' },
              { label: t('value'), id: 'value' },
            ]}
            hideHeader={false}
            value={statistics}
            textWrap
          />
        </div>
      </div>
    </div>
  );
}

export default Caching;
