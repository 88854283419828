/* eslint-disable no-param-reassign */
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { setNotificationError } from '../NotificationSlices';
import apiAuthorization from '../../../utils/api/SystemManagement/apiAuthorization';
import { changeDateTimeFormat } from '../../../utils/helpers';

interface APIAuthProps {
  equals: boolean;
  id: string;
  clientName: string;
  clientToken: string;
  createTime: number;
  code: any;
}

export const fetchApiAuthorization = createAsyncThunk(
  'acc/fetchResourceFile',
  async (data: any, { dispatch }) => {
    const response = await apiAuthorization
      .getListPage(data)
      .then((response1) => {
        return {
          ...response1.data,
          data: {
            list: response1.data.data.map((item: APIAuthProps) => ({
              ...item,
              id: item.id,
              createTime: changeDateTimeFormat(item.createTime),
            })),
            size: response1.data.data.length,
            ...data,
          },
        };
      })
      .catch((error) => {
        dispatch(
          setNotificationError({
            error: error.message,
            status: error.response?.status || 500,
          }),
        );
        return error;
      });
    return response;
  },
);

interface ReaderReduxInterface {
  apiAuthorizationList: any;
  status: 'loading' | 'success' | 'failed';
  totalDataCount: number;
  currentPageNo: number;
  searchFilter: { [key: string]: any };
}

const initialState: ReaderReduxInterface = {
  apiAuthorizationList: [],
  status: 'success',
  totalDataCount: 0,
  currentPageNo: 0,
  searchFilter: {},
};

export const ApiAuthorizationSlice: any = createSlice({
  name: 'apiAuthorization',
  initialState,
  reducers: {
    deleteApiAuthorization: (state, action: any) => {
      state.apiAuthorizationList = state.apiAuthorizationList.filter(
        (val: any) => val.id !== action.payload,
      );
      state.totalDataCount -= 1;
    },
    setSearchFilter: (state, action: any) => {
      state.searchFilter = action.payload;
    },
  },
  extraReducers: (builder) => {
    // Add reducers for additional action types here, and handle loading state as needed
    builder
      .addCase(fetchApiAuthorization.pending, (state = initialState) => {
        state.status = 'loading';
      })
      .addCase(fetchApiAuthorization.fulfilled, (state: any, action: any) => {
        // Add user to the state array
        if (action.payload.success) {
          state.apiAuthorizationList = action.payload.data.list;
          state.totalDataCount = action.payload.data.size;
          state.currentPageNo += 1;
        }
        state.status = 'success';
      })
      .addCase(fetchApiAuthorization.rejected, (state: any) => {
        state.status = 'failed';
      });
  },
});

// Action creators are generated for each case reducer function
export const { setSearchFilter, deleteApiAuthorization } = ApiAuthorizationSlice.actions;

export default ApiAuthorizationSlice.reducer;
