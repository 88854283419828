/* eslint-disable */
import React, { useEffect, useState } from 'react';
import { createTheme, styled, ThemeProvider } from '@mui/material/styles';
import { SimpleTreeView } from '@mui/x-tree-view/SimpleTreeView';
import { TreeItem, treeItemClasses, TreeItemProps } from '@mui/x-tree-view/TreeItem';

const CustomTreeItem = styled((props: TreeItemProps) => <TreeItem {...props} />)(({ theme }) => ({
  [`& .${treeItemClasses.content}`]: {
    padding: theme.spacing(0.5, 1),
    margin: theme.spacing(0.2, 0),
  },
  [`& .${treeItemClasses.iconContainer}`]: {
    '& .close': {
      opacity: 0.3,
    },
  },
  [`& .${treeItemClasses.groupTransition}`]: {
    marginLeft: 15,
    paddingLeft: 18,
  },
  // Fix selected state styling
  [`& .${treeItemClasses.content}.Mui-selected`]: {
    backgroundColor: 'lightblue', // Change this color
  },
}));

// function ExpandIcon() {
//   return <DownArrowIcon />;
// }

// function CollapseIcon() {
//   return <DownArrowIcon />;
// }

// function EndIcon() {
//   return <DownArrowIcon />;
// }

// Recursive function to render tree items
// const renderTreeItems = (nodes: any) => {
//   return (
//     <CustomTreeItem itemId={nodes.id} label={nodes.text}>
//       {nodes.item &&
//         nodes.item.length > 0 &&
//         nodes.item.map((child: any) => renderTreeItems(child))}
//     </CustomTreeItem>
//   );
// };

type TreeNode = {
  id: string;
  text: string;
  item?: TreeNode[];
};
const renderTreeItems: any = (nodes: TreeNode[] | TreeNode) => {
  if (Array.isArray(nodes)) {
    return nodes?.map((node) => renderTreeItems(node)); // Map multiple root nodes
  }

  return (
    <CustomTreeItem
      key={nodes?.id}
      itemId={String(nodes?.id)} // Ensure `nodeId` is a string
      label={nodes?.text}>
      {nodes?.item && nodes?.item?.length > 0 ? renderTreeItems(nodes?.item) : null}
    </CustomTreeItem>
  );
};

const theme = createTheme({
  typography: {
    fontFamily: 'Urbanist', // Customize the font family globally
  },
});

export default function TreeViewMultiSelect({
  listItems,
  onSelect,
  value,
  // onSelectValue,
  multiSelect,
  checkboxSelection,
  // isExpanded,
}: any) {
  const [selectedNode, setSelectedNode] = useState<any>(multiSelect ? value || [] : value || null);

  /**
   * Handles the selection of a tree node.
   * When a parent node is selected, all its child nodes are automatically selected.
   * When a parent node is deselected, all its child nodes are also deselected.
   *
   * @param {React.MouseEvent} event - The click event triggered by user interaction.
   * @param {string} itemId - The unique identifier of the selected node.
   */
  const handleNodeSelect = (event: React.MouseEvent, itemId: string): void => {
    const target = event.target as HTMLElement;

    // Check if the clicked target is a checkbox

    const isCheckboxClick =
      target.tagName === 'INPUT' && (target as HTMLInputElement).type === 'checkbox';

    if (!isCheckboxClick) {
      event.stopPropagation(); // Prevent unintended selection
      return;
    }

    /**
     * Recursively retrieves all child node IDs of a given node.
     *
     * @param {Object} node - The current node being processed.
     * @returns {string[]} - Array of child node IDs.
     */
    const getAllChildIds = (node: TreeNode): string[] => {
      let childIds: string[] = [];

      if (node.item && node.item.length > 0) {
        node.item.forEach((child) => {
          childIds.push(child.id);
          childIds = childIds.concat(getAllChildIds(child)); // Recursively add all children
        });
      }

      return childIds;
    };

    /**
     * Finds a node by its ID within a tree structure.
     *
     * @param {Object[]} nodes - The list of nodes to search.
     * @param {string} id - The ID of the node to find.
     * @returns {Object|null} - The matching node object or null if not found.
     */
    const findNodeById = (nodes: TreeNode[], id: string): TreeNode | null => {
      for (const node of nodes) {
        if (node.id === id) return node;
        if (node.item) {
          const found = findNodeById(node.item, id);
          if (found) return found;
        }
      }
      return null;
    };

    const selectedNodeData = findNodeById(listItems, itemId);
    if (!selectedNodeData) return;

    const childIds = getAllChildIds(selectedNodeData);

    if (multiSelect) {
      setSelectedNode((prev: any) => {
        const prevArray = Array.isArray(prev) ? prev : [];

        const isParentAlreadySelected = prevArray.includes(itemId);
        let newSelection;

        if (isParentAlreadySelected) {
          // If parent is already selected, remove it and its children
          newSelection = prevArray.filter((id) => id !== itemId && !childIds.includes(id));
        } else {
          // If parent is selected, add it and its children
          newSelection = [...prevArray, itemId, ...childIds];
        }

        onSelect(newSelection);
        return newSelection;
      });
    } else {
      setSelectedNode(itemId);
      onSelect(itemId);
    }
  };

  // const getDefaultExpandedItems = (nodes: TreeNodeInterface[]): string[] => {
  //   let expandedItems: string[] = [];

  //   // Check if nodes is an array
  //   if (Array.isArray(nodes)) {
  //     nodes.forEach((node) => {
  //       expandedItems.push(node?.id); // Add current node ID for expansion
  //       if (node?.item && node?.item?.length > 0) {
  //         expandedItems = expandedItems.concat(getDefaultExpandedItems(node?.item)); // Recursively expand children
  //       }
  //     });
  //   }

  //   return expandedItems;
  // };

  // Keep selectedNode in sync with value
  useEffect(() => {
    setSelectedNode(value || []);
  }, [value]);

  return (
    <ThemeProvider theme={theme}>
      <SimpleTreeView
        multiSelect={multiSelect}
        checkboxSelection={checkboxSelection}
        onItemClick={handleNodeSelect}
        aria-label="customized"
        // defaultExpandedItems={defaultExpandedItems}
        // expandedItems={defaultExpandedItems}
        selectedItems={selectedNode || value}
        // defaultSelectedItems={selectedNode || value}
        sx={{
          overflowX: 'hidden',
          flexGrow: 1,
          maxWidth: 300,
          '& .MuiTreeItem-content.Mui-selected': {
            backgroundColor: checkboxSelection ? 'transparent' : 'inherit',
          },
        }}>
        {renderTreeItems(listItems)}
      </SimpleTreeView>
    </ThemeProvider>
  );
}
