import React, { useState } from 'react';

// Use Translation
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from '../../redux/store';

// Use own components
import ViewPersonnel from './components/view-personnel';

// Use redux functions
import {
  fetchPendingReviewList,
  deletePendingReview,
  setSearchFilter,
} from '../../redux/slices/PendingReviewSlices';
import { setNotificationError } from '../../redux/slices/NotificationSlices';

// Use Interface
import PendingReviewReduxInterface from '../../interface/pending-review.interface';

// Use api's calls
import pendingReviewApis from '../../utils/api/pendingReview';

// Use shared components
import TopHeader from '../../shared-components/top-header/top-header';
import CardBox from '../../shared-components/card/card';
import DataTable from '../../shared-components/data-table/data-table';
import DeleteModalBox from '../../shared-components/delete-modal-box/delete-modal-box';
import ModalBox from '../../shared-components/modal-box/modal-box';
import { checkPermission } from '../../utils/role-permission';
import CardRightButtonGroup from '../../shared-components/card-right-button-group/card-right-button-group';
import { DeleteButtonIcon } from '../../assets/svgicons/svgicon';

function PendingReview() {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { pendingReviews, status, totalDataCount, searchFilter }: PendingReviewReduxInterface =
    useAppSelector((state: any) => state.pendingReviews);
  const { loadMenus } = useAppSelector((state) => state.auth);

  const columnsField = [
    {
      accessorKey: 'pin',
      header: t('personnelId'),
    },
    {
      accessorKey: 'name',
      header: t('firstName'),
    },
    {
      accessorKey: 'lastName',
      header: t('lastName'),
    },
    {
      accessorKey: 'mobilePhone',
      header: t('mobileNumber'),
    },
    {
      accessorKey: 'status',
      header: t('status'),
      Cell: ({ cell }: any) => {
        let output;
        switch (cell.getValue()) {
          case 1:
            output = <span className="text-success">Approved</span>;
            break;
          case 2:
            output = <span className="text-danger">Rejected</span>;
            break;

          default:
            break;
        }

        return output;
      },
    },
    {
      accessorKey: 'createTime',
      header: t('changeTime'),
    },
  ];

  const [modalOpen, setModalOpen] = useState(false);
  const [modalView, setModalView] = useState({
    title: t('addNewDepartment'),
    content: <>hi</>,
  });
  const [selectedDeleteFullData, setSelectedDeleteFullData] = useState<any[]>([]);

  const fetchPendingReviewData = (params: any) => {
    if (status === 'success') {
      dispatch(fetchPendingReviewList({ ...params }));
    }
  };

  const viewRecord = (val: any) => {
    setModalOpen(true);
    setModalView({
      title: t('viewPersonnel'),
      content: <ViewPersonnel closeModal={() => setModalOpen(false)} data={val} />,
    });
  };

  const deleteFunction = (id: string) => {
    pendingReviewApis
      .deletePendingReview(id)
      .then((response) => {
        if (response.data.code === 0) {
          dispatch(
            setNotificationError({
              error: t('pendingReviewDeletedSuccessfully'),
              status: 200,
            }),
          );
          dispatch(deletePendingReview(id));
          setModalOpen(false);
        } else {
          dispatch(
            setNotificationError({
              error: response.data.message,
              status: response.data.code,
            }),
          );
        }
      })
      .catch((error) => {
        dispatch(
          setNotificationError({
            error: error.message,
            status: error.status,
          }),
        );
      });
  };
  const deleteRecord = (val: any) => {
    setModalOpen(true);
    setModalView({
      title: t('deletePersonnel'),
      content: (
        <DeleteModalBox
          closeModal={() => setModalOpen(false)}
          id={val.id}
          okFunction={deleteFunction}
        />
      ),
    });
  };
  const handleDeleteClick = () => {
    if (selectedDeleteFullData.length === 0) {
      dispatch(
        setNotificationError({
          error: t('pleaseSelectAtLeastOneReview'),
          status: 400,
        }),
      );
      return;
    }

    setModalView({
      title: t('delete'),
      content: (
        <DeleteModalBox
          closeModal={() => setModalOpen(false)}
          id={selectedDeleteFullData.map((pending) => pending.departmentId).join(',')}
          okFunction={deleteRecord}
          isMultiSelect
        />
      ),
    });
    setModalOpen(true);
  };
  const actionButtons = [
    {
      label: t('view'),
      enable: checkPermission('pers:tempPerson:view', loadMenus),
      function: viewRecord,
    },
    {
      label: t('delete'),
      enable: checkPermission('pers:tempPerson:del', loadMenus),
      function: deleteRecord,
    },
  ];

  const getFilterData = (filter: any): any => {
    if (filter.pageIndex > 0) {
      dispatch(setSearchFilter(filter));
      fetchPendingReviewData({
        pageNo: filter.pageIndex,
        pageSize: filter.pageSize,
        ...filter.filter,
      });
    }
  };

  return (
    <div className="pending-review">
      <ModalBox
        status={modalOpen}
        closeModal={() => setModalOpen(false)}
        title={modalView.title}
        content={modalView.content}
      />
      <TopHeader
        title={t('pendingReview')}
        broadCram={[
          {
            title: t('personnel'),
          },
          {
            title: t('pendingReview'),
          },
        ]}
      />
      <CardBox
        title={t('listOfPendingReview')}
        rightSide={
          <div className="delete-icon">
            <CardRightButtonGroup
              showDeleteButton
              buttonEnable={checkPermission('pers:position:add', loadMenus)}
              DeleteButtonIcon={<DeleteButtonIcon />}
              deleteButtonLabel={t('delete')}
              deleteButtonFunction={handleDeleteClick}
              deleteButtonEnable
              deleteButtonClass="multi-delete-button"
            />
          </div>
        }>
        <DataTable
          actionButtonStatus
          action={actionButtons}
          columns={columnsField}
          getFilterData={getFilterData}
          enablePagination
          assignFilterValue={searchFilter}
          assignSelectedData={[]}
          getSelectedData={(val: any): any => console.log(val)}
          getSelectedFullData={(val: any): any => {
            setSelectedDeleteFullData(val);
          }}
          checkBoxActive
          values={pendingReviews}
          fixedColumn={['pin']}
          isFetching={status === 'loading'}
          totalRecords={totalDataCount}
          height={411}
        />
      </CardBox>
    </div>
  );
}

export default PendingReview;
