import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useAppDispatch } from '../../../../../redux/store';
import Label from '../../../../../shared-components/label/Label';
import InputField from '../../../../../shared-components/input-field/input-field';
import Button from '../../../../../shared-components/button/button';
import { fileDownload, getCurrentDateTimeWithTimezone } from '../../../../../utils/helpers';
import authorizedDevice from '../../../../../utils/api/SystemManagement/authorizedDevice';
import { setNotificationError } from '../../../../../redux/slices/NotificationSlices';
import { ExportModalType } from '../../../../../interface/System/authorizedDevice.interface';

/**
 * Component for exporting key files for selected devices.
 *
 * @component
 * @param {Object} props - Component props
 * @param {Array} props.selectedDevices - List of selected device IDs
 * @param {Function} props.closeModal - Function to close the modal
 * @returns {JSX.Element} ExportKeyFile component
 */
function ExportKeyFile({ selectedDevices, closeModal }: ExportModalType): JSX.Element {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const [activeTime, setActiveTime] = useState('');
  const [disableButton, setDisableButton] = useState(true);

  /**
   * Handles the submission of the export key file request.
   */
  const submit = async () => {
    setDisableButton(true);

    // Create a new FormData object
    const formData = new FormData();

    // Append the attrs array to the FormData
    formData.append('activeTime', activeTime);
    formData.append('ids', selectedDevices?.join(','));

    try {
      const response: any = await authorizedDevice.exportKey(formData);

      if (response.status === 200) {
        const blobData = await response.blob(); // Convert the response to a blob
        const currentDateTime = getCurrentDateTimeWithTimezone(); // Use helper function
        fileDownload(blobData, `Authorization device_${currentDateTime}.zip`);
        closeModal();
      } else {
        // Handle error response
        dispatch(
          setNotificationError({
            error: response.message || 'An error occurred',
            status: response.status,
          }),
        );
        throw new Error(`The data is empty and cannot be exported!`);
      }
    } catch (error: any) {
      dispatch(
        setNotificationError({
          error: error.message || 'An unexpected error occurred',
          status: error.status || 500,
        }),
      );
    } finally {
      setDisableButton(false); // Re-enable the button in both success and error cases
    }
  };

  useEffect(() => {
    if (activeTime === '') {
      setDisableButton(true);
    } else {
      setDisableButton(false);
    }
  }, [activeTime]);
  return (
    <div className="export-key-file">
      <div className="form-wrapper">
        <Label title={`${t('noOfDeviceSelected: ')} : ${selectedDevices?.length}`} />
        <InputField
          id="activeTime"
          name="activeTime"
          label={t('activeTime')}
          type="text"
          value={activeTime}
          onChangeFunction={(name, value) => setActiveTime(value)}
          validation
          message="hours(1-72)"
          required
        />
      </div>
      <div className="form-buttons-right">
        <Button
          onClickFunction={() => {
            closeModal();
          }}
          title={t('cancel')}
          className="btn-outline-primary"
        />
        <Button
          onClickFunction={submit}
          title={t('export')}
          className="btn-primary"
          disabled={disableButton}
        />
      </div>
    </div>
  );
}

export default ExportKeyFile;
