import { get, post } from '../base/index';

export default {
  saveList: (params: any = {}) => {
    return post(`${process.env.REACT_APP_API_URL}Sys/api/paramSetting/save`, params);
  },

  importPrivacyTxt: (data: any) => {
    return post(`${process.env.REACT_APP_API_URL}Sys/api/paramSetting/importPrivacyTxt`, data);
  },

  viewPrivacyTxt: (data: any = {}) => {
    return post(
      `${process.env.REACT_APP_API_URL}Sys/api/paramSetting/viewPrivacyTxt?privacyVal=${data}`,
      {},
    );
  },

  paramSetting: () => {
    return get(`${process.env.REACT_APP_API_URL}Sys/api/paramSetting/index`, {});
  },
};
