import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
// Import shared components
import { useAppDispatch, useAppSelector } from '../../redux/store';
import TopHeader from '../../shared-components/top-header/top-header';
import CardBox from '../../shared-components/card/card';
import DataTable from '../../shared-components/data-table/data-table';
import DeleteModalBox from '../../shared-components/delete-modal-box/delete-modal-box';
import ModalBox from '../../shared-components/modal-box/modal-box';
import CardRightButtonGroup from '../../shared-components/card-right-button-group/card-right-button-group';
import { DeleteButtonIcon, ExportIcon } from '../../assets/svgicons/svgicon';
import AccessByLevelExport from './components/export-data';
import AddPersonnel from './components/add-personnel';
import accessByLevel from '../../utils/api/AccessRule/accessByLevel';
import {
  fetchAccessByLevels,
  fetchAccessDeptLevel,
  setLevelSearchFilter,
  deleteLevels as deleteLevelList,
  removeLevel,
} from '../../redux/slices/AccessByLevelSlices';
import { DepartmentParamsProps } from '../../interface/accessByDepartment.interface';
import { PersonProps } from '../../interface/accessByPerson.interface';
import { setNotificationError } from '../../redux/slices/NotificationSlices';
import { checkPermission } from '../../utils/role-permission';

interface LevelProps {
  pageNo: number;
  levelId?: string;
}
interface DeleteLevelProps {
  pin: string;
  id: string | string[];
}
function SetAccessByLevels() {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const {
    levelList,
    levelId,
    levels,
    totalDataCount,
    status,
    searchFilter,
    levelTotalDataCount,
    levelSearchFilter,
  } = useAppSelector((state: any) => state.accessByLevel);

  /**
   * Selects the `loadMenus` permission from the authentication slice of the Redux store.
   *
   * @returns {Object} loadMenus - The user's menu permissions.
   */
  const { loadMenus } = useAppSelector((state) => state.auth);

  const [modalOpen, setModalOpen] = useState(false);
  const [modalView, setModalView] = useState({
    title: '',
    content: <>hi</>,
  });
  const [selectedValues, setSelectedValues] = useState<string[]>([]);
  const [selectedFullData, setSelectedFullData] = useState<any>([]);
  const [personnelSelect, setPersonnelSelect] = useState<string[]>([]);
  const [selectedPersonFullData, setSelectedPersonFullData] = useState<any[]>([]);

  // let handleEditOpen: any;
  const columnsField = [
    {
      accessorKey: 'name',
      header: t('levelName'),
    },
    {
      accessorKey: 'authAreaName',
      header: t('areaName'),
    },
    {
      accessorKey: 'timeSegName',
      header: t('timeZone'),
    },
  ];
  const personnelColumn = [
    {
      accessorKey: 'personPin',
      header: t('personnelId'),
    },
    {
      accessorKey: 'personName',
      header: t('firstName'),
    },
    {
      accessorKey: 'personLastName',
      header: t('lastName'),
    },
    {
      accessorKey: 'deptName',
      header: t('department'),
    },
  ];

  const handleEditOpen = (val: PersonProps): void => {
    setModalView({
      title: t('addPersonnel'),
      content: <AddPersonnel closeModal={() => setModalOpen(false)} personIds={val.id} />,
    });
    setModalOpen(true);
  };
  const fetchData = (params: DepartmentParamsProps) => {
    if (status === 'success') {
      dispatch(fetchAccessByLevels({ ...params }));
    }
  };

  // level functions
  const fetchLevels = (params: LevelProps) => {
    if (params.levelId) {
      dispatch(fetchAccessDeptLevel({ ...params }));
    }
  };

  const getFilterData = (filter: any): any => {
    if (filter.pageIndex > 0) {
      dispatch(setLevelSearchFilter(filter));
      fetchLevels({ pageNo: filter.pageIndex, pageSize: filter.pageSize, ...filter.filter });
    }
  };

  // department functions
  const getSelected = (id: string[]) => {
    setSelectedValues(id);
    fetchLevels({ levelId: id?.[0], pageNo: 1 });
    if (id?.length === 0) {
      dispatch(removeLevel());
    }
  };
  const getFilterLevels = (filter: any): any => {
    if (filter.pageIndex > 0) {
      dispatch(setLevelSearchFilter(filter));
      fetchData({ pageNo: filter.pageIndex, pageSize: filter.pageSize, ...filter.filter });
    }
  };
  const deleteLevels = (params: DeleteLevelProps) => {
    const req = {
      levelId: selectedValues?.[0],
      personIds: params,
    };
    accessByLevel
      .deleteLevel(req)
      .then((response) => {
        if (response.data.success) {
          dispatch(
            setNotificationError({
              error: t('levelDeletedSuccessfully'),
              status: 200,
            }),
          );
          dispatch(deleteLevelList(levelId));
          setModalOpen(false);
          fetchLevels({ levelId: selectedValues[0], pageNo: 1 });
        } else {
          dispatch(
            setNotificationError({
              error: response.data.message,
              status: response.data.code,
            }),
          );
          setModalOpen(false);
        }
      })
      .catch((error) => {
        dispatch(
          setNotificationError({
            error: error.message,
            status: error.status,
          }),
        );
        setModalOpen(false);
      });
  };
  const handleDeletePersonClick = () => {
    if (selectedPersonFullData.length === 0) {
      dispatch(
        setNotificationError({
          error: t('pleaseSelectAtLeastOneDoor'),
          status: 400,
        }),
      );
      return;
    }

    setModalOpen(true);
    setModalView({
      title: t('delete'),
      content: (
        <DeleteModalBox
          closeModal={() => setModalOpen(false)}
          id={selectedPersonFullData.map((val) => val?.id).join(',')}
          okFunction={deleteLevels}
          isMultiSelect
        />
      ),
    });
  };

  const actionButtons = [
    {
      label: t('addPersonnel'),
      enable: checkPermission('acc:personLevelByLevel:addPerson', loadMenus),
      function: (val: any) => handleEditOpen(val),
    },
  ];
  const levelActionButtons = [
    {
      label: t('deleteFromDefaultLevels'),
      enable: checkPermission('acc:personLevelByLevel:delPerson', loadMenus),
      function: (val: any) => {
        setModalOpen(true);
        setModalView({
          title: t('deleteDepartment'),
          content: (
            <DeleteModalBox
              closeModal={() => setModalOpen(false)}
              id={val.id}
              okFunction={deleteLevels}
            />
          ),
        });
      },
    },
  ];
  const exportData = (data: any) => {
    console.log(data);
    setModalOpen(false);
  };
  const handleExport = () => {
    setModalOpen(true);
    setModalView({
      title: t('exportPersonnelOfAccessLevel'),
      content: (
        <AccessByLevelExport closeModal={() => setModalOpen(false)} submitFunction={exportData} />
      ),
    });
  };

  const selectedLevelName = selectedFullData.slice(selectedFullData.length - 1);

  const enableExportPerson = checkPermission('acc:personLevelByLevel:export', loadMenus) || false;

  return (
    <div className="access-by-level">
      <ModalBox
        status={modalOpen}
        closeModal={() => setModalOpen(false)}
        title={modalView.title}
        content={modalView.content}
      />
      <TopHeader
        title={t('access')}
        broadCram={[
          {
            title: t('accessDevice'),
          },
          {
            title: t('setaccessByLevels'),
          },
        ]}
      />
      <CardBox
        header={t('accessLevels')}
        title={t('listOfAccessLevels')}
        rightSide={<CardRightButtonGroup buttonStatus={false} />}>
        {enableExportPerson && (
          <div className="action-wrapper">
            <div className="action-btn" role="presentation" onClick={handleExport}>
              <ExportIcon />
              <span className="label">{t('exportPersonnelOfAccessLevel')}</span>
            </div>
          </div>
        )}
        <DataTable
          actionButtonStatus={!modalOpen} // Disable action buttons when modal is open
          action={actionButtons}
          columns={columnsField}
          getFilterData={getFilterLevels}
          assignFilterValue={searchFilter}
          assignSelectedData={selectedValues}
          getSelectedData={(val: any): any => getSelected(val)}
          getSelectedFullData={(val: any): any => setSelectedFullData(val)}
          checkBoxActive
          singleRowSelection
          enablePagination
          isFetching={status === 'loading'} // Placeholder status
          values={levelList} // Use the actual data here
          fixedColumn={['name']}
          totalRecords={totalDataCount}
          height={411}
        />
        <hr className="line" />
        <div className="browse-table">
          <div className="door-header">
            <div className="header">
              <h4>Browse Personnel ({selectedLevelName?.[0]?.authAreaName}) From Levels</h4>
              <span className="sub-title">{t('listOfPersonnel')}</span>
            </div>
            <div className="delete-icon">
              <CardRightButtonGroup
                DeleteButtonIcon={<DeleteButtonIcon />}
                deleteButtonLabel={t('delete')}
                showDeleteButton
                deleteButtonFunction={handleDeletePersonClick}
                deleteButtonEnable={checkPermission('acc:level:delDoor', loadMenus)}
                deleteButtonClass="multi-delete-button"
              />
            </div>
          </div>
          <DataTable
            actionButtonStatus={!modalOpen} // Disable action buttons when modal is open
            action={levelActionButtons}
            columns={personnelColumn}
            getFilterData={getFilterData}
            assignFilterValue={levelSearchFilter}
            assignSelectedData={personnelSelect}
            getSelectedData={(val: any): any => setPersonnelSelect(val)}
            getSelectedFullData={(val: any): any => {
              setSelectedPersonFullData(val);
            }}
            checkBoxActive
            isFetching={status === 'loading'} // Placeholder status
            values={levels}
            fixedColumn={['id']}
            totalRecords={levelTotalDataCount}
            height={411}
          />
        </div>
      </CardBox>
    </div>
  );
}

export default SetAccessByLevels;
