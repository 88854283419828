import { get, post } from '../base/index';

export default {
  getAuthSystemList: () => {
    return get(`${process.env.REACT_APP_API_URL}Auth/api/authRole/getAuthSystemList`, {});
  },
  getTreeList: (data: any = {}) => {
    return get(`${process.env.REACT_APP_API_URL}Auth/api/authRole/getTreeLists`, data);
  },
  addRole: (data: any = {}) => {
    return post(`${process.env.REACT_APP_API_URL}Auth/api/authRole/save`, data, {});
  },
  deleteRole: (data: any = {}) => {
    return post(`${process.env.REACT_APP_API_URL}Auth/api/authRole/delete`, {}, data);
  },
  getRoleList: (data: any = {}) => {
    return post(`${process.env.REACT_APP_API_URL}Auth/api/authRole/listByPage`, {}, data);
  },
};
