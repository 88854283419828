import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
// Import shared components
import { useAppDispatch, useAppSelector } from '../../redux/store';
import TopHeader from '../../shared-components/top-header/top-header';
import CardBox from '../../shared-components/card/card';
import DataTable from '../../shared-components/data-table/data-table';
import ModalBox from '../../shared-components/modal-box/modal-box';
import CardRightButtonGroup from '../../shared-components/card-right-button-group/card-right-button-group';
import { AddIcon, DeleteButtonIcon } from '../../assets/svgicons/svgicon';
import AddMultiPersonGroup from './components/AddMultiPersonGroup';
import DeleteModalBox from '../../shared-components/delete-modal-box/delete-modal-box';
import AddPersonnel from './components/AddPersonnel';
import multiPersonGroupApi from '../../utils/api/AccessRule/multiPersonGroup';
import { setNotificationError } from '../../redux/slices/NotificationSlices';

import {
  MultiLevelApiProps,
  MultiDeleteLevelProps,
  MultiPersonProps,
} from '../../interface/multiPersonGroup.interface';

import {
  fetchMultiPersonGroup,
  fetchPersonGroupList,
  setSearchFilter,
  setLevelSearchFilter,
  deleteMultiPerson,
  resetLevels,
  deleteLevels as deleteLevelList,
} from '../../redux/slices/MultiPersonGroupSlices';
import { checkPermission } from '../../utils/role-permission';

function MultiPersonGroup() {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const {
    multiPersonGroup,
    personId,
    levels,
    levelStatus: status,
    totalDataCount,
    levelSearchFilter: searchFilter,
  } = useAppSelector((state: any) => state.multiPersonGroup);

  /**
   * Selects the `loadMenus` permission from the authentication slice of the Redux store.
   *
   * @returns {Object} loadMenus - The user's menu permissions.
   */
  const { loadMenus } = useAppSelector((state) => state.auth);

  const [modalOpen, setModalOpen] = useState(false);
  const [modalView, setModalView] = useState({
    title: '',
    content: <>hi</>,
  });
  const fetchData = async (params: any) => {
    if (status === 'success') {
      dispatch(fetchMultiPersonGroup({ ...params }));
    }
  };
  const [selectedPerson, setSelectPerson] = useState<string[]>([]); // Add state to hold selected cards
  const [selectedLevel, setSelectedLevel] = useState<string[]>([]); // Add state to hold selected cards
  const [selectedData, setSelectedData] = useState<any[]>([]); // Add state to hold selected cards
  const [selectedDoorFullData, setSelectedDoorFullData] = useState<any[]>([]);
  const [selectedLevelFullData, setSelectedLevelFullData] = useState<any[]>([]);

  let handleEditOpen: any;

  const canEdit = checkPermission('acc:combOpenPerson:edit', loadMenus) || true;

  const columnsField = [
    {
      accessorKey: 'name',
      header: t('groupName'),
      Cell: ({ row }: any) => (
        <div
          className={canEdit ? 'list-hyperlink' : ''}
          role="presentation"
          onClick={(event) => {
            if (!canEdit) return;
            event.stopPropagation(); // Prevent row selection
            handleEditOpen(row.original); // Call your edit function
          }}>
          {row?.original?.name}
        </div>
      ),
    },
    {
      accessorKey: 'remark',
      header: t('remark'),
    },
  ];

  const personnelColumn = [
    {
      accessorKey: 'personPin',
      header: t('personnelId'),
    },
    {
      accessorKey: 'personName',
      header: t('firstName'),
    },
    {
      accessorKey: 'personLastName',

      header: t('lastName'),
    },
    {
      accessorKey: 'deptName',
      header: t('department'),
    },
  ];

  const addPersonnel = (val: MultiPersonProps): void => {
    setModalView({
      title: t('addPersonnel'),
      content: (
        <AddPersonnel
          closeModal={() => setModalOpen(false)}
          personIds={val.id}
          combOpenPersonId={val.id}
        />
      ),
    });

    setModalOpen(true);
  };

  const getFilterData = (filter: any): any => {
    if (filter.pageIndex > 0) {
      dispatch(setSearchFilter(filter));
      fetchData({ pageNo: filter.pageIndex, pageSize: filter.pageSize, ...filter.filter });
    }
  };

  const fetchLevels = async (params: MultiLevelApiProps) => {
    if (params.accCombOpenPersonId) {
      dispatch(fetchPersonGroupList({ ...params }));
    }
  };

  const handleSelectPerson = (selectedIds: string[]): void => {
    setSelectedLevel(selectedIds);
    const lastSelectedId = selectedIds.at(-1);
    if (selectedIds.length === 1 && lastSelectedId) {
      fetchLevels({
        accCombOpenPersonId: lastSelectedId,
        personId: lastSelectedId,
        pageNo: 1,
      });
    } else if (selectedIds.length === 0) {
      setSelectedData([]);
      dispatch(resetLevels());
    }
  };
  const getLevelFilter = (filter: any): any => {
    if (filter.pageIndex > 0) {
      dispatch(setLevelSearchFilter(filter));
      fetchLevels({ pageNo: filter.pageIndex, pageSize: filter.pageSize, ...filter.filter });
    }
  };

  handleEditOpen = (val: any) => {
    setModalOpen(true);
    setModalView({
      title: t('edit'),

      content: <AddMultiPersonGroup closeModal={() => setModalOpen(false)} data={val} />,
    });
  };

  const deleteRecord = (id: any) => {
    multiPersonGroupApi
      .deleteMultiPerson(id)
      .then((response) => {
        if (response.data.success) {
          dispatch(
            setNotificationError({
              error: t('multiPersonGroupDeletedSuccessfully'),
              status: 200,
            }),
          );
          dispatch(deleteMultiPerson(id));
          setModalOpen(false);
          fetchData({
            pageNo: searchFilter.pageIndex,
            pageSize: searchFilter.pageSize,
          });
        } else {
          dispatch(
            setNotificationError({
              error: response.data.message,
              status: response.data.code,
            }),
          );
        }
      })
      .catch((error) => {
        dispatch(
          setNotificationError({
            error: error.message,
            status: error.response?.status || 500,
          }),
        );
      });
  };

  const deleteLevels = (params: MultiDeleteLevelProps) => {
    const req = {
      combOpenId: selectedLevel?.[0],
      personIds: params,
    };
    multiPersonGroupApi
      .delPerson(req)
      .then((response) => {
        if (response.data.success) {
          dispatch(
            setNotificationError({
              error: t('personDeletedSuccessfully'),

              status: 200,
            }),
          );
          dispatch(deleteLevelList(params.linkId));
          setModalOpen(false);
          fetchLevels({
            pageNo: 1,
            personId,
            accCombOpenPersonId: params.linkId,
          });
        } else {
          dispatch(
            setNotificationError({
              error: response.data.message,
              status: response.data.code,
            }),
          );
          setModalOpen(false);
        }
      })
      .catch((error) => {
        dispatch(
          setNotificationError({
            error: error.message,

            status: error.status,
          }),
        );
        setModalOpen(false);
      });
  };
  const handleDeleteClick = () => {
    if (selectedDoorFullData.length === 0) {
      dispatch(
        setNotificationError({
          error: t('pleaseSelectAtLeastOneGroup'), // Also fixed the error message
          status: 400,
        }),
      );
      return;
    }

    setModalOpen(true);
    setModalView({
      title: t('delete'),
      content: (
        <DeleteModalBox
          closeModal={() => setModalOpen(false)}
          id={selectedDoorFullData.join(',')}
          okFunction={deleteRecord}
          isMultiSelect
        />
      ),
    });
  };
  const actionButtons = [
    {
      label: t('edit'),
      enable: checkPermission('acc:combOpenPerson:edit', loadMenus),
      function: handleEditOpen,
    },
    {
      label: t('delete'),
      enable: checkPermission('acc:combOpenPerson:del', loadMenus),
      function: (val: any) => {
        setModalOpen(true);
        setModalView({
          title: t('deleteMultiPersonGroup'),
          content: (
            <DeleteModalBox
              closeModal={() => setModalOpen(false)}
              id={val.id}
              okFunction={deleteRecord}
            />
          ),
        });
      },
    },
    {
      label: t('addPersonnel'),
      enable: checkPermission('acc:combOpenPerson:addPerson', loadMenus),
      function: (val: any) => addPersonnel(val),
    },
  ];
  const handleDeleteLevelClick = () => {
    if (selectedLevelFullData.length === 0) {
      dispatch(
        setNotificationError({
          error: t('pleaseSelectAtLeastOneDoor'),
          status: 400,
        }),
      );
      return;
    }
    setModalOpen(true);
    setModalView({
      title: t('delete'),
      content: (
        <DeleteModalBox
          closeModal={() => setModalOpen(false)}
          id={selectedLevelFullData.map((val) => val.id).join(',')}
          okFunction={deleteLevels}
          isMultiSelect
        />
      ),
    });
  };
  const levelActionButtons = [
    {
      label: t('deleteFromDefaultLevels'),
      enable: checkPermission('acc:combOpenPerson:delPerson', loadMenus),
      function: (val: any) => {
        setModalOpen(true);
        setModalView({
          title: t('deleteDepartment'),
          content: (
            <DeleteModalBox
              closeModal={() => setModalOpen(false)}
              id={val.id}
              okFunction={deleteLevels}
            />
          ),
        });
      },
    },
  ];

  const selectedLevelName = selectedData.slice(selectedData.length - 1);
  return (
    <div className="access-by-level">
      <ModalBox
        status={modalOpen}
        closeModal={() => setModalOpen(false)}
        title={modalView.title}
        content={modalView.content}
      />
      <TopHeader
        title={t('access')}
        broadCram={[
          {
            title: t('accessRule'),
          },
          {
            title: t('multiPersonGroup'),
          },
        ]}
      />
      <CardBox
        header={t('openDoorGroup')}
        title={t('listOfDoors')}
        rightSide={
          <div className="delete-icon">
            <CardRightButtonGroup
              buttonIcon={<AddIcon color="#ffffff" />}
              buttonLabel={t('new')}
              buttonClass="btn-primary btn-sm"
              buttonStatus
              buttonFunction={(): void => {
                setModalOpen(true);
                setModalView({
                  title: t('new'),
                  content: <AddMultiPersonGroup closeModal={() => setModalOpen(false)} />,
                });
              }}
              buttonEnable={checkPermission('acc:combOpenPerson:add', loadMenus)}
              // Add delete button configuration
              DeleteButtonIcon={<DeleteButtonIcon />}
              deleteButtonLabel={t('delete')}
              showDeleteButton
              deleteButtonFunction={handleDeleteClick}
              deleteButtonEnable={checkPermission('acc:combOpenPerson:del', loadMenus)}
              deleteButtonClass="multi-delete-button"
            />
          </div>
        }>
        <DataTable
          actionButtonStatus={!modalOpen} // Disable action buttons when modal is open
          action={actionButtons}
          columns={columnsField}
          getFilterData={getLevelFilter}
          assignFilterValue={searchFilter}
          assignSelectedData={selectedLevel} // Assign selected cards
          getSelectedData={(val: any): any => {
            handleSelectPerson(val); // Set selected cards
            setSelectedDoorFullData(val);
          }}
          checkBoxActive
          singleRowSelection
          enablePagination
          isFetching={status === 'loading'} // Placeholder status
          values={multiPersonGroup} // Use the actual data here
          fixedColumn={['name']}
          totalRecords={totalDataCount}
          height={411}
        />
        <hr className="line" />
        <div className="browse-table">
          <div className="header">
            <h4>Browse Personnel ({selectedLevelName?.[0]?.personPin}) From Levels</h4>
          </div>
          <div className="door-header">
            <span className="sub-title">{t('listOfPersonnel')}</span>
            <div className="delete-icon">
              <CardRightButtonGroup
                DeleteButtonIcon={<DeleteButtonIcon />}
                deleteButtonLabel={t('delete')}
                showDeleteButton
                deleteButtonFunction={handleDeleteLevelClick}
                deleteButtonEnable={checkPermission('acc:combOpenPerson:delPerson', loadMenus)}
                deleteButtonClass="multi-delete-button"
              />
            </div>
          </div>
          <DataTable
            actionButtonStatus={!modalOpen} // Disable action buttons when modal is open
            action={levelActionButtons}
            columns={personnelColumn}
            getFilterData={getFilterData}
            assignFilterValue={searchFilter}
            // assignSelectedData={personnelSelect}
            assignSelectedData={selectedPerson} // Assign selected cards
            getSelectedData={(val: any): any => setSelectPerson(val)} // Set selected cards
            getSelectedFullData={(val: any[]): any => {
              setSelectedLevelFullData(val);
            }}
            checkBoxActive
            isFetching={status === 'loading'} // Placeholder status
            values={levels}
            // Use the actual data here
            fixedColumn={['id']}
            totalRecords={0}
            height={411}
          />
        </div>
      </CardBox>
    </div>
  );
}

export default MultiPersonGroup;
