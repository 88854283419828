import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import InputField from '../../../shared-components/input-field/input-field';
import { ResetPwdProps } from '../../../interface/login.interface';
import { hideIcon, viewIcon } from '../../../assets/svgicons/svgicon';
import Button from '../../../shared-components/button/button';
import auth from '../../../utils/api/auth';
import { useAppDispatch } from '../../../redux/store';
import { setNotificationError } from '../../../redux/slices/NotificationSlices';

/**
 * Validates password strength based on the following criteria:
 * - Weak: At least 8 characters with at least 2 of the following: numbers, lowercase letters, uppercase letters, special characters
 * - Medium: At least 8 characters with either (numbers + lowercase) or (numbers + uppercase)
 * - Strong: At least 8 characters with at least 3 of the following: numbers, lowercase letters, uppercase letters, special characters
 *
 * @param {string} password - The password to validate
 * @returns {string} - 'None', 'Weak', 'Medium', or 'Strong'
 */
const validatePassword = (password: string): string => {
  if (!password || password.length < 8) {
    return 'None';
  }

  const hasNumbers = /[0-9]/.test(password);
  const hasLowerCase = /[a-z]/.test(password);
  const hasUpperCase = /[A-Z]/.test(password);
  const hasSpecialChars = /[!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?]/.test(password);

  const characterTypeCount = [hasNumbers, hasLowerCase, hasUpperCase, hasSpecialChars].filter(
    Boolean,
  ).length;

  if (characterTypeCount >= 3) {
    return 'Strong';
  }

  if (characterTypeCount === 2) {
    if ((hasNumbers && hasLowerCase) || (hasNumbers && hasUpperCase)) {
      return 'Medium';
    }
  }

  if (characterTypeCount >= 2) {
    return 'Weak';
  }

  return 'None';
};

/**
 * Compares two password strengths and returns if the first meets or exceeds the second
 * @param {string} actualStrength - The actual password strength ('None', 'Weak', 'Medium', 'Strong')
 * @param {string} requiredStrength - The minimum required strength ('Weak', 'Medium', 'Strong')
 * @returns {boolean} - Whether the actual strength meets or exceeds the required strength
 */
const meetsStrengthRequirement = (actualStrength: string, requiredStrength: string): boolean => {
  const strengthValues = {
    None: 0,
    Weak: 1,
    Medium: 2,
    Strong: 3,
  };

  return (
    strengthValues[actualStrength as keyof typeof strengthValues] >=
    strengthValues[requiredStrength as keyof typeof strengthValues]
  );
};

function ResetPassword({ userName, closeModal }: any) {
  const dispatch = useAppDispatch();
  const [formData, setFormData] = useState<ResetPwdProps>({
    username: userName,
    password: '',
    newPwd: '',
    confirmPwd: '',
  });
  const [validMessages, setValidMessages] = useState({
    message1: '',
    message2: '',
    pwdStrength: '',
  });
  const [passwordsMatch, setPasswordsMatch] = useState(true);
  const [isPasswordVisible, setIsPasswordVisible] = useState(false);
  const [isNewPwdVisible, setIsNewPwdVisible] = useState(false);
  const [isConfirmPwdVisible, setIsConfirmPwdVisible] = useState(false);
  const [disableButton, setDisableButton] = useState(true);
  const [minRequiredStrength, setMinRequiredStrength] = useState('Weak');
  const [meetsMinRequirement, setMeetsMinRequirement] = useState(true);

  const { t } = useTranslation();

  const getPasswordMessage = (strength: string): string => {
    switch (strength) {
      case 'None':
        return t('passwordCondition');
      case 'Weak':
        return t('weakPasswordMessage');
      case 'Medium':
        return t('mediumPasswordMessage');
      case 'Strong':
        return t('strongPasswordMessage');
      default:
        return t('passwordCondition');
    }
  };

  const checkPasswordStrength = (password: string): void => {
    const strength = validatePassword(password);
    setValidMessages((prev) => ({
      ...prev,
      pwdStrength: strength, // This reflects the new password's strength
    }));

    // Check if the new password meets the minimum system requirement
    const meets = meetsStrengthRequirement(strength, minRequiredStrength);
    setMeetsMinRequirement(meets);
  };

  const handleInputChange = (name: string, value: any): void => {
    setFormData({
      ...formData,
      [name]: value,
    });

    if (name === 'newPwd') {
      checkPasswordStrength(value);

      if (formData.confirmPwd) {
        setPasswordsMatch(value === formData.confirmPwd);
      }
    }

    if (name === 'confirmPwd') {
      setPasswordsMatch(value === formData.newPwd);
    }
  };

  const handleSubmit = async () => {
    if (formData.newPwd !== formData.confirmPwd) {
      return;
    }

    const passwordStrength = validatePassword(formData.newPwd);
    if (passwordStrength === 'None') {
      return;
    }

    if (!meetsStrengthRequirement(passwordStrength, minRequiredStrength)) {
      return;
    }

    setDisableButton(true);
    try {
      const request = {
        oldPassword: formData.password,
        password: formData.newPwd,
        userName: formData.username,
      };
      const response = await auth.submitPwdChange(request);
      if (response?.data?.success) {
        dispatch(
          setNotificationError({
            error: response?.data?.msg,
            status: 200,
          }),
        );
        closeModal();
        setDisableButton(false);
      } else {
        setDisableButton(false);
        dispatch(
          setNotificationError({
            error: response?.data?.msg,
            status: response?.data?.status || 500,
          }),
        );
      }
    } catch (error: any) {
      setDisableButton(false);
      dispatch(
        setNotificationError({
          error: error.message,
          status: error.response?.status || 500,
        }),
      );
    }
  };

  const fetchInit = async () => {
    try {
      const response = await auth.initPassword({ userName });
      if (response?.data?.success) {
        setValidMessages((prev) => ({
          ...prev,
          message1: response?.data?.data?.message0,
          message2: response?.data?.data?.message1,
        }));

        // Set the minimum required strength from the API response
        const requiredStrength = response?.data?.data?.message2Leave || 'Weak';
        setMinRequiredStrength(requiredStrength);
      } else {
        dispatch(
          setNotificationError({
            error: response?.data?.msg,
            status: response?.data?.status || 500,
          }),
        );
      }
    } catch (error: any) {
      dispatch(
        setNotificationError({
          error: error.message,
          status: error.response?.status || 500,
        }),
      );
    }
  };

  useEffect(() => {
    if (
      formData.password &&
      formData.newPwd &&
      formData.confirmPwd &&
      passwordsMatch &&
      validatePassword(formData.newPwd) !== 'None' &&
      meetsMinRequirement
    ) {
      setDisableButton(false);
    } else {
      setDisableButton(true);
    }
  }, [formData, passwordsMatch, meetsMinRequirement]);

  useEffect(() => {
    fetchInit();
  }, [userName]);

  return (
    <div className="reset-password">
      <div className="pwd-description">
        <span>{validMessages?.message1}</span>
        <div>
          {validMessages?.message2}{' '}
          <span className="text-danger strong">{minRequiredStrength}</span>{' '}
          {/* Show system requirement */}
        </div>
        {validMessages?.pwdStrength !== 'Weak' && (
          <div className="text-danger pwd-valid">
            {getPasswordMessage(validMessages?.pwdStrength)} {/* Show new password strength */}
          </div>
        )}
      </div>
      <div className="input-box">
        <div className="input-wrap">
          <InputField
            name="username"
            label={t('username')}
            id="username"
            type="text"
            value={formData.username}
            innerIconStatus={false}
            className="username-input"
          />
          <InputField
            name="password"
            label={t('password')}
            id="password"
            type={isPasswordVisible ? 'text' : 'password'}
            value={formData.password}
            onChangeFunction={handleInputChange}
            innerIconStatus
            InnerIcon={isPasswordVisible ? viewIcon : hideIcon}
            innerIconFunction={() => setIsPasswordVisible(!isPasswordVisible)}
            className="password-input"
            required
            message={t('Invalid Password')}
          />
        </div>
        <div className="input-wrap">
          <InputField
            name="newPwd"
            label={t('newPassword')}
            id="newPwd"
            type={isNewPwdVisible ? 'text' : 'password'}
            value={formData.newPwd}
            onChangeFunction={handleInputChange}
            innerIconStatus
            InnerIcon={isNewPwdVisible ? viewIcon : hideIcon}
            innerIconFunction={() => setIsNewPwdVisible(!isNewPwdVisible)}
            className="password-input"
            required
            message={
              !meetsMinRequirement && formData.newPwd
                ? t('Password strength does not meet system requirements')
                : ''
            }
            validStatus={formData.newPwd ? meetsMinRequirement : true}
            errorStatus={!meetsMinRequirement && !!formData.newPwd}
          />
          <InputField
            name="confirmPwd"
            label={t('confirmPassword')}
            id="confirmPwd"
            type={isConfirmPwdVisible ? 'text' : 'password'}
            value={formData.confirmPwd}
            onChangeFunction={handleInputChange}
            innerIconStatus
            InnerIcon={isConfirmPwdVisible ? viewIcon : hideIcon}
            innerIconFunction={() => setIsConfirmPwdVisible(!isConfirmPwdVisible)}
            className="password-input"
            required
            message={!passwordsMatch ? t('Please enter the same value again') : ''}
            validStatus={formData.confirmPwd ? passwordsMatch : true}
            errorStatus={!passwordsMatch && !!formData.confirmPwd}
          />
        </div>
      </div>
      <div className="form-buttons-right">
        <Button
          title={t('done')}
          className="btn-primary"
          onClickFunction={handleSubmit}
          disabled={disableButton}
        />
      </div>
    </div>
  );
}

export default ResetPassword;
