import React, { useState } from 'react';

// Use Translation
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from '../../redux/store';

// Use own components
import BatchIssueCard from './components/batch-issue-card';

// Use shared components
import TopHeader from '../../shared-components/top-header/top-header';
import CardBox from '../../shared-components/card/card';
import DataTable from '../../shared-components/data-table/data-table';
import CardRightButtonGroup from '../../shared-components/card-right-button-group/card-right-button-group';
import ModalBox from '../../shared-components/modal-box/modal-box';
import CardExportData from './components/export-data';
import ConformationBox from '../../shared-components/conformation-box/conformation-box';
import { setNotificationError } from '../../redux/slices/NotificationSlices';

// Use assets svg icons
import { AddIcon } from '../../assets/svgicons/svgicon';

// Import card slice actions
import { fetchCardList, setSearchFilter } from '../../redux/slices/CardSlice';

import cardApi from '../../utils/api/card';
import { changeDateTimeFormat } from '../../utils/helpers';
import { checkPermission } from '../../utils/role-permission';

function CardManagement() {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { cards, status, totalDataCount, searchFilter } = useAppSelector(
    (state: any) => state.cards,
  );
  const { loadMenus } = useAppSelector((state) => state.auth);

  const [modalOpen, setModalOpen] = useState(false);
  const [modalView, setModalView] = useState({
    title: t('batchIssueCard'),
    content: <>hi</>,
  });
  const [selectedCards, setSelectedCards] = useState<string[]>([]); // Add state to hold selected cards

  const columnsField = [
    {
      accessorKey: 'cardNo',
      header: t('cardNumber'),
    },
    {
      accessorKey: 'personPin',
      header: t('personnelId'),
      size: 150,
    },
    {
      accessorKey: 'personName',
      header: t('firstName'),
      size: 150,
    },
    {
      accessorKey: 'personLastName',
      header: t('lastName'),
      size: 150,
    },
    {
      accessorKey: 'deptCode',
      header: t('departmentNumber'),
      size: 150,
    },
    {
      accessorKey: 'deptName',
      header: t('departmentName'),
    },
    {
      accessorKey: 'issueTime',
      header: t('issuedCardDate'),
      size: 200,
      Cell: ({ row }: any) => <div>{changeDateTimeFormat(row.original.issueTime)}</div>,
    },
    {
      accessorKey: 'cardState',
      header: t('cardStatue'),
    },
  ];

  const fetchData = async (params: any) => {
    try {
      if (status === 'success') {
        dispatch(fetchCardList({ ...params }));
      }
    } catch (error) {
      console.log(error);
    }
  };

  const exportData = () => {
    setModalOpen(false);
  };
  const reported = () => {
    const request = selectedCards.join('&ids=');

    cardApi
      .batchCardLoss(request)
      .then((response: any) => {
        if (response.data.code === 0) {
          dispatch(
            setNotificationError({
              error: t('reportedLostCardSuccess'),
              status: 200,
            }),
          );
          setModalOpen(false);
          setSelectedCards([]);
        } else {
          dispatch(
            setNotificationError({
              error: response.data.message,
              status: response.data.code,
            }),
          );
        }
      })
      .catch((error: any) => {
        dispatch(
          setNotificationError({
            error: error.message,
            status: error.response?.status || 500,
          }),
        );
        setSelectedCards([]);
      });
  };
  // reactivate api integartion
  const reactivate = () => {
    const request = selectedCards.join('&ids=');

    cardApi
      .batchCardReactivation(request)
      .then((response: any) => {
        if (response.data.code === 0) {
          dispatch(
            setNotificationError({
              error: t('reactivateLostCardSuccess'),
              status: 200,
            }),
          );
          setModalOpen(false);
          setSelectedCards([]);
        } else {
          dispatch(
            setNotificationError({
              error: response.data.message,
              status: response.data.code,
            }),
          );
        }
      })
      .catch((error: any) => {
        dispatch(
          setNotificationError({
            error: error.message,
            status: error.response?.status || 500,
          }),
        );
        setSelectedCards([]);
      });
  };
  const moreActionButton = [
    {
      title: t('batchIssueCard'),
      enable: checkPermission('pers:card:batchIssueCard', loadMenus),
      clickFunction: (): void => {
        setModalOpen(true);
        setModalView({
          title: t('batchIssueCard'),
          content: <BatchIssueCard closeModal={() => setModalOpen(false)} />,
        });
      },
    },
    {
      title: t('reportedLostCard'),
      enable: checkPermission('pers:card:batchCardLoss', loadMenus),
      clickFunction: (): void => {
        if (selectedCards.length > 0) {
          setModalOpen(true);
          setModalView({
            title: t('reported'),
            content: (
              <ConformationBox
                closeModal={() => setModalOpen(false)}
                okFunction={reported}
                title={t('areYouWantToPerformTheReportedLostCardOperation')}
                buttonLabel={t('ok')}
              />
            ),
          });
        } else {
          // Show an error notification when no person is selected
          dispatch(
            setNotificationError({
              error: t('pleaseSelectAtLeastOneCard'),
              status: 400, // Error status code
            }),
          );
        }
      },
    },
    {
      title: t('reactivateLostCard'),
      enable: checkPermission('pers:card:batchCardRevert', loadMenus),
      clickFunction: (): void => {
        if (selectedCards.length > 0) {
          setModalOpen(true);
          setModalView({
            title: t('reactivate'),
            content: (
              <ConformationBox
                closeModal={() => setModalOpen(false)}
                okFunction={reactivate}
                title={t('areYouWantToPerformTheReportedLostCardOperation')}
                buttonLabel={t('ok')}
              />
            ),
          });
        } else {
          // Show an error notification when no person is selected
          dispatch(
            setNotificationError({
              error: t('pleaseSelectAtLeastOneCard'),
              status: 400, // Error status code
            }),
          );
        }
      },
    },
    {
      title: t('export'),
      enable: checkPermission('pers:card:export', loadMenus),
      clickFunction: (): void => {
        setModalOpen(true);
        setModalView({
          title: t('export'),
          content: (
            <CardExportData closeModal={() => setModalOpen(false)} submitFunction={exportData} />
          ),
        });
      },
    },
  ];

  const getFilterData = (filter: any): any => {
    if (filter.pageIndex > 0) {
      dispatch(setSearchFilter(filter));
      fetchData({
        pageNo: filter.pageIndex,
        pageSize: filter.pageSize,
        ...filter.filter,
      });
    }
  };

  return (
    <div className="card-management">
      <ModalBox
        status={modalOpen}
        closeModal={() => setModalOpen(false)}
        title={modalView.title}
        content={modalView.content}
      />
      <TopHeader
        title={t('card')}
        broadCram={[
          {
            title: t('cardManagement'),
          },
          {
            title: t('card'),
          },
        ]}
      />
      <CardBox
        title={t('card')}
        rightSide={
          <CardRightButtonGroup
            actions={moreActionButton}
            buttonIcon={<AddIcon color="#ffffff" />}
            buttonLabel={t('add')}
            buttonClass="btn-primary btn-sm"
            buttonStatus={false}
            buttonFunction={(): void => {}}
          />
        }>
        <DataTable
          actionButtonStatus={false}
          columns={columnsField}
          getFilterData={getFilterData}
          assignFilterValue={searchFilter}
          assignSelectedData={selectedCards}
          getSelectedData={(val: any): any => setSelectedCards(val)}
          checkBoxActive
          enablePagination
          isFetching={status === 'loading'}
          values={cards}
          fixedColumn={['cardNo']}
          totalRecords={totalDataCount}
          height={411}
        />
      </CardBox>
    </div>
  );
}

export default CardManagement;
