import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import InputField from '../../../shared-components/input-field/input-field';
import Button from '../../../shared-components/button/button';
import ModalBox from '../../../shared-components/modal-box/modal-box';
import SelectLinkage from './SelectLinkageDevice';
import LinkageTriggerConnections from './LinkageTriggerConnections';
import CheckBoxInputField from '../../../shared-components/checkbox-input-field/checkbox-input-field';
import TabPages from '../../../shared-components/tab-pages/tab-pages';
import OutputPoint from './OutputPoint';
import VideoLinkage from './VideoLinkage';
import EmailLinkage from './EmailLinkage';
import Intrusion from './Intrusion';
import SendSms from './SendSms';
import Label from '../../../shared-components/label/Label';
import Line from './Line';
import Whatsapp from './whatsapp';
import { DeviceProps } from '../../../interface/accessByDepartment.interface';
import { useAppDispatch } from '../../../redux/store';
import { setNotificationError } from '../../../redux/slices/NotificationSlices';
import linkage from '../../../utils/api/AccessRule/linkage';
import { fetchLinkage } from '../../../redux/slices/LinkageSlices';

interface LinkageProps {
  closeModal: () => void;
  update?: any;
}

interface DoorEventProps {
  id: string;
  text: string;
}

/**
 * Transforms input data into a standardized array format
 * @param {any} data - Input data containing item, id and text properties
 * @returns {Array} Combined array of items and parent object
 */
const transformData = (data: any) => {
  const { item, id, text } = data;

  // Check if `item` is iterable (an array), fallback to an empty array if not
  const itemsArray = Array.isArray(item) ? item : [];

  // Combine child items with parent object
  return [...itemsArray, { id, text }];
};

/**
 * Transforms data array into options format with id, label and value
 * @param {any[]} data - Array of items to transform
 * @returns {Array<{id: string, label: string, value: string}>} Transformed options array
 */
const transformOptions = (data: any) => {
  return data.map((item: any) => ({
    id: item.id,
    label: item.text,
    value: item.id,
  }));
};

/**
 * AddLinkage component for creating and editing linkage configurations
 * @param {Object} props - Component props
 * @param {Function} props.closeModal - Function to close the modal
 * @param {any} [props.update] - Optional update data for editing existing linkage
 */
function AddLinkage({ closeModal, update }: LinkageProps) {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const [value, setValue] = useState(
    update || {
      name: '',
      device: '',
      trigger: [],
      inputPoint: [],
      outputAuxOutAddr: [],
      doorOutput: [],
      doorActionType: '0',
      auxoutActionType: '0',
      mailAddr: '',
      mobileNo: '',
      partitionActionType: '',
      partitionArmType: '',
      whatsappMobileNo: '',
      doorActionTime: '',
      auxoutActionTime: '',
      // video linkage state
      popUpVideo: [],
      record: [],
      popUpTime: '10',
      recordBeforeTime: '10',
      recordTime: '10',
      capture: [],
      capturePop: [],
      captureTime: '10',
    },
  );

  const [modalOpen, setModalOpen] = useState(false);
  const [modalView, setModalView] = useState({
    title: '',
    content: <>hi</>,
  });

  const [disable, setDisable] = useState(false);
  const [selectedDevices, setSelectedDevices] = useState<any[]>(update?.selectedDevices || []);
  const [triggerConditions, setTriggerConditions] = useState<any[]>(update?.triggerValue || []);
  const [auxInputs, setAuxInputs] = useState([]);
  const [inputPointOptions, setInputPointOptions] = useState([]);
  const [doorOutputOptions, setDoorOutputOptions] = useState([]);
  // const [tabs, setTabs] = useState([
  //   {
  //     key: 'outputPoint',
  //     title: t('outputPoint'),
  //     content: (
  //       <OutputPoint values={value} changeValue={() => {}} auxInputs={[]} doorOutputs={[]} />
  //     ),
  //   },
  // ]);
  // const [tabIndex, setTabIndex] = useState<any>();
  const [checkExistName, setCheckExistName] = useState(true);
  const [search, setSearch] = useState('');

  const onChangeValue = (name: string, data: any) => {
    setValue((prev: any) => ({
      ...prev,
      [name]: data,
    }));
  };

  const tableColumns = [
    { label: t('deviceName'), id: 'deviceAlias' },
    { label: t('serialNumber'), id: 'deviceSn' },
  ];

  const getSelectedDevice = (selected: DeviceProps[]) => {
    const deviceValue = selected.map((item: any) => ({
      label: item.deviceName,
      id: item.id,
      value: item.id,
    }));
    setSelectedDevices(deviceValue);
    setValue((prev: any) => ({
      ...prev,
      device: deviceValue?.[0]?.id,
    }));
  };

  const getInOut = (params: any) => {
    linkage
      .getInOutInfo(params)
      .then((res) => {
        // auxiliary output data
        const transformAuxInput = res?.data?.data?.auxOutOutputArray
          ? transformData(res?.data?.data?.auxOutOutputArray)
          : [];
        const auxOutputs: any =
          transformAuxInput.length > 0 ? transformOptions(transformAuxInput) : [];
        setAuxInputs(auxOutputs);

        // input point data
        const transformInput: any = transformOptions(res?.data?.data?.inputArray);
        setInputPointOptions(transformInput);

        // door data
        const transformDoors = res?.data?.data?.doorOutputArray?.item
          ? transformData(res?.data?.data?.doorOutputArray)
          : [];

        const doorOutput: any = transformOptions(
          transformDoors?.length > 0 ? transformDoors : res?.data?.data?.doorOutputArray,
        );
        setDoorOutputOptions(doorOutput);

        // setTabs([
        //   {
        //     key: 'outputPoint',
        //     title: t('outputPoint'),
        //     content: (
        //       <OutputPoint
        //         values={value}
        //         changeValue={onChangeValue}
        //         auxInputs={auxOutputs}
        //         doorOutputs={doorOutput}
        //       />
        //     ),
        //   },
        // ]);
      })
      .catch((error) => {
        dispatch(
          setNotificationError({
            error: error.msg,
            status: error.code || 500,
          }),
        );
      });
  };
  const handleClickDevice = () => {
    setModalView({
      title: t('selectLinkageDevice'),
      content: (
        <SelectLinkage
          searchValue={search}
          changeSearch={(name, val) => setSearch(val)}
          tableColumns={tableColumns}
          getSelected={(selected: DeviceProps[]) => getSelectedDevice(selected)}
          closeModal={() => setModalOpen(false)}
        />
      ),
    });
    setModalOpen(true);
  };
  /**
   * Handles adding new trigger conditions while preventing duplicates
   * @param {DoorEventProps[]} val - Array of door events containing id and text properties
   */
  const addTriggerCondition = (val: DoorEventProps[]) => {
    // Transform incoming door events into standardized trigger format
    const triggerValue = val.map((item) => ({
      label: item.text,
      id: item.id,
      value: item.id,
    }));

    // Update triggerConditions state by merging new triggers with existing ones
    // while filtering out duplicates based on ID
    setTriggerConditions((prev) => {
      const existingIds = new Set(prev.map((item) => item.id));
      const newTriggers = triggerValue.filter((item) => !existingIds.has(item.id));
      return [...prev, ...newTriggers];
    });

    // Extract IDs for API request
    const getId = val.map((ids) => ids.id);
    const inOutRequest = {
      devId: selectedDevices?.[0]?.id,
      triggerOpt: getId.toString(),
    };
    // Fetch input/output information based on selected triggers
    getInOut(inOutRequest);

    // Update the main form value state by merging new trigger IDs
    // with existing ones and removing duplicates
    setValue((prev: any) => {
      const existingTriggers = prev.trigger || [];
      const allTriggers = [...existingTriggers, ...triggerValue.map((item) => item.id)];
      const uniqueTriggers = [...new Set(allTriggers)];
      return {
        ...prev,
        trigger: uniqueTriggers,
      };
    });
  };

  const handleLinkageTrigger = () => {
    if (selectedDevices?.length === 0) {
      dispatch(
        setNotificationError({
          error: 'Please select the device',
          status: 500,
        }),
      );
    } else {
      setModalView({
        title: t('chooseLinkageTriggerConnections'),
        content: (
          <LinkageTriggerConnections
            closeModal={() => setModalOpen(false)}
            addTriggerCondition={addTriggerCondition}
            deviceId={selectedDevices?.[0]?.id}
            value={value.trigger}
          />
        ),
      });
      setModalOpen(true);
    }
  };

  const onBlur = async (name: string, data: any) => {
    if (name === 'name' && data !== '' && data !== update?.name) {
      const responseData = await linkage.isExistName(data);
      setCheckExistName(responseData?.data);
    }
  };

  const submit = () => {
    setDisable(true);
    const {
      name,
      device,
      trigger,
      inputPoint,
      doorOutput,
      doorActionType,
      auxoutActionType,
      mailAddr,
      mobileNo,
      whatsappMobileNo,
      outputAuxOutAddr,
      id,
      doorActionTime,
      auxoutActionTime,
      popUpVideo,
      record,
      popUpTime,
      recordBeforeTime,
      recordTime,
      capture,
      capturePop,
      captureTime,
    } = value;
    const request = {
      name,
      deviceId: device,
      triggerCond: trigger?.toString(),
      inputAddr: inputPoint?.toString(),
      outputDoorAddr: doorOutput?.toString(),
      outputAuxOutAddr: outputAuxOutAddr?.length > 0 ? outputAuxOutAddr?.toString() : '',
      doorActionType,
      auxoutActionType,
      partitionActionType: '',
      partitionArmType: '',
      mailAddr,
      mobileNo,
      whatsappMobileNo,
      id,
      doorActionTime,
      auxoutActionTime,
      popUpVideo: popUpVideo?.length > 0,
      record: record?.length > 0,
      popUpTime,
      recordBeforeTime,
      recordTime,
      capture: capture?.length > 0,
      capturePop: capturePop?.length > 0,
      captureTime,
    };

    linkage
      .save(request)
      .then((response) => {
        if (response?.data?.success) {
          setDisable(false);
          const message = update?.id
            ? t('linkageUpdatedSuccessfully')
            : t('linkageAddedSuccessfully');
          dispatch(
            setNotificationError({
              error: message,
              status: 200,
            }),
          );
          closeModal();
          dispatch(fetchLinkage({ pageNo: 1, pageSize: 10 }));
        } else {
          setDisable(false);
          dispatch(
            setNotificationError({
              error: response.data.message,
              status: response.data.code,
            }),
          );
        }
      })
      .catch((error) => {
        setDisable(false);
        dispatch(
          setNotificationError({
            error: error.message,
            status: error.status,
          }),
        );
      });
  };

  // useEffect(() => {
  //   setTabs();
  // }, [auxInputs, doorOutputOptions]);

  const getById = () => {
    // get in out data
    const inOutRequest = {
      devId: update?.deviceId,
      triggerOpt: update?.triggerCond,
      accLinkageId: update?.id,
    };
    getInOut(inOutRequest);
  };

  useEffect(() => {
    if (value.name && checkExistName && value.trigger?.length > 0 && value.inputPoint?.length > 0) {
      setDisable(false);
    } else {
      setDisable(true);
    }
  }, [value, checkExistName]);

  useEffect(() => {
    if (update?.id) {
      getById();
    }
  }, [update]);

  return (
    <div className="add-linkage flex-row column">
      <ModalBox
        status={modalOpen}
        closeModal={() => setModalOpen(false)}
        title={modalView.title}
        content={modalView.content}
      />
      <div className="flex-row" style={{ alignItems: 'center' }}>
        <div className="col">
          <InputField
            name="name"
            label={t('name')}
            id="name"
            type="text"
            value={value.name}
            innerIconStatus={false}
            onChangeFunction={onChangeValue}
            onBlurFunction={onBlur}
            errorStatus={value.name !== '' && !checkExistName}
            message={t('nameIsAlreadyExist')}
            required
          />
        </div>
        <div className="select-device-wrapper">
          <div className="select-device">
            <Button
              onClickFunction={handleClickDevice}
              title={t('selectDevice')}
              className="btn-outline-primary"
              disabled={update?.id}
            />
            <div className="device-name">
              <p className="label">{t('SelectedDevice')}</p>
              <h4 className="value">{selectedDevices?.[0]?.label}</h4>
            </div>
          </div>
        </div>
      </div>
      <div className="flex-row linkage-box">
        <div className="box-wrapper">
          <div className="header">
            <Label title={t('linkageTriggerConditions')} required />
            <span className="add-link" role="presentation" onClick={handleLinkageTrigger}>
              {t('add')}
            </span>
          </div>
          <div className="box">
            <CheckBoxInputField
              name="trigger"
              data={triggerConditions}
              value={value?.trigger}
              onChangeFunction={onChangeValue}
              alignment="column"
            />
          </div>
        </div>
        <div className="box-wrapper">
          <div className="header">
            <Label title={t('inputPoint')} required />
          </div>
          <div className="box">
            <CheckBoxInputField
              name="inputPoint"
              data={inputPointOptions}
              value={value?.inputPoint}
              onChangeFunction={onChangeValue}
              alignment="column"
            />
          </div>
        </div>
      </div>
      <div className="linkage-types">
        <TabPages
          tabs={[
            {
              key: 'outputPoint',
              title: t('outputPoint'),
              content: (
                <OutputPoint
                  values={value}
                  changeValue={onChangeValue}
                  auxInputs={auxInputs}
                  doorOutputs={doorOutputOptions}
                />
              ),
              enable: true,
            },
            {
              key: 'videoLinkage',
              title: t('videoLinkage'),
              enable: true,
              content: <VideoLinkage values={value} changeValue={onChangeValue} />,
            },
            {
              key: 'E-mail',
              title: t('E-mail'),
              enable: true,
              content: <EmailLinkage values={value} changeValue={onChangeValue} />,
            },
            {
              key: 'intrusion',
              title: t('intrusion'),
              enable: true,
              content: <Intrusion values={value} changeValue={onChangeValue} />,
            },
            {
              key: 'sendSMS',
              title: t('sendSMS'),
              enable: true,
              content: <SendSms values={value} changeValue={onChangeValue} />,
            },
            {
              key: 'line',
              title: t('line'),
              enable: true,
              content: <Line />,
            },
            {
              key: 'whatsapp',
              title: t('whatsapp'),
              enable: true,
              content: <Whatsapp values={value} changeValue={onChangeValue} />,
            },
          ]}
          defaultNumber={0}
        />
      </div>
      <div className="form-buttons-right">
        <Button
          onClickFunction={() => {
            closeModal();
          }}
          title={t('cancel')}
          className="btn-default"
        />
        <Button
          onClickFunction={submit}
          title={t('done')}
          className="btn-primary"
          disabled={disable}
        />
      </div>
    </div>
  );
}

AddLinkage.defaultProps = {
  update: [],
};

export default AddLinkage;
