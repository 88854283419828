import React from 'react';

// Use common modules
import { Navigate } from 'react-router-dom'; // Temp
import Cookies from 'universal-cookie';

function AuthGuard({ Component }: any) {
  const cookies = new Cookies();
  const isAuthenticated = cookies.get('token') && cookies.get('username');

  if (!isAuthenticated) {
    return <Navigate to="/login" replace />;
  }

  // Pass routePath to RoleBasedGua rd for permission check
  return Component;
}

export default AuthGuard;
