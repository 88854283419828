import { get, post } from '../base/index';

export default {
  getAllDoor: () => {
    return get(`${process.env.REACT_APP_API_URL}Acc/api/realTimeMonitor/getAllDoor`, {});
  },

  getAllAuxIn: () => {
    return get(`${process.env.REACT_APP_API_URL}Acc/api/realTimeMonitor/getAllAuxIn`, {});
  },

  getAllAuxOut: () => {
    return get(`${process.env.REACT_APP_API_URL}Acc/api/realTimeMonitor/getAllAuxOut`, {});
  },

  openDoor: (data: any = {}) =>
    post(`${process.env.REACT_APP_API_URL}Acc/api/realTimeMonitor/openDoor`, {}, data),

  closeDoor: (data: any = {}) =>
    post(`${process.env.REACT_APP_API_URL}Acc/api/realTimeMonitor/closeDoor`, {}, data),

  cancelAlarm: (data: any = {}) =>
    post(`${process.env.REACT_APP_API_URL}Acc/api/realTimeMonitor/cancelAlarm`, {}, data),

  lockDoor: (data: any = {}) =>
    post(`${process.env.REACT_APP_API_URL}Acc/api/realTimeMonitor/lockDoor`, {}, data),

  unLockDoor: (data: any = {}) =>
    post(`${process.env.REACT_APP_API_URL}Acc/api/realTimeMonitor/unLockDoor`, {}, data),

  normalOpenDoor: (data: any = {}) =>
    post(`${process.env.REACT_APP_API_URL}Acc/api/realTimeMonitor/normalOpenDoor`, {}, data),

  enableNormalOpenDoor: (data: any = {}) =>
    post(`${process.env.REACT_APP_API_URL}Acc/api/realTimeMonitor/enableNormalOpenDoor`, {}, data),

  disableNormalOpenDoor: (data: any = {}) =>
    post(`${process.env.REACT_APP_API_URL}Acc/api/realTimeMonitor/disableNormalOpenDoor`, {}, data),

  openAuxOut: (data: any = {}) =>
    post(`${process.env.REACT_APP_API_URL}Acc/api/realTimeMonitor/openAuxOut`, {}, data),

  closeAuxOut: (data: any = {}) =>
    post(`${process.env.REACT_APP_API_URL}Acc/api/realTimeMonitor/closeAuxOut`, {}, data),

  auxOutNormalOpen: (data: any = {}) =>
    post(`${process.env.REACT_APP_API_URL}Acc/api/realTimeMonitor/auxOutNormalOpen`, {}, data),
};
