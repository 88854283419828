import React, { useState } from 'react';

// Use Translation
import { useTranslation } from 'react-i18next';

// Use own components
import CardFormatTesting from './components/card-format-testing';
import AddWiegandFormat from './components/add-wiegand-format';

// Use shared components
import TopHeader from '../../shared-components/top-header/top-header';
import CardBox from '../../shared-components/card/card';
import DataTable from '../../shared-components/data-table/data-table';
import CardRightButtonGroup from '../../shared-components/card-right-button-group/card-right-button-group';
import ModalBox from '../../shared-components/modal-box/modal-box';
import DeleteModalBox from '../../shared-components/delete-modal-box/delete-modal-box';

// Use assets svg icons
import { AddIcon, DeleteButtonIcon } from '../../assets/svgicons/svgicon';

// Use assets json files
// import WiegandFormatData from '../../assets/json/wiegand-format-simple-data.json';
import { useAppDispatch, useAppSelector } from '../../redux/store';
import { WiegandFormatReduxInterface } from '../../interface/wiegandFormat.interface';
import {
  deleteWiegandFormat,
  fetchWiegandFormatList,
  setSearchFilter,
} from '../../redux/slices/WiegantSlice';
import EditWiegandFormat from './components/editWiegandFormat';
import { setNotificationError } from '../../redux/slices/NotificationSlices';
import wiegantFormat from '../../utils/api/wiegantFormat';
import { checkPermission } from '../../utils/role-permission';
import { rtMonitorOptions } from '../realTime-monitoring/options';

interface DeleteDepartProps {
  id: string;
}
function WiegandFormat() {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { wiegandFormats, status, totalDataCount, searchFilter }: WiegandFormatReduxInterface =
    useAppSelector((state: any) => state.wiegandFormat);
  const { loadMenus } = useAppSelector((state) => state.auth);
  const [selectedWiegandFullData, setSelectedWiegandFullData] = useState<any[]>([]);

  let handleEditOpen: any;
  const canEdit = checkPermission('pers:wiegandFmt:edit', loadMenus);
  const columnsField = [
    {
      accessorKey: 'name',
      header: t('name'),
      Cell: ({ row }: any) => (
        <div
          className={canEdit ? 'list-hyperlink' : ''}
          role="presentation"
          onClick={(event) => {
            if (!canEdit) return;
            event.stopPropagation();
            handleEditOpen(row.original);
          }}>
          {row?.original?.name}
        </div>
      ),
    },
    {
      accessorKey: 'wiegandMode',
      header: t('mode'),
      Cell: ({ row }: any) => {
        return row?.original?.wiegandMode === 1 ? 'Mode One' : 'Mode Two';
      },
    },
    {
      accessorKey: 'siteCode',
      header: t('siteCode'),
    },
    {
      accessorKey: 'autoMode',
      header: t('auto'),
    },
  ];

  const [modalOpen, setModalOpen] = useState(false);
  const [modalView, setModalView] = useState({
    title: t('addNewWiegandFormat'),
    content: <>hi</>,
  });
  const [selectedFormats, setSelectedFormats] = useState<string[]>([]);

  const fetchWiegandData = (params: any) => {
    if (status === 'success') {
      dispatch(fetchWiegandFormatList({ ...params }));
    }
  };

  const deleteRecord = (params: DeleteDepartProps) => {
    const req = {
      ids: params,
    };
    wiegantFormat
      .deleteWiegandFormat(req)
      .then((response) => {
        if (response.data.code === 0) {
          dispatch(
            setNotificationError({
              error: t('wiegandDeletedSuccessfully'),
              status: 200,
            }),
          );
          dispatch(deleteWiegandFormat(req));
          setModalOpen(false);
          dispatch(fetchWiegandFormatList({ pageNo: 1, pageSize: 10 }));
        } else {
          dispatch(
            setNotificationError({
              error: rtMonitorOptions[response.data.message],
              status: response.data.code,
            }),
          );
        }
      })
      .catch((error) => {
        dispatch(
          setNotificationError({
            error: error.message,
            status: error.response?.status || 500,
          }),
        );
      });
  };
  handleEditOpen = (editData: any) => {
    const data = {
      ...editData,
      autoMode: editData?.autoMode === 'Yes' ? ['yes'] : [],
    };
    setModalOpen(true);
    setModalView({
      title: t('editWiegandFormat'),
      content: <EditWiegandFormat closeModal={() => setModalOpen(false)} data={{ ...data }} />,
    });
  };
  const handleDeleteClick = () => {
    if (selectedWiegandFullData.length === 0) {
      dispatch(
        setNotificationError({
          error: t('pleaseSelectAtLeastOneWiegand'), // Also fixed the error message
          status: 400,
        }),
      );
      return;
    }

    setModalOpen(true);
    setModalView({
      title: t('delete'),
      content: (
        <DeleteModalBox
          closeModal={() => setModalOpen(false)}
          id={selectedWiegandFullData.map((val) => val?.id).join(',')}
          okFunction={deleteRecord}
          isMultiSelect
        />
      ),
    });
  };
  const actionButtons = [
    {
      label: t('edit'),
      enable: checkPermission('pers:wiegandFmt:edit', loadMenus),
      function: (val: any) => handleEditOpen(val),
    },
    {
      label: t('delete'),
      enable: checkPermission('pers:wiegandFmt:del', loadMenus),
      disabled: true,
      function: (val: any) => {
        setModalOpen(true);
        setModalView({
          title: t('deleteWiegandFormat'),
          content: (
            <DeleteModalBox
              closeModal={() => setModalOpen(false)}
              id={val.id}
              okFunction={deleteRecord}
            />
          ),
        });
      },
    },
  ];

  const moreActionButton = [
    {
      title: t('cardFormatsTesting'),
      enable: checkPermission('pers:wiegandFmt:test', loadMenus),
      clickFunction: (): void => {
        setModalOpen(true);
        setModalView({
          title: t('cardFormatsTesting'),
          content: <CardFormatTesting closeModal={() => setModalOpen(false)} />,
        });
      },
    },
  ];

  const getFilterData = (filter: any): any => {
    if (filter.pageIndex > 0) {
      dispatch(setSearchFilter(filter));
      fetchWiegandData({
        pageNo: filter.pageIndex,
        pageSize: filter.pageSize,
        ...filter.filter,
      });
    }
  };

  return (
    <div className="wiegand-format">
      <ModalBox
        status={modalOpen}
        closeModal={() => setModalOpen(false)}
        title={modalView.title}
        content={modalView.content}
      />
      <TopHeader
        title={t('wiegandFormat')}
        broadCram={[
          {
            title: t('cardManagement'),
          },
          {
            title: t('wiegandFormat'),
          },
        ]}
      />
      <CardBox
        title={t('wiegandFormat')}
        rightSide={
          <div className="delete-icon">
            <CardRightButtonGroup
              actions={moreActionButton}
              buttonIcon={<AddIcon color="#ffffff" />}
              buttonLabel={t('add')}
              buttonClass="btn-primary btn-sm"
              buttonStatus
              buttonFunction={(): void => {
                setModalOpen(true);
                setModalView({
                  title: t('addNewWiegandFormat'),
                  content: <AddWiegandFormat closeModal={() => setModalOpen(false)} />,
                });
              }}
              buttonEnable={checkPermission('pers:wiegandFmt:add', loadMenus)}
              // Add delete button configuration
              DeleteButtonIcon={<DeleteButtonIcon />}
              deleteButtonLabel={t('delete')}
              showDeleteButton
              deleteButtonFunction={handleDeleteClick}
              deleteButtonEnable={checkPermission('pers:wiegandFmt:del', loadMenus)}
              deleteButtonClass="multi-delete-button"
            />
          </div>
        }>
        <DataTable
          actionButtonStatus={!modalOpen}
          action={actionButtons}
          columns={columnsField}
          getFilterData={getFilterData}
          enablePagination
          assignFilterValue={searchFilter}
          assignSelectedData={selectedFormats}
          getSelectedData={(val: any): any => setSelectedFormats(val)}
          getSelectedFullData={(val: any): any => {
            setSelectedWiegandFullData(val);
          }}
          checkBoxActive
          values={wiegandFormats}
          fixedColumn={['name']}
          isFetching={status === 'loading'}
          totalRecords={totalDataCount}
          height={411}
        />
      </CardBox>
    </div>
  );
}

export default WiegandFormat;
