import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';
import InputField from '../../../../shared-components/input-field/input-field';
import SelectBoxField from '../../../../shared-components/selectbox-field/selectbox-field';
import Button from '../../../../shared-components/button/button';
import { ModalTypeProps, StateProps } from '../../../../interface/personAvailability.interface';
import personAvailability from '../../../../utils/api/AdvancedFunctions/personAvailability';
import { useAppDispatch, useAppSelector } from '../../../../redux/store';
import { setNotificationError } from '../../../../redux/slices/NotificationSlices';
import zone from '../../../../utils/api/AdvancedFunctions/zone';
import { fetchPersonAvailability } from '../../../../redux/slices/PersonAvailabilitySlices';
import readerDefine from '../../../../utils/api/AdvancedFunctions/readerDefine';
import InputDateTime from '../../../../shared-components/input-time-date/input-time-date';
import ModalBox from '../../../../shared-components/modal-box/modal-box';
import ConformationBox from '../../../../shared-components/conformation-box/conformation-box';

export const useOptions = [
  {
    label: '-------',
    id: '',
  },
  {
    label: 'Valid Date',
    id: '1',
  },
  {
    label: 'After the First Use of Valid Days',
    id: '2',
  },
  {
    label: 'Use Number of Times',
    id: '3',
  },
];

const initialValues = {
  name: '',
  zoneId: '',
  validDate: '',
  validType: '1',
  validDateTime: '',
  validTimes: '',
};
function AddAvailability({ closeModal, update }: ModalTypeProps) {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { searchFilter } = useAppSelector((state: any) => state.personAvailability);

  const [values, setValues] = useState<StateProps>(update || initialValues);
  const [disable, setDisable] = useState(false);
  const [zoneList, setZoneList] = useState<any[]>([]);
  const [checkExistName, setCheckExistName] = useState(true);
  const [modalOpen, setModalOpen] = useState(false);
  const [modalView, setModalView] = useState({
    title: '',
    content: <>hi</>,
  });

  const fetchData = () => {
    zone
      .getZoneList()
      .then((res) => {
        const initialOption = {
          label: '-------',
          id: '',
        };
        const result = [
          initialOption,
          ...(res?.data?.data?.map((item: any) => ({
            label: item.text,
            id: item.value,
          })) || []),
        ];
        setZoneList(result);
      })
      .catch((error) => {
        dispatch(
          setNotificationError({
            error: error.message,
            status: error.status,
          }),
        );
      });
  };

  const onChangeValue = async (name: string, value: any) => {
    setValues((prev) => ({
      ...prev,
      [name]: value,
    }));
    if (name === 'name' && value !== '' && value !== update?.name) {
      const responseData = await personAvailability.isExistName({ name: value });
      setCheckExistName(responseData?.data);
    }
  };

  const handleAccept = (data: any) => {
    setValues((prev: any) => ({
      ...prev,
      validDate: dayjs(data),
    }));
  };

  const changeDateTime = (data: any) => {
    setValues((prev: any) => ({
      ...prev,
      validDate: dayjs(data),
    }));
  };

  const save = (type: string) => {
    const { name, zoneId, validType, validDate, validDateTime, validTimes, id } = values;
    const request: any = {
      id,
      name,
      zoneId,
      validType,
    };
    if (validType === '1') {
      request.validDate = validDate;
    } else if (validType === '2') {
      request.validDays = validDateTime;
    } else if (validType === '3') {
      request.validTimes = validTimes;
    }
    personAvailability
      .save(request)
      .then((response) => {
        if (response.data.success) {
          dispatch(
            setNotificationError({
              error: response.data.msg,
              status: 200,
            }),
          );
          setDisable(false);
          dispatch(
            fetchPersonAvailability({
              ...searchFilter.filter,
              pageNo: 1,
              pageSize: 10,
            }),
          );
          if (type) {
            closeModal();
          } else {
            setValues(initialValues);
          }
        } else {
          dispatch(
            setNotificationError({
              error: response.data.message,
              status: response.data.code,
            }),
          );
        }
      })
      .catch((error) => {
        dispatch(
          setNotificationError({
            error: error.message,
            status: error.status,
          }),
        );
      });
  };

  const submit = async (type: string = '') => {
    try {
      const req = {
        opType: 'accPersonLimitZone',
        zoneId: values?.zoneId,
      };
      const response1 = await readerDefine.checkDevIssueVerify(req);
      if (response1.data.success) {
        // call save api function
        save(type);
      } else {
        setModalView({
          title: t('reset'),
          content: (
            <ConformationBox
              closeModal={() => save(type)}
              okFunction={() => setModalOpen(false)}
              title={t('checkDevIssueVerify')}
              buttonLabel={t('ok')}
              cancelTrue
            />
          ),
        });
        setModalOpen(true);
      }
    } catch (error: any) {
      dispatch(
        setNotificationError({
          error: error.message,
          status: error.status,
        }),
      );
    }
  };

  useEffect(() => {
    if (values?.name && values?.zoneId) {
      if (values?.validType === '1' && values?.validDate) {
        setDisable(false);
      } else if (values?.validType === '2' && values?.validDateTime) {
        setDisable(false);
      } else if (values?.validType === '3' && values?.validTimes) {
        setDisable(false);
      } else {
        setDisable(true);
      }
    } else {
      setDisable(true);
    }
  }, [values]);

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <div className="add-personAvailability flex-row column">
      <ModalBox
        status={modalOpen}
        closeModal={() => setModalOpen(false)}
        title={modalView.title}
        content={modalView.content}
      />
      <div className="form-row">
        <div className="flex-row">
          <InputField
            id="name"
            name="name"
            label={t('name')}
            type="text"
            value={values.name}
            onChangeFunction={onChangeValue}
            errorStatus={values.name !== '' && !checkExistName}
            message={t('objectIsAlreadyExist')}
            required
          />
          <SelectBoxField
            id="zoneId"
            name="zoneId"
            label={t('zone')}
            value={values.zoneId}
            onChangeFunction={(name, value) => onChangeValue(name, value.id)}
            data={zoneList}
            required
            disabled={update !== undefined}
          />
        </div>
        <div className="flex-row">
          <SelectBoxField
            id="validType"
            name="validType"
            label={t('use')}
            value={values.validType}
            onChangeFunction={(name, value) => onChangeValue(name, value.id)}
            data={useOptions}
            required
            disabled={update !== undefined}
          />
          {values.validType === '1' && (
            <InputDateTime
              label={t('date')}
              value={values?.validDate}
              onChangeFunction={changeDateTime}
              okFunction={handleAccept}
            />
          )}
          {values.validType === '2' && (
            <InputField
              id="validDateTime"
              name="validDateTime"
              label={t('days')}
              type="text"
              value={values.validDateTime}
              onChangeFunction={onChangeValue}
              required
            />
          )}
          {values.validType === '3' && (
            <InputField
              id="validTimes"
              name="validTimes"
              label={t('times')}
              type="text"
              value={values.validTimes}
              onChangeFunction={onChangeValue}
              required
            />
          )}
        </div>
      </div>

      <div className="form-buttons-right">
        {!update && (
          <Button
            onClickFunction={() => {
              submit('saveAndNew');
            }}
            title={t('save&new')}
            className="btn-primary"
            disabled={disable}
          />
        )}
        <Button
          onClickFunction={() => {
            closeModal();
          }}
          title={t('cancel')}
          className="btn-outline-primary"
        />
        <Button
          onClickFunction={submit}
          title={t('done')}
          className="btn-primary"
          disabled={disable}
        />
      </div>
    </div>
  );
}

export default AddAvailability;
