import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import InputField from '../../../../../shared-components/input-field/input-field';
import FormRightButton from '../../../../../shared-components/form-right-buttons/FormRightButton';
import {
  AddVideoChannelProp,
  InterlockGroupType,
} from '../../../../../interface/System/videoChannel.interface';

// Import all icons
import Camera from '../../../../../assets/images/camera.png';
import Keypad from '../../../../../assets/images/keypad.png';
import Running from '../../../../../assets/images/running.png';
import Speed from '../../../../../assets/images/speed.png';
import Grid from '../../../../../assets/images/grid.png';
import FingerPrintImage from '../../../../../assets/images/fingerprint-image.png';
import Shield from '../../../../../assets/images/shield.png';
import Falling from '../../../../../assets/images/falling.png';
import Group from '../../../../../assets/images/group.png';
import Forbidden from '../../../../../assets/images/forbidden.png';
import Worker from '../../../../../assets/images/worker.png';
import Warning from '../../../../../assets/images/warning.png';
import Phone from '../../../../../assets/images/phone.png';
import Briefcase from '../../../../../assets/images/briefcase.png';
import Sos from '../../../../../assets/images/sos.png';
import Cone from '../../../../../assets/images/cone.png';
import Card from '../../../../../assets/images/card.png';
import Exit from '../../../../../assets/images/exit.png';
import Alarm from '../../../../../assets/images/alarm.png';

// Define icon options for the grid
const iconOptions = [
  { id: 'camera', value: 'camera', image: Camera },
  { id: 'keypad', value: 'keypad', image: Keypad },
  { id: 'running', value: 'running', image: Running },
  { id: 'speed', value: 'speed', image: Speed },
  { id: 'grid', value: 'grid', image: Grid },
  { id: 'fingerprint', value: 'fingerprint', image: FingerPrintImage },
  { id: 'shield', value: 'shield', image: Shield },
  { id: 'falling', value: 'falling', image: Falling },
  { id: 'group', value: 'group', image: Group },
  { id: 'forbidden', value: 'forbidden', image: Forbidden },
  { id: 'worker', value: 'worker', image: Worker },
  { id: 'warning', value: 'warning', image: Warning },
  { id: 'phone', value: 'phone', image: Phone },
  { id: 'briefcase', value: 'briefcase', image: Briefcase },
  { id: 'sos', value: 'sos', image: Sos },
  { id: 'cone', value: 'cone', image: Cone },
  { id: 'card', value: 'card', image: Card },
  { id: 'exit', value: 'exit', image: Exit },
  { id: 'alarm', value: 'alarm', image: Alarm },
];

function AddVideoChannel({ closeModal, data }: InterlockGroupType) {
  const { t } = useTranslation();
  const [value, setValue] = useState<AddVideoChannelProp>({
    id: data?.id || '',
    name: data?.name || '',
    iconName: data?.iconName || '',
    clientName: data?.clientName || '',
    clientToken: data?.clientToken || '',
  });

  const onChangeValue = (name: string, values: any) => {
    setValue((prev: AddVideoChannelProp) => ({
      ...prev,
      [name]: values,
    }));
  };

  const submit = () => {
    console.log('hai');
  };

  return (
    <div className="add-video-channel">
      <div className="video-field">
        <InputField
          id="name"
          name="name"
          label={t('name')}
          type="text"
          value={value.name}
          onChangeFunction={onChangeValue}
          required
        />
        <InputField
          id="id"
          name="id"
          label={t('id')}
          type="text"
          value={value.id}
          onChangeFunction={onChangeValue}
          required
        />
      </div>
      <div className="form-field">
        <label className="field-label" htmlFor="icon-selector">
          {t('icon')}
          <span className="required">*</span>
        </label>
        <div className="icon-grid">
          {iconOptions.map((icon) => (
            <div className="icon-option" key={icon.id}>
              <input
                type="radio"
                id={`icon-${icon.id}`}
                name="iconName"
                value={icon.value}
                checked={value.iconName === icon.value}
                onChange={() => onChangeValue('iconName', icon.value)}
              />
              <div id={`icon-${icon.id}-label`} className="icon-container selected">
                <div className="icon-image">
                  <img src={icon.image} alt={icon.value} />
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
      <FormRightButton
        cancelLabel={t('cancel')}
        cancelFunction={closeModal}
        submitLabel={t('save')}
        submitFunction={submit}
      />
    </div>
  );
}

AddVideoChannel.defaultProps = {
  data: '',
};

export default AddVideoChannel;
