import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
// Import shared components
import { useAppDispatch, useAppSelector } from '../../../../redux/store';
import TopHeader from '../../../../shared-components/top-header/top-header';
import CardBox from '../../../../shared-components/card/card';
import DataTable from '../../../../shared-components/data-table/data-table';
import ModalBox from '../../../../shared-components/modal-box/modal-box';
import CardRightButtonGroup from '../../../../shared-components/card-right-button-group/card-right-button-group';
import { setNotificationError } from '../../../../redux/slices/NotificationSlices';
import {
  deleteAuthDev,
  fetchAuthDeviceList,
  setSearchFilter,
} from '../../../../redux/slices/system-management/AuthorizedDeviceSlices';
import AddAuthorizedDevice from './components/add-auth-device';
import ConformationBox from '../../../../shared-components/conformation-box/conformation-box';
import ExportKeyFile from './components/export-key-files';
import DeleteModalBox from '../../../../shared-components/delete-modal-box/delete-modal-box';
import authorizedDevice from '../../../../utils/api/SystemManagement/authorizedDevice';
import { checkPermission } from '../../../../utils/role-permission';

function AuthorizedDevice() {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { authDevList, status, totalDataCount, searchFilter } = useAppSelector(
    (state: any) => state.authDevice,
  );
  const { loadMenus } = useAppSelector((state) => state.auth);
  const [modalOpen, setModalOpen] = useState(false);
  const [modalView, setModalView] = useState({
    title: '',
    content: <>hi</>,
  });
  const [selectedValues, setSelectedValues] = useState<string[]>([]);

  const columnsField = [
    {
      accessorKey: 'protype',
      header: t('productMode'),
      size: 150,
    },
    {
      accessorKey: 'sn',
      header: t('serialNumber'),
    },
    {
      accessorKey: 'deviceSecret',
      header: t('deviceSecret'),
    },
    {
      accessorKey: 'product',
      header: t('productName'),
    },
    {
      accessorKey: 'productId',
      header: t('productCode'),
    },
    {
      accessorKey: 'module',
      header: t('module'),
    },
    {
      accessorKey: 'authFlag',
      header: t('whetherToAuthorize'),
      size: '250',
      Cell: ({ row }: any) => {
        let result;
        if (row.original.authFlag) {
          result = <span className="text-success-2">Authorized</span>;
        } else {
          result = <span className="text-danger">UnAuthorized</span>;
        }
        return result;
      },
    },
    {
      accessorKey: 'remarks',
      header: t('remarks'),
    },
  ];

  /**
   *
   * @param params - The filter object containing pagination and filter criteria.
   */
  const fetchData = async (params: any) => {
    if (status === 'success') {
      dispatch(fetchAuthDeviceList({ ...params }));
    }
  };

  /**
   * Handles filtering and pagination of the authorized device list.
   *
   * @param {Object} filter - The filter object containing pagination and filter criteria.
   * @param {number} filter.pageIndex - The current page index (1-based).
   * @param {number} filter.pageSize - The number of items per page.
   * @param {Object} filter.filter - Additional filter criteria.
   * @returns {void}
   */
  const getFilterData = (filter: any): any => {
    if (filter.pageIndex > 0) {
      dispatch(setSearchFilter(filter));
      fetchData({
        pageNo: filter.pageIndex,
        pageSize: filter.pageSize,
        ...filter.filter,
      });
    }
  };

  /**
   *
   * @param editData - The record to be edited.
   */
  const handleEditOpen = (editData: any) => {
    setModalView({
      title: t('edit'),
      content: <AddAuthorizedDevice update={editData} closeModal={() => setModalOpen(false)} />,
    });
    setModalOpen(true);
  };

  /**
   *
   * @param id - The ID of the record to be deleted.
   */
  const deleteRecord = (id: any) => {
    const req = {
      ids: id,
    };
    authorizedDevice
      .delete(req)
      .then((response) => {
        if (response.data.success) {
          dispatch(
            setNotificationError({
              error: response.data.msg,
              status: 200,
            }),
          );
          dispatch(deleteAuthDev(id));
          setModalOpen(false);
        } else {
          dispatch(
            setNotificationError({
              error: response.data.msg,
              status: response.data.code || 500,
            }),
          );
        }
      })
      .catch((error) => {
        dispatch(
          setNotificationError({
            error: error.message,
            status: error.response?.status || 500,
          }),
        );
      });
  };

  const actionButtons = [
    {
      label: t('edit'),
      enable: checkPermission('adms:authDevice:edit', loadMenus),
      function: (val: any) => handleEditOpen(val),
    },
    {
      label: t('delete'),
      enable: checkPermission('adms:authDevice:del', loadMenus),
      disabled: true,
      function: (val: any) => {
        setModalOpen(true);
        setModalView({
          title: t('delete'),
          content: (
            <DeleteModalBox
              closeModal={() => setModalOpen(false)}
              id={val.id}
              okFunction={deleteRecord}
            />
          ),
        });
      },
    },
  ];

  const actions = [
    {
      title: t('exportKeyFile'),
      enable: checkPermission('adms:authDevice:export', loadMenus),
      clickFunction: (): void => {
        if (selectedValues?.length > 0) {
          setModalOpen(true);
          setModalView({
            title: t('exportKeyFile'),
            content: (
              <ExportKeyFile
                selectedDevices={selectedValues}
                closeModal={() => setModalOpen(false)}
              />
            ),
          });
        } else {
          setModalOpen(true);
          setModalView({
            title: t('selectObject'),
            content: (
              <ConformationBox
                closeModal={() => setModalOpen(false)}
                okFunction={() => setModalOpen(false)}
                title={t('pleaseSelectTheObjectsYouWantToOperate')}
                buttonLabel={t('ok')}
              />
            ),
          });
        }
      },
    },
  ];

  return (
    <div className="department">
      <ModalBox
        status={modalOpen}
        closeModal={() => setModalOpen(false)}
        title={modalView.title}
        content={modalView.content}
      />
      <TopHeader
        title={t('system')}
        broadCram={[
          {
            title: t('communicationManagement'),
          },
          {
            title: t('authorizedDevice'),
          },
        ]}
      />
      <CardBox
        title={t('listOfProtocolMode')}
        rightSide={<CardRightButtonGroup actions={actions} buttonStatus={false} />}>
        <DataTable
          actionButtonStatus={!modalOpen}
          action={actionButtons}
          columns={columnsField}
          getFilterData={getFilterData}
          assignFilterValue={searchFilter}
          assignSelectedData={selectedValues}
          getSelectedData={(val: any): any => setSelectedValues(val)}
          checkBoxActive
          enablePagination
          isFetching={status === 'loading'}
          values={authDevList}
          fixedColumn={['id']}
          totalRecords={totalDataCount}
          height={411}
        />
      </CardBox>
    </div>
  );
}

export default AuthorizedDevice;
