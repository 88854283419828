import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Table from '../../../shared-components/table/table';
import Button from '../../../shared-components/button/button';
import device from '../../../utils/api/device';
import { useAppDispatch } from '../../../redux/store';
import { setNotificationError } from '../../../redux/slices/NotificationSlices';
import SpinnerLoader from '../../../shared-components/spinnerLoader/spinner-loader';

function ViewDeviceCapacity({ closeModal, selectedId }: any) {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const [tableData, setTableData] = useState<any>([]);
  const [loading, setLoading] = useState(false);
  let handleClick: any;

  const renderLoadingState = (deviceName: string) => ({
    deviceName,
    personQuantity: <SpinnerLoader />,
    faceQuantity: <SpinnerLoader />,
    fingerPrint: (
      <div className="col-span">
        <SpinnerLoader />
        <hr />
        <SpinnerLoader />
      </div>
    ),
    fingerVein: (
      <div className="col-span">
        <SpinnerLoader />
        <hr />
        <SpinnerLoader />
      </div>
    ),
    palmQuantity: (
      <div className="col-span">
        <SpinnerLoader />
        <hr />
        <SpinnerLoader />
      </div>
    ),
    faceBioData: (
      <div className="col-span">
        <SpinnerLoader />
        <hr />
        <SpinnerLoader />
      </div>
    ),
    action: <Button disabled title={t('get')} className="btn-primary" />,
  });

  const renderDataRow = (
    item: any,
    prevDeviceName: string,
    loader: boolean,
    getAllIsTrue?: boolean,
  ) => {
    const isStatus = item?.[8] === "disabled = 'disabled'" || getAllIsTrue;
    return {
      deviceName: (
        <span className={item?.[8] === "disabled = 'disabled'" ? 'text-danger' : ''}>
          {prevDeviceName}
        </span>
      ),
      personQuantity: item?.user?.[item?.id] || 'x',
      faceQuantity:
        item?.face?.[item?.id] === 'false' || !item?.face?.[item?.id]
          ? 'x'
          : item?.face?.[item?.id],
      fingerPrint: (
        <div className="col-span">
          {loader ? (
            <SpinnerLoader />
          ) : (
            <span className="span-1">
              {item?.finger?.[item?.id] === 'false' || !item?.finger?.[item?.id]
                ? 'x'
                : item?.finger?.[item?.id]}
            </span>
          )}
          <hr />
          <span>{item?.finger?.fingerVersion || 'x'}</span>
        </div>
      ),
      fingerVein: (
        <div className="col-span">
          <span className="span-1">
            {item?.fv?.[item?.id] === 'false' ? 'x' : item?.fv?.[item?.id]}
          </span>
          <hr />
          <span>{item?.finger?.fvVersion || 'x'}</span>
        </div>
      ),
      palmQuantity: (
        <div className="col-span">
          <span className="span-1">
            {item?.palm?.[item?.id] === 'false' ? 'x' : item?.palm?.[item?.id]}
          </span>
          <hr />
          <span>{item?.palm?.pvVersion || 'x'}</span>
        </div>
      ),
      faceBioData: (
        <div className="col-span">
          <span className="span-1">{item?.faceBiodata?.[item?.id] || 'x'}</span>
          <hr />
          <span>{item?.faceBiodata?.vislightFaceVersion || 'x'}</span>
        </div>
      ),
      action: (
        <Button
          onClickFunction={() => handleClick(selectedId)}
          title={t('get')}
          className="btn-primary"
          disabled={isStatus}
        />
      ),
    };
  };

  const getViewRules = (req: any) => {
    device
      .queryDevUsage(req)
      .then((res) => {
        if (res.data.success) {
          setLoading(res.data.data.getAll);
          const result = res.data.data.retList.map((item: any) =>
            renderDataRow(item, item?.[0], false, res.data.data.getAll),
          );
          setTableData(result);
        } else {
          setLoading(false);
          dispatch(setNotificationError({ error: res.data.msg, status: res.data.ret }));
        }
      })
      .catch((error) => {
        setLoading(false);
        dispatch(
          setNotificationError({ error: error.message, status: error.response?.status || 500 }),
        );
      });
  };

  handleClick = (params: string | string[]) => {
    setLoading(true);
    setTableData((prevData: any) =>
      prevData.map((item: any) => renderLoadingState(item.deviceName)),
    );
    const deviceId = Array.isArray(params) ? params : [params];
    const request = { devId: deviceId?.join(',') };
    device
      .getDevUsage(request)
      .then((res) => {
        setLoading(false);
        if (res.data.success) {
          setTableData((prevData: any) => {
            const mappedData = [res.data.data];
            return mappedData.map((item, i) =>
              renderDataRow(item, prevData[i]?.deviceName, loading),
            );
          });
        } else {
          setLoading(false);
          dispatch(setNotificationError({ error: res.data.msg, status: res.data.ret || 500 }));
        }
      })
      .catch((error) => {
        setLoading(false);
        dispatch(
          setNotificationError({ error: error.message, status: error.response?.status || 500 }),
        );
      });
  };

  useEffect(() => {
    if (selectedId) {
      const request = { ids: selectedId.toString() };
      getViewRules(request);
    }
  }, [selectedId]);
  return (
    <div className="view-device-capacity">
      <Table
        header={[
          { label: 'Device Name', id: 'deviceName' },
          { label: 'Personnel Quantity', id: 'personQuantity' },
          { label: 'Face Quantity', id: 'faceQuantity' },
          { label: 'FingerPrint Quantity', id: 'fingerPrint' },
          { label: 'FingerVein Quantity', id: 'fingerVein' },
          { label: 'Palm Quantity', id: 'palmQuantity' },
          { label: 'FaceBio Data', id: 'faceBioData' },
          { label: '', id: 'action' },
        ]}
        value={tableData}
        hideHeader={false}
        headerButton
        clickHeader={() => handleClick(selectedId)}
        disableHeaderBtn={loading}
      />
      <div className="bottom">
        <Button onClickFunction={closeModal} title={t('close')} className="btn-primary" />
      </div>
    </div>
  );
}

export default ViewDeviceCapacity;
