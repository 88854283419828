import { post } from '../base/index';

export default {
  getListPage: (data: any = {}) => {
    return post(`${process.env.REACT_APP_API_URL}Acc/api/accMusterPoint/list`, {}, { ...data });
  },
  deptList: (data: any = {}) => {
    return post(`${process.env.REACT_APP_API_URL}Acc/api/accMusterPoint/deptList`, {}, data);
  },
  isExistName: (data: any = {}) =>
    post(`${process.env.REACT_APP_API_URL}Acc/api/accMusterPoint/valid`, {}, data),
  save: (data: any = {}) => {
    return post(`${process.env.REACT_APP_API_URL}Acc/api/accMusterPoint/save`, data, {});
  },
  delete: (data: string = '') => {
    return post(`${process.env.REACT_APP_API_URL}Acc/api/accMusterPoint/delete/${data}`, {});
  },
  selectPersonList: (data: any = {}) => {
    return post(`${process.env.REACT_APP_API_URL}Acc/api/accMusterPoint/selectPersonList`, data);
  },
  addPerson: (data: any = {}) => {
    return post(`${process.env.REACT_APP_API_URL}Acc/api/accMusterPoint/addPerson`, data);
  },
  delPerson: (data: any = {}) => {
    return post(`${process.env.REACT_APP_API_URL}Acc/api/accMusterPoint/delPerson`, {}, data);
  },
  getPersonList: (data: any = {}) => {
    return post(
      `${process.env.REACT_APP_API_URL}Acc/api/accMusterPoint/personList`,
      {},
      { ...data, pageSize: 10 },
    );
  },
  selectReaderList: (data: any = {}) => {
    return post(`${process.env.REACT_APP_API_URL}Acc/api/accMusterPoint/selectReaderList`, data);
  },
  edit: (data: any = {}) => {
    return post(`${process.env.REACT_APP_API_URL}Acc/api/accMusterPoint/edit`, {}, data);
  },
  selectDeptList: (data: any = {}) => {
    return post(
      `${process.env.REACT_APP_API_URL}Acc/api/accMusterPoint/selectDeptList`,
      { ...data, pageSize: 10 },
      {},
    );
  },
  addDept: (data: any = {}) => {
    return post(`${process.env.REACT_APP_API_URL}Acc/api/accMusterPoint/addDept`, {}, data);
  },
  deletePoint: (data: any = {}) => {
    return post(`${process.env.REACT_APP_API_URL}Acc/api/accMusterPoint/del`, {}, data);
  },
  deleteDept: (data: any = {}) => {
    return post(`${process.env.REACT_APP_API_URL}Acc/api/accMusterPoint/delDept`, {}, data);
  },
  activate: (data: any = {}) => {
    return post(`${process.env.REACT_APP_API_URL}Acc/api/accMusterPoint/activate`, {}, data);
  },
  cancel: (data: any = {}) => {
    return post(`${process.env.REACT_APP_API_URL}Acc/api/accMusterPoint/cancel`, {}, data);
  },
  getAllMusterPointList: () => {
    return post(`${process.env.REACT_APP_API_URL}Acc/api/accMusterPoint/getAllMusterPointList`, {});
  },
  getMusterPointList: () => {
    return post(`${process.env.REACT_APP_API_URL}Acc/api/accMusterPoint/getMusterPointList`, {});
  },
};
