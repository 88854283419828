import React, { useEffect, useState } from 'react';

// Use common modules
import { useTranslation } from 'react-i18next';
import { useAppSelector } from '../../../../redux/store';

// Use shared components
import TopHeader from '../../../../shared-components/top-header/top-header';
import CardBox from '../../../../shared-components/card/card';
import TabPages from '../../../../shared-components/tab-pages/tab-pages';
import Button from '../../../../shared-components/button/button';

// Use interface
import { ParameterReduxInterface } from '../../../../interface/parameter.interface';
import Records from '../../system-management/data-cleaning/components/record';
import ServerConfiguration from './components/serverConfiguration';
import ADFieldSetting from './components/adFieldSetting';
import SynchronizePermission from './components/synchronizePermission';
import ADDataTimingImport from './components/adDataTimingImport';

function ADManagement() {
  const { t } = useTranslation();
  const { parameters }: ParameterReduxInterface = useAppSelector((state: any) => state.parameters);

  const [parametersValue, setParameters] = useState(parameters || {});

  const onChangeValue = (value: any) => {
    setParameters((prev: any) => ({
      ...prev,
      ...value,
    }));
  };

  const [tabs, setTabs] = useState([
    {
      key: 'record',
      title: t('record'),
      content: <Records onChangeFunction={onChangeValue} values={parametersValue} />,
      enable: true,
    },
  ]);

  const submit = () => {
    console.log('Submit button clicked');
  };

  useEffect(() => {
    if (Object.keys(parameters).length !== 0) {
      setParameters({ ...parameters });
    }
  }, [parameters]);

  useEffect(() => {
    if (Object.keys(parametersValue).length > 0) {
      setTabs([
        {
          key: 'serverConfiguration',
          title: t('serverConfiguration'),
          content: (
            <ServerConfiguration onChangeFunction={onChangeValue} values={parametersValue} />
          ),
          enable: true,
        },
        {
          key: 'adFieldSetting',
          title: t('adFieldSetting'),
          content: <ADFieldSetting onChangeFunction={onChangeValue} />,
          enable: true,
        },
        {
          key: 'synchronizePermissionGroupInformation',
          title: t('synchronizePermissionGroupInformation'),
          content: <SynchronizePermission onChangeFunction={onChangeValue} />,
          enable: true,
        },
        {
          key: 'adDataTimingImport',
          title: t('adDataTimingImport'),
          content: <ADDataTimingImport onChangeFunction={onChangeValue} />,
          enable: true,
        },
      ]);
    }
  }, [parametersValue]);

  return (
    <div className="parameters">
      <TopHeader
        title={t('system')}
        broadCram={[{ title: t('thirdPartyIntegration') }, { title: t('adManagement') }]}
      />
      <CardBox title={t('adManagement')}>
        <TabPages tabs={tabs} defaultNumber={0} />

        <div className="parameters-buttons">
          <div className="form-buttons-right">
            <Button onClickFunction={submit} title={t('done')} className="btn-primary btn-bold" />
          </div>
        </div>
      </CardBox>
    </div>
  );
}

export default ADManagement;
