import React from 'react';
import { useTranslation } from 'react-i18next';
import InputField from '../../../../../shared-components/input-field/input-field';

interface MoniterInterface {
  onChangeFunction: (name: any, changeValue: any) => void;
  values?: any;
}

function ServerSideNetworkCondition({ onChangeFunction, values }: MoniterInterface) {
  const { t } = useTranslation();
  const onChange = (name: any, changeValue: any) => {
    onChangeFunction(name, changeValue);
  };

  return (
    <div className="card-management">
      <div className="tab active">{t('whetherTheInternetConnectionIsNormal')}</div>
      <div className="adms-item">
        <InputField
          id="adms.linkIntenet"
          label=""
          name="adms.linkIntenet"
          type="text"
          value={values['adms.linkIntenet']}
          onChangeFunction={onChange}
          disabled
        />
      </div>
    </div>
  );
}
ServerSideNetworkCondition.defaultProps = {
  values: '',
};
export default ServerSideNetworkCondition;
