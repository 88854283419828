import React, { useState } from 'react';

// Use Translation
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../redux/store';

// Use own components
import AddDevice from './components/add-device';

// Use shared components
import TopHeader from '../../shared-components/top-header/top-header';
import CardBox from '../../shared-components/card/card';
import DataTable from '../../shared-components/data-table/data-table';
import CardRightButtonGroup from '../../shared-components/card-right-button-group/card-right-button-group';
import ModalBox from '../../shared-components/modal-box/modal-box';
import DeviceExportData from './components/export-data';
import ConformationBox from '../../shared-components/conformation-box/conformation-box';

// Use assets svg icons
import { AddIcon, DeleteButtonIcon } from '../../assets/svgicons/svgicon';

// Import card slice actions
import { fetchDeviceList, setSearchFilter } from '../../redux/slices/DeviceSlice';

import DeleteModalBox from '../../shared-components/delete-modal-box/delete-modal-box';
import SearchDevice from './components/search-device';
import ListDropdown from '../../shared-components/list-dropdown/list-dropdown';
import {
  controlOptions,
  setupOptions,
  viewGetOptions,
  CommunicationOptions,
} from './device-options';
import UpgradeFirmware from './components/upgrade-firmware';
import SynchronizeTime from './components/synchronize-time';
import SynchronizeAllDataToDevice from './components/synchronize-allDataToDevice';
import BgVerificationOptions from './components/bg-verification-option';
import ModifyIpAddress from './components/modify-ip-address';
import ModifyCommunicationPassword from './components/modify-communication-password';
import SwitchNetworkConnection from './components/switch-network-connection';
import DeviceTimeZone from './components/device-timeZone';
import RegistrationDevice from './components/registration-device';
import GetPersonalInformation from './components/get-personal-information';
import GetTransaction from './components/get-transaction';
import ViewRulesOfDevices from './components/view-rulesOf-devices';
import device from '../../utils/api/device';
import { setNotificationError } from '../../redux/slices/NotificationSlices';
import ModifyFingerThrehold from './components/ModifyFingerThreshold';
import ReplaceDevice from './components/ReplaceDevice';
import ViewDeviceCapacity from './components/view-device-capacity';
import { checkPermission } from '../../utils/role-permission';
import { saveSystemCode } from '../../redux/slices/AuthSlices';

function DeviceList() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { devices, status, totalDataCount, searchFilter } = useAppSelector(
    (state: any) => state.device,
  );
  const { loadMenus } = useAppSelector((state) => state.auth);

  const [modalOpen, setModalOpen] = useState(false);
  const [modalView, setModalView] = useState({
    title: 'title',
    content: <>hi</>,
  });
  const [selectedIds, setSelectedCards] = useState<string[]>([]);
  const [selectedDeviceFullData, setSelectedDeviceFullData] = useState<any[]>([]);

  const [selectedData, setSelectedData] = useState([]);
  const [deleteButton, setDeleteButton] = useState(false);
  let handleEditOpen: any;

  const canEdit = checkPermission('acc:device:edit', loadMenus);
  const columnsField = [
    {
      accessorKey: 'alias',
      header: t('deviceName'),
      Cell: ({ row }: any) => (
        <div
          className={canEdit ? 'list-hyperlink' : ''}
          role="presentation"
          onClick={(event) => {
            if (!canEdit) return;
            event.stopPropagation();
            handleEditOpen(row.original);
          }}>
          {row?.original?.alias}
        </div>
      ),
    },
    {
      accessorKey: 'sn',
      header: t('serialNumber'),
      size: 150,
    },
    {
      accessorKey: 'authAreaName',
      header: t('areaName'),
      size: 150,
    },
    {
      accessorKey: 'ipAddress',
      header: t('ipAddress'),
      size: 150,
    },
    {
      accessorKey: 'status',
      header: t('status'),
      size: 150,
      Cell: ({ row }: any) => {
        let result;
        if (row?.original?.status === 'online') {
          result = <span style={{ color: '#00A76F' }}>{t('online')}</span>;
        } else if (row?.original?.status === 'offline') {
          result = <span style={{ color: '#E8212E' }}>{t('offline')}</span>;
        } else {
          result = <span style={{ color: '#E8212E' }}>{t('disabled')}</span>;
        }
        return result;
      },
    },
    {
      accessorKey: 'deviceName',
      header: t('deviceModel'),
    },
    {
      accessorKey: 'isRegistrationDevice',
      header: t('registerDevice'),
      size: 150,
      Cell: ({ row }: any) => {
        let result;
        if (row?.original?.isRegistrationDevice) {
          result = <span style={{ color: '#00A76F' }}>Registered</span>;
        } else {
          result = <span style={{ color: '#E8212E' }}>Not Registered</span>;
        }
        return result;
      },
    },
    {
      accessorKey: 'fwVersion',
      header: t('firmwareVersion'),
    },
    {
      accessorKey: 'commType',
      header: t('commandsList'),
    },
  ];
  const fetchData = async (params: any) => {
    if (status === 'success') {
      dispatch(fetchDeviceList({ ...params }));
    }
  };

  const deleteRecord = (id: string) => {
    setDeleteButton(true);
    device
      .deleteDevice(id)
      .then((response) => {
        if (response.data.success) {
          dispatch(
            setNotificationError({
              error: t('deviceDeletedSuccessfully'),
              status: 200,
            }),
          );
          setDeleteButton(false);
          setModalOpen(false);
          fetchData({ pageNo: 1, pageSize: 10, ...searchFilter.filter }); // Refresh the list after successful deletion
        } else {
          dispatch(
            setNotificationError({
              error: response.data.msg,
              status: response.data.ret,
            }),
          );
          setDeleteButton(false);
        }
      })
      .catch((error) => {
        setDeleteButton(false);
        dispatch(
          setNotificationError({
            error: error.message,
            status: error.response?.status || 500,
          }),
        );
      });
  };

  const exportData = () => {
    setModalOpen(false);
  };

  const handleClearAdministrator = () => {
    device
      .clearAdministrator(selectedIds)
      .then((response) => {
        if (response.data.success) {
          dispatch(
            setNotificationError({
              error: response.data.msg,
              status: 200,
            }),
          );
          setModalOpen(false);
          fetchData({ pageNo: 1, pageSize: 10, ...searchFilter.filter }); // Refresh the list after successful deletion
        } else {
          dispatch(
            setNotificationError({
              error: response.data.msg,
              status: response.data.ret,
            }),
          );
        }
      })
      .catch((error) => {
        dispatch(
          setNotificationError({
            error: error.message,
            status: error.response?.status || 500,
          }),
        );
      });
  };

  const handleControlApis = (apiName: string = '') => {
    device
      .controlApis(apiName, selectedIds)
      .then((response) => {
        if (response.data.code === 0 || response.data.success) {
          let successMsg;
          if (apiName === 'clearCmdCache') {
            successMsg = 'clearCmdSuccess';
          } else {
            successMsg = `${apiName}Successfully`;
          }
          dispatch(
            setNotificationError({
              error: t(successMsg),
              status: 200,
            }),
          );
          setModalOpen(false);
          fetchData({ pageNo: 1, pageSize: 10, ...searchFilter.filter }); // Refresh the list after successful deletion
        } else {
          dispatch(
            setNotificationError({
              error: response.data.msg,
              status: response.data.ret,
            }),
          );
        }
      })
      .catch((error) => {
        dispatch(
          setNotificationError({
            error: error.message,
            status: error.response?.status || 500,
          }),
        );
      });
  };

  // handle click setup
  const handleGetDeviceOption = () => {
    device
      .getOptFromDev(selectedIds)
      .then((response) => {
        if (response.data.success) {
          const message = t('The operation succeeded!');
          dispatch(
            setNotificationError({
              error: message,
              status: 200,
            }),
          );
          setModalOpen(false);
          fetchData({ pageNo: 1, pageSize: 10, ...searchFilter.filter }); // Refresh the list after successful deletion
        } else {
          dispatch(
            setNotificationError({
              error: response.data.msg,
              status: response.data.ret,
            }),
          );
        }
      })
      .catch((error) => {
        dispatch(
          setNotificationError({
            error: error.message,
            status: error.response?.status || 500,
          }),
        );
      });
  };

  const setModal = (titleKey: string, okFunction: () => void, content: JSX.Element) => {
    setModalView({ title: t(titleKey), content });
    setModalOpen(true);
  };

  const handleClickControl = (value: string = '') => {
    if (selectedIds.length === 0) {
      setModalOpen(true);
      setModalView({
        title: t('selectObject'),
        content: (
          <ConformationBox
            closeModal={() => setModalOpen(false)}
            okFunction={() => setModalOpen(false)}
            title={t('pleaseSelectTheObjectsYouWantToOperate')}
            buttonLabel={t('ok')}
          />
        ),
      });
      return;
    }
    switch (value) {
      case 'clearAdminPermission':
        device
          .isSupportClearAdministrator(selectedIds)
          .then((res) => {
            if (res.data.ret === '400') {
              dispatch(setNotificationError({ error: res.data.msg, status: 500 }));
            } else {
              setModal(
                'clearAdminPermission',
                handleClearAdministrator,
                <ConformationBox
                  closeModal={() => setModalOpen(false)}
                  okFunction={handleClearAdministrator}
                  title={t('areYouSureToClearDeviceAdministratorPermissions')}
                  buttonLabel={t('ok')}
                  cancelTrue
                />,
              );
            }
          })
          .catch((error) => {
            dispatch(
              setNotificationError({ error: error.message, status: error.response?.status || 500 }),
            );
          });
        break;
      case 'clearCmdCache':
        setModal(
          'clearCommand',
          () => handleControlApis('clearCmdCache'),
          <ConformationBox
            closeModal={() => setModalOpen(false)}
            okFunction={() => handleControlApis('clearCmdCache')}
            title={t('areYouWantToPerformTheClearCommandOperation')}
            buttonLabel={t('ok')}
            cancelTrue
          />,
        );
        break;
      case 'rebootDevice':
        setModal(
          'rebootDevice',
          () => handleControlApis('rebootDevice'),
          <ConformationBox
            closeModal={() => setModalOpen(false)}
            okFunction={() => handleControlApis('rebootDevice')}
            title={t('areYouWantToPerformTheRebootDeviceOperation')}
            buttonLabel={t('ok')}
            cancelTrue
          />,
        );
        break;
      case 'enable':
        setModal(
          'enable',
          () => handleControlApis('enableDevice'),
          <ConformationBox
            closeModal={() => setModalOpen(false)}
            okFunction={() => handleControlApis('enableDevice')}
            title={t('areYouWantToPerformTheEnableOperation')}
            buttonLabel={t('ok')}
            cancelTrue
          />,
        );
        break;
      case 'disable':
        setModal(
          'disable',
          () => handleControlApis('disableDevice'),
          <ConformationBox
            closeModal={() => setModalOpen(false)}
            okFunction={() => handleControlApis('disableDevice')}
            title={t('areYouWantToPerformTheDisableOperation')}
            buttonLabel={t('ok')}
            cancelTrue
          />,
        );
        break;
      case 'upgradeFirmware':
        setModalView({
          title: t('upgradeFirmware'),
          content: (
            <UpgradeFirmware closeModal={() => setModalOpen(false)} selectedValues={selectedData} />
          ),
        });
        setModalOpen(true);
        break;
      case 'synchronizeTime':
        setModalView({
          title: t('synchronizeTime'),
          content: (
            <SynchronizeTime
              closeModal={() => setModalOpen(false)}
              selectedValues={selectedData}
              selectedId={selectedIds}
            />
          ),
        });
        setModalOpen(true);
        break;
      case 'synchronizeAllDevices':
        setModalView({
          title: t('synchronizeAllDevices'),
          content: (
            <SynchronizeAllDataToDevice
              closeModal={() => setModalOpen(false)}
              selectedValues={selectedData}
              selectedId={selectedIds}
            />
          ),
        });
        setModalOpen(true);
        break;
      default:
        break;
    }
  };

  const changeControl = (value: any) => {
    handleClickControl(value);
  };

  // setup
  const handleClickSetup = (value: string = '') => {
    if (selectedIds.length > 0) {
      if (value === 'verificationOptions') {
        setModalOpen(true);
        setModalView({
          title: t('Set Bg - Verification Options'),
          content: (
            <BgVerificationOptions
              closeModal={() => setModalOpen(false)}
              selectedValues={selectedData}
              selectedId={selectedIds}
            />
          ),
        });
      } else if (value === 'setDeviceTimeZone') {
        setModalOpen(true);
        setModalView({
          title: t('setDeviceTimeZone'),
          content: (
            <DeviceTimeZone
              closeModal={() => setModalOpen(false)}
              selectedValues={selectedData}
              selectedId={selectedIds}
            />
          ),
        });
      } else if (value === 'registrationDevice') {
        if (selectedIds.length === 1) {
          device
            .isSupportRegistration(selectedIds)
            .then((response) => {
              if (response.data.data.failedReason === 'Device does not support this feature') {
                setModalView({
                  title: t('setAsRegistrationDevice'),
                  content: (
                    <ConformationBox
                      closeModal={() => setModalOpen(false)}
                      okFunction={() => setModalOpen(false)}
                      title={t('Device does not support this feature')}
                      buttonLabel={t('ok')}
                    />
                  ),
                });
                setModalOpen(true);
              } else {
                setModalOpen(true);
                setModalView({
                  title: t('registrationDevice'),
                  content: (
                    <RegistrationDevice
                      closeModal={() => setModalOpen(false)}
                      selectedValues={selectedIds}
                    />
                  ),
                });
              }
            })
            .catch((error) => {
              dispatch(
                setNotificationError({
                  error: error.message,
                  status: error.response?.status || 500,
                }),
              );
            });
        } else {
          setModalOpen(true);
          setModalView({
            title: t('selectObject'),
            content: (
              <ConformationBox
                closeModal={() => setModalOpen(false)}
                okFunction={() => setModalOpen(false)}
                title={t('Only one object can be selected at one time!')}
                buttonLabel={t('ok')}
              />
            ),
          });
        }
      } else if (value === 'modifyFingerprintThreshold') {
        if (selectedIds.length === 1) {
          setModalOpen(true);
          setModalView({
            title: t('modifyFingerprintThreshold'),
            content: (
              <ModifyFingerThrehold
                closeModal={() => setModalOpen(false)}
                // selectedValues={selectedData}
                selectedId={selectedIds}
              />
            ),
          });
        } else {
          setModalOpen(true);
          setModalView({
            title: t('selectObject'),
            content: (
              <ConformationBox
                closeModal={() => setModalOpen(false)}
                okFunction={() => setModalOpen(false)}
                title={t('Only one object can be selected at one time!')}
                buttonLabel={t('ok')}
              />
            ),
          });
        }
      } else if (value === 'extendedParameters') {
        if (selectedIds.length === 1) {
          device
            .isSupportExtendParam(selectedIds)
            .then((response) => {
              const result = response.data.data.failedReason;
              if (result === 'Device does not support this feature') {
                setModalView({
                  title: t('setAsRegistrationDevice'),
                  content: (
                    <ConformationBox
                      closeModal={() => setModalOpen(false)}
                      okFunction={() => setModalOpen(false)}
                      title={result}
                      buttonLabel={t('ok')}
                    />
                  ),
                });
                setModalOpen(true);
              } else {
                setModalOpen(true);
                setModalView({
                  title: t('registrationDevice'),
                  content: (
                    <RegistrationDevice
                      closeModal={() => setModalOpen(false)}
                      selectedValues={selectedData}
                    />
                  ),
                });
              }
            })
            .catch((error) => {
              dispatch(
                setNotificationError({
                  error: error.message,
                  status: error.response?.status || 500,
                }),
              );
            });
        } else {
          setModalOpen(true);
          setModalView({
            title: t('selectObject'),
            content: (
              <ConformationBox
                closeModal={() => setModalOpen(false)}
                okFunction={() => setModalOpen(false)}
                title={t('Only one object can be selected at one time!')}
                buttonLabel={t('ok')}
              />
            ),
          });
        }
      } else if (value === 'replaceDevice') {
        if (selectedIds.length === 1) {
          setModalView({
            title: t('replaceDevice'),
            content: (
              <ReplaceDevice closeModal={() => setModalOpen(false)} selectedId={selectedIds} />
            ),
          });
          setModalOpen(true);
        } else {
          setModalOpen(true);
          setModalView({
            title: t('selectObject'),
            content: (
              <ConformationBox
                closeModal={() => setModalOpen(false)}
                okFunction={() => setModalOpen(false)}
                title={t('Only one object can be selected at one time!')}
                buttonLabel={t('ok')}
              />
            ),
          });
        }
      }
    } else {
      setModalOpen(true);
      setModalView({
        title: t('selectObject'),
        content: (
          <ConformationBox
            closeModal={() => setModalOpen(false)}
            okFunction={() => setModalOpen(false)}
            title={t('pleaseSelectTheObjectsYouWantToOperate')}
            buttonLabel={t('ok')}
          />
        ),
      });
    }
  };

  const changeSetup = (value: any) => {
    handleClickSetup(value);
  };

  // view-get
  const handleClickViewGet = (value: string = '') => {
    if (selectedIds.length > 0) {
      if (value === 'getDeviceOption') {
        const modalTitle =
          selectedIds.length === 1
            ? t('areYouWantToPerformTheGetDeviceOptionOperation')
            : t('Only one object can be selected at one time!');
        const clickFunction =
          selectedIds.length === 1 ? handleGetDeviceOption : () => setModalOpen(false);
        if (selectedIds.length === 1) {
          setModalView({
            title: t('getDeviceOption'),
            content: (
              <ConformationBox
                closeModal={() => setModalOpen(false)}
                okFunction={clickFunction}
                title={modalTitle}
                buttonLabel={t('ok')}
                cancelTrue={selectedIds.length === 1}
              />
            ),
          });
          setModalOpen(true);
        }
      } else if (value === 'getPersonnelInformation') {
        if (selectedIds.length === 1) {
          setModalOpen(true);
          setModalView({
            title: t('getPersonnelInformation'),
            content: (
              <GetPersonalInformation
                closeModal={() => setModalOpen(false)}
                selectedValues={selectedData}
                selectedId={selectedIds}
              />
            ),
          });
        } else {
          setModalOpen(true);
          setModalView({
            title: t('selectObject'),
            content: (
              <ConformationBox
                closeModal={() => setModalOpen(false)}
                okFunction={() => setModalOpen(false)}
                title={t('Only one object can be selected at one time!')}
                buttonLabel={t('ok')}
              />
            ),
          });
        }
      } else if (value === 'getTransactions') {
        if (selectedIds.length === 1) {
          setModalOpen(true);
          setModalView({
            title: t('getTransactions'),
            content: (
              <GetTransaction
                closeModal={() => setModalOpen(false)}
                selectedValues={selectedData}
                selectedId={selectedIds}
              />
            ),
          });
        } else {
          setModalOpen(true);
          setModalView({
            title: t('selectObject'),
            content: (
              <ConformationBox
                closeModal={() => setModalOpen(false)}
                okFunction={() => setModalOpen(false)}
                title={t('Only one object can be selected at one time!')}
                buttonLabel={t('ok')}
              />
            ),
          });
        }
      } else if (value === 'viewRulesOfDevices') {
        if (selectedIds.length === 1) {
          setModalOpen(true);
          setModalView({
            title: t('viewRulesOfDevices'),
            content: (
              <ViewRulesOfDevices
                closeModal={() => setModalOpen(false)}
                selectedValues={selectedData}
                selectedId={selectedIds}
              />
            ),
          });
        } else {
          setModalOpen(true);
          setModalView({
            title: t('selectObject'),
            content: (
              <ConformationBox
                closeModal={() => setModalOpen(false)}
                okFunction={() => setModalOpen(false)}
                title={t('Only one object can be selected at one time!')}
                buttonLabel={t('ok')}
              />
            ),
          });
        }
      } else if (value === 'viewDeviceCapacity') {
        setModalOpen(true);
        setModalView({
          title: t('viewDeviceCapacity'),
          content: (
            <ViewDeviceCapacity closeModal={() => setModalOpen(false)} selectedId={selectedIds} />
          ),
        });
      }
    } else {
      setModalOpen(true);
      setModalView({
        title: t('selectObject'),
        content: (
          <ConformationBox
            closeModal={() => setModalOpen(false)}
            okFunction={() => setModalOpen(false)}
            title={t('pleaseSelectTheObjectsYouWantToOperate')}
            buttonLabel={t('ok')}
          />
        ),
      });
    }
  };

  const changeViewGet = (value: any) => {
    handleClickViewGet(value);
  };
  // communication
  const handleClickCommunication = (value: string = '') => {
    const openConfirmationBox = (title: string, message: string) => {
      setModalOpen(true);
      setModalView({
        title,
        content: (
          <ConformationBox
            closeModal={() => setModalOpen(false)}
            okFunction={() => setModalOpen(false)}
            title={message}
            buttonLabel={t('ok')}
          />
        ),
      });
    };

    const openModalWithContent = (title: string, Component: React.ReactElement) => {
      setModalOpen(true);
      setModalView({
        title,
        content: Component,
      });
    };

    if (selectedIds.length === 0) {
      openConfirmationBox(t('selectObject'), t('pleaseSelectTheObjectsYouWantToOperate'));
      return;
    }

    if (selectedIds.length > 1) {
      openConfirmationBox(t('selectObject'), t('Only one object can be selected at one time!'));
      return;
    }

    const modals: any = {
      modifyIpAddress: {
        title: t('modifyIPAddress'),
        Component: (
          <ModifyIpAddress
            closeModal={() => setModalOpen(false)}
            selectedId={selectedIds}
            selectedValues={selectedData}
          />
        ),
      },
      modifyCommunicationPassword: {
        title: t('modifyCommunicationPassword'),
        Component: (
          <ModifyCommunicationPassword
            closeModal={() => setModalOpen(false)}
            selectedId={selectedIds}
            selectedValues={selectedData}
          />
        ),
      },
      switchNetworkConnection: {
        title: t('switchNetworkConnection'),
        Component: (
          <SwitchNetworkConnection
            closeModal={() => setModalOpen(false)}
            selectedId={selectedIds}
            selectedValues={selectedData}
          />
        ),
      },
    };

    const modal = modals[value];
    if (modal) {
      openModalWithContent(modal.title, modal.Component);
    }
  };

  const changeCommunication = (value: any) => {
    handleClickCommunication(value);
  };
  handleEditOpen = (editData: any) => {
    const updateData = {
      ...editData,
      controlPanelType: editData?.acpanelType,
      area: editData?.authAreaId,
    };
    setModalOpen(true);
    setModalView({
      title: t('editDevice'),
      content: <AddDevice closeModal={() => setModalOpen(false)} data={{ ...updateData }} />,
    });
  };
  const handleDeleteClick = () => {
    if (selectedDeviceFullData.length === 0) {
      dispatch(
        setNotificationError({
          error: t('pleaseSelectAtLeastOneDevice'), // Also fixed the error message
          status: 400,
        }),
      );
      return;
    }

    setModalOpen(true);
    setModalView({
      title: t('delete'),
      content: (
        <DeleteModalBox
          closeModal={() => setModalOpen(false)}
          id={selectedDeviceFullData.map((val) => val.id).join(',')}
          okFunction={deleteRecord}
          isMultiSelect
        />
      ),
    });
  };
  const actionButtons = [
    {
      label: t('edit'),
      enable: checkPermission('acc:device:edit', loadMenus),
      function: (val: any) => handleEditOpen(val),
    },
    {
      label: t('delete'),
      enable: checkPermission('acc:device:del', loadMenus),
      function: (val: any) => {
        setModalOpen(true);
        setModalView({
          title: t('deleteDevice'),
          content: (
            <DeleteModalBox
              closeModal={() => setModalOpen(false)}
              id={val.id}
              okFunction={deleteRecord}
              disabled={deleteButton}
            />
          ),
        });
      },
    },
    {
      label: t('viewCommand'),
      enable: checkPermission('acc:device:del', loadMenus),
      function: (val: any) => {
        dispatch(saveSystemCode('System'));
        navigate(`/admin/system/communication-management/device-command/${val?.sn}`);
      },
    },
  ];

  const moreActionButton = [
    {
      title: t('search'),
      enable: checkPermission('acc:device:searchDev', loadMenus),
      clickFunction: (): void => {
        setModalOpen(true);
        setModalView({
          title: t('searchDevice'),
          content: <SearchDevice closeModal={() => setModalOpen(false)} />,
        });
      },
    },
    {
      title: t('export'),
      enable: checkPermission('acc:device:export', loadMenus),
      clickFunction: (): void => {
        setModalOpen(true);
        setModalView({
          title: t('export'),
          content: (
            <DeviceExportData closeModal={() => setModalOpen(false)} submitFunction={exportData} />
          ),
        });
      },
    },
    {
      dropdown: (
        <ListDropdown
          data={controlOptions(loadMenus)}
          label={t('control')}
          changeFunction={changeControl}
          disabled={selectedIds.length > 0}
        />
      ),
      enable: controlOptions(loadMenus)?.length > 0,
      clickFunction: handleClickControl,
    },
    {
      dropdown: (
        <ListDropdown
          data={setupOptions(loadMenus)}
          label={t('setup')}
          changeFunction={changeSetup}
          disabled={selectedIds.length > 0}
        />
      ),
      enable: setupOptions(loadMenus)?.length > 0,
      clickFunction: handleClickSetup,
    },
    {
      dropdown: (
        <ListDropdown
          data={viewGetOptions(loadMenus)}
          label={t('view/get')}
          changeFunction={changeViewGet}
          disabled={selectedIds.length > 0}
        />
      ),
      enable: viewGetOptions(loadMenus)?.length > 0,
      clickFunction: handleClickViewGet,
    },
    {
      dropdown: (
        <ListDropdown
          data={CommunicationOptions(loadMenus)}
          label={t('communication')}
          changeFunction={changeCommunication}
          disabled={selectedIds.length > 0}
        />
      ),
      enable: CommunicationOptions(loadMenus)?.length > 0,
      clickFunction: handleClickCommunication,
    },
  ];

  const getFilterData = (filter: any): any => {
    if (filter.pageIndex > 0) {
      dispatch(setSearchFilter(filter));
      fetchData({ pageNo: filter.pageIndex, pageSize: filter.pageSize, ...filter.filter });
    }
  };

  const handleRefresh = () => {
    fetchData({ pageNo: 1, pageSize: 10 });
    setSelectedCards([]);
    setSelectedData([]);
  };

  return (
    <div className="card-management">
      <ModalBox
        status={modalOpen}
        closeModal={() => setModalOpen(false)}
        title={modalView.title}
        content={modalView.content}
      />
      <TopHeader
        title={t('access')}
        broadCram={[
          {
            title: t('accessDevice'),
          },
          {
            title: t('device'),
          },
        ]}
      />
      <CardBox
        title={t('listOfDevice')}
        rightSide={
          <div className="delete-icon">
            <CardRightButtonGroup
              actions={moreActionButton}
              buttonIcon={<AddIcon color="#ffffff" />}
              buttonLabel={t('addDevice')}
              buttonClass="btn-primary btn-sm"
              buttonStatus
              buttonFunction={(): void => {
                setModalOpen(true);
                setModalView({
                  title: t('addDevice'),
                  content: <AddDevice closeModal={() => setModalOpen(false)} />,
                });
              }}
              buttonEnable={checkPermission('acc:device:add', loadMenus)}
              // Add delete button configuration
              DeleteButtonIcon={<DeleteButtonIcon />}
              deleteButtonLabel={t('delete')}
              showDeleteButton
              deleteButtonFunction={handleDeleteClick}
              deleteButtonEnable={checkPermission('acc:device:del', loadMenus)}
              deleteButtonClass="multi-delete-button"
            />
          </div>
        }>
        <DataTable
          actionButtonStatus={!modalOpen}
          action={actionButtons}
          columns={columnsField}
          getFilterData={getFilterData}
          assignFilterValue={searchFilter}
          assignSelectedData={selectedIds}
          getSelectedData={(val: any): any => setSelectedCards(val)}
          getSelectedFullData={(val: any): any => {
            setSelectedDeviceFullData(val);
          }}
          checkBoxActive
          enablePagination
          isFetching={status === 'loading'}
          values={devices}
          enableRefresh
          handleRefresh={handleRefresh}
          fixedColumn={['name']}
          totalRecords={totalDataCount}
          height={411}
        />
      </CardBox>
    </div>
  );
}

export default DeviceList;
