import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from '../../../../redux/store';

// Shared components
import TopHeader from '../../../../shared-components/top-header/top-header';
import CardBox from '../../../../shared-components/card/card';
import DataTable from '../../../../shared-components/data-table/data-table';
import CardRightButtonGroup from '../../../../shared-components/card-right-button-group/card-right-button-group';
import ModalBox from '../../../../shared-components/modal-box/modal-box';
import DeleteModalBox from '../../../../shared-components/delete-modal-box/delete-modal-box';
import { setNotificationError } from '../../../../redux/slices/NotificationSlices';
import { AddIcon } from '../../../../assets/svgicons/svgicon';

// Redux slice actions
import {
  fetchApiAuthorization,
  deleteApiAuthorization as deleteEM,
  setSearchFilter,
} from '../../../../redux/slices/system-management/APIAuthorization';
import apiAuthorization from '../../../../utils/api/SystemManagement/apiAuthorization';

// Components
import { FilterProps } from '../../../../interface/common.interface';
import AddClient from './components/AddClient';
import { checkPermission } from '../../../../utils/role-permission';

function APIAuthorization() {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { apiAuthorizationList, status, totalDataCount, searchFilter } = useAppSelector(
    (state: any) => state.apiAuthorization,
  );

  const [modalOpen, setModalOpen] = useState(false);
  const [modalView, setModalView] = useState({
    title: '',
    content: <>hi</>,
  });
  const { loadMenus } = useAppSelector((state) => state.auth);

  const [selectedValues, setSelectedValues] = useState<any>([]);
  let handleEditOpen: any;

  const columns = [
    {
      accessorKey: 'clientName',
      header: t('clientID'),
      Cell: ({ row }: any) =>
        checkPermission('auth:apiToken:edit', loadMenus) ? (
          <span
            className="list-hyperlink"
            role="presentation"
            onClick={(event) => {
              event.stopPropagation();
              handleEditOpen(row.original);
            }}>
            {row?.original?.clientName}
          </span>
        ) : (
          <div>{row?.original?.name}</div>
        ),
      size: 150,
    },
    { accessorKey: 'clientToken', header: t('clientSecret') },
    {
      accessorKey: 'createTime',
      header: t('createTime'),
    },
  ];

  const fetchData = async (params: FilterProps) => {
    if (status === 'success') {
      dispatch(fetchApiAuthorization({ ...params }));
    }
  };

  const getFilterData = (filter: any): any => {
    if (filter.pageIndex > 0) {
      dispatch(setSearchFilter(filter));
      fetchData({ pageNo: filter.pageIndex, pageSize: filter.pageSize, ...filter.filter });
    }
  };
  const deleteRecord = (id: any) => {
    apiAuthorization
      .delete(id)
      .then((response) => {
        if (response.data.success) {
          dispatch(
            setNotificationError({
              error: t('apiAuthorizationDeletedSuccessfully'),
              status: 200,
            }),
          );
          dispatch(deleteEM(id));
          setModalOpen(false);
          fetchData({ pageNo: 1 });
        } else {
          dispatch(
            setNotificationError({
              error: response.data.message,
              status: response.data.code,
            }),
          );
        }
      })
      .catch((error) => {
        dispatch(
          setNotificationError({
            error: error.message,
            status: error.status,
          }),
        );
      });
  };
  const actionButtons = [
    {
      label: t('edit'),
      enable: checkPermission('auth:apiToken:edit', loadMenus),

      function: (val: any) => handleEditOpen(val),
    },
    {
      label: t('delete'),
      enable: checkPermission('auth:apiToken:del', loadMenus),
      function: (val: any) => {
        setModalOpen(true);
        setModalView({
          title: t('deleteResource'),
          content: (
            <DeleteModalBox
              closeModal={() => setModalOpen(false)}
              id={val.id}
              okFunction={deleteRecord}
            />
          ),
        });
      },
    },
  ];

  handleEditOpen = (editData: any) => {
    if (!modalOpen) {
      setModalOpen(true);
      setModalView({
        title: t('edit'),
        content: <AddClient closeModal={() => setModalOpen(false)} data={editData} />,
      });
    }
  };
  const moreActionButton = [
    // {
    //   title: t('delete'),
    //   enable: checkPermission('auth:apiToken:del', loadMenus),
    //   clickFunction: (): void => {
    //     if (selectedValues.length === 0) {
    //       dispatch(
    //         setNotificationError({
    //           error: t('pleaseSelectAtLeastOneLinkage'),
    //           status: 400,
    //         }),
    //       );
    //       return;
    //     }
    //     setModalView({
    //       title: t('delete'),
    //       content: (
    //         <DeleteModalBox
    //           closeModal={() => setModalOpen(false)}
    //           id={selectedValues}
    //           okFunction={deleteRecord}
    //         />
    //       ),
    //     });
    //     setModalOpen(true);
    //   },
    // },
    {
      title: t('browseTheAPI'),
      enable: checkPermission('auth:apiToken:browse', loadMenus),
      clickFunction: (): void => {
        // Use the utility function to open Swagger UI
        apiAuthorization.openSwaggerUI();
      },
    },
  ];

  return (
    <div className="card-management">
      <ModalBox
        status={modalOpen}
        closeModal={() => setModalOpen(false)}
        title={modalView.title}
        content={modalView.content}
      />
      <TopHeader
        title={t('system')}
        broadCram={[{ title: t('authorityManagement') }, { title: t('apiAuthorization') }]}
      />
      <CardBox
        header=""
        title={t('listOfClient')}
        rightSide={
          <CardRightButtonGroup
            actions={moreActionButton}
            buttonIcon={<AddIcon color="#ffffff" />}
            buttonLabel={t('new')}
            buttonClass="btn-primary btn-sm"
            buttonStatus
            buttonFunction={(): void => {
              setModalOpen(true);
              setModalView({
                title: t('new'),
                content: <AddClient closeModal={() => setModalOpen(false)} />,
              });
            }}
            buttonEnable={checkPermission('auth:apiToken:add', loadMenus)}
          />
        }>
        <DataTable
          actionButtonStatus={!modalOpen}
          action={actionButtons}
          columns={columns}
          getFilterData={getFilterData}
          assignFilterValue={searchFilter}
          assignSelectedData={selectedValues}
          getSelectedData={(val: any): any => setSelectedValues(val)}
          checkBoxActive
          enablePagination
          isFetching={status === 'loading'}
          values={apiAuthorizationList}
          fixedColumn={['clientName']}
          totalRecords={totalDataCount}
          height={411}
        />
      </CardBox>
    </div>
  );
}

export default APIAuthorization;
