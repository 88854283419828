import React from 'react';
import { useTranslation } from 'react-i18next';
import GaugeChart from 'react-gauge-chart';
import Label from '../../../../../shared-components/label/Label';
import Table from '../../../../../shared-components/table/table';
import {
  CurrentInfoTabType,
  JvmOrPcInterface,
} from '../../../../../interface/System/currentInformation.interface';
import { convertToDecimal } from '../../../../../utils/helpers';
import SpinnerLoader from '../../../../../shared-components/spinnerLoader/spinner-loader';

function CurrentInformation({ currentInfo }: CurrentInfoTabType) {
  const { t } = useTranslation();
  const cpuUsageRadio: string = currentInfo?.cpu?.usageRatio?.split('%')?.[0];
  const convertCpu: number = convertToDecimal(Number(cpuUsageRadio));
  const pcUsageRadio: string = currentInfo?.pc?.usageRatio?.split('%')?.[0];
  const convertPc: number = convertToDecimal(Number(pcUsageRadio));
  const jvmUsageRadio: string = currentInfo?.jvm?.usageRatio?.split('%')?.[0];
  const convertJvm: number = convertToDecimal(Number(jvmUsageRadio));

  const system = currentInfo?.system
    ? Object?.entries(currentInfo?.system).map(([key, value]) => ({
        attribute: key,
        value,
      }))
    : [];
  const cpuData = currentInfo?.cpu
    ? Object?.entries(currentInfo?.cpu).map(([key, value]) => ({
        attribute: key,
        value,
      }))
    : [];
  const memoryData =
    currentInfo?.pc && currentInfo?.jvm
      ? Object.keys(currentInfo?.jvm).map((key) => ({
          attribute: key,
          physicalHost: currentInfo?.pc[key as keyof JvmOrPcInterface],
          jvm: currentInfo?.jvm[key as keyof JvmOrPcInterface],
        }))
      : [];
  const thread: any = currentInfo?.thread?.data;
  /**
   * use RegExp
   * More robust parsing, avoiding potential issues with .split() on unexpected spaces.
   */
  const parsedThreadData = thread
    ? thread?.map((item: any) =>
        JSON.parse(
          item
            .replace(/{/g, '{"')
            .replace(/=/g, '":"')
            .replace(/, /g, '", "')
            .replace(/}/g, '"}')
            .replace(/'/g, ''),
        ),
      )
    : [];

  return (
    <div className="current-information">
      <div className="chart-container">
        <div className="chart-wrapper">
          <div className="chart-title">
            <Label title={t('processorUsage')} />
          </div>
          <div className="charts">
            {currentInfo?.cpu ? (
              <GaugeChart
                id="gauge-chart"
                className="chart"
                nrOfLevels={30}
                percent={convertCpu}
                textColor="transparent"
                needleColor="red"
                colors={['#000000', '#D9D9D9']}
              />
            ) : (
              <SpinnerLoader />
            )}
          </div>
          <span className="chart-value">{currentInfo?.cpu?.usageRatio}</span>
        </div>
        <div className="chart-wrapper">
          <div className="chart-title">
            <Label title={t('pcMemoryUsage')} />
          </div>
          <div className="charts">
            {currentInfo?.pc ? (
              <GaugeChart
                id="gauge-chart"
                className="chart"
                nrOfLevels={30}
                percent={convertPc}
                textColor="transparent"
                needleColor="red"
                colors={['#000000', '#D9D9D9']}
              />
            ) : (
              <SpinnerLoader />
            )}
          </div>
          <span className="chart-value">{currentInfo?.pc?.usageRatio}</span>
        </div>
        <div className="chart-wrapper">
          <div className="chart-title">
            <Label title={t('jvmMemoryUsage')} />
          </div>
          <div className="charts">
            {currentInfo?.jvm ? (
              <GaugeChart
                id="gauge-chart"
                className="chart"
                nrOfLevels={30}
                percent={convertJvm}
                textColor="transparent"
                needleColor="red"
                colors={['#000000', '#D9D9D9']}
              />
            ) : (
              <SpinnerLoader />
            )}
          </div>
          <span className="chart-value">{currentInfo?.jvm?.usageRatio}</span>
        </div>
      </div>
      <hr />
      <div className="info-table-container chart-container">
        <div className="table-wrapper">
          <div className="table-title">
            <Label title={t('processorInformation')} />
          </div>
          <Table
            header={[
              { label: t('attribute'), id: 'attribute' },
              { label: t('value'), id: 'value' },
            ]}
            hideHeader={false}
            value={cpuData}
            textWrap
          />
        </div>
        <div className="table-wrapper">
          <div className="table-title">
            <Label title={t('memoryInformation')} />
          </div>
          <Table
            header={[
              { label: t('attribute'), id: 'attribute' },
              { label: t('physicalHost'), id: 'physicalHost' },
              { label: t('jvm'), id: 'jvm' },
            ]}
            hideHeader={false}
            value={memoryData}
            textWrap
          />
        </div>
        <div className="table-wrapper">
          <div className="table-title">
            <Label title={t('systemInformation')} />
          </div>
          <Table
            header={[
              { label: t('attribute'), id: 'attribute' },
              { label: t('value'), id: 'value' },
            ]}
            hideHeader={false}
            value={system}
            textWrap
          />
        </div>
      </div>
      <div className="table-wrapper">
        <div className="table-title">
          <Label title={`${t('numberOfNetwork')} ${currentInfo?.thread?.threadCount}`} />
        </div>
        <Table
          header={[
            { label: t('id'), id: 'id' },
            { label: t('name'), id: 'name' },
            { label: t('status'), id: 'state' },
          ]}
          hideHeader={false}
          value={parsedThreadData}
          textWrap
        />
      </div>
      <div className="table-wrapper">
        <div className="table-title">
          <Label title={`${t('numberOfNetworkPorts')} ${currentInfo?.net?.length}`} />
        </div>
        <Table
          header={[
            { label: t('Number Of Network Ports'), id: 'name' },
            { label: t('ipv4'), id: 'ipv4' },
            { label: t('maximumTransmissionUnit'), id: 'mtu' },
            { label: t('maximumSpeed'), id: 'speed' },
            { label: t('receivingSpeed'), id: 'recv' },
            { label: t('sendingSpeed'), id: 'sent' },
          ]}
          hideHeader={false}
          value={currentInfo?.net}
          textWrap
        />
      </div>
    </div>
  );
}

export default CurrentInformation;
