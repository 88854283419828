import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import Cookies from 'universal-cookie';
import InputField from '../../../shared-components/input-field/input-field';
import Button from '../../../shared-components/button/button';
import { setNotificationError } from '../../../redux/slices/NotificationSlices';
import realtimeMonitoring from '../../../utils/api/Devices/realtimeMonitoring';
import auth from '../../../utils/api/auth';

interface RealTimeCommonPopupProps {
  selected: any;
  allDoorIds?: any;
  closeModal: () => void;
  modelName: string;
  message?: string;
  submitFunction?: (val: any) => void;
}

function RealTimeCommonPopup({
  selected,
  allDoorIds,
  closeModal,
  modelName,
  message,
  submitFunction,
}: RealTimeCommonPopupProps) {
  const { t } = useTranslation();
  const cookies = new Cookies();
  const dispatch = useDispatch();

  const [doors, setDoor] = useState<any>({
    openInterval: 5,
    loginPassword: '',
    userName: cookies.get('username') && cookies.get('username'),
  });
  const [disable, setDisable] = useState(false);
  const [showPwd, setShowPwd] = useState(false);

  /**
   * Handles the submission of door-related operations.
   * It determines the required request payload based on the selected operation
   * and executes the corresponding API call.
   *
   * @function submit
   * @returns {void}
   */
  const submit = (): void => {
    setDisable(true);

    const doorIdsToSend = selected.length > 0 ? selected : allDoorIds;

    /** @type {Object} Request object for opening doors */
    const requestOpenDoor: any = {
      ids: doorIdsToSend?.join(','),
      openInterval: doors?.openInterval,
    };

    /** @type {Object} Generic request object */
    const request: any = {
      ids: doorIdsToSend?.join(','),
    };

    if (!showPwd) {
      requestOpenDoor.userName = doors?.userName;
      requestOpenDoor.loginPassword = doors?.loginPassword;
      request.userName = doors?.userName;
      request.loginPassword = doors?.loginPassword;
    }

    /** @type {Object.<string, Function>} Mapping of operations to their respective API calls */
    const operations: { [s: string]: Function } = {
      remoteOpening: () => realtimeMonitoring.openDoor(requestOpenDoor),
      remoteClosing: () => realtimeMonitoring.closeDoor(request),
      cancelAlarm: () => realtimeMonitoring.cancelAlarm(request),
      activateLockDown: () => realtimeMonitoring.lockDoor(request),
      deActivateLockDown: () => realtimeMonitoring.unLockDoor(request),
      remoteNormallyOpen: () => realtimeMonitoring.normalOpenDoor(request),
      enableIntraday: () => realtimeMonitoring.enableNormalOpenDoor(request),
      disableIntraday: () => realtimeMonitoring.disableNormalOpenDoor(request),
      remoteOpen: () => realtimeMonitoring.openAuxOut(requestOpenDoor),
      remoteClose: () => realtimeMonitoring.closeAuxOut(request),
      remoteNormalOpen: () => realtimeMonitoring.auxOutNormalOpen(request),
    };

    /**
     * Handles the success response from the API call.
     * Displays a success notification and re-enables the button after a delay.
     *
     * @param {Object} res - The response object from the API
     */
    const handleSuccess = (res: any) => {
      dispatch(setNotificationError({ error: t('theOperationSucceeded'), status: 200 }));
      closeModal();

      if (submitFunction && modelName === 'remoteOpening') {
        submitFunction(doors);
      }

      if (res?.data?.code === 0) {
        setTimeout(() => setDisable(false), 3500);
      }
    };

    /**
     * Handles the error response from the API call.
     * Displays an error notification and re-enables the button.
     *
     * @param {Object} error - The error object from the API response
     */
    const handleError = (error: any) => {
      setDisable(false);
      dispatch(
        setNotificationError({
          error: error?.response?.data?.msg || error.message,
          status: error?.status,
        }),
      );
    };

    // Execute the operation if it exists
    if (operations[modelName as keyof typeof operations]) {
      operations[modelName as keyof typeof operations]().then(handleSuccess).catch(handleError);
    } else {
      setDisable(false);
      dispatch(
        setNotificationError({
          error: t('operationNotSupported'),
          status: 400,
        }),
      );
    }
  };

  const checkNeedValidUser = async () => {
    try {
      const response = await auth.isNeedValidUser({ mode: 'pwd' });
      if (response.data.success) {
        setShowPwd(response?.data?.success);
      }
    } catch (error: any) {
      dispatch(
        setNotificationError({
          error: error?.response?.data?.msg || error.message,
          status: error?.status || 500,
        }),
      );
    }
  };

  useEffect(() => {
    checkNeedValidUser();
  }, []);

  useEffect(() => {
    if (
      doors?.openInterval &&
      (showPwd ? true : doors?.loginPassword) &&
      Number(doors.openInterval) > 0 &&
      Number(doors.openInterval) < 255
    ) {
      setDisable(false);
    } else {
      setDisable(true);
    }
  }, [doors, showPwd]);

  return (
    <div className="door-modals">
      <div className="flex-row column">
        <h4 className="label">
          {t('target')} : <span>{selected.length > 0 ? t('selected') : t('allDoors')}</span>
        </h4>
        <div className={`input-wrap ${!showPwd ? 'column' : ''}`}>
          {modelName === 'remoteOpening' || modelName === 'remoteOpen' ? (
            <InputField
              name="openInterval"
              label={t('open')}
              id="openInterval"
              type="number"
              value={doors.openInterval}
              innerIconStatus={false}
              onChangeFunction={(field: string, value: any) =>
                setDoor({ ...doors, openInterval: value })
              }
              errorStatus={doors.openInterval <= 0 || doors.openInterval > 255}
              // validation={doors.openInterval < 0 || doors.openInterval > 255}
              message={t('second(1-254)')}
              required
            />
          ) : (
            <span className="message">{message}</span>
          )}
          {!showPwd && (
            <InputField
              name="loginPassword "
              label={t('password')}
              id="loginPassword "
              type="password"
              value={doors?.loginPassword}
              innerIconStatus={false}
              onChangeFunction={(field: string, value: any) =>
                setDoor({ ...doors, loginPassword: value })
              }
              required
            />
          )}
        </div>
        <div className="form-buttons-right">
          <Button
            onClickFunction={() => {
              closeModal();
            }}
            title={t('cancel')}
            className="btn-default"
          />
          <Button
            onClickFunction={submit}
            title={t('ok')}
            className="btn-primary"
            disabled={disable}
          />
        </div>
      </div>
    </div>
  );
}

RealTimeCommonPopup.defaultProps = {
  message: '',
  submitFunction: () => {},
  allDoorIds: [],
};

export default RealTimeCommonPopup;
