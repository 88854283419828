import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from '../../redux/store';

// Use shared components
import TopHeader from '../../shared-components/top-header/top-header';
import CardBox from '../../shared-components/card/card';
import DataTable from '../../shared-components/data-table/data-table';
import ModalBox from '../../shared-components/modal-box/modal-box';
// Use assets svg icons

// import DeleteModalBox from '../../shared-components/delete-modal-box/delete-modal-box';
// Import card slice actions
import AddPersonnel from './components/AddPersonnel';
import {
  deletePersons,
  fetchPersonsList,
  fetchVerifyModeGroup,
  setDoorSearchFilter,
} from '../../redux/slices/VerifyModeGroupSlices';
import DeleteModalBox from '../../shared-components/delete-modal-box/delete-modal-box';
import verificationModeGroup from '../../utils/api/AccessRule/verificationModeGroup';
import { setNotificationError } from '../../redux/slices/NotificationSlices';
import { checkPermission } from '../../utils/role-permission';
import CardRightButtonGroup from '../../shared-components/card-right-button-group/card-right-button-group';
import { DeleteButtonIcon } from '../../assets/svgicons/svgicon';

function VerificationModeGroup() {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const {
    verifyModeGroup,
    status,
    totalDataCount,
    searchFilter,
    persons,
    personStatus,
    personTotalDataCount,
    personSearchFilter,
  } = useAppSelector((state: any) => state.verifyModeGroup);

  /**
   * Selects the `loadMenus` permission from the authentication slice of the Redux store.
   *
   * @returns {Object} loadMenus - The user's menu permissions.
   */
  const { loadMenus } = useAppSelector((state) => state.auth);

  const [selectedVerify, setSelectedVerify] = useState<string[]>([]); // Add state to hold selected cards
  const [modalOpen, setModalOpen] = useState(false);
  const [modalView, setModalView] = useState({
    title: '',
    content: <>hi</>,
  });
  const [selectedPerson, setSelectedPersons] = useState<string[]>([]); // Add state to hold selected cards
  const [selectedPersonFullData, setSelectedPersonFullData] = useState<any[]>([]);

  const ColumnsField = [
    {
      accessorKey: 'name',
      header: t('ruleName'),
    },
    {
      accessorKey: 'timeSegName',
      header: t('timeZones'),
    },
  ];

  const doorColumnsField = [
    {
      accessorKey: 'personPin',
      header: t('personnelId'),
    },
    {
      accessorKey: 'personName',
      header: t('firstName'),
    },
    {
      accessorKey: 'personLastName',
      header: t('lastName'),
    },
    {
      accessorKey: 'deptName',
      header: t('department'),
    },
  ];

  const fetchData = async (params: any) => {
    if (status === 'success') {
      dispatch(fetchVerifyModeGroup({ ...params }));
    }
  };

  const getFilterData = (filter: any): any => {
    if (filter.pageIndex > 0) {
      dispatch(setDoorSearchFilter(filter));
      fetchData({ pageNo: filter.pageIndex, pageSize: filter.pageSize, ...filter.filter });
    }
  };

  const fetchPersons = (params: any) => {
    if (params.linkId) {
      dispatch(fetchPersonsList({ ...params }));
    }
  };

  const personFilter = (filter: any): any => {
    if (filter.pageIndex > 0) {
      dispatch(setDoorSearchFilter(filter));
      fetchPersons({ pageNo: filter.pageIndex, pageSize: filter.pageSize, ...filter.filter });
    }
  };

  const getSelected = (id: string[]) => {
    setSelectedVerify(id);
    fetchPersons({ linkId: id?.[0] });
  };

  const deletePerson = (id: any) => {
    const request = {
      personIds: id,
      verifyModeRulePersonGroupId: selectedVerify?.[0],
    };
    verificationModeGroup
      .delPerson(request)
      .then((response) => {
        if (response.data.success) {
          dispatch(
            setNotificationError({
              error: response.data.msg,
              status: 200,
            }),
          );
          dispatch(deletePersons(id));
          setModalOpen(false);
          fetchPersons({
            linkId: selectedVerify?.[0],
            pageNo: personSearchFilter.pageIndex,
            pageSize: personSearchFilter.pageSize,
          });
        } else {
          dispatch(
            setNotificationError({
              error: response.data.message,
              status: response.data.code,
            }),
          );
        }
      })
      .catch((error) => {
        dispatch(
          setNotificationError({
            error: error.message,
            status: error.response?.status || 500,
          }),
        );
      });
  };
  const handleDeletePersonClick = () => {
    if (selectedPersonFullData.length === 0) {
      dispatch(
        setNotificationError({
          error: t('pleaseSelectAtLeastOneDoor'),
          status: 400,
        }),
      );
      return;
    }

    setModalOpen(true);
    setModalView({
      title: t('delete'),
      content: (
        <DeleteModalBox
          closeModal={() => setModalOpen(false)}
          id={selectedPersonFullData.map((val) => val.id).join(',')}
          okFunction={deletePerson}
          isMultiSelect
        />
      ),
    });
  };

  const actionButtons = [
    {
      label: t('addPersonnel'),
      enable: checkPermission('acc:verifyModeRulePersonGroup:addPerson', loadMenus),
      function: (val: any): void => {
        setModalView({
          title: t('addPersonnel'),
          content: <AddPersonnel closeModal={() => setModalOpen(false)} personIds={val.id} />,
        });
        setModalOpen(true);
      },
    },
  ];
  const personActions = [
    {
      label: t('delete'),
      enable: checkPermission('acc:verifyModeRulePersonGroup:delPerson', loadMenus),
      function: (val: any) => {
        setModalOpen(true);
        setModalView({
          title: t('delete'),
          content: (
            <DeleteModalBox
              closeModal={() => setModalOpen(false)}
              id={val.id}
              okFunction={deletePerson}
            />
          ),
        });
      },
    },
  ];

  return (
    <div className="card-management">
      <ModalBox
        status={modalOpen}
        closeModal={() => setModalOpen(false)}
        title={modalView.title}
        content={modalView.content}
      />
      <TopHeader
        title={t('access')}
        broadCram={[
          {
            title: t('accessRule'),
          },
          {
            title: t('verificationModeGroup'),
          },
        ]}
      />
      <CardBox header={t('verificationMode')} title={t('listOfRules')}>
        <DataTable
          actionButtonStatus={!modalOpen}
          action={actionButtons}
          columns={ColumnsField}
          getFilterData={getFilterData}
          assignFilterValue={searchFilter}
          assignSelectedData={selectedVerify} // Assign selected cards
          getSelectedData={(val: any): any => getSelected(val)} // Set selected
          checkBoxActive
          singleRowSelection
          enablePagination
          isFetching={status === 'loading'}
          values={verifyModeGroup}
          fixedColumn={['name']}
          totalRecords={totalDataCount}
          height={411}
        />
        <hr className="line" />
        <div className="header" style={{ padding: '20px' }}>
          <h4>{t('browsePersonnel')}</h4>
        </div>
        <div className="door-header">
          <div className="doorlist">
            <span className="sub-title">{t('listOfPersonnel')}</span>
          </div>
          <div className="delete-icon">
            <CardRightButtonGroup
              DeleteButtonIcon={<DeleteButtonIcon />}
              deleteButtonLabel={t('delete')}
              showDeleteButton
              deleteButtonFunction={handleDeletePersonClick}
              deleteButtonEnable={checkPermission(
                'acc:verifyModeRulePersonGroup:delPerson',
                loadMenus,
              )}
              deleteButtonClass="multi-delete-button"
            />
          </div>
        </div>
        <DataTable
          action={personActions}
          columns={doorColumnsField}
          values={persons}
          actionButtonStatus={!modalOpen}
          getFilterData={personFilter}
          assignFilterValue={personSearchFilter}
          assignSelectedData={selectedPerson} // Assign selected cards
          getSelectedData={(val: any): any => setSelectedPersons(val)} // Set selected cards
          getSelectedFullData={(val: any): any => {
            setSelectedPersonFullData(val);
          }}
          checkBoxActive
          isFetching={personStatus === 'loading'}
          fixedColumn={['personPin']}
          totalRecords={personTotalDataCount}
          height={411}
        />
      </CardBox>
    </div>
  );
}

export default VerificationModeGroup;
