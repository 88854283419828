import React, { useEffect, useState } from 'react';

// Use interface
import { SelectBoxFieldInterFace, SelectItemInterFace } from '../../interface/common.interface';

// Use assets svg icons
import { DownArrowIcon, CloseIcon } from '../../assets/svgicons/svgicon';

function SelectBoxField({
  name,
  label,
  id,
  data,
  value,
  className,
  disabled,
  required,
  message,
  errorStatus,
  validation,
  onChangeFunction,
}: SelectBoxFieldInterFace) {
  const [selectValue, setSelectValue] = useState<string>('');
  const [selectItems, setSelectItems] = useState<Array<SelectItemInterFace>>(data);
  const [isOpen, setIsOpen] = useState<boolean>(false);

  const onKeyup = (e: React.ChangeEvent<HTMLInputElement>): void => {
    const inputValue = e.target.value;
    setSelectValue(inputValue);
    setIsOpen(true);
    setSelectItems(
      Array.isArray(data)
        ? data.filter((val) => val.label.toLowerCase().includes(inputValue.toLowerCase()))
        : [],
    );
  };

  const selectItem = (item: SelectItemInterFace): void => {
    if (item.id !== value) {
      onChangeFunction(name, item);
    }
    setSelectValue(item.label);
    setSelectItems(data);
    setIsOpen(false);
  };

  const onBlurInput = (): void => {
    // setTimeout(() => {
    if (Array.isArray(data)) {
      const selectData = data.find((val) => val.id === value);
      setSelectValue(selectData?.label || '');
      setSelectItems(data);
      setIsOpen(false);
    }
    // }, 200);
  };

  const closeData = (): void => {
    onChangeFunction(name, { id: '', label: '' });
    setSelectValue('');
    setSelectItems(data);
  };

  useEffect((): void => {
    if (Array.isArray(data)) {
      const selectData = data.find((val) => val.id === value);
      setSelectValue(selectData?.label || '');
    }
  }, [value, data]);

  useEffect(() => {
    if (Array.isArray(data)) {
      setSelectItems(data);
      const selectData = data.find((val) => val.id === value);
      setSelectValue(selectData?.label || '');
    }
  }, [data]);

  return (
    <div className="form-select-item">
      <input
        type="text"
        id={id}
        className={`input_field ${className}`}
        value={selectValue}
        onBlur={onBlurInput}
        onFocus={() => setIsOpen(true)}
        autoComplete="off"
        required={required}
        name={name}
        onChange={onKeyup}
        disabled={disabled}
        multiple
      />
      <label className={`input-label ${className}`} htmlFor={id} title={label}>
        {label}
        {required && <span>*</span>}
      </label>
      <div className="input-right-close-icon" onClick={closeData} role="presentation">
        <CloseIcon />
      </div>
      <div className="input-right-icon">
        <DownArrowIcon />
      </div>
      <div className={`select-items ${isOpen ? 'show' : 'hide'}`}>
        {Array.isArray(selectItems) && selectItems.length > 0 ? (
          selectItems.map((item) => (
            <div
              key={item.id}
              className={`select-item ${item.id === value ? 'selected' : ''}`}
              onClick={() => selectItem(item)}
              role="presentation">
              {item.label}
            </div>
          ))
        ) : (
          <div className="select-item disabled">No Data Found</div>
        )}
      </div>
      {errorStatus && <small className="input-error-message">{message}</small>}
      {validation && <small className="input-validation-message">{message}</small>}
    </div>
  );
}

export default SelectBoxField;
