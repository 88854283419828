import React from 'react';

function PageLoader() {
  return (
    <div className="page-loader">
      <div className="wrapper">
        <div className="circle" />
        <div className="circle" />
        <div className="circle" />
        <div className="shadow" />
        <div className="shadow" />
        <div className="shadow" />
      </div>
    </div>
  );
}

export default PageLoader;
