import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
} from 'recharts';
import {
  JVMUsageIcon,
  MemoryUsageIcon,
  ProcessorUsageIcon,
} from '../../../../../assets/svgicons/svgicon';
import { CurrentInfoTabType } from '../../../../../interface/System/currentInformation.interface';
import { getCurrentMinute } from '../../../../../utils/helpers';

function TrendInformation({ currentInfo }: CurrentInfoTabType) {
  const { t } = useTranslation();
  const [trendData, setTrendData] = useState(currentInfo);
  const [chartTrendData, setChartTrendData] = useState<any[]>([]);
  const [chartThreadData, setChartThreadData] = useState<any[]>([]);

  useEffect(() => {
    setTrendData(currentInfo);
    const addTrendData = {
      time: getCurrentMinute(),
      processor: parseFloat(currentInfo?.cpu?.usageRatio),
      pcMemory: parseFloat(currentInfo?.pc?.usageRatio),
      jvmMemory: parseFloat(currentInfo?.jvm?.usageRatio),
    };
    setChartTrendData((prev: any) => [...prev, addTrendData]);
    const addThreadData = {
      time: getCurrentMinute(),
      thread: parseFloat(currentInfo?.thread?.threadCount),
    };
    setChartThreadData((prev: any) => [...prev, addThreadData]);
  }, [currentInfo]);

  return (
    <div className="trend-information">
      <div className="chart-box">
        <h6 className="title">{t('threadNumberTrend')}</h6>
        <div className="usage-box-wrapper">
          <div className="usage-box">
            <ProcessorUsageIcon />
            <span>{t('processorUsage')}</span>
            <span className="percentage">{trendData?.cpu?.usageRatio}</span>
          </div>
          <div className="usage-box">
            <MemoryUsageIcon />
            <span>{t('pcMemoryUsage')}</span>
            <span className="percentage">{trendData?.pc?.usageRatio}</span>
          </div>
          <div className="usage-box">
            <JVMUsageIcon />
            <span>{t('jvmMemoryUsage')}</span>
            <span className="percentage">{trendData?.jvm?.usageRatio}</span>
          </div>
        </div>
        <div className="line-chart-container">
          <ResponsiveContainer width="100%" height={300}>
            <LineChart data={chartTrendData} margin={{ top: 20, right: 30, left: 20, bottom: 10 }}>
              <CartesianGrid strokeDasharray="1 1" />
              <XAxis dataKey="time" allowDecimals />
              <YAxis tickFormatter={(value) => `${value}%`} />
              <Tooltip />
              <Line
                type="monotone"
                dataKey="processor"
                stroke="#B84592"
                strokeWidth={1}
                dot={{ r: 4 }}
              />
              <Line
                type="monotone"
                dataKey="pcMemory"
                stroke="#AFB845"
                strokeWidth={1}
                dot={{ r: 4 }}
              />
              <Line
                type="monotone"
                dataKey="jvmMemory"
                stroke="#45B8A3"
                strokeWidth={1}
                dot={{ r: 4 }}
              />
            </LineChart>
          </ResponsiveContainer>
        </div>
      </div>
      <div className="chart-box">
        <h6 className="title">{t('systemPerformanceTrend')}</h6>
        <div className="usage-box-wrapper">
          <div className="usage-box">
            <ProcessorUsageIcon />
            <span>{t('noOfThreads')}</span>
            <span className="percentage">{trendData?.thread?.threadCount}</span>
          </div>
        </div>
        <div className="line-chart-container">
          <ResponsiveContainer width="100%" height={300}>
            <LineChart data={chartThreadData} margin={{ top: 20, right: 30, left: 20, bottom: 10 }}>
              <CartesianGrid strokeDasharray="1 1" />
              <XAxis dataKey="time" allowDecimals />
              <YAxis tickFormatter={(value) => `${value}%`} />
              <Tooltip />
              <Line
                type="monotone"
                dataKey="thread"
                stroke="#B84592"
                strokeWidth={1}
                dot={{ r: 4 }}
              />
            </LineChart>
          </ResponsiveContainer>
        </div>
      </div>
    </div>
  );
}

export default TrendInformation;
