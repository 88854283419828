/* eslint-disable no-param-reassign */
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { setNotificationError } from '../NotificationSlices';
import authorityManagementUser from '../../../utils/api/SystemManagement/authorityManagementUser';

export const fetchAuthorityManagementUser = createAsyncThunk(
  'acc/fetchAuthorityManagementUser ',
  async (data: any, { dispatch }) => {
    const response = await authorityManagementUser
      .getListPage(data)
      .then((response1) => {
        return {
          ...response1.data,
          data: {
            list: response1?.data?.data?.data.map((item: any) => ({
              ...item,
              ...(item?.initFlag ? { delete: false } : {}),
            })),
          },
          size: response1.data.data.total,
          ...data,
        };
      })
      .catch((error: any) => {
        dispatch(setNotificationError(error.response.data));
        return error;
      });
    return response;
  },
);
interface ReaderReduxInterface {
  AuthorityManagementUser: any;
  status: 'loading' | 'success' | 'failed';
  totalDataCount: number;
  currentPageNo: number;
  searchFilter: { [key: string]: any };
}
const initialState: ReaderReduxInterface = {
  AuthorityManagementUser: [],
  status: 'success',
  totalDataCount: 0,
  currentPageNo: 0,
  searchFilter: {},
};

export const AuthorityManagementUserSlice: any = createSlice({
  name: 'user',
  initialState,
  reducers: {
    deleteUser: (state, action: any) => {
      state.AuthorityManagementUser = state.AuthorityManagementUser.filter(
        (val: any) => val.id !== action.payload,
      );
      state.totalDataCount -= 1;
    },
    setSearchFilter: (state, action: any) => {
      state.searchFilter = action.payload;
    },
  },
  extraReducers: (builder) => {
    // Add reducers for additional action types here, and handle loading state as needed
    builder
      .addCase(fetchAuthorityManagementUser.pending, (state = initialState) => {
        state.status = 'loading';
      })
      .addCase(fetchAuthorityManagementUser.fulfilled, (state: any, action: any) => {
        // Add user to the state array
        if (action.payload.success) {
          state.AuthorityManagementUser = action.payload.data.list;
          state.totalDataCount = action.payload.size;
        }
        state.status = 'success';
      })
      .addCase(fetchAuthorityManagementUser.rejected, (state: any) => {
        state.status = 'failed';
      });
  },
});

// Action creators are generated for each case reducer function
export const { setSearchFilter, deleteUser } = AuthorityManagementUserSlice.actions;

export default AuthorityManagementUserSlice.reducer;
