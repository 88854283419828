import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import TabPages from '../../../../../shared-components/tab-pages/tab-pages';
import Button from '../../../../../shared-components/button/button';
import InputField from '../../../../../shared-components/input-field/input-field';
import role from '../../../../../utils/api/SystemManagement/role';
import { useAppDispatch, useAppSelector } from '../../../../../redux/store';
import { setNotificationError } from '../../../../../redux/slices/NotificationSlices';
import {
  fetchRoleList,
  removeAssignId,
  removeRoles,
} from '../../../../../redux/slices/system-management/RoleSlices';
import Label from '../../../../../shared-components/label/Label';
import PersonnelTab from './personnel-tab';
import AccessTab from './access-tab';
import SystemTab from './system-tab';
import { AuthorityAddRoleProps, SysArrProps } from '../../../../../interface/role.interface';

/**
 * AuthorityAddRole component for adding or editing roles
 * @param {Object} props - Component props
 * @param {Function} props.closeModal - Function to close the modal
 * @param {Object} [props.data] - Optional data for editing an existing role
 */

const initialState = {
  code: '',
  name: '',
  assignId: [],
};

function AuthorityAddRole({ closeModal, data }: AuthorityAddRoleProps) {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { assignId } = useAppSelector((state: any) => state?.role);

  const [values, setValues] = useState<any>({
    code: data?.code || '',
    name: data?.name || '',
    assignId: data?.assignId || [],
  });
  const [tabs, setTabs] = useState([
    {
      key: 'Personnel',
      title: t('personnel'),
      content: <>personnel</>,
    },
  ]);
  const [disable, setDisable] = useState(false);

  /**
   * Handles changes in form inputs
   * @param {string} name - Name of the input field
   * @param {any} value - New value of the input field
   */

  const handleChange = (name: string, value: any) => {
    setValues((prev: any) => ({
      ...prev,
      [name]: value,
    }));
  };

  /**
   * Handles form submission
   * @param {string} type - Type of submission (e.g., 'saveAndNew', 'done')
   */
  const handleSubmit = (type: 'saveAndNew' | 'done') => {
    setDisable(true);
    const req = {
      name: values?.name,
      code: values?.code,
      assignId: assignId?.toString(),
      id: data?.id,
    };
    role
      .addRole(req)
      .then((response) => {
        if (response?.data?.success) {
          const message = data ? t('roleUpdatedSuccessfully') : t('roleAddedSuccessfully');
          dispatch(
            setNotificationError({
              error: message,
              status: 200,
            }),
          );
          setDisable(false);
          if (type === 'saveAndNew') {
            setValues(initialState);
            dispatch(removeAssignId());
          } else {
            dispatch(fetchRoleList({ pageNo: 1, pageSize: 10 }));
            closeModal();
          }
        } else {
          setDisable(false);
          dispatch(
            setNotificationError({
              error: response.data.msg,
              status: response.data.code,
            }),
          );
        }
      })
      .catch((error) => {
        setDisable(false);
        dispatch(
          setNotificationError({
            error: error.message,
            status: error.status,
          }),
        );
      });
  };

  /**
   * Renders the GenericTab for all tab types.
   *
   * @param key - The system array item containing tab details.
   * @returns JSX.Element - The rendered tab component.
   */
  const renderTabComponent = (key: SysArrProps): JSX.Element => {
    let result: JSX.Element;
    if (key?.name === 'Personnel') {
      result = <PersonnelTab tabKey={key?.id} roleId={data?.id} handleChange={handleChange} />;
    } else if (key?.name === 'Access') {
      result = <AccessTab tabKey={key?.id} roleId={data?.id} handleChange={handleChange} />;
    } else {
      result = <SystemTab tabKey={key?.id} roleId={data?.id} handleChange={handleChange} />;
    }
    return result;
  };

  const getSystemList = async () => {
    try {
      const response = await role.getAuthSystemList();

      if (response.data.success) {
        const sysArr = JSON.parse(response.data.data)?.sysArr;

        const tabArray = sysArr?.map((item: SysArrProps) => ({
          key: item.id,
          title: item.name,
          enable: true,
          content: renderTabComponent(item),
        }));
        setTabs(tabArray);
      } else {
        dispatch(
          setNotificationError({
            error: response.data.msg,
            status: response.data.code || 500,
          }),
        );
      }
    } catch (error: any) {
      dispatch(
        setNotificationError({
          error: error.message,
          status: error.response?.status || 500,
        }),
      );
    }
  };

  useEffect(() => {
    getSystemList();

    return () => {
      dispatch(removeRoles());
    };
  }, []);

  useEffect(() => {
    if (values?.name && values?.code && assignId.length > 0) {
      setDisable(false);
    } else {
      setDisable(true);
    }
  }, [values, assignId]);

  return (
    <div className="role-container">
      <div className="role-content">
        <div className="input-section">
          <InputField
            id="code"
            name="code"
            label={t('roleCode')}
            type="text"
            value={values.code}
            onChangeFunction={handleChange}
            required
          />
          <InputField
            id="name"
            name="name"
            label={t('roleName')}
            type="text"
            value={values.name}
            onChangeFunction={handleChange}
            required
          />
        </div>

        <div className="permissions-section">
          <Label title={t('assignPermissions')} required />
          <TabPages tabs={tabs} defaultNumber={0} />
        </div>

        <div className="form-buttons-right">
          {!data && (
            <Button
              onClickFunction={() => handleSubmit('saveAndNew')}
              title={t('save&new')}
              className="btn-primary"
              disabled={disable}
            />
          )}
          <Button
            onClickFunction={() => closeModal()}
            title={t('cancel')}
            className="btn-outline-primary"
          />
          <Button
            onClickFunction={() => handleSubmit('done')}
            title={t('done')}
            className="btn-primary"
            disabled={disable}
          />
        </div>
      </div>
    </div>
  );
}

AuthorityAddRole.defaultProps = {
  data: '',
};

export default AuthorityAddRole;
