import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from '../../../../redux/store';

// Shared components
import TopHeader from '../../../../shared-components/top-header/top-header';
import CardBox from '../../../../shared-components/card/card';
import DataTable from '../../../../shared-components/data-table/data-table';
import CardRightButtonGroup from '../../../../shared-components/card-right-button-group/card-right-button-group';
import ModalBox from '../../../../shared-components/modal-box/modal-box';
import DeleteModalBox from '../../../../shared-components/delete-modal-box/delete-modal-box';
import { setNotificationError } from '../../../../redux/slices/NotificationSlices';
import { AddIcon, DeleteButtonIcon } from '../../../../assets/svgicons/svgicon';
import ClientReset from './components/ClientReset';
// Redux slice actions
import {
  fetchClientRegister,
  deleteClientRegister as deleteClient,
  setSearchFilter,
} from '../../../../redux/slices/system-management/clientRegisterSlices';
import clientRegister from '../../../../utils/api/SystemManagement/clientRegister';

// Components
import { FilterProps } from '../../../../interface/common.interface';
import AddClientRegister from './components/AddClientRegister';
import { checkPermission } from '../../../../utils/role-permission';

function ClientRegister() {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { clientRegisterList, status, totalDataCount, searchFilter } = useAppSelector(
    (state: any) => state.clientRegister,
  );
  const { loadMenus } = useAppSelector((state) => state.auth);

  const [modalOpen, setModalOpen] = useState(false);
  const [modalView, setModalView] = useState({
    title: '',
    content: <>hi</>,
  });
  const [selectedValues, setSelectedValues] = useState<any>([]);
  const [selectedClientFullData, setSelectedClientFullData] = useState<any[]>([]);

  // let handleEditOpen: any;

  const columns = [
    // {
    //   accessorKey: 'registrationCode',
    //   header: t('registrationCode'),
    //   Cell: ({ row }: any) => (
    //     <span
    //       className="list-hyperlink"
    //       role="presentation"
    //       onClick={(event) => {
    //         event.stopPropagation();
    //         handleEditOpen(row.original);
    //       }}>
    //       {row?.original?.registrationCode}
    //     </span>
    //   ),
    //   size: 150,
    // },
    { accessorKey: 'registrationCode', header: t('registrationCode') },
    { accessorKey: 'clientName', header: t('clientName') },
    { accessorKey: 'registrationKey', header: t('registrationKey') },
    { accessorKey: 'activation', header: t('activation') },
    { accessorKey: 'activatedDate', header: t('activatedDate') },
    { accessorKey: 'creationDate', header: t('creationDate') },
    { accessorKey: 'clientType', header: t('clientType') },
  ];

  const fetchData = async (params: FilterProps) => {
    if (status === 'success') {
      dispatch(fetchClientRegister({ ...params }));
    }
  };

  const getFilterData = (filter: any): any => {
    if (filter.pageIndex > 0) {
      dispatch(setSearchFilter(filter));
      fetchData({ pageNo: filter.pageIndex, pageSize: filter.pageSize, ...filter.filter });
    }
  };
  const deleteRecord = (id: any) => {
    if (selectedValues.length === 0) {
      dispatch(
        setNotificationError({
          error: t('pleaseSelectAtLeastOneEmailManagement'),
          status: 400,
        }),
      );
      return;
    }

    clientRegister
      .delete(id)
      .then((response) => {
        if (response.data.success) {
          dispatch(
            setNotificationError({
              error: t('clientRegisterDeletedSuccessfully'),
              status: 200,
            }),
          );
          dispatch(deleteClient(id));
          setModalOpen(false);
          fetchData({ pageNo: 1 });
        } else {
          dispatch(
            setNotificationError({
              error: t('deleteFailed'),
              status: 400,
            }),
          );
        }
      })
      .catch((error) => {
        dispatch(
          setNotificationError({
            error: error.message,
            status: error.response?.status || 500,
          }),
        );
      });
  };
  const actionButtons = [
    // {
    //   label: t('edit'),
    //   function: (val: any) => handleEditOpen(val),
    // },
    {
      label: t('delete'),
      enable: checkPermission('base:register:del', loadMenus),
      disabled: true,
      function: (val: any) => {
        setModalOpen(true);
        setModalView({
          title: t('deleteResource'),
          content: (
            <DeleteModalBox
              closeModal={() => setModalOpen(false)}
              id={val.id}
              okFunction={deleteRecord}
            />
          ),
        });
      },
    },
  ];

  // handleEditOpen = (editData: any) => {
  //   if (!modalOpen) {
  //     setModalOpen(true);
  //     setModalView({
  //       title: t('edit'),
  //       content: <AddClientRegister closeModal={() => setModalOpen(false)} data={editData} />,
  //     });
  //   }
  // };
  const handleDeleteClick = () => {
    if (selectedClientFullData.length === 0) {
      dispatch(
        setNotificationError({
          error: t('pleaseSelectAtLeastOneClient'), // Also fixed the error message
          status: 400,
        }),
      );
      return;
    }

    setModalOpen(true);
    setModalView({
      title: t('delete'),
      content: (
        <DeleteModalBox
          closeModal={() => setModalOpen(false)}
          id={selectedClientFullData.map((val) => val.id).join(',')}
          okFunction={deleteRecord}
          isMultiSelect
        />
      ),
    });
  };
  const moreActionButton = [
    {
      title: t('delete'),
      enable: checkPermission('base:register:del', loadMenus),
      clickFunction: (): void => {
        if (selectedValues.length === 0) {
          dispatch(
            setNotificationError({
              error: t('pleaseSelectAtLeastOneLinkage'),
              status: 400,
            }),
          );
          return;
        }
        setModalView({
          title: t('delete'),
          content: (
            <DeleteModalBox
              closeModal={() => setModalOpen(false)}
              id={selectedValues}
              okFunction={deleteRecord}
            />
          ),
        });
        setModalOpen(true);
      },
    },
    {
      title: t('reset'),
      enable: checkPermission('base:register:reset', loadMenus),
      clickFunction: (): void => {
        if (selectedValues.length === 0) {
          dispatch(
            setNotificationError({
              error: t('pleaseSelectAtLeastOneLinkage'),
              status: 400,
            }),
          );
          return;
        }
        setModalView({
          title: t('reset'),
          content: <ClientReset closeModal={() => setModalOpen(false)} />,
        });
        setModalOpen(true);
      },
    },
  ];

  return (
    <div className="card-management">
      <ModalBox
        status={modalOpen}
        closeModal={() => setModalOpen(false)}
        title={modalView.title}
        content={modalView.content}
      />
      <TopHeader
        title={t('system')}
        broadCram={[{ title: t('authorityManagement') }, { title: t('clientRegister') }]}
      />
      <CardBox
        header=""
        title={t('listOfClientRegistered')}
        rightSide={
          <div className="delete-icon">
            <CardRightButtonGroup
              actions={moreActionButton}
              buttonIcon={<AddIcon color="#ffffff" />}
              buttonLabel={t('new')}
              buttonClass="btn-primary btn-sm"
              buttonStatus
              buttonFunction={(): void => {
                setModalOpen(true);
                setModalView({
                  title: t('new'),
                  content: <AddClientRegister closeModal={() => setModalOpen(false)} />,
                });
              }}
              buttonEnable={checkPermission('base:register:add', loadMenus)}
              // Add delete button configuration
              DeleteButtonIcon={<DeleteButtonIcon />}
              deleteButtonLabel={t('delete')}
              showDeleteButton
              deleteButtonFunction={handleDeleteClick}
              deleteButtonEnable={checkPermission('base:register:del', loadMenus)}
              deleteButtonClass="multi-delete-button"
            />
          </div>
        }>
        <DataTable
          actionButtonStatus={!modalOpen}
          action={actionButtons}
          columns={columns}
          getFilterData={getFilterData}
          assignFilterValue={searchFilter}
          assignSelectedData={selectedValues}
          getSelectedData={(val: any): any => setSelectedValues(val)}
          getSelectedFullData={(val: any): any => {
            setSelectedClientFullData(val);
          }}
          checkBoxActive
          enablePagination
          isFetching={status === 'loading'}
          values={clientRegisterList}
          fixedColumn={['registrationCode']}
          totalRecords={totalDataCount}
          height={411}
        />
      </CardBox>
    </div>
  );
}

export default ClientRegister;
