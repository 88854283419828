import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  ParameterInterface,
  ParameterReduxInterface,
} from '../../../../../interface/parameter.interface';
import { setNotificationError } from '../../../../../redux/slices/NotificationSlices';
import { useAppSelector, useAppDispatch } from '../../../../../redux/store';
import SelectBoxField from '../../../../../shared-components/selectbox-field/selectbox-field';
import { AttentionIcon } from '../../../../../assets/svgicons/svgicon';
import Button from '../../../../../shared-components/button/button';
import dataCleaning from '../../../../../utils/api/SystemManagement/dataCleaning';
import ModalBox from '../../../../../shared-components/modal-box/modal-box';

function System({ onChangeFunction }: ParameterInterface) {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { parameters }: ParameterReduxInterface = useAppSelector((state: any) => state.parameters);

  // Parse JSON strings safely
  const systemDataCleanOpLog = parameters?.systemDataCleanOpLog
    ? JSON.parse(parameters.systemDataCleanOpLog)
    : { runtime: '03:00:00', keptMonth: '6' };

  const systemDataCleanDbBak = parameters?.systemDataCleanDbBak
    ? JSON.parse(parameters.systemDataCleanDbBak)
    : { runtime: '04:00:00', keptMonth: '6' };

  const systemDataCleanCmdData = parameters?.systemDataCleanCmdData
    ? JSON.parse(parameters.systemDataCleanCmdData)
    : { runtime: '02:00:00', keptMonth: '6' };

  const [values, setvalues] = useState({
    ...parameters,

    system: {
      number: systemDataCleanOpLog.keptMonth,
      startTime: systemDataCleanOpLog.runtime,
    },
    Database: {
      number: systemDataCleanDbBak.keptMonth,
      startTime: systemDataCleanDbBak.runtime,
    },
    device: {
      number: systemDataCleanCmdData.keptMonth,
      startTime: systemDataCleanCmdData.runtime,
    },
  });

  const [modalOpen, setModalOpen] = useState(false);
  const [modalView, setModalView] = useState({
    title: '',
    content: <>hi</>,
  });
  const [isLoading, setIsLoading] = useState(false);

  const executionTimeOptions = Array.from({ length: 24 }, (_, index) => {
    const time = `${index.toString().padStart(2, '0')}:00:00`;
    return { label: time, id: time };
  });

  const sections = [
    {
      title: 'System Operation Log',
      key: 'system',
      retentionName: 'systemDataCleanOpLogKeptMonth',
      timeName: 'systemDataCleanOpLogTime',
      hasWarning: true,
    },
    {
      title: 'Database Backup File',
      key: 'Database',
      retentionName: 'systemDataCleanDbBakKeptMonth',
      timeName: 'systemDataCleanDbBakTime',
      hasCleanup: true,
    },
    {
      title: 'Device Commands',
      key: 'device',
      retentionName: 'systemDataCleanCmdDataKeptMonth',
      timeName: 'systemDataCleanCmdDataTime',
      hasCleanup: true,
    },
  ];

  const retentionOptions = Array.from({ length: 36 }, (_, index) => ({
    label: t((index + 1).toString()),
    id: (index + 1).toString(),
  }));

  const onChange = (section: string, field: string, changeValue: string | number) => {
    setvalues((prev: any) => ({
      ...prev,
      [section]: {
        ...prev[section],
        [field]: changeValue,
      },
    }));
  };

  // Add useEffect to handle updates from parameters
  useEffect(() => {
    if (parameters) {
      const updatedValues: any = {};

      if (parameters.systemDataCleanOpLog) {
        const opLogData = JSON.parse(parameters.systemDataCleanOpLog);
        updatedValues.system = {
          number: opLogData.keptMonth,
          startTime: opLogData.runtime,
        };
      }

      if (parameters.systemDataCleanDbBak) {
        const dbBakData = JSON.parse(parameters.systemDataCleanDbBak);
        updatedValues.Database = {
          number: dbBakData.keptMonth,
          startTime: dbBakData.runtime,
        };
      }

      if (parameters.systemDataCleanCmdData) {
        const cmdData = JSON.parse(parameters.systemDataCleanCmdData);
        updatedValues.device = {
          number: cmdData.keptMonth,
          startTime: cmdData.runtime,
        };
      }

      // Only update if there are changes
      if (Object.keys(updatedValues).length > 0) {
        setvalues((prev: any) => ({
          ...prev,
          ...updatedValues,
        }));
      }
    }
  }, [parameters]);

  const handleConfirmCleanup = async (section: string) => {
    try {
      setIsLoading(true);
      let name = '';
      if (section === 'device') {
        name = 'AdmsDevCmd';
      } else if (section === 'Database') {
        name = 'dbbackup';
      }

      if (name) {
        const requestData: any = {
          keptMonth: '6',
          name,
        };

        // Only add columnName for AdmsDevCmd, not for dbBackup
        if (section === 'device') {
          requestData.columnName = 'commitTime';
        }
        const response = await dataCleaning.immediateClean(requestData);

        if (response.data.success) {
          dispatch(
            setNotificationError({
              error: t('successfullyCleanUpDevice'),
              status: 200,
            }),
          );
          setModalOpen(false);
        } else {
          dispatch(
            setNotificationError({
              error: response.data.message,
              status: response.data.code,
            }),
          );
        }
      }
      setIsLoading(false);
    } catch (error: any) {
      dispatch(
        setNotificationError({
          error: error.message,
          status: error.response?.status || 500,
        }),
      );
      setIsLoading(false);
    }
  };

  const handleImmediateCleanup = (section: string) => {
    const messageKey =
      section === 'device'
        ? 'areYouSureYouWantToCleanUpDeviceCommandsBefore6Months?'
        : 'Database Backup Cleanup Confirmation';

    setModalView({
      title: t('Confirm Cleanup'),
      content: (
        <>
          <p>{t(messageKey)}</p>
          <div className="modal-buttons">
            <div className="form-buttons-right">
              <Button
                onClickFunction={() => setModalOpen(false)}
                title={t('cancel')}
                className="btn-default"
              />
              <Button
                onClickFunction={() => handleConfirmCleanup(section)}
                title={t('done')}
                className="btn-primary"
                disabled={isLoading}
              />
            </div>
          </div>
        </>
      ),
    });
    setModalOpen(true);
  };

  useEffect(() => {
    const transformedValues = sections.reduce((acc, section) => {
      return {
        ...acc,
        [section.retentionName]: values?.[section.key]?.number || '6',
        [section.timeName]: values?.[section.key]?.startTime || '00:00:00',
      };
    }, {});

    onChangeFunction(transformedValues);
  }, [values, onChangeFunction]);

  // Render method remains the same as in the previous version
  return (
    <div className="data-setting">
      <ModalBox
        status={modalOpen}
        closeModal={() => setModalOpen(false)}
        title={modalView.title}
        content={modalView.content}
      />

      <div className="data-setting-form">
        <div className="column">
          {sections.slice(0, 2).map((section) => (
            <div key={section.key} className="item item-1">
              <div className="record-title">{t(section.title)}</div>
              <div className="sub-title">{t('Retains the Recent')}</div>
              <div className="main-container">
                <SelectBoxField
                  id="number"
                  name="number"
                  value={values?.[section.key]?.number}
                  onChangeFunction={(name, selectBoxValue) =>
                    onChange(section.key, name, selectBoxValue.id)
                  }
                  data={retentionOptions}
                  validation
                  message={t('monthsOfData')}
                />
              </div>
              <div className="form-start">
                <div className="sub-title">{t('Execution Time')}</div>
                <SelectBoxField
                  id="startTime"
                  name="startTime"
                  value={values?.[section.key]?.startTime}
                  onChangeFunction={(name, selectBoxValue) =>
                    onChange(section.key, name, selectBoxValue.id)
                  }
                  data={executionTimeOptions}
                />
                {section.hasCleanup && (
                  <div className="cleanup-button">
                    <Button
                      title={t('Immediately Clean Up')}
                      className="btn-primary"
                      onClickFunction={() => handleImmediateCleanup(section.key)}
                      disabled={isLoading}
                    />
                  </div>
                )}
                {section.hasWarning && (
                  <div className="item text-danger">
                    <div className="note">{t('Carefully Cleanup')}</div>
                  </div>
                )}
              </div>
            </div>
          ))}
        </div>

        <div className="column">
          {sections.slice(2).map((section) => (
            <div key={section.key} className="item item-1">
              <div className="record-title">{t(section.title)}</div>
              <div className="sub-title">{t('Retains the Recent')}</div>
              <div className="main-container">
                <SelectBoxField
                  id="number"
                  name="number"
                  value={values?.[section.key]?.number}
                  onChangeFunction={(name, selectBoxValue) =>
                    onChange(section.key, name, selectBoxValue.id)
                  }
                  data={retentionOptions}
                />
              </div>
              <div className="form-start">
                <div className="sub-title">{t('Execution Time')}</div>
                <SelectBoxField
                  id="startTime"
                  name="startTime"
                  value={values?.[section.key]?.startTime}
                  onChangeFunction={(name, selectBoxValue) =>
                    onChange(section.key, name, selectBoxValue.id)
                  }
                  data={executionTimeOptions}
                />
                {section.hasCleanup && (
                  <div className="cleanup-button">
                    <Button
                      title={t('Immediately Clean Up')}
                      className="btn-primary"
                      onClickFunction={() => handleImmediateCleanup(section.key)}
                      disabled={isLoading}
                    />
                  </div>
                )}
              </div>
            </div>
          ))}

          <div className="text-danger flex-row center">
            <AttentionIcon />
            {t(
              'Cleaning frequency is executed once every day and clean up data before the set number of reserved months. Execution Time refers to the time when the system starts to perform a data clean-up. When you click OK, the system will automatically clean expired system data according to the users settings.',
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default System;
