import React, { useEffect, useState } from 'react';

// Use common modules
import { useTranslation } from 'react-i18next';

// Use shared components
import RadioButtonField from '../../../shared-components/radio-button-field/radio-button-field';
import { PermissionInterface } from '../../../interface/permissions.interface';
import CheckBoxInputField from '../../../shared-components/checkbox-input-field/checkbox-input-field';
import AddLevelModal from './add-level-modal';
import ModalBox from '../../../shared-components/modal-box/modal-box';
import person from '../../../utils/api/person';
import { useAppDispatch, useAppSelector } from '../../../redux/store';
import { setNotificationError } from '../../../redux/slices/NotificationSlices';
import accessByDepartment from '../../../utils/api/AccessRule/accessByDepartment';
import { saveLevelData, saveLevelId } from '../../../redux/slices/PersonSlices';
import InputDateTime from '../../../shared-components/input-time-date/input-time-date';
import { utcDateTimeFormat } from '../../../utils/helpers';
import { AttentionIcon } from '../../../assets/svgicons/svgicon';

function CardSetting({ values, onChangeFunction }: PermissionInterface) {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { level, levelId } = useAppSelector((state) => state.persons);
  const [value, setValue] = useState<any>({
    ...values,
    general: values.general ?? false,
    levelSettings: levelId || [],
  });
  const [modalOpen, setModalOpen] = useState(false);
  const [modalView, setModalView] = useState({
    title: t('Add to Levels'),
    content: <>hi</>,
  });
  const [accLevels, setAccLevels] = useState<any>(level || []);
  // const [selectedLevels, setSelectedLevels] = useState<Level[]>([]);

  const onChange = (name: string, changeValue: any) => {
    if (name === 'superUser' && changeValue === 15) {
      return setValue((prev: any) => ({
        ...prev,
        [name]: changeValue,
        startTime: '',
        endTime: '',
        extendPassage: [],
        setValidTime: [],
      }));
    }

    return setValue((prev: any) => ({
      ...prev,
      [name]: changeValue,
    }));
  };

  const handleAddLevel = (newLevels: any[]) => {
    const result: any = newLevels.map((item) => ({
      id: item.id,
      label: item.levelName,
      value: item.id,
    }));
    setAccLevels((prev: any) => [...prev, ...result]);
    const defaultLevel = result.map((item: any) => item.value);
    const combineId: any = [...defaultLevel, ...value.levelSettings];
    setValue((val: any) => ({
      ...val,
      levelSettings: combineId,
    }));
    dispatch(saveLevelData(result));
    dispatch(saveLevelId(combineId));
  };

  const checkboxTreeData = [
    { id: '1', label: t('Level 3'), value: 'my test', children: [] },
    { id: '2', label: t('My Testing'), value: '12 hours', children: [] },
  ];

  const getAccLevels = async () => {
    try {
      const response = await person.editPerson({ personId: values?.person_id || values?.id });

      const result = response?.data?.data?.tempList?.map((item: any) => ({
        label: item.name,
        id: item.id,
        value: item.id,
      }));
      setAccLevels(result);
      const defaultLevel = result.map((item: any) => item.value);

      setValue((val: any) => ({
        ...val,
        levelSettings: defaultLevel,
        superUser: response?.data?.data?.item?.superAuth,
        deviceOperationRule: response?.data?.data?.item?.privilege,
        extendPassage: response?.data?.data?.item?.delayPassage ? ['1'] : [],
        setValidTime: response?.data?.data?.item?.isSetValidTime ? ['1'] : [],
        accessDisabled: response?.data?.data?.item?.disabled ? ['1'] : [],
        startTime: utcDateTimeFormat(response?.data?.data?.item?.startTime),
        endTime: utcDateTimeFormat(response?.data?.data?.item?.endTime),
      }));
    } catch (error: any) {
      dispatch(
        setNotificationError({
          error: error.message,
          status: error.status,
        }),
      );
    }
  };

  const getLevelListByDept = async () => {
    try {
      const response = await accessByDepartment.getLevelListByDept(values?.deptId);
      if (Object.keys(response.data.data).length > 0) {
        const result = Object.entries(response?.data?.data || {}).map(([id, name]) => ({
          label: name,
          id,
          value: id,
        }));
        setAccLevels(result);
        const defaultLevel = result.map((item: any) => item.value);
        setValue((val: any) => ({
          ...val,
          levelSettings: defaultLevel,
        }));
      }
    } catch (error: any) {
      dispatch(
        setNotificationError({
          error: error.message,
          status: error.status,
        }),
      );
    }
  };

  useEffect(() => {
    if (values?.id) {
      getAccLevels();
    } else {
      getLevelListByDept();
    }
  }, []);

  const levelIds = accLevels.map((item: any) => item.id);

  useEffect(() => onChangeFunction(value), [value]);

  return (
    <div className="permissions">
      <ModalBox
        status={modalOpen}
        closeModal={() => setModalOpen(false)}
        title={modalView.title}
        content={modalView.content}
      />
      <div className="permissions-form">
        <div className="row-form">
          <div className="item item1">
            <label htmlFor="level-settings" className="radio-label">
              {t('Level Settings')}
            </label>
            <div className="level-settings-container">
              <div className="level-settings-header">
                {accLevels.length > 0 ? (
                  <CheckBoxInputField
                    name="levelSettings"
                    alignment="column"
                    position="left"
                    value={value.levelSettings}
                    data={accLevels}
                    onChangeFunction={(name: string, changeValue: any) =>
                      onChange(name, changeValue)
                    }
                  />
                ) : (
                  <span>Please add Level</span>
                )}
                <div className="add-btn-wrapper">
                  <button
                    className="add-button"
                    type="button"
                    onClick={() => {
                      setModalOpen(true);
                      setModalView({
                        title: t('Add to Levels'),
                        content: (
                          <AddLevelModal
                            onClose={() => setModalOpen(false)}
                            onAddLevel={handleAddLevel}
                            checkboxTreeData={checkboxTreeData} // Pass the data here
                            levelIds={levelIds}
                          />
                        ),
                      });
                    }}>
                    {t('Add +')}
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className="item">
            <RadioButtonField
              label={t('superUser')}
              name="superUser"
              alignment="row"
              data={[
                { label: t('yes'), id: 15, value: 15 },
                { label: t('no'), id: 0, value: 0 },
              ]}
              value={value.superUser}
              onChangeFunction={onChange}
            />
          </div>
          <div className="item" />
          <div className="item" />
          <div className="item">
            <RadioButtonField
              label={t('deviceOperationRule')}
              name="deviceOperationRule"
              alignment="warp"
              data={[
                {
                  label: t('ordinaryUser'),
                  id: 0,
                  value: 0,
                },
                {
                  label: t('enroller'),
                  id: 14,
                  value: 14,
                },
                {
                  label: t('administrator'),
                  id: 2,
                  value: 2,
                },
              ]}
              value={value.deviceOperationRule}
              onChangeFunction={onChange}
            />
          </div>
          <div className="item item3">
            <CheckBoxInputField
              label=""
              name="extendPassage"
              alignment="column"
              position="right"
              required
              data={[
                {
                  label: t('extendPassage'),
                  id: '1',
                  value: '1',
                  disabled: value?.superUser === 15 ? 'disabled' : '',
                },
              ]}
              value={value.extendPassage}
              onChangeFunction={onChange}
            />
            <CheckBoxInputField
              label=""
              name="accessDisabled"
              alignment="column"
              position="right"
              required
              data={[
                {
                  label: t('accessDisabled'),
                  id: '1',
                  value: '1',
                },
              ]}
              value={value.accessDisabled}
              onChangeFunction={onChange}
            />
            <CheckBoxInputField
              label=""
              name="setValidTime"
              alignment="column"
              position="right"
              required
              data={[
                {
                  label: t('setValidTime'),
                  id: '1',
                  value: '1',
                  disabled: value?.superUser === 15 ? 'disabled' : '',
                },
              ]}
              value={value.setValidTime}
              onChangeFunction={onChange}
            />
            {value?.setValidTime?.length > 0 && (
              <div className="flex-row column">
                <div className="flex-row">
                  <InputDateTime
                    label={t('startTime')}
                    value={value.startTime}
                    onChangeFunction={(val) => onChange('startTime', val)}
                    monthFormat={false}
                    ampm={false}
                  />
                  <InputDateTime
                    label={t('endTime')}
                    value={value.endTime}
                    onChangeFunction={(val) => onChange('endTime', val)}
                    monthFormat={false}
                    ampm={false}
                  />
                </div>
                <div className="text-danger flex-row center">
                  <AttentionIcon />
                  {t('theValidTimeOfSomeDevicesOnlySupportsSettingTheYearMonthAndDay')}
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default CardSetting;
