import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Cookies from 'universal-cookie';
import Button from '../../../shared-components/button/button';
import { useAppDispatch, useAppSelector } from '../../../redux/store';
import accessLevel from '../../../utils/api/AccessRule/accessLevel';
import { setNotificationError } from '../../../redux/slices/NotificationSlices';
import { fetchAccessLevels } from '../../../redux/slices/AccessLevelSlices';
import InputField from '../../../shared-components/input-field/input-field';
import { checkIsNeedValidUser } from '../../../redux/slices/AuthSlices';

type ModalType = {
  closeModal: () => void;
  selectedId: string[];
};
interface RequestInterface {
  ids: string[] | string;
  userName?: string;
  loginPassword?: string;
}
function CancelAlarmModal({ closeModal, selectedId }: ModalType) {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const cookies = new Cookies();
  const { isNeedValidUser } = useAppSelector((state) => state?.auth);
  const [password, setPassword] = useState('');

  const submit = async () => {
    try {
      const request: RequestInterface = {
        ids: selectedId.toString(),
      };
      if (!isNeedValidUser) {
        request.userName = cookies.get('username') && cookies.get('username');
        request.loginPassword = password;
      }
      const response = await accessLevel.cancelAlarmDoor(request);
      if (response?.data?.success) {
        dispatch(
          setNotificationError({
            error: response.data.msg,
            status: 200,
          }),
        );
        closeModal();
        dispatch(fetchAccessLevels({ pageNo: 1, pageSize: 10 }));
      } else {
        dispatch(
          setNotificationError({
            error: response.data.msg,
            status: 500,
          }),
        );
      }
    } catch (error: any) {
      dispatch(
        setNotificationError({
          error: error.message,
          status: error.status,
        }),
      );
    }
  };

  useEffect(() => {
    dispatch(checkIsNeedValidUser({ mode: 'pwd' }));
  }, []);

  return (
    <div className="remote-opening-modal">
      {!isNeedValidUser ? (
        <div className="input-section">
          <InputField
            id="password"
            name="password"
            label="User Password"
            value={password}
            onChangeFunction={(name: string, val: any) => setPassword(val)}
            type="password"
            required
          />
        </div>
      ) : (
        <>
          <div className="target-section">
            <label htmlFor="target">{t('target')} :</label>
            <span>{t('allDoors')}</span>
          </div>
          <div className="confirmation-text">{t('areYouWantToPerformTheCancelAlarmOperation')}</div>
        </>
      )}

      <div className="modal-footer">
        <Button onClickFunction={closeModal} title={t('cancel')} className="btn-default" />
        <Button
          onClickFunction={submit}
          title={t('ok')}
          className="btn-primary"
          disabled={!isNeedValidUser && !password}
        />
      </div>
    </div>
  );
}

export default CancelAlarmModal;
