import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useAppDispatch } from '../../redux/store';
import TopHeader from '../../shared-components/top-header/top-header';
import CardBox from '../../shared-components/card/card';
import InputField from '../../shared-components/input-field/input-field';
import SelectBoxField from '../../shared-components/selectbox-field/selectbox-field';
import TabPages from '../../shared-components/tab-pages/tab-pages';
import { Search } from '../../assets/svgicons/svgicon';
import auth from '../../utils/api/auth';
import { setNotificationError } from '../../redux/slices/NotificationSlices';
import Table from '../../shared-components/table/table';
import Door from './components/door-tab';
import AuxiliaryInput from './components/auxiliary-input-tab';
import AuxiliaryOutput from './components/auxiliary-output-tab';
import { eventCodes, verificationModes } from './options';
import useWebSocket from '../../utils/webSocket/webSocket';
import MessageTip from './components/message-tip';

function RealTimeMonitoring() {
  const { t } = useTranslation();
  const [areaList, setAreaList] = useState([]);
  const dispatch = useAppDispatch();

  const [filterValue, setFilterValue] = useState({
    deviceName: '',
    area: '',
    status: '',
  });

  const [eventData, setEventData] = useState<any>({});
  const [eventTableData, setEventTableData] = useState<any>([]);
  const [userData, setUserData] = useState<any>({});

  const statusData = [
    {
      title: t('normal'),
      count: eventTableData?.filter((item: any) => item.colorCode === 'color:green').length,
      color: 'success',
    },
    {
      title: t('alarm'),
      count: eventTableData?.filter((item: any) => item.colorCode === 'color:red').length,
      color: 'danger',
    },
    {
      title: t('exception'),
      count: eventTableData?.filter((item: any) => item.colorCode === 'color:#E57A14').length,
      color: 'warning',
    },
    {
      title: t('totalReceived'),
      count: eventTableData?.length,
    },
  ];

  const handleDevState = (message: any) => {
    setEventData(message);
  };

  const handleEventData = (message: any) => {
    const rowData = message?.rows?.[0]?.data;
    const user = message?.rows?.[0]?.userdata;
    const bgColor = message.rows?.[0]?.style;
    const resultBg = { [bgColor.split(':')[0]]: bgColor.split(':')[1] };
    const result = {
      time: rowData?.[0],
      area: rowData?.[1],
      device: rowData?.[2],
      eventPoint: rowData?.[3],
      eventDescription: eventCodes[rowData?.[4]] || rowData?.[4],
      cardNumber: rowData?.[5],
      person: rowData?.[6],
      readerName: rowData?.[7],
      verificationMode: verificationModes[rowData?.[8]],
      colorCode: message.rows?.[0]?.style,
      textColor: resultBg,
    };
    setEventTableData((prevData: any) => {
      const sortData: any = [...prevData, result];
      sortData.sort((a: any, b: any) => new Date(b.time).getTime() - new Date(a.time).getTime());
      return sortData;
    });
    setUserData({ ...result, ...user });
  };

  const { initializeWebSocket: stateWebSocket } = useWebSocket({
    type: 'accRTMonitor/getDevState',
    params: {},
    onMessage: handleDevState,
    handleGetEventData: handleEventData,
  });

  const onChangeValue = (name: string, value: string | number) => {
    setFilterValue((prev: any) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleClearData = () => {
    setEventTableData([]);
  };

  const getAuthAreaList = () => {
    auth
      .getAuthArea({ pageNo: 1 })
      .then((res) => {
        const authArea = res.data.data.data.map((area: any) => ({
          label: area.name,
          id: area.id,
        }));
        const areaIds = authArea?.map((area: any) => area.id).join(',');
        stateWebSocket({ areaIds });
        setAreaList(authArea);
        setFilterValue((prev: any) => ({
          ...prev,
          area: authArea?.[0]?.id,
        }));
      })
      .catch((error) => {
        dispatch(
          setNotificationError({
            error: error.message,
            status: error.status,
          }),
        );
      });
  };

  useEffect(() => {
    getAuthAreaList();
  }, []);

  return (
    <div className="real-time-monitoring">
      <TopHeader
        title={t('access')}
        broadCram={[
          {
            title: t('accessDevice'),
          },
          {
            title: t('realTimeMonitoring'),
          },
        ]}
      />
      <div className="content">
        <CardBox>
          <div className="filters">
            <InputField
              name="deviceName"
              label={t('deviceName')}
              id="name"
              type="text"
              value={filterValue.deviceName}
              innerIconStatus
              InnerIcon={Search}
              onChangeFunction={onChangeValue}
            />
            <SelectBoxField
              name="area"
              label={t('Area')}
              id="area"
              value={filterValue.area}
              onChangeFunction={(name, value) => onChangeValue(name, value.id)}
              data={areaList}
              className=""
            />
            <SelectBoxField
              name="status"
              label={t('status')}
              id="status"
              value={filterValue.status}
              onChangeFunction={(name, value) => onChangeValue(name, value.id)}
              data={[
                { label: t('online'), id: 1 },
                { label: t('disable'), id: 2 },
                { label: t('offline'), id: 0 },
                { label: t('unknown'), id: 3 },
              ]}
              className=""
            />
          </div>
        </CardBox>
        <CardBox>
          <TabPages
            tabs={[
              {
                key: 'door',
                title: t('door'),
                content: <Door />,
                enable: true,
              },
              {
                key: 'auxiliaryInput',
                title: t('auxiliaryInput'),
                content: <AuxiliaryInput data={eventData?.auxInStates} />,
                enable: true,
              },
              {
                key: 'auxiliaryOutput',
                title: t('auxiliaryOutput'),
                content: <AuxiliaryOutput data={eventData?.auxOutStates} />,
                enable: true,
              },
            ]}
            defaultNumber={0}
          />
        </CardBox>
        <CardBox title={t('Real - Time Events')}>
          <div className="real-time-events">
            <div className="event-table">
              <Table
                header={[
                  { label: t('time'), id: 'time' },
                  { label: t('area'), id: 'area' },
                  { label: t('device'), id: 'device' },
                  { label: t('eventPoint'), id: 'eventPoint' },
                  { label: t('eventDescription'), id: 'eventDescription' },
                  { label: t('cardNumber'), id: 'cardNumber' },
                  { label: t('person'), id: 'person' },
                  { label: t('readerName'), id: 'readerName' },
                  { label: t('verificationMode'), id: 'verificationMode' },
                ]}
                hideHeader={false}
                value={eventTableData}
                textWrap
              />
            </div>
            <div className="status-container">
              <div className="clear-all" role="presentation" onClick={handleClearData}>
                <span className="text">{t('clearDataRows')}</span>
              </div>
              {statusData.map((status: any) => (
                <div key={status.title} className="status-wrap">
                  {status?.color && <div className={`circle-dot ${status?.color}`} />}
                  <span style={{ color: '#696C6F' }}>
                    {status?.title} : {status?.count}
                  </span>
                </div>
              ))}
            </div>
          </div>
        </CardBox>
        {userData && <MessageTip userData={userData} />}
      </div>
    </div>
  );
}

export default RealTimeMonitoring;
