import React, { useState } from 'react';

// Use Translation
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from '../../redux/store';

// Use own components
import AddDepartment from './components/add-department';

// Use redux functions
import {
  deleteDepartment,
  fetchDepartmentList,
  setSearchFilter,
} from '../../redux/slices/DepartmentSlices';
import { setNotificationError } from '../../redux/slices/NotificationSlices';

// Use api's calls
import departmentApis from '../../utils/api/department';

// Use Interface
import { DepartmentReduxInterface, DeleteDepartProps } from '../../interface/department.interface';

// Use shared components
import TopHeader from '../../shared-components/top-header/top-header';
import CardBox from '../../shared-components/card/card';
import DataTable from '../../shared-components/data-table/data-table';
import CardRightButtonGroup from '../../shared-components/card-right-button-group/card-right-button-group';
import ModalBox from '../../shared-components/modal-box/modal-box';
import DepartmentExportData from './components/export-data';
import DeleteModalBox from '../../shared-components/delete-modal-box/delete-modal-box';
import TabModalBox from '../../shared-components/tab-modal-box/tab-modal-box';
import DepartmentImportData from './components/import-data';
import DownloadDepartmentImportTemplate from './components/download-department-import-template';
import { rtMonitorOptions } from '../realTime-monitoring/options';

// Use assets svg icons
import { AddIcon, DeleteButtonIcon } from '../../assets/svgicons/svgicon';
import { checkPermission } from '../../utils/role-permission';

function Department() {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { departments, status, totalDataCount, searchFilter }: DepartmentReduxInterface =
    useAppSelector((state: any) => state.departments);
  const { loadMenus } = useAppSelector((state) => state.auth);
  let handleEditOpen: any;
  const columnsField = [
    {
      accessorKey: 'code',
      header: t('departmentNumber'),
      Cell: ({ row }: any) =>
        checkPermission('pers:position:edit', loadMenus) ? (
          <div
            className="list-hyperlink"
            role="presentation"
            onClick={(event) => {
              event.stopPropagation(); // Prevent row selection
              handleEditOpen(row.original); // Call your edit function
            }}>
            {row?.original?.code}
          </div>
        ) : (
          <div>{row?.original?.code}</div>
        ),
    },
    {
      accessorKey: 'name',
      header: t('departmentName'),
    },
    {
      accessorKey: 'parentCode',
      header: t('parentDepartmentNumber'),
    },
    {
      accessorKey: 'parentName',
      header: t('parentDepartmentName'),
    },
    {
      accessorKey: 'creationDate',
      header: t('creationDate'),
    },
  ];

  const [modalOpen, setModalOpen] = useState(false);
  const [tabModalOpen, setTabModalOpen] = useState(false);
  const [selectedDeleteFullData, setSelectedDeleteFullData] = useState<any[]>([]);
  const [selectedDepartment, setSelectedDepartment] = useState<any[]>([]);

  const [modalView, setModalView] = useState({
    title: t('addNewDepartment'),
    content: <>hi</>,
  });
  const [tabModalView, setTabModalView] = useState([
    {
      title: t('import'),
      content: <>import</>,
      key: '',
      enable: false,
    },
  ]);

  const fetchDepartmentData = async (params: any) => {
    if (status === 'success') {
      dispatch(fetchDepartmentList({ ...params }));
    }
  };

  const deleteRecord = (params: DeleteDepartProps) => {
    const req = {
      ids: params,
    };
    departmentApis
      .deleteDepartment(req)
      .then((response) => {
        if (response.data.success) {
          dispatch(
            setNotificationError({
              error: t('departmentDeletedSuccessfully'),
              status: 200,
            }),
          );
          dispatch(deleteDepartment(params));
          setModalOpen(false);
          dispatch(fetchDepartmentList({ pageNo: 1, pageSize: 10 }));
        } else {
          dispatch(
            setNotificationError({
              error: response.data.msg,
              status: response.data.success,
            }),
          );
        }
      })
      .catch((error) => {
        dispatch(
          setNotificationError({
            error: rtMonitorOptions[error.response?.data?.msg] || error.message,
            status: error.response?.status || error.status,
          }),
        );
      });
  };

  handleEditOpen = (editData: any) => {
    setModalOpen(true);
    setModalView({
      title: t('editDevice'),
      content: (
        <AddDepartment
          closeModal={() => setModalOpen(false)}
          data={{ ...editData, update: true }}
        />
      ),
    });
  };
  const actionButtons = [
    {
      label: t('edit'),
      enable: checkPermission('auth:department:edit', loadMenus),
      function: (val: any) => handleEditOpen(val),
    },
    {
      label: t('delete'),
      enable: checkPermission('auth:department:del', loadMenus),
      function: (val: any) => {
        setModalOpen(true);
        setModalView({
          title: t('deleteDepartment'),
          content: (
            <DeleteModalBox
              closeModal={() => setModalOpen(false)}
              id={val.departmentId}
              okFunction={deleteRecord}
            />
          ),
        });
      },
    },
  ];

  const exportData = () => {
    setTabModalOpen(false);
  };

  const importData = () => {
    setTabModalOpen(false);
  };
  const handleDeleteClick = () => {
    if (selectedDeleteFullData.length === 0) {
      dispatch(
        setNotificationError({
          error: t('pleaseSelectAtLeastOneDepartment'),
          status: 400,
        }),
      );
      return;
    }

    setModalView({
      title: t('delete'),
      content: (
        <DeleteModalBox
          closeModal={() => setModalOpen(false)}
          id={selectedDeleteFullData.map((val) => val?.departmentId).join(',')}
          okFunction={deleteRecord}
          isMultiSelect
        />
      ),
    });
    setModalOpen(true);
  };
  const modalContents = [
    {
      title: t('importDepartment'),
      enable: checkPermission('auth:department:import', loadMenus),
      content: (
        <DepartmentImportData
          closeModal={() => setTabModalOpen(false)}
          submitFunction={importData}
        />
      ),
      key: 'importDeportment',
      index: 0,
    },
    {
      title: t('downloadDepartmentImportTemplate'),
      enable: checkPermission('auth:department:import', loadMenus),
      content: (
        <DownloadDepartmentImportTemplate
          closeModal={() => setTabModalOpen(false)}
          submitFunction={importData}
        />
      ),
      key: 'downloadTemplate',
      index: 1,
    },
  ];
  // Check if any child component has permission
  const isImportParentEnabled = modalContents.some((item) => item?.enable);

  const moreActionButton = [
    {
      title: t('export'),
      enable: checkPermission('auth:department:export', loadMenus),
      clickFunction: (): void => {
        setModalOpen(true);
        setModalView({
          title: t('exportDepartment'),
          content: (
            <DepartmentExportData
              closeModal={() => setModalOpen(false)}
              submitFunction={exportData}
            />
          ),
        });
      },
    },
    {
      title: t('import'),
      enable: isImportParentEnabled,
      clickFunction: (): void => {
        setTabModalOpen(true);
        setTabModalView(modalContents);
      },
    },
  ];

  const getFilterData = (filter: any): any => {
    if (filter.pageIndex > 0) {
      dispatch(setSearchFilter(filter));
      fetchDepartmentData({
        pageNo: filter.pageIndex,
        pageSize: filter.pageSize,
        ...filter.filter,
      });
    }
  };

  return (
    <div className="department">
      <ModalBox
        status={modalOpen}
        closeModal={() => setModalOpen(false)}
        title={modalView.title}
        content={modalView.content}
      />
      <TabModalBox
        status={tabModalOpen}
        closeModal={() => setTabModalOpen(false)}
        title={t('import')}
        tabs={tabModalView}
      />
      <TopHeader
        title={t('department')}
        broadCram={[
          {
            title: t('personnel'),
          },
          {
            title: t('department'),
          },
        ]}
      />
      <CardBox
        title={t('listOfDepartment')}
        rightSide={
          <div className="delete-icon">
            <CardRightButtonGroup
              actions={moreActionButton}
              buttonIcon={<AddIcon color="#ffffff" />}
              buttonLabel={t('add')}
              buttonClass="btn-primary btn-sm"
              buttonStatus
              buttonFunction={(): void => {
                setModalOpen(true);
                setModalView({
                  title: t('addNewDepartment'),
                  content: <AddDepartment closeModal={() => setModalOpen(false)} />,
                });
              }}
              buttonEnable={checkPermission('auth:department:add', loadMenus)}
              DeleteButtonIcon={<DeleteButtonIcon />}
              deleteButtonLabel={t('delete')}
              showDeleteButton
              deleteButtonFunction={handleDeleteClick}
              deleteButtonEnable={checkPermission('pers:person:del', loadMenus)}
              deleteButtonClass="multi-delete-button"
            />
          </div>
        }>
        <DataTable
          actionButtonStatus={!modalOpen}
          action={actionButtons}
          columns={columnsField}
          getFilterData={getFilterData}
          enablePagination
          assignFilterValue={searchFilter}
          assignSelectedData={selectedDepartment}
          getSelectedData={(val: any): any => {
            setSelectedDepartment(val);
          }}
          getSelectedFullData={(val: any): any => {
            setSelectedDeleteFullData(val);
          }}
          checkBoxActive
          values={departments}
          fixedColumn={['code']}
          isFetching={status === 'loading'}
          totalRecords={totalDataCount}
          height={411}
        />
      </CardBox>
    </div>
  );
}

export default Department;
