import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from '../../../../redux/store';

// Use shared components
import TopHeader from '../../../../shared-components/top-header/top-header';
import CardBox from '../../../../shared-components/card/card';
import DataTable from '../../../../shared-components/data-table/data-table';
import CardRightButtonGroup from '../../../../shared-components/card-right-button-group/card-right-button-group';
// Import slice actions
import {
  fetchDictionaryManagement,
  setSearchFilter,
} from '../../../../redux/slices/system-management/DictionaryManagementSlices';
import { FilterProps } from '../../../../interface/common.interface';
import { formatCommonText } from '../../../../utils/helpers';

function DictionaryManagement() {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { dictionaryList, status, totalDataCount, searchFilter, currentPageNo } = useAppSelector(
    (state: any) => state.dictionaryManagement,
  );
  const columns = [
    {
      accessorKey: 'moduleName',
      header: t('module'),
    },
    {
      accessorKey: 'dictCode',
      header: t('dictionaryClassification'),
    },
    {
      accessorKey: 'dictName',
      header: t('keyName'),
    },
    {
      accessorKey: 'dictValue',
      header: t('value'),
      Cell: ({ row }: any) =>
        formatCommonText(row?.original?.dictValue)
          ? formatCommonText(row?.original?.dictValue)
          : row?.original?.dictValue,
    },
  ];

  const fetchData = async (params: FilterProps) => {
    if (status === 'success') {
      dispatch(fetchDictionaryManagement({ ...params }));
    }
  };

  const getFilterData = (filter: any): any => {
    if (currentPageNo > 0) {
      dispatch(setSearchFilter(filter));
      fetchData({ pageNo: 1, ...filter.filter });
    }
  };

  const fetchNextData = (): any => {
    fetchData({ pageNo: currentPageNo + 1, ...searchFilter.filter });
  };

  useEffect(() => {
    if (dictionaryList.length === 0) {
      fetchData({ pageNo: 1 });
    }
  }, []);

  return (
    <div className="card-management">
      <TopHeader
        title={t('system')}
        broadCram={[
          {
            title: t('systemManagement'),
          },
          {
            title: t('dictionaryManagement'),
          },
        ]}
      />
      <CardBox
        header=""
        title={t('listOfModule')}
        rightSide={
          <CardRightButtonGroup
            buttonIcon={false}
            buttonLabel=""
            buttonClass=""
            buttonStatus={false}
          />
        }>
        <DataTable
          actionButtonStatus={false}
          columns={columns}
          getFilterData={getFilterData}
          assignFilterValue={searchFilter}
          checkBoxActive={false}
          fetchNextPage={fetchNextData}
          isFetching={status === 'loading'}
          values={dictionaryList}
          fixedColumn={['eventTime']}
          totalRecords={totalDataCount}
          height={411}
        />
      </CardBox>
    </div>
  );
}

export default DictionaryManagement;
