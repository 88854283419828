import { fetch, post } from '../base/index';

export default {
  getDeviceCommand: (data: any = {}) => {
    return post(`${process.env.REACT_APP_API_URL}Sys/api/admsDevCmd/listByPage`, {}, data);
  },
  clearCommand: () => {
    return post(`${process.env.REACT_APP_API_URL}Sys/api/admsDevCmd/clean`, {});
  },
  exportData: (data: any) => {
    return fetch(`${process.env.REACT_APP_API_URL}Sys/api/admsDevCmd/export`, 'POST', data);
  },
};
