import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
// Import shared components
import { useAppDispatch, useAppSelector } from '../../../../redux/store';
import TopHeader from '../../../../shared-components/top-header/top-header';
import CardBox from '../../../../shared-components/card/card';
import DataTable from '../../../../shared-components/data-table/data-table';
import ModalBox from '../../../../shared-components/modal-box/modal-box';
import CardRightButtonGroup from '../../../../shared-components/card-right-button-group/card-right-button-group';
import { CheckCircleIcon, CloseCircleIcon } from '../../../../assets/svgicons/svgicon';
import {
  fetchDevCommandList,
  setSearchFilter,
} from '../../../../redux/slices/system-management/DeviceCommandSlices';
import DevCommandExportData from './components/export-data';
import ConformationBox from '../../../../shared-components/conformation-box/conformation-box';
import deviceCommand from '../../../../utils/api/SystemManagement/deviceCommand';
import { setNotificationError } from '../../../../redux/slices/NotificationSlices';
import { checkPermission } from '../../../../utils/role-permission';

function DeviceCommand() {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { sn } = useParams();
  const { devCmdList, status, totalDataCount, searchFilter } = useAppSelector(
    (state: any) => state.deviceCommand,
  );
  const { loadMenus } = useAppSelector((state) => state.auth);
  const [modalOpen, setModalOpen] = useState(false);
  const [modalView, setModalView] = useState({
    title: '',
    content: <>hi</>,
  });

  const columnsField = [
    {
      accessorKey: 'cmdId',
      header: t('id'),
      size: 150,
    },
    {
      accessorKey: 'sn',
      header: t('serialNumber'),
    },
    {
      accessorKey: 'isImme',
      header: t('immediatelyCmd'),
      Cell: ({ row }: any) => {
        let result: JSX.Element;
        if (row.original?.isImme) {
          result = <CheckCircleIcon color="#48d726de" />;
        } else {
          result = <CloseCircleIcon color="#f00" />;
        }
        return result;
      },
    },
    {
      accessorKey: 'commitTime',
      header: t('submitTime'),
    },
    {
      accessorKey: 'returnTime',
      header: t('returnTime'),
    },
    {
      accessorKey: 'returnValue',
      header: t('returnValue'),
    },
    {
      accessorKey: 'remarks',
      header: t('remarks'),
    },
  ];

  const fetchData = async (params: any) => {
    if (status === 'success') {
      dispatch(fetchDevCommandList({ ...params }));
    }
  };

  const getFilterData = (filter: any): any => {
    if (filter.pageIndex > 0) {
      dispatch(setSearchFilter(filter));
      fetchData({
        pageNo: filter.pageIndex,
        pageSize: filter.pageSize,
        sn,
        ...filter.filter,
      });
    }
  };

  const cleanCmd = () => {
    deviceCommand
      .clearCommand()
      .then((response) => {
        if (response.data.success) {
          dispatch(
            setNotificationError({
              error: response.data.msg,
              status: 200,
            }),
          );
          setModalOpen(false);
          fetchData({
            pageNo: searchFilter.pageIndex,
            pageSize: searchFilter.pageSize,
            sn,
          });
        } else {
          dispatch(
            setNotificationError({
              error: response.data.msg,
              status: response.data.code,
            }),
          );
        }
      })
      .catch((error) => {
        dispatch(
          setNotificationError({
            error: error.msg,
            status: error.status,
          }),
        );
      });
  };

  const actions = [
    {
      title: t('clearCommands'),
      enable: checkPermission('adms:devCmd:clearCmd', loadMenus),
      clickFunction: (): void => {
        setModalOpen(true);
        setModalView({
          title: t('clearCommands'),
          content: (
            <ConformationBox
              closeModal={() => setModalOpen(false)}
              okFunction={cleanCmd}
              title={t('areYouWantToPerformTheClearCommandsOperation')}
              buttonLabel={t('ok')}
              cancelTrue
            />
          ),
        });
      },
    },
    {
      title: t('export'),
      enable: checkPermission('adms:devCmd:export', loadMenus),
      clickFunction: (): void => {
        setModalOpen(true);
        setModalView({
          title: t('export'),
          content: (
            <DevCommandExportData
              closeModal={() => setModalOpen(false)}
              submitFunction={() => setModalOpen(false)}
            />
          ),
        });
      },
    },
  ];

  return (
    <div className="department">
      <ModalBox
        status={modalOpen}
        closeModal={() => setModalOpen(false)}
        title={modalView.title}
        content={modalView.content}
      />
      <TopHeader
        title={t('system')}
        broadCram={[
          {
            title: t('communicationManagement'),
          },
          {
            title: t('deviceCommand'),
          },
        ]}
      />
      <CardBox
        title={t('listOfDeviceCommand')}
        rightSide={<CardRightButtonGroup actions={actions} buttonStatus={false} />}>
        <DataTable
          actionButtonStatus={false}
          columns={columnsField}
          getFilterData={getFilterData}
          assignFilterValue={searchFilter}
          checkBoxActive={false}
          enablePagination
          isFetching={status === 'loading'}
          values={devCmdList}
          fixedColumn={['id']}
          totalRecords={totalDataCount}
          height={411}
        />
      </CardBox>
    </div>
  );
}

export default DeviceCommand;
