import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from '../../../../redux/store';

// Use shared components
import TopHeader from '../../../../shared-components/top-header/top-header';
import CardBox from '../../../../shared-components/card/card';
import DataTable from '../../../../shared-components/data-table/data-table';
import CardRightButtonGroup from '../../../../shared-components/card-right-button-group/card-right-button-group';
import ModalBox from '../../../../shared-components/modal-box/modal-box';
// Import slice actions
import {
  fetchDbMgt,
  setSearchFilter,
} from '../../../../redux/slices/system-management/DataBaseMgtSlices';
// import ExportData from './components/export-data';
import { FilterProps } from '../../../../interface/common.interface';
import BackupImmediate from './components/BackupImmediate';
import BackupSchedule from './components/BackupSchedule';
import FTPServerSettings from './components/FTPServerSettings';
import { CheckCircleIcon, CloseCircleIcon } from '../../../../assets/svgicons/svgicon';
import { setNotificationError } from '../../../../redux/slices/NotificationSlices';
import ConformationBox from '../../../../shared-components/conformation-box/conformation-box';
import databaseManagement from '../../../../utils/api/SystemManagement/databaseManagement';
import { checkPermission } from '../../../../utils/role-permission';
import { fileDownload, getCurrentDateTimeWithTimezone } from '../../../../utils/helpers';

function DatabaseManagement() {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { dbList, status, totalDataCount, searchFilter } = useAppSelector(
    (state: any) => state.databaseManagement,
  );
  const { loadMenus } = useAppSelector((state) => state.auth);

  const [modalOpen, setModalOpen] = useState(false);
  const [modalView, setModalView] = useState({
    title: 'hi',
    content: <>hi</>,
  });
  const [selectedValues, setSelectedValues] = useState([]);

  const columns = [
    {
      accessorKey: 'username',
      header: t('operator'),
    },
    {
      accessorKey: 'startTime',
      header: t('startTime'),
    },
    {
      accessorKey: 'dbVersion',
      header: t('databaseVersion'),
    },
    {
      accessorKey: 'isImme',
      header: t('backupImmediately'),
      Cell: ({ row }: any) => {
        const value = row.original?.isImme ? (
          <CheckCircleIcon color="#48d726de" />
        ) : (
          <CloseCircleIcon color="#f00" />
        );
        return value;
      },
    },
    {
      accessorKey: 'backupResult',
      header: t('backupStatus'),
      Cell: ({ row }: any) => {
        let result;
        if (row.original.backupResult === 1) {
          result = <span style={{ color: '#00A76F' }}>Enable</span>;
        } else {
          result = <span className="text-danger">Disable</span>;
        }
        return result;
      },
    },
    {
      accessorKey: 'backupPath',
      header: t('backupPath'),
    },
    {
      accessorKey: 'fileType',
      header: t('fileType'),
    },
  ];

  const fetchData = async (params: FilterProps) => {
    if (status === 'success') {
      dispatch(fetchDbMgt({ ...params }));
    }
  };

  const getFilterData = (filter: any): any => {
    if (filter.pageIndex > 0) {
      dispatch(setSearchFilter(filter));
      fetchData({ pageNo: filter.pageIndex, pageSize: filter.pageSize, ...filter.filter });
    }
  };

  const downloadBackup = async (id: string) => {
    // Download backup
    try {
      // Create a new FormData object
      const formData = new FormData();

      // Append the attrs array to the FormData
      formData.append('id', id);
      formData.append('tableNameParam', 'db');
      const response: any = await databaseManagement.opDownloadFile(formData);

      if (response) {
        const blobData = await response.blob(); // Convert the response to a blob
        const currentDateTime = getCurrentDateTimeWithTimezone(); // Use helper function
        fileDownload(blobData, `db_${currentDateTime}.psql`);

        setModalOpen(false);
      } else {
        setModalOpen(false);
        dispatch(
          setNotificationError({
            error: response.data.msg,
            status: response.data.code || 500,
          }),
        );
      }
    } catch (error: any) {
      setModalOpen(false);
      dispatch(
        setNotificationError({
          error: error.response.data.msg,
          status: error.status || 500,
        }),
      );
    }
  };

  const deleteDatabase = async (ids: string) => {
    // Delete backup
    try {
      const response = await databaseManagement.delete({ ids });

      if (response?.data.success) {
        setModalOpen(false);
        dispatch(
          setNotificationError({
            error: response.data.msg,
            status: 200,
          }),
        );
        fetchData({ pageNo: searchFilter.pageIndex, pageSize: searchFilter.pageSize });
      } else {
        dispatch(
          setNotificationError({
            error: response.data.msg,
            status: response.data.code || 500,
          }),
        );
      }
    } catch (error: any) {
      dispatch(
        setNotificationError({
          error: error.message,
          status: error.status,
        }),
      );
    }
  };

  // resource file backup
  const handleResourceFile = async () => {
    try {
      const response = await databaseManagement.resourceFileBackup();

      if (response?.data.success) {
        setModalOpen(false);
        dispatch(
          setNotificationError({
            error: response.data.msg,
            status: 200,
          }),
        );
        fetchData({ pageNo: searchFilter.pageIndex, pageSize: searchFilter.pageSize });
      } else {
        dispatch(
          setNotificationError({
            error: response.data.msg,
            status: response.data.code || 500,
          }),
        );
      }
    } catch (error: any) {
      dispatch(
        setNotificationError({
          error: error.message,
          status: error.status,
        }),
      );
    }
  };

  const actions = [
    {
      label: t('download'),
      enable: true,
      function: (val: any): void => {
        setModalView({
          title: t('download'),
          content: (
            <ConformationBox
              closeModal={() => setModalOpen(false)}
              okFunction={() => downloadBackup(val?.id)}
              title={t('areYouWantToDownloadTheSelectedDatabases')}
              buttonLabel={t('ok')}
              cancelTrue
            />
          ),
        });
        setModalOpen(true);
      },
    },
    {
      label: t('delete'),
      enable: checkPermission('system:baseDbBackup:del', loadMenus),
      function: (val: any): void => {
        setModalView({
          title: t('delete'),
          content: (
            <ConformationBox
              closeModal={() => setModalOpen(false)}
              okFunction={() => deleteDatabase(val.id)}
              title={t('areYouSureYouWantToPerformTheDeleteOperation?')}
              buttonLabel={t('ok')}
              cancelTrue
            />
          ),
        });
        setModalOpen(true);
      },
    },
  ];

  const moreActionButton = [
    {
      title: t('backupImmediately'),
      enable: checkPermission('system:baseDbBackup:immeBackup', loadMenus),
      clickFunction: (): void => {
        setModalView({
          title: t('backupImmediately'),
          content: <BackupImmediate closeModal={() => setModalOpen(false)} />,
        });
        setModalOpen(true);
      },
    },
    {
      title: t('backupSchedule'),
      enable: checkPermission('system:baseDbBackup:planBackup', loadMenus),
      clickFunction: (): void => {
        setModalView({
          title: t('backupSchedule'),
          content: <BackupSchedule closeModal={() => setModalOpen(false)} />,
        });
        setModalOpen(true);
      },
    },
    {
      title: t('FtpServerSettings'),
      enable: checkPermission('system:baseDbBackup:getFtpSetting', loadMenus),
      clickFunction: (): void => {
        setModalView({
          title: t('FtpServerSettings'),
          content: <FTPServerSettings closeModal={() => setModalOpen(false)} />,
        });
        setModalOpen(true);
      },
    },
    {
      title: t('resourceFileBackup'),
      enable: checkPermission('system:baseDbBackup:getFtpSetting', loadMenus),
      clickFunction: (): void => {
        setModalView({
          title: t('FtpServerSettings'),
          content: (
            <ConformationBox
              closeModal={() => setModalOpen(false)}
              okFunction={() => handleResourceFile()}
              title={t('areYouWantToResourceFileBackupOperation')}
              buttonLabel={t('ok')}
              cancelTrue
            />
          ),
        });
        setModalOpen(true);
      },
    },
  ];

  return (
    <div className="card-management">
      <ModalBox
        status={modalOpen}
        closeModal={() => setModalOpen(false)}
        title={modalView.title}
        content={modalView.content}
      />
      <TopHeader
        title={t('system')}
        broadCram={[
          {
            title: t('systemManagement'),
          },
          {
            title: t('databaseManagement'),
          },
        ]}
      />
      <CardBox
        header=""
        title={t('listOfOperator')}
        rightSide={
          <CardRightButtonGroup
            actions={moreActionButton}
            buttonIcon={false}
            buttonLabel=""
            buttonClass=""
            buttonStatus={false}
          />
        }>
        <DataTable
          actionButtonStatus={!modalOpen}
          action={actions}
          columns={columns}
          getFilterData={getFilterData}
          assignFilterValue={searchFilter}
          assignSelectedData={selectedValues}
          getSelectedData={(val: any): any => setSelectedValues(val)}
          checkBoxActive
          isFetching={status === 'loading'}
          values={dbList}
          fixedColumn={['eventTime']}
          totalRecords={totalDataCount}
          height={411}
          enablePagination
        />
      </CardBox>
    </div>
  );
}

export default DatabaseManagement;
