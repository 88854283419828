import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
// Import shared components
import { useAppDispatch, useAppSelector } from '../../../redux/store';
import TopHeader from '../../../shared-components/top-header/top-header';
import CardBox from '../../../shared-components/card/card';
import DataTable from '../../../shared-components/data-table/data-table';
import DeleteModalBox from '../../../shared-components/delete-modal-box/delete-modal-box';
import ModalBox from '../../../shared-components/modal-box/modal-box';
import CardRightButtonGroup from '../../../shared-components/card-right-button-group/card-right-button-group';
import { DepartmentParamsProps } from '../../../interface/accessByDepartment.interface';
import { setNotificationError } from '../../../redux/slices/NotificationSlices';
import { AddIcon, DeleteButtonIcon } from '../../../assets/svgicons/svgicon';
import AddAntiPassback, { abpType } from './components/addGlobalAntiPassback';
import AddPersonnel from './components/AddPersonnel';
import {
  deletePerson,
  fetchGlobalAntiPassBackList,
  fetchPersonList,
  setPersonSearchFilter,
  setSearchFilter,
  deleteAntiPassBack as deleteApb,
} from '../../../redux/slices/GlobalAntipassbackSlices';
import globalAntiPassBack from '../../../utils/api/AdvancedFunctions/globalAntiPassBack';
import { checkPermission } from '../../../utils/role-permission';

interface LevelProps {
  pageNo: number;
  pageSize: number;
  // personPin: string;
  linkId?: string;
}
interface DeleteLevelProps {
  globalApbId: string;
  id: string;
}
function GlobalAntiPassBack() {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const {
    antiPassBackList,
    status,
    totalDataCount,
    searchFilter,
    persons,
    personStatus,
    personTotalDataCount,
    personSearchFilter,
  } = useAppSelector((state: any) => state.globalAntiPassBack);

  /**
   * Selects the `loadMenus` permission from the authentication slice of the Redux store.
   *
   * @returns {Object} loadMenus - The user's menu permissions.
   */
  const { loadMenus } = useAppSelector((state) => state.auth);

  const [modalOpen, setModalOpen] = useState(false);
  const [modalView, setModalView] = useState({
    title: '',
    content: <>hi</>,
  });
  const [selectedValues, setSelectedValues] = useState<string[]>([]);
  const [personnelSelect, setPersonnelSelect] = useState<string[]>([]);
  let handleEditOpen: any;
  const [selectedRuleFullData, setSelectedRuleFullData] = useState<any[]>([]);
  const [selectedPersonFullData, setSelectedPersonFullData] = useState<any[]>([]);

  const canEdit = checkPermission('acc:globalApb:edit', loadMenus) || true;

  const columnsField = [
    {
      accessorKey: 'name',
      header: t('ruleName'),
      Cell: ({ row }: any) => (
        <div
          className={canEdit ? 'list-hyperlink' : ''}
          role="presentation"
          onClick={(event) => {
            if (!canEdit) return;
            event.stopPropagation(); // Prevent row selection
            handleEditOpen(row.original); // Call your edit function
          }}>
          {row?.original?.name}
        </div>
      ),
    },
    {
      accessorKey: 'zoneName',
      header: t('zone'),
    },
    {
      accessorKey: 'applyTo',
      header: t('antiPassBackType'),
      Cell: ({ row }: any) => {
        const result = abpType.find((item) => String(row.original.applyTo) === item.id);
        return <div>{result?.label}</div>;
      },
    },
    {
      accessorKey: 'timeSegName',
      header: t('personnelRange'),
    },
  ];
  const personnelColumn = [
    {
      accessorKey: 'personPin',
      header: t('personnelId'),
    },
    {
      accessorKey: 'personName',
      header: t('firstName'),
    },
    {
      accessorKey: 'personLastName',
      header: t('lastName'),
    },
    {
      accessorKey: 'deptName',
      header: t('department'),
    },
  ];

  handleEditOpen = (val: any): void => {
    const editData = {
      ...val,
      isResetApb: val?.isResetApb === 0 ? [] : ['0'],
      apbType: String(val.apbType),
      applyTo: String(val.applyTo),
    };
    setModalView({
      title: t('edit'),
      content: <AddAntiPassback closeModal={() => setModalOpen(false)} update={editData} />,
    });
    setModalOpen(true);
  };

  // level functions
  const fetchLevels = (params: LevelProps) => {
    if (params.linkId) {
      dispatch(fetchPersonList({ ...params }));
    }
  };

  const getFilterData = (filter: any): any => {
    if (filter.pageIndex > 0) {
      dispatch(setPersonSearchFilter(filter));
      fetchLevels({
        pageNo: filter.pageIndex,
        pageSize: filter.pageSize,
        ...filter.filter,
      });
    }
  };

  const fetchData = (params: DepartmentParamsProps) => {
    if (status === 'success') {
      dispatch(fetchGlobalAntiPassBackList({ ...params }));
    }
  };

  const getSelected = (id: string[]) => {
    setSelectedValues(id);
    fetchLevels({ linkId: id?.[0], ...searchFilter.filter });
  };

  const getFilterLevels = (filter: any): any => {
    if (filter.pageIndex > 0) {
      dispatch(setSearchFilter(filter));
      fetchData({
        pageNo: filter.pageIndex,
        pageSize: filter.pageSize,
        ...filter.filter,
      });
    }
  };
  const selectedPersonIds = selectedPersonFullData.map((val) => val.id).join(',');
  const deleteRecord = (params: DeleteLevelProps) => {
    const req = {
      globalApbId: selectedValues?.[0],
      personIds: params,
    };
    globalAntiPassBack
      .delPerson(req)
      .then((response) => {
        if (response.data.success) {
          dispatch(
            setNotificationError({
              error: t('personDeletedSuccessfully'),
              status: 200,
            }),
          );
          dispatch(deletePerson(params.id));
          setModalOpen(false);
          fetchLevels({
            linkId: selectedValues?.[0],
            pageNo: searchFilter.pageIndex,
            pageSize: searchFilter.pageSize,
          });
        } else {
          dispatch(
            setNotificationError({
              error: response.data.message,
              status: response.data.code,
            }),
          );
          setModalOpen(false);
        }
      })
      .catch((error) => {
        dispatch(
          setNotificationError({
            error: error.message,
            status: error.status,
          }),
        );
        setModalOpen(false);
      });
  };

  const selectedIds = selectedRuleFullData.join(',');
  const deleteAntiPassBack = (ids: any) => {
    globalAntiPassBack
      .delete({ ids })
      .then((response) => {
        if (response.data.success) {
          dispatch(
            setNotificationError({
              error: t('accGlobalApbDeletedSuccessfully'),
              status: 200,
            }),
          );
          dispatch(deleteApb(ids));
          setModalOpen(false);
          fetchData({ pageNo: 1, pageSize: 10 });
        } else {
          dispatch(
            setNotificationError({
              error: response.data.msg,
              status: response.data.code,
            }),
          );
          setModalOpen(false);
        }
      })
      .catch((error) => {
        dispatch(
          setNotificationError({
            error: error.message,
            status: error.status,
          }),
        );
        setModalOpen(false);
      });
  };
  const handleDeleteClick = () => {
    if (selectedRuleFullData.length === 0) {
      dispatch(
        setNotificationError({
          error: t('pleaseSelectAtLeastOneLPassback'), // Also fixed the error message
          status: 400,
        }),
      );
      return;
    }

    setModalOpen(true);
    setModalView({
      title: t('delete'),
      content: (
        <DeleteModalBox
          closeModal={() => setModalOpen(false)}
          id={selectedIds}
          okFunction={deleteAntiPassBack}
          isMultiSelect
        />
      ),
    });
  };
  const actionButtons = [
    {
      label: t('edit'),
      enable: checkPermission('acc:globalApb:edit', loadMenus),
      function: (val: any) => handleEditOpen(val),
    },
    {
      label: t('delete'),
      enable: checkPermission('acc:globalApb:del', loadMenus),
      function: (val: any) => {
        setModalOpen(true);
        setModalView({
          title: t('delete'),
          content: (
            <DeleteModalBox
              closeModal={() => setModalOpen(false)}
              id={val.id}
              okFunction={deleteAntiPassBack}
            />
          ),
        });
      },
    },
    {
      label: t('addPersonnel'),
      enable: checkPermission('acc:globalApb:addPerson', loadMenus),
      function: (val: any): void => {
        setModalView({
          title: t('addPersonnel'),
          content: <AddPersonnel closeModal={() => setModalOpen(false)} id={val.id} />,
        });
        setModalOpen(true);
      },
    },
  ];
  const handleDeletePersonClick = () => {
    if (selectedPersonFullData.length === 0) {
      dispatch(
        setNotificationError({
          error: t('pleaseSelectAtLeastOneDoor'),
          status: 400,
        }),
      );
      return;
    }
    setModalOpen(true);
    setModalView({
      title: t('delete'),
      content: (
        <DeleteModalBox
          closeModal={() => setModalOpen(false)}
          id={selectedPersonIds}
          okFunction={deleteRecord}
          isMultiSelect
        />
      ),
    });
  };

  const personActions = [
    {
      label: t('delete'),
      enable: checkPermission('acc:globalApb:delPerson', loadMenus),
      function: (val: any) => {
        setModalOpen(true);
        setModalView({
          title: t('delete'),
          content: (
            <DeleteModalBox
              closeModal={() => setModalOpen(false)}
              id={val}
              okFunction={deleteRecord}
            />
          ),
        });
      },
    },
  ];

  return (
    <div className="access-by-level">
      <ModalBox
        status={modalOpen}
        closeModal={() => setModalOpen(false)}
        title={modalView.title}
        content={modalView.content}
      />
      <TopHeader
        title={t('access')}
        broadCram={[
          {
            title: t('advancedFunctions'),
          },
          {
            title: t('globalAntiPassback'),
          },
        ]}
      />
      <CardBox
        header={t('globalAntiPassback')}
        title={t('listOfRule')}
        rightSide={
          <div className="delete-icon">
            <CardRightButtonGroup
              buttonLabel={t('new')}
              buttonIcon={<AddIcon color="#ffffff" />}
              buttonClass="btn-primary btn-sm"
              buttonStatus
              buttonFunction={(): void => {
                setModalOpen(true);
                setModalView({
                  title: t('new'),
                  content: <AddAntiPassback closeModal={() => setModalOpen(false)} />,
                });
              }}
              buttonEnable={checkPermission('acc:globalApb:add', loadMenus)}
              // Add delete button configuration
              DeleteButtonIcon={<DeleteButtonIcon />}
              deleteButtonLabel={t('delete')}
              showDeleteButton
              deleteButtonFunction={handleDeleteClick}
              deleteButtonEnable={checkPermission('acc:globalApb:del', loadMenus)}
              deleteButtonClass="multi-delete-button"
            />
          </div>
        }>
        <DataTable
          actionButtonStatus={!modalOpen}
          action={actionButtons}
          columns={columnsField}
          getFilterData={getFilterLevels}
          enablePagination
          assignFilterValue={searchFilter}
          assignSelectedData={selectedValues}
          getSelectedData={(val: any): any => {
            getSelected(val); // Set selected cards
            setSelectedRuleFullData(val);
          }}
          checkBoxActive
          singleRowSelection
          values={antiPassBackList}
          fixedColumn={['name']}
          isFetching={status === 'loading'}
          totalRecords={totalDataCount}
          height={411}
        />
        <hr className="line" />
        <div className="browse-table">
          <div className="door-header">
            <div className="header">
              <h4>Person</h4>
              <span className="sub-title">{t('listOfPersonnel')}</span>
            </div>
            <div className="delete-icon">
              <CardRightButtonGroup
                DeleteButtonIcon={<DeleteButtonIcon />}
                deleteButtonLabel={t('delete')}
                showDeleteButton
                deleteButtonFunction={handleDeletePersonClick}
                deleteButtonEnable={checkPermission('acc:globalApb:delPerson', loadMenus)}
                deleteButtonClass="multi-delete-button"
              />
            </div>
          </div>
          <DataTable
            actionButtonStatus={!modalOpen}
            action={personActions}
            columns={personnelColumn}
            getFilterData={getFilterData}
            enablePagination
            assignFilterValue={personSearchFilter}
            assignSelectedData={personnelSelect}
            getSelectedData={(val: any): any => setPersonnelSelect(val)}
            getSelectedFullData={(val: any[]): any => {
              setSelectedPersonFullData(val);
            }}
            checkBoxActive
            values={persons}
            fixedColumn={['id']}
            isFetching={personStatus === 'loading'}
            totalRecords={personTotalDataCount}
            height={411}
          />
        </div>
      </CardBox>
    </div>
  );
}

export default GlobalAntiPassBack;
