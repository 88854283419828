import { post, get } from '../base/index';

export default {
  getListPage: (data: any = {}) => {
    return post(
      `${process.env.REACT_APP_API_URL}Sys/api/clientRegister/list`,
      {},
      { ...data, pageSize: 10 },
    );
  },

  getClientList: () => {
    return get(`${process.env.REACT_APP_API_URL}Sys/api/clientRegister/getRegistClientList`, {});
  },

  saveList: (data: any = {}) => {
    return post(`${process.env.REACT_APP_API_URL}Sys/api/clientRegister/save`, data, {});
  },

  delete: (id: string = '') => {
    return get(`${process.env.REACT_APP_API_URL}Sys/api/clientRegister/delete?ids=${id}`, {});
  },
};
