import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from '../../../redux/store';

// Use shared components
import TopHeader from '../../../shared-components/top-header/top-header';
import CardBox from '../../../shared-components/card/card';
import DataTable from '../../../shared-components/data-table/data-table';
import CardRightButtonGroup from '../../../shared-components/card-right-button-group/card-right-button-group';
import ModalBox from '../../../shared-components/modal-box/modal-box';
import ConformationBox from '../../../shared-components/conformation-box/conformation-box';
// Import slice actions
import {
  fetchTodayTransactionList,
  setSearchFilter,
} from '../../../redux/slices/EventsFromTodaySlices';
import { setNotificationError } from '../../../redux/slices/NotificationSlices';
import { DepartmentParamsProps } from '../../../interface/accessByDepartment.interface';
import { changeDateTimeFormat, verifyModeFormat } from '../../../utils/helpers';
import eventsFromToday from '../../../utils/api/AccControlReports/eventsFromToday';
import { MediaFileType } from '../../../assets/svgicons/svgicon';
import ExportData from './export-data';
import { checkPermission } from '../../../utils/role-permission';
import { eventCodes } from '../../realTime-monitoring/options';

function EventsFromToday() {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { todayTransactions, status, totalDataCount, searchFilter } = useAppSelector(
    (state: any) => state.todayTransaction,
  );

  /**
   * Selects the `loadMenus` permission from the authentication slice of the Redux store.
   *
   * @returns {Object} loadMenus - The user's menu permissions.
   */
  const { loadMenus } = useAppSelector((state) => state.auth);

  const [modalOpen, setModalOpen] = useState(false);
  const [modalView, setModalView] = useState({
    title: 'hi',
    content: <>hi</>,
  });

  const handleOpenMedia = (val: string) => {
    console.log('hi', val);
  };

  const columns = [
    {
      accessorKey: 'eventTime',
      header: t('time'),
      size: 180,
      Cell: ({ row }: any) => <div>{changeDateTimeFormat(row.original.eventTime)}</div>,
    },
    {
      accessorKey: 'areaName',
      header: t('areaName'),
      size: 130,
    },
    {
      accessorKey: 'devAlias',
      header: t('deviceName'),
      size: 150,
    },
    {
      accessorKey: 'eventPointName',
      header: t('eventPoint'),
      size: 150,
    },
    {
      accessorKey: 'eventName',
      header: t('eventDescription'),
      size: 180,
      Cell: ({ row }: any) => eventCodes[row.original.eventName],
    },
    {
      accessorKey: 'levelAndEventPriority',
      header: t('eventLevel'),
      size: 170,
      Cell: ({ row }: any) => {
        let result;
        if (row.original.levelAndEventPriority === '0') {
          result = <span style={{ color: '#00A76F' }}>Normal</span>;
        } else if (row.original.levelAndEventPriority === '2#0') {
          result = <span className="text-danger">Alarm(Weak)</span>;
        } else if (row.original.levelAndEventPriority === '1') {
          result = <span className="text-warning">Exception</span>;
        }
        return result;
      },
    },
    {
      accessorKey: 'capturePhotoPath',
      header: t('mediaFile'),
      size: 150,
      Cell: ({ row }: any) => (
        <div
          style={{ cursor: 'pointer' }}
          role="presentation"
          onClick={(event) => {
            event.stopPropagation(); // Prevent row selection
            handleOpenMedia(row.original.capturePhotoPath); // Call your edit function
          }}>
          {row.original.capturePhotoPath ? <MediaFileType /> : ''}
        </div>
      ),
    },
    {
      accessorKey: 'pin',
      header: t('personnelId'),
      size: 140,
    },
    {
      accessorKey: 'name',
      header: t('firstName'),
      size: 140,
    },
    {
      accessorKey: 'lastName',
      header: t('lastName'),
      size: 140,
    },
    {
      accessorKey: 'cardNo',
      header: t('cardNumber'),
      size: 140,
    },
    {
      accessorKey: 'deptName',
      header: t('departmentName'),
      size: 170,
    },
    {
      accessorKey: 'readerName',
      header: t('readerName'),
      size: 180,
    },
    {
      accessorKey: 'verifyModeName',
      header: t('verificationMode'),
      size: 150,
      Cell: ({ row }: any) => {
        return <div>{verifyModeFormat(row.original.verifyModeName)}</div>;
      },
    },
  ];

  const fetchData = async (params: DepartmentParamsProps) => {
    if (status === 'success') {
      dispatch(fetchTodayTransactionList({ ...params }));
    }
  };

  const getFilterData = (filter: any): any => {
    if (filter.pageIndex > 0) {
      dispatch(setSearchFilter(filter));
      fetchData({ pageNo: filter.pageIndex, pageSize: filter.pageSize, ...filter.filter });
    }
  };

  const exportData = () => {
    setModalOpen(false);
  };

  const onConfirmClear = () => {
    eventsFromToday
      .clearData()
      .then((response) => {
        if (response.data.success) {
          dispatch(
            setNotificationError({
              error: response.data.msg,
              status: 200,
            }),
          );
          setModalOpen(false);
          fetchData({ pageNo: 1 }); // Refresh the list after successful deletion
        } else {
          dispatch(
            setNotificationError({
              error: response.data.msg,
              status: response.data.ret,
            }),
          );
        }
      })
      .catch((error) => {
        dispatch(
          setNotificationError({
            error: error.message,
            status: error.response?.status || 500,
          }),
        );
      });
  };
  const moreActionButton = [
    {
      title: t('export'),
      enable: checkPermission('acc:transactionToday:export', loadMenus),
      clickFunction: (): void => {
        setModalView({
          title: t('export'),
          content: (
            <ExportData closeModal={() => setModalOpen(false)} submitFunction={exportData} />
          ),
        });
        setModalOpen(true);
      },
    },
    {
      title: t('clearAllData'),
      enable: true,
      clickFunction: (): void => {
        setModalView({
          title: t('clearAllData'),
          content: (
            <ConformationBox
              closeModal={() => setModalOpen(false)}
              okFunction={onConfirmClear}
              title={t('areYouWantToClearAllRecords')}
              buttonLabel={t('ok')}
              cancelTrue
            />
          ),
        });
        setModalOpen(true);
      },
    },
  ];

  return (
    <div className="card-management">
      <ModalBox
        status={modalOpen}
        closeModal={() => setModalOpen(false)}
        title={modalView.title}
        content={modalView.content}
      />
      <TopHeader
        title={t('access')}
        broadCram={[
          {
            title: t('accControlReports'),
          },
          {
            title: t('eventsFromToday'),
          },
        ]}
      />
      <CardBox
        header=""
        title={t('listOfEvents')}
        rightSide={
          <CardRightButtonGroup
            actions={moreActionButton}
            buttonIcon={false}
            buttonLabel=""
            buttonClass=""
            buttonStatus={false}
          />
        }>
        <DataTable
          actionButtonStatus={false}
          columns={columns}
          enablePagination
          getFilterData={getFilterData}
          assignFilterValue={searchFilter}
          checkBoxActive={false}
          isFetching={status === 'loading'}
          values={todayTransactions}
          fixedColumn={['eventTime']}
          totalRecords={totalDataCount}
          height={411}
        />
      </CardBox>
    </div>
  );
}

export default EventsFromToday;
